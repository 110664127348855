import React, { useState } from "react";
import Provision from "./provision";
import Privacy from "./privacy";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import ModalContent from "./modalContent";
import Modal from "../../../common/modal";
import { PageContainer } from "./styled/common";

const UserJoinForm = () => {
  const navigate = useNavigate();
  const [toggleLegalInfo, setToggleLegalInfo] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    mberNm: "",
    telno: "",
    email: "",
  });
  const [isDuplicate, setIsDuplicate] = useState({
    username: false,
    telno: false,
    email: false,
  });
  const [agreements, setAgreements] = useState({
    selectall: false,
    serviceTerms: false,
    privacyPolicy: false,
    marketing: false,
  });

  const changeInput = (e) => {
    const { name, checked, type } = e.target;

    if (!name) return;

    if (type === "checkbox") {
      if (name === "selectall") {
        setAgreements({
          selectall: checked,
          serviceTerms: checked,
          privacyPolicy: checked,
          marketing: checked,
        });
      } else {
        setAgreements((prev) => {
          const updated = { ...prev, [name]: checked };
          const allChecked = updated.serviceTerms && updated.privacyPolicy && updated.marketing;

          return { ...updated, selectall: allChecked };
        });
      }
    } else {
      const { value } = e.target;
      setFormData((data) => ({ ...data, [name]: value }));

      if (isDuplicate.hasOwnProperty(name) && isDuplicate[name]) {
        setIsDuplicate((state) => ({ ...state, [name]: false }));
      }
    }
  };

  // 아이디, 휴대폰 번호 중복검사
  const alreadyExists = async (type) => {
    const types = {
      username: {
        url: "/api/isExistId",
        body: { username: formData.username },
        checkDuplicate: (data) => data.message === "isExist",
        displayName: "아이디",
      },
      telno: {
        url: "/api/duplMoblphonNo",
        body: { moblphonNo: formData.telno },
        checkDuplicate: (data) => !data.success,
        displayName: "휴대폰 번호",
      },
      email: {
        url: "/api/duplEmail",
        body: { email: formData.email },
        checkDuplicate: (data) => !data.success,
        displayName: "이메일",
      },
    };

    try {
      const { url, body, checkDuplicate, displayName } = types[type];
      const res = await axios.post(url, body);

      if (checkDuplicate(res.data)) {
        toast.error(`이미 사용 중인 ${displayName}입니다.`);
        setIsDuplicate((data) => ({ ...data, [type]: false }));
      } else {
        toast.success(`사용 가능한 ${displayName}입니다.`);
        setIsDuplicate((data) => ({ ...data, [type]: true }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validationCheck = (type) => {
    const types = {
      telno: {
        reg: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
        msg: "유효하지 않은 휴대폰 번호입니다.",
      },
      username: {
        reg: /^[a-zA-Z0-9]{6,15}$/,
        msg: "아이디는 영문과 숫자를 포함한 6~15자리여야 합니다.",
      },
      email: {
        reg: /([A-Za-z0-9_.-]+(\.[A-Za-z0-9_.-]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/,
        msg: "유효하지 않은 이메일 주소입니다.",
      },
    };

    const { reg, msg } = types[type];
    const inputValue = formData[type] || undefined;

    if (inputValue && reg.test(inputValue)) {
      alreadyExists(type);
    } else {
      toast.error(msg);
    }
  };

  // 전체 체크
  const validateFormData = () => {
    const fieldLabels = {
      username: "아이디",
      password: "비밀번호",
      confirmPassword: "비밀번호 확인",
      mberNm: "고객명",
      telno: "휴대폰 번호",
      email: "이메일",
    };

    for (let key in formData) {
      if (textNotExist(formData[key])) {
        const label = fieldLabels[key] || "";
        toast.error(`${chooseParticle(label, "을를")} 입력해주세요.`);
        return;
      }
    }

    for (let key in isDuplicate) {
      if (!isDuplicate[key]) {
        const label = fieldLabels[key] || "";
        toast.error(`${label} 중복 확인을 해주세요.`);
        return;
      }
    }

    const regPassword = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[#?!@$ %^&*-]).{8,}$/;
    if (!regPassword.test(formData.password)) {
      toast.error("비밀번호 양식이 올바르지 않습니다.");
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      toast.error("비밀번호가 일치하지 않습니다.");
      return;
    }

    if (!agreements.privacyPolicy || !agreements.privacyPolicy) {
      toast.error("모든 필수 약관에 동의해야 회원가입이 가능합니다.");
      return;
    }
    const regKor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    if (regKor.test(formData.email)) {
      toast.error("이메일 양식이 일치하지 않습니다.");
      return;
    }

    handleModalClose();
  };

  const createCmpny = async () => {
    const url = "/api/cmpny";
    const body = {
      cmpnyNm: formData.mberNm,
      useYn: "Y",
      createMberId: formData.username,
      updateMberId: formData.username,
    };
    const res = await axios.put(url, body);
    if (res.data) {
      const cmpnyNo = res.data.rtnModel.cmpnyNo;
      await onSubmit(cmpnyNo);
    }
  };

  const onSubmit = async (cmpnyNo) => {
    const url = "/api/mber";
    const body = {
      cmpnyNo,
      username: formData.username,
      password: formData.password,
      mberNm: formData.mberNm,
      moblphonNo: formData.telno,
      email: formData.email,
      createMberId: formData.username,
      updateMberId: formData.username,
      rgsCl: "MBRS04",
      delYn: "N",
    };
    const res = await axios.put(url, body);
    if (res.data.success) {
      toast.success("회원 가입이 완료되었습니다.");
      navigate("/login");
    }
  };

  const handleModalClose = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  const handleModalConfirm = () => {
    createCmpny();
  };

  return (
    <PageContainer>
      <div className="sub_top" id="mypage">
        <span className="font_22 font300 pdb_20 font_color_white">회원가입</span>
        <span className="font_72 font700 font_color_1 pdb_70">JOIN</span>
      </div>
      <div className="inner_content">
        <div className="inner_title m_join_in">
          <span className="font_30 font300 font_color_black pdb_40">
            모든 작업을 한 곳에서 편리하게,
            <br />
            모노티 캘린더에 오신 것을 환영합니다.
          </span>
        </div>
        <div className="writing_ty2 m_join_wrap">
          {
            <div className="step_tit_bar m_join">
              <span className="font_32 font600 font_color_1 pdb_20">계정정보</span>
              <span className="font_16 font_color_gray font300 text_left pdb_20">
                ※ 모두 필수 입력 정보입니다.
              </span>
            </div>
          }
          <ul className="writing_list m_join">
            <li className="no_border">
              <div className="writing_tit required">
                <strong className="tit">
                  아이디<em className="point">필수</em>
                </strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider between_f">
                  <input
                    type="text"
                    name="username"
                    placeholder="아이디 (영문 + 숫자, 6~15자리)"
                    value={formData.username}
                    onChange={(e) => changeInput(e)}
                    tabIndex={1}
                  />
                  <button
                    type="button"
                    className="btn_line gray btn_duplicate"
                    onClick={() => validationCheck("username")}
                    tabIndex={2}>
                    <span>중복 확인</span>
                  </button>
                </div>
              </div>
            </li>
            <li className="no_border">
              <div className="writing_tit required">
                <strong className="tit">
                  비밀번호<em className="point">필수</em>
                </strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider between_f">
                  <input
                    type="password"
                    name="password"
                    placeholder="비밀번호 (영문 + 숫자 + 특수문자, 8자리이상)"
                    value={formData.password}
                    onChange={(e) => changeInput(e)}
                    tabIndex={3}
                  />
                  <button
                    type="button"
                    name="button"
                    className="btn_line gray btn_duplicate no_cusor">
                    <i className="icon_secure"></i>
                  </button>
                </div>
              </div>
            </li>

            <li className="no_border">
              <div className="writing_tit required">
                <strong className="tit">
                  비밀번호 확인<em className="point">필수</em>
                </strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider between_f">
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="비밀번호 확인"
                    value={formData.confirmPassword}
                    onChange={(e) => changeInput(e)}
                    tabIndex={4}
                  />
                  <button
                    type="button"
                    name="button"
                    className="btn_line gray btn_duplicate no_cusor">
                    <i className="icon_secure"></i>
                  </button>
                </div>
              </div>
            </li>
            <li className="no_border">
              <div className="writing_tit required">
                <strong className="tit">
                  고객명<em className="point">필수</em>
                </strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider">
                  <input
                    type="text"
                    name="mberNm"
                    placeholder="고객명을 입력하세요"
                    value={formData.mberNm}
                    onChange={(e) => changeInput(e)}
                    tabIndex={5}
                  />
                </div>
              </div>
            </li>
            <li>
              {/* <div className="impor_txt">
                입력한 휴대전화로 알림톡이 발송되니 정확하게 입력해주세요.
              </div> */}
              <div className="writing_tit required">
                <strong className="tit">
                  고객 휴대전화<em className="point">필수</em>
                </strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider between_f">
                  <input
                    type="text"
                    name="telno"
                    placeholder="휴대폰 번호(-없이 입력)"
                    value={formData.telno}
                    onChange={(e) => changeInput(e)}
                    tabIndex={6}
                  />
                  <button
                    type="button"
                    className="btn_line gray btn_duplicate"
                    onClick={() => validationCheck("telno")}
                    tabIndex={7}>
                    <span>중복 확인</span>
                  </button>
                </div>
              </div>
            </li>
            <li>
              <div className="writing_tit required">
                <strong className="tit">
                  이메일<em className="point">필수</em>
                </strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider between_f">
                  <input
                    type="text"
                    className=""
                    name="email"
                    placeholder="이메일"
                    value={formData.email}
                    onChange={(e) => changeInput(e)}
                    tabIndex={8}
                  />
                  <button
                    type="button"
                    className="btn_line gray btn_duplicate"
                    onClick={() => validationCheck("email")}
                    tabIndex={9}>
                    <span>중복 확인</span>
                  </button>
                </div>
              </div>
            </li>
            <li>
              <div className="check_area">
                <label className="btn_check mgr10">
                  <input
                    type="checkbox"
                    name="selectall"
                    checked={agreements.selectall}
                    onChange={(e) => changeInput(e)}
                  />
                  <span className="hero"></span>
                  <strong>전체약관에 동의합니다.</strong>
                </label>
              </div>
              <div className="agree_box">
                <ul>
                  <li>
                    <label className="btn_check mgr10">
                      <input
                        type="checkbox"
                        name="serviceTerms"
                        checked={agreements.serviceTerms}
                        onChange={(e) => changeInput(e)}
                        tabIndex={10}
                      />
                      <span className="hero"></span>
                      <strong>모노티 캘린더 서비스이용약관 (필수)</strong>
                      <button
                        type="button"
                        name="button"
                        className="agree_btn"
                        onClick={() => setToggleLegalInfo("provision")}>
                        <span>약관 보기</span>
                      </button>
                    </label>
                  </li>
                  <li>
                    <label className="btn_check mgr10">
                      <input
                        type="checkbox"
                        name="privacyPolicy"
                        checked={agreements.privacyPolicy}
                        onChange={(e) => changeInput(e)}
                        tabIndex={11}
                      />
                      <span className="hero"></span>
                      <strong>모노티 캘린더 개인정보처리방침 (필수)</strong>
                      <button
                        type="button"
                        name="button"
                        className="agree_btn"
                        onClick={() => setToggleLegalInfo("privacy")}>
                        <span>약관 보기</span>
                      </button>
                    </label>
                  </li>
                  <li>
                    <label className="btn_check mgr10">
                      <input
                        type="checkbox"
                        name="marketing"
                        checked={agreements.marketing}
                        onChange={(e) => changeInput(e)}
                        tabIndex={12}
                      />
                      <span className="hero"></span>
                      <strong>광고성정보수신 (선택)</strong>
                    </label>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <div className="btnarea_center mt20">
            <button className="btn_normal" style={{ border: "none" }} onClick={validateFormData}>
              <span>
                다음 <i className="fa fa-angle-right" aria-hidden="true"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
      {toggleLegalInfo === "privacy" && <Privacy setToggleLegalInfo={setToggleLegalInfo} />}
      {toggleLegalInfo === "provision" && <Provision setToggleLegalInfo={setToggleLegalInfo} />}
      <Modal
        modalState={modalOpen}
        handleModalState={handleModalClose}
        html={<ModalContent handleConfirm={handleModalConfirm} handleCancel={handleModalClose} />}
        w="300px"
        h="auto"
      />
    </PageContainer>
  );
};

export default UserJoinForm;
