import Footer from "./common/footer.jsx";
import Header from "./common/header.jsx";
import styled from "styled-components";

import { useEffect, useState } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store.js";
import { ToastContainer } from "react-toastify";

const CustomToast = styled(ToastContainer)`
  z-index: 10000;
  width: "400px";
  font-weight: 600;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const App = () => {
  document.title = "모노티";
  const { pathname } = useLocation();
  const [isComponent, setIsComponent] = useState(true);

  function ScrollToTop() {
    useEffect(() => {
      window.scrollTo(0, 0);
      changeBodyClassName();
      setIsComponent(!pathname.includes("self-service"));
    }, [pathname]);

    return null;
  }

  return (
    <Provider store={store}>
      <CustomToast
        position="top-center"
        theme="light"
        pauseOnHover
        hideProgressBar={true}
        autoClose={1000}
        closeButton={false}
        limit={1}
      />
      <ScrollToTop />
      {isComponent && <Header />}
      <Outlet />
      {isComponent && <Footer />}
    </Provider>
  );
};

export default App;
