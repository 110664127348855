import axios from "axios"
import { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import MypageBanner from "../../mypageBanner";

export default function MakeCompanyDomain () {

  const navigate = useNavigate();
  const inputRef = useRef(null);
  const user = useSelector((state) => state?.user?.data[0]);

  const [inputDomain, setInputDomain] = useState("");
  const [domainCheck, setDomainCheck] = useState(false);

  useEffect(() => {
    checkedDomain();
  }, []);

  const checkedDomain = async (flag) => {
    const url = '/api/domainSettingLoad'
    const body = {
      delYn: "N"
    }

    if(flag){
      body.domain = inputDomain;
    }else{
      body.cmpnyNo = user.cmpnyNo;
    }

    const response = await axios.post(url,body);
    if(flag){
      if(response?.data?.domain || inputDomain === ''){
        toast.error('사용 할 수 없는 도메인입니다.');
        inputRef.current.focus();
        setDomainCheck(false);
      }else{
        toast.success('사용 할 수 있는 도메인입니다.');
        setDomainCheck(true);
      }
    }else{
      setInputDomain(response?.data?.domain);
    }
  }

  const handleInputDomain = (value) =>{
    setDomainCheck(false);
    setInputDomain(value);
  }

  const makeHomepage = () => {
    if(!domainCheck){
      toast.error('홈페이지 생성에 실패하였습니다.');
      inputRef.current.focus();
    }else{
      saveDomain();
    }
  }

  const saveDomain = async () => {
    const url = '/api/domainSettingSave'
    const body = {
      cmpnyNo: user.cmpnyNo,
      domain: inputDomain,
      delYn: "N",
      createMberId: user.userName,
      updateMberId: user.userName
    }

    const response = await axios.put(url,body);
    if(response?.data?.success){
      navigate("/mypage/homepage",{state : {saveMessage: true}});
    }else{
      toast.error('에러가 발생 했습니다 잠시후 다시 시도해주세요.');
    }
  }

  return(
    <div>
      <MypageBanner/>
      <div className="inner_content">
        <div id="step1_html">
          <div className="writing_ty2">
            <ul className="writing_list" style={{marginBottom : "40px"}}>
              <li>
                <div className="writing_tit " style={{padding : "10px 30px"}}>
                  <strong className="tit">도메인 설정</strong>
                </div>
                <div className="writing_cont" id="company_domain">
                  <div className="inputxt_normal">
                    <p className="font_18 font_color_black font300 text_center">
                      https://1472.ai/h/
                    </p>
                    <input
                      ref={inputRef}
                      type="text"
                      placeholder="abcd"
                      value={inputDomain}
                      onChange={e => handleInputDomain(e.target.value)}
                    />
                    <button
                      type="button"
                      name="button"
                      className="btn_line gray btn_duplicate"
                      onClick={() => checkedDomain(true)}
                    >
                      중복체크
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div className="btnarea_center mt20">
              <a className="btn_normal" onClick={makeHomepage}>
                생성하기
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}