import React from "react";
import { useOutletContext } from "react-router";

import { ChicProductsContainer } from "../shopping/component/styled/chicTheme";
import { ModernProductsContainer } from "../shopping/component/styled/modernTheme";
import { CuteProductsContainer } from "../shopping/component/styled/cuteTheme";

import CuteBanner from "../shopping/component/themes/cute/banner";
import ModernBanner from "../shopping/component/themes/modern/banner";
import ProductListForm from "./component/productListForm";
import { SimpleProductsContainer } from "../shopping/component/styled/simpleTheme";
import SimpleBanner from "../shopping/component/themes/simple/banner";

const ProductsList = () => {
  const { theme } = useOutletContext();

  const renderComponent = () => {
    switch (theme) {
      case "A":
        return <ProductListForm />;
      case "B":
        return (
          <ChicProductsContainer>
            <ProductListForm />
          </ChicProductsContainer>
        );
      case "C":
        return (
          <ModernProductsContainer>
            <ModernBanner />
            <ProductListForm />
          </ModernProductsContainer>
        );
      case "D":
        return (
          <CuteProductsContainer>
            <CuteBanner />
            <ProductListForm />
          </CuteProductsContainer>
        );
      case "E":
        return (
          <SimpleProductsContainer>
            <SimpleBanner />
            <ProductListForm />
          </SimpleProductsContainer>
        );
      default:
        return <ProductListForm />;
    }
  };

  return <>{renderComponent()}</>;
};

export default ProductsList;
