import React from "react";
import { useOutletContext } from "react-router";

import { SimpleContainer } from "./styled/simpleTheme";

import SimpleBanner from "./themes/simple/banner";
import OurHistory from "./themes/simple/ourHistory";
import Products from "./themes/simple/products";
import Portfolio from "./themes/simple/portfolio";
import News from "./themes/simple/news";

const SimpleTheme = () => {
  const { cmpnyNo, url, homepageInfo } = useOutletContext();
  return (
    <SimpleContainer>
      <SimpleBanner />
      <OurHistory homepageInfo={homepageInfo} url={url} />
      <Products cmpnyNo={cmpnyNo} url={url} />
      <Portfolio cmpnyNo={cmpnyNo} url={url} />
      <News cmpnyNo={cmpnyNo} url={url} />
    </SimpleContainer>
  );
};

export default SimpleTheme;
