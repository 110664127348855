import React from "react";

import { Container } from "./styled/shop";

import ShopSearch from "./component/shopSearch";
import ShopLocator from "./component/shopLocator";
import EChanggoCard from "./component/eChanggoCard";
import GroupLocator from "./component/groupLocator";

const Shop = () => {
  return (
    <Container>
      <ShopSearch />
      <ShopLocator />
      <EChanggoCard />
      <GroupLocator />
    </Container>
  );
};

export default Shop;
