import "swiper/css";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { useRegex } from "../../../hooks/useRegex";
import { useEffect, useState } from "react";

import { MemberPageSwiperContainer } from "./styled/member";
import { useMediaQuery } from "react-responsive";

const MemberPageSwiper = ({ members = {} }) => {
  const [swiperMembers, setSwiperMembers] = useState([]);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const isFontSize = useMediaQuery({ query: "(max-width:1250px) and (min-width: 1050px)" });

  const { TelFormat } = useRegex();

  const initializeSwiper = (swiper) => {
    setSwiperInstance(swiper);
  };

  useEffect(() => {
    setSwiperMembers(members.content?.slice(0, 6));
  }, [members]);

  useEffect(() => {
    if (swiperInstance && swiperMembers) {
      swiperInstance.slideTo(0);
      swiperInstance.autoplay = { running: true };
      swiperInstance.update(); // 스와이퍼 업데이트
    }
  }, [swiperMembers]);

  const handleNextClick = () => {
    if (swiperInstance) {
      swiperInstance.slideNext();
    }
  };

  const handlePrevClick = () => {
    if (swiperInstance) {
      swiperInstance.slidePrev();
    }
  };

  const shopsLink = (domain) => {
    window.open(`https://1472.ai/shop/${domain}`);
  };

  return (
    <MemberPageSwiperContainer>
      <div
        className="swiper member_swiper_content"
        style={{
          width: "80%",
          margin: "0 auto",
        }}>
        <Swiper
          modules={[Autoplay, Pagination]}
          loop={true}
          slidesPerView={1}
          spaceBetween={50}
          slidesPerGroup={1}
          scrollbar={false}
          onSwiper={initializeSwiper} // 스와이퍼 인스턴스 초기화
          pagination={{ clickable: true }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          breakpoints={{
            1050: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            767: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          }}>
          {swiperMembers?.map((item, i) => (
            <SwiperSlide
              key={i}
              className="member_search_wrap"
              onClick={() => shopsLink(item.domain)}>
              <div className="swiper-slide" style={{ paddingBottom: "0%" }}>
                <img className="member_recon" src="/img/member_recon.png" />
                <span
                  className="member_location font_16 font500 font_color_white"
                  style={{ top: "-2%", right: "1%" }}>
                  대전
                </span>
                {item.logoImage ? (
                  <div className="mem_logo">
                    <img className="member_logo" src={imgHandler(item.logoImage)} />
                  </div>
                ) : (
                  <div className="mem_logo">
                    <img className="member_logo" src="/img/member_nologo_copy.png" />
                  </div>
                )}
                <span className="font_24 font600 font_color_black pdb_10 text_center text_overflow">
                  {item.cmpnyNm ?? ""}
                </span>
                <span
                  className={`font_16 font300 font_color_gray pdb_20 text_center text_overflow`}>
                  {item.adres ?? ""}
                </span>
                <div className="member_info font500 font_color_gray text_center">
                  <span className="swiper_resp_tel text_overflow font_18">
                    대표자: {item.rprNm ?? " "}
                  </span>
                  <br />
                  <span
                    className={`swiper_resp_tel text_overflow ${
                      isFontSize ? "font_14" : "font_18"
                    }`}>
                    전화: {TelFormat(item.telNo) ?? " "}
                  </span>
                </div>
                <div className="member_info_img">
                  <img
                    src={
                      item.bannerImgList && JSON.parse(item.bannerImgList)
                        ? imgHandler(JSON.parse(item.bannerImgList))
                        : "/img/function_11_bg.png"
                    }
                    style={{
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>
              <p
                className="member_search_btn font_20 font500 font_color_white"
                style={{ padding: "54% 0" }}
                id="mobile_none">
                <span className="homepage_link">홈페이지 바로가기</span>
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="swiper-button-prev swiper-button-white" onClick={handlePrevClick} />
      <div
        className="swiper-button-next swiper-button-white"
        onClick={handleNextClick}
        style={{ color: "white" }}
      />
    </MemberPageSwiperContainer>
  );
};

export default MemberPageSwiper;
