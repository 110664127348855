import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setDashboardIndex } from "../../store/compoIndex";

const MypageBanner = () => {
  const dispatch = useDispatch();
  return (
    <div className="sub_top" id="mypage">
      <span className="font_22 font300 pdb_20 font_color_white">{service.dash}</span>
      <span className="font_72 font700 font_color_1 pdb_70">{service.dashE}</span>
      <ul className="sub_menu">
        {myQnaMenu.map((item, i) =>
          item.label === "도움말" ? (
            <li key={i}>
              <Link to={item.link}>{item.label}</Link>
            </li>
          ) : (
            <li onClick={i === 0 ? () => dispatch(setDashboardIndex(0)) : null} key={i}>
              <Link to={item.link}>{item.label}</Link>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

export default MypageBanner;
