import React from "react";
import { OwnerMessageContainer } from "../styled/community";
import SwiperList from "../common/swiperList";
import { useMediaQuery } from "react-responsive";

const OwnerMessage = () => {
  const temp = Array.from({ length: 9 }).fill("1");
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  return (
    <OwnerMessageContainer className="viewerContiner">
      <div className="label">
        <span>사장님 한마디</span>
        <button> 글 남기기</button>
      </div>
      <SwiperList data={temp} range={isTablet ? 3 : 4} type="ownerMessage" />
    </OwnerMessageContainer>
  );
};

export default OwnerMessage;
