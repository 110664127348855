import React, { useEffect, useState } from "react";
import SwiperPerView from "../common/swiperPerView";

const ShareHobby = () => {
  const temp = Array.from({ length: 18 });

  return (
    <div className="viewerContiner">
      <div className="label">
        <span>내 취미 공유</span>
        <button>추천하기</button>
      </div>
      <SwiperPerView data={temp} type="groups" />
    </div>
  );
};

export default ShareHobby;
