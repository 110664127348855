import React from "react";
import { SecondaryMockupContainer } from "../styled/info";

const SecondaryMockup = ({ type, content }) => {
  return (
    <SecondaryMockupContainer className="infoSection">
      <div className="inner">
        <div className="img">
          <img src={`/img/info/secondaryMockup_${type}.png`} />
        </div>
        <div className="content">
          <span className="title">{content.heading}</span>
          <span className="desc">{content.description}</span>
          <strong>{content.feature}</strong>
        </div>
      </div>
    </SecondaryMockupContainer>
  );
};

export default SecondaryMockup;
