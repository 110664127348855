import styled, { keyframes } from "styled-components";

export const MemberSwiperContainer = styled.div`
  .member_swiper .swiper-slide-active {
    min-height: 560px;
    .member_location {
      right: 55px;
    }
    .member_description {
      padding: 0;
    }
  }
  @media screen and (max-width: 767px) {
    .member_swiper .swiper-slide-active {
      min-height: 600px;
    }
  }
`;

export const SectionSwiperContainer = styled.div`
  background: #191f28;
  min-height: 700px;
  .inner {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    color: #fff;
    padding: 100px 0;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
    .content {
      display: flex;
      flex-direction: column;
      gap: 30px;
      font-weight: normal;
      strong {
        font-size: 48px;
        font-weight: 700;
      }
      .buttons {
        display: flex;
        gap: 10px;
        button {
          border-radius: 10px;
          width: 190px;
          height: 55px;
          background: #fff;
          color: #000;
          margin-right: 10px;
          margin-bottom: 10px;
          border: none;
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: center;
          img {
            width: 20px;
            height: 20px;
            object-fit: contain;
            user-select: none;
          }
          span {
            font-size: 14px;
            font-weight: 600;
            color: #000;
          }
        }
      }
    }
    .img {
      width: 500px;
      text-align: center;
      img {
        max-width: 100%;
        height: 500px;
        object-fit: contain;
        filter: drop-shadow(30px 30px 30px #000);
        user-select: none;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .inner {
      padding: 70px 20px;
    }
  }
  @media screen and (max-width: 1050px) {
    min-height: auto;
    .inner {
      .img {
        display: none;
      }
      .content {
        text-align: center;
        width: 100%;
        strong {
          font-size: 36px;
        }
        > span {
          display: none;
        }
        .buttons {
          justify-content: center;
          button {
            margin: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .inner {
      padding: 50px 20px;
      .content {
        strong {
          font-size: 25px;
        }
        .buttons {
          button {
            width: 50%;
            height: 40px;
          }
        }
      }
    }
  }
`;

export const MainContainer = styled.div`
  .mainSection {
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:nth-child(odd) {
      background: #f2f4f6;
      .inner {
        flex-direction: row-reverse;
        .content {
          text-align: right;
          button {
            margin-left: auto;
          }
        }
      }
    }
    &:nth-child(even) {
      .img {
        text-align: right;
      }
    }
    .inner {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 150px;
      .img {
        width: 400px;
        img {
          max-width: 100%;
          max-height: 500px;
          object-fit: contain;
          user-select: none;
          filter: drop-shadow(30px 30px 15px #999);
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 400px;
        .title,
        strong {
          font-size: 30px;
          font-weight: 700;
          white-space: pre-line;
        }
        .details {
          color: #747474;
          white-space: pre-line;
        }
        button {
          width: 200px;
          height: 60px;
          background: #101010;
          color: #fff;
          border-radius: 10px;
          border: none;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .mainSection {
      height: auto;
      .inner {
        padding: 40px 20px;
        gap: 10%;
        .content {
          max-width: 40%;
          .title {
            font-size: 12px;
          }
          strong {
            font-size: 20px;
          }
          .details {
            font-size: 14px;
          }
          button {
            width: 122px;
            height: 36px;
            font-size: 12px;
          }
        }
        .img {
          max-width: 40%;
          img {
            max-height: 300px;
            filter: drop-shadow(rgb(153, 153, 153) 15px 15px 10px);
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .mainSection .inner {
      gap: 20px;
      .content {
        max-width: calc(50% - 10px);
        gap: 30px;
        strong {
          font-size: 16px;
        }
        .details {
          font-size: 12px;
        }
        button {
          width: 100px;
          height: 30px;
        }
      }
      .img {
        max-width: calc(50% - 10px);
        img {
          max-height: 300px;
          filter: drop-shadow(rgb(153, 153, 153) 15px 15px 10px);
        }
      }
    }
  }
`;
