import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/ko";

import { AgendaContainer } from "../styled/scheduler";

import { solarToLunar } from "../../../../../hooks/useLunar";
import { timeByDate, isEventCategory, calculateDday } from "../function/scheduler";
import { holidayList, weatherStates } from "../function/dateInfo";
import { isOutsideCmpny } from "../function/common";

import HamburgerMenu from "./hamburgerMenu";

const Agenda = ({
  selected,
  toggleAgendaContent,
  isTablet,
  clickEvent,
  onDate,
  setIsBurgerOpen,
  isBurgerOpen,
  isHolidayOpen,
  todayWeather,
}) => {
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);
  const [holidays, setHolidays] = useState([]);
  const user = useSelector((state) => state?.user?.data[0]);

  useEffect(() => {
    const target = isHoliday(onDate);
    setHolidays(target);
  }, [onDate]);

  const changeLunar = (onDate) => {
    const [year, month, day] = moment(onDate).format("YYYY-MM-DD").split("-");
    const lunar = solarToLunar(year, month, day);
    return lunar;
  };

  const isHoliday = (onDate) => {
    const [month, day] = moment(onDate).format("MM-DD").split("-");

    const targets = holidayList.filter((holi) => {
      const isExactDay = holi.month === parseInt(month) && holi.day === parseInt(day);
      const isWithinRange = onDate >= moment(holi.startDt) && onDate <= moment(holi.endDt);

      return isExactDay || isWithinRange;
    });

    return targets.length > 0 ? targets : null;
  };

  const isWeather = (weather) => {
    const state = weatherStates[weather];
    if (!state) return <></>;

    const Icon = state.icon;
    return (
      <>
        <span className="weather">{state.text}</span>
        <Icon style={{ color: state.color }} />
      </>
    );
  };

  const openAdvertisementPage = async () => {
    user && localStorage.setItem("user", JSON.stringify(user));
    window.open(`https://1472.ai/shop/lotteria-daejeon`);
  };

  return (
    <AgendaContainer
      className={`agenda ${isDarkMode}`}
      style={{
        transform: `translateY(${isTablet ? "150%" : "0"})`,
      }}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          toggleAgendaContent(false);
        }
      }}>
      <div className="agendaInner">
        <div className="lunar">
          <div>
            <span className="font600">{moment(onDate).format("MM월DD일 dddd")}</span>
            <span>{changeLunar(onDate)}</span>
          </div>
          {todayWeather && <div className="weather">{isWeather(todayWeather.weather)}</div>}
        </div>
        <ul className="constractList">
          <ul>
            <li className="advertisement" onClick={openAdvertisementPage}>
              <img src="/img/tempAdvertisementLogo.png" alt="" />
              <span>
                롯데리아 전제품 10% 할인
                <br />
                14기 조원선 <small>[AD]</small>
              </span>
            </li>
            {holidays &&
              holidays.length > 0 &&
              holidays.map((holiday, index) => (
                <li key={index} onClick={() => isHolidayOpen(holiday)}>
                  {holiday.icon && (
                    <img
                      src={holiday.icon}
                      alt={holiday.name}
                      style={{ width: 20, height: 20, marginRight: 5 }}
                    />
                  )}
                  <p className="font600">{holiday.name}</p>
                </li>
              ))}
          </ul>
          {selected.length > 0 &&
            selected.map((item, idx) =>
              item.dday !== "Y" ? (
                <li
                  style={{ borderLeft: `5px solid #${item.color}` }}
                  onClick={() => clickEvent(item)}
                  key={idx}
                  className={`${isOutsideCmpny(item, user) ? "outside" : ""}`}>
                  <div style={{ width: "100%" }}>
                    <div className="title">
                      {item.icon && (
                        <img
                          src={`/img/scheduler/scheduler_icon_${item.icon}`}
                          alt=""
                          style={{ width: 20 }}
                        />
                      )}
                      <span
                        style={{
                          textDecoration: item.completeYn === "Y" ? "line-through" : "none",
                          width: item.icon ? "80%" : "100%",
                        }}
                        className="font600 font_16">
                        {item.title}
                      </span>
                    </div>
                    {item.dday === "N" && <p>{timeByDate(onDate, item.startDt, item.endDt)}</p>}
                    <p>
                      <span>{`${isEventCategory(item.gbn)}일정`}</span>
                      {item.gbn !== "P" && <span>: {item.orgNm}</span>}
                    </p>
                  </div>
                </li>
              ) : (
                <li onClick={() => clickEvent(item)} className="dDay">
                  <div>
                    <div className="title">
                      {item.icon && (
                        <img
                          src={`/img/scheduler/scheduler_icon_${item.icon}`}
                          alt=""
                          style={{ width: 20 }}
                        />
                      )}
                      <span
                        style={{
                          textDecoration: item.completeYn === "Y" ? "line-through" : "none",
                        }}
                        className="font600">
                        {item.title}
                      </span>
                    </div>
                    <p>{moment(item.startDt).format("YYYY-MM-DD")}</p>
                  </div>
                  <div>{calculateDday(item.startDt)}</div>
                </li>
              ),
            )}
        </ul>
      </div>
      {isTablet && (
        <HamburgerMenu
          onDate={moment(onDate).format("YYYY-MM-DD hh:00:00")}
          isBurgerOpen={isBurgerOpen}
          setIsBurgerOpen={setIsBurgerOpen}
        />
      )}
    </AgendaContainer>
  );
};

export default Agenda;
