import React from "react";
import { KeyboardContainer } from "../styled/common";

const NumberKeyboard = ({ type = null, inputValue, handleChangeInput }) => {
  const keys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "지우기", "0", "전체삭제"];

  const handleKeyPress = (key) => {
    if (key === "지우기") {
      handleBackspace();
    } else if (key === "전체삭제") {
      handleChangeInput(type, "");
    } else {
      const newValue = inputValue + key;
      handleChangeInput(type, newValue);
    }
  };

  const handleBackspace = () => {
    const newValue = inputValue.slice(0, -1);
    handleChangeInput(type, newValue);
  };

  return (
    <KeyboardContainer>
      <div className="keyboard">
        {keys.map((key, index) => (
          <button key={index} onClick={() => handleKeyPress(key)}>
            {key}
          </button>
        ))}
      </div>
    </KeyboardContainer>
  );
};

export default NumberKeyboard;
