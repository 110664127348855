import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

import { BsCloudPlus, BsGrid, BsMoon, BsSun } from "react-icons/bs";

import { DashboardInfoContainer } from "../styled/dashboard";

import { weatherStates } from "../../../scheduler/component/function/dateInfo";

import QuickLinks from "./dashboardInfo/quickLinks";
import MakeShortcutsModal from "./dashboardInfo/makeShortcutsModal";
import Greeting from "./dashboardInfo/greeting";

import useScrollLock from "../../../../../hooks/useScrollLock";
import useOutsideClick from "../../../../../hooks/useOutsideClick";

const DashboardInfo = ({ changeWidgets, isDragEnabled, setting, updateSetting }) => {
  const menuRef = useRef();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const user = useSelector((state) => state?.user?.data[0]);

  const [todayWeather, setTodayWeather] = useState(null);
  const [isMakeQuickLink, setIsMakeQuickLink] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditable, sestIsEditable] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useScrollLock(isMenuOpen);
  useOutsideClick(menuRef, () => {
    setIsMenuOpen(false);
  });

  useEffect(() => {
    getWeather();
  }, []);

  useEffect(() => {
    console.log(activeIndex);
  }, [activeIndex]);

  const getWeather = async () => {
    const url = "/api/weather";
    const today = moment().format("YYYY-MM-DD");
    const req = {
      startDt: today,
      endDt: today,
    };
    const res = await axios.post(url, req);
    if (res.status === 200) {
      setTodayWeather(res.data[0]);
    }
  };

  const isWeather = (weather) => {
    if (!weather) return null;

    const state = weatherStates[weather?.weather];
    if (!state) return null;

    return (
      <div className="weather">
        <span className="icon">
          <state.icon />
        </span>
        <span>
          {weather?.temMin}℃/{weather?.temMax}℃
        </span>
      </div>
    );
  };

  const clickMakeQuickButton = () => {
    if (isDragEnabled) {
      toast.error("위젯 설정 중에는 변경할 수 없습니다.");
      return;
    }
    setIsMakeQuickLink(true);
  };

  const changeTheme = () => {
    updateSetting({ mode: setting.mode === "dark" ? "white" : "dark" }, "테마가 변경되었습니다.");
  };

  const editQuick = () => {
    sestIsEditable(true);
    setIsMakeQuickLink(true);
  };

  const deleteQuick = async () => {
    const shortcuts = setting.shortcuts.filter((_, idx) => idx !== activeIndex);
    await updateSetting({ shortcuts }, "바로가기가 삭제되었습니다.");

    setIsMenuOpen(false);
    setActiveIndex(null);
  };

  return (
    <DashboardInfoContainer className={setting.mode}>
      {isMakeQuickLink && (
        <MakeShortcutsModal
          setIsMakeQuickLink={setIsMakeQuickLink}
          setting={setting}
          updateSetting={updateSetting}
          activeIndex={activeIndex}
          isEditable={isEditable}
          sestIsEditable={sestIsEditable}
        />
      )}
      <div className="infoContent">
        {isMobile && <Greeting />}
        <QuickLinks
          setting={setting}
          isDragEnabled={isDragEnabled}
          setActiveIndex={setActiveIndex}
          deleteQuick={deleteQuick}
          editQuick={editQuick}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          menuRef={menuRef}
        />
        <div class="overview">
          <span>{moment().format("YYYY년 M월 D일")}</span>
          <div className="buttons">
            <button onClick={changeWidgets}>
              <span className="icon">
                <BsGrid />
              </span>
              <span>{isDragEnabled ? "편집완료" : "위젯설정"}</span>
            </button>
            <button onClick={clickMakeQuickButton}>
              <span className="icon">
                <BsCloudPlus />
              </span>
              <span>바로가기 추가</span>
            </button>
          </div>
          <div className="themeWeatherWidget">
            <button onClick={changeTheme}>
              <span className="icon">{setting.mode === "dark" ? <BsSun /> : <BsMoon />}</span>
              {setting.mode === "dark" ? "라이트모드" : "다크모드"}
            </button>
            {isWeather(todayWeather)}
          </div>
        </div>
      </div>
    </DashboardInfoContainer>
  );
};

export default DashboardInfo;
