import React from "react";
import { PrdSyncDesktopContainer } from "../../styled/productSyncForm";
import { AiOutlineCheck } from "react-icons/ai";
import { CustomInput } from "../../styled/common";
import Pagination from "react-js-pagination";

const ProducSynctDesktop = ({
  products,
  total,
  pages,
  changePages,
  selectedProducts,
  handleCheck,
  handleAllCheck,
  synced,
  categoriesObj,
}) => {
  const isAllChecked =
    products.length > 0 &&
    selectedProducts.length > 0 &&
    products.every((product) => selectedProducts.some((selected) => selected.id === product.id));

  return (
    <PrdSyncDesktopContainer>
      <table>
        <colgroup>
          <col style={{ width: "5%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <thead>
          <tr>
            <th>
              <CustomInput className="block">
                <input
                  type="checkbox"
                  checked={isAllChecked}
                  onChange={(e) => handleAllCheck(e.target.checked)}
                />
                <span className="checkmark"></span>
              </CustomInput>
            </th>
            <th>이미지</th>
            <th>
              <span>제품명</span>
            </th>
            <th>
              <span>브랜드</span>
            </th>
            <th>
              <span>가격</span>
            </th>
            <th>
              <span>카테고리</span>
            </th>
            <th>
              <span>규격</span>
            </th>
            <th>
              <span>연동여부</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {products.length > 0 ? (
            products.map((prd) => (
              <tr>
                <td>
                  <CustomInput className="block">
                    <input
                      type="checkbox"
                      checked={selectedProducts.some((item) => item.id === prd.id)}
                      onChange={() => handleCheck(prd)}
                    />
                    <span className="checkmark"></span>
                  </CustomInput>
                </td>
                <td>
                  <img src={imgHandler(prd?.media, false)} alt="" />
                </td>
                <td>
                  <span
                    className="name"
                    {...(prd?.description && { "data-description": prd.description })}>
                    {prd?.name}
                  </span>
                </td>
                <td>
                  <span className="brand">{prd?.brand}</span>
                </td>
                <td>
                  <span className="price">
                    {prd?.basePrice ? prd?.basePrice.toLocaleString() : 0}원
                  </span>
                </td>
                <td>
                  <span className="category">{categoriesObj[prd?.categoryId]}</span>
                </td>
                <td>
                  <span className="specifications">{prd?.specifications}</span>
                </td>
                <td>
                  {synced?.[prd?.id] && (
                    <span className="sync">
                      <AiOutlineCheck />
                    </span>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}></td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        activePage={pages.page}
        itemsCountPerPage={pages.limit}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={(e) => changePages({ target: "page", value: e })}
      />
    </PrdSyncDesktopContainer>
  );
};

export default ProducSynctDesktop;
