import React, { useEffect, useState } from "react";
import HomepageEdit from "../../homepageEdit";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";
import PreviewModal from "./previewModal";
import { toast } from "react-toastify";

const ThemeSettingForm = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);
  const themes = [
    { key: "A", label: "default", type: 0 },
    { key: "B", label: "chic", type: 1 },
    { key: "C", label: "modern", type: 2 },
    { key: "D", label: "cute", type: 3 },
    { key: "E", label: "simple", type: 4 },
  ];
  const [data, setData] = useState({
    bannerImgList: null,
    bannerImgTextList: null,
    cmpnyNo: null,
    type: 0,
  });
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    getMySetting();
  }, []);

  const getMySetting = async () => {
    const url = "/api/firstSettingLoad";
    const body = {
      cmpnyNo: user.cmpnyNo,
      delYn: "N",
    };
    try {
      const res = await axios.post(url, body);
      console.log(res);
      if (res.status === 200) {
        setData({ ...res.data, type: res.data.type ? res.data.type : 0 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeTheme = (e) => {
    const { value } = e.target;
    setData((data) => ({ ...data, type: parseInt(value, 10) }));
  };

  const handleSubmit = async () => {
    const url = "/api/firstSettingSave";
    const body = { ...data, createMberId: user.userName, updateMberId: user.userName };

    try {
      const res = await axios.put(url, body);
      if (res.status === 200) {
        console.log(res);
        toast.success("테마가 변경되었습니다.");
        navigate("/mypage/register");
      }
    } catch (error) {
      toast.error("잠시 후 다시 시도해주세요.");
    }
  };

  return (
    <div className="inner_content_wrap">
      <HomepageEdit />
      <div id="step1_html">
        <div className="writing_ty2">
          <ul>
            {themes.map((item) => (
              <li>
                <div className="themeInfo">
                  <label className="btn_radio">
                    <input
                      type="radio"
                      value={item.type}
                      checked={data.type === item.type}
                      onChange={handleChangeTheme}
                    />
                    <span>{item.key}</span>
                  </label>
                </div>
                <div className="preview" onClick={() => setPreview(item)}>
                  <img src={`/img/shopTheme/shop_${item.label}.png`} alt={item.label} />
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="btnarea_center pdt_40 hp_step_btn">
          <button className="btn_normal_save" style={{ border: "none" }} onClick={handleSubmit}>
            <span>저장하고 다음 단계</span>
          </button>
        </div>
      </div>
      {preview && <PreviewModal preview={preview} setPreview={setPreview} />}
    </div>
  );
};

export default ThemeSettingForm;
