import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setFunctionIndex } from "../../store/compoIndex";

export default function FunctionBanner() {
  const dispatch = useDispatch();

  return (
    <div className="sub_top" id="service">
      <span className="font_22 font300 pdb_20 font_color_white">{service.function}</span>
      <span className="font_72 font_color_1 font700 pdb_70">{service.functionE}</span>
        <ul className="sub_menu">
          {subMenu.map((item, i) =>
            <li key={i}><Link onClick={() => dispatch(setFunctionIndex(i))} to={"/function"} >{item.label}</Link></li>
          )}
        </ul>
    </div>
  )
}