import ReactModal from "react-modal";

const Modal = ({ modalState, handleModalState, html, w, h, top, isDarkMode = "" }) => {
  return (
    <ReactModal
      isOpen={modalState}
      onRequestClose={handleModalState}
      className={"hp_step_setting_wrap_content"}
      ariaHideApp={false}
      style={{
        overlay: {
          width: "100%",
          zIndex: "9999",
          background: isDarkMode ? "rgba(0, 0, 0, 0.75)" : "rgba(255, 255, 255, 0.75)",
        },
        content: {
          position: "absolute",
          top: top ? top : "50%",
          left: "50%",
          width: w,
          height: h,
          borderRadius: "4px",
          outline: "none",
          border: `1.5px solid ${isDarkMode ? "#000" : "#eee"}`,
          background: isDarkMode && "#2b2b2b",
        },
      }}>
      {html}
    </ReactModal>
  );
};

export default Modal;
