import React, { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import { LocatorSwiperContainer } from "../../styled/shop";
import { GoChevronRight } from "react-icons/go";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

const ShopLocatorSwiper = ({
  images,
  type,
  swiperRef = null,
  imageHeight = null,
  updateImageSize = null,
}) => {
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const isComponentType = (arr) => {
    if (type === "restaurant") return arr[0];
    if (type === "store") return arr[1];
    if (type === "group") return arr[2];
  };

  const modules = [...isComponentType([[Autoplay], [], [Pagination]])];

  const renderInfo = () => {
    return isComponentType([
      <>
        <div className="tit">리틀포레스트</div>
        <span>대전 둔산동 1129-1</span>
        <span>대표자번호: 010-1234-5678</span>
      </>,
      <>
        <div className="tit">리틀포레스트</div>
        <span>설명설명입니다.</span>
      </>,
      <>
        <div className="tit">
          <div className="grpName">
            <span>한사랑 산악회</span>
            <button>
              <GoChevronRight />
            </button>
          </div>
        </div>
        <span className="grpDesc">산행을 좋아하는 사람끼리 만든 모임</span>
      </>,
    ]);
  };

  const isSwiperButton = () => {
    return type === "store" && !isMobile && !isTablet;
  };

  const imageResize = (index) => {
    if (updateImageSize && index === 0) {
      updateImageSize();
    }
  };

  const handleSwiperButton = (isPrev) => {
    if (swiperRef.current) {
      if (isPrev) {
        swiperRef.current.swiper.slidePrev();
      } else {
        swiperRef.current.swiper.slideNext();
      }
    }
  };

  return (
    <LocatorSwiperContainer>
      {isSwiperButton() && (
        <button className="swiperButons prev" onClick={() => handleSwiperButton(true)}>
          <FaChevronLeft />
        </button>
      )}
      <Swiper
        key={type}
        loop={true}
        ref={swiperRef}
        modules={modules}
        autoplay={{ delay: isComponentType([0, 1000, 1000]), disableOnInteraction: false }}
        spaceBetween={isComponentType([20, 20, 20])}
        className={`mySwiper ${type}`}
        slidesPerView={isComponentType([7, 1, 1])}
        pagination={{ clickable: true }}
        speed={isComponentType([6000, 500, 500])}
        centeredSlides={false}
        breakpoints={{
          0: {
            slidesPerView: isComponentType([1.5, 2, 1]),
          },
          767: {
            slidesPerView: isComponentType([5, 4, 2]),
          },
          1050: {
            slidesPerView: isComponentType([7, 5, 3.2]),
          },
        }}>
        {images.map((item, index) => (
          <SwiperSlide key={`shop_${type}_${index}`}>
            {type === "store" ? (
              <>
                <div className="slideContent">
                  <div className="imageArea" style={{ height: imageHeight }}>
                    <img src={`/img/prdct_0${item[0] + 1}.png`} />
                  </div>
                  <div className="infoArea">{renderInfo()}</div>
                </div>
                <div className="slideContent">
                  <div
                    className="imageArea"
                    style={{ height: isComponentType([null, imageHeight, null]) }}>
                    <img src={`/img/prdct_0${item[1] + 1}.png`} onLoad={() => imageResize(index)} />
                  </div>
                  <div className="infoArea">{renderInfo()}</div>
                </div>
              </>
            ) : (
              <div className="slideContent">
                <div className="imageArea">
                  <img src={`/img/prdct_0${item + 1}.png`} onLoad={() => imageResize(index)} />
                </div>
                <div className="infoArea">{renderInfo()}</div>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {isSwiperButton() && (
        <button className="swiperButons next" onClick={() => handleSwiperButton(false)}>
          <FaChevronRight />
        </button>
      )}
    </LocatorSwiperContainer>
  );
};

export default ShopLocatorSwiper;
