import MypageBanner from "../mypageBanner";
import PromotionForm from "./component/promotion/promotionForm";
import HomePageSettingMenu from "./homepageSettingMenu";

export default function Promotion() {
  return (
    <div>
      <MypageBanner />
      <div className="inner_content">
        <HomePageSettingMenu />
        <PromotionForm />
      </div>
    </div>
  );
}
