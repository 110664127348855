export const parseHTMLContent = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const image = doc.querySelector("img")?.src || null;
  doc.querySelectorAll("figure, img").forEach((el) => el.remove());

  const text = doc.body.innerHTML;

  return { image, text };
};
