import React, { useRef, useState } from "react";
import { OrderListContainer, SalesListTable } from "../../../styled/salesCheck";
import { IoMdMore } from "react-icons/io";
import { CustomInput } from "../../../styled/common";
import { useMediaQuery } from "react-responsive";
import OrderItem from "./orderItem";
import { FaChevronRight } from "react-icons/fa";
import Pagination from "react-js-pagination";

const OrderList = ({ data = [], current, setCurrent, totalElements }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const contentRefs = useRef([]);
  const [selected, setSelected] = useState(null);

  const toggleItem = async (item) => {
    if (selected?.id === item.id) {
      setSelected(null);
    } else {
      setSelected(item);
    }
  };

  return (
    <OrderListContainer>
      <ul className="orders">
        <li className="header">
          <div className="toggle"></div>
          <div className="index">주문번호</div>
          <div className="status">주문상태</div>
          <div className="createdAt">주문날짜</div>
          <div className="price">주문금액</div>
        </li>
        {data.length === 0 ? (
          <li className="none">주문내역이 존재하지 않습니다.</li>
        ) : (
          data.map((item, index) => (
            <OrderItem
              item={item}
              index={index}
              contentRefs={contentRefs}
              toggleItem={toggleItem}
              selected={selected}
            />
          ))
        )}
      </ul>
      <Pagination
        activePage={current.page}
        itemsCountPerPage={8}
        totalItemsCount={totalElements}
        pageRangeDisplayed={3}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={(page) => setCurrent({ page: page, pageSize: 8 })}
      />
    </OrderListContainer>
  );
};

export default OrderList;
