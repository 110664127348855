import { IoMdAdd, IoMdClose } from "react-icons/io";
import {
  ProjectCloseButton,
  ProjectCreateContainer,
  ProjectSection,
} from "./component/common/styled/project";
import { GrTemplate } from "react-icons/gr";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";

export default function ProjectCreate() {
  const navigate = useNavigate();
  const location = useLocation();
  const eventInfo = location?.state?.eventInfo;

  return (
    <ProjectSection>
      <ProjectCloseButton
        onClick={() =>
          eventInfo
            ? navigate("/mypage/calendarEntry", { state: { eventInfo } })
            : navigate("/mypage", { state: { slideIndex: 1 } })
        }>
        <IoMdClose />
      </ProjectCloseButton>
      <ProjectCreateContainer>
        <div className="inner">
          <div className="info">
            <span>프로젝트 만들기</span>
            <p>프로젝트를 어떻게 만들지 선택해보세요.</p>
          </div>
          <div className="selectButtons">
            <button
              onClick={() =>
                navigate("/mypage/projectCreateSec", eventInfo && { state: { eventInfo } })
              }>
              <div className="icon">
                <IoMdAdd />
              </div>
              <span>직접 만들기</span>
            </button>
            <button onClick={() => alert("준비 중입니다.")}>
              <div className="icon">
                <GrTemplate />
              </div>
              <span>템플릿 선택하기</span>
            </button>
          </div>
        </div>
      </ProjectCreateContainer>
    </ProjectSection>
  );
}
