import React from "react";
import { SearchBannerContainer } from "../../styled/shop";

const ShopSearchBanner = () => {
  const banner = Array.from({ length: 3 }, (_, idx) => idx);
  return (
    <SearchBannerContainer>
      <ul>
        {banner.map((item, index) => (
          <li key={`banner_${index}`}>
            <img src={`/img/prdct_0${item + 1}.png`} />
          </li>
        ))}
      </ul>
    </SearchBannerContainer>
  );
};

export default ShopSearchBanner;
