import { useState } from "react";
import { useOutletContext } from "react-router";

import DefaultTheme from "./component/defaultTheme";
import ChicTheme from "./component/chicTheme";
import ModernTheme from "./component/modernTheme";
import CuteTheme from "./component/cuteTheme";
import SimpleTheme from "./component/simpleTheme";

export default function ShoppingPage() {
  const { theme } = useOutletContext();

  const renderComponent = () => {
    switch (theme) {
      case "A":
        return <DefaultTheme />;
      case "B":
        return <ChicTheme />;
      case "C":
        return <ModernTheme />;
      case "D":
        return <CuteTheme />;
      case "E":
        return <SimpleTheme />;
      default:
        return <DefaultTheme />;
    }
  };

  return <div>{renderComponent()}</div>;
}
