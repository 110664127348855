import React from "react";
import { useNavigate } from "react-router";
import { ContentNavigation } from "../../common/styled/project";
import { projectMenus } from "../../common/function/project";

const ProjectDetailTabMenu = ({ tabChange }) => {
  const navigate = useNavigate();

  const clickEvent = (name, idx) => {
    if (name === "리포트") {
      alert("준비 중입니다.");
      return;
    }
    tabChange(idx);
  };

  return (
    <ContentNavigation>
      {projectMenus.map((item, idx) => (
        <li onClick={() => clickEvent(item.name, idx)}>
          <item.icon />
          <span>{item.name}</span>
        </li>
      ))}
    </ContentNavigation>
  );
};

export default ProjectDetailTabMenu;
