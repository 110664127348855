import React from "react";
import { List } from "../styled/common";
import { formatDate, isPayType, isPlatform, isStorageType, resStatus } from "../function/kiosk";
import { useRegex } from "../../../../../hooks/useRegex";

const KioskList = ({ data = [], type, handleModalOpen = null }) => {
  const { TelFormat } = useRegex();
  const isResStatus = type === "resStatus";

  const ListItem = ({ item }) => (
    <li>
      <ul>
        <li>
          <span className="tit">예약번호</span>
          <span>{item.id}</span>
        </li>
        <li>
          <span className="tit">이름</span>
          <span>{item.mberNm}</span>
        </li>
        <li>
          <span className="tit">전화번호</span>
          <span>{TelFormat(item.tel)}</span>
        </li>
        <li>
          <span className="tit">플랫폼</span>
          <span>{isPlatform(item?.os)}</span>
        </li>
        {isResStatus && (
          <>
            <li>
              <span className="tit">가격</span>
              <span>{item.price ? item.price.toLocaleString() : 0}원</span>
            </li>
            <li>
              <span className="tit">{type === "resStatus" ? "픽업상태" : "결제상태"}</span>
              <span
                className="status"
                style={{
                  color: resStatus[item.reservationStatus]?.color,
                  borderColor: resStatus[item.reservationStatus]?.color,
                }}>
                {resStatus[item.reservationStatus]?.label}
              </span>
            </li>
          </>
        )}
        <li>
          <span className="tit">보관날짜</span>
          <span>{item.reservationDay}</span>
        </li>
        <li>
          <span className="tit">보관시작시간</span>
          <span>{item.reservationStartTime ? item.reservationStartTime : ""}</span>
        </li>
        <li>
          <span className="tit">메모</span>
          <span>{item.memo ? item.memo : ""}</span>
        </li>
      </ul>
      {!isResStatus ? (
        <button className="statusButton" onClick={() => handleModalOpen("COMPLETED", item)}>
          관리
        </button>
      ) : (
        <button className="statusButton" onClick={() => handleModalOpen(item)}>
          상세보기
        </button>
      )}
    </li>
  );

  return (
    <List
      className={type ? "pickUp" : "resStatus"}
      style={{
        height: data.length > 0 ? "auto" : "100%",
      }}>
      {data.length > 0 ? (
        data.map((item) => <ListItem key={item.id} item={item} />)
      ) : (
        <li className="notUser">사용자가 존재하지 않습니다.</li>
      )}
    </List>
  );
};

export default KioskList;
