import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import {
  NotMessage,
  ProjectCheckBoxLabel,
  ProjectListViewContainer,
} from "../common/styled/project";
import { PiUserSquare } from "react-icons/pi";
import { BsPin, BsPinAngleFill } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { toast } from "react-toastify";
import { hideLoading, showLoading } from "../../../../../store/loading";
import Loading from "../../../../../common/loading";
import { isCategory, isColor } from "../common/function/project";
import { MdMenu } from "react-icons/md";
import { LuLayoutGrid } from "react-icons/lu";

const ProjectListView = ({ setShowSideMenu, showSideMenu, clickSideMenu, setClickSideMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isIneer = useMediaQuery({ query: "(max-width:1400px)" });
  const user = useSelector((state) => state?.user?.data[0]);
  const isLoading = useSelector((state) => state?.loading?.isLoading);
  const [projects, setProjects] = useState({});
  const [originProjects, setOriginProjects] = useState({});
  const [hasProjects, setHasProjects] = useState();
  const [isProjectsOpen, setIsProjectsOpen] = useState({
    fixedProjectList: true,
    cmpnyProjectList: true,
    otherProjectList: true,
    groupProjectList: true,
  });
  const [isDoneProjects, setIsDoneProjects] = useState(false);
  const [searched, setSearched] = useState("");

  useEffect(() => {
    if (user) {
      getProjectList();
    }
  }, []);

  const calcHeight = (length) => {
    const itemHeight = 128;
    const gap = 20;

    if (!length) return 0;

    const lines = Math.ceil(length / (isMobile ? 2 : isTablet ? 3 : isIneer ? 4 : 5));
    return lines * itemHeight + (lines - 1) * gap;
  };

  // 전체 프로젝트 조회
  const getProjectList = async (completeYn = "N") => {
    // dispatch(showLoading());
    const url = "/api/projectList";
    const body = {
      mberNo: user.mberNo,
      completeYn,
      delYn: "N",
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      const data = res.data;

      setProjects(data);
      setOriginProjects(data);
      setHasProjects(
        Object.values(data).some(
          (projectList) => Array.isArray(projectList) && projectList.length > 0,
        ),
      );

      dispatch(hideLoading());
    }
  };

  const handleSearch = () => {
    const filtered = {};

    for (const key in originProjects) {
      if (originProjects.hasOwnProperty(key)) {
        const projectList = originProjects[key];
        if (Array.isArray(projectList)) {
          filtered[key] = projectList.filter((project) => project.projectNm.includes(searched));
        }
      }
    }

    setProjects(filtered);
    setHasProjects(
      Object.values(filtered).some(
        (projectList) => Array.isArray(projectList) && projectList.length > 0,
      ),
    );
  };

  // 프로젝트 즐겨찾기
  const projectPinned = async (e, project) => {
    // dispatch(showLoading());
    const state = project.pinnedYn === "Y";

    e.stopPropagation();
    if (project.completeYn === "Y") {
      toast.error("해당 프로젝트는 변경할 수 없습니다.");
      return;
    }

    const url = "/api/participants";
    const body = {
      projectParticipantNo: project.projectParticipantNo,
      projectNo: project.projectNo,
      gbn: project.gbn,
      mberNo: user.mberNo,
      orgNo: project.gbn === "C" ? user.cmpnyNo : project.orgNo,
      partiType: project.partiType,
      pinnedYn: state ? "N" : "Y",
      delYn: "N",
      updateMberId: user.userName,
    };

    const res = await axios.put(url, [body]);

    if (res.status === 200) {
      getProjectList();
      toast.success(`즐겨찾기가 ${state ? "취소" : "등록"}되었습니다.`);
    }
  };

  const handleDoneProject = (e) => {
    const { checked } = e.target;
    setIsDoneProjects(checked);
    getProjectList(checked ? null : "N");
  };

  return (
    <ProjectListViewContainer>
      <div className="contentInfo">
        <div className="projectListHeader">
          <div className="info">
            내 프로젝트
            <div>
              <ProjectCheckBoxLabel>
                <span>완료된 프로젝트 포함</span>
                <input type="checkbox" checked={isDoneProjects} onChange={handleDoneProject} />
              </ProjectCheckBoxLabel>
            </div>
          </div>
          <div className="search">
            <input
              type="text"
              placeholder="프로젝트명을 입력해주세요."
              onChange={(e) => setSearched(e.target.value)}
              onKeyDown={(e) => handleEnterKey(e, handleSearch)}
            />
            <button>검색</button>
          </div>
        </div>
        <div className="sideMenuButtons">
          {isTablet && (
            <button className="sideMenuButton" onClick={() => setShowSideMenu(!showSideMenu)}>
              <MdMenu />
            </button>
          )}
          {isMobile && (
            <button className="sideMenuButton" onClick={() => setClickSideMenu(!clickSideMenu)}>
              <LuLayoutGrid />
            </button>
          )}
        </div>
      </div>
      <div className="contentsLayer" style={{ height: hasProjects ? "auto" : "calc(100% - 70px)" }}>
        {hasProjects ? (
          Object.keys(projects).map((gbn) => (
            <div key={gbn} className="projectContent">
              <div className="projectContentInfo">
                <span>{isCategory(gbn)}</span>
                {projects[gbn] && projects[gbn].length > 0 && (
                  <button
                    className="toggleOpenButton"
                    onClick={() =>
                      setIsProjectsOpen({ ...isProjectsOpen, [gbn]: !isProjectsOpen[gbn] })
                    }>
                    {isProjectsOpen[gbn] ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </button>
                )}
              </div>
              <ul style={{ height: isProjectsOpen[gbn] ? calcHeight(projects[gbn].length) : 0 }}>
                {projects[gbn].map((project, index) => (
                  <li
                    key={index}
                    style={{ borderLeft: `7px solid ${isColor(gbn)}` }}
                    onClick={() => navigate(`/mypage/project/${project.projectNo}`)}>
                    {project.projectParticipantNo && (
                      <button
                        className="favoritesButton"
                        onClick={(e) => projectPinned(e, project)}>
                        {gbn.includes("fixed") ? (
                          <BsPinAngleFill style={{ color: "#e72e2e" }} />
                        ) : (
                          <BsPin />
                        )}
                      </button>
                    )}
                    <p>{project.projectNm}</p>
                    <div className="projectInfoFooter">
                      <div className="userLength">
                        <PiUserSquare />
                        <span>{project.partiCnt}</span>
                      </div>
                      {project.unReadMsgCnt > 0 && (
                        <div className="unReadMsgCnt">
                          {project.unReadMsgCnt > 99 ? "99+" : project.unReadMsgCnt}
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <NotMessage>프로젝트가 존재하지 않습니다.</NotMessage>
        )}
      </div>
      {isLoading && <Loading />}
    </ProjectListViewContainer>
  );
};

export default ProjectListView;
