import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";

import { onClickLogout } from "../../../../../store/user";
import { resetSetting } from "../../../../../store/scheduler";

import Modal from "../../../../../common/modal";
import axios from "axios";
import { toast } from "react-toastify";

const MembershipWithdrawal = () => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const user = useSelector((state) => state?.user?.data[0]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const onSubmit = async () => {
    const url = "/api/mber";
    const body = {
      mberNo: user.mberNo,
      cmpnyNo: user.cmpnyNo,
      username: user.userName,
      // password:'',
      mberNm: user.name,
      email: user.email,
      createMberId: user.userName,
      updateMberId: user.userName,
      delYn: "Y",
    };

    const res = await axios.put(url, body);
    if (res.data.success) {
      dispatch(onClickLogout());
      dispatch(resetSetting());
      toast.success("회원 탈퇴가 완료되었습니다.");
      navigate("/login");
    }
  };

  const DeleteModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">회원 탈퇴</h2>
        <button onClick={handleModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className={`font_${isMobile ? 20 : 18} font300 font_color_gray pdt_20 text_center`}>
          회원 탈퇴를 하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={onClickModalCheck}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const handleModal = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  const onClickModalCheck = async () => {
    setModalOpen(false);
    navigate("/login");
    onSubmit();
  };

  return (
    <>
      <Modal
        modalState={modalOpen}
        handleModalState={handleModal}
        html={DeleteModalForm()}
        w="300px"
        h="auto"
      />
      <div className="writing_ty2 m_join_wrap">
        <div className="step_tit_bar m_join">
          <span className="font_32 font600 font_color_1 pdb_20">회원탈퇴</span>
          <span className="font_16 font_color_gray font300 text_left pdb_20">
            ※ 탈퇴 전 확인하세요.
          </span>
        </div>
        <div className="writing_list withdrawal">
          <span>탈퇴하시면 일정, 디데이, 프로필 등</span>
          <span>
            모든 데이터가 <strong>삭제</strong>되며 복구가 <strong>불가능</strong>합니다.
          </span>
        </div>
        <div className="btnarea_center mt20">
          <button
            className="btn_normal"
            style={{ border: "none" }}
            onClick={() => setModalOpen(true)}>
            <span>탈퇴하기</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default MembershipWithdrawal;
