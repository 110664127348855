import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";

import { MdOutlineNavigateNext } from "react-icons/md";

import Modal from "../../../../../../common/modal";
import ImageDetailViewer from "../../../../../../common/imageDetailViewer";
import { ProjectDetailFiles, ProjectDetailSection } from "../../common/styled/project";

const ProjectDetailFile = ({ project }) => {
  const [folderState, setFolderState] = useState("");
  const [files, setFiles] = useState({ I: {}, V: {}, F: {} });
  const [counts, setCounts] = useState({});
  const { projectNo } = useParams();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isDetail, setIsDetail] = useState(false);
  const [imageOrder, setImageOrder] = useState([]);
  const [fileHeight, setFileHeight] = useState();
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();

  useEffect(() => {
    getFiles();
  }, []);

  const updateImageSize = () => {
    const previewWidth = document.querySelector(".fileItem")?.offsetWidth;
    setFileHeight(previewWidth);
  };

  useEffect(() => {
    updateImageSize();
    window.addEventListener("resize", updateImageSize);
    return () => {
      window.removeEventListener("resize", updateImageSize);
    };
  }, []);

  // 파일 조회
  const getFiles = async () => {
    const url = "/api/fileList";
    const body = {
      projectNo,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      splitFiles(res.data);
    }
  };

  // 이미지, 동영상, 파일 분리
  const splitFiles = async (data) => {
    const splitData = { I: {}, V: {}, F: {} };
    const count = { I: 0, V: 0, F: 0 };

    data.forEach((file) => {
      if (splitData.hasOwnProperty(file.fileType)) {
        const date = moment(file.createDt).format("YYYY-MM-DD");
        if (!splitData[file.fileType][date]) {
          splitData[file.fileType][date] = [];
        }
        splitData[file.fileType][date].push(file);
        count[file.fileType] += 1;
      }
    });

    setCounts(count);
    setFiles(splitData);
  };

  // 사진, 동영상, 파일 폴더 선택
  const changeFolder = (newFolder) => {
    setFolderState(newFolder);
    setSelectedFiles([]);
  };

  const folders = [
    { key: "I", name: "사진" },
    { key: "V", name: "동영상" },
    { key: "F", name: "파일" },
  ];

  const isFolderState = () => {
    if (!project.projectNm) return null;
    if (folderState) {
      const name = folders.find((f) => f.key === folderState)?.name;

      return (
        <>
          <span onClick={revertToPreviousFolder} style={{ cursor: "pointer" }}>
            {project.projectNm}
          </span>
          <span className="icon">
            <MdOutlineNavigateNext />
          </span>
          <span>{name}</span>
        </>
      );
    } else {
      return <span>{project.projectNm}</span>;
    }
  };

  const isFileType = (item) => {
    if (folderState === "I") {
      return <img src={imgHandler(item.filePath, false)} onLoad={updateImageSize} />;
    }
    if (folderState === "F") {
      return (
        <>
          <img
            src="/img/file.png"
            onLoad={updateImageSize}
            style={{ height: `calc(100% - 18px)` }}
          />
          <div className="tit">{item.fileNm}</div>
        </>
      );
    }
    if (folderState === "V") {
      return <img src="/img/video.png" onLoad={updateImageSize} />;
    }
  };

  // 파일 선택/해제
  const toggleFileItem = (file) => {
    setSelectedFiles((item) => {
      if (item.includes(file)) {
        return item.filter((item) => item !== file);
      } else {
        return [...item, file];
      }
    });
  };

  // 전체 파일 선택/해제
  const handleAllCheckboxClick = (data, isChecked) => {
    const allFiles = data.flat();
    if (isChecked) {
      setSelectedFiles((prevSelectedFiles) => [...new Set([...prevSelectedFiles, ...allFiles])]);
    } else {
      setSelectedFiles((prevSelectedFiles) =>
        prevSelectedFiles.filter((file) => !allFiles.includes(file)),
      );
    }
  };

  const handleModalOpen = (type, item = null) => {
    setModalContent({ type, item });
    setModalOpen(true);
  };

  // 모달
  const ModalForm = ({ type, item }) => {
    const modalDetails = {
      portfolio: {
        title: "포트폴리오",
        body: "포트폴리오로 전송하시겠습니까?",
        onConfirm: () => navigate("/mypage/registerPhotoUpload", { state: { images: item } }),
      },
      default: {
        title: "오류",
        body: "알 수 없는 오류가 발생했습니다.",
        onConfirm: () => setModalOpen(false),
      },
    };

    const modalInfo = modalDetails[type] || modalDetails.default;

    return (
      <div>
        <h2 className="font_20 font500 font_color_white">{modalInfo.title}</h2>
        <button onClick={() => setModalOpen(false)} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">{modalInfo.body}</span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={modalInfo.onConfirm}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  // 뒤로가기
  const revertToPreviousFolder = () => {
    setFolderState("");
    setSelectedFiles([]);
  };

  // 이미지 클릭
  const handleImageClick = (item) => {
    if (folderState === "F") return;

    setIsDetail(true);

    const flatImages = Object.values(files[folderState]).flat(2);
    const index = flatImages.findIndex((image) => image.projectFileNo === item.projectFileNo);

    if (index === -1) return;

    const images = flatImages.map((item) => ({
      path: item.filePath,
      createNm: item.mberNm,
      createDt: item.createDt,
    }));

    const reorderedImages = [...images.slice(index, images.length), ...images.slice(0, index)];
    setImageOrder(reorderedImages);
  };

  return (
    <ProjectDetailSection>
      <ProjectDetailFiles>
        <div className="filesHeader">
          <div className="filePath">{isFolderState()}</div>
          {folderState && selectedFiles.length > 0 && (
            <div className="fileButtons">
              {folderState === "I" && (
                <button onClick={() => handleModalOpen("portfolio", selectedFiles)}>
                  포트폴리오 전송
                </button>
              )}
              <button onClick={() => alert("준비 중입니다.")}>내려받기</button>
            </div>
          )}
        </div>
        <ul className="folders">
          {folders.map((item) => (
            <li
              key={item.key}
              onClick={() => changeFolder(item.key)}
              className={item.key === folderState ? "active" : ""}>
              <span>{item.name}</span>
              {counts[item.key] > 0 && <i>&#40;{counts[item.key]}&#41;</i>}
            </li>
          ))}
        </ul>
        <div className="filesContainer">
          {files[folderState] &&
            Object.entries(files[folderState]).map(([date, data]) => (
              <div key={date} className="dateGroup">
                <div className="dateHeader">
                  <input
                    type="checkbox"
                    checked={data.every((item) => selectedFiles.includes(item))}
                    onChange={(e) => handleAllCheckboxClick(data, e.target.checked)}
                  />
                  <span>{date}</span>
                </div>
                {data.length > 0 && (
                  <ul className="fileGroup">
                    {data.map((item, idx) => (
                      <li
                        key={idx}
                        className="fileItem"
                        data-path={item.filePath}
                        style={{ height: fileHeight }}
                        onClick={() => handleImageClick(item)}>
                        <div className="preview">{isFileType(item)}</div>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFileItem(item);
                          }}
                          className="selected">
                          {selectedFiles.includes(item) && <i>{selectedFiles.indexOf(item) + 1}</i>}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
        </div>
        <Modal
          modalState={modalOpen}
          handleModalState={() => setModalOpen(false)}
          html={<ModalForm type={modalContent?.type} item={modalContent?.item} />}
          w="300px"
          h="auto"
        />
        {isDetail && <ImageDetailViewer images={imageOrder} setIsDetail={setIsDetail} />}
      </ProjectDetailFiles>
    </ProjectDetailSection>
  );
};

export default ProjectDetailFile;
