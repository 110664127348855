import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getSetting } from "../../../../../../store/scheduler";
import SideMenuChoiceOption from "./sideMenuChoiceOption";

const SideMenuChoiceList = () => {
  const dispatch = useDispatch();
  const { mySchedSet, isDarkMode } = useSelector((state) => state?.scheduler);
  const [isOpen, setIsOpen] = useState({
    companyYnList: true,
    groupYnList: true,
  });

  // 체크박스 전체 선택
  const selectAllCheck = (name) => {
    const updatedItems = mySchedSet[name].map((item) => ({
      ...item,
      check: !mySchedSet[name].every((it) => it.check),
    }));
    const updateSet = { ...mySchedSet, [name]: updatedItems };

    updateSchedulerSetting(updateSet);
  };

  // 체크박스 리스트 상세보기
  const changeIsOpen = (category) => {
    setIsOpen((state) => ({ ...state, [category]: !state[category] }));
  };

  // 체크박스 변경
  const changeInput = (e) => {
    const { id, name, type } = e.target;
    let updateSet = { ...mySchedSet };

    switch (type) {
      case "radio":
        const updateId = id.split("-")[1];
        updateSet[name] = updateId;
        break;

      case "checkbox":
        if (name === "personalYn") {
          updateSet[name] = !updateSet[name];
        } else {
          const orgNo = parseInt(id);
          updateSet[name] = updateSet[name].map((item) =>
            item.orgNo === orgNo ? { ...item, check: !item.check } : item,
          );
        }
        break;

      default:
        break;
    }

    updateSchedulerSetting(updateSet);
  };

  const updateSchedulerSetting = async (updateSet) => {
    const url = "/api/toktokSetting";
    const body = { ...updateSet };

    try {
      const res = await axios.put(url, body);
      if (res.status === 200) {
        dispatch(getSetting(body));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const completeYnOptions = [
    { id: "completeYn-D", label: "전체", value: "D" },
    { id: "completeYn-Y", label: "완료", value: "Y" },
    { id: "completeYn-N", label: "미완료", value: "N" },
  ];

  const viewModeOptions = [
    { id: "viewMode-D", label: "일정별", value: "D" },
    { id: "viewMode-C", label: "유형별", value: "C" },
  ];

  return (
    <div className="choiceListArea">
      <div
        className="choiceContainer font_16"
        style={{
          borderBottom: `1px solid ${isDarkMode ? "#2b2b2b" : "#efefef"}`,
        }}>
        <div className="choiceHead font_22 font700">완료 일정 선택</div>
        <div className="radioContainer">
          <div className="tabs">
            {completeYnOptions.map((option) => (
              <React.Fragment key={option.id}>
                <input
                  type="radio"
                  id={option.id}
                  name="completeYn"
                  checked={mySchedSet.completeYn === option.value}
                  onChange={changeInput}
                />
                <label className="tab" htmlFor={option.id}>
                  {option.label}
                </label>
              </React.Fragment>
            ))}
            <span className="glider"></span>
          </div>
        </div>
      </div>
      <div
        className="choiceContainer font_16"
        style={{
          borderBottom: `1px solid ${isDarkMode ? "#2b2b2b" : "#efefef"}`,
        }}>
        <div className="choiceHead font_22 font700">달력 유형 선택</div>
        <div className="calendarViewContainer">
          <div className="tabs">
            {viewModeOptions.map((option) => (
              <React.Fragment key={option.id}>
                <input
                  type="radio"
                  id={option.id}
                  name="viewMode"
                  checked={mySchedSet.viewMode === option.value}
                  onChange={changeInput}
                />
                <label className="tab" htmlFor={option.id}>
                  {option.label}
                </label>
              </React.Fragment>
            ))}
            <span className="glider"></span>
          </div>
        </div>
      </div>
      <div className="choiceContainer font_16">
        <div className="choiceHead font_22 font700">일정 유형 선택</div>
        <ul className="choiceList">
          <li className="personalYn">
            <label htmlFor="personalYn">
              <input
                type="checkbox"
                checked={mySchedSet.personalYn}
                name="personalYn"
                id="personalYn"
                onChange={changeInput}
              />
              개인
            </label>
          </li>
          {Array.isArray(mySchedSet.companyYnList) && mySchedSet.companyYnList.length > 0 && (
            <SideMenuChoiceOption
              label="전체 업무"
              name="companyYnList"
              items={mySchedSet.companyYnList}
              isOpen={isOpen}
              changeIsOpen={changeIsOpen}
              changeInput={changeInput}
              selectAllCheck={selectAllCheck}
            />
          )}
          {Array.isArray(mySchedSet.groupYnList) && mySchedSet.groupYnList.length > 0 && (
            <SideMenuChoiceOption
              label="전체 모임"
              id="check2"
              name="groupYnList"
              items={mySchedSet.groupYnList}
              isOpen={isOpen}
              changeIsOpen={changeIsOpen}
              changeInput={changeInput}
              selectAllCheck={selectAllCheck}
            />
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideMenuChoiceList;
