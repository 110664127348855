import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "../../../shopping.css";

const DefaultHeader = ({
  headerFixed,
  cmpnyNm,
  logo,
  menus,
  links,
  handleHome,
  homepageInfo,
  handleLogoutModal,
  url,
  setClickSideMenu,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);

  const isInner = useMediaQuery({ query: "(max-width:1400px)" });

  return (
    <header
      style={
        headerFixed
          ? { position: "fixed", boxShadow: "0px 7px 7px rgba(0,0,0,0.1)" }
          : { position: "absolute" }
      }>
      <div className="hd_wrap boundary" style={{ padding: isInner ? "0 10px" : 0 }}>
        {homepageInfo ? (
          <>
            {logo ? (
              <h1 className="logo_wrapper">
                <img className="logo logo_img" src={logo} onClick={handleHome} />
              </h1>
            ) : (
              <h1 className="logo_text cmpnyNm" onClick={handleHome}>
                {homepageInfo?.logoText ?? cmpnyNm}
              </h1>
            )}
          </>
        ) : (
          <h1 className="logo_wrapper shop_main_page_header">
            <img className="logo logo_img" src={"/img/logo_monoti_shop.png"} onClick={handleHome} />
          </h1>
        )}
        {pathname.includes("login") && (
          <div className="login_text_wrapper">
            <span className="login_text">로그인</span>
          </div>
        )}
        <div>
          <div id="tnb" className="">
            <ul className="font_14 font700">
              <li>
                {user ? (
                  <span onClick={handleLogoutModal}>로그아웃</span>
                ) : (
                  <Link to="/shop/login">로그인</Link>
                )}
              </li>
              {!user && (
                <li>
                  <Link to="/type">회원가입</Link>
                </li>
              )}
              <li>
                <Link to={url ? `/shop/${url}/cart` : "/shop/cart"}>장바구니</Link>
              </li>
              <li className="tnb_btn">
                <Link to={homepageInfo ? `${url}/search?keyword=` : "search?keyword="}>
                  <img src="/img/icon_search.png" alt="검색" />
                </Link>
              </li>
              <li onClick={() => setClickSideMenu(true)} className="tnb_btn">
                <a id="menuBtn" style={{ cursor: "pointer" }}>
                  <img src="/img/shop_icon_menu.png" alt="전체메뉴" />
                </a>
              </li>
            </ul>
          </div>
          <div id="gnb" className="">
            <nav>
              <ul className="font_18 font700">
                {menus.map((item) => (
                  <li onClick={() => navigate(`/shop/${url}/${links[item.menuType]}`)}>
                    {item.afterMenuName ? item.afterMenuName : item.beforeMenuName}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default DefaultHeader;
