export default function MainBenefit() {
  return(
    <>
      <div className="inner_title" id="function_1" >
        <span className="font_48 font600 pdb_20">{service.point}</span>
        <span className="font_30 font300 pdb_20">{service.fComment1}</span>
      </div>
      <div className="inner_content_wrap" id="function_11">
        <ul className="function_1_ul">
          {functionKey.map((item, i) =>
          <li key={i}>
            <img src={item.img} />
            <span className="font_28 font600 pdb_20 text_center">{item.label}</span>
            <p className="font_18 font300 font_color_gray text_center">{item.comment}</p>
          </li>
          )}
        </ul>
      </div>
    </>
  )
}