import React from "react";
import { SearchContainer } from "../styled/shop";
import ShopSearchMap from "./shopSearch/shopSearchMap";
import ShopSearchBanner from "./shopSearch/shopSearchBanner";
import ShopSearchForm from "./shopSearch/shopSearchForm";

const ShopSearch = () => {
  return (
    <SearchContainer>
      <ShopSearchForm />
      <ShopSearchMap />
      <ShopSearchBanner />
    </SearchContainer>
  );
};

export default ShopSearch;
