import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import TabMenu from "../common/tabMenu";
import OrderItem from "./mobile/orderItem";

const MobileOrderView = ({
  categorized,
  selectOrder,
  rejectOrder,
  acceptOrder,
  openModal,
  completedOrder,
  selected,
  setSelected,
  updateOrder,
  active,
  setActive,
  checkedOrders,
}) => {
  const contentRefs = useRef([]);
  const orderListRef = useRef(null);

  const [pickupBy, setPickupBy] = useState([]);

  const tabs = [
    { key: "PENDING", label: "신규 주문" },
    { key: "READY", label: "처리중" },
    { key: "PICKUP", label: "완료" },
  ];

  useEffect(() => {
    if (categorized && active)
      setPickupBy(Array.from({ length: categorized[active].length }).fill(10));
  }, [categorized, active]);

  useEffect(() => {
    setSelected(null);

    if (orderListRef.current) {
      orderListRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [active]);

  const editPickupBy = (index, type = false) => {
    setPickupBy((prev) =>
      prev.map((time, i) => (i === index ? (type ? time + 10 : Math.max(time - 10, 10)) : time)),
    );
  };

  const toggleItem = async (item) => {
    if (selected?.id === item.id) {
      setSelected(null);
    } else {
      selectOrder(item);
    }
  };

  return (
    <>
      <div className="info">
        <div className="label">주문접수</div>
        <TabMenu tabs={tabs} active={active} setActive={setActive} />
      </div>
      <div className="content pickup">
        <ul className="order" ref={orderListRef}>
          {categorized[active].map((item, index) => (
            <OrderItem
              item={item}
              index={index}
              pickupBy={pickupBy}
              rejectOrder={rejectOrder}
              editPickupBy={editPickupBy}
              acceptOrder={acceptOrder}
              active={active}
              toggleItem={toggleItem}
              selected={selected}
              contentRefs={contentRefs}
              openModal={openModal}
              completedOrder={completedOrder}
              setSelected={setSelected}
              updateOrder={updateOrder}
              checkedOrders={checkedOrders}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default MobileOrderView;
