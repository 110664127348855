import React from "react";
import { HighlightContainer } from "../styled/info";

const Highlight = ({ type, content }) => {
  return (
    <HighlightContainer className={`infoSection ${type}`}>
      <div className="inner">
        <div className="img">
          <img src={`/img/info/highlight_${type}.png`} alt="" />
        </div>
        <div className="content">
          <span className="title">{content.heading}</span>
          <span className="desc">{content.description}</span>
          <strong>{content.feature}</strong>
        </div>
      </div>
    </HighlightContainer>
  );
};

export default Highlight;
