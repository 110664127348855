import React from "react";
import { CloseButton, Container } from "../kiosk/component/styled/common";
import { MdClose } from "react-icons/md";
import { exitFullscreen, useDisableScroll } from "../kiosk/component/function/kiosk";
import { useNavigate } from "react-router";
import { MutualBenefitMenu } from "./component/styled/mutualBenefit";

const MutualBenefit = () => {
  useDisableScroll();
  const navigate = useNavigate();

  const image = "";
  const winwin = [
    { key: "pickup", tit: "픽업", imagePath: "cafe" },
    { key: "salesCheck", tit: "매출확인", imagePath: "salesCheck_salesCheck" },
    { key: "clientCount", tit: "주문 수", imagePath: null },
    { key: "community", tit: "커뮤니티  ", imagePath: "invoice" },
  ];

  const exitKioskPage = () => {
    exitFullscreen();
    navigate("/mypage");
  };

  return (
    <Container className="bg">
      <CloseButton>
        <button onClick={exitKioskPage}>
          <MdClose />
        </button>
      </CloseButton>
      <MutualBenefitMenu>
        {winwin.map((item) => (
          <>
            {item.key !== "clientCount" ? (
              <li
                className={item.key}
                onClick={() =>
                  item.key === "orderRegistration"
                    ? alert("준비 중입니다")
                    : navigate(`/mypage/mutualBenefit/${item.key}`)
                }>
                <div className="content">
                  <div className="image">
                    <img src={`/img/mutualBenefit/${item.imagePath}.png`} alt="" />
                  </div>
                  <span>{item.tit}</span>
                </div>
              </li>
            ) : (
              <li className={item.key}>
                <div className="content">
                  <div className="customers">48명</div>
                  <span>
                    오늘
                    <br />
                    {item.tit}
                  </span>
                </div>
              </li>
            )}
          </>
        ))}
      </MutualBenefitMenu>
    </Container>
  );
};

export default MutualBenefit;
