import styled from "styled-components";

export const MemberPageSwiperContainer = styled.div`
  .member_swiper_content {
    .mem_logo {
      left: 50%;
      transform: translateX(-50%);
      width: 95px;
      height: 95px;
      border: none;
      margin: 0;
      margin-bottom: 19px;
      border: 1px solid #ddd;
      border-radius: 50%;
      .member_logo {
        max-width: none;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .member_location {
      width: 38px;
    }
  }
  .member_search_wrap {
    .member_search_btn {
      z-index: 0;
    }
  }
`;

export const MemberSearchContainer = styled.div`
  .member_search_wrap {
    .member_search .member_location.font_16.font500.font_color_white {
      top: 3%;
      width: 38px;
    }
    .member_info {
      height: 78px;
      .font_14 {
        overflow: visible;
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .mem_logo2 {
      border-radius: 50%;
      width: 95px;
      height: 95px;
      left: 50%;
      transform: translateX(-50%);
      border: 0.5px solid #ddd;
      border-radius: 50%;
    }
    .member_logo {
      max-width: none;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .adres {
      height: 20px;
      margin-bottom: 20px;
    }
  }
  .member_search_btn {
    padding: 0;
  }

  @media screen and (max-width: 1050px) {
    .adres {
      height: 22px;
    }
  }
  @media screen and (max-width: 767px) {
    .member_search .member_search_wrap .member_location.font_16.font500.font_color_white {
      width: 24px;
      height: 28px;
      background-size: contain;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .member_search .member_info {
      height: 50px;
    }
    .adres {
      height: 18px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 400px) {
    .member_search .mem_logo2 {
      width: 65px;
      height: 65px;
    }
  }
`;
