import React from "react";

import RestaurantLocator from "./shopLocator/restaurantLocator";
import StoreLocator from "./shopLocator/storeLocator";

const ShopLocator = () => {
  return (
    <>
      <RestaurantLocator />
      <StoreLocator />
    </>
  );
};

export default ShopLocator;
