import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  product : 0,
  function : 0,
  service : 0,
  application : 0,
  dashboard : 0,
  company : 0,
  faq : 0,
  register : 0,
  shopCompany : 0,
}

const compoIndex  = createSlice({
  name : "compoIndex",
  initialState,
  reducers : {
    setProductIndex(state, action) {
      state.product = action.payload;
    },
    setFunctionIndex(state, action){
      state.function = action.payload;
    },
    setServiceIndex(state, action){
      state.service = action.payload;
    },
    setApplicationIndex(state, action){
      state.application = action.payload;
    },
    setDashboardIndex(state, action){
      state.dashboard = action.payload;
    },
    setCompanyIndex(state, action){
      state.company = action.payload;
    },
    setFaqIndex(state, action){
      state.faq = action.payload;
    },
    setRegisterIndex(state, action){
      state.register = action.payload;
    },
    setShopCompany(state, action){
      if(action.payload === 0){
        state.shopCompany = action.payload;
      }else{
        state.shopCompany += 1;
      }
    },
  }
})

export const 
{ setProductIndex,
  setFunctionIndex, 
  setServiceIndex, 
  setApplicationIndex, 
  setDashboardIndex, 
  setCompanyIndex, 
  setFaqIndex, 
  setRegisterIndex,
  setShopCompany
} = compoIndex.actions;
export default compoIndex;
