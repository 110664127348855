import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";

export default function NoticeWriteForm() {
  const navigater = useNavigate();
  const index = useSelector((state) => state.compoIndex.register);
  const isMobile = useMediaQuery({query: "(max-width:767px)"});

  return (
    <div className="inner_content_wrap">
      <div className="board_view">
        <form id="borad_new_data">
          <ul className="writing_list" id="board_table">
            <li>
              <div className="writing_tit required">
                <strong className="tit">카테고리<em className="point">필수</em></strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal">
                  <select name="category_type" id="category_type">
                    <option value="A">카테고리1</option>
                    <option value="T">카테고리2</option>
                    <option value="C">카테고리3</option>
                    <option value="C">카테고리4</option>
                    <option value="C">카테고리5</option>
                  </select>
                </div>
              </div>
            </li>
            <li>
              <div className="writing_tit required">
                <strong className="tit">제목<em className="point">필수</em></strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal">
                  <input type="text" placeholder="제목을 입력하세요." defaultValue />
                </div>
              </div>
            </li>
            <li>
              <div className="writing_tit required">
                <strong className="tit">내용<em className="point">필수</em></strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal">
                  <textarea id="que_contents" name="que_contents" defaultValue={" * 1:1 문의 관련 정보를 입력해주시면 보다 정확한 답변을 받을 수 있습니다.\n                                  \n\n                                  1) 문의유형: 예) 홈페이지문의\n                                  \n\n                                  2) 회사명/홈페이지유형: (주)노타이틀/인테리어형\n                                  \n\n                                  3) 내용: 문의내용을 작성해주세요.\n                              "} />
                </div>
              </div>
            </li>
            <li>
              <div className="writing_tit">
                <strong className="tit">첨부파일</strong>
              </div>
              <div className="writing_cont">
                <div className="file_import">
                  <input type="text" id="file_import1" readOnly="readonly" defaultValue />
                  <label>
                    파일찾기
                    <input type="file" id="wk_file" name="wk_file"  /> {/* onChange="javascript:document.getElementById('file_import1').value=this.value" */}
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </form>
      </div>
      {/* 수정/등록 등 권한이 있을 때 */}
      {index !== 3 && 
        <div className="content_control" >
          <Link onClick={() => navigater(-1)}><button style={isMobile ? {position : "absolute", left : "18%"} : {}} type="button" className="btn_board_cancel">취소</button></Link>
          <button type="button" style={isMobile ? {position : "absolute", right : "18%"} : {}} className="btn_board_confirm">등록</button>
        </div>
      }
      
      {index === 3 &&  
        <div className="btnarea_center pdt_40">
          <Link onClick={() => navigater(-1)} className="btn_normal_cancel"><span>취소</span></Link>
          <Link className="btn_normal"><span>등록</span></Link>
        </div>}
      {/* 수정/등록 등 권한이 있을 때 */}
    </div>
  )
}