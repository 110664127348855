import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setApplicationIndex } from "../../store/compoIndex";

const ApplicationBanner = ({ index }) => {
  const dispatch = useDispatch();
  
  return (
    <div className="sub_top" id="function">
      <span className="font_22 font300 pdb_20 font_color_white">{service.intro}</span>
      <span className="font_72 font700 font_color_1 pdb_70">{service.app}</span>
        <ul className="sub_menu">
          {appBanner.map((item, i) =>
            <li 
              className={index === i ? "actived" : ""}
              key={i}
              onClick={() => dispatch(setApplicationIndex(i))}
            >
              <Link>{item.label}</Link>
            </li>
          )}
        </ul>
    </div>
  );
};

export default ApplicationBanner;