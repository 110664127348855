import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { FeedPreviewItem, GroupItem, ProjectItem, SchedulerItem, StockItem } from "./searchItems";
import { SearchResults } from "../styled/search";
import { useRegex } from "../../../../hooks/useRegex";

const SearchAll = ({ tabChange, searched, searchWord, isSearch }) => {
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const { MoneyFormat } = useRegex();
  const [imageHeight, setImageHeight] = useState();

  const updateImageSize = () => {
    const previewWidth = document.querySelector(".preview")?.offsetWidth;
    setImageHeight(previewWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateImageSize);
    return () => {
      window.removeEventListener("resize", updateImageSize);
    };
  }, []);

  const itemTypes = {
    scheduleList: { label: "일정", component: SchedulerItem, tabIndex: 1 },
    groupList: { label: "모임", component: GroupItem, tabIndex: 2 },
    feedList: { label: "소식", component: FeedPreviewItem, tabIndex: 3 },
    projectList: { label: "프로젝트", component: ProjectItem, tabIndex: 4 },
    stockList: { label: "e층창고", component: StockItem, tabIndex: 5 },
  };

  return (
    <div>
      <SearchResults>
        {isSearch &&
          Object.entries(searched).map(([listName, itemList]) => (
            <div key={listName} className={`result ${listName}`}>
              <div className="label">
                <span>
                  {itemTypes[listName].label}
                  {itemList.length > 0 && <strong>{MoneyFormat(itemList.length)}</strong>}
                </span>
                {itemList.length > 0 && (
                  <button onClick={() => tabChange(itemTypes[listName].tabIndex, searchWord)}>
                    더보기
                  </button>
                )}
              </div>
              {itemList.length > 0 ? (
                <ul>
                  {itemList.slice(0, listName === "stockList" && !isTablet ? 5 : 3).map((item) => {
                    const ItemComponent = itemTypes[listName].component;
                    return (
                      <ItemComponent
                        item={item}
                        {...(listName === "stockList" && { updateImageSize, imageHeight })}
                        {...(listName === "feedList" && { tabChange, searchWord })}
                      />
                    );
                  })}
                </ul>
              ) : (
                <div>검색 결과가 존재하지 않습니다.</div>
              )}
            </div>
          ))}
      </SearchResults>
    </div>
  );
};

export default SearchAll;
