import React from "react";

const Privacy = ({ setToggleLegalInfo }) => {
  return (
    <div id="privacy" class="popup_layer" style={{ display: "block" }}>
      <div class="popup_content">
        <div class="popup_layer popup_title">
          <title>개인정보처리방침</title>
          <button onClick={() => setToggleLegalInfo("")}>X</button>
        </div>
        <div class="popup_body">
          <p>
            모노티 캘린더 개인정보처리방침 <br />
            <br />
            1. 개인정보의 수집, 이용 목적
            <br />
            ('www.1472.ai' 이하 '노타이틀')은 정보통신망 이용촉진 및 정보보호에 관한 법률,
            개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한
            이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
            <br />
            (' 노타이틀 ') 은 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을
            통하여 공지할 것입니다.
            <br />
            <br />
            1. “노타이틀”은 개인정보의 처리 목적은 개인정보를 다음의 목적을 위해 처리합니다. 처리한
            개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는
            사전동의를 구할 예정입니다.
            <br />
            <br />
            가. 홈페이지 회원가입 및 관리
            <br />
            회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적
            본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지·통지, 고충처리, 분쟁
            조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
            <br />
            나. 민원사무 처리
            <br />
            민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을
            목적으로 개인정보를 처리합니다.
            <br />
            다. 상품재화 또는 서비스 제공
            <br />
            물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증,
            요금결제·정산, 채권추심 등을 목적으로 개인정보를 처리합니다.
            <br />
            라. 마케팅 및 광고에의 활용
            <br />
            신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공
            , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도
            파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
            <br />
            <br />
            2. 개인정보의 수집항목 및 수집방법
            <br />
            (1) 수집항목
            <br />
            첫째, " 노타이틀 "은 주문조회용 정보, 원활한 고객상담, 각종서비스의 제공을 위해 회원가입
            또는 비회원 주문시 아래의 개인정보 항목을 수집하고 있습니다.
            <br />
            ① 일반회원 :<br />
            - 필수항목 : 본인인증값, 성명, 아이디, 비밀번호, 성별, 주소, 전화번호, 휴대전화번호
            ,이메일
            <br />
            ② 비회원 :<br />
            - 필수항목 : 성명, 비밀번호, 주소, 이메일, 휴대전화번호
            <br />
            둘째, 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어
            수집될 수 있습니다.
            <br />
            - IP Address, 쿠키, 방문 일시, OS종류, 브라우져 종류 서비스 이용 기록 등<br />
            셋째, 부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에
            대하여 한해서만. 개인정보 추가 수집에 대해 동의를 받는 경우에 한해서만, 아래와 같은
            정보들이 수집될 수 있습니다.
            <br />
            - 경품 등 배송 등을 위한 배송지, 수령자명, 유선전화번호, 휴대전화번호, E-Mail 등<br />
            넷째, 서비스 이용 과정에서 아래와 같은 정보들이 수집될 수 있습니다.
            <br />
            - 신용카드 결제 시 : 카드사명, 카드번호 등<br />
            - 휴대전화 결제 시 : 이동전화번호, 통신사, 결제승인번호, 이메일주소 등<br />
            - 계좌이체 시 : 은행명, 계좌번호 등<br />
            - 환불 시 : 환불계좌정보(은행명, 계좌번호, 예금주명)
            <br />
            다섯째, 이용자의 기본적 인권을 침해할 우려가 있는 인종 및 민족, 사상 및 신조, 출신지 및
            본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등의 정보는 이용자의 동의 또는
            법령에 의한 경우 이외에는 수집하지 않습니다.
            <br />
            (2) 개인정보 수집방법
            <br />
            " 노타이틀 "은 홈페이지(회원가입, 비회원주문, 1:1문의)와 상담전화, 이메일,
            제휴사로부터의 제공, 생성정보 수집을 통해 개인정보를 수집하고 있습니다.
            <br />
            <br />
            3. 개인정보의 보유 및 이용기간
            <br />
            " 노타이틀 "은 이용자의 개인정보를 수집하는 경우 그 보유기간은 회원가입 하신 후
            해지(탈퇴신청, 직권탈퇴 포함)시까지 입니다. 또한 회원 탈퇴시 " 노타이틀 "은 이용자의
            수집된 개인정보가 열람 또는 이용될 수 없도록 파기 처리 됩니다. 개인정보가 제3자에게
            제공된 경우에는 제3자에게도 파기하도록 지시합니다. 또한, 다음과 같은 경우 개인정보의
            수집 목적 또는 제공받은 목적이 달성되면, 개인정보를 파기합니다.
            <br />
            (1) 결제정보의 경우, 대금의 완제일 또는 채권소멸시효기간의 만료된 때<br />
            (2) 배송정보의 경우, 물품 또는 서비스가 인도되거나 제공된 때<br />
            (3) 단, 상법 및 ‘전자상거래등에서 소비자보호에 관한 법률’, 전자금융거래법,
            여신전문금융업법 국세기본법, 법인세법, 부가가치세법 등 관련 법령 및 " 노타이틀 " 내부
            규정에 의하여 성명, 주소 등 거래의 주체를 식별할 수 있는 정보와 거래 관련 권리 의무
            관계의 확인 등에 한하여 서비스 이용에 관한 동의를 철회한 경우에도 이를 보존할 수 있으며,
            다음과 같이 거래기록을 보관합니다.
            <br />
            ① 표시, 광고에 관한 기록
            <br />
            -보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            -보존 기간 : 6개월
            <br />
            ② 계약 또는 청약철회 등에 관한 기록
            <br />
            -보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            -보존 기간 : 5년
            <br />
            ③ 대금결제 및 재화등의 공급에 관한 기록
            <br />
            -보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            -보존 기간 : 5년
            <br />
            ④ 소비자의 불만 또는 분쟁처리에 관한 기록
            <br />
            -보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률
            <br />
            -보존 기간 : 3년
            <br />
            이용자의 개인정보는 회원가입 해지 시 또는 제공 목적 달성 후 별도의 DB로 옮겨져 내부 방침
            및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 보관된 후
            기록을 재생할 수 없는 기술적 방법에 의하여 영구 삭제됩니다. 별도 DB로 옮겨진 개인정보는
            법률에 의한 경우가 아니고서는 보유 이외의 다른 목적으로 이용되지 않습니다.
            <br />
            <br />
            4. 개인정보 보호 책임자
            <br />
            “‘노타이틀” 은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
            정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고
            있습니다.
            <br />
            [ 개인정보 관리 책임자 ]<br />
            성명: 조윤호
            <br />
            전화: 042-272-5555
            <br />
            이메일 주소: helpnotitle@gmailcomt
            <br />
            <br />
            [ 개인정보 관리 담당자 ]<br />
            성명: 조윤호
            <br />
            전화: 042-272-5555
            <br />
            이메일 주소: helpnotitle@gmailcomt
            <br />
          </p>
        </div>
      </div>
      <div
        class="popup_bg"
        onClick={() => setToggleLegalInfo("")}
        style={{ position: "absolute" }}></div>
    </div>
  );
};

export default Privacy;
