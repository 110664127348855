import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setCompanyIndex } from "../../../../store/compoIndex";

export default function CompanyInfoTitle({ index }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.data[0]);

  return (
    <div className="inner_title">
      <span className="font_30 font300 font_color_black pdb_10">
        <strong className="font_color_1">{user.name}</strong>님, 모노티 캘린더 홈페이지를 통해
        <br />
        홍보와 사업장 관리를 함께 도와드립니다.
      </span>
      <span className="font_24 font300 font_color_black pdb_10 text_center">
        홈페이지 제품 연동형과 제품 등록형 홈페이지를 선택 또는 모두 추가할 수 있습니다.
      </span>
      <span className="font_18 font300 font_color_gray pdb_40 text_center">
        (사업자번호별 각 1개씩 추가 가능, 사업자번호가 다를 경우 추가 가능)
      </span>
      {/* <ul className="inner_title_menu">
        {companyMenu.map((item, i) => (
          <li
            onClick={() => dispatch(setCompanyIndex(i))}
            key={i}
            className={index === i ? "actived" : ""}>
            <Link to={"/mypage/companyInfo"}>{item.label}</Link>
          </li>
        ))}
      </ul> */}
    </div>
  );
}
