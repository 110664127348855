import React from "react";
import {
  formatStorageTimes,
  isPayType,
  resStatus,
} from "../../../mypage/kiosk/component/function/kiosk";

const StorageItem = ({ item, checked, toggleCheckbox }) => {
  return (
    <li>
      <div className="storage">
        <div>
          <span className="tit">가격</span>
          <span className="con">{item.price.toLocaleString()}원</span>
        </div>
        <div>
          <span className="tit">픽업상태</span>
          <span className="con status">{resStatus[item?.reservationStatus].label}</span>
        </div>
        <div>
          <span className="tit">결제타입</span>
          <span className="con">{isPayType(item.payType)}</span>
        </div>
        <div>
          <span className="tit">보관시간</span>
          <span className="con">{formatStorageTimes(item).start}</span>
        </div>
        <div>
          <span className="tit">보관마감시간</span>
          <span className="con">{formatStorageTimes(item).end}</span>
        </div>
        {/* <div>
          <span className="tit">추가금액</span>
          <span className="con">{item.addPay ? item.addPay.toLocaleString() : 0}원</span>
        </div> */}
      </div>
      <label className="customInput">
        <input
          type="checkbox"
          name={item.id}
          checked={checked.includes(parseInt(item.id, 10))}
          onChange={toggleCheckbox}
        />
        <span className="checkmark"></span>
      </label>
    </li>
  );
};

export default StorageItem;
