import React from "react";
import MypageBanner from "../mypageBanner";
import HomePageSettingMenu from "./homepageSettingMenu";
import ThemeSettingForm from "./component/themeSetting/themeSettingForm";
import { ThemeSettingContainer } from "./component/styled/themeSetting";

const ThemeSetting = () => {
  return (
    <ThemeSettingContainer>
      <MypageBanner />
      <div className="inner_content">
        <HomePageSettingMenu />
        <ThemeSettingForm />
      </div>
    </ThemeSettingContainer>
  );
};

export default ThemeSetting;
