import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { DashboardSideMenuContainer, SideMenuContainer } from "./styled/common";
import {
  IoChevronDown,
  IoChevronUp,
  IoNotificationsOutline,
  IoSearchOutline,
} from "react-icons/io5";
import { GrClose } from "react-icons/gr";

import AuthButtons from "./header/authButtons";

export default function DashboardSideMenu({ clickSideMenu, setClickSideMenu, menuItems }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const [notify, setNotify] = useState([]);

  useEffect(() => {
    if (user && isTablet) {
      getNotification();
    }
  }, [user]);

  const getNotification = async () => {
    try {
      const res = await axios.post("/api/notificationHistory", { mberNo: user.mberNo });
      console.log(res);
      setNotify((res?.data || []).filter((not) => not.readYn === "N"));
    } catch (error) {
      console.error(error);
    }
  };

  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  const toggleMenu = (index) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  return (
    <DashboardSideMenuContainer
      style={{
        transform: clickSideMenu ? "translateX(0)" : "translateX(200%)",
        opacity: clickSideMenu ? 1 : 0,
      }}
      className={setting.mode}>
      <div className="wrapper">
        <div className="sideMenuInfo">
          <div className="logo">
            <span>MONOTI</span>
          </div>
          <div className="buttons">
            <button onClick={() => navigate("/mypage/search")}>
              <IoSearchOutline />
            </button>
            <button onClick={() => navigate("/mypage/notify")}>
              <IoNotificationsOutline />
            </button>
            <button onClick={() => setClickSideMenu(false)}>
              <GrClose />
            </button>
          </div>
        </div>
        <div className="gbn">
          <ul>
            {menuItems.map((menu, index) => (
              <li key={index}>
                <button className="toggle" onClick={() => toggleMenu(index)}>
                  <span>{menu.title}</span>
                  <span className="icon">
                    {openMenuIndex === index ? <IoChevronUp /> : <IoChevronDown />}
                  </span>
                </button>
                {menu.items && (
                  <ul
                    style={{
                      height: openMenuIndex === index ? `${menu.items.length * 35}px` : "0",
                      overflow: "hidden",
                      transition: "height 0.3s ease",
                    }}
                    className="menuItem">
                    {menu.items.map((item, i) => (
                      <li key={i}>
                        <Link
                          to={item.link}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (menu.action && typeof menu.action === "function") {
                              dispatch(menu.action(i));
                            }
                            setClickSideMenu(false);
                          }}>
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="authButtons">
          <AuthButtons setClickSideMenu={setClickSideMenu} />
        </div>
      </div>
    </DashboardSideMenuContainer>
  );
}
