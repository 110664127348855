import axios from "axios";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

import { IoMdOptions } from "react-icons/io";

import ProductSyncDesktop from "./productSyncForm/productSyncDesktop";
import ProductFilter from "./common/productFilter";

import { FilterHandler } from "../styled/common";
import { toast } from "react-toastify";

export default function ProductSyncForm({ categories, categoriesObj, synced }) {
  const user = useSelector((state) => state?.user?.data[0]);
  const isMobile = useMediaQuery({ query: "(max-width:640px)" });
  const [pages, setPages] = useState({ limit: 10, page: 1 });
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [filtered, setFiltered] = useState({
    minBasePrice: "",
    maxBasePrice: "",
    brand: "",
    name: "",
    categoryId: null,
  });

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    console.log(filtered);
  }, [filtered]);

  const getProduct = async (limit = 10, page = 1) => {
    try {
      const url = "/erp/cloudProducts/get";
      const body = { limit, offset: limit * (page - 1) };

      Object.entries(filtered).forEach(([key, value]) => {
        if (!!value) {
          if (key === "minBasePrice" || key === "maxBasePrice") {
            body[key] = parseFloat(value.toString().replace(/,/g, ""));
          } else {
            body[key] = value;
          }
        }
      });

      const res = await axios.post(url, body);

      if (res.status === 200) {
        console.log(res.data.products);
        setTotal(res.data.totalCount);
        setProducts(res.data.products);
        setSelectedProducts([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changePages = ({ target, value }) => {
    setPages((data) => {
      const updatedPages = { ...data, [target]: value };
      getProduct(updatedPages.limit, updatedPages.page);
      return updatedPages;
    });
  };

  const handleSync = async () => {
    if (!selectedProducts || !selectedProducts.length) return;
    const url = "/erp/vendorProducts/create";
    const body = {
      cmpnyNo: user?.cmpnyNo,
      cloudProductId: selectedProducts.map((item) => parseInt(item.id, 10)).filter(Boolean),
    };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        toast.success("상품이 성공적으로 연동되었습니다.");
        getProduct();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheck = (prd) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.some((item) => item.id === prd.id)
        ? prevSelected.filter((item) => item.id !== prd.id)
        : [...prevSelected, prd],
    );
  };

  const handleAllCheck = (isChecked) => {
    if (isChecked) {
      setSelectedProducts(products);
    } else {
      setSelectedProducts([]);
    }
  };

  return (
    <div className="hp_step_content">
      <div className="hp_step_product_step">
        <span
          className="font_16 font400 text_left font_color_gray"
          style={isMobile ? { fontSize: "18px" } : {}}>
          <i className="fa fa-cubes" aria-hidden="true" />
          조회한 제품 총 <strong className="font_color_1 font400">{total}</strong>개
        </span>
        <ProductFilter
          filtered={filtered}
          categories={categories}
          categoriesObj={categoriesObj}
          setFiltered={setFiltered}
        />
        <FilterHandler>
          <div className="filter">
            <select
              value={pages.limit}
              onChange={(e) =>
                changePages({ target: "limit", value: parseInt(e.target.value, 10) })
              }>
              <option value={10}>10개 보기</option>
              <option value={30}>30개 보기</option>
              <option value={50}>50개 보기</option>
            </select>
            <button className="filterBtn" onClick={() => getProduct()}>
              조회
            </button>
          </div>
          <button className="productSyncBtn" onClick={handleSync}>
            연동하기
          </button>
        </FilterHandler>
        <form method="post">
          <div className="hp_step_product_wrap">
            <ProductSyncDesktop
              products={products}
              total={total}
              pages={pages}
              changePages={changePages}
              selectedProducts={selectedProducts}
              handleCheck={handleCheck}
              handleAllCheck={handleAllCheck}
              synced={synced}
              categoriesObj={categoriesObj}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
