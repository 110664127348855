import QnaWriteForm from "./component/faqPage/faqWriteForm";
import NoticeBanner from "./noticeBanner";

export default function QnaWrite() {
  return (
    <>
      <NoticeBanner />
      <QnaWriteForm />
    </>
  );
}
