import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { HambergerMenu } from "./styled/group";

import { FiRefreshCcw } from "react-icons/fi";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { MdOutlineHowToVote } from "react-icons/md";
import { IoExitSharp } from "react-icons/io5";
import { GoArrowUp } from "react-icons/go";
import { toast } from "react-toastify";

const GroupHambergerMenu = ({ refresh, handleExitModal = null }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShowBtn, setIsShowBtn] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 500) {
        setIsShowBtn(true);
      } else {
        setIsShowBtn(false);
      }
    };
    window.addEventListener("scroll", handleShowButton);
    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, [isShowBtn]);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <HambergerMenu>
      <div className="feedSideNav">
        <div className="sidebar">
          <ul className={`feedNavList ${isMenuOpen ? "open" : ""}`}>
            <li>
              <div onClick={() => refresh()}>
                <FiRefreshCcw />
                <p>새로고침</p>
              </div>
            </li>
            <li>
              <div onClick={() => navigate(`/group/group-feed-write/${id ? id : "0"}`)}>
                <HiOutlinePencilAlt />
                <p>새글</p>
              </div>
            </li>
            <li>
              <div onClick={() => alert("준비 중입니다.")}>
                <MdOutlineHowToVote />
                <p>투표</p>
              </div>
            </li>
            {!isNaN(id) && (
              <li>
                <div onClick={() => handleExitModal()}>
                  <IoExitSharp />
                  <p>모임탈퇴</p>
                </div>
              </li>
            )}
            <li style={{ display: isShowBtn ? "block" : "none" }} className="row">
              <div onClick={scrollToTop}>
                <GoArrowUp />
                <p>맨 위로</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="burgerBox">
          <label
            class="burger"
            for="burger"
            onChange={() => setIsMenuOpen(!isMenuOpen)}
            checked={isMenuOpen}>
            <input type="checkbox" id="burger" />
            <div>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </label>
        </div>
      </div>
    </HambergerMenu>
  );
};

export default GroupHambergerMenu;
