import React from "react";
import { Row, Col } from "react-bootstrap";
import { tabMenus } from "../function/group";

const GroupDetailFooter = ({ tabChange }) => {
  return (
    <Row
      className="d-lg-none navbar fixed-bottom navbar-light bg-light"
      style={{
        zIndex: "9000",
        position: "fixed",
        display: "flex",
        justifyContent: "space-evenly",
        height: "70px",
        alignItems: "center",
        backgroundColor: "#eee",
        bottom: 0,
        width: "100%",
      }}>
      {tabMenus.map((item, idx) => (
        <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem", width: "25%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => tabChange(idx)}>
            <item.icon style={{ fontSize: "30px", color: "black", strokeWidth: "1" }} />
            <span>{item.name}</span>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default GroupDetailFooter;
