import styled from "styled-components";

export const ThemeSettingContainer = styled.div`
  .writing_ty2 {
    ul {
      display: flex;
      align-items: center;
      gap: 20px;
      margin: 40px 0;
      li {
        .themeInfo {
          margin-bottom: 20px;
          text-align: center;
          label {
            display: inline-block;
            margin-right: 0;
            width: 100%;
            cursor: pointer;
          }
          span {
            margin-left: 5px;
            display: inline-block;
          }
        }
        .preview {
          width: 100%;
          height: 250px;
          overflow: hidden;
          border: 1px solid #ddd;
          cursor: pointer;
          img {
            width: 100%;
            object-fit: cover;
            user-select: none;
          }
        }
      }
    }
  }

  .previewModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 33;
    .content {
      width: 1200px;
      height: 70vh;
      overflow-y: scroll;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        max-width: 100%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .writing_ty2 ul li .preview {
      height: 150px;
    }
  }
`;
