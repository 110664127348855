import BoardList from "../../../common/boardList"
import NoticeTitle from "./noticeTitle"

export default function NoticeForm() {
  const BoardListProps = {
    button: false,
    search: true,    
    title:"공지사항",
  }

  return(
    <div className="inner_content">
      <NoticeTitle />
      <div className="inner_content_wrap">
        <BoardList  BoardListProps={BoardListProps} />
      </div>
    </div>
  )
}

