import React from "react";

import { InfoContainer } from "./styled/info";

import Visual from "./common/visual";
import Hero from "./common/hero";
import Mockup from "./common/mockup";
import Highlight from "./common/highlight";
import SecondaryMockup from "./common/secondaryMockup";

const InfoGroup = () => {
  const components = [
    {
      component: Hero,
      content: {
        heading: "효율적인 커뮤니케이션",
        description:
          "모임 기능은 팀 내 커뮤니케이션을 통합하고 간소화합니다.\n모임 전체가 소통할 수 있는 공간을 제공하여,\n중요한 공지사항이나 토론이 한 곳에서 이루어집니다.\n이를 통해 모든 구성원이 최신 정보를\n실시간으로 공유받을 수 있습니다.",
        feature: "공지를 놓치지 않게 해주는 알림 기능",
      },
    },
    {
      component: Mockup,
      content: {
        heading: "작업 관리 기능",
        description:
          "프로젝트 관리 기능을 지원하며 업무의 효율성을 극대화합니다.\n팀원들간에 작업을 배정할 수 있고\n각 작업의 상태를 명확하게 파악할 수 있어\n프로젝트가 계획대로 진행되고 있는지 쉽게 확인할 수 있습니다.\n일정 지연을 방지하고 모든 팀원이 동일한 목표를 향해 나아갈 수 있습니다.",
      },
    },
    {
      component: Highlight,
      content: {
        description:
          "모임 내에서 공유 일정이나 마감일을 쉽게 설정할 수 있으며\n모든 구성원에게 자동으로 알림을 제공하여 일정을 놓치지 않도록 합니다.\n캘린더 기능과 연동하여 일정을 시각적으로 한눈에 볼 수 있어 계획 관리가 수월해집니다.",
        feature: "실시간 알림 기능으로 업무의 효율성 극대화",
      },
    },
    {
      component: SecondaryMockup,
      content: {
        heading: "파일 공유 및 보관",
        description:
          "모임 내에서 파일을 쉽게 공유하고\n중앙 집중식으로 관리할 수 있어\n팀원들이 필요할 때 언제든지 파일에 접근할 수 있습니다.\n모든 팀원이 동일한 파일을 쉽게 찾아볼 수 있고\n파일 수정 내역이나 버전 이력을 추적할 수 있습니다.",
        feature: "효율적인 파일 관리 가능",
      },
    },
  ];
  return (
    <InfoContainer className="group">
      <Visual />
      {components.map(({ component: Component, content }, index) => (
        <Component key={index} content={content} type="group" />
      ))}
    </InfoContainer>
  );
};

export default InfoGroup;
