import React from "react";
import { CustomerListContainer } from "../../styled/storage";
import { useRegex } from "../../../../../../hooks/useRegex";
import { IoSearch } from "react-icons/io5";

const CustomerList = ({
  transformValue,
  data,
  handleInputChange,
  handleSearch,
  searched,
  handleClickCustomer,
}) => {
  const { TelFormat } = useRegex();

  return (
    <CustomerListContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="search">
        <input
          type="text"
          placeholder="전화번호 뒷자리를 입력해주세요."
          onChange={handleInputChange}
          value={searched}
          maxLength={4}
          onKeyDown={(e) => e.key === "Enter" && handleSearch()}
        />
        <button onClick={handleSearch}>
          <IoSearch />
        </button>
      </div>
      <div className="customers">
        {data && data.length > 0 ? (
          <ul>
            {data.map((item) => (
              <li>
                <div className="info">
                  <span className="idx">{item.id}</span>
                  <span className="name">{item.mberNm}</span>
                  <span className="tel">{TelFormat(item.tel)}</span>
                </div>
                <div className="buttons" style={{ textAlign: "right" }}>
                  <button onClick={() => handleClickCustomer(item)}>픽업완료</button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div>픽업 요청이 존재하지 않습니다.</div>
        )}
      </div>
    </CustomerListContainer>
  );
};

export default CustomerList;
