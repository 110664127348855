import React from "react";
import { SearchedContainer } from "../styled/search";
import { useRegex } from "../../../../hooks/useRegex";
import { FeedPreviewItem } from "./searchItems";

const SearchFeed = ({ data, isSearch }) => {
  const { MoneyFormat } = useRegex();

  return (
    <div className="searched">
      {isSearch && (
        <SearchedContainer>
          {data.length > 0 ? (
            <>
              <div className="label">
                {data.length > 0 && (
                  <span>
                    검색 결과
                    <strong>{MoneyFormat(data.length)}</strong>개
                  </span>
                )}
              </div>
              {data.length > 0 && data && (
                <ul>
                  {data.map((item) => (
                    <FeedPreviewItem item={item} />
                  ))}
                </ul>
              )}
            </>
          ) : (
            <div className="noData">검색 결과가 존재하지 않습니다.</div>
          )}
        </SearchedContainer>
      )}
    </div>
  );
};

export default SearchFeed;
