import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { store } from "./store/store";
import Header from "./common/header";
import Footer from "./common/footer";

const CustomToast = styled(ToastContainer)`
  z-index: 10000;
  width: ${(props) => props.isMobile ? "100%" : "400px"};
  font-weight: 600;
  font-size: 16px;
`;

const AppMobile = () => {
  const isMobile = useMediaQuery({query: "(max-width:767px)"});

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
      changeBodyClassName();
    }, [pathname]);

    return null;
  }

  return (
    <Provider store={store}>
      <ScrollToTop />
      <CustomToast
        position="top-center"
        theme="light"
        pauseOnHover
        hideProgressBar={true}
        autoClose={1000}
        closeButton={false}
        isMobile={isMobile}
      />
      <Header />
      <div className="sub_wrap">

      <Outlet />
      </div>
      <Footer />
    </Provider>
  )
}

export default AppMobile