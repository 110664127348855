import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { ParticipantInviteModalContainer } from "../../../common/styled/project";
import ProjectDetailParticipantList from "./projectDetailParticipantList";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Modal from "../../../../../../../common/modal";
import ProjectDetailParticipantSearched from "./projectDetailParticipantSearched";
import { useMediaQuery } from "react-responsive";

const ProjectDetailParticipantModal = ({
  setIsInviteOpen,
  project,
  participantList,
  getParticipantList,
  getProjectRoom,
}) => {
  const isGrp = project?.gbn === "G" ? true : false;
  const user = useSelector((state) => state?.user?.data[0]);
  const [isSearch, setIsSearch] = useState(false);
  const [searched, setSearched] = useState("");
  const [originCmpnyMber, setOriginCmpnyMber] = useState([]);
  const [mberList, setMberList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [mberTotal, setMberTotal] = useState(0);
  const [chkMberList, setChkMberList] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [searchedList, setSearchedList] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  useEffect(() => {
    if (isGrp) {
      getGrpMber();
    } else {
      getCmpnyMber();
      getSearchedParticipantList();
    }
  }, []);

  // 페이지
  const getSearchCond = (number) => {
    const pageSize = isMobile ? 7 : 10;
    const pageNumber = number - 1;

    return {
      delYn: "N",
      offset: pageNumber * pageSize,
      pageNumber: pageNumber,
      pageSize,
      paged: true,
    };
  };

  // 저장된 사람 클릭 시
  const handleSearchedMber = async (item) => {
    const url = "/api/mberList";
    const body = {
      mberNo: item.mberNo,
      mberNm: item.searchWord,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      const data = res.data.content;
      const filtered = data.filter(
        (mber) => mber.mberNo === item.mberNo && mber.mberNm === item.searchWord,
      );

      setMberList(filtered);
      setMberTotal(1);
      setIsSearch(true);
    }
  };

  // 회사 멤버
  const getCmpnyMber = async () => {
    const url = "/api/cmpnyMember";
    const body = {
      cmpnyNo: user.cmpnyNo,
    };
    try {
      const res = await axios.post(url, body);
      if (res.data) {
        const content = res.data;

        const list = content.map((item) => ({
          mberNm: item.mberNm ? item.mberNm : null,
          mberNo: item.mberNo,
          cmpnyNm: project?.orgNm,
          cmpnyNo: item.cmpnyNo,
          moblphonNo: item.moblphonNo,
        }));

        setOriginCmpnyMber(list);
        setMberList(list.slice(0, isMobile ? 7 : 10));
        setMberTotal(res.data.length ? res.data.length : 0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 사용자 검색
  const getSearchMber = async (number = 1) => {
    const url = "/api/mberList";
    const body = {
      useFullNm: "Y",
      ...getSearchCond(number),
    };

    if (!isNaN(searched)) {
      const phoneRegex = /^(010\d{8}|011\d{7})$/;
      if (!phoneRegex.test(searched)) {
        toast.error("전화번호 양식이 일치하지 않습니다.");
        return;
      }

      body.moblphonNo = searched;
    } else {
      body.mberNm = searched;
    }

    const res = await axios.post(url, body);
    if (res.status === 200) {
      const content = res.data.content;

      const list = content.map((item) => ({
        mberNm: item.mberNm,
        mberNo: item.mberNo,
        cmpnyNo: item.cmpnyNo,
        cmpnyNm: item.cmpnyNm,
        moblphonNo: item.moblphonNo,
      }));

      setMberList(list);
      setMberTotal(res.data.totalElements);
    }
  };

  // 모임 조회
  const getGrpMber = async (number = 1) => {
    const url = "/api/groupMberList";
    const body = {
      groupNo: project?.orgNo,
      nickNm: searched,
      ...getSearchCond(number),
    };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      const content = res.data.content;

      const list = content.map((item) => ({
        mberNm: item.nickNm ? item.nickNm : null,
        mberNo: item.mberNo,
        cmpnyNm: null,
        cmpnyNo: project?.orgNo,
        moblphonNo: null,
      }));

      setMberList(list);
      setMberTotal(res.data.totalElements);
    }
  };

  // 초대한 사람 기록 조회
  const getSearchedParticipantList = async () => {
    const url = "/api/searchedParticipantList";
    const body = {
      cmpnyNo: user.cmpnyNo,
    };

    const res = await axios.post(url, body);
    if (res.status === 200 && res.data.length > 0) {
      const data = res.data.filter((item) => item.cmpnyNo !== user.cmpnyNo);
      setSearchedList(data);
    }
  };

  // 페이지네이션 변경
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);

    setIsAllChecked(false);
    setChkMberList([]);

    if (isGrp) {
      getGrpMber(pageNumber);
    } else {
      if (searched) {
        getSearchMber(pageNumber);
      } else {
        const pageSize = isMobile ? 7 : 10;
        const startIndex = (pageNumber - 1) * pageSize;

        if (tabIndex === 0) {
          setMberList(originCmpnyMber.slice(startIndex, startIndex + pageSize));
        } else {
          const list = searchedList.map((item) => ({
            mberNm: item.searchWord,
            mberNo: item.mberNo,
            cmpnyNo: item.cmpnyNo,
            cmpnyNm: item.cmpnyNm,
            moblphonNo: item.moblphonNo,
          }));
          setMberList(list.slice(startIndex, startIndex + pageSize));
          setMberTotal(searchedList.length);
        }
      }
    }
  };

  const handleSearch = () => {
    if (!searched || !searched.trim()) {
      toast.error("검색어를 입력해 주세요.");
      return;
    }

    setIsSearch(true);

    if (isGrp) {
      getGrpMber();
    } else {
      getSearchMber();
    }
  };

  const handleInvite = async (list) => {
    try {
      const url = "/api/participants";
      const body = list.map((item) => ({
        projectNo: project?.projectNo,
        gbn: project?.gbn,
        mberNo: item.mberNo,
        orgNo: item.cmpnyNo,
        partiType: isGrp || project.orgNo === item.cmpnyNo ? "I" : "O",
        pinnedYn: "N",
        delYn: "N",
        createMberId: user.userName,
        updateMberId: user.userName,
      }));

      const res = await axios.put(url, body);

      if (res.status === 200) {
        const promises = [getProjectRoom(project), getParticipantList(project)];

        if (!isGrp) {
          promises.push(
            ...list
              .filter((item) => !searchedList.some((mber) => mber.mberNo === item.mberNo))
              .map((item) => handleSaveSearched(item)),
          );
        }

        await Promise.all(promises);

        toast.success(`${list.length > 1 ? "모든" : "해당"} 멤버가 성공적으로 초대되었습니다.`);
        setModalOpen(false);
        setIsAllChecked(false);
        setChkMberList([]);
      }
    } catch (error) {
      console.error("Error inviting members:", error);
      toast.error("멤버 초대 중 오류가 발생했습니다.");
    }
  };

  // 초대한 사람 기록 저장
  const handleSaveSearched = async (item) => {
    const url = "/api/saveSearchedParticipantList";
    const body = {
      cmpnyNo: user.cmpnyNo,
      searchWord: item.mberNm,
      refeMberNo: item.mberNo,
      delYn: "N",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    const res = await axios.put(url, body);
    if (res.status !== 200) {
      console.log(res);
    }
  };

  // 체크박스
  const handleCheck = (mber) => {
    if (mber.mberNm && !participantList.some((participant) => participant.mberNo === mber.mberNo)) {
      const isAlreadyChecked = chkMberList.some((chkMber) => chkMber.mberNo === mber.mberNo);

      let updated;
      if (isAlreadyChecked) {
        updated = chkMberList.filter((chkMber) => chkMber.mberNo !== mber.mberNo);
      } else {
        updated = [...chkMberList, mber];
      }

      setChkMberList(updated);
      setIsAllChecked(
        updated.length ===
          mberList.filter(
            (mber) =>
              mber.mberNm &&
              !participantList.some((participant) => participant.mberNo === mber.mberNo),
          ).length,
      );
    }
  };

  // 전체 선택/해제 토글
  const handleCheckAll = (e) => {
    if (e.target.checked) {
      const filtered = mberList.filter(
        (mber) =>
          mber.mberNm && !participantList.some((participant) => participant.mberNo === mber.mberNo),
      );
      setChkMberList(filtered);
      setIsAllChecked(true);
    } else {
      setChkMberList([]);
      setIsAllChecked(false);
    }
  };

  const isChecked = (mberNo) => {
    return chkMberList.some((mber) => mber.mberNo === mberNo);
  };

  // 모달
  const handleModalOpen = (type, item = []) => {
    const filtered = item.filter(
      (item) =>
        item.mberNm && !participantList.some((participant) => participant.mberNo === item.mberNo),
    );

    if (filtered.length === 0) {
      toast.error("초대 가능한 멤버가 존재하지 않습니다.");
      return;
    }

    setModalContent({ type, item: filtered });
    setModalOpen(true);
  };

  const ModalForm = ({ type, item }) => {
    const modalDetails = {
      invite: {
        title: "초대",
        body: `${item.length === 1 ? "해당" : `${item.length}명의`} 멤버를 초대하시겠습니까?`,
        onConfirm: () => handleInvite(item),
      },
      default: {
        title: "오류",
        body: "알 수 없는 오류가 발생했습니다.",
        onConfirm: () => setModalOpen(false),
      },
    };

    const modalInfo = modalDetails[type] || modalDetails.default;

    return (
      <div>
        <h2 className="font_20 font500 font_color_white">{modalInfo.title}</h2>
        <button onClick={() => setModalOpen(false)} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">{modalInfo.body}</span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={modalInfo.onConfirm}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const changeTabIndex = (idx) => {
    setTabIndex(idx);
    if (idx === 0) {
      setMberList(originCmpnyMber.slice(0, isMobile ? 7 : 10));
    } else {
      const list = searchedList.map((item) => ({
        mberNm: item.searchWord,
        mberNo: item.mberNo,
        cmpnyNo: item.cmpnyNo,
        cmpnyNm: item.cmpnyNm,
        moblphonNo: item.moblphonNo,
      }));
      setMberList(list.slice(0, isMobile ? 7 : 10));
      setMberTotal(searchedList.length);
    }
    setCurrentPage(1);
  };

  return (
    <ParticipantInviteModalContainer
      onClick={(e) => e.target === e.currentTarget && setIsInviteOpen(false)}>
      <div className="modalContainer font_14">
        <div>
          <button className="close" onClick={() => setIsInviteOpen(false)}>
            <AiOutlineClose />
          </button>
          <div className="search">
            <input
              type="text"
              onChange={(e) => setSearched(e.target.value)}
              onKeyDown={(e) => handleEnterKey(e, handleSearch)}
              placeholder={
                isGrp
                  ? "닉네임을 입력해주세요."
                  : "휴대전화번호(숫자만 입력) 또는 이름을 입력해주세요."
              }
            />
            <button onClick={handleSearch}>검색</button>
          </div>
          {!isSearch && !isGrp && searchedList.length > 0 && (
            <ProjectDetailParticipantSearched
              searchedList={searchedList}
              handleSearchedMber={handleSearchedMber}
            />
          )}
          {!isSearch && !isGrp && (
            <ul className="tabMenu">
              <li
                className={tabIndex === 0 ? "active" : "inactive"}
                onClick={() => changeTabIndex(0)}>
                {project.orgNm}
              </li>
              <li
                className={tabIndex === 1 ? "active" : "inactive"}
                onClick={() => changeTabIndex(1)}>
                외부업체
              </li>
            </ul>
          )}
          <ProjectDetailParticipantList
            mberTotal={mberTotal}
            currentPage={currentPage}
            onPageChange={onPageChange}
            mberList={mberList}
            isGrp={isGrp}
            isSearch={isSearch}
            handleCheck={handleCheck}
            handleCheckAll={handleCheckAll}
            isChecked={isChecked}
            isAllChecked={isAllChecked}
            handleModalOpen={handleModalOpen}
            chkMberList={chkMberList}
            participantList={participantList}
            searched={searched}
          />
        </div>
      </div>
      <Modal
        modalState={modalOpen}
        handleModalState={() => setModalOpen(false)}
        html={<ModalForm type={modalContent?.type} item={modalContent?.item} />}
        w="300px"
        h="auto"
      />
    </ParticipantInviteModalContainer>
  );
};

export default ProjectDetailParticipantModal;
