import React from "react";
import { Choice, ModalBackground } from "../../styled/common";
import ModalHeader from "./modalHeader";

// 여러 개 중 하나 선택
const ChoiceModal = ({
  title = "",
  message = [],
  active = null,
  handleConfirm = null,
  handleCancel = null,
  handleChangeOption = null,
  options = [],
}) => {
  return (
    <ModalBackground>
      <Choice>
        <ModalHeader title={title} handleCancel={handleCancel} />
        <div className="modalContent">
          <div className="message">
            <strong>{message}</strong>
          </div>
          <div className="choiceOption">
            <ul>
              {options.map((item) => (
                <li
                  onClick={() => handleChangeOption(item)}
                  className={item === active ? "active" : ""}>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="modalActions">
            <button onClick={handleCancel}>닫기</button>
            <button onClick={handleConfirm}>확인</button>
          </div>
        </div>
      </Choice>
    </ModalBackground>
  );
};

export default ChoiceModal;
