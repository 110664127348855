import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router";
import { Link } from "react-router-dom";
import CustomModal from "../customModal";
import Modal from "../modal";

const BoardView = ({userName, cmpnyNo, isShop}) => {
  const navigater = useNavigate();
  const [notice, setNotice] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const params = useParams();
  const noticeNo = params.noticeNo;
  const location = useLocation();
  const queryPage = location?.state?.page || 0;
  const searchQuery = location?.state?.keyword || "";

  let urlData = "";
  if(isShop){
    const {url} = useOutletContext();
    urlData = url
  }
  const moveToUrl = searchQuery ? 
  `/shop/${urlData}/search?keyword=${searchQuery}` : 
  `/shop/${urlData}/shoppingNotice?page=${queryPage}`;

  useEffect(() => {
    getNotice();
  }, [])

  const getNotice = async () => {
    const req = {noticeNo : noticeNo};
    const response = await axios.post('/api/notice', req);
    setNotice(response.data);
  }

  const deleteNotice = async () => {
    const req = {
      noticeNo: noticeNo,
      cmpnyNo: cmpnyNo,
      delYn: "Y",
      createMberId : userName,
      updateMberId : userName,
      gbn: "N"
    }

    const response = await axios.put("/api/notice", req);
    if(response.data.success === true){
      navigater("/mypage/register", {state : {noticeDelete: true}});
    }
  }
  
  return(
    <div>
      <div className="inner_content_wrap">
        <div className="borad_info">
          <span className="font_30 font500 font_color_black text_left">{notice.noticeSj}</span>
          <div className="author_info" style={{display : "flex", justifyContent : "space-between", alignItems:"center"}}>
            <div>
              <span className="author_name font_14 font_color_gray font300">
                <span className="sound_only">작성자</span>
                <i className="fa fa-pencil-square-o" aria-hidden="true" /> {notice.createMberId}
              </span>
              <span className="author_date font_14 font_color_gray font300">
                <span className="sound_only">작성일</span>
                <i className="fa fa-clock-o" aria-hidden="true" /> {notice.createDt}
              </span>
            </div>
            {
              // isShop &&
              <section 
                id="secBrdcrmb" 
                className="font_14 text_right" 
                style={{fontWeight : "400"}}
              >
                <Link to={isShop ? `/shop/${urlData}/shoppingNotice` : `/mypage/register`}>
                  <span style={{cursor : "pointer"}}>전체 공지사항 보기</span>
                </Link>
              </section>
            }
          </div>

          <div className="board_content">
            <p className="board_content_inner" style={{whiteSpace : "pre-wrap", textAlign : "left"}}>
              {notice.noticeCn}
            </p>
          </div>
          <div className="content_control">
            {
              !isShop &&
              <>
                <button 
                  type="button" 
                  className="btn_board_edit" 
                  onClick={() => navigater("/mypage/registerNoticeWrite", {state : {isEdit : true, notice : notice}})}
                >
                  수정
                </button>
                {/* <button type="button" className="btn_board_reply" >답변</button> */}
                <button 
                  onClick={() => setDeleteOpen(true)} 
                  type="button" 
                  className="btn_board_delete"
                >
                  삭제
                </button>
                {/* <button type="button" className="btn_board_new" >등록</button> */}
                <button 
                  type="button" 
                  className="btn_board_list white" 
                  onClick={() => navigater("/mypage/register")}
                >
                  목록
                </button>
              </>
            }
            {
              isShop && 
              <button
                type="button"  
                className="btn_board_list white" 
                style={{width:"53.33px", borderRadius : "3px", background : "#4e515d"}}
                onClick={() => navigater(moveToUrl)}
              >
                목록
              </button>
            }
          </div>
          <ul className="content_paging">
            {/* {prevNotice &&
              <li>
                <span className="font_16 font300 font_color_gray content_paging_info"><i className="fa fa-angle-up" aria-hidden="true" /> 이전글</span>
                <a onClick={moveToPrevPage} style={{cursor : "pointer"}} className="content_prev font_18 font_color_black">{prevNotice?.noticeSj}</a>
                <span className="font_16 font300 font_color_gray content_paging_date"><span className="sound_only">등록일</span>{prevNotice?.createDt}</span>
              </li>}
            {nextNotice && 
              <li>
                <span className="font_16 font300 font_color_gray content_paging_info"><i className="fa fa-angle-down" aria-hidden="true" /> 다음글</span>
                <a onClick={moveToNextPage} style={{cursor : "pointer"}} className="content_next font_18 font_color_black">{nextNotice?.noticeSj}</a>
                <span className="font_16 font300 font_color_gray content_paging_date"><span className="sound_only">등록일</span>{nextNotice?.createDt}</span>
              </li>} */}
          </ul>
        </div>
      </div>
      <Modal
        modalState={deleteOpen}
        handleModalState={() => setDeleteOpen(false)}
        html={<CustomModal 
          title={"공지사항 삭제"} 
          content={"공지사항을 삭제 하시겠습니까?"} 
          handleConfirm={deleteNotice} 
          handleCancel={() => setDeleteOpen(false)} 
        />}
        w="300px"
        h="auto"
      />
    </div>
  )
};

export default BoardView;