import React, { useEffect, useState } from "react";
import { ResultNotifyContainer } from "../styled/selfService";
import { storageType } from "../../../mypage/kiosk/component/function/kiosk";
import StorageItem from "./storageItem";
import { useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";

const ResultNotify = ({ transformValue, details, setActive }) => {
  const navigate = useNavigate();

  const [storage, setStorage] = useState({});
  const [checked, setChecked] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    const categorizedData = details.reduce(
      (acc, item) => {
        const type = item.type;
        if (type >= 0 && type <= 2) {
          acc[type].items.push(item);
        }
        return acc;
      },
      { 0: { items: [] }, 1: { items: [] }, 2: { items: [] } },
    );

    setStorage(categorizedData);
  }, [details]);

  const toggleSelectAll = () => {
    if (isAllSelected) {
      setChecked([]);
    } else {
      console.log(storage);

      const allIds = Object.values(storage).flatMap((type) => type.items.map((item) => item.id));
      setChecked(allIds);
    }

    setIsAllSelected(!isAllSelected);
  };

  const toggleCheckbox = (e) => {
    const { name } = e.target;
    const id = parseInt(name, 10);

    if (checked.includes(id)) {
      setChecked((data) => data.filter((item) => item !== id));
    } else {
      setChecked((data) => [...data, id]);
    }
  };

  const handlePickUpRequest = async () => {
    if (checked.length === 0) {
      toast.error("선택한 보관함이 존재하지 않습니다.");
      return;
    }

    const url = "/api/pickup-request";
    const body = { historyIds: [...checked], reserveId: details[0].reserveId };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        setActive(3);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const calculateTime = (item) => {
    if (!item) return "";

    const { reservationDay, reservationStartTime } = item;
    const reservationDateTime = moment(
      `${reservationDay} ${reservationStartTime}`,
      "YYYY-MM-DD HH:mm",
    );
    const currentTime = moment();
    const duration = moment.duration(currentTime.diff(reservationDateTime));

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    const totalMinutes = hours * 60 + minutes;
    // const totalFee = Math.ceil(totalMinutes / 10) * ratePerTenMinutes;

    const usageTimeString = `${hours}시간${minutes > 0 ? ` ${minutes}분` : ""}`;
    // const feeString = `총 ${totalFee}원이 추가로 부과됩니다.`;

    return usageTimeString;
  };

  return (
    <ResultNotifyContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      {details && details.length > 0 ? (
        <div className="result">
          <div className="info">
            <div className="resultInfo">
              <div className="user">
                <div>
                  <span className="tit">성함: </span>
                  <span className="con">
                    {details[0]?.mberNm
                      ? `${details[0]?.mberNm[0]}${
                          details[0]?.mberNm.length > 1 ? "*" : ""
                        }${details[0]?.mberNm.substring(2)}`
                      : ""}
                  </span>
                </div>
                <div>
                  <span className="tit">전화번호: </span>
                  <span className="con">
                    {details[0]?.tel
                      ? `${details[0]?.tel.substring(0, 3)}****${details[0]?.tel.substring(7)}`
                      : ""}
                  </span>
                </div>
              </div>
              <button className="toggleButton" onClick={toggleSelectAll}>
                {isAllSelected ? "전체해제" : "전체선택"}
              </button>
            </div>
            <div className="storageInfo">
              {Object.keys(storage).map((typeKey) =>
                storage[typeKey].items.length > 0 ? (
                  <div className="type">
                    <p>{storageType[typeKey].label}</p>
                    <ul>
                      {storage[typeKey].items.map((item, index) => (
                        <StorageItem
                          item={item}
                          checked={checked}
                          toggleCheckbox={toggleCheckbox}
                        />
                      ))}
                    </ul>
                  </div>
                ) : null,
              )}
            </div>
            <div className="feeInfo">
              <span>
                고객님의 총사용 시간은 {calculateTime(details[0])}입니다.
                {/* <br /> */}
                {/* 그에 따른 요금은 10분당 200원으로 총 400원이 추가로 부과됩니다. */}
              </span>
            </div>
          </div>
          <div className="buttons">
            <button onClick={() => navigate("/self-service")}>취소</button>
            <button onClick={handlePickUpRequest}>신청</button>
          </div>
        </div>
      ) : (
        <div className="notDetails">예약 정보를 찾을 수 없습니다.</div>
      )}
    </ResultNotifyContainer>
  );
};

export default ResultNotify;
