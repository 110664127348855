import { useSelector } from "react-redux";
import FunctionBanner from "./functionBanner";
import { useEffect, useRef } from "react";

const Function = () => {
  const functionIndex = useSelector((state) => state.compoIndex.function);
  const refArr = useRef([]);

  useEffect(() => {
    refArr.current[functionIndex]?.scrollIntoView({ behavior: "auto" });
  }, [functionIndex]);

  return (
    <>
      <FunctionBanner />
      <div className="inner_content" id="service_wrap">
        {mainPage.map((item, i) => (
          <div key={item.id}>
            <div
              ref={(el) => (refArr.current[i] = el)}
              style={{ height: item.height }}
              id={item.id}
            />
            <div className="inner_title">
              <span className="font_48 font600 pdb_20">{item.label}</span>
              <span className="font_30 font300 pdb_20">
                {item.comment}
                <br />
                {item?.comment1}
              </span>
            </div>
            <div className="inner_content_wrap">
              <img src={item.img} />
              {item.id === "multipage" && (
                <video autoPlay loop muted poster="/img/main_video_poster.jpg">
                  <source src="/img/main_video.mp4" type="video/mp4" />
                  <strong>Your browser does not support the video tag.</strong>
                </video>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Function;
