import React from "react";
import OrderItem from "./orderItem";

const OrderList = ({
  title,
  orders,
  message,
  onClick,
  selected,
  renderExtraContent,
  checkedOrders,
}) => (
  <div className={title ? "inProgressOrders" : "newOrders"}>
    {title && (
      <div className="inProgressInfo">
        <span>{title}</span>
      </div>
    )}
    <ul>
      {orders && orders.length > 0
        ? orders.map((item) => (
            <OrderItem
              key={item.id}
              item={item}
              onClick={onClick}
              selected={selected}
              checkedOrders={checkedOrders}
              extraContent={renderExtraContent ? renderExtraContent(item) : null}
            />
          ))
        : message && <li className="none">{message}</li>}
    </ul>
  </div>
);

export default OrderList;
