import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/css/pagination";
import { useMediaQuery } from "react-responsive";

const Products = ({ cmpnyNo, homepageInfo, url }) => {
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  useEffect(() => {
    if (cmpnyNo) {
      getProducts(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getProducts = async (cmpnyNo) => {
    const url = "/api/prductStock";
    const body = {
      cmpnyNo,
      offset: 0,
      pageNumber: 0,
      pageSize: 15,
      paged: true,
    };

    try {
      const res = await axios.post(url, body);
      setProducts(res.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="product">
      <div className="label">
        PRODUCTS
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
      <div className="products">
        <div className="info">
          상품
          <span>{homepageInfo.cmpnyNm}의 다양한 상품들을 보여드릴게요</span>
        </div>
        <div className="swiperContent">
          <Swiper
            ref={swiperRef}
            className="productSwiper"
            modules={[Pagination]}
            slidesPerView={isMobile ? 1.7 : isTablet ? 2.7 : 3}
            spaceBetween={isMobile ? 10 : isTablet ? 20 : 60}
            loop={true}
            speed={2000}
            pagination={{ type: "progressbar" }}>
            {products.map((item, i) => (
              <SwiperSlide key={i}>
                <div className="swiper-wrapper">
                  <div className="productSlide" id={i}>
                    <div
                      className="box"
                      onClick={() => navigate(`/shop/${url}/productsView/${item.prdNo}`)}>
                      <img
                        src={isPrdImg(item)}
                        onError={(e) => (e.target.src = "/img/prdct_08.png")}
                        alt="Product"
                      />
                      <div className="productInfo">
                        <span>{item.prductNm}</span>
                        <p>
                          {item.notitlePdCmPrice
                            ? `${item.notitlePdCmPrice.toLocaleString()}원`
                            : "가격문의"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Products;
