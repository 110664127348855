import React from "react";
import { StorageDetailContainer } from "../../styled/pickUp";
import { Buttons } from "../../styled/common";

const AddServiceTime = ({ transformValue, handleService, handleBackButton }) => {
  return (
    <StorageDetailContainer
      className="slideContent center"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="modalTit">서비스 시간 60분을 추가하시겠습니까?</div>
      <Buttons>
        <button onClick={handleBackButton}>취소</button>
        <button onClick={handleService}>확인</button>
      </Buttons>
    </StorageDetailContainer>
  );
};

export default AddServiceTime;
