import moment from "moment";
import React from "react";
import { useNavigate } from "react-router";

const NoticeList = ({ notices, url }) => {
  const navigate = useNavigate();
  const moveToPage = (noticeNo) => {
    navigate(`/shop/${url}/shoppingNoticeView/${noticeNo}`);
  };

  return (
    <ul className="notices">
      {notices.map((item) => (
        <li onClick={() => moveToPage(item.noticeNo)}>
          <div className="noticeCl">{item.noticeCl}</div>
          <div className="noticeSj">{item.noticeSj}</div>
          <div className="date">{moment(item.createDt).format("YYYY.MM.DD")}</div>
        </li>
      ))}
    </ul>
  );
};

export default NoticeList;
