import styled from "styled-components";

export const EditModalContainer = styled.div`
  .input_field {
    width: 100%;
    padding: 20px;
    > ul {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      > li {
        width: 100%;
        font-size: 16px;
        &.half_li {
          display: inline-block;
          width: calc(50% - 5px);
        }
        &:last-child {
          margin-bottom: 0;
        }
        .file_import label {
          height: 40px;
          width: 40px;
          line-height: 40px;
        }
        .preview {
          .thumbnailImages {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 10px;
          }
          li {
            width: calc((100% - 40px) / 5);
            height: 100%;
            aspect-ratio: 1;
            margin-bottom: 0;
            position: relative;
            img,
            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            button {
              position: absolute;
              top: 0;
              right: 0;
              font-size: 20px;
              border: none;
              overflow: hidden;
              background: rgba(0, 0, 0, 0.3);
              color: #fff;
              svg {
                display: block;
              }
            }
          }
        }
        .inputxt_normal.category {
          padding: 10px;
          font-weight: 300;
          border: 1px solid #ccc;
          border-radius: 0;
          color: #333333;
          position: relative;
          .selected {
            display: flex;
            align-items: center;
            gap: 10px;
            span:not(.not_category) {
              color: #0074ca;
            }
            button {
              border: none;
              background: #efefef;
              border-radius: 50%;
              padding: 3px;
              font-size: 14px;
              svg {
                display: block;
              }
            }
          }
          ul {
            position: absolute;
            max-height: 150px;
            overflow-y: auto;
            top: 100%;
            left: 0;
            background: #fff;
            border: 1px solid #ddd;
            z-index: 333;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
              rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            width: 100%;
            &::-webkit-scrollbar {
              width: 8px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #0074ca;
              border: 2px solid #fff;
            }
            &::-webkit-scrollbar-track {
              background-color: transparent;
            }
            li {
              cursor: pointer;
              margin-bottom: 5px;
              display: flex;
              gap: 5px;
              align-items: center;
              transition: 0.3s;
              &:hover {
                background: #efefef;
              }
            }
          }
        }
        .tit {
          margin-bottom: 5px;
          display: inline-block;
          em.point {
            font-size: 0;
            font-style: normal;
          }
          em.point::after {
            width: 12px;
            height: 12px;
            display: inline;
            content: "*";
            font-size: 18px;
            font-weight: 500;
            color: #0074ca;
            margin-left: 8px;
          }
        }
        input {
          font-size: 16px;
          padding: 10px;
        }
        input[type="number"] {
          outline: none;
          &:focus {
            box-shadow: 0 0 5px #9ed4ff;
            border: 1px solid #558ab7 !important;
          }
          ::-webkit-outer-spin-button,
          ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    }
  }
`;

export const DetailProductContainer = styled.div`
  ul {
    padding: 10px;
    li {
      width: 100%;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      &.media,
      &.tags {
        display: flex;
        .content {
          width: calc(100% - 100px);
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          img,
          video {
            width: calc((100% - 40px) / 5);
            aspect-ratio: 1;
          }
          span {
            width: auto;
            font-size: 14px;
          }
        }
      }
      span {
        &.tit {
          display: inline-block;
          font-size: 16px;
          font-weight: 700;
          width: 100px;
        }
        &.content {
          font-size: 16px;
        }
      }
    }
  }
`;
