import styled from "styled-components";

export const CustomInput = styled.label`
  position: relative;
  cursor: pointer;
  height: 20px;
  width: 20px;
  &.block {
    display: block;
    margin: auto;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    padding: 0;
    width: 0;
    height: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 6px;
    background-color: transparent;
    transition: all 0.3s ease;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg) scale(0);
      width: 7px;
      height: 13px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
    }
  }
  input:checked ~ .checkmark {
    /* background-color: #000; */
    /* border-color: #000; */
    &:after {
      display: block;
      /* animation: checkboxExpand 0.3s ease forwards; */
      transform: translate(-50%, -50%) rotate(45deg) scale(1);
      border-color: #0074ca;
    }
  }
  &:hover .checkmark {
    background-color: #efefef;
  }

  @keyframes checkboxExpand {
    0% {
      transform: translate(-50%, -50%) rotate(45deg) scale(0);
    }
    100% {
      transform: translate(-50%, -50%) rotate(45deg) scale(1);
    }
  }
`;

export const ProductFilterContainer = styled.div`
  table {
    font-size: 16px;
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
    th,
    td {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 20px 0;
    }
    th {
      background: #f5f5f5;
      width: 10%;
    }
    td {
      width: 40%;
      position: relative;
      .price {
        display: flex;
        align-items: center;
      }
      input {
        border: none !important;
        padding: 0px 20px;
        width: 100%;
        &:focus {
          border: none !important;
          box-shadow: none;
        }
      }
      .selected {
        padding: 0px 20px;
        cursor: pointer;
        .not {
          color: #666;
        }
        .category {
          color: #0074ca;
          display: flex;
          gap: 5px;
        }
        .deleteBtn {
          border-radius: 50%;
          border: none;
          background: #efefef;
          width: 20px;
          svg {
            margin: auto;
            display: block;
          }
        }
      }
      .categories {
        width: 100%;
        position: absolute;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        background: #fff;
        z-index: 33;
        height: 200px;
        overflow-y: auto;
        top: 102%;
        left: 0;
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #0074ca;
          border: 2px solid #fff;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        li {
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            background: #efefef;
          }
        }
      }
    }
  }
`;

export const FilterHandler = styled.div`
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 40px;
  .productSyncBtn {
    background-color: #0c9e8b;
    color: #fff;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
  }
  .deleteBtn {
    border: 1px solid rgb(221, 221, 221);
    background-color: rgb(238, 238, 238);
    height: 31px;
    padding: 5px 10px;
  }
  .filterBtn {
    padding: 5px 10px;
    font-size: 16px;
    border: 1px solid #333;
    height: 31px;
    background: #333;
    color: #fff;
    svg {
      display: block;
    }
  }
  .filter {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  select {
    padding: 5px 20px 5px 5px;
    &:focus-visible {
      outline: none;
    }
  }
`;
