import HomepageEndForm from "./component/homepageEndForm";
import MypageBanner from "../mypageBanner";

export default function HomepageEnd () {
  return(
    <div>
      <MypageBanner />
      <HomepageEndForm />
    </div>
  )
}