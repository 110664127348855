import React from "react";

const Refund = () => {
  return (
    <p>
      제 22 조 (환불 규정)
      <br /> ① 회원은 회사에 환불을 요구할 수 있습니다. 환불은 회사가 안내하는 정책 및 방법에 따라
      진행됩니다.
      <br />② 회사는 다음의 방식으로 환불을 진행합니다
      <br />
      <br />
      1. 보관함
      <br />
      a. 고객은 예약 시간 전까지 예약을 취소/변경 할 수 있으며, 회사 및 업주는 해당 규정에 따라
      취소처리 및 환불을 할 수 있습니다.
      <br />
      a-1.고객이 예약 시 설정한 보관 시작 시간 이후: 환불 불가
      <br />
      a-2.고객이 예약 시 설정한 보관 시작 시간 이전: 결제 금액 전액 환불
      <br />
      a-3.회사,업주의 귀책사유로 인해 서비스를 이용하지 못한 경우: 결제 금액 전액 환불
      <br />
      b. 회사의 환불은 고객이 결제한 방법과 같은 방법으로 환불함을 원칙으로 합니다.
      <br />
      <br />
      2. 픽업
      <br /> a. 취소 및 환불 규정은 전자상거래등 에서의 소비자보호에 관한 법률등 관련 법령을
      준수합니다. 주문 완료 후 음식 조리가 시작된 경우, 또는 개별적으로 상품이 생산된 경우에는 단순
      변심에 의한 청약철회 등은 할 수 없습니다.
      <br /> b. "회사" 및 "업주"는 별도의 취소 및 환불 관련 규정을 정할 수 있으며, 이 경우 별도계약
      및 이용조건상의 취소 및 환불 규정이 우선 적용됩니다. "회사" 및 "업주"는 "이용자" 가 일정 시간
      내에 매장에 방문하여 상품을 수령하지 않았음을 이유로 주문을 취소할 수 있습니다. 이 때 개별
      제작되어 재판매가 불가능한 상품 및 시 간의 경과에 의하여 재판매가 곤란할 정도로 가치가 현저히
      감소한 상품은 환불되지 않습니다.
      <br />
      <br />
      3. 배송
      <br /> a. 취소 및 환불 규정은 전자상거래등 에서의 소비자보호에 관한 법률등 관련 법령을
      준수합니다. 주문 완료 후 음식 조리가 시작된 경우, 또는 개별적으로 상품이 생산된 경우에는 단순
      변심에 의한 청약철회 등은 할 수 없습니다.
      <br /> b. "회사" 및 "업주"는 별도의 취소 및 환불 관련 규정을 정할 수 있으며, 이 경우 별도계약
      및 이용조건상의 취소 및 환불 규정이 우선 적용됩니다.
    </p>
  );
};

export default Refund;
