import React from "react";

const EventListItem = ({ icon, title, children, addClass }) => {
  return (
    <li>
      <div className="tit">
        {icon}
        <span>{title}</span>
      </div>
      <div className={`content ${addClass ? addClass : ""}`}>{children}</div>
    </li>
  );
};

export default EventListItem;
