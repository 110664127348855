import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { AiOutlineClose } from "react-icons/ai";
import { BsFileEarmarkMedical, BsFileEarmarkPlay } from "react-icons/bs";
import { toast } from "react-toastify";
import axios from "axios";
import { ProjectCommentFile } from "../../../common/styled/project";

const ProjectDetailCommentFiles = ({
  item: info,
  images,
  files,
  handleDeleteFile,
  type,
  handleImageClick,
}) => {
  const isShowDeleteButton = type === "new";
  const isFileType = {
    I: "이미지",
    V: "비디오",
    F: "파일",
  };

  const handleDownloadFile = async (file) => {
    if (isShowDeleteButton || !file || !file?.filePath) {
      return;
    }

    const { filePath } = file;
    const url = imgHandler(filePath);

    try {
      const res = await axios.post("/api/getFile", { url }, { responseType: "arraybuffer" });
      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filePath.split("/").pop();
      link.click();
      toast.success(`${chooseParticle(isFileType[file.fileType], "을를")} 저장하였습니다.`);
    } catch (error) {
      console.error(`파일 다운로드 실패:`, error);
    }
  };

  const calculateSwiperWidth = (length) => {
    if (!length) return 0;
    const count = Math.min(length, 3);
    return count * 100 + (count - 1) * 10;
  };

  return (
    <ProjectCommentFile>
      {images && images.length > 0 && (
        <Swiper
          pagination={true}
          slidesPerView={images.length > 2 ? 3 : images.length}
          spaceBetween={10}
          className="mySwiper"
          style={{ width: calculateSwiperWidth(images.length) }}>
          {images
            .filter(
              (item) =>
                !(info.deleteFiles?.some((deleted) => deleted.filePath === item.filePath) ?? false),
            )
            .map((item, idx) => (
              <SwiperSlide key={idx}>
                <div className="swiper-wrapper">
                  <div className="slide swiper-slide" id={idx}>
                    <div className="slide_text_warp text_center">
                      <div className="image">
                        {isShowDeleteButton && (
                          <div
                            className="deleteBox"
                            onClick={() => handleDeleteFile({ ...item, state: type })}>
                            <AiOutlineClose />
                          </div>
                        )}
                        <img
                          src={imgHandler(item.filePath) || ""}
                          onClick={() => !isShowDeleteButton && handleImageClick(images, idx)}
                          style={{
                            cursor: isShowDeleteButton ? "default" : "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      )}
      {files && files.length > 0 && (
        <div className="fileList">
          <ul>
            {files
              .filter(
                (item) =>
                  !(
                    info.deleteFiles?.some((deleted) => deleted.filePath === item.filePath) ?? false
                  ),
              )
              .map((item) => (
                <li
                  onClick={() => handleDownloadFile(item)}
                  style={{ cursor: !isShowDeleteButton ? "pointer" : "default" }}>
                  <div className="icon">
                    <BsFileEarmarkMedical />
                  </div>
                  <div className="tit">{item.fileNm}</div>
                  {isShowDeleteButton && (
                    <button
                      className="delete"
                      onClick={() => handleDeleteFile({ ...item, state: type })}>
                      <AiOutlineClose />
                    </button>
                  )}
                </li>
              ))}
          </ul>
        </div>
      )}
    </ProjectCommentFile>
  );
};

export default ProjectDetailCommentFiles;
