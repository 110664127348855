import styled from "styled-components";

// ProductListForm
export const CategoryList = styled.section`
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .categorys {
    .categoryInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      button.toggleButton {
        border: none;
        display: flex;
        font-size: 22px;
        background: transparent;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      transition: box-shadow 0.3s, padding 0.3s, height 0.3s, opacity 0.2s;
      opacity: 0;
      gap: 20px;
      &.active {
        padding: 20px;
        opacity: 1;
        li {
          cursor: pointer;
        }
      }
      li {
        width: calc((100% - 160px) / 9);
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .categorys ul {
      gap: 15px;
      &.active {
        padding: 15px;
      }
      li {
        width: calc((100% - 75px) / 6);
      }
    }
  }
  @media screen and (max-width: 767px) {
    .categorys ul {
      gap: 10px;
      &.active {
        padding: 10px;
      }
      li {
        width: calc((100% - 30px) / 4);
      }
    }
  }
`;

export const ScrollTopBtn = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #efefef;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  svg {
    font-size: 30px;
    display: block;
  }
  &.theme-E {
    border-radius: 5px;
    background: #000;
    color: #fff;
    padding: 5px;
    width: 50px;
    height: 50px;
    div {
      margin: auto;
      svg {
        font-size: 40px;
        stroke-width: 1px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    bottom: 6rem;
  }
`;

// ProductsViewForm
export const ContProductsView = styled.main`
  .totalSumm {
    display: block;
    h3 {
      text-align: right;
      border-bottom: 1px solid #efefef;
      padding-bottom: 20px;
      margin-bottom: 5px;
    }
  }
  &#contProductsView #secInfo .total {
    border-top: 1px solid #efefef;
  }
  &#contProductsView #secInfo .options .opt_amount .control span {
    width: 50px;
  }
  @media screen and (max-width: 767px) {
    .totalSumm {
      margin-left: 0;
      width: 100%;
    }
    &#contProductsView #secInfo .options dt {
      width: calc(100% - 130px);
      font-size: 16px;
      text-align: right;
    }
    &#contProductsView #secInfo .options .opt_amount dd {
      width: auto;
      display: block;
    }
  }
`;

export const PaginationContainer = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;
  text-align: center;
  padding-top: 60px;
`;

export const OrderContainer = styled.div`
  padding: 50px;
  width: 1280px;
  max-width: 100%;
  margin: 120px auto 0;
  font-size: 15px;
  span {
    display: block;
  }
  .test {
    font-size: 40px;
    font-weight: 700;
    margin: 50px auto;
  }
  .payment {
    display: flex;
    gap: 20px;
  }
  button {
    font-size: 20px;
    margin-top: 20px;
    padding: 10px 20px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  > div {
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 30px;
  }
  .products {
    p.info {
      margin-bottom: 20px;
    }
    ul li {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .prdImage {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .prdInfo {
        width: calc(100% - 110px);
      }
    }
  }
  .total {
  }
`;
