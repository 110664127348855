import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { IoMdSearch } from "react-icons/io";
import Modal from "../../../../../../common/modal";
import {
  GroupDetailSearchFormContainer,
  GroupDetailSettingMemberContainer,
} from "../../../styled/group";

const GroupDetailSettingMember = ({
  userInfo,
  grpMber,
  setGrpMber,
  grp,
  getGrpMbers,
  setIsInvite,
}) => {
  const { id } = useParams();
  const [members, setMembers] = useState([...grpMber]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const user = useSelector((state) => state?.user?.data[0]);
  const [changeMber, setChangeMber] = useState({});
  const [searchMber, setSearchMber] = useState("");

  const authOptions = [
    { value: 0, label: "마스터" },
    { value: 1, label: "부방장" },
    { value: 2, label: "간부" },
    { value: 3, label: "행동대장" },
    { value: 4, label: "일반" },
  ];

  useEffect(() => {
    setMembers([...grpMber]);
  }, [grpMber]);

  // 멤버 조회
  const getGrpMber = async () => {
    const url = "/api/groupMberList";
    const body = {
      groupNo: parseInt(id),
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 1,
      paged: false,
    };
    try {
      const res = await axios.post(url, body);
      if (res.data) {
        setGrpMber(res.data.content);
        setMembers(res.data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => {
    setModalOpen((modal) => !modal);
  };

  // 권한 변경
  const handleAuthChange = (item, newAuth) => {
    if (userInfo.auth >= item.auth) {
      toast.error("해당 멤버의 권한을 변경할 수 없습니다.");
      return;
    }
    if (userInfo.auth >= newAuth) {
      toast.error("이 권한으로는 변경할 수 없습니다.");
      return;
    }

    setChangeMber({
      seq: item.seq,
      groupNo: item.groupNo,
      mberNo: item.mberNo,
      delYn: "N",
      gbnValue: item.gbnValue,
      auth: newAuth,
      isAuthChanged: "Y",
    });
    setModalMsg("auth");
    setModalOpen(true);
  };

  // 멤버 삭제
  const handleDeleteChange = (item) => {
    if (userInfo.auth >= item.auth) {
      toast.error("해당 멤버는 삭제할 수 없습니다.");
      return;
    }
    setChangeMber({
      seq: item.seq,
      groupNo: item.groupNo,
      mberNo: item.mberNo,
      delYn: "Y",
      auth: item.auth,
      gbnValue: item.gbnValue,
    });
    setModalMsg("del");
    setModalOpen(true);
  };

  // 모달 확인
  const handleModalConfirm = async () => {
    if (modalMsg === "auth") {
      await updateMemberAuth();
    } else if (modalMsg === "del") {
      await deleteMember();
    }
    setModalOpen(false);
  };

  // 멤버 권한 변경 - api
  const updateMemberAuth = async () => {
    const url = "/api/groupMber";
    const body = {
      ...changeMber,
      createMberId: user.userName,
      updateMberId: user.userName,
      isAuthChanged: "Y",
    };
    const res = await axios.put(url, body);

    if (res.data.success) {
      toast.success("멤버 권한이 변경되었습니다.");
      getGrpMber();
    }
  };

  // 멤버 삭제 - api
  const deleteMember = async () => {
    const url = "/api/groupMber";
    const body = {
      ...changeMber,
      createMberId: user.userName,
      updateMberId: user.userName,
    };
    const res = await axios.put(url, body);

    if (res.data.success) {
      toast.success("모임 멤버가 삭제되었습니다.");
      getGrpMber();
    }
  };

  const ModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">
          {modalMsg === "auth" ? "권한 변경" : "멤버 삭제"}
        </h2>
        <button onClick={handleModalClose} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          {modalMsg === "auth" ? "변경" : "삭제"}하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={handleModalConfirm}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  // 가입한 멤버 검색
  const mberSearch = () => {
    if (searchMber) {
      setMembers(
        grpMber.filter(
          (user) =>
            (user.nickNm && user.nickNm.includes(searchMber)) ||
            (user.moblphonNo && user.moblphonNo.includes(searchMber)),
        ),
      );
    } else {
      setMembers([...grpMber]);
    }
  };

  return (
    <GroupDetailSettingMemberContainer>
      <Modal
        modalState={modalOpen}
        handleModalState={handleModalClose}
        html={ModalForm()}
        w="300px"
        h="auto"
      />
      <div className="inviteBtnArea">
        <GroupDetailSearchFormContainer style={{ margin: "15px 0px" }}>
          <input
            type="text"
            value={searchMber}
            onChange={(e) => setSearchMber(e.target.value)}
            onKeyDown={(e) => handleEnterKey(e, mberSearch)}
            placeholder="닉네임 혹은 전화번호를 입력하세요."
          />
          <button onClick={mberSearch}>
            <IoMdSearch />
          </button>
        </GroupDetailSearchFormContainer>
        <div className="buttons">
          <button onClick={() => setIsInvite(true)}>멤버 초대</button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>이름</th>
            {/* <th>아이디</th>
        <th>이메일</th>
        <th>휴대번호</th> */}
            <th>가입날짜</th>
            <th>권한</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>
          {members.map((item) => (
            <tr key={item.mberNo}>
              <td>{grp.nickUseYn === "Y" && item.nickNm ? item.nickNm : "닉네임 미설정"}</td>
              {/* <td>{item.username}</td>
          <td>{item.email}</td>
          <td>
            {item.moblphonNo &&
              TelFormat(item.moblphonNo, /(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3")}
          </td> */}
              <td>{moment(item.createDt).format("YYYY-MM-DD HH:mm")}</td>
              <td>
                <select value={item.auth} onChange={(e) => handleAuthChange(item, e.target.value)}>
                  {authOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <button onClick={() => handleDeleteChange(item)}>삭제</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </GroupDetailSettingMemberContainer>
  );
};

export default GroupDetailSettingMember;
