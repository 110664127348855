import React from "react";

const NotitleInfo = () => {
  const NOTITLE_INFO = [
    ["대표전화: 042-272-5555", "상담시간: 09:00 - 17:00", "(토,일 공휴일 휴무)"],
    [
      "상호명: 노타이틀",
      "대표자명: 조윤호",
      "사업자등록번호: 118-87-01602",
      "통신판매업: 제 2022-대전서구-1316 호",
    ],
    [
      "주소: 대전광역시 서구 둔산남로 176, 2층(탄방동)",
      "팩스: 0303-3130-7344",
      "대표메일: helpnotitle@gmail.com",
      "Copyright © 2023 No-title. All Rights Reserved.",
    ],
  ];
  return (
    <div className="footer_content">
      <div className="footer_info">노타이틀</div>
      <div className="footer_list">
        {NOTITLE_INFO.map((group, index) => (
          <ul key={index}>
            {group.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default NotitleInfo;
