import styled from "styled-components";

export const SwiperContainer = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 1400px) {
    .GroupHeaderInner {
      width: 100%;
    }
  }
  @media screen and (max-width: 1050px) {
  }
  @media screen and (max-width: 767px) {
    .gnb {
      display: none;
    }
    .swiper-button-prev.swiper-button-white,
    .swiper-button-next.swiper-button-white {
      display: none;
    }
  }
`;

export const GroupDetailContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  .groupMember {
    width: 100%;
    .inviteArea {
      text-align: right;
      margin-bottom: 20px;
      button {
        padding: 15px 30px;
        border: none;
        background-color: #efefef;
        border-radius: 0;
        font-size: 18px;
      }
    }
    .members {
      max-width: 1000px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 50px;
      li {
        width: calc((100% - 60px) / 4);
        padding: 20px;
        box-sizing: border-box;
        background: #f1f3f4;
        border-radius: 30px;
        position: relative;
        display: flex;
        flex-direction: column;
        .memberTitle {
          height: 52px;
        }
        .compny {
          text-align: center;
          img {
            width: 100%;
            height: 100px;
            object-fit: contain;
          }
        }
      }
    }
  }

  /* 지도 축소 버튼 */
  #closeUpBtn2 {
    width: 10rem;
    border-radius: 0.25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    -webkit-clip-path: polygon(
      0 0,
      0 0,
      100% 0,
      100% 0,
      100% calc(100% - 15px),
      calc(100% - 15px) 100%,
      15px 100%,
      0 100%
    );
    clip-path: polygon(
      0 0,
      0 0,
      100% 0,
      100% 0,
      100% calc(100% - 15px),
      calc(100% - 15px) 100%,
      15px 100%,
      0 100%
    );
    height: 40px;
    font-size: 0.9rem;
    line-height: 14px;
    letter-spacing: 1.2px;
    transition: 0.2s 0.1s;
    background-image: linear-gradient(90deg, #1c1c1c, #0074ca);
    border: 0 solid;
    overflow: hidden;
  }

  @media screen and (max-width: 1400px) {
    .groupMember .members li {
      width: calc((100% - 60px) / 3);
    }
  }
  @media screen and (max-width: 1050px) {
    .groupMember .members {
      justify-content: space-between;
    }
    .groupMember .members li {
      width: calc((100% - 30px) / 2);
    }
  }
  @media screen and (max-width: 767px) {
    .groupMember .members {
      justify-content: center;
    }
    .groupMember .members li {
      width: calc((100% - 60px));
    }
  }
`;

export const FeedContainer = styled.div`
  margin: 7%;
  .cardList {
    max-width: 700px;
    margin: auto;
    margin-bottom: 5em;
    li {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      .cardHeader {
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.03);
        padding: 1.25rem;
        box-sizing: border-box;
        justify-content: space-between;
        .title {
          display: flex;
          align-items: center;
          gap: 7px;
          span {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: auto;
          }
          .info {
            display: flex;
            align-items: center;
            gap: 7px;
            width: calc(100% - 50px);
          }
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            overflow: hidden;
          }
          svg {
            font-size: 22px;
            margin-right: 7px;
          }
        }
        img,
        svg {
          display: block;
        }
        .dropdown {
          position: relative;
          cursor: pointer;
          font-size: 14px;
          & > svg {
            font-size: 20px;
          }
          ul {
            display: none;
            position: absolute;
            top: 150%;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            cursor: pointer;
            li {
              padding: 5px;
              cursor: pointer;
            }
          }
          &.active ul {
            display: block;
            z-index: 2;
            padding: 0;
            width: 150%;
            background-color: #fff;
          }
        }
      }
      .cardBody {
        padding: 1rem;
        .text {
          margin: 3rem 0;
          word-break: break-all;
          white-space: pre-line;
          .more {
            width: 100%;
            button {
              width: 100%;
              padding: 3px 0;
              background: transparent;
              color: #999;
              border: none;
              font-size: 12px;
            }
          }
          .contentDate {
            font-size: 10px;
            text-align: right;
            color: #c9c9c9;
            margin: 10px 0;
          }
        }

        .commentDel button {
          border: none;
          background: transparent;
          padding: 5px;
          svg {
            display: block;
            margin: auto;
          }
        }
        .commentTime {
          flex-grow: 1;
          text-align: right;
          text-overflow: clip;
          width: 17%;
          font-size: 8px;
        }
        .user {
          display: flex;
          align-items: center;
          flex-direction: column;
          .userContent {
            display: flex;
            align-items: center;
            & > img {
              width: 30px;
              height: 30px;
              object-fit: cover;
              border-radius: 50%;
              overflow: hidden;
            }
          }
          .comment {
            margin: 0;
            line-break: anywhere;
          }
          .more {
            width: 100%;
            button {
              width: 100%;
              padding: 3px 0;
              background: transparent;
              color: #999;
              border: none;
            }
          }
        }
      }
      .cardFooter {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        form {
          display: flex;
          justify-content: space-between;
          width: 100%;
          input {
            border: none;
            padding: 1rem;
            box-sizing: border-box;
            width: 100%;
          }
          button {
            border: none;
            padding: 1rem;
            color: #fff;
            display: flex;
          }
        }
      }
    }
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 150px;
    .cardList li .cardHeader .title {
      font-size: 14px;
      gap: 10px;
      .info {
        flex-direction: column;
        align-items: baseline;
        gap: 0;
      }
    }
    .cardList li .cardHeader .title span:last-child {
      margin-left: 0;
    }
    .cardList li .cardHeader .title svg {
      /* display: none; */
    }
  }
`;

export const HambergerMenu = styled.div`
  position: fixed;
  bottom: 80px;
  right: 1rem;
  z-index: 10;
  cursor: pointer;
  .feedSideNav {
    position: relative;
    svg {
      font-size: 30px;
      color: #fff;
      display: block;
    }
    .burgerBox {
      background-color: #0074ca;
      border-radius: 17px;
      z-index: 3;
      box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
      &:has(input:checked) {
        background: #f0f0f0;
      }
      .burger {
        position: relative;
        width: 60px;
        height: 60px;
        background: transparent;
        cursor: pointer;
        display: block;
        padding: 15px;
        input {
          display: none;
          &:checked {
            & ~ div {
              width: 30px;
              height: 30px;
              span:nth-of-type(1) {
                transform: rotate(45deg);
                top: 3px;
                left: 5px;
                background: #999;
              }
              span:nth-of-type(2) {
                width: 0%;
                opacity: 0;
              }
              span:nth-of-type(3) {
                transform: rotate(-45deg);
                top: 23.5px;
                left: 5px;
                background: #999;
              }
            }
          }
        }
        div {
          width: 30px;
          height: 25px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          span {
            display: block;
            position: absolute;
            height: 4px;
            width: 100%;
            background: #fff;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
            &:nth-of-type(1) {
              top: 0px;
              transform-origin: left center;
            }
            &:nth-of-type(2) {
              top: 50%;
              transform: translateY(-50%);
              transform-origin: left center;
            }
            &:nth-of-type(3) {
              top: 100%;
              transform-origin: left center;
              transform: translateY(-100%);
            }
          }
        }
      }
    }
    .sidebar {
      .feedNavList {
        li {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -10;
          opacity: 0;
          transition: 0.25s ease-in-out;
          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            background-color: #0074ca;
            border-radius: 17px;
            width: 60px;
            height: 60px;
            position: relative;
            p {
              position: absolute;
              bottom: -15px;
              left: 0;
              width: 100%;
              background: #999;
              text-align: center;
              border-radius: 5px;
              font-size: 10px;
              color: #fff;
              opacity: 0;
            }
          }
          &:nth-child(2) {
            div {
              background: #8aa6db;
            }
          }
          &:nth-child(3) {
            div {
              background: #c3955a;
            }
          }
          &:nth-child(4) {
            div {
              background: #404756;
            }
          }
          &.row {
            div {
              background: #317f43;
            }
          }
        }
        &.open li {
          z-index: 1;
          opacity: 1;
          &:nth-child(1) {
            bottom: 85px;
          }
          &:nth-child(2) {
            bottom: calc(85px * 2);
          }
          &:nth-child(3) {
            bottom: calc(85px * 3);
          }
          &:nth-child(4) {
            bottom: calc(85px * 4);
          }
          &.row {
            left: -72px;
            bottom: 0;
          }
          div {
            box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
            p {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    bottom: 90px;
  }
`;

export const BigImageContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9001;
  .imageContent {
    position: relative;
    width: 80%;
    height: 80%;
    background: rgba(0, 0, 0, 0.7);
    padding: 30px;
    .mySwiper {
      width: 100%;
      height: 100%;
    }
    .smallBtn {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 11;
      padding: 5px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 17px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .mySwiper {
      position: static;
    }
    .swiper-pagination {
      color: #fff;
    }
  }
  @media screen and (max-width: 767px) {
    .imageContent {
      width: 100%;
    }
  }
`;

export const CreateGroupContainer = styled.div`
  input[type="text"] {
    width: 100%;
    display: inline-block;
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid #ccc;
    border-radius: 0;
    color: #333333;
  }
  input[type="checkbox"] {
    appearance: none;
    width: 22px;
    height: 22px;
    border: 1px solid #ccc;
    margin-right: 5px;
    padding: 0;
    &:checked {
      border: 0.3em solid #fff;
      outline: 1px solid #ccc;
      background-color: #0074ca;
      box-shadow: none;
    }
  }
  .file_import {
    label {
      position: relative;
      display: inline-block;
      text-align: center;
      width: 110px;
      height: 50px;
      line-height: 54px;
      font-size: 18px;
      font-weight: 300;
      color: #fff;
      background: #333333;
      border: 0;
      border-radius: 0;
    }
    input#wk_file {
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
    .preview {
      width: 100%;
      border: 1px solid #ccc;
      height: 250px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .deleteBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        border: none;
        background: rgba(0, 0, 0, 0.3);
        color: #fff;
        font-size: 25px;
        top: 0;
        right: 0;
      }
      .notImage {
        width: 100%;
        height: 100%;
        background: #efefef;
        span {
          font-size: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  ul {
    width: 600px;
    margin: auto;
    li {
      font-size: 18px;
      div {
        &.tit {
          margin-bottom: 3px;
          em {
            color: #ccc;
          }
        }
      }
      margin-bottom: 20px;
      .radio-inputs {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        border: 1px solid #ccc;
        padding: 0.25rem;
        width: 100%;
        .radio {
          flex: 1 1 auto;
          text-align: center;
          input {
            display: none;
          }
          .name {
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            border-radius: 0.5rem;
            border: none;
            padding: 5px 0px;
            color: rgba(51, 65, 85, 1);
            transition: all 0.15s ease-in-out;
          }
        }
      }
      .radio-inputs .radio input:checked + .name {
        background-color: #0074ca;
        font-weight: 600;
        color: #efefef;
      }
      &.grpName {
        .content {
          display: flex;
        }
        button {
          height: 44px;
          font-size: 16px;
          line-height: 44px;
          width: 110px;
          line-height: 54px;
          font-size: 18px;
          font-weight: 300;
          color: #fff;
          background: #333333;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .btnArea {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 50px;
    button {
      border: none;
      background: #0074ca;
      text-align: center;
      width: 110px;
      height: 50px;
      line-height: 54px;
      font-size: 18px;
      font-weight: 300;
      color: rgb(255, 255, 255);
      border: 0px;
      border-radius: 0px;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 767px) {
    margin: 7% 10px;
    ul {
      width: 100%;
    }
    .file_import label,
    .btnArea button {
      width: 100px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
    }
  }
`;

export const GroupInviteContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  .inviteContent {
    background: #fff;
    padding: 20px;
    position: relative;
    height: 380px;
    .closeBtnArea {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 25px;
      button {
        border: none;
        background: transparent;
      }
    }
    .search_box {
      button {
        background: #0074ca;
      }
    }
    .searched {
      height: 200px;
      margin-top: 30px;
      overflow: auto;
      ul {
        li {
          margin-bottom: 2px;
          padding-bottom: 2px;
          display: flex;
          align-items: center;
          font-size: 16px;
          text-align: center;
          border-bottom: 1px solid #efefef;
          &:first-child {
            background: #efefef;
            padding: 5px 0;
          }
          div {
            padding: 3px 0;
            &:nth-child(1) {
              width: 30%;
            }
            &:nth-child(2) {
              width: 40%;
            }
            &:nth-child(3) {
              width: 30%;
              button {
                background: #0074ca;
                color: #fff;
                padding: 5px 10px;
                border-radius: 10px;
                font-size: 13px;
                border: none;
                &.signedUp {
                  background: #777;
                  cursor: default;
                }
              }
            }
          }
        }
      }
      .msg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        height: 200px;
        button {
          background: #efefef;
          padding: 10px;
          border-radius: 5px;
          border: none;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .inviteContent {
      width: 400px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    .inviteContent {
      width: 100%;
    }
  }
`;

export const DeleteGrpContainer = styled.div`
  text-align: center;
  font-size: 18px;
  padding: 30px 0;
  .msg {
    .title {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      svg {
        font-size: 30px;
        color: #bf0000;
      }
    }
    .info p strong {
      color: #bf0000;
      font-weight: 500;
    }
  }
  .confirm {
    margin: 20px 0;
    input[type="checkbox"] {
      appearance: none;
      width: 22px;
      height: 22px;
      border: 1px solid #ccc;
      margin-right: 5px;
      padding: 0;
      &:checked {
        border: 0.3em solid #fff;
        outline: 1px solid #ccc;
        background-color: #0074ca;
        box-shadow: none;
      }
    }
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .btnArea {
    margin-top: 20px;
    button {
      border: none;
      background: #0074ca;
      text-align: center;
      width: 110px;
      height: 50px;
      line-height: 54px;
      font-size: 18px;
      font-weight: 300;
      color: rgb(255, 255, 255);
      border: 0px;
      border-radius: 0px;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    .msg .title {
      font-size: 20px;
    }
  }
`;

export const GroupApplyContainer = styled.div`
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  .applyContent {
    padding: 30px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .apply {
      position: relative;
      .close {
        position: absolute;
        top: -30px;
        right: -30px;
        font-size: 25px;
        cursor: pointer;
      }
    }
    input[type="checkbox"] {
      appearance: none;
      width: 22px;
      height: 22px;
      border: 1px solid rgb(204, 204, 204);
      margin-right: 5px;
      padding: 0px;
      &:checked {
        border: 0.3em solid rgb(255, 255, 255);
        outline: rgb(204, 204, 204) solid 1px;
        background-color: rgb(0, 116, 202);
        box-shadow: none;
      }
    }
    .list {
      width: 500px;
      tbody tr td {
        &:first-child {
          width: 10%;
        }
        &:last-child {
          display: flex;
          align-items: center;
          border: none;
          button {
            padding: 5px 0;
            &:first-child {
              background: #0074ca;
              color: #fff;
            }
            &:last-child {
              background: #efefef;
            }
          }
        }
      }
    }
    .btnArea {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 30px;
      button {
        padding: 10px 30px;
        font-size: 14px;
        &:first-child {
          background: #0074ca;
          color: #fff;
        }
        &:last-child {
          background: #efefef;
        }
      }
    }
    ul.pagination li {
      width: 7%;
    }
  }
`;

export const GroupJoinInfoContainer = styled.div`
  .myGrpContent {
    max-width: 600px;
    margin: auto;
    p {
      font-size: 18px;
      margin-bottom: 5px;
    }
    > div {
      margin: 10px 0;
      label {
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 7px;
      }
      input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
      }
      &.gbn label span {
        font-size: 20px;
      }
      &.nick {
        button {
          width: 150px;
          border: 1px solid transparent;
          background: #333333;
          color: #fff;
          padding: 10px;
        }
      }
    }
  }
`;

export const GroupSideMenu = styled.div`
  width: 240px;
  min-height: calc(-73px + 100vh);
  border-right: 1px solid rgb(239, 239, 239);
  background: #fff;
  font-size: 15px;
  .grpImgCover,
  .grpName,
  .buttons,
  .grpDelDt {
    padding: 15px;
    width: 100%;
    border-bottom: 1px solid #efefef;
  }
  .grpDelDt {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    strong.time {
      color: tomato;
    }
  }
  .grpImgCover {
    height: 160px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border: 1px solid #efefef;
    }
  }
  .grpName {
    font-size: 20px;
    font-weight: 600;
    word-wrap: break-word;
  }
  .buttons button {
    padding: 10px 0;
    width: 100%;
    text-align: center;
    border: none;
    background: #0074ca;
    color: #ffff;
    border-radius: 5px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    min-height: auto;
    .grpImgCover {
      height: 85px;
    }
    .grpImgCover,
    .grpName,
    .buttons,
    .grpDelDt {
      padding: 0;
      border-bottom: none;
    }
    .grpName {
      font-size: 16px;
      width: calc(100% - 60px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      gap: 0 10px;
      flex-wrap: wrap;
      .grpDelDt {
        flex-direction: row;
        gap: 5px;
      }
      .buttons {
        width: auto;
        button {
          font-size: 13px;
          width: 50px;
        }
      }
    }
  }
`;

export const GroupDetailViewContainer = styled.div`
  border-top: 1px solid #efefef;
  height: calc(-60px + 100vh);
  overflow: hidden;
  background: #efefef;
  ul.tabs {
    display: flex;
    align-items: center;
    li {
      padding: 10px 20px;
      box-shadow: 0 0 0 1px #efefef;
      background: #fff;
      width: 100px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      &.active {
        background: #0074ca;
        border: 1px solid #0074ca;
        color: #fff;
      }
    }
  }
  .grpContainer {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    height: calc(-61px + 100vh);
  }
  .grpInfoBody {
    max-width: 1000px;
    width: calc(100% - 240px);
    padding: 20px 10px 20px 20px;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .grpSection {
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: calc(100% - 71px);
      background: #fff;
      border-radius: 5px;
      overflow: hidden auto;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0074ca;
        border: 2px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    ul.tabs {
      justify-content: center;
      li {
        padding: 10px 5px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    overflow: hidden auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #0074ca;
      border: 2px solid #efefef;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ul.tabs {
      flex-wrap: wrap;
      li {
        width: 50%;
        padding: 0;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .grpContainer {
      flex-direction: column;
    }
    .grpInfoBody {
      max-width: none;
      width: 100%;
      padding: 10px;
      height: 100%;
      overflow: auto;
      .grpSection {
        min-height: calc(100vh - 440px);
        margin-bottom: 70px;
      }
    }
  }
`;

export const GroupDetailHomeContainer = styled.div`
  padding: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const GroupDetailFeedContainer = styled.div`
  border-radius: 5px;
  ul.feeds {
    > li {
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      margin: auto;
      margin-bottom: 15px;
      max-width: 550px;
      &.advertisement {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .feedHeader {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        .writerInfo {
          display: flex;
          font-size: 13px;
          align-items: center;
          gap: 10px;
          width: calc(100% - 32px);
          > div {
            width: calc(100% - 40px);
          }
          img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
          }
          .nickNm {
            max-width: calc(50%);
            width: 100%;
            font-weight: 700;
          }
        }
        .dropdown {
          position: relative;
          z-index: 3;
          font-size: 18px;
          > ul {
            position: absolute;
            border: 0;
            left: 50%;
            width: 70px;
            background: #fff;
            text-align: center;
            transform: translateX(-50%);
            border: 1px solid #ddd;
            border-radius: 5px;
            font-size: 13px;
            li {
              padding: 3px 0;
              cursor: pointer;
              &:last-child {
                border-top: 1px solid #ddd;
              }
            }
          }
        }
      }
      .feedBody {
        background: #fff;
        border: 1px solid #efefef;
        border-left: none;
        border-right: none;
        padding: 15px;
        .images {
          height: 400px;
          width: 100%;
        }
        .content {
          font-size: 14px;
          white-space: pre-wrap;
          margin: 10px 0;
        }
        .more {
          border: none;
          background: transparent;
          color: #999;
          margin: 5px 0;
        }
      }
      .feedFooter {
        form {
          position: relative;
          width: 100%;
          height: 40px;
          input[type="text"] {
            width: 100%;
            height: 100%;
            border: none;
            padding: 10px;
          }
          button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            border: none;
            display: flex;
            padding: 5px;
            color: #fff;
            border-radius: 5px;
          }
        }
      }
      .commentList {
        .comments {
          border-bottom: 1px solid #efefef;
        }
        .more {
          text-align: center;
          background: #efefef;
          border: none;
          font-size: 12px;
          width: 100%;
          padding: 7px;
        }
      }
      ul.comments {
        > li {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 8px 10px;
          &:last-child {
            border-bottom: none;
          }
          img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 50%;
            overflow: hidden;
            outline: 1px solid #d1d1d1;
          }
          .commentContentBox {
            display: flex;
            flex-direction: column;
            width: 70%;
            .commentUserName {
              font-weight: 700;
            }
            .comment {
              margin: 0px;
              line-break: anywhere;
            }
          }
          .commentDeleteButton {
            color: #5e5e5e;
            border: none;
            background: transparent;
            padding: 5px;
            display: flex;
          }
          .commentTime {
            flex-grow: 1;
            text-align: right;
            text-overflow: clip;
            width: 17%;
            font-size: 10px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .grpContainer {
      flex-direction: column;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0074ca;
        border: 2px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      flex-direction: column;
      overflow-y: auto;
    }
  }
`;

export const GroupDetailMemberContainer = styled.div`
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  .memberHeader {
    margin-bottom: 15px;
    border-bottom: 1px solid #efefef;
    padding-bottom: 15px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      .info {
        font-size: 20px;
        span {
          color: #0074ca;
          font-weight: 700;
        }
      }
      button {
        border: 1px solid #0074ca;
        background: transparent;
        color: #0074ca;
        font-size: 16px;
        padding: 6px 10px;
      }
    }
    .memberSearch {
      position: relative;
      input[type="text"] {
        width: 100%;
        border: 1px solid #efefef;
        padding: 10px 30px 10px 10px;
      }
      button {
        border: none;
        background: transparent;
        font-size: 18px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
      }
    }
  }
  .memberList {
    width: 100%;
    ul {
      li {
        &.notMber {
          justify-content: center;
          font-size: 14px;
        }
        display: flex;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid #efefef;
        padding-bottom: 15px;
        margin-bottom: 15px;
        .profileImage {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .profileInfo {
          width: calc(100% - 60px);
          font-weight: 700;
          font-size: 14px;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
`;

export const GroupDetailSettingContainer = styled.div`
  height: 100%;
  .slideWrapper {
    flex-flow: row nowrap;
    display: flex;
    flex: 1;
    width: 100%;
    transition: transform 0.5s ease;
    height: 100%;
    overflow: hidden;
    margin: auto;
    .slideContent {
      flex: 0 0 50%;
      transform: translateX(0);
      flex-basis: 100%;
      flex-shrink: 0;
      transition: 0.3s;
      width: 100%;
      overflow-x: hidden;
      height: 100%;
      padding: 15px;
      box-sizing: border-box;
      overflow: hidden auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0074ca;
        border: 2px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
  }
  .label {
    font-size: 18px;
    font-weight: 600;
    max-width: 550px;
    margin: 0 auto 15px;
  }
  ul.settingMenu {
    font-size: 15px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    padding: 15px 0;
    max-width: 550px;
    margin: auto;
    li {
      font-size: 15px;
      border-bottom: 1px solid #efefef;
      padding-bottom: 15px;
      margin-bottom: 15px;
      cursor: pointer;
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
  .backToMenu {
    border-bottom: 1px solid #efefef;
    padding-bottom: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 550px;
    margin: auto;
    button {
      border: none;
      background: transparent;
      font-size: 20px;
      display: flex;
    }
    span {
      font-size: 17px;
    }
  }
`;

export const GroupDetailSettingProfileContainer = styled.div`
  max-width: 550px;
  margin: auto;
  .userProfile {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    > div {
      text-align: center;
      width: 100%;
      margin: 15px auto;
      position: relative;
      display: flex;
    }
    input {
      border: 1px solid #efefef;
      padding: 10px;
      border-radius: 5px;
      width: 100%;
    }
    .gbn {
      position: relative;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input {
        padding: 10px 100px 10px 10px;
        &:focus {
          outline: none;
        }
      }
      span {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }
    }
    button {
      border: 1px solid transparent;
      padding: 10px;
      background: #333;
      color: #efefef;
      flex: none;
    }
  }
  .submitButton {
    margin: auto;
    display: block;
    padding: 10px 25px;
    border: none;
    border-radius: 5px;
    color: #efefef;
    background: #0074ca;
    font-size: 16px;
  }
`;

export const GroupDetailSearchContainer = styled.div`
  padding: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  .label {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      font-size: 18px;
      font-weight: 700;
      strong {
        margin-left: 5px;
        color: #0074ca;
      }
    }
    button {
      border: none;
      background: transparent;
      color: #999;
    }
  }
  .eChanggo {
    min-height: calc(100% - 55px);
  }
  .productList {
    margin: 30px 0;
    ul {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      li {
        width: calc((100% - 45px) / 4);
        padding: 15px;
        .preview {
          > div {
            background: #999;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
          }

          img {
            width: 100%;
            height: 180px;
            object-fit: contain;
          }
        }
        .product {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          margin-top: 10px;
          .prductNm,
          .cnsmrPc {
            font-weight: 700;
          }
          span:last-child {
            color: #999;
          }
          .cmpnyMbers {
            display: flex;
            flex-wrap: wrap;
            gap: 5px 10px;
            margin-top: 10px;
            span {
              background: #0074ca;
              padding: 3px 10px;
              border-radius: 10px;
              color: #efefef;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .productList ul li {
      width: calc((100% - 15px) / 2);
      .product .prductNm {
        font-weight: 500;
      }
    }
  }
`;

export const GroupDetailSearchMapContainer = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
`;

export const GroupDetailSearchFormContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 15px 0 30px;
  font-size: 14px;
  align-items: center;
  input[type="text"] {
    border: 1px solid #efefef;
    padding: 10px;
    width: 300px;
    height: 40px;
  }
  button {
    display: flex;
    font-size: 21px;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #0074ca;
    background: #0074ca;
    color: #efefef;
  }
`;

export const GroupDetailSearchBlogContainer = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
`;

export const BlogItemContainer = styled.li`
  width: calc((100% - 45px) / 4);
  padding: 15px;
  border: 1px solid #efefef;
  border-radius: 5px;
  cursor: pointer;
  .image {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 15px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .info {
    font-size: 15px;
    .title {
      font-weight: 600;
      display: block;
    }
    .cmpnyNm {
      color: #999;
    }
  }
  .mbers {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 10px;
    margin-top: 15px;
    span {
      background: #0074ca;
      padding: 3px 10px;
      border-radius: 10px;
      color: #fff;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 767px) {
    width: calc((100% - 15px) / 2);
    .info span {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
`;

export const GroupDetailSearchProjectContainer = styled.div`
  .projectContent {
    width: 100%;
    margin-bottom: 20px;
  }
  ul {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    transition: 0.3s;
    li {
      padding: 10px;
      outline: 1px solid #efefef;
      border-radius: 5px;
      width: calc((100% - 90px) / 5);
      position: relative;
      cursor: pointer;
      margin: 1px;
      p {
        width: 100%;
        margin: 30px 0 15px;
        font-size: 15px;
        position: relative;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: 38px;
      }
      .userLength {
        display: flex;
        align-items: center;
        gap: 5px;
        svg {
          font-size: 20px;
        }
        display: flex;
        align-items: center;
      }
    }
  }
  @media screen and (max-width: 767px) {
    ul li {
      width: calc((100% - 20px) / 2);
    }
  }
`;

export const GroupDetailSettingMemberContainer = styled.div`
  padding: 30px 0;
  max-width: 550px;
  margin: auto;
  .buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 15px 0;
    gap: 5px;
    button {
      border: 1px solid rgb(0, 116, 202);
      background: transparent;
      color: rgb(0, 116, 202);
      font-size: 16px;
      padding: 6px 10px;
    }
  }
  table {
    width: 100%;
    border: 1px solid #efefef;
    border-collapse: collapse;
    font-size: 14px;
    th,
    td {
      border: 1px solid #efefef;
    }
    thead {
      tr th {
        background: #0074ca;
        color: #fff;
        padding: 10px 0;
        font-size: 16px;
        border-right: 1px solid #efefef;
        &:last-child {
          border-right: 1px solid #0074ca;
        }
      }
    }
    tbody {
      tr td {
        font-size: 14px;
        padding: 5px 0;
        text-align: center;
        select {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          padding-left: 10px;
          cursor: pointer;
          &:focus {
            box-shadow: none;
            border: none !important;
          }
        }
        button {
          width: 100%;
          height: 25px;
          border: none;
          background: transparent;
        }
      }
    }
  }
`;

export const GroupDetailSettingMembershipContainer = styled.div`
  padding: 30px 0;
  max-width: 550px;
  margin: auto;
  table {
    width: 100%;
    border: 1px solid #efefef;
    border-collapse: collapse;
    font-size: 14px;
    input[type="checkbox"] {
      appearance: none;
      width: 22px;
      height: 22px;
      border: 1px solid #ccc;
      margin-right: 5px;
      padding: 0;
      &:checked {
        border: 0.3em solid #fff;
        outline: 1px solid #ccc;
        background-color: #0074ca;
        box-shadow: none;
      }
    }
    th,
    td {
      border: 1px solid #efefef;
    }
    thead {
      tr th {
        background: #0074ca;
        color: #fff;
        padding: 10px 0;
        font-size: 16px;
        border-right: 1px solid #efefef;
        &:last-child {
          border-right: 1px solid #0074ca;
        }
      }
    }
    tbody {
      tr td {
        font-size: 14px;
        padding: 5px 0;
        text-align: center;
        select {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          padding-left: 10px;
          cursor: pointer;
          &:focus {
            box-shadow: none;
            border: none !important;
          }
        }
        button {
          width: calc(50% - 15px);
          height: 25px;
          border: none;
          background: transparent;
        }
      }
    }
  }
  .btnArea {
    display: flex;
    gap: 15px;
    align-items: center;
    button {
      padding: 10px 25px;
      border: none;
      background: #0074ca;
      color: #fff;
      font-size: 15px;
      &:last-child {
        background: #555;
      }
    }
  }
`;

export const GroupDetailSearchMapSelectCmpny = styled.div`
  position: absolute;
  width: 30%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  transform: translate(-100%);
  transition: 0.3s;
  opacity: 0;
  z-index: 10;
  &.active {
    transform: translateX(0);
    opacity: 1;
    > div > button {
      display: flex;
    }
  }
  > div {
    position: relative;
    width: 100%;
    height: 100%;
    > button {
      position: absolute;
      top: 10px;
      right: 0;
      display: flex;
      font-size: 20px;
      background: transparent;
      border: none;
      transform: translate(100%);
      width: 40px;
      height: 40px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 5px 5px 0;
      transition: 0.3s;
      display: none;
      border: 1px solid #fff;
      border-left: none;
      &:hover {
        border: 1px solid #0074ca;
        border-left: none;
        color: #0074ca;
        background: #efefef;
      }
    }
    .cmpny {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 14px;
      padding: 10px;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0074ca;
        border: 2px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      .cmpnyLogo {
        width: 100%;
        height: 100px;
        background: #efefef;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          background: #fff;
          border-radius: 5px;
        }
      }
      .cmpnyInfo {
        display: flex;
        flex-direction: column;
      }
      .cmpnyNm {
        font-weight: 600;
        cursor: pointer;
      }
      .cmpnyMbers {
        display: flex;
        flex-wrap: wrap;
        gap: 5px 10px;
        span {
          background: #0074ca;
          padding: 3px 10px;
          border-radius: 10px;
          color: #efefef;
          font-size: 12px;
        }
      }
      .portList {
        height: 200px;
        width: 100%;
        .mySwiper {
          width: 100%;
          height: 100%;
          .swiper-slide {
            width: 85%;
          }
          .slideImageContent {
            width: 100%;
            height: 100%;
            padding: 10px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .last {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              cursor: pointer;
              gap: 5px;
              .icon {
                font-size: 15px;
                padding: 10px;
                background: #efefef;
                border-radius: 50%;
                display: flex;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    width: 300px;
  }
  @media screen and (max-width: 767px) {
    width: 250px;
  }
`;

export const StockItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  gap: 15px;
  .product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    .stock {
      display: flex;
      flex-direction: column;
    }
    .prductNm,
    .cnsmrPc {
      font-weight: 700;
    }
    .cmpnyNm {
      background: #0074ca;
      padding: 2px 10px;
      border-radius: 10px;
      width: 100%;
      min-height: min-content;
      max-width: max-content;
      color: #fff;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: row;
    .preview {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .product {
      width: calc(100% - 115px);
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
      }
    }
  }
`;
