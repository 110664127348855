import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import axios from "axios";

import { ProjectDetailSection } from "../../common/styled/project";

import ProjectDetailChatRoom from "./projectDetailDashboard/projectDetailChatRoom";
import ProjectDetailNotice from "./projectDetailDashboard/projectDetailNotice";
import ProjectDetailCommentList from "./projectDetailDashboard/projectDetailCommentList";
import ProjectDetailCommentMenu from "./projectDetailDashboard/projectDetailCommentMenu";
import Modal from "../../../../../../common/modal";
import ImageDetailViewer from "../../../../../../common/imageDetailViewer";

import { targetWebSocket } from "../../common/function/project";

const ProjectDetailDashboard = ({ project, projectNotice, projectRoomList: rooms }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  const [projectRoomList, setProjectRoomList] = useState([...rooms]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [viewType, setViewType] = useState(0);
  const webSockets = useRef([]);
  const [comments, setComments] = useState([]);
  const [imageOrder, setImageOrder] = useState([]);
  const [isDetail, setIsDetail] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({});
  const [selectedComment, setSelectedComment] = useState(null);
  const [isReply, setIsReply] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [newComment, setNewComment] = useState({
    projectCommentNo: null,
    content: "",
    imgList: [],
    fileList: [],
    updateFiles: [],
    deleteFiles: [],
  });
  const [isRoomConnected, setIsRoomConnected] = useState(false);
  const isRoomSelected = !!selectedRoom;
  const transformValue = viewType * -100;

  useEffect(() => {
    const allWebSockets = rooms.map((room) => joinWebSocket(room));
    webSockets.current = allWebSockets;
  }, []);

  useEffect(() => {
    if (isRoomConnected && selectedRoom) {
      getProjectCommentList();
      setRoomConnection(selectedRoom.roomId, true);
      sessionStorage.setItem("roomId", selectedRoom.roomId);
    } else {
      sessionStorage.removeItem("roomId");
    }

    setViewType(selectedRoom ? 1 : 0);
    setIsReply(null);
    setNewComment({
      projectCommentNo: null,
      content: "",
      imgList: [],
      fileList: [],
      updateFiles: [],
      deleteFiles: [],
    });
  }, [selectedRoom]);

  // 웹소켓 연결
  const joinWebSocket = (item) => {
    if (!item) return;

    const SERVER = "wss://nofax.co.kr";
    const LOCAL = `ws://127.0.0.1:8080`;

    const ws = new WebSocket(`${SERVER}/echo?room=${item.roomId}&clientID=${user.mberNo}`);

    console.log(ws);

    ws.onopen = async () => {
      setIsRoomConnected(true);
    };

    ws.onmessage = (event) => {
      const receivedMsg = JSON.parse(event.data);
      let message = receivedMsg[0];

      if (message.delYn === "Y") {
        message.content = "삭제된 메세지입니다.";
      }

      if (sessionStorage.getItem("roomId")) {
        const roomId = sessionStorage.getItem("roomId");
        handleChatMessage(roomId, message);
      }

      handleMatchingRoom(message);
    };

    ws.onclose = () => {
      setIsRoomConnected(false);
    };

    if (isWebSocketOpen(ws)) {
      console.log("WebSocket is open");
    } else {
      console.log("WebSocket is not open");
    }

    return ws;
  };

  const isWebSocketOpen = (ws) => {
    return ws && ws.readyState === WebSocket.OPEN;
  };

  // 채팅방 미진입
  const handleMatchingRoom = (message) => {
    setProjectRoomList((room) =>
      room.map((item) => {
        if (parseInt(item.roomId, 10) === parseInt(message.roomId, 10)) {
          const messageCreateDt = moment(message.createDt);

          if (item.commentInfo && item.commentInfo.createDt) {
            const existingCreateDt = moment(item.commentInfo.createDt);

            if (messageCreateDt.isBefore(existingCreateDt)) {
              return item;
            }
          }

          return {
            ...item,
            commentInfo: {
              content: message.content,
              imgList: message.imgList,
              fileList: message.fileList,
              createDt: messageCreateDt.format("YYYY-MM-DD HH:mm:ss"),
              updateDt: messageCreateDt.format("YYYY-MM-DD HH:mm:ss"),
            },
            unReadMsgCnt: message.cnt,
          };
        }

        return item;
      }),
    );
  };

  // 채팅방 진입
  const handleChatMessage = (roomId, message) => {
    if (parseInt(roomId, 10) !== parseInt(message.roomId, 10)) {
      return;
    }
    if (message.delYn === "Y") {
      setComments((prev) =>
        prev.map((item) => (item.projectCommentNo === message.projectCommentNo ? message : item)),
      );
    } else {
      setComments((prev) => [...prev, message]);
    }
  };

  // 룸 연결시간 설정
  const setRoomConnection = async (roomId, isConnect = false) => {
    const url = "/api/roomConnection";
    const body = {
      roomId,
      mberNo: user.mberNo,
      connectTime: null,
      disconnectTime: null,
    };

    const now = moment().format("YYYY-MM-DD HH:mm:ss");

    if (isConnect) {
      body.connectTime = now;
    } else {
      body.disconnectTime = now;
    }

    const res = await axios.put(url, body);

    if (res.status === 200) {
      if (isConnect) {
        setProjectRoomList((room) =>
          room.map((item) =>
            parseInt(item.roomId, 10) === parseInt(roomId, 10)
              ? {
                  ...item,
                  unReadMsgCnt: 0,
                }
              : item,
          ),
        );
      }
    }
  };

  // 프로젝트 댓글 조회
  const getProjectCommentList = async (project) => {
    const url = "/api/CommentList";
    const body = {
      projectNo: project?.projectNo,
      roomId: selectedRoom?.roomId,
      gbn: project?.gbn,
      // delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      const data = res.data.map((item) =>
        item.delYn === "Y" ? { ...item, content: "삭제된 메세지입니다." } : item,
      );

      setComments(data);
    }
  };

  // 파일 이미지 클릭
  const handleImageClick = (images, index) => {
    setIsDetail(true);

    const imageList = images.map((item) => ({
      path: item.filePath,
      createNm: item.mberNm,
      createDt: item.createDt,
    }));

    const reorderedImages = [
      ...imageList.slice(index, imageList.length),
      ...imageList.slice(0, index),
    ];

    setImageOrder(reorderedImages);
  };

  // 댓글 메뉴 버튼
  const handleContextMenu = (e, item) => {
    e.preventDefault();
    const { clientX, clientY } = e;
    setMenuPosition({ top: clientY, left: clientX });
    setIsMenuOpen(true);
    setSelectedComment(item);
  };

  const resetSelectComment = () => {
    setIsMenuOpen(false);
    setSelectedComment(null);
    setIsReply(null);
  };

  // 모달
  const handleModalOpen = (type, item = null) => {
    setModalContent({ type, item });
    setModalOpen(true);
  };

  // 댓글 삭제
  const handleDeleteComment = async (item) => {
    const target = targetWebSocket(webSockets.current, selectedRoom);
    if (!target || !project) return;

    if (!isWebSocketOpen(target)) {
      console.error("WebSocket is not open.");
      return;
    }

    const body = {
      content: newComment.content,
      req: {
        projectCommentNo: item.projectCommentNo,
        delYn: "Y",
      },
    };

    target.send(JSON.stringify(body));
    setModalOpen(false);
  };

  const ProjectDetailModalForm = ({ type, item }) => {
    const modalDetails = {
      comment: {
        title: "댓글 삭제",
        body: "댓글을 삭제하시겠습니까?",
        onConfirm: () => handleDeleteComment(item),
      },
      default: {
        title: "오류",
        body: "알 수 없는 오류가 발생했습니다.",
        onConfirm: () => setModalOpen(false),
      },
    };

    const modalInfo = modalDetails[type] || modalDetails.default;

    return (
      <div>
        <h2 className="font_20 font500 font_color_white">{modalInfo.title}</h2>
        <button onClick={() => setModalOpen(false)} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">{modalInfo.body}</span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={modalInfo.onConfirm}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  return (
    <ProjectDetailSection className="projectFeed">
      <div className="slideWrapper">
        <div
          className="slideContent"
          style={{
            flex: isRoomSelected && !isTablet ? "0 0 50%" : "0 0 100%",
            transform: `translateX(${isRoomSelected && isTablet ? `${transformValue}%` : 0})`,
          }}>
          <ProjectDetailNotice projectNotice={projectNotice} project={project} />
          <ProjectDetailChatRoom
            projectRoomList={projectRoomList}
            setSelectedRoom={setSelectedRoom}
          />
        </div>
        <div
          className="slideContent"
          style={{
            transform: `translateX(${!isTablet ? 0 : `${transformValue}%`})`,
            borderLeft: isTablet ? "none" : "1px solid #efefef",
            flex: !isTablet ? "0 0 50%" : "0 0 100%",
          }}>
          <ProjectDetailCommentList
            project={project}
            selectedRoom={selectedRoom}
            setSelectedRoom={setSelectedRoom}
            webSockets={webSockets}
            comments={comments}
            setComments={setComments}
            handleImageClick={handleImageClick}
            handleContextMenu={handleContextMenu}
            isReply={isReply}
            selectedComment={selectedComment}
            resetSelectComment={resetSelectComment}
            setRoomConnection={setRoomConnection}
            newComment={newComment}
            setNewComment={setNewComment}
          />
        </div>
      </div>
      {isMenuOpen && (
        <ProjectDetailCommentMenu
          handleModalOpen={handleModalOpen}
          menuPosition={menuPosition}
          selectedComment={selectedComment}
          setIsReply={setIsReply}
          resetSelectComment={resetSelectComment}
          setIsMenuOpen={setIsMenuOpen}
        />
      )}
      {isDetail && <ImageDetailViewer images={imageOrder} setIsDetail={setIsDetail} />}
      <Modal
        modalState={modalOpen}
        handleModalState={() => setModalOpen(false)}
        html={<ProjectDetailModalForm type={modalContent?.type} item={modalContent?.item} />}
        w="300px"
        h="auto"
      />
    </ProjectDetailSection>
  );
};

export default ProjectDetailDashboard;
