import React, { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { GroupDetailMemberContainer } from "../../styled/group";
import { FiMoreVertical } from "react-icons/fi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const GroupDetailMembers = ({ grp, grpMber, setIsInvite }) => {
  const [mber, setMber] = useState([...grpMber]);
  const user = useSelector((state) => state?.user?.data[0]);
  const [searched, setSearched] = useState("");

  const handleSearch = () => {
    const filtered = grpMber.filter((item) => item.nickNm && item.nickNm.includes(searched));
    setMber(filtered);
    setSearched("");
  };

  return (
    <GroupDetailMemberContainer>
      <div className="memberHeader">
        <div className="title">
          <div className="info">
            멤버 <span>{grpMber.length}</span>
          </div>
          <button onClick={() => setIsInvite(true)}>멤버 초대</button>
        </div>
        <div className="memberSearch">
          <input
            type="text"
            placeholder="멤버 닉네임 검색"
            value={searched}
            onChange={(e) => setSearched(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSearch()}
          />
          <button onClick={handleSearch}>
            <IoSearchOutline />
          </button>
        </div>
      </div>
      {grpMber.length > 0 && (
        <div className="memberList">
          <ul>
            {mber.length > 0 ? (
              mber.map((item) => (
                <li>
                  <div className="profileImage">
                    <img
                      src={item.mberImgPath ? imgHandler(item.mberImgPath) : "/img/user2.png"}
                      alt={item.nickNm}
                    />
                  </div>
                  <div
                    className="profileInfo"
                    style={{ color: item.mberNo === user.mberNo ? "#0074ca" : "#000" }}>
                    {item.gbnValue && grp.gbn && <span>{item.gbnValue + grp.gbn}</span>}
                    {item.nickNm ? item.nickNm : "닉네임 미설정"}
                  </div>
                </li>
              ))
            ) : (
              <li className="notMber">해당 멤버가 존재하지 않습니다.</li>
            )}
          </ul>
        </div>
      )}
    </GroupDetailMemberContainer>
  );
};

export default GroupDetailMembers;
