import React from "react";
import { PrdSyncDesktopContainer } from "../../styled/productSyncForm";
import { AiOutlineCheck } from "react-icons/ai";
import { CustomInput } from "../../styled/common";
import Pagination from "react-js-pagination";

const ProductDesktop = ({
  products,
  total,
  pages,
  changePages,
  selectedProducts,
  handleCheck,
  handleAllCheck,
  categoriesObj,
  handleModalState,
}) => {
  const startIndex = (pages.page - 1) * pages.limit;
  const currentProducts = products.slice(startIndex, startIndex + pages.limit);

  const isAllChecked =
    currentProducts.length > 0 &&
    selectedProducts.length > 0 &&
    currentProducts.every((product) =>
      selectedProducts.some((selected) => selected.id === product.id),
    );

  console.log(products);

  return (
    <PrdSyncDesktopContainer>
      <table>
        <colgroup>
          <col style={{ width: "5%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <thead>
          <tr>
            <th>
              <CustomInput className="block">
                <input
                  type="checkbox"
                  checked={isAllChecked}
                  onChange={(e) => handleAllCheck(e.target.checked)}
                />
                <span className="checkmark"></span>
              </CustomInput>
            </th>
            <th>이미지</th>
            <th>
              <span>제품명</span>
            </th>
            <th>
              <span>브랜드</span>
            </th>
            <th>
              <span>가격</span>
            </th>
            <th>
              <span>카테고리</span>
            </th>
            <th>
              <span>규격</span>
            </th>
            <th>
              <span></span>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentProducts.length > 0 ? (
            currentProducts.map((prd) => (
              <tr>
                <td>
                  <CustomInput className="block">
                    <input
                      type="checkbox"
                      checked={selectedProducts.some((item) => item.id === prd.id)}
                      onChange={() => handleCheck(prd)}
                    />
                    <span className="checkmark"></span>
                  </CustomInput>
                </td>
                <td>
                  <img src={imgHandler(prd?.media)} alt="" />
                </td>
                <td>
                  <span
                    className="name"
                    {...(prd?.description && { "data-description": prd.description })}>
                    {prd?.name}
                  </span>
                </td>
                <td>
                  <span className="brand">{prd?.brand}</span>
                </td>
                <td>
                  <span className="price">{prd?.price ? prd?.price.toLocaleString() : 0}원</span>
                </td>
                <td>
                  <span className="category">{categoriesObj[prd?.categoryId]}</span>
                </td>
                <td>
                  <span className="specifications">{prd?.specifications}</span>
                </td>
                <td>
                  <div className="buttons">
                    <button onClick={() => handleModalState("edit", prd)}>수정</button>
                    <button onClick={() => handleModalState("detail", prd)}>보기</button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}></td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        activePage={pages.page}
        itemsCountPerPage={pages.limit}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={(e) => changePages({ target: "page", value: e })}
      />
    </PrdSyncDesktopContainer>
  );
};

export default ProductDesktop;
