import React from "react";
import { useSelector } from "react-redux";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const SideMenuSelectMonth = ({
  userDate,
  setUserDate,
  handleDateClick,
  setIsAnnualCalendar,
  isAnnualCalendar,
}) => {
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);
  const months = Array.from({ length: 12 }, (_, idx) => idx + 1);

  const handleMonthClick = (item) => {
    handleDateClick(`${userDate.year}-${String(item).padStart(2, "0")}-01`);
    setIsAnnualCalendar(false);
  };

  return (
    <div className="calendarArea" style={{ display: isAnnualCalendar ? "block" : "none" }}>
      <div className="selectMonth">
        <div className="monthsHeader">
          <h2
            onClick={() => {
              setIsAnnualCalendar(false);
            }}>
            {userDate.year}년
          </h2>
          <div className="buttons">
            <button onClick={() => setUserDate({ ...userDate, year: userDate.year - 1 })}>
              <BsChevronLeft />
            </button>
            <button onClick={() => setUserDate({ ...userDate, year: userDate.year + 1 })}>
              <BsChevronRight />
            </button>
          </div>
        </div>
        <div className="monthsBody">
          {months.map((item) => (
            <div className="font_14" key={item}>
              <span
                onClick={() => handleMonthClick(item)}
                style={{
                  border: `2px solid ${
                    userDate.month === item ? (isDarkMode ? "#2b2b2b" : "#efefef") : "transparent"
                  }`,
                }}>
                {item}월
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideMenuSelectMonth;
