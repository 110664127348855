import React from "react";
import { HeroContainer } from "../styled/info";

const Hero = ({ type, content }) => {
  return (
    <HeroContainer className={`infoSection ${type}`}>
      <div className="inner">
        <div className="img">
          <img src={`/img/info/hero_${type}.png`} alt="" />
        </div>
        <div className="content">
          <span className="title">{content.heading}</span>
          <span className="desc">{content.description}</span>
          <strong>{content.feature}</strong>
        </div>
      </div>
    </HeroContainer>
  );
};

export default Hero;
