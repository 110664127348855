import { useEffect } from "react";

const useScrollLock = (isLocked) => {
  useEffect(() => {
    const handleScroll = (e) => {
      e.preventDefault();
    };

    if (isLocked) {
      window.addEventListener("scroll", handleScroll, { passive: false });
      window.addEventListener("wheel", handleScroll, { passive: false });
      window.addEventListener("touchmove", handleScroll, { passive: false });
    } else {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
    };
  }, [isLocked]);
};

export default useScrollLock;
