import React from "react";

const SaveForm = ({ transformValue, handleSubmit }) => {
  return (
    <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
      <span>저장하시겠습니까?</span>
      <button className="submit" onClick={handleSubmit}>
        확인
      </button>
    </div>
  );
};

export default SaveForm;
