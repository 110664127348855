import React, { useState } from "react";
import { IoNotificationsOffOutline } from "react-icons/io5";

import moment from "moment";
import "moment/locale/ko";

const NotifyList = ({
  notification,
  readNotification,
  toggleNotiSelection,
  selectedNotiNos,
  isDelete,
  handleInviteResponse,
}) => {
  const NoticeItem = ({ item }) => {
    const [isDecision, setIsDecision] = useState(false);

    const notiType = {
      S: "일정",
      C: "커스텀알림",
      I: "초대",
      R: "가입",
      A: "광고",
      RA: "가입신청",
      F: "피드",
    };

    return (
      <li
        onClick={(e) => {
          console.log(item);
          if (e.target.type !== "checkbox") {
            if (item.notiType !== "I") {
              readNotification(item);
            } else {
              setIsDecision(!isDecision);
            }
          }
        }}
        style={{ opacity: item.readYn === "Y" ? 0.5 : 1 }}>
        <div className="notifyWrapper">
          {<img src={item.imgPath ? imgHandler(item.imgPath) : "/img/application_price_1.png"} />}
          <div className="content font_16">
            <div className="font_14">{notiType[item.notiType]}</div>
            <div className="infoBox">
              <div className="title">{item.title}</div>
              <div className="date">{moment(item.createDt).format("YYYY-MM-DD LT")}</div>
            </div>
            <div className="msgInfoBox">
              <span>{item.content}</span>
            </div>
          </div>
          {isDelete && (
            <div className="deleteChk">
              <label class="cont">
                <input
                  type="checkbox"
                  name={item.notiNo}
                  onChange={(e) => {
                    e.stopPropagation();
                    toggleNotiSelection(item.notiNo);
                  }}
                  checked={selectedNotiNos.includes(item.notiNo)}
                />
                <span></span>
              </label>
            </div>
          )}
        </div>
        {item.notiType === "I" && (
          <div className={`decisionBtnArea ${isDecision ? "inv" : ""}`}>
            <button onClick={() => handleInviteResponse(item, "Y")}>수락</button>
            <button onClick={() => handleInviteResponse(item, "N")}>거절</button>
          </div>
        )}
      </li>
    );
  };

  return (
    <div className="section">
      {notification.length > 0 ? (
        <ul>
          {notification?.map((item) => (
            <NoticeItem item={item} />
          ))}
        </ul>
      ) : (
        <div className="notNotis">
          <div className="icon">
            <IoNotificationsOffOutline />
          </div>
          <span>새로운 알림이 없습니다.</span>
        </div>
      )}
    </div>
  );
};

export default NotifyList;
