import React, { useRef, useState, useEffect } from "react";
import { ViewAllContainer } from "../styled/storage";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { isStorageType } from "../function/kiosk";

const ViewAll = ({ data, handleClickStorage, active }) => {
  const swiperRef = useRef();
  const isTablet = useMediaQuery({ query: "(max-width:820px)" });
  const ITEMS_PER_PAGE = isTablet ? 50 : 250;

  const getRange = (active) => {
    if (active === 0) {
      return { start: 1, end: 500 };
    } else if (active === 1) {
      return { start: 501, end: 1000 };
    } else if (active === 2) {
      return { start: 2001, end: 2500 };
    }
    return { start: 1, end: 500 };
  };

  const range = getRange(active);

  const [page, setPage] = useState({
    start: range.start,
    end: Math.min(range.start + ITEMS_PER_PAGE - 1, range.end),
  });

  useEffect(() => {
    const range = getRange(active);
    setPage({
      start: range.start,
      end: Math.min(range.start + ITEMS_PER_PAGE - 1, range.end),
    });

    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(0);
    }
  }, [active, ITEMS_PER_PAGE]);

  const handleSwiperButton = (isPrev) => {
    if (swiperRef.current) {
      if (isPrev) {
        swiperRef.current.swiper.slidePrev();
      } else {
        swiperRef.current.swiper.slideNext();
      }
    }
  };

  const splitDataIntoChunks = (data, size) => {
    const chunks = [];
    for (let i = 0; i < data.length; i += size) {
      chunks.push(data.slice(i, i + size));
    }
    return chunks;
  };

  const handleSlideChange = (swiper) => {
    const currentPage = swiper.activeIndex;
    const start = range.start + currentPage * ITEMS_PER_PAGE;
    const end = Math.min(start + ITEMS_PER_PAGE - 1, range.end);

    setPage({ start, end });
  };

  const dataChunks = splitDataIntoChunks(data, ITEMS_PER_PAGE);

  return (
    <ViewAllContainer className="content">
      <div className="section">
        <div className="slide">
          <Swiper
            className="slideWrapper"
            ref={swiperRef}
            onSlideChange={handleSlideChange}
            onResize={handleSlideChange}>
            {dataChunks.map((chunk, chunkIndex) => (
              <SwiperSlide className="slideContent" key={`slide_${chunkIndex}`}>
                <table>
                  <tbody>
                    {Array.from({ length: 10 }, (_, rowIndex) => (
                      <tr key={rowIndex}>
                        {Array.from({ length: isTablet ? 5 : 25 }, (_, colIndex) => {
                          const itemIndex = rowIndex * (isTablet ? 5 : 25) + colIndex;
                          const item = chunk[itemIndex];
                          return (
                            <td
                              key={colIndex}
                              onClick={() => item.id && handleClickStorage("userInfo", item)}
                              className={`${
                                item.id ? `hover ${isStorageType(active, "key")}` : "empty"
                              } ${item.status}`}>
                              <div className="index">
                                <span>{item.index}</span>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </SwiperSlide>
            ))}
          </Swiper>
          <button onClick={() => handleSwiperButton(true)} className="prev" />
          <button onClick={() => handleSwiperButton(false)} className="next" />
        </div>
        <div className="page">
          <span>
            {page.start}~{page.end}
          </span>
        </div>
      </div>
    </ViewAllContainer>
  );
};

export default ViewAll;
