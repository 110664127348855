import React, { useEffect } from "react";
import { Container } from "./component/styled/selfService";
import { useDisableScroll } from "../mypage/kiosk/component/function/kiosk";
import { useNavigate } from "react-router";

const SelfService = () => {
  useDisableScroll();
  const navigate = useNavigate();

  const step = [
    { icon: "screenTouch", message: "화면터치" },
    { icon: "inputOptions", message: "옵션입력" },
    { icon: "storageComplete", message: "보관완료" },
  ];

  const actions = [
    {
      name: "keep",
      title: "내 빵 보관하기",
      message: "고객님의 성함과 휴대전화 번호를 입력해 주세요.",
    },
    {
      name: "find",
      title: "보관한 빵 찾기",
      message: "고객님의 성함과 휴대전화 번호, 예약 번호를 입력해 주세요.",
    },
  ];

  return (
    <Container>
      <div className="content">
        <div className="sungsimdang"></div>
        <div className="step">
          <ul>
            {step.map((item, index) => (
              <>
                <li>
                  <div className="box">
                    <img src={`/img/selfService/${item.icon}.png`} alt={item.message} />
                    <span>{item.message}</span>
                  </div>
                </li>
                {index !== step.length - 1 && (
                  <li>
                    <img src="/img/selfService/nextStep.png" alt="" />
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>
        {actions.map((item) => (
          <div
            className={`${item.name} actions`}
            onClick={() =>
              name === "find" ? alert("준비 중입니다.") : navigate(`/self-service/${item.name}`)
            }>
            <div className="icon">
              <img src={`/img/selfService/${item.name}.png`} alt="" />
            </div>
            <div className="info">
              <span className="tit">{item.title}</span>
              <span className="con">{item.message}</span>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default SelfService;
