import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  user-select: none;
  .content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .sungsimdang {
      width: calc(100% - 138px);
      height: calc(100% - 287px);
      background-image: url("/img/selfService/bg.png");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
    .step {
      width: 138px;
      height: calc(100% - 287px);
      background: #3d4352;
      display: flex;
      align-items: center;
      justify-content: center;
      ul {
        width: 100%;
        li {
          text-align: center;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          img {
          }
          span {
            display: block;
            font-size: 20px;
            margin-top: 10px;
            color: #fff;
          }
        }
      }
    }
    .actions {
      width: 50%;
      height: 287px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 5px;
      color: #fff;
      cursor: pointer;
      .tit {
        display: block;
        font-size: 30px;
        margin-bottom: 5px;
      }
      .con {
        font-size: 20px;
        height: 50px;
        display: block;
      }
      .icon {
        width: 120px;
        height: 120px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &.keep {
        background: #fe8a01;
      }
      &.find {
        background: #ff4b4b;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .content {
      flex-direction: column;
      flex-wrap: nowrap;
      .step {
        display: none;
      }
      .sungsimdang {
        width: 100%;
        height: calc(100% - 240px);
      }
      .actions {
        width: 100%;
        height: 120px;
        .icon {
          width: 40px;
          height: 40px;
        }
        .tit {
          font-size: 15px;
        }
        .con {
          font-size: 12px;
          height: auto;
        }
      }
    }
  }
`;

export const StorageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  .content {
    width: 100vw;
    height: calc(100% - 86px);
    display: flex;
  }
  .sidemenu {
    width: 375px;
    height: 100%;
    background: #e9edf0;
    padding: 20px;
    ul {
      height: calc(100% - 90px);
      li {
        width: 100%;
        font-size: 30px;
        font-weight: 700;
        border-radius: 20px;
        margin-bottom: 10px;
        text-align: center;
        &:last-child {
          margin-bottom: 0;
        }
        &:nth-child(even) {
          color: #a4a6a8;
        }
        &:nth-child(odd) {
          height: 90px;
          background: #fff;
          line-height: 90px;
        }
        &.active {
          background: #4c80f1;
          color: #fff;
        }
      }
    }
    button {
      font-size: 30px;
      width: 100%;
      height: 90px;
      background: #4c80f1;
      color: #fff;
      border: none;
      border-radius: 20px;
      font-weight: 700;
    }
  }
  .slideWrapper {
    overflow: hidden;
    display: flex;
    position: relative;
    height: 100%;
    .slideContent {
      flex: 0 0 100%;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      transition: 0.5s;
      overflow: hidden;
      .inputFields {
        height: 200px;
        input {
          width: 100%;
          height: 100%;
          font-size: 48px;
          height: 100%;
          text-align: center;
          font-weight: 700;
          border: 1px solid #e9edf0;
          &:focus {
            border: 1px solid #e9edf0 !important;
            box-shadow: none;
          }
        }
      }
      .fieldBox {
        height: calc(100% - 200px);
      }
    }
  }
  @media screen and (max-width: 767px) {
    .content {
      flex-direction: column;
      .sidemenu {
        width: 100%;
        height: 100px;
        padding: 10px;
        ul {
          width: 100%;
          height: calc(100% - 50px);
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          li {
            height: auto;
            font-size: 12px;
            margin-bottom: 0;
            &:nth-child(odd) {
              line-height: 100%;
              padding: 10px 0;
            }
            &:nth-child(even) {
              width: auto;
              margin: 0 10px;
              transform: rotate(-90deg);
            }
          }
        }
        button {
          height: 30px;
          font-size: 16px;
          display: block;
          margin-left: auto;
        }
      }
    }
  }
`;

export const StorageTypeContainer = styled.div`
  height: 100%;
  padding: 0 50px;
  .total {
    width: 100%;
    height: 200px;
    font-weight: 700;
    font-size: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .storageList {
    width: 100%;
    height: calc(100% - 400px);
    display: flex;
    gap: 10px;
    .storageItem {
      width: calc((100% - 20px) / 3);
      border: 2px solid #e9edf0;
      .image {
        height: calc(100% - 116px);
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .info {
        height: 56px;
        line-height: 56px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
      }
      .stepper {
        width: 100%;
        border-top: 1px solid #e9edf0;
        display: flex;
        height: 60px;
        font-size: 32px;
        align-items: center;
        font-weight: 700;
        button {
          height: 100%;
          width: 50px;
          border: none;
          background: transparent;
        }
        span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(100% - 100px);
          border-left: 1px solid #e9edf0;
          border-right: 1px solid #e9edf0;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .total {
      height: 100px;
    }
    .storageList {
      height: calc(100% - 100px);
      flex-direction: column;
      .storageItem {
        width: 100%;
        height: calc(100% / 3);
        .image {
          display: none;
        }
        .info {
          height: calc(100% - 40px);
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 0;
        }
        .stepper {
          height: 40px;
          font-size: 24px;
        }
      }
    }
  }
`;

export const RetrieveContainer = styled.div`
  width: 100vw;
  height: 100vh;
  .content {
    width: 100vw;
    display: flex;
    flex-direction: column;
    .tabs {
      height: 86px;
      line-height: 86px;
      font-size: 30px;
      ul {
        height: 100%;
        display: flex;
        li {
          flex: 1;
          height: 100%;
          display: inline-block;
          text-align: center;
          font-weight: 700;
          border-bottom: 9px solid transparent;
          cursor: pointer;
          &.active {
            border-bottom: 9px solid #4c80f1;
          }
        }
      }
    }
    .slideWrapper {
      padding: 28px 0;
      overflow: hidden;
      display: flex;
      position: relative;
      height: calc(100% - 86px);
      background: #e9edf0;
      .slideContent {
        flex: 0 0 100%;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        transition: 0.5s;
        overflow: hidden;
        .contactInputFields {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          .contactInputSlide {
            flex: 0 0 100%;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            transition: 0.5s;
            overflow: hidden;
          }
        }
        .inputBox {
          background: #fff;
          max-width: 584px;
          height: 100%;
          margin: 0 auto;
        }
        .inputFields {
          height: 100px;
          input {
            width: 100%;
            height: 100%;
            font-size: 48px;
            height: 100%;
            text-align: center;
            font-weight: 700;
            border: 1px solid #e9edf0;
            &:focus {
              border: 1px solid #e9edf0 !important;
              box-shadow: none;
            }
          }
        }
        .keyboardFields {
          height: calc(100% - 200px);
        }
        .inputActions {
          height: 100px;
          width: 100%;
          button {
            width: 100%;
            height: 100%;
            font-size: 30px;
            background: #fff;
            font-weight: 700;
            border: 1px solid #e9edf0;
          }
        }
      }
    }
  }
`;

export const ResultNotifyContainer = styled.div`
  .result {
    max-width: 890px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .info {
      background: #fff;
      height: 590px;
      font-size: 24px;
      padding: 20px;
      .resultInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        .user .tit {
          font-weight: 700;
        }
        button {
          border: none;
          background: #e9edf0;
          padding: 10px 20px;
          font-weight: 700;
          color: #4c80f1;
        }
      }
      .feeInfo {
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        font-size: 14px;
        color: #929292;
      }
      .storageInfo {
        height: calc(100% - 170px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #5a5a5a;
          border-radius: 10px;
          border: 2px solid #d9d9d9;
        }
        &::-webkit-scrollbar-track {
          background-color: #d9d9d9;
          border-radius: 10px;
        }
        .type {
          min-height: 100px;
          display: flex;
          padding: 10px;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          p {
            width: 110px;
            font-weight: 700;
          }
          ul {
            width: calc(100% - 110px);
            /* border-bottom: 1px solid #e9edf0; */
            li {
              width: 100%;
              font-size: 14px;
              border-bottom: 1px solid #e9edf0;
              display: flex;
              align-items: center;
              padding: 5px 20px;
              .storage {
                div {
                  display: inline-block;
                  width: calc(100% / 3);
                  span {
                    display: inline-block;
                    &.tit {
                      font-weight: 700;
                      margin-right: 10px;
                    }
                    &.con.status {
                      background: #e7f5f2;
                      color: #429b8c;
                      padding: 3px 10px;
                      font-size: 12px;
                      font-weight: 700;
                    }
                  }
                }
              }
              .customInput {
                position: relative;
                cursor: pointer;
                height: 25px;
                width: 25px;
                display: block;
                display: block;
                margin: auto;
                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  padding: 0;
                  width: 0;
                  height: 0;
                }
                .checkmark {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
                  border: 2px solid #ccc;
                  border-radius: 6px;
                  background-color: transparent;
                  transition: all 0.3s ease;
                  &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    top: 46%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(45deg) scale(0);
                    width: 9px;
                    height: 17px;
                    border: solid #fff;
                    border-width: 0 3px 3px 0;
                  }
                }
                input:checked ~ .checkmark {
                  background-color: #4c80f1;
                  border-color: #4c80f1;
                  &:after {
                    display: block;
                    transform: translate(-50%, -50%) rotate(45deg) scale(1);
                    border-color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
    .buttons {
      width: 100%;
      button {
        height: 70px;
        width: 50%;
        font-size: 32px;
        font-weight: 600;
        border: none;
        background: #fff;
        &:last-child {
          background: #4c80f1;
          color: #fff;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .result {
      justify-content: space-between;
      padding: 0 10px;
      .info {
        font-size: 14px;
        background: transparent;
        padding: 0;
        height: calc(100% - 60px);
        .resultInfo {
          margin-bottom: 15px;
          button {
            background: #fff;
          }
        }
        .storageInfo {
          background: #ffffff;
          height: calc(100% - 83px);
          .type {
            flex-wrap: wrap;
            p {
              width: 100%;
              font-size: 20px;
              font-weight: 900;
            }
            ul {
              width: 100%;
              li {
                padding: 10px 0;
                position: relative;
                .storage div {
                  display: block;
                  width: 100%;
                  margin-bottom: 5px;
                }
                .customInput {
                  position: absolute;
                  margin: 0;
                  top: 10px;
                  right: 0;
                }
              }
            }
          }
        }
        .feeInfo {
          height: 30px;
        }
      }
      .buttons {
        button {
          font-size: 16px;
          height: 40px;
        }
      }
    }
  }
`;
