import React from "react";

const OrderControls = ({
  active,
  item,
  rejectOrder,
  acceptOrder,
  openModal,
  pickupBy,
  index,
  selected,
  setSelected,
  updateOrder,
}) => {
  switch (active) {
    case "COMPLETED":
    case "PICKUP":
      return null;
    case "READY":
      return (
        <div className="controls">
          <button onClick={() => setSelected(null)}>취소</button>
          <button
            onClick={() =>
              openModal({
                type: "confirmation",
                title: "픽업 완료",
                message: ["해당 주문을 완료하시겠습니까?"],
                handleConfirm: () => updateOrder(item.id),
              })
            }>
            완료
          </button>
        </div>
      );
    case "PENDING":
      return (
        <div className="controls">
          <button onClick={() => rejectOrder(item)}>거절</button>
          <button
            onClick={() =>
              openModal({
                type: "confirmation",
                title: "주문 수락",
                message: ["주문을 수락하시겠습니까?"],
                handleConfirm: () => acceptOrder(item.id, pickupBy[index]),
              })
            }>
            수락
          </button>
        </div>
      );
    default:
      return null;
  }
};

export default OrderControls;
