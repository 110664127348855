import React, { useEffect, useState } from "react";
import { MakeShortcutsContainer } from "../../styled/dashboard";
import InputField from "./inputField";
import SelectIcon from "./selectIcon";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import { Modal } from "../../../../styled/mypage";

const MakeShortcutsModal = ({
  setIsMakeQuickLink,
  setting,
  updateSetting,
  activeIndex,
  isEditable,
  sestIsEditable,
}) => {
  const isEdit = activeIndex !== null && activeIndex !== undefined && isEditable;
  const fixed = "https://";

  const [viewType, setViewType] = useState(0);
  const [shortcut, setShortcuts] = useState(
    isEdit ? setting.shortcuts[activeIndex] : { icon: "", url: "https://", label: "" },
  );

  const services = [
    { name: "모임", path: "/group", key: "group" },
    { name: "프로젝트", path: "/mypage/projectList", key: "project" },
    { name: "캘린더", path: "/mypage/scheduler", key: "scheduler" },
    { name: "e층창고", path: "/shop", key: "shop" },
  ];

  const transformValue = viewType * -100;

  const changeShortcut = (e) => {
    const { name, value } = e.target;

    if (name === "url") {
      if (!value.startsWith(fixed)) {
        setShortcuts((data) => ({ ...data, url: fixed }));
      } else {
        const userInput = value.slice(fixed.length);
        setShortcuts((data) => ({ ...data, url: userInput }));
      }
    } else {
      setShortcuts((data) => ({ ...data, [name]: value }));
    }
  };

  const isMonotiService = () => {
    return services.find(
      (item) =>
        item.key === shortcut.icon && item.name === shortcut.label && item.path === shortcut.url,
    );
  };

  const changePage = (type = false) => {
    if (type) {
      if (viewType === 0) {
        if (!validationCheck()) return;

        const target = isMonotiService();
        if (target) {
          submitShortcut(shortcut);
          return;
        }

        setViewType(1);
      }
      if (viewType === 1) {
        let target = { ...shortcut };
        if (!target.url.startsWith(fixed)) {
          target.url = `${fixed}${shortcut.url}`;
        }

        submitShortcut(target);
      }
    } else {
      if (viewType === 0) {
        setIsMakeQuickLink(false);
        sestIsEditable(false);
      }
      if (viewType === 1) setViewType(0);
    }
  };

  const validationCheck = () => {
    if (!shortcut.label) {
      toast.error("이름을 입력해주세요.");
      return false;
    }

    if (!shortcut.url) {
      toast.error("URL을 입력해주세요.");
      return false;
    }

    const target = services.find((item) => item.path === shortcut.url);
    if (target) return true;

    const urlPattern = /^(https?:\/\/)?(www\.)?[\da-z.-]+\.[a-z]{2,6}([\/\w .-]*)*\/?$/;
    if (!urlPattern.test(shortcut.url)) {
      toast.error("올바른 URL 형식이 아닙니다.");
      return false;
    }

    return true;
  };

  const submitShortcut = (data) => {
    if (!validationCheck()) return;

    if (isEdit) {
      const shortcuts = setting.shortcuts.map((item, idx) => (idx === activeIndex ? data : item));

      if (JSON.stringify(shortcuts) === JSON.stringify(setting.shortcuts)) {
        setIsMakeQuickLink(false);
        return;
      }

      updateSetting({ shortcuts }, "바로가기 버튼이 수정되었습니다");
    } else {
      updateSetting(
        { shortcuts: [...setting.shortcuts, { ...data }] },
        "바로가기가 추가되었습니다.",
      );
    }

    setIsMakeQuickLink(false);
  };

  const selectMonotiService = (item) => {
    setShortcuts(() => ({ icon: item.key, url: item.path, label: item.name }));
  };

  return (
    <Modal className={setting.mode}>
      <MakeShortcutsContainer className={setting.mode}>
        <div className="modal">
          <div className="modalContent">
            <div className="slideWrapper">
              <InputField
                transformValue={transformValue}
                changeShortcut={changeShortcut}
                shortcut={shortcut}
                changePage={changePage}
                selectMonotiService={selectMonotiService}
                services={services}
                isMonotiService={isMonotiService}
              />
              <SelectIcon
                transformValue={transformValue}
                changeShortcut={changeShortcut}
                shortcut={shortcut}
                changePage={changePage}
              />
            </div>
          </div>
        </div>
      </MakeShortcutsContainer>
    </Modal>
  );
};

export default MakeShortcutsModal;
