import NoticeWriteForm from "../../../common/noticeWriteForm";
import MypageBanner from "../mypageBanner";
import MyPageQnaTitle from "./component/myPageQnaTitle";

export default function MyQnaWrite () {
  
  return(
    <>
      <MypageBanner />
      <div className="inner_content">
        <MyPageQnaTitle />
        <NoticeWriteForm />
      </div>
    </>
  )
}