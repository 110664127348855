import React, { useEffect, useState } from "react";
import {
  NotMessage,
  ProjectDetailSection,
  ProjectSchedulerContainer,
} from "../../common/styled/project";
import { showEventTime } from "../../../../scheduler/component/function/scheduler";
import { useLocation, useNavigate, useParams } from "react-router";
import { IoAddSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import moment from "moment";

const ProjectScheduler = ({ schedule, project, participantList }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);
  const { projectNo } = useParams();
  const [isParticipant, setIsParticipant] = useState();

  useEffect(() => {
    const participant = participantList.filter((item) => user.mberNo === item.mberNo)?.[0];
    if (participant) {
      setIsParticipant(true);
    }
  }, []);

  return (
    <ProjectDetailSection>
      <ProjectSchedulerContainer>
        {schedule.length > 0 ? (
          <ul>
            {schedule.map((item) => (
              <li
                onClick={() => navigate(`/mypage/detailEvent/${item.scheduleNo}?path=${pathname}`)}>
                <span className="tit">{item.title}</span>
                <span>{showEventTime(item.startDt, item.endDt)}</span>
              </li>
            ))}
          </ul>
        ) : (
          <NotMessage>해당 프로젝트의 일정이 존재하지 않습니다.</NotMessage>
        )}
        {isParticipant && (
          <button
            className="addScheduler"
            onClick={() =>
              navigate(`/mypage/calendarEntry?path=${pathname}`, {
                state: {
                  eventInfo: {
                    startDt: moment().format("YYYY-MM-DD HH:30:00"),
                    endDt: moment().startOf("hour").add(1, "hour").format("YYYY-MM-DD HH:30:00"),
                    projectNo,
                    gbn: project.gbn,
                    orgNo: project.gbn === "C" ? user.orgNo : project.orgNo,
                  },
                },
              })
            }>
            <IoAddSharp />
          </button>
        )}
      </ProjectSchedulerContainer>
    </ProjectDetailSection>
  );
};

export default ProjectScheduler;
