import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import { BsFileEarmarkMedical } from "react-icons/bs";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";

const ProjectDetailNotice = ({ project, projectNotice }) => {
  const projectFeedBodyRef = useRef(null);
  const [noticeHeight, setNoticeHeight] = useState("auto");
  const [isExpanded, setIsExpanded] = useState(true);

  const calcHeight = () => {
    if (projectFeedBodyRef.current) {
      setNoticeHeight(`${projectFeedBodyRef.current.scrollHeight}px`);
    }
  };

  useEffect(() => {
    const images = projectFeedBodyRef.current?.querySelectorAll("img") || [];
    let imagesLoaded = 0;

    const onImageLoad = () => {
      imagesLoaded += 1;
      if (imagesLoaded === images.length) {
        calcHeight();
      }
    };

    if (images.length > 0) {
      images.forEach((img) => {
        if (img.complete) {
          imagesLoaded += 1;
        } else {
          img.addEventListener("load", onImageLoad);
        }
      });

      if (imagesLoaded === images.length) {
        calcHeight();
      }
    } else {
      calcHeight();
    }

    return () => {
      images.forEach((img) => {
        img.removeEventListener("load", onImageLoad);
      });
    };
  }, [projectNotice]);

  const isFileType = {
    I: "이미지",
    V: "비디오",
    F: "파일",
  };

  const handleDownloadFile = async (file) => {
    if (!file || !file?.filePath) {
      return;
    }

    const { filePath } = file;
    const url = imgHandler(filePath);

    try {
      const res = await axios.post("/api/getFile", { url }, { responseType: "arraybuffer" });
      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filePath.split("/").pop();
      link.click();
      toast.success(`${chooseParticle(isFileType[file.fileType], "을를")} 저장하였습니다.`);
    } catch (error) {
      console.error(`파일 다운로드 실패:`, error);
    }
  };

  const toggleHeight = () => {
    if (!isExpanded) {
      calcHeight();
    } else {
      setNoticeHeight("0px");
    }
    setIsExpanded(!isExpanded);
  };
  return (
    <div>
      <div className="projectFeedHeader">
        <div className="writerInfo">
          <img
            src={
              projectNotice?.imgPath
                ? imgHandler(projectNotice.imgPath, false)
                : "/img/logo_with_text.png"
            }
            alt="logo"
            style={{ width: 40, height: 40, objectFit: "contain", borderRadius: "50%" }}
          />
          <div>
            {<span>{project?.mberNm ? project.mberNm : ""}</span>}
            <p>{moment(projectNotice?.updateDt).format("YYYY-MM-DD HH:mm")}</p>
          </div>
        </div>
        <span className="projectInfo">{project?.orgNm}</span>
      </div>
      <div className="projectFeedToggle">
        <button onClick={toggleHeight}>{isExpanded ? <IoChevronUp /> : <IoChevronDown />}</button>
      </div>
      <div
        className="projectFeedBody"
        ref={projectFeedBodyRef}
        style={{
          height: noticeHeight,
          margin: 15,
        }}>
        <div className="tit">{projectNotice?.title}</div>
        <div
          className="con"
          id="contents"
          dangerouslySetInnerHTML={{ __html: projectNotice?.content }}></div>
        <div className="files">
          {projectNotice?.fileList && projectNotice?.fileList?.length > 0 && (
            <ul>
              {projectNotice?.fileList.map((item) => (
                <li onClick={() => handleDownloadFile(item)}>
                  <div className="icon">
                    <BsFileEarmarkMedical />
                  </div>
                  <span>{item.fileNm}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailNotice;
