import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import axios from "axios";

import GroupDetailHome from "./groupDetailView/groupDetailHome";
import GroupDetailSearch from "./groupDetailView/groupDetailSearch";
import GroupDetailMembers from "./groupDetailView/groupDetailMembers";
import GroupDetailSetting from "./groupDetailView/groupDetailSetting";
import GroupDetailSideMenu from "./groupDetailView/groupDetailSideMenu";
import GroupDetailTabMenu from "./groupDetailView/groupDetailTabMenu";
import GroupInvite from "./groupDetailView/groupInvite.jsx";

import { GroupDetailViewContainer } from "../styled/group";
import { useMediaQuery } from "react-responsive";
import GroupDetailFooter from "./groupDetailFooter.jsx";

const GroupDetailView = ({ grpMber, grp, setGrp, setGrpMber, setUserInfo, getGrpMbers }) => {
  const { id } = useParams();
  const location = useLocation();
  const user = useSelector((state) => state?.user?.data[0]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [active, setActive] = useState(0);
  const [cmpnys, setCmpnys] = useState({});
  const [isInvite, setIsInvite] = useState(false);

  const userInfo = grpMber.find((item) => parseInt(item.mberNo, 10) === parseInt(user.mberNo, 10));

  useEffect(() => {
    if (id) {
      getGrpCmpnyList();
    }

    if (
      location.search
      // sessionStorage.getItem("queryString")
    ) {
      const params = new URLSearchParams(location.search);
      const keyword = params.get("keyword");

      if (keyword) {
        tabChange(1);
      }
    }
  }, []);

  const getGrpCmpnyList = async () => {
    const url = "/api/groupCmpnyList";
    const body = { groupNos: [parseInt(id, 10)] };
    const res = await axios.post(url, body);

    if (res.status === 200) {
      setCmpnys(res.data.result);
    }
  };

  // 탭 메뉴 변경
  const tabChange = (idx) => {
    setActive(idx);
  };

  // 컴포넌트
  function Components({ index }) {
    return (
      <>
        {
          [
            <GroupDetailHome grp={grp} cmpnys={cmpnys} />,
            <GroupDetailSearch grp={grp} grpMber={grpMber} userInfo={userInfo} cmpnys={cmpnys} />,
            <GroupDetailMembers grp={grp} grpMber={grpMber} setIsInvite={setIsInvite} />,
            <GroupDetailSetting
              grp={grp}
              setGrp={setGrp}
              grpMber={grpMber}
              userInfo={userInfo}
              tabChange={tabChange}
              setGrpMber={setGrpMber}
              getGrpMbers={getGrpMbers}
              setUserInfo={setUserInfo}
              setIsInvite={setIsInvite}
            />,
          ][index]
        }
      </>
    );
  }

  return (
    <GroupDetailViewContainer>
      <div className="grpContainer">
        <GroupDetailSideMenu grp={grp} />
        <div className="grpInfoBody">
          {!isMobile && <GroupDetailTabMenu tabChange={tabChange} />}
          <div className="grpSection">{<Components index={active} />}</div>
        </div>
      </div>
      {isInvite && <GroupInvite members={grpMber} setIsInvite={setIsInvite} grp={grp} />}
      {isMobile && <GroupDetailFooter tabChange={tabChange} />}
    </GroupDetailViewContainer>
  );
};

export default GroupDetailView;
