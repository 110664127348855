import React from "react";
import NumberKeyboard from "./numberKeyboard";

const TelFields = ({ transformValue, formData, handleChangeInput }) => {
  const formatPhone = (value) => {
    if (!value) return "";
    const cleaned = value.replace(/\D/g, "");

    if (cleaned.length <= 3) {
      return cleaned;
    }
    if (cleaned.length <= 7) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    }

    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 7)}-${cleaned.slice(7, 11)}`;
  };

  return (
    <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="inputFields">
        <input type="text" value={formatPhone(formData.tel)} readOnly />
      </div>
      <div className="fieldBox">
        <NumberKeyboard
          type="tel"
          inputValue={formData.tel}
          handleChangeInput={handleChangeInput}
        />
      </div>
    </div>
  );
};

export default TelFields;
