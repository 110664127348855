import BoardView from "../../../../common/boardViewPage";
import FaqTitle from "./faqTitle";

export default function FaqViewForm() {
  return (
    <div>
      <div className="inner_content">
        <FaqTitle />
        <BoardView />
      </div>
    </div>
  );
}
