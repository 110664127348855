import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { PageContainer } from "./styled/common";

export default function ChangePwForm({ user, code }) {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const validateInput = () => {
    if (!formData.password) {
      toast.error("비밀번호를 입력해주세요.");
      return false;
    }
    if (!formData.confirmPassword) {
      toast.error("비밀번호 확인을 입력해주세요.");
      return false;
    }
    if (formData.password !== formData.confirmPassword) {
      toast.error("비밀번호가 일치하지 않습니다.");
      return false;
    }
    const regPassword = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[#?!@$ %^&*-]).{8,}$/;
    if (!regPassword.test(formData.password)) {
      toast.error("비밀번호 양식이 올바르지 않습니다.");
      return false;
    }
    return true;
  };

  const changeInput = (e) => {
    const { name, value } = e.target;
    if (!name) return;

    setFormData((data) => ({ ...data, [name]: value }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!validateInput()) return;

    try {
      const url = "/api/newPasswd";
      const body = {
        username: user.userName,
        mberNm: user.mberNm,
        email: user.email,
        certCode: code,
        password: formData.password,
      };

      const res = await axios.post(url, body);
      console.log(res);

      if (res.data.success) {
        toast.success("비밀번호가 변경되었습니다.");
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
      toast.error("잠시 후 다시 시도해주세요.");
    }
  };

  return (
    <PageContainer>
      <div className="inner_content_wrap">
        <div className="change_pw">
          <form onSubmit={onSubmit}>
            <input
              type="password"
              placeholder="새 비밀번호 (영문 + 숫자 + 특수문자, 8자리이상)"
              name="password"
              onChange={(e) => changeInput(e)}
            />
            <input
              type="password"
              placeholder="새 비밀번호 확인 (영문 + 숫자 + 특수문자, 8자리이상)"
              name="confirmPassword"
              onChange={(e) => changeInput(e)}
            />
            <button type="submit" id="pw-change-btn" className="pw_confirm">
              <span>정보수정하기</span>
            </button>
          </form>
        </div>
      </div>
    </PageContainer>
  );
}
