import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="section fp-auto-height myContent" id="section4">
      <footer>
        <div className="ft_link">
          <a href="">
            {common.tool} {common.name}
          </a>
          <a href="">회사소개</a>
          <Link to={"/policy"}>이용약관</Link>
          <Link to={"/policy"}>개인정보처리방침</Link>
        </div>
        <div className="ft_info">
          <div className="ft_info1">
            <span className="font_18 font500 pdb_30">대표전화</span>
            <span className="font_30 font700 font_color_1 pdb_40">{common.call}</span>
            <span className="font_18 font300">
              {common.time}
              <br />
              {common.day}
            </span>
          </div>
          <div className="ft_info2">
            <span className="font_16 font400 pdb_70">
              상호명: 노타이틀&nbsp;&nbsp;&nbsp;&nbsp; 대표자명: 조윤호&nbsp;&nbsp;&nbsp;&nbsp;
              {common.business}
              <br />
              통신판매업: 제 2022-대전서구-1316 호
              <br />
              {common.address}
              &nbsp;&nbsp;&nbsp;&nbsp; {common.fax}
              &nbsp;&nbsp;&nbsp;&nbsp; {common.mail}
            </span>
            <span className="font_14 font300">{common.copyright}</span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
