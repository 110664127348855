import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { SwiperListContainer, SwiperPerViewContainer } from "../styled/common";
import { useMediaQuery } from "react-responsive";

const createSlides = (data, range) => {
  const slides = [];
  for (let i = 0; i < data.length; i += range) {
    slides.push(data.slice(i, i + range));
  }
  console.log(slides);
  return slides;
};

const SwiperPerView = ({ data, type }) => {
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [slides, setSlides] = useState([]);
  const range = isMobile ? 1 : isTablet ? 4 : 6;

  useEffect(() => {
    setSlides(createSlides(data, range));
  }, [data]);

  const swiperRef = useRef();

  return (
    <SwiperPerViewContainer className={type}>
      <Swiper
        className="wrapper"
        ref={swiperRef}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}>
        {slides.map((slideData, index) => (
          <SwiperSlide key={index}>
            <div className="contentSlide">
              {slideData.map((item) => (
                <div className="box">
                  <div className="image">
                    <img
                      src={item?.imgUrl ? imgHandler(item.imgUrl) : "/img/function_11_bg.png"}
                      alt="group image"
                    />
                  </div>
                  <div className="info">
                    <span>{item?.groupName ? item.groupName : "게임하기"}</span>
                    <button>가입하기</button>
                  </div>
                </div>
              ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperPerViewContainer>
  );
};

export default SwiperPerView;
