import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

import { onClickLogout } from "../../../store/user";

import Modal from "../../../common/modal";
import ShopSideMenu from "./component/shopSideMenu";
import DefaultHeader from "./component/themes/defaultHeader";
import ModernHeader from "./component/themes/modernHeader";
import CuteHeader from "./component/themes/cuteHeader";

const ShoppingHeader = ({ url, homepageInfo, theme }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [modalOpen, setModalOpen] = useState(false);
  const [cmpnyNm, setCmpnyNm] = useState("");
  const [logo, setLogo] = useState("");
  const [headerFixed, setHeaderFixed] = useState(false);
  const [clickSideMenu, setClickSideMenu] = useState(false);
  const { pathname } = useLocation();
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > (isMobile ? 4 : 14) && !isShopMainPage() && ["A", "B"].includes(theme)) {
        setHeaderFixed(true);
      } else {
        setHeaderFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (homepageInfo?.cmpnyNo && !homepageInfo?.logoText && !homepageInfo?.logoImage) {
      getCmpnyNm();
    }
    if (homepageInfo?.logoImage) {
      getLogo();
    }
    if (homepageInfo?.cmpnyNo) {
      getMenuSetting();
    }
  }, [homepageInfo]);

  const isShopMainPage = () => {
    const urls = ["/shop/", "/shop"];
    return urls.includes(pathname);
  };

  const getMenuSetting = async () => {
    const url = "/api/menuSetting";
    const body = {
      cmpnyNo: homepageInfo?.cmpnyNo,
    };
    const res = await axios.post(url, body);

    if (res.status === 200 && res.data.length > 0) {
      const data = res.data
        .filter((item) => item.delYn === "N" && item.displayYn === "Y")
        .sort((a, b) => {
          if (a.seq > b.seq) {
            return 1;
          }
          if (a.seq < b.seq) {
            return -1;
          }
          return 0;
        });

      setMenus(data);
    } else {
      const data = createDefaultMenu();
      setMenus(data);
    }
  };

  const links = {
    0: "company",
    1: "productsList",
    2: "portfolio",
    3: "shoppingNotice",
  };

  const createDefaultMenu = () => {
    const names = ["회사소개", "제품소개", "포트폴리오", "공지사항"];
    const data = [];

    for (let i = 0; i < names.length; i++) {
      data.push({
        menuType: `${i}`,
        displayYn: "N",
        beforeMenuName: names[i],
        afterMenuName: "",
        seq: i + 1,
        cmpnyNo: homepageInfo?.cmpnyNo,
        delYn: "N",
      });
    }

    return data;
  };

  const getLogo = async () => {
    setLogo(await imgHandler(homepageInfo?.logoImage));
  };

  const handleHome = () => {
    navigate(`/shop/${url ? url : ""}`);
  };

  const handleLogoutModal = () => {
    setModalOpen(!modalOpen);
  };

  const onClickModalCheck = async () => {
    await dispatch(onClickLogout());
    setModalOpen(false);
    navigate(`/shop${url ? `/${url}` : ""}`);
  };

  const getCmpnyNm = async () => {
    const url = "/api/cmpnyNmLoad";
    const body = {
      cmpnyNo: homepageInfo?.cmpnyNo,
    };
    const response = await axios.post(url, body);
    setCmpnyNm(response.data.cmpnyNm);
  };

  const onClickCancel = () => {
    setModalOpen(false);
    navigate(`/shop/${url}`);
  };

  const LogoutModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">로그아웃</h2>
        <button onClick={() => handleLogoutModal()} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          로그아웃 하시겠습니까?
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}>
          <button
            onClick={() => onClickCancel()}
            className="hp_step_setting_cate_save"
            style={{
              padding: "10px 20px",
              margin: "0px",
              background: "#6C757D",
            }}>
            취소
          </button>
          <button
            onClick={() => onClickModalCheck()}
            className="hp_step_setting_cate_save"
            style={{
              padding: "10px 20px",
              margin: "0px",
            }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const renderComponent = () => {
    const commonProps = {
      headerFixed,
      cmpnyNm,
      logo,
      menus,
      links,
      handleHome,
      homepageInfo,
      handleLogoutModal,
      url,
      setClickSideMenu,
    };

    const components = {
      A: DefaultHeader,
      B: DefaultHeader,
      C: ModernHeader,
      D: CuteHeader,
      E: null,
    };

    const HeaderComponent = components[theme] || DefaultHeader;
    return HeaderComponent ? <HeaderComponent {...commonProps} /> : null;
  };

  return (
    <>
      <Modal
        modalState={modalOpen}
        handleModalState={handleLogoutModal}
        html={LogoutModalForm()}
        w="300px"
        h="auto"
      />
      {renderComponent()}
      <ShopSideMenu
        setClickSideMenu={setClickSideMenu}
        clickSideMenu={clickSideMenu}
        user={user}
        url={url}
        menus={menus}
      />
    </>
  );
};

export default ShoppingHeader;
