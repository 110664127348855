import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const Products = ({ homepageInfo, cmpnyNo, url }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getProducts(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getProducts = async (cmpnyNo) => {
    const url = "/api/prductStock";
    const body = {
      cmpnyNo,
      offset: 0,
      pageNumber: 0,
      pageSize: 3,
      paged: true,
    };

    try {
      const res = await axios.post(url, body);
      setProducts(res.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="productInfo">
      <div className="info">
        <span>PRODUCTS</span>
        <span>{homepageInfo.cmpnyNm}의 인기 상품을 보여드릴게요</span>
      </div>
      <div className="products">
        {products.length > 0 &&
          products.map((item) => (
            <div
              className="box"
              onClick={() => navigate(`/shop/${url}/productsView/${item.prdNo}`)}>
              <span>{item.prductNm}</span>
              <img
                src={
                  item.prdImgFlpth
                    ? imgHandler(item.prdImgFlpth)
                    : item.extrlImgUrl
                    ? imgHandler(item.extrlImgUrl)
                    : "/img/icon_function_3.png"
                }
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Products;
