import styled from "styled-components";

export const PrdSyncDesktopContainer = styled.div`
  table {
    border-collapse: collapse;
    font-size: 16px;
    text-align: center;
    width: 100%;
    tr th {
      padding: 20px 0;
    }
    tr td {
      padding: 15px 0;
      border-bottom: 1px solid #ccc;
      img {
        max-width: 60px;
        height: 60px;
        object-fit: contain;
      }
      span {
        display: block;
        &.name {
          position: relative;
          &[data-description] {
            cursor: pointer;
            &::after {
              content: attr(data-description);
              position: absolute;
              transform: translate(-50%, 0);
              left: 50%;
              top: 100%;
              background-color: rgba(0, 0, 0, 0.4);
              opacity: 0;
              transition: 0.3s;
              font-size: 12px;
              padding: 5px;
              border-radius: 4px;
              white-space: nowrap;
              color: white;
            }
            &:hover::after {
              opacity: 1;
            }
          }
        }
      }
      .buttons {
        button {
          width: calc(50% - 5px);
          max-width: 100px;
          font-size: 16px;
          color: #333;
          border-radius: 5px;
          padding: 5px 0;
          &:first-child {
            margin-right: 10px;
            border: 1px solid #ddd;
            background-color: #eee;
          }
          &:last-child {
            color: #fff;
            background-color: #555;
            border: 1px solid #555;
          }
        }
      }
    }
  }
`;

export const ProductFilterModalContainer = styled.div`
  .filter_form {
    padding: 20px;
    min-width: 400px;
    ul {
      width: 100%;
    }
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      label {
        display: block;
        margin-top: 5px;
      }
      input,
      .selected {
        width: 100%;
        display: block;
        border: 1px solid #555;
        padding: 5px 10px;
        font-weight: 300;
        &:focus {
          outline: none;
        }
      }
      .selected {
        span {
          color: #0074ca;
        }
      }

      &.category {
        height: 300px;
        .categories {
          width: 100%;
          font-weight: 300;
          border: 1px solid #555;
          border-top: none;
          height: calc(100% - 64px);
          overflow-x: hidden;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #0074ca;
            border: 2px solid #fff;
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          li {
            margin: 0;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
              background: #efefef;
            }
          }
          button {
            margin-right: 5px;
            background: transparent;
            border: none;
            svg {
              display: block;
            }
          }
        }
      }
      &.price {
        label {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    button {
      padding: 10px 20px;
      &:first-child {
        background: #6c757d;
      }
    }
  }
`;
