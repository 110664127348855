import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const FailPage = () => {
  const { url } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    console.log(url);
  }, []);

  return <div></div>;
};

export default FailPage;
