import React, { useState } from "react";
import { useSelector } from "react-redux";

import GroupDetailSettingProfile from "./groupDetailSetting/groupDetailSettingProfile";
import GroupDetailSettingInfo from "./groupDetailSetting/groupDetailSettingInfo";
import GroupDetailSettingMember from "./groupDetailSetting/groupDetailSettingMember";
import GroupDetailSettingMembership from "./groupDetailSetting/groupDetailSettingMembership";

import { GroupDetailSettingContainer } from "../../styled/group";

import { IoMdArrowBack } from "react-icons/io";
import GroupDetailSettingDelete from "./groupDetailSetting/groupDetailSettingDelete";
import GroupDetailSettingWithdrawal from "./groupDetailSetting/groupDetailSettingWithdrawal";

const GroupDetailSetting = ({
  grp,
  setGrp,
  grpMber,
  userInfo,
  tabChange,
  setGrpMber,
  setUserInfo,
  getGrpMbers,
  setIsInvite,
}) => {
  const [active, setActive] = useState(0);
  const [viewType, setViewType] = useState(0);

  const transformValue = viewType * -100;

  const settingTabChange = (idx) => {
    setActive(idx);
    setViewType(1);
  };

  function Components({ index }) {
    return (
      <>
        {
          [
            <GroupDetailSettingProfile
              grp={grp}
              userInfo={userInfo}
              tabChange={tabChange}
              setViewType={setViewType}
              setGrpMber={setGrpMber}
              setUserInfo={setUserInfo}
            />,
            <GroupDetailSettingInfo grp={grp} setGrp={setGrp} />,
            <GroupDetailSettingMember
              grp={grp}
              userInfo={userInfo}
              setGrpMber={setGrpMber}
              grpMber={grpMber}
              setIsInvite={setIsInvite}
            />,
            <GroupDetailSettingMembership getGrpMbers={getGrpMbers} grp={grp} />,
            userInfo.auth === 0 ? (
              <GroupDetailSettingDelete grp={grp} setGrp={setGrp} />
            ) : (
              <GroupDetailSettingWithdrawal userInfo={userInfo} />
            ),
          ][index]
        }
      </>
    );
  }

  const tabs = ["프로필 변경", "모임 변경", "멤버 관리", "가입 승인 관리", "모임 탈퇴"];

  return (
    <GroupDetailSettingContainer>
      <div className="slideWrapper">
        <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
          <div className="label">설정</div>
          <ul className="settingMenu">
            {tabs.map(
              (item, idx) =>
                (idx === 0 || idx === 4 || userInfo.auth === 0) && (
                  <li key={idx} onClick={() => settingTabChange(idx)}>
                    {idx === 4 && userInfo.auth === 0 ? "모임 삭제" : item}
                  </li>
                ),
            )}
          </ul>
        </div>
        <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
          <div className="backToMenu">
            <button onClick={() => setViewType(0)}>
              <IoMdArrowBack />
            </button>
            <span>{active === 4 && userInfo.auth === 0 ? "모임 삭제" : tabs[active]}</span>
          </div>
          {<Components index={active} />}
        </div>
      </div>
    </GroupDetailSettingContainer>
  );
};

export default GroupDetailSetting;
