import React from "react";
import { FaCheck } from "react-icons/fa";

const SelectIcon = ({ transformValue, shortcut, changePage, changeShortcut }) => {
  const shortcuts = [
    // "project",
    // "scheduler",
    // "group",
    "snapchat",
    "discord",
    "youtube",
    "facebook",
    "tiktok",
    "instagram",
    "twitch",
    "thread",
    "behance",
    "naver",
    "",
  ];

  return (
    <div
      className="slideContent selectIcon"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <span className="label">아이콘 생성</span>
      <div className="content">
        {shortcuts.map((item, idx) => (
          <div
            className={shortcut.icon === item ? "img active" : "img"}
            onClick={() => changeShortcut({ target: { name: "icon", value: item } })}>
            {!item ? (
              <>
                <img src={`/img/quickLinks/not.png`} />
                <div className="not">{shortcut.label.slice(0, 1)}</div>
              </>
            ) : (
              <img src={`/img/quickLinks/${item}.png`} />
            )}
            <div className="checkmark">
              <FaCheck />
            </div>
          </div>
        ))}
      </div>
      <div className="buttons">
        <button onClick={() => changePage()}>이전</button>
        <button onClick={() => changePage(true)}>생성</button>
      </div>
    </div>
  );
};

export default SelectIcon;
