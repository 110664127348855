import React, { useEffect, useState } from "react";
import { Modal, ModalCloseButton } from "../../styled/common";
import { StoreSettingsContainer } from "../../styled/storage";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import SettingForm from "./storeSettings/settingForm";
import SaveForm from "./storeSettings/saveForm";
import { TbArrowBackUp } from "react-icons/tb";

const StoreSettings = ({ handleExitModal }) => {
  const [settings, setSettings] = useState({
    isOpen: true,
    fridgeAvailable: true,
    ambientAvailable: true,
    carrierAvailable: true,
    notice: "",
  });

  const [viewType, setViewType] = useState(0);
  const transformValue = viewType * -100;

  const handleChange = (e) => {
    const { name, type, value } = e.target;

    if (type === "radio") {
      setSettings((data) => {
        const updatedSettings = { ...data, [name]: value === "true" };

        const anyOptionTrue = options.some((option) => updatedSettings[option.field] === true);
        updatedSettings.isOpen = anyOptionTrue;

        return updatedSettings;
      });
    } else {
      setSettings((data) => ({ ...data, [name]: value }));
    }
  };

  const options = [
    { label: "냉장고", field: "fridgeAvailable" },
    { label: "상온", field: "ambientAvailable" },
    { label: "캐리어", field: "carrierAvailable" },
  ];

  useEffect(() => {
    getStorageStatus();
  }, []);

  const getStorageStatus = async () => {
    const url = "/erp/breadStorageStatus";
    const body = { id: 1 };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        console.log({ ...res.data, notice: res.data.notice ? res.data.notice : "" });
        setSettings((data) => ({ ...res.data, notice: res.data.notice ? res.data.notice : "" }));
      }
    } catch (error) {
      alert("잠시 후 다시 시도해주세요.");
    }
  };

  const handleSubmit = async () => {
    if (viewType === 0) {
      setViewType(1);
      console.log(settings);
      return;
    }

    const url = "/erp/updateBreadStorage";
    const body = { ...settings };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        toast.success("성공적으로 변경되었습니다.");
        console.log(res);
        await handleExitModal();
      }
    } catch (error) {
      alert("잠시 후 다시 시도해주세요.");
    }
  };

  const toggleAllSelected = (value) => {
    const updatedSettings = { ...settings };

    options.forEach((option) => {
      updatedSettings[option.field] = value;
    });

    setSettings(() => ({ ...updatedSettings, isOpen: value }));
  };

  return (
    <StoreSettingsContainer>
      <Modal>
        <div className="modalContent">
          <div className="slideWrapper">
            <SettingForm
              transformValue={transformValue}
              options={options}
              settings={settings}
              toggleAllSelected={toggleAllSelected}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
            <SaveForm transformValue={transformValue} handleSubmit={handleSubmit} />
          </div>
        </div>
        <ModalCloseButton
          className="close"
          onClick={() => (viewType ? setViewType(0) : handleExitModal())}>
          {viewType ? <TbArrowBackUp /> : <IoClose />}
        </ModalCloseButton>
      </Modal>
    </StoreSettingsContainer>
  );
};

export default StoreSettings;
