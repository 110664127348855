import React, { useState } from "react";

import { ProjectListContainer, ProjectSection } from "./component/common/styled/project";

import ProjectSideMenu from "./component/common/component/projectSideMenu";
import ProjectHeader from "./component/common/component/projectHeader";
import ProjectFileBoxView from "./component/projectFileBoxPage/projectFileBoxView";
import TooltipSideMenu from "../../../common/tooltipSideMenu";

const ProjectFileBoxPage = () => {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [clickSideMenu, setClickSideMenu] = useState(false);

  return (
    <ProjectSection>
      <ProjectListContainer>
        <ProjectHeader clickSideMenu={clickSideMenu} setClickSideMenu={setClickSideMenu} />
        <div className="inner">
          <ProjectSideMenu showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu} />
          <ProjectFileBoxView
            setShowSideMenu={setShowSideMenu}
            showSideMenu={showSideMenu}
            clickSideMenu={clickSideMenu}
            setClickSideMenu={setClickSideMenu}
          />
        </div>
        {clickSideMenu && (
          <TooltipSideMenu
            clickSideMenu={clickSideMenu}
            setClickSideMenu={setClickSideMenu}
            isMargin={false}
          />
        )}
      </ProjectListContainer>
    </ProjectSection>
  );
};

export default ProjectFileBoxPage;
