import styled from "styled-components";

export const KeyboardContainer = styled.div`
  width: 100%;
  font-size: 20px;
  height: 100%;
  .keyboard {
    width: 100%;
    height: 100%;
    display: table;
    button {
      width: calc(100% / 3);
      height: calc(100% / 4);
      font-size: 30px;
      background: transparent;
      border: 1px solid #e9edf0;
      font-weight: 700;
      &:last-child {
        background: #4c80f1;
        color: #fff;
        border: 1px solid #4c80f1;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .keyboard button {
      font-size: 20px;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 86px;
  width: 100vw;
  border-bottom: 1px solid #e9edf0;
  h2 {
    font-size: 30px;
  }
  button {
    background: transparent;
    border: none;
    font-size: 40px;
    display: flex;
    &:last-child {
      svg {
        stroke-width: 20px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    height: 50px;
    h2 {
      font-size: 16px;
    }
    button {
      font-size: 30px;
    }
  }
`;

export const ActionConfirmationContainer = styled.div`
  height: calc(100% - 86px);
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 50px;
    .tit {
      font-size: 24px;
      font-weight: 600;
    }
    .con {
      span {
        display: block;
      }
      font-size: 32px;
      font-weight: 700;
    }
    .actions {
      button {
        background: #4c80f1;
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        border: none;
        padding: 18px 50px;
      }
    }
  }
`;
