function InitialState() {
  const user = getUserToSessionStorage();
  if (user) {
    return {
      result: user
    }
  } else {
    return {
      result: null,
    };
  }
}

function saveUserToSessionStorage(result) {
  sessionStorage.setItem('user', JSON.stringify(result));
}

function getUserToSessionStorage() {
  const user = sessionStorage.getItem('user');
  return user ? JSON.parse(user) : null;

}

const initialState = InitialState();
// 초기 상태값 정의


export default function reducer(state = initialState, action) {

  switch (action.type) {
    case "LOGIN":
      saveUserToSessionStorage(action.payload);
      return {
        ...state,
        result: action.payload,
      };
    case "LOGOUT":
      sessionStorage.removeItem('user');
      return {
        ...state,
        result: null
      };
    
    default:
      return state;
  }

}