import React from "react";
import { Link } from "react-router-dom";
import { FiHome, FiMenu, FiShoppingCart, FiUser, FiFileText } from "react-icons/fi";
import { PiUsersThreeLight } from "react-icons/pi";
import { Row, Col } from "react-bootstrap";

function MobileFooter() {
  const footerMenu = [
    { icon: FiHome, name: "협업툴모노티", path: "/" },
    { icon: FiFileText, name: "새소식", path: "/group/feed" },
    { icon: PiUsersThreeLight, name: "전체모임", path: "/group" },
    { icon: FiUser, name: "내모임", path: "/group/myGroup" },
  ];
  return (
    <Row
      className="d-lg-none navbar fixed-bottom navbar-light bg-light"
      style={{
        zIndex: "9000",
        position: "fixed",
        display: "flex",
        justifyContent: "space-evenly",
        height: "70px",
        alignItems: "center",
        backgroundColor: "#eee",
        bottom: 0,
        width: "100%",
      }}>
      {footerMenu.map((item) => (
        <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem", width: "25%" }}>
          <Link to={item.path} className="nav-link">
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <item.icon style={{ fontSize: "30px", color: "black", strokeWidth: "1" }} />
              <span>{item.name}</span>
            </div>
          </Link>
        </Col>
      ))}
    </Row>
  );
}

export default MobileFooter;
