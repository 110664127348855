const BoardSearch = ({selectList, setSelected, setKeyword, getData}) => {
  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  }

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  return (
    <div className="search_box">
      <fieldset>
        <legend>검색영역</legend>
        <div className="basic">
          <select name="search_type" id="search_type" onChange={handleSelect}>
            {selectList.map((item, index) =>
              <option key={index} value={item}>{item}</option>
            )}
          </select>
          <input type="text" id="keyword" name="keyword" placeholder="검색어를 입력하세요." onChange={handleKeywordChange} onKeyPress={(e) => handleKeyPress(e, getData)}/>
          <button type="submit"><i className="fa fa-search" aria-hidden="true" /><span className="sound_only">검색</span></button>
        </div>
      </fieldset>
    </div>
  );
};

export default BoardSearch;