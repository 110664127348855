import React from "react";

import { IoEyeOutline } from "react-icons/io5";
import { AiOutlineLike } from "react-icons/ai";
import { MdOutlineInsertComment } from "react-icons/md";

import { Interaction } from "../styled/common";

const InteractionStats = () => {
  return (
    <Interaction className="interaction">
      <div>
        <span className="icon">
          <IoEyeOutline />
        </span>
        <span>3900</span>
      </div>
      <div>
        <span className="icon">
          <AiOutlineLike />
        </span>
        <span>150</span>
      </div>
      <div>
        <span className="icon">
          <MdOutlineInsertComment />
        </span>
        <span>150</span>
      </div>
    </Interaction>
  );
};

export default InteractionStats;
