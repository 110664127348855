import React, { useState } from "react";
import EventListItem from "../common/eventListItem";
import { CiAlarmOn, CiBellOn, CiHashtag, CiTextAlignLeft } from "react-icons/ci";
import { IoHappyOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import IconPicker from "../common/iconPicker";

const DdayForm = ({ formData, handleClickButton, setFormData, isEditable, pushMinutes }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [isIcon, setIsIcon] = useState(false);

  const changeInput = (e) => {
    const { name, value } = e.target;

    if (!name) return;
    if (name === "startDt" && !value) return;

    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  return (
    <>
      <ul>
        <EventListItem icon={<CiHashtag />} title="제목" addClass="title">
          {formData.icon && (
            <img src={`/img/scheduler/scheduler_icon_${formData.icon}`} className="selectedIcon" />
          )}
          <input
            type="text"
            placeholder="디데이 제목"
            value={formData.title}
            name="title"
            onChange={(e) => changeInput(e)}
            style={{
              padding: formData.icon ? "7px 30px" : "7px 30px 7px 14px",
            }}
          />
          <button className="iconSelect" onClick={() => setIsIcon(true)}>
            <IoHappyOutline />
          </button>
          {isIcon && <IconPicker setIsIcon={setIsIcon} setFormData={setFormData} />}
        </EventListItem>
        <EventListItem icon={<CiAlarmOn />} title="날짜" addClass="date">
          <div className="dtBox">
            <input
              type="date"
              value={formData.startDt}
              onChange={(e) => changeInput(e)}
              name="startDt"
              style={{ width: isMobile ? "100%" : "50%" }}
              day={moment(formData.startDt).format("dddd")}
            />
          </div>
        </EventListItem>
        <EventListItem icon={<CiBellOn />} title="알림" addClass="push">
          <select
            name="pushMinute"
            value={formData.pushMinute}
            onChange={(e) => changeInput(e)}
            style={{ width: isMobile ? "100%" : "50%" }}>
            <option value="notPush">선택안함</option>
            {Object.entries(pushMinutes).map(([label, value]) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </select>
        </EventListItem>
        <EventListItem icon={<CiTextAlignLeft />} title="메모" addClass="">
          <textarea
            placeholder="설명을 추가해주세요"
            value={formData.memo}
            name="memo"
            onChange={(e) => changeInput(e)}></textarea>
        </EventListItem>
      </ul>
      <div className="btnArea font_14">
        <button onClick={() => handleClickButton(isEditable ? "eventEdit" : "eventSave")}>
          저장
        </button>
        {isEditable && (
          <button onClick={() => handleClickButton("eventDel")} className="delBtn">
            삭제
          </button>
        )}
      </div>
    </>
  );
};

export default DdayForm;
