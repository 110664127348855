import React from "react";
import { SideFormList } from "../styled/calendarEntry";
import { useRegex } from "../../../../../hooks/useRegex";
import { useSelector } from "react-redux";

const SideFormItem = ({ item, formData, onToggle, actSelType }) => {
  const { TelFormat } = useRegex();
  const isWorkerSelected = formData.workerList && formData.workerList.hasOwnProperty(item.mberNo);
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);

  return (
    <SideFormList className={isDarkMode}>
      <label htmlFor={item.mberNo}>
        <div>
          {actSelType ? (
            <input
              type="radio"
              id={item.mberNo}
              name="manager"
              onChange={() => onToggle(item)}
              checked={formData.managerNo === item.mberNo}
            />
          ) : (
            <input
              type="checkbox"
              id={item.mberNo}
              name="worker"
              onChange={() => onToggle(item)}
              checked={isWorkerSelected}
            />
          )}
        </div>
        <div>{item.mberNm}</div>
        {formData.gbn === "C" && (
          <div>
            {item.tel
              ? TelFormat(item.tel, /(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3")
              : item.moblphonNo
              ? TelFormat(item.moblphonNo, /(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3")
              : ""}
          </div>
        )}
      </label>
    </SideFormList>
  );
};

export default SideFormItem;
