import React from "react";
import { WeeklyCommentContainer } from "../styled/community";
import InteractionStats from "../common/interactionStats";

const WeeklyComments = () => {
  const temp = Array.from({ length: 2 });

  return (
    <WeeklyCommentContainer>
      <div className="label">주간 베스트 한마디</div>
      <div className="comments">
        {temp.map((item) => (
          <div className="box">
            <div className="userIcon">
              <img src="/img/user2.png" alt="" />
            </div>
            <div className="feed">
              <span>안녕하세요~ 다들 좋은 하루 보내세요</span>
              <div className="info">
                <span className="writer">모노티</span>
                <InteractionStats />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="moreBotton">
        <button>
          <span>더보기 +</span>
        </button>
      </div>
    </WeeklyCommentContainer>
  );
};

export default WeeklyComments;
