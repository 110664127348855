import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router";

import { SearchEventContainer } from "../../scheduler/component/styled/scheduler";

import { hexToRgb } from "../../scheduler/component/function/common";

const SearchSchedule = ({ data, isSearch }) => {
  const [scheduleList, setScheduleList] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const transformedData = transformData(data);
      setScheduleList(transformedData);
    }
  }, [data]);

  // 년/월/일 일정 분류
  const transformData = (schedules) => {
    return schedules.reduce((acc, item) => {
      const startDate = moment(item.startDt).startOf("day");
      const endDate = moment(item.endDt).startOf("day");

      for (let date = startDate; date.isSameOrBefore(endDate, "day"); date.add(1, "days")) {
        const year = date.year();
        const month = date.month() + 1;
        const day = date.date();
        if (!acc[year]) acc[year] = {};
        if (!acc[year][month]) acc[year][month] = {};
        if (!acc[year][month][day]) acc[year][month][day] = [];
        acc[year][month][day].push(item);
      }
      return acc;
    }, {});
  };

  const isColor = (event) => {
    if (!event) return;

    let color;

    if (event.gbn === "P") color = `${event.color || "139859"}`;
    if (event.gbn === "C") color = "0074ca";
    if (event.gbn === "G") color = "F46F02";

    const rgb = hexToRgb(color);
    return `rgba(${rgb}, 0.8)`;
  };

  return (
    <SearchEventContainer>
      {isSearch && (
        <div className="searchContaier" style={{ height: "auto" }}>
          <>
            {Object.keys(scheduleList).length > 0 ? (
              <table className="searchResultsTable">
                <tbody>
                  {Object.entries(scheduleList).flatMap(([year, months]) => [
                    <tr key={year}>
                      <td className="year">{year}년</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>,
                    ...Object.entries(months).flatMap(([month, days]) => [
                      <tr key={`${year}-${month}`}>
                        <td></td>
                        <td className="month">{month}월</td>
                        <td></td>
                        <td></td>
                      </tr>,
                      ...Object.entries(days).flatMap(([day, events]) => [
                        <tr key={`${year}-${month}-${day}`}>
                          <td></td>
                          <td></td>
                          <td className="day">{day}일</td>
                          <td></td>
                        </tr>,
                        ...events.map((event, index) => (
                          <tr key={`${year}-${month}-${day}-${index}`}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td
                              style={{
                                background: isColor(event),
                                color: "#fff",
                                textDecoration: event.completeYn === "Y" ? "line-through" : "none",
                                cursor: "pointer",
                              }}
                              className="event"
                              onClick={() => navigate(`/mypage/detailEvent/${event.scheduleNo}`)}>
                              {event.title}
                            </td>
                          </tr>
                        )),
                      ]),
                    ]),
                  ])}
                </tbody>
              </table>
            ) : (
              <div className="noData">일정이 존재하지 않습니다.</div>
            )}
          </>
        </div>
      )}
    </SearchEventContainer>
  );
};

export default SearchSchedule;
