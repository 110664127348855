import React from "react";
import "./style/loading.css"; // 스타일 시트를 import 합니다.

const ShopLoading = () => {
  return (
    <div className="loading" >
      <div className="spinner"></div>
    </div>
  );
};

export default ShopLoading;