import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { HiMiniEllipsisVertical } from "react-icons/hi2";
import { Link } from "react-router-dom";

const Homepage = ({ label, className, handleButtonClick }) => {
  const user = useSelector((state) => state?.user?.data[0]);

  const [domain, setDomain] = useState("");
  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    checkedDomain();
  }, []);

  const checkedDomain = async () => {
    const url = "/api/domainSettingLoad";
    const body = { delYn: "N", cmpnyNo: user.cmpnyNo };
    const res = await axios.post(url, body);

    if (res.status === 200) setDomain(res.data.domain);
  };

  const openNewTab = () => {
    window.open(`http://1472.ai/shop/${domain}`);
    user && localStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <div>
      <div className="info">
        <span className="label">{label}</span>
        <button data-name={className} onClick={handleButtonClick}>
          <HiMiniEllipsisVertical />
        </button>
      </div>
      {domain && (
        <span className="cmpny" onClick={openNewTab}>
          https://1472.ai/shop/{domain}
        </span>
      )}
    </div>
  );
};

export default Homepage;
