import React from 'react';

const CustomPagination = ({totalPage, currentPage, getData, setCurrentPage}) => {
  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  }

  const firstPageChange = () => {
    setCurrentPage(0);
    handlePageChange(0);
  }

  const prevPageChange = () => {
    if(currentPage > 0){
      handlePageChange(currentPage - 1);
    }
  }

  const nextPageChange = () => {
    if(currentPage < totalPage - 1){
      handlePageChange(currentPage + 1);
    }
  }

  const lastPageChange = () => {
    setCurrentPage(totalPage - 1);
    handlePageChange(totalPage - 1);
  }

  return (
    <>
      <a onClick={firstPageChange} className="ctrl fir"><i className="fa fa-angle-double-left" aria-hidden="true"></i><span className="sound_only">처음</span></a>
      <a onClick={prevPageChange} className="ctrl bac"><i className="fa fa-angle-left" aria-hidden="true"></i><span className="sound_only">이전</span></a>
      {Array.from({length : totalPage > 5 ? 5 : totalPage}, (_, index) => {
        let page;
        if(currentPage >= 2){
          page = currentPage - 2 + index + 1;
        } else {
          page = index + 1;
        }

        return (
          page === currentPage + 1 ?
          <a key={page} className="num actived" onClick={() => handlePageChange(page - 1)}>
            <strong>{page}<span className="sound_only">현재 페이지</span></strong>
          </a>
          :
          <a key={page} className="num" onClick={() => handlePageChange(page - 1)}>
            {page}<span className="sound_only"></span>
          </a>
        )
      })}
      <a onClick={nextPageChange} className="ctrl nex"><i className="fa fa-angle-right" aria-hidden="true"></i><span className="sound_only">다음</span></a>
      <a onClick={lastPageChange} className="ctrl las"><i className="fa fa-angle-double-right" aria-hidden="true"></i><span className="sound_only">마지막</span></a>
    </>
  );
};

export default CustomPagination;