import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { toast } from "react-toastify";

// styled import
import { GroupDetailContainer } from "./component/styled/group";
import { GroupSection } from "./component/styled/common";

// page import
import GroupJoin from "./component/groupJoin";
import GroupDetailView from "./component/groupDetail/groupDetailView.jsx";
import Loading from "../../common/loading.jsx";

// use globally redux
import { hideLoading, showLoading } from "../../store/loading";

const GroupDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const isLoading = useSelector((state) => state?.loading?.isLoading);
  const user = useSelector((state) => state?.user?.data[0]);

  const [isMap, setIsMap] = useState(false);
  const [grp, setGrp] = useState({});
  const [grpMber, setGrpMber] = useState([]);
  const [isMapExpand, setIsMapExpand] = useState(true);
  const [isUserRegister, setIsUserRegister] = useState(false);
  const [userInfo, setUserInfo] = useState({
    auth: location.state?.auth !== undefined ? location.state.auth : 4,
    display: location.state?.display ? location.state.display : false,
  });
  const [isApplyUser, setIsApplyUser] = useState(null);

  useEffect(() => {
    if (!checkParam()) {
      return;
    }
    if (!user) {
      setIsUserRegister(false);
    }
    getGrpDetail();
  }, []);

  const checkParam = () => {
    if (isNaN(id)) {
      navigate("/group");
      return false;
    } else {
      setIsMap(true);
    }
    return true;
  };

  // 모임 조회
  const getGrpDetail = async () => {
    dispatch(showLoading());
    const url = "/api/groupList";
    const req = getDataCond();
    const res = await axios.post(url, req);
    const grpInfo = res?.data?.content[0];

    if (grpInfo) {
      setGrp(grpInfo);
      getGrpMbers(grpInfo);
    } else {
      toast.error("존재하지 않는 모임입니다.");
      navigate("/group");
    }
  };

  // 모임 멤버 조회
  const getGrpMbers = async (grp) => {
    const url = "/api/groupMberList";
    const req = getDataCond();
    const res = await axios.post(url, req);
    const grpMbers = res?.data?.content;

    // 가입 여부
    const isMber = grpMbers?.filter((item) => item?.mberNo === user?.mberNo)?.[0];

    if (isMber) {
      setUserInfo((prev) => ({
        ...prev,
        auth: isMber.auth,
        seq: isMber.seq,
        nickNm: isMber.nickNm,
        gbnValue: isMber.gbnValue,
      }));
      setIsUserRegister(true);
    }

    if (grpMbers) {
      setGrpMber(grpMbers);
    }
    // 가입하지 않았거나
    // 명칭/닉네임을 설정한 모임이고 명칭/닉네임을 정하지 않았을 경우
    if (!isMber || (!isMber.gbnValue && grp.gbn) || (!isMber?.nickNm && grp.nickUseYn === "Y")) {
      setIsUserRegister(false);
    } else {
      setIsUserRegister(true);
    }

    if (grp.approvalUseYn === "Y" && !isMber) {
      getApplyMbers(grp);
    }
    dispatch(hideLoading());
  };

  // 가입 신청 대기 멤버 조회
  const getApplyMbers = async (grp) => {
    const url = "/api/groupMberList";
    const req = getDataCond();
    req.approvalYn = "N";
    const res = await axios.post(url, req);
    const applyList = res?.data?.content;

    // 가입 신청 여부
    const isApply = applyList?.filter((item) => item?.mberNo === user?.mberNo)?.[0];

    // 비공개 모임이고
    // 가입 신청 목록에 존재하지 않거나 로그인을 하지 않았고
    // state 전달 값을 받지 않았을 경우
    if ((!isApply || !user) && grp.displayYn === "N" && !userInfo.display) {
      toast.error("해당 모임은 비공개 모임입니다.");
      navigate("/group");
      return;
    }
    if (isApply) {
      setIsApplyUser(isApply);
    }
  };

  const getDataCond = () => {
    return {
      groupNo: id,
      offset: 0,
      delYn: "N",
      pageNumber: 0,
      pageSize: 1,
      paged: false,
    };
  };

  return (
    <>
      <GroupDetailContainer>
        <GroupSection style={{ margin: `60px 0 0`, minHeight: `calc(-60px + 100vh)` }}>
          {isUserRegister ? (
            <>
              <GroupDetailView
                isMap={isMap}
                isMapExpand={isMapExpand}
                setIsMapExpand={setIsMapExpand}
                grp={grp}
                setGrp={setGrp}
                grpMber={grpMber}
                setGrpMber={setGrpMber}
                setUserInfo={setUserInfo}
                getGrpMbers={getGrpMbers}
              />
            </>
          ) : (
            <GroupJoin
              setIsUserRegister={setIsUserRegister}
              userInfo={userInfo}
              grp={grp}
              getGrpMbers={getGrpMbers}
              isApplyUser={isApplyUser}
            />
          )}
        </GroupSection>
        {isLoading && <Loading />}
      </GroupDetailContainer>
    </>
  );
};

export default GroupDetail;
