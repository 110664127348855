export default function HomepageTip () {
  return(
    <>
    <div className="inner_title">
      <span className="font_36 font600 font_color_black pdb_40">홈페이지 꿀팁</span>
    </div>
    <div className="inner_content_wrap" id="">
        <ul className="step_end_ul">
            <li>
                <img src="/img/hp_step_end_1.png" />
                <span className="font_24 font600 font_color_black pdb_20 text_center">#대문 사진을 바꿔보세요.</span>
                <p className="font_18 font300 font_color_gray text_center">
                    시작 화면에 원하는 이미지를 넣을 수 있습니다.<br />
                    설정 &gt; 메인이미지 화면으로 이동해보세요.
                </p>
            </li>
            <li>
                <img src="/img/hp_step_end_2.png" />
                <span className="font_24 font600 font_color_black pdb_20 text_center">#로고를 넣어보세요.</span>
                <p className="font_18 font300 font_color_gray text_center">
                    로고는 PGN파일만 등록이 가능합니다.<br />
                    설정 &gt; 로고에서 이미지를 첨부해 주세요.
                </p>
            </li>
            <li>
                <img src="/img/hp_step_end_3.png" />
                <span className="font_24 font600 font_color_black pdb_20 text_center">#도메인이 있으신가요?</span>
                <p className="font_18 font300 font_color_gray text_center">
                    노타이틀에서는 원하는 ID를 도메인 뒤에 적용해드립니다.<br />
                    설정 &gt; 홍보설정 에서 도메인을 적용해주세요.
                </p>
            </li>
            <li>
                <img src="/img/hp_step_end_4.png" />
                <span className="font_24 font600 font_color_black pdb_20 text_center">#제품연동을 어떻게 하나요?</span>
                <p className="font_18 font300 font_color_gray text_center">
                    설정 &gt; 제품에서 다양한 제품을 보시고, 취급하는 제품을 나의 홈페이지에 연동 할 수 있습니다.
                </p>
            </li>
        </ul>
    </div>
    </>
  )
}