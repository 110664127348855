import styled from "styled-components";

export const GroupInner = styled.div`
  box-sizing: border-box;
  max-width: 1400px;
  margin: auto;
  a {
    color: #000;
  }
  &.minHeigth {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1400px) {
  }
`;

export const GroupSection = styled.div`
  min-height: calc(100vh - 540px);
  color: #000;
  box-sizing: border-box;
  margin: 60px 0;
  position: relative;
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 130px);
    margin: 100px 0;
  }
`;

export const GroupListContainer = styled.div`
  margin: 3%;
  position: relative;
  .member_info_img {
    height: 170px;
    margin-bottom: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .member_info {
    width: 100%;
    height: 80px;
  }
  .member_search .member_info_img img {
    width: 100%;
    object-fit: contain;
  }
  .member_search.groupMember .member_search_wrap {
    min-height: 0;
    background-color: white;
  }
  @media screen and (max-width: 767px) {
    .member_info_top {
      height: 60px !important;
      margin-top: 10%;
      display: -webkit-box;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .member_search.groupMember .member_search_wrap {
      min-height: 0;
      margin: 0;
      padding: 10px;
    }
    .member_search .member_info_img {
      height: 100px;
    }
  }
  @media screen and (max-width: 640px) {
    .member_search.groupMember .member_search_wrap {
      width: calc(100% / 2 - 5px);
    }
  }
`;

export const GroupMberList = styled.div`
  margin: 3%;
  .member_search_wrap {
    min-height: 100px;
    padding: 25px;
  }
  img {
    height: 100px;
    width: 100%;
    object-fit: contain;
  }
  .memberTitle {
    height: 52px;
  }
  @media screen and (max-width: 767px) {
    .memberTitle {
      height: 40px;
    }
  }
  @media screen and (max-width: 640px) {
    .members .member_search.groupMember .member_search_wrap {
      width: calc(100% / 2 - 5px);
    }
  }
`;

export const GroupSearchBox = styled.div`
  margin: 30px 0 30px;
  .search_box {
    text-align: center;
    input {
      width: 50%;
    }
  }
  @media screen and (max-width: 1400px) {
    .search_box {
      text-align: center;
    }
  }
  @media screen and (max-width: 1050px) {
  }
  @media screen and (max-width: 767px) {
  }
`;

export const GroupDetailMenu = styled.div`
  .title {
    background: #efefef;
    padding: 40px 0;
    box-sizing: border-box;
    margin-bottom: 20px;
    height: 400px;
    .image {
      max-width: 700px;
      height: 100%;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .delDt {
    text-align: center;
    span {
      color: #bf0000;
    }
  }
  .subMenu {
    max-width: 1400px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    li {
      a {
        width: 180px;
        height: 50px;
        line-height: 50px;
        border: 1px solid #cccccc;
        font-size: 18px;
        font-weight: 300;
        transition: 0.5s;
        position: relative;
        text-align: center;
        display: block;
        &:hover,
        &.actived {
          background: #0074ca;
          border-color: #0074ca;
          color: #fff;
          font-weight: 700;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
  }
  @media screen and (max-width: 1050px) {
  }
  @media screen and (max-width: 767px) {
    .subMenu {
      justify-content: center;
    }
  }
`;

export const GroupTitle = styled.span`
  padding: 40px 0;
  @media screen and (max-width: 767px) {
    padding: 0;
    margin-top: 70px;
  }
`;

export const GroupExit = styled.div`
  background-color: #0074ca;
  border-radius: 50%;
  position: fixed;
  bottom: 80px;
  right: 1rem;
  cursor: pointer;
  z-index: 1;
  padding: 15px;
  width: 60px;
  height: 60px;
  font-size: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  svg {
    color: #fff;
  }
`;

export const GroupPagination = styled.div`
  margin-bottom: 0;
  @media screen and (max-width: 767px) {
    margin-bottom: 150px;
  }
`;

export const NotData = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const NotDataList = styled.div`
  font-size: 18px;
  text-align: center;
  padding: 15px 0;
`;

export const ConfirmButton = styled.button`
  border: none;
  color: #fff;
  background: #0074ca;
  margin: 50px auto 0;
  padding: 15px 25px;
  font-size: 18px;
  display: block;
  &.isSubmit {
    background: #464646;
    cursor: no-drop;
    pointer-events: none;
  }
`;

export const HeaderContainer = styled.header`
  padding: 0;
  h1 {
    display: none;
  }
  .headerInner {
    max-width: 1400px;
    padding: 10px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    nav {
      width: calc(100% - 500px);
      li {
        margin-right: 0;
      }
    }
    .logo {
      height: 40px;
      object-fit: contain;
      margin: 0;
      width: auto;
    }
    .login_menu {
      width: 165px;
    }
    .dropdown-menu {
      width: 150%;
      padding: 20px 0;
      li a {
        padding: 0;
      }
    }
    .headerBtnArea {
      display: flex;
      .notify {
        width: 40px;
        height: 40px;
        font-size: 32px;
        border: none;
        color: #fff;
        margin-left: 10px;
        position: relative;
        background: #0074ca;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(25%, -25%);
          background: #bd1e01;
          font-size: 10px;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      button {
        width: 40px;
        height: 40px;
        font-size: 32px;
        border: none;
        color: #fff;
        margin-left: 10px;
        svg {
          display: block;
          margin: auto;
        }
        &:first-child {
          margin-left: 20px;
        }
        &.logout {
          background: #333;
          i.icon_logout {
            background: url("/img/icon_logout.png");
            background-position: 50% 50%;
            background-size: cover;
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        &.login {
          background: #333;
          i.icon_login {
            background: url("/img/icon_login.png");
            background-position: 50% 50%;
            background-size: cover;
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        &.sideMenu,
        &.mypage,
        &.search {
          background: #0074ca;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    nav {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .headerInner {
      .login_menu {
        width: auto;
        transform: translateY(0);
      }
      img.logo {
        width: 100px;
        height: 35px;
      }
    }
  }
`;
