import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProjectSwiper from "./swiper/projectSwiper";
import { HiOutlinePlus } from "react-icons/hi2";
import { useMediaQuery } from "react-responsive";

const Projects = ({ label, className, handleButtonClick }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const [project, setProject] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  useEffect(() => {
    if (user) {
      getProjectList();
    }
  }, [user]);

  const getProjectList = async () => {
    const url = "/api/projectList";
    const body = {
      mberNo: user.mberNo,
      delYn: "N",
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      const allProjects = [].concat(
        res.data.cmpnyProjectList || [],
        res.data.fixedProjectList || [],
        res.data.groupProjectList || [],
        res.data.otherProjectList || [],
      );

      setProject(allProjects);
    }
  };

  return (
    <div>
      <div className="info">
        <span className="label">{label}</span>
        <button data-name={className} onClick={handleButtonClick}>
          <HiOutlinePlus />
        </button>
      </div>
      <ProjectSwiper data={project} perView={isMobile ? 1.5 : 4.3} />
    </div>
  );
};

export default Projects;
