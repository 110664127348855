import React from "react";
import SalesCheckInfo from "./common/salesCheckInfo";
import SortOptions from "./common/sortOptions";
import SalesList from "./common/salesList";

const SalesOverview = ({ active, tabs }) => {
  const temp = Array.from({ length: 11 });

  return (
    <>
      <SalesCheckInfo active={active} tabs={tabs} />
      <SortOptions active={active} />
      <SalesList data={temp} type="salesOverview" />
    </>
  );
};

export default SalesOverview;
