import React from "react";
import { GroupDetailSearchFormContainer } from "../../../styled/group";
import { IoMdSearch } from "react-icons/io";

const GroupDetailSearchForm = ({ searchWord, setSearchWord, handleSearch }) => {
  return (
    <GroupDetailSearchFormContainer>
      <input
        type="text"
        value={searchWord}
        onChange={(e) => setSearchWord(e.target.value)}
        onKeyDown={(e) => handleEnterKey(e, handleSearch)}
      />
      <button onClick={handleSearch}>
        <IoMdSearch />
      </button>
    </GroupDetailSearchFormContainer>
  );
};

export default GroupDetailSearchForm;
