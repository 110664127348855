import React from "react";
import { GroupDetailSearchBlogContainer } from "../../../styled/group";
import { NotDataList } from "../../../styled/common";
import { BlogItem } from "./groupDetailSearchItem";

const GroupDetailSearchBlog = ({ data, cmpnys, isSearch }) => {
  return (
    <GroupDetailSearchBlogContainer>
      {isSearch && (
        <>
          <div className="label">
            {data.length > 0 && (
              <span>
                검색 결과
                <strong>{data.length.toLocaleString()}</strong>개
              </span>
            )}
          </div>
          {data.length > 0 ? (
            <ul>
              {data.map((item) => (
                <BlogItem item={item} cmpnys={cmpnys} />
              ))}
            </ul>
          ) : (
            <NotDataList>친구소식이 존재하지 않습니다.</NotDataList>
          )}
        </>
      )}
    </GroupDetailSearchBlogContainer>
  );
};

export default GroupDetailSearchBlog;
