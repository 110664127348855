import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

import { AddScheduleContainer, ScheduleFormContainer } from "./component/styled/calendarEntry";
import { GoToBackHeader } from "./component/styled/common";

import { keysToRemove } from "./component/function/scheduler";
import { isSameUser } from "./component/function/common";

import { IoMdArrowBack } from "react-icons/io";

import DdayForm from "./component/ddayEntry/ddayForm";
import Modal from "../../../common/modal";
import ModalContent from "./component/common/modalContent";

const DdayEntry = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state?.user?.data[0]);
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);

  const [formData, setFormData] = useState({
    mberNo: user.mberNo,
    title: "",
    startDt: "",
    gbn: "P",
    pushMinute: 900,
    memo: "",
    dday: "Y",
  });
  const [modalMsg, setModalMsg] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");

  const ddayInfo = location.state?.eventInfo || {};

  const isEditable = Object.keys(ddayInfo).length > 3 && isSameUser(user, ddayInfo);

  const pushMinutes = {
    "하루 전 오전 9시": 900,
    "하루 전 오후 12시": 720,
    "당일 오전 9시": -540,
    "당일 오후 12시": -720,
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    isURLSearched();

    if (Object.keys(ddayInfo).length !== 0) {
      const item = keysToRemove(ddayInfo);
      const date = moment(item.startDt).format("YYYY-MM-DD");
      const push =
        !item.pushMinute || !Object.values(pushMinutes).includes(item.pushMinute)
          ? "notPush"
          : item.pushMinute;

      setFormData((data) => ({
        ...data,
        ...item,
        pushMinute: push,
        startDt: date,
      }));
    } else {
      const date = moment().format("YYYY-MM-DD");
      setFormData((data) => ({
        ...data,
        startDt: date,
      }));
    }
  }, []);

  const isURLSearched = () => {
    const params = new URLSearchParams(location.search);
    const keywordEncoded = params.get("keyword");

    if (keywordEncoded) {
      const keyword = decodeURIComponent(keywordEncoded);
      setSearchTxt(keyword);
    }
  };

  // 모달
  const handleModalOpen = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  const exitPage = () => {
    setModalMsg("exit");
    handleModalOpen();
  };

  const handleModalConfirm = () => {
    if (modalMsg === "exit") {
      navigate(`/mypage/scheduler${searchTxt ? `?keyword=${searchTxt}` : ""}`);
    }
    if (modalMsg === "eventEdit" || modalMsg === "eventSave") {
      handleSubmit();
    }
    if (modalMsg === "eventDel") {
      handleDelete();
    }
  };

  const handleModalCancel = () => {
    setModalOpen(false);
  };

  const handleSubmit = async () => {
    const url = "/api/schedule";
    const body = {
      ...formData,
      delYn: "N",
    };

    if (!isNaN(body.pushMinute)) {
      body.pushMinute = parseInt(body.pushMinute, 10);
    } else {
      body.pushMinute = null;
    }

    body.startDt = moment(body.startDt).format("YYYY-MM-DD 00:00:00");
    body.endDt = moment(body.startDt).format("YYYY-MM-DD 23:59:59");

    const res = await axios.put(url, body);
    if (res.status === 200) {
      toast.success(`디데이가 ${isEditable ? "수정" : "등록"}되었습니다.`);
      navigate(`/mypage/scheduler${searchTxt ? `?keyword=${searchTxt}` : ""}`);
    }
  };

  const handleDelete = async () => {
    const url = "/api/schedule";
    const body = {
      ...formData,
      delYn: "Y",
    };

    const res = await axios.put(url, body);
    if (res.status === 200) {
      toast.success("디데이가 삭제되었습니다.");
      navigate(`/mypage/scheduler${searchTxt ? `?keyword=${searchTxt}` : ""}`);
    }
  };

  const handleClickButton = (action) => {
    setModalMsg(action);
    if (action !== "eventDel") {
      checkSubmitCond();
    } else {
      handleModalOpen();
    }
  };

  const checkSubmitCond = () => {
    if (!formData.title) {
      toast.error("제목이 존재하지 않습니다");
      return;
    }

    handleModalOpen();
  };

  return (
    <AddScheduleContainer className={`inner_content ${isDarkMode}`} id="schduler_content">
      <GoToBackHeader className={isDarkMode}>
        <span className="icon" onClick={exitPage}>
          <IoMdArrowBack />
        </span>
        <span>디데이 {isEditable ? "수정" : "등록"}</span>
      </GoToBackHeader>
      <ScheduleFormContainer>
        <DdayForm
          formData={formData}
          setFormData={setFormData}
          isEditable={isEditable}
          handleClickButton={handleClickButton}
          pushMinutes={pushMinutes}
        />
      </ScheduleFormContainer>
      <Modal
        modalState={modalOpen}
        handleModalState={handleModalOpen}
        html={
          <ModalContent
            modalMsg={modalMsg}
            handleConfirm={handleModalConfirm}
            handleCancel={handleModalCancel}
            isDday={true}
          />
        }
        isDarkMode={isDarkMode}
        w="300px"
        h="auto"
      />
    </AddScheduleContainer>
  );
};

export default DdayEntry;
