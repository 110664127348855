import MypageBanner from "../mypageBanner";
import FaqViewForm from "./component/faqViewForm";

export default function FaqView () {
  return(
    <>
      <MypageBanner/>
      <FaqViewForm />
    </>
  )
}