import { FiHome, FiSearch, FiUsers, FiSettings, FiMap } from "react-icons/fi";
const navermaps = window.naver.maps;

export const tabMenus = [
  {
    name: "홈",
    icon: FiHome,
  },
  {
    name: "검색",
    icon: FiSearch,
  },

  {
    name: "멤버",
    icon: FiUsers,
  },
  { name: "설정", icon: FiSettings },
];

export const htmlMarkers = {
  htmlMarker1: {
    content:
      '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(https://navermaps.github.io/maps.js.ncp/docs/img/cluster-marker-1.png);background-size:contain;"></div>',
    size: navermaps.Size(40, 40),
    anchor: navermaps.Point(20, 20),
  },
  htmlMarker2: {
    content:
      '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(https://navermaps.github.io/maps.js.ncp/docs/img/cluster-marker-2.png);background-size:contain;"></div>',
    size: navermaps.Size(40, 40),
    anchor: navermaps.Point(20, 20),
  },
  htmlMarker3: {
    content:
      '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(https://navermaps.github.io/maps.js.ncp/docs/img/cluster-marker-3.png);background-size:contain;"></div>',
    size: navermaps.Size(40, 40),
    anchor: navermaps.Point(20, 20),
  },
  htmlMarker4: {
    content:
      '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(https://navermaps.github.io/maps.js.ncp/docs/img/cluster-marker-4.png);background-size:contain;"></div>',
    size: navermaps.Size(40, 40),
    anchor: navermaps.Point(20, 20),
  },
  htmlMarker5: {
    content:
      '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(https://navermaps.github.io/maps.js.ncp/docs/img/cluster-marker-5.png);background-size:contain;"></div>',
    size: navermaps.Size(40, 40),
    anchor: navermaps.Point(20, 20),
  },
};

export const isGrpMberGbnValue = (item) => {
  if (item.nickNm && item.gbn && item.gbnValue) {
    return <span>{`${item.gbnValue}${item.gbn} ${item.nickNm}`}</span>;
  } else {
    return <span>{item.nickNm ? item.nickNm : "닉네임 미설정"}</span>;
  }
};

export const findMembersByCompanyNo = (cmpnyNo = null, cmpnys = null) => {
  if (!cmpnyNo || !cmpnys) return;

  const cmpnyDetails = cmpnys[cmpnyNo];
  if (cmpnyDetails && Array.isArray(cmpnyDetails) && cmpnyDetails.length > 0) {
    return cmpnyDetails.map((item) => isGrpMberGbnValue(item));
  } else {
    return null;
  }
};
