import React from "react";
import { SelectedScopeContainer } from "../styled/calendarEntry";
import { toast } from "react-toastify";

const SelectedScope = ({ type, item, setItem, isModalOpen, submit }) => {
  const text = type === "update" ? "수정" : "삭제";

  const changeScope = (e) => {
    const { name, value } = e.target;
    setItem((data) => ({
      ...data,
      recur: {
        ...data.recur,
        [name]: value,
      },
    }));
  };

  const isChecked = (option) => {
    return item.recur[type] === option;
  };

  const onSubmit = () => {
    if (!item.recur[type]) {
      toast.error("수정 옵션을 선택해주세요.");
      return;
    }

    submit();
  };

  const renderRadioOption = (option, label) => (
    <label>
      <input
        type="radio"
        name={type}
        value={option}
        onChange={changeScope}
        checked={isChecked(option)}
      />
      <span>{label}</span>
    </label>
  );

  return (
    <SelectedScopeContainer>
      <div className="contentArea">
        <span>반복된 일정입니다.</span>
        <div className="checkArea">
          {renderRadioOption("ONLY", `이 일정만 ${text}`)}
          {renderRadioOption("AFTER", `이후 일정 모두 ${text}`)}
          {renderRadioOption("ALL", `전체 반복 일정 ${text}`)}
        </div>
        <div className="btnArea">
          <button onClick={() => isModalOpen(false)}>취소</button>
          <button onClick={onSubmit}>{text}</button>
        </div>
      </div>
    </SelectedScopeContainer>
  );
};

export default SelectedScope;
