import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { DashboardHeaderContainer } from "./styled/common";
import { CiBellOn, CiHome, CiSearch, CiMenuBurger } from "react-icons/ci";
import DashboardSideMenu from "./dashboardSideMenu";
import { useSelector } from "react-redux";
import TooltipSideMenu from "./tooltipSideMenu";

const DashboardHeader = () => {
  const navigate = useNavigate();
  const [clickSideMenu, setClickSideMenu] = useState(false);
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  const setting = useSelector((state) => state?.user?.setting);

  const menuItems = [
    { icon: CiHome, title: "home", link: "/" },
    { icon: CiBellOn, title: "home", link: "/mypage/notify" },
    { icon: CiSearch, title: "home", link: "/mypage/search" },
  ];

  const services = [
    { link: "/info/group", label: "모임" },
    { link: "/info/scheduler", label: "캘린더" },
    { link: "/info/project", label: "프로젝트" },
    { link: "/info/shop", label: "e층창고" },
  ];

  const sideMenuItems = [
    { title: "서비스", link: "#", items: services },
    { title: "공지/안내", link: "/notice", items: noticeBanner },
  ];

  useEffect(() => {
    if (clickSideMenu) {
      document.documentElement.style.height = "100vh";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.height = "auto";
      document.documentElement.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [clickSideMenu]);

  return (
    <DashboardHeaderContainer className={setting?.mode}>
      <div className="headerInner">
        <h1>{service.welcome}</h1>
        <Link to="/" className="logo">
          <div>MONOTI</div>
        </Link>
        <div className="nav">
          {menuItems.map((item) => (
            <button onClick={() => navigate(item.link)}>
              <div className="icon">{<item.icon />}</div>
            </button>
          ))}
          <button onClick={() => setClickSideMenu(true)} className="sideMenu">
            <CiMenuBurger />
          </button>
        </div>
      </div>
      {
        <>
          {clickSideMenu && !isTablet ? (
            <TooltipSideMenu setClickSideMenu={setClickSideMenu} clickSideMenu={clickSideMenu} />
          ) : (
            <DashboardSideMenu
              setClickSideMenu={setClickSideMenu}
              clickSideMenu={clickSideMenu}
              menuItems={sideMenuItems}
            />
          )}
        </>
      }
    </DashboardHeaderContainer>
  );
};
export default DashboardHeader;
