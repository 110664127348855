import React from "react";
import ScheduleSwiper from "./swiper/scheduleSwiper";
import { HiOutlinePlus } from "react-icons/hi2";

const Dday = ({ label, ddays, className, handleButtonClick }) => {
  return (
    <div>
      <div className="info">
        <span className="label">{label}</span>
        <button data-name={className} onClick={handleButtonClick}>
          <HiOutlinePlus />
        </button>
      </div>
      <ScheduleSwiper data={ddays} perView={1} />
    </div>
  );
};

export default Dday;
