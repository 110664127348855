import React, { useEffect } from "react";

import { IoIosLink } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { FiSend } from "react-icons/fi";

import ProjectDetailCommentFiles from "./projectDetailCommentFiles";
import { ProjectNewComment } from "../../../common/styled/project";

const ProjectDetailCommentNewItem = ({
  project,
  isReply,
  selectedComment,
  resetSelectComment,
  newComment,
  handleDeleteFile,
  handleComment,
  notParticipant,
  updateFilesFromInput,
  textareaRef,
  MAX_LENGTH,
  handleKeyDown,
  handleContentChange,
  handlePaste,
}) => {
  return (
    <ProjectNewComment>
      {isReply && (
        <div class="replyOrigin">
          <div className="originContent">
            <div className="info">
              <span>{selectedComment.mberNm}</span>에게 답장
            </div>
            <div className="replyContent">
              {selectedComment.content ? selectedComment.content : "사진/파일"}
            </div>
          </div>
          <button onClick={() => resetSelectComment()}>
            <IoCloseSharp />
          </button>
        </div>
      )}
      <div className="commentContainer">
        <div className="commentContent active">
          <input
            type="file"
            id="newFile"
            onChange={(e) => project?.projectParticipantNo && updateFilesFromInput(e)}
            multiple
            onClick={(e) => !project?.projectParticipantNo && notParticipant(e)}
          />
          <label htmlFor="newFile" style={{ cursor: "pointer" }}>
            <IoIosLink />
          </label>
          {project?.projectParticipantNo ? (
            <textarea
              rows={1}
              ref={textareaRef}
              onChange={handleContentChange}
              onKeyDown={handleKeyDown}
              maxLength={MAX_LENGTH}
              value={newComment.content}
              onPaste={handlePaste}></textarea>
          ) : (
            <div>프로젝트 참여 후 댓글을 남길 수 있습니다.</div>
          )}
          <button
            className="submit"
            onClick={(e) => (project?.projectParticipantNo ? handleComment() : notParticipant(e))}>
            <FiSend />
          </button>
        </div>
        <ProjectDetailCommentFiles
          item={newComment}
          images={newComment.imgList}
          files={newComment.fileList}
          handleDeleteFile={handleDeleteFile}
          type={"new"}
        />
      </div>
    </ProjectNewComment>
  );
};

export default ProjectDetailCommentNewItem;
