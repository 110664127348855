import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  bussinessCode: "",
  largeCode: "",
  middleCode: "",
  smallCode: "",
  detailCode: "",
  bussinessCategory: [],
  largeCategory: [],
  middleCategory: [],
  smallCategory: [],
  detailCategory: [],
  isClick: [false, false, false, false],
};

const productCode = createSlice({
  name: "productCode",
  initialState,
  reducers: {
    setBussinessCode(state, action) {
      state.bussinessCode = action.payload;
    },
    setLargeCode(state, action) {
      state.largeCode = action.payload;
    },
    setMiddleCode(state, action) {
      state.middleCode = action.payload;
    },
    setSmallCode(state, action) {
      state.smallCode = action.payload;
    },
    setDetailCode(state, action) {
      state.detailCode = action.payload;
    },
    setBussinessCategory(state, action) {
      state.bussinessCategory = [...action.payload];
    },
    setLargeCategory(state, action) {
      state.largeCategory = [...action.payload];
    },
    setMiddleCategory(state, action) {
      state.middleCategory = [...action.payload];
    },
    setSmallCategory(state, action) {
      state.smallCategory = [...action.payload];
    },
    setDetailCategory(state, action) {
      state.detailCategory = [...action.payload];
    },
    setIsClick(state, action) {
      state.isClick = [...action.payload];
    },
    resetBussiness(state) {
      state.bussinessCode = "";
      state.largeCode = "";
      state.middleCode = "";
      state.smallCode = "";
      state.detailCode = "";
      state.largeCategory = [];
      state.middleCategory = [];
      state.smallCategory = [];
      state.detailCategory = [];
      state.isClick = [false, false, false, false];
    },
    resetLarge(state) {
      state.largeCode = "";
      state.middleCode = "";
      state.smallCode = "";
      state.detailCode = "";
      state.middleCategory = [];
      state.smallCategory = [];
      state.detailCategory = [];
      state.isClick = [true, false, false, false];
    },
  },
});

export const {
  setBussinessCode,
  setLargeCode,
  setMiddleCode,
  setSmallCode,
  setDetailCode,
  setBussinessCategory,
  setLargeCategory,
  setMiddleCategory,
  setSmallCategory,
  setDetailCategory,
  setIsClick,
  resetBussiness,
  resetLarge,
} = productCode.actions;
export default productCode;
