import React, { useEffect, useState } from "react";
import FilePreview from "../../common/filePreview";
import { DetailProductContainer } from "../../../styled/productForm";
import moment from "moment";

const DetailProduct = ({ closeModal, selectedProduct, categories, categoriesObj }) => {
  const fields = [
    { name: "상품명", key: "name", type: "string" },
    { name: "브랜드", key: "brand", type: "string" },
    { name: "상품설명", key: "description", type: "string" },
    { name: "규격", key: "specifications", type: "string" },
    { name: "가격", key: "price", type: "number" },
    { name: "상품재고", key: "quantity", type: "number" },
    // {name:'태그',key:"tags"},
  ];

  const isExist = (data) => {
    if (!data) return false;
    if (!data.length) return false;
    return true;
  };

  return (
    <DetailProductContainer>
      <h2 className="font_20 font500 font_color_white">상품 수정</h2>
      <button onClick={() => closeModal()} className="hp_step_setting_wrap_close">
        <i className="fa fa-times" aria-hidden="true" />
      </button>
      <ul>
        {isExist(selectedProduct?.media) && (
          <li className="media">
            <span className="tit">상품이미지</span>
            <div className="content">
              {selectedProduct.media.map((item) => (
                <FilePreview item={item} />
              ))}
            </div>
          </li>
        )}
        {fields.map((item) => (
          <li>
            <span className="tit">{item?.name}</span>
            <span className="content">
              {item?.type === "string"
                ? selectedProduct?.[item?.key]
                : `${selectedProduct?.[item?.key].toLocaleString() || 0}`}
            </span>
          </li>
        ))}
        <li>
          <span className="tit">카테고리</span>
          <span className="content">{categoriesObj[selectedProduct?.categoryId]}</span>
        </li>
        <li className="tags">
          {isExist(selectedProduct?.tags)}
          <span className="tit">태그</span>
          <div className="content">
            {selectedProduct.tags.map((item, idx) => (
              <span>
                {item}
                {idx !== selectedProduct.tags.length - 1 && ", "}
              </span>
            ))}
          </div>
        </li>
        <li>
          <span className="tit">상품생성날짜</span>
          <span className="content">{moment(selectedProduct.createdAt).format("YYYY-MM-DD")}</span>
        </li>
        <li>
          <span className="tit">상품연동날짜</span>
          <span className="content">{moment(selectedProduct.updatedAt).format("YYYY-MM-DD")}</span>
        </li>
      </ul>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
        <button
          onClick={() => console.log(1)}
          className="hp_step_setting_cate_save"
          style={{ padding: "10px 20px", margin: "0px" }}>
          확인
        </button>
      </div>
    </DetailProductContainer>
  );
};

export default DetailProduct;
