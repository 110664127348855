import React from "react";

const MobileEventTodoListView = ({ item, handleTodoComplete }) => {
  return (
    <ul className="list font_16">
      {item.todo.map((todoItem) => (
        <li key={todoItem.todoNo}>
          <label style={{ display: "flex", gap: 25, cursor: "pointer" }}>
            <input
              type="checkbox"
              name={todoItem.todoNo}
              checked={todoItem.completeYn === "Y"}
              onChange={(e) => handleTodoComplete(e)}
            />
            <span
              style={{
                textDecoration: todoItem.completeYn === "Y" ? "line-through" : "none",
              }}>
              {todoItem.title}
            </span>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default MobileEventTodoListView;
