import React from "react";
import ChunjiinKeyboard from "./keyboard";

const NameFields = ({ transformValue, formData, handleChangeInput }) => {
  return (
    <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="inputFields">
        <input type="text" value={formData.mberNm} readOnly />
      </div>
      <div className="fieldBox">
        <ChunjiinKeyboard handleChangeInput={handleChangeInput} />
      </div>
    </div>
  );
};

export default NameFields;
