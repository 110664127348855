import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mySchedSet: {
    settingNo: null,
    mberNo: null,
    screenMode: 0,
    mobileScreenMode: null,
    personalYn: true,
    companyYnList: [],
    groupYnList: [],
    viewMode: "D",
  },
  isDarkMode: null,
};

const scheduler = createSlice({
  name: "scheduler",
  initialState,
  reducers: {
    getSetting(state, action) {
      state.mySchedSet = action.payload;
      state.isDarkMode = action.payload.screenMode ? "dark" : "";
    },
    resetSetting(state) {
      state.mySchedSet = {};
    },
  },
});

export const { getSetting, resetSetting } = scheduler.actions;
export default scheduler;
