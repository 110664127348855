import PortfolioList from "./portfolioPage/portfolioList";
import { useOutletContext } from "react-router";

import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";

import { ChicPortfolioContainer } from "../shopping/component/styled/chicTheme";
import { ModernProductsContainer } from "../shopping/component/styled/modernTheme";
import { CuteProductsContainer } from "../shopping/component/styled/cuteTheme";
import { SimplePortfolioContainer } from "../shopping/component/styled/simpleTheme";

export default function PortfolioPage() {
  const { theme } = useOutletContext();

  const renderComponent = () => {
    switch (theme) {
      case "A":
        return <PortfolioList />;
      case "B":
        return (
          <ChicPortfolioContainer>
            <PortfolioList />
          </ChicPortfolioContainer>
        );
      case "C":
        return (
          <ModernProductsContainer>
            <ModernBanner />
            <PortfolioList />
          </ModernProductsContainer>
        );
      case "D":
        return (
          <CuteProductsContainer>
            <CuteBanner />
            <PortfolioList />
          </CuteProductsContainer>
        );
      case "E":
        return (
          <SimplePortfolioContainer>
            <SimpleBanner />
            <PortfolioList />
          </SimplePortfolioContainer>
        );
      default:
        return <PortfolioList />;
    }
  };

  return <>{renderComponent()}</>;
}
