import BoardView from "../../../common/boardViewPage";
import NoticeTitle from "./noticeTitle";

export default function NoticeViewForm () {
  return(
    <div className="inner_content">
      <NoticeTitle />
      <div className="inner_content_wrap">
        <BoardView />
      </div>
    </div>
  )
}