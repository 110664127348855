import React, { useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const SideMenuCalendar = ({
  isAnnualCalendar,
  setIsAnnualCalendar,
  handleDateClick,
  onDate,
  data,
  clickDate,
  sideCalendarRef,
  version,
}) => {
  const calendarArea = useRef();

  useEffect(() => {
    const tit = document.querySelector(
      ".sidemenu .writing_constract_list_wrap .calendarArea .fc .fc-header-toolbar .fc-toolbar-title",
    );

    const handleClick = () => setIsAnnualCalendar(true);
    tit.addEventListener("click", handleClick);

    return () => tit.removeEventListener("click", handleClick);
  }, [isAnnualCalendar]);

  return (
    <div
      className="calendarArea"
      style={{ display: isAnnualCalendar ? "none" : "block" }}
      ref={calendarArea}>
      <div className="calendarBtnArea">
        <button
          onClick={() => clickDate({ date: moment().format("YYYY-MM-DD") })}
          className="todayBtn">
          오늘
        </button>
        <div className="moveMonth">
          <button
            onClick={() =>
              handleDateClick(moment(onDate).subtract(1, "months").format("YYYY-MM-01"))
            }>
            <BsChevronLeft />
          </button>
          <button
            onClick={() => handleDateClick(moment(onDate).add(1, "months").format("YYYY-MM-01"))}>
            <BsChevronRight />
          </button>
        </div>
      </div>
      <FullCalendar
        key={version}
        ref={sideCalendarRef}
        initialDate={onDate}
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, interactionPlugin]}
        contentHeight={"auto"}
        events={data}
        locale={"ko"}
        dateClick={(info) => clickDate(info)}
        dayCellContent={(args) => moment(args.date).format("D")}
        eventDisplay={"list-item"}
        dayMaxEvents={3}
        buttonText={{ today: "오늘" }}
        // eventClassNames={(info) => `schedule${assignColorClass(info.event.extendedProps.color)}`}
      />
    </div>
  );
};

export default SideMenuCalendar;
