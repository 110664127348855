import React, { useEffect } from "react";
import { AppInstallContainer } from "./styled/common";

const AppInstall = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const url =
    /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
      ? "https://apps.apple.com/kr/app/%EB%AA%A8%EB%85%B8%ED%8B%B0-monoti-%EA%B3%B5%EC%9C%A0%EC%BA%98%EB%A6%B0%EB%8D%94-%EA%B8%B0%EB%B0%98-%ED%98%91%EC%97%85%ED%88%B4/id6473604609"
      : /android/i.test(userAgent)
      ? "https://play.google.com/store/apps/details?id=com.notitle.toktokCalendar&pcampaignid=web_share"
      : null;

  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  return (
    <AppInstallContainer>
      <button
        onClick={() => {
          if (url) {
            window.location.href = url;
          }
        }}>
        앱 설치
      </button>
    </AppInstallContainer>
  );
};

export default AppInstall;
