import { useOutletContext } from "react-router";

import { ChicNoticeContainer } from "../shopping/component/styled/chicTheme";
import { ModernNoticeContainer } from "../shopping/component/styled/modernTheme";
import { CuteNoticeContainer } from "../shopping/component/styled/cuteTheme";
import { SimpleNoticeContainer } from "../shopping/component/styled/simpleTheme";

import NoticeForm from "./component/noticeForm";
import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";

const ShoppingNotice = () => {
  const { theme } = useOutletContext();

  const renderComponent = () => {
    switch (theme) {
      case "A":
        return <NoticeForm />;
      case "B":
        return (
          <ChicNoticeContainer>
            <NoticeForm />
          </ChicNoticeContainer>
        );
      case "C":
        return (
          <ModernNoticeContainer>
            <ModernBanner />
            <NoticeForm />
          </ModernNoticeContainer>
        );
      case "D":
        return (
          <CuteNoticeContainer>
            <CuteBanner />
            <NoticeForm />
          </CuteNoticeContainer>
        );
      case "E":
        return (
          <SimpleNoticeContainer>
            <SimpleBanner />
            <NoticeForm />
          </SimpleNoticeContainer>
        );
      default:
        return <NoticeForm />;
    }
  };

  return <>{renderComponent()}</>;
};

export default ShoppingNotice;
