import React from "react";

export const mainSection = [
  {
    key: "group",
    title: "모임",
    info: "협력과\n소통의 공간",
    details: "동네 소모임부터\n대규모 행사까지 손쉽게 관리",
    path: "/group",
  },
  {
    key: "scheduler",
    title: "캘린더",
    info: "다양한 일정과\n쉬운 이벤트 관리",
    details: "모임 내 중요한 일정들을\n손쉽게 계획하고 쉽게 공유",
    path: "/mypage/scheduler",
  },
  {
    key: "project",
    title: "프로젝트",
    info: "목표와 작업을\n체계적으로 관리",
    details: "프로젝트 목표 설정, 작업 분배,\n진행 상황 추적 등 쉽게 처리",
    path: "/mypage/projectList",
  },
  {
    key: "shop",
    title: "e층창고",
    info: "내 마음대로 만드는\n나만의 홈페이지",
    details: "편리한 인터페이스와\n다양한 카테고리 제공",
    path: "/shop",
  },
];
