import React from "react";
import { MockupContainer } from "../styled/info";

const Mockup = ({ type, content }) => {
  return (
    <MockupContainer className="infoSection">
      <div className="inner">
        <div className="content">
          {content.heading && <span className="title">{content.heading}</span>}
          {content?.description && <span className="desc">{content.description}</span>}
          {content?.feature && <strong>{content.feature}</strong>}
        </div>
        <div className="img">
          <img src={`/img/info/mockup_${type}.png`} alt="" />
        </div>
      </div>
    </MockupContainer>
  );
};

export default Mockup;
