import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import ProductCategory from "../productCategory";
import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import Modal from "../../../../common/modal";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";

const StyledAutocomplete = styled(Autocomplete)`
  width: 250px;
  font-size: 18px;
  margin-bottom: 20px;

  & input:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none !important;
  }
`;

export default function ProductBrandTop({
  bussinessCode,
  largeCode,
  middleCode,
  smallCode,
  detailCode,
  check,
  selectedBrand,
  setBussinessCode,
  setLargeCode,
  setMiddleCode,
  setSmallCode,
  setDetailCode,
  setCheck,
  setSelectedBrand,
  getBrandList,
  bussinessCategory,
  largeCategory,
  middleCategory,
  smallCategory,
  detailCategory,
  setBussinessCategory,
  setLargeCategory,
  setMiddleCategory,
  setSmallCategory,
  setDetailCategory,
}) {
  useEffect(() => {
    getBrand();
    getBussiness();
  }, []);

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const cmpnyNo = useSelector((state) => state?.user?.data[0]?.cmpnyNo);
  const [brand, setBrand] = useState([]);
  const [message, setMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isClick, setIsClick] = useState([false, false, false, false]);

  const resetBussiness = () => {
    setBussinessCode("");
    setLargeCode("");
    setMiddleCode("");
    setLargeCategory([]);
    setMiddleCategory([]);
    setSmallCategory([]);
    setDetailCategory([]);
    setIsClick([false, false, false, false]);
  };

  const resetLarge = () => {
    setLargeCode("");
    setMiddleCode("");
    setMiddleCategory([]);
    setSmallCategory([]);
    setDetailCategory([]);
    setIsClick([true, false, false, false]);
  };

  const getBrand = async () => {
    const url = "/api/brand";
    const body = {
      searchText: "",
    };

    const response = await axios.post(url, body);
    const result = response.data.map((item) => ({ value: item.brand, label: item.brand }));
    setBrand(result);
  };

  const getBussiness = async () => {
    const url = "/api/code";
    const body = {
      clCode: "BTY",
    };

    const response = await axios.post(url, body);
    setBussinessCategory(response.data);
  };

  const getLargeCategory = async (e) => {
    if (e.target.value === "업종") {
      resetBussiness();
      return;
    }

    const url = "/api/clCode";
    const body = {
      upperClCode: "PCL",
    };

    const response = await axios.post(url, body);

    if (e.target.value === "BTY005") {
      setLargeCategory(response.data);
    } else {
      setLargeCategory([]);
      resetLarge();
    }
    setBussinessCode(e.target.value);
    setIsClick([true, false, false, false]);
  };

  const getMiddleCategory = async (e) => {
    if (e.target.value === "대분류") {
      resetLarge();
      return;
    }

    const url = "/api/clCode";
    const body = {
      upperClCode: e.target.value,
    };

    const response = await axios.post(url, body);
    setLargeCode(e.target.value);
    setMiddleCategory(response.data);
    setIsClick([true, true, false, false]);
  };

  const getSmallCategory = async (e) => {
    const url = "/api/clCode";
    const body = {
      upperClCode: e.target.value,
    };

    const response = await axios.post(url, body);
    setMiddleCode(e.target.value);
    setSmallCategory(response.data);
    setIsClick([true, true, true, false]);
  };

  const getDetailCategory = async (e) => {
    const url = "/api/clCode";
    const body = {
      upperClCode: e.target.value,
    };

    const response = await axios.post(url, body);
    setDetailCategory(response.data);
    setIsClick([true, true, true, true]);
  };

  const saveBrand = () => {
    if (!bussinessCode && !selectedBrand) {
      toast.error("브랜드 또는 업종을 선택해 주세요.");
      return;
    }

    setMessage("연동 리스트에 추가 하시겠습니까?");
    setOpenModal(true);
  };

  const postBrand = async () => {
    const url = "/api/brandSave";
    const request = {
      brand: selectedBrand.value,
      delYn: "N",
      cmpnyNo,
      allTimeFlag: check,
      bsnsTy: bussinessCode,
      prductCl1: largeCode,
      prductCl2: middleCode,
      prductCl3: smallCode,
      prductCl4: detailCode,
    };

    const response = await axios.post(url, request);
    getBrandList({ page: 1 });
    setCheck(false);
    setOpenModal(false);
    toast.success("연동 리스트에 추가 되었습니다.");
  };

  const saveModalForm = (message) => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">브랜드/카테고리 연동</h2>
        <button onClick={() => setOpenModal(!openModal)} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">{message}</span>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}>
          <button
            onClick={() => setOpenModal(!openModal)}
            className="hp_step_setting_cate_save"
            style={{
              padding: "10px 15px",
              margin: "0px",
              background: "#6C757D",
              fontSize: "16px",
            }}>
            취소
          </button>
          <button
            onClick={postBrand}
            className="hp_step_setting_cate_save"
            style={{
              padding: "10px 15px",
              margin: "0px",
              fontSize: "16px",
            }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return;
    }
  };

  return (
    <>
      <StyledAutocomplete
        id="combo-box-demo"
        value={selectedBrand}
        options={brand}
        onChange={(_, newValue) => {
          setSelectedBrand(newValue);
          resetBussiness();
        }}
        clearOnEscape
        renderInput={(params) => (
          <TextField {...params} label="브랜드 검색" variant="standard" onKeyDown={handleKeyDown} />
        )}
        noOptionsText={"검색된 브랜드가 없습니다."}
      />

      <ProductCategory
        getLargeCategory={getLargeCategory}
        getMiddleCategory={getMiddleCategory}
        getSmallCategory={getSmallCategory}
        getDetailCategory={getDetailCategory}
        bussinessCode={bussinessCode}
        largeCode={largeCode}
        middleCode={middleCode}
        bussinessCategory={bussinessCategory}
        largeCategory={largeCategory}
        middleCategory={middleCategory}
        smallCategory={smallCategory}
        detailCategory={detailCategory}
        isClick={isClick}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
          }}>
          <input type="checkbox" onChange={() => setCheck(!check)} checked={check} />
          <span
            style={{
              fontSize: "18px",
              color: "#1b1b1b",
              marginLeft: "10px",
            }}>
            현 시간 이후
          </span>
        </div>
        <button onClick={saveBrand} className="product_edit_btn" type="button">
          저장
        </button>
      </div>
      <Modal
        modalState={openModal}
        // handleModalState={handleDeleteModal}
        html={saveModalForm(message)}
        w={isMobile ? "280px" : "25%"}
        h="auto"
      />
    </>
  );
}
