import React from "react";
import ProgressPointer from "./progressPointer";
import { ProgressContainer } from "../styled/pickup";

const Progress = ({ selected }) => {
  const status = selected?.status;

  return (
    <ProgressContainer>
      <div className="bg">
        {status !== "CANCELLED" ? (
          <>
            <ProgressPointer time="17:00" status="주문대기" className="PENDING" />
            <ProgressPointer time="17:00" status="주문승인" className="CONFIRMED" />
            <ProgressPointer time="17:00" status="상품준비" className="READY" />
            <ProgressPointer time="17:30" status="상품완료" className="COMPLETED" />
            <ProgressPointer time="17:40" status="픽업완료" className="PICKUP" />
          </>
        ) : (
          <ProgressPointer time="17:40" status="주문취소" className="CANCELLED" />
        )}
        <div className={`line ${status}`}></div>
      </div>
    </ProgressContainer>
  );
};

export default Progress;
