import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { OrderContainer } from "../styled/shopping";
import axios from "axios";

const SuccessPage = () => {
  const location = useLocation();
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const data = {
      paymentKey: searchParams.get("paymentKey"),
      orderId: searchParams.get("orderId"),
      amount: searchParams.get("amount"),
    };

    setPaymentData(data);
    handlePayment(data);
  }, [location]);

  const handlePayment = async (data) => {
    const url = "/payments/confirm";
    const body = { paymentKey: data.paymentKey, orderId: data.orderId, amount: data.amount };
    try {
      const res = await axios.post(url, body);
      console.log("결제 완료", res.data);

      // if (data.virtualAccount) {
      //   setBankAccount(data.virtualAccount);
      // }
    } catch (error) {
      console.error("결제 승인 중 오류", error);
      // alert("결제 승인 중 오류");
    }
  };

  return (
    <OrderContainer>
      <h2>결제 성공</h2>
      {paymentData ? (
        <div>
          <h3>결제 정보</h3>
          <p>결제 방법: {paymentData.paymentKey}</p>
          <p>주문 ID: {paymentData.orderId}</p>
          <p>결제 금액: {paymentData.amount}원</p>
        </div>
      ) : (
        <p>결제 정보가 없습니다.</p>
      )}
    </OrderContainer>
  );
};

export default SuccessPage;
