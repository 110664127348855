import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import MainBenefit from "./mainBenefit"
import ServiceBanner from "./component/serviceBanner";
import ProductionCase from "./component/productionCase";
import ServiceBenefit from "./component/serviceBenefit";

const Service = () => {

  const serviceIndex = useSelector((state) => state.compoIndex.service);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const refArr = [ref1, ref2, ref3]
  
  useEffect(() => {
    refArr[serviceIndex]?.current?.scrollIntoView({ behavior: 'auto' });
  }, [serviceIndex]);

  return (
    <div>
      <ServiceBanner/>
      <div className="inner_content" id="function_wrap" ref={refArr[0]}>
        <MainBenefit />
        <ProductionCase ref2={refArr[1]}/>
        <ServiceBenefit ref3={refArr[2]}/>
      </div>
    </div>
  );
};

export default Service;