import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useLocation, useNavigate } from "react-router";
import { Link, useNavigate, useParams } from "react-router-dom";

import { handleLogin } from "../../../store/user";
import { getSetting } from "../../../store/scheduler";

import axios from "axios";

import GroupJoinInfo from "./groupJoinInfo";

import { toast } from "react-toastify";

const GroupJoin = ({ setIsUserRegister, userInfo, grp, getGrpMbers, isApplyUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [sign, setSign] = useState(false);
  const [loginInfo, setLoginInfo] = useState({ id: "", pwd: "" });

  const user = useSelector((state) => state?.user?.data[0]);

  useEffect(() => {
    const initKakao = async () => {
      if (!Kakao.isInitialized()) {
        // Kakao.init('b9886faecde9faa67a51166600a9d107'); // 개발 환경에 맞는 API 키 사용
        Kakao.init("4970bf08e349ccf4b295296035944317"); // 운영 환경에 맞는 API 키 사용
      }
    };
    initKakao();
  }, []);

  useEffect(() => {
    if (id === "0" && !user) {
      setSign(true);
      return;
    } else {
      setSign(false);
    }
  }, [id, user]);

  const loginWithKakao = () => {
    Kakao.Auth.login({
      success: function (authObj) {
        const token = authObj.access_token;
        axios
          .request({
            method: "POST",
            url: "https://kapi.kakao.com/v2/user/me",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          })
          .then(async (response) => {
            const { data, status } = response;
            if (status === 200) {
              await kakaoSignIn(data);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      },
      fail: function (err) {
        alert("error!!!!" + JSON.stringify(err));
      },
    });
  };

  const kakaoSignIn = async (user) => {
    const url = "/api/kakaoSignIn";
    const req = {
      email: user.kakao_account.email,
      id: user.id,
      name: user.kakao_account.name,
      phoneNumber: user.kakao_account.phone_number,
    };
    const res = await axios.post(url, req);
    if (!res?.data?.userName) {
      return false;
    } else {
      dispatch(handleLogin(res.data));
      if (id === "0") {
        navigate("/group");
      }
    }
  };

  const changeLoginInfo = (e) => {
    const { name, value } = e.target;
    setLoginInfo({ ...loginInfo, [name]: value });
  };

  const signIn = async () => {
    if (!loginInfo.id || !loginInfo.pwd) {
      toast.error("아이디 또는 비밀번호를 입력해주세요");
      return;
    }
    const url = "/api/signIn";
    const req = {
      id: loginInfo.id,
      pwd: loginInfo.pwd,
    };
    const res = await axios.post(url, req);
    if (!res?.data?.userName) {
      toast.error("아이디 또는 비밀번호가 일치하지 않습니다");
      return false;
    } else {
      dispatch(handleLogin(res.data));
      signInGrp(res.data.mberNo);
      getSchedulerSetting(res.data.mberNo);
      if (id === "0") {
        navigate("/group");
      }
    }
  };

  // 캘린더/마이페이지 redux
  const getSchedulerSetting = async (mberNo) => {
    const url = "/api/toktokSettingList";
    const req = {
      mberNo,
    };
    const res = await axios.post(url, req);
    if (res.request.status === 200) {
      dispatch(getSetting(res.data));
    }
  };

  // 로그인 시 모임에 가입된 멤버인지 확인
  const signInGrp = async (mberNo) => {
    const url = "/api/groupMberList";
    const req = {
      mberNo,
      groupNo: id,
      offset: 0,
      delYn: "N",
      pageNumber: 0,
      pageSize: 1,
      paged: false,
    };
    const res = await axios.post(url, req);

    if (res.data.content.length > 0) {
      setIsUserRegister(true);
    }
  };

  return (
    <>
      {id !== "0" ? (
        <div
          className="banner-image"
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#eee",
            height: "300px",
          }}>
          <img
            src={grp?.imgUrl ? imgHandler(grp?.imgUrl) : "/img/function_22_bg.png"}
            alt="배너 이미지"
            style={{
              objectFit: "scale-down",
              width: "100%",
            }}
          />
        </div>
      ) : null}
      <div
        className="inner_content"
        style={{
          marginBottom: "100px",
        }}>
        <div className="inner_title">
          <span className="font_30 font300 font_color_black">
            {id === "0" ? "로그인" : `${grp?.groupName} 에 오신걸 환영합니다!`}
          </span>
        </div>
        {user && !sign ? (
          <GroupJoinInfo
            id={id}
            grp={grp}
            userInfo={userInfo}
            auth={userInfo.auth}
            seq={userInfo.seq}
            setIsUserRegister={setIsUserRegister}
            getGrpMbers={getGrpMbers}
            isApplyUser={isApplyUser}
          />
        ) : (
          <div className="inner_content_wrap">
            <div className="change_pw">
              <input
                type="text"
                placeholder="ID"
                name="id"
                value={loginInfo.id}
                onChange={changeLoginInfo}
                onKeyDown={(e) => handleEnterKey(e, signIn)}
              />
              <input
                type="password"
                placeholder="Password"
                name="pwd"
                value={loginInfo.pwd}
                onChange={changeLoginInfo}
                onKeyDown={(e) => handleEnterKey(e, signIn)}
              />
              <button type="submit" className="pw_confirm pw_100" onClick={signIn}>
                <span>로그인</span>
              </button>
              <button
                className="pw_confirm pw_100"
                style={{
                  backgroundColor: "#FEE500",
                  margin: "10px 0",
                }}
                onClick={loginWithKakao}>
                <span style={{ color: "black" }}>카카오톡 로그인</span>
              </button>
              <Link to="/memberSearch" id="leave" className="member_find">
                회원정보찾기
              </Link>
              <Link to="/type" id="leave" className="member_apply" type="button">
                회원가입
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GroupJoin;
