import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  page : 1,
  selected : 10,
  prductNm : "",
};

const productSync = createSlice({
  name : "productSync",
  initialState,
  reducers : {
    setProductSyncPage(state, action) {
      state.page = action.payload;
    },
    setProductSyncSelected(state, action) {
      state.selected = action.payload;
    },
    setPrductNmSync(state, action) {
      state.prductNm = action.payload;
    }
  }
})

export const { setProductSyncPage, setProductSyncSelected, setPrductNmSync } = productSync.actions;
export default productSync;
