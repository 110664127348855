import React from "react";
import { GroupSideMenu } from "../../styled/group";
import { useNavigate, useParams } from "react-router";
import moment from "moment";

const GroupDetailSideMenu = ({ grp }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <GroupSideMenu>
      <div className="grpImgCover">
        <img src={grp.imgUrl ? imgHandler(grp.imgUrl) : "/img/function_11_bg.png"} alt="" />
      </div>
      <div className="info">
        <div className="grpName">{grp?.groupName}</div>
        <div className="buttons">
          <button onClick={() => navigate(`/group/group-feed-write/${id ? id : "0"}`)}>새글</button>
        </div>
        {grp?.delDt && (
          <div className="grpDelDt">
            <span>해당 모임은</span>
            <span>
              <strong className="time">{moment(grp?.delDt).format("M월 D일 H시")}</strong>에 삭제될
              예정입니다.
            </span>
          </div>
        )}
      </div>
    </GroupSideMenu>
  );
};

export default GroupDetailSideMenu;
