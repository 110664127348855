import { Link } from "react-router-dom";
import ShoppingSectionSwiper from "../../shoppingSectionSwiper";
import ProductListForm from "../../productList/component/productListForm";
import { useState } from "react";
import "./styled/shoppingMain.css";

export default function DefaultTheme() {
  return (
    <main id="contIdx" className="shop_main" style={{ flex: "1" }}>
      <ShoppingSectionSwiper />
      <div className="list_info font_color_gray_light" style={{ justifyContent: "center" }}></div>
      <ProductListForm main={true} />
    </main>
  );
}
