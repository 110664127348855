import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  ProjectCreateSecPreviewContainer,
  ContentNavigation,
  ProjectDetailHeader,
} from "../common/styled/project";

import { RxDotsVertical } from "react-icons/rx";
import { BsPin } from "react-icons/bs";

import moment from "moment";
import { isColor, projectMenus } from "../common/function/project";

const ProjectCreateSecPreview = ({ formData, focusState }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const virtualUsers = ["홍길동", "홍길서", "홍길남", "홍길북"];

  useEffect(() => {
    const htmlString = formData.content;
    const contents = document.getElementById("contents");
    contents.innerHTML = htmlString;
  }, [formData.content]);

  const isFocus = (name) => {
    if (focusState[name]) {
      return "active";
    } else {
      return "";
    }
  };

  return (
    <ProjectCreateSecPreviewContainer style={{ width: 691 }}>
      <ProjectDetailHeader>
        <div className="projectHeaderGroup">
          <div className="gbn" style={{ background: isColor(formData.gbn) }}></div>
          <BsPin />
        </div>
        <button className="moreButton">
          <RxDotsVertical />
        </button>
        <div className={`projectTitle`} style={{ width: " calc(100% - 120px)" }}>
          <h2 className={`projectNm ${isFocus("projectNm")}`}>
            {formData.projectNm ? formData.projectNm : "프로젝트명"}
          </h2>
          <p className={`projectDesc ${isFocus("projectDesc")}`}>{formData.projectDesc}</p>
        </div>
      </ProjectDetailHeader>
      <div className="projectInfoBody">
        <div className="content">
          <ContentNavigation>
            {projectMenus.map((item) => (
              <li>
                <item.icon />
                <span>{item.name}</span>
              </li>
            ))}
          </ContentNavigation>
          <div className="projectFeed">
            <div className="projectFeedHeader">
              <div className="writerInfo">
                <img src="/img/logo_with_text.png" alt="logo" />
                <div>
                  <span>{user.name ? user.name : "김길동"}</span>
                  <p>{moment().format("YYYY-MM-DD hh:mm")}</p>
                </div>
              </div>
              <div className="projectFeedHeaderButton">
                <button>
                  <RxDotsVertical />
                </button>
              </div>
            </div>
            <div className="projectFeedBody">
              <div className={`tit ${isFocus("title")}`}>
                {formData.title ? formData.title : "프로젝트 공지명"}
              </div>
              <div className={`con ck-content ${isFocus("content")}`} id="contents">
                {formData.content
                  ? formData.content
                  : "공지글 내용 예시입니다. 해당 내용은 작성 시 삭제됩니다."}
              </div>
            </div>
          </div>
        </div>
        <div className="users">
          <ul>
            {virtualUsers.map((user) => (
              <li>
                <div className="userProfileImage"></div>
                <div className="userInfo">
                  <span>{user}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </ProjectCreateSecPreviewContainer>
  );
};

export default ProjectCreateSecPreview;
