import React, { useEffect, useState } from "react";
import axios from "axios";
import { GroupDetailSearchBlogContainer } from "../../../styled/group";
import { useParams } from "react-router";
import { BlogItem } from "../groupDetailSearch/groupDetailSearchItem";

const GroupDetailBlog = ({ cmpnys }) => {
  const { id } = useParams();
  const [blogList, setBlogList] = useState([]);

  useEffect(() => {
    getGroupSearchData();
  }, []);

  // 통합검색
  const getGroupSearchData = async () => {
    const url = "/api/combineSearch";
    const body = {
      groupNo: id,
      searchType: "B",
    };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        setBlogList(res.data.blogList);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  // 클릭

  return (
    <GroupDetailSearchBlogContainer>
      {blogList && blogList.length > 0 && (
        <ul>
          {blogList.map((item) => (
            <BlogItem item={item} cmpnys={cmpnys} />
          ))}
        </ul>
      )}
    </GroupDetailSearchBlogContainer>
  );
};

export default GroupDetailBlog;
