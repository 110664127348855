import React, { useState, useCallback } from "react";
import { BsCloudDownload } from "react-icons/bs";

const DragToUpload = ({ onFilesAdded }) => {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragOver(false);
      const files = event.dataTransfer.files;
      onFilesAdded(files);
    },
    [onFilesAdded],
  );

  const handleFileChange = useCallback(
    (event) => {
      const files = event.target.files;
      onFilesAdded(files);
    },
    [onFilesAdded],
  );

  return (
    <div className={`dragToUpload ${dragOver ? "drag-over" : ""}`}>
      <input
        type="file"
        id="fileUpload"
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/*"
        multiple
      />
      <label
        htmlFor="fileUpload"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}>
        <BsCloudDownload />
        <span>이미지를 여기로 드래그하거나 클릭해서 추가해주세요.</span>
      </label>
    </div>
  );
};

export default DragToUpload;
