import styled from "styled-components";

export const MemberSearchContainer = styled.div`
  .inner_title.m_join_in.m_search {
    display: none;
  }
  .writing_list {
    border: none;
    padding: 0;
  }
  .countdown {
    font-size: 12px;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    color: #dc0000;
  }
  @media screen and (max-width: 768px) {
    .tab_item {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
