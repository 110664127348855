import React from "react";
import NumberKeyboard from "../common/numberKeyboard";

const ReserveInput = ({ transformValue, formData, handleChangeInput, determineStepAction }) => {
  return (
    <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="inputBox">
        <div className="inputFields">
          <input type="text" value={formData.id} readOnly />
        </div>
        {/* <input /> */}
        <div className="keyboardFields">
          <NumberKeyboard
            type="id"
            handleChangeInput={handleChangeInput}
            inputValue={formData.id}
          />
        </div>
        <div className="inputActions">
          <button onClick={() => determineStepAction(true)}>확인</button>
        </div>
      </div>
    </div>
  );
};

export default ReserveInput;
