import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MypageBanner from "../mypageBanner";
import ProductAddForm from "./component/product/productAddForm";
import ProductBrandForm from "./component/product/productBrandForm";
import ProductForm from "./component/product/productForm";
import ProductMenu from "./component/product/productMenu";
import ProductSyncForm from "./component/product/productSyncForm";
import HomePageSettingMenu from "./homepageSettingMenu";
import { setProductIndex } from "../../../store/compoIndex";
import axios from "axios";

export default function Product() {
  useEffect(() => {
    dispatch(setProductIndex(0));
    getProduct();
    getCategory();
  }, []);

  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesObj, setCategoriesObj] = useState({});
  const [synced, setSynced] = useState({});
  const [filtered, setFiltered] = useState({
    minBasePrice: 0,
    maxBasePrice: 0,
    brand: "",
    name: "",
    categoryId: null,
  });

  const user = useSelector((state) => state?.user?.data[0]);
  const index = useSelector((state) => state.compoIndex.product);

  const getCategory = async () => {
    const url = "/api/categories";
    const res = await axios.get(url);

    if (res.status === 200) {
      const data = res.data || [];
      const sortedCategories = data.sort((a, b) => {
        return a.path.localeCompare(b.path, undefined, { numeric: true });
      });

      setCategories(sortedCategories);
      setCategoriesObj(
        data.reduce((acc, ele) => {
          acc[ele.categoryId] = ele.categoryName;
          return acc;
        }, {}),
      );
    }
  };

  const getProduct = async () => {
    try {
      const url = "/erp/vendorProducts/get";
      const body = { cmpnyNo: user.cmpnyNo };

      Object.entries(filtered).forEach(([key, value]) => {
        if (!!value) {
          if (key === "minBasePrice" || key === "maxBasePrice") {
            body[key] = parseFloat(value.toString().replace(/,/g, ""));
          } else {
            body[key] = value;
          }
        }
      });

      const res = await axios.post(url, body);

      if (res.status === 200) {
        setProducts(res.data.products);
        setSynced(
          res.data.products.reduce((acc, ele) => {
            acc[ele.id] = true;
            return acc;
          }, {}),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <MypageBanner />
      <div className="inner_content">
        <HomePageSettingMenu />
        <ProductMenu index={index} />
        <Components
          index={index}
          products={products}
          setProducts={setProducts}
          categories={categories}
          categoriesObj={categoriesObj}
          synced={synced}
          getProduct={getProduct}
          filtered={filtered}
          setFiltered={setFiltered}
        />
      </div>
    </>
  );
}

function Components({
  index,
  products,
  setProducts,
  categories,
  categoriesObj,
  synced,
  getProduct,
  filtered,
  setFiltered,
}) {
  return (
    <>
      {
        [
          <ProductForm
            getProduct={getProduct}
            categories={categories}
            categoriesObj={categoriesObj}
            products={products}
            filtered={filtered}
            setFiltered={setFiltered}
          />,
          <ProductSyncForm categories={categories} categoriesObj={categoriesObj} synced={synced} />,
          <ProductAddForm categoriesObj={categoriesObj} getProduct={getProduct} />,
          <ProductBrandForm />,
        ][index]
      }
    </>
  );
}
