import React from "react";

import { InfoContainer } from "./styled/info";

import Visual from "./common/visual";
import Hero from "./common/hero";
import Mockup from "./common/mockup";
import Highlight from "./common/highlight";
import SecondaryMockup from "./common/secondaryMockup";

const InfoScheduler = () => {
  const components = [
    {
      component: SecondaryMockup,
      content: {
        heading: "통합 일정 관리",
        description:
          "개인 일정과 팀 일정을 한 곳에서 관리할 수 있는 통합된 플랫폼 제공\n자신이 속한 여러 팀의 일정이나 \n개인 업무 일정을 동시에 확인할 수 있습니다.\n통합된 일정을 통해 시간 충돌을 방지하고,\n개인과 팀 모두의 생산성을 높일 수 있습니다.",
        feature: "원활한 시간 관리",
      },
    },
    {
      component: Hero,
      content: {
        heading: "알림 및 리마인더 관리",
        description:
          "캘린더에 설정된 중요한 일정이나 마감일이 다가오면\n모노티앱이 자동으로 알림을 제공합니다.\n알림 기능 덕분에 팀원들은 중요한 일을 놓치지 않고 제때 준비할 수 있습니다.\n사용자들은 알림 시간을\n자유롭게 설정할 수 있습니다.",
        feature: "각자의 업무 스타일에 맞춘 세밀한 관리",
      },
    },
    {
      component: Mockup,
      content: {
        heading: "실시간 업데이트 및 자동 동기화",
        description:
          "캘린더는 실시간으로 업데이트되며,\n팀 내에서 공유된 일정이 자동으로 동기화됩니다.\n누군가가 새로운 회의 일정을 잡거나 마감일을 변경하면,\n모든 관련 팀원들에게 즉시 반영되어 최신 일정을 파악할 수 있습니다.\n이는 소통의 혼선을 방지하고, 팀원들이 항상 최신 정보에 맞춰 행동할 수 있습니다.",
        feature: "시간 낭비 없는 효과적인 협업",
      },
    },
    {
      component: Highlight,
      content: {
        description:
          "일정별로 카테고리를 나누고 색상 코드를 설정할 수 있어,\n중요한 일정이나 마감일을 한눈에 쉽게 구분할 수 있습니다.\n시각적 구분이 용이하기 때문에 일정 관리의 효율성을 극대화할 수 있습니다.",
        feature: "일정 카테고리 및 색상 코드화",
      },
    },
  ];
  return (
    <InfoContainer className="scheduler">
      <Visual />
      {components.map(({ component: Component, content }, index) => (
        <Component key={index} content={content} type="scheduler" />
      ))}
    </InfoContainer>
  );
};

export default InfoScheduler;
