import React, { useEffect } from "react";
import FoodItem from "./foodItem";
import { OrderMenuContainer } from "../styled/pickup";
import { useMediaQuery } from "react-responsive";

const OrderMenu = ({ selected, products = [] }) => {
  return (
    <OrderMenuContainer className={selected?.status}>
      <div className="info">
        <span className="foodItem label">메뉴</span>
        <span className="foodQuantity">수량</span>
        <span className="foodPrice">금액</span>
      </div>
      <ul>
        {products.map((item) => (
          <FoodItem title={item.name} quantity={item.quantity} price={item.price} options={[]} />
        ))}
        {/* <FoodItem title="맛있는 떡볶이 세트1번" quantity={1} price={14000} />
        <FoodItem
          title="맛있는 순대볶음"
          quantity={1}
          price={14000}
          options={["맵기 1단계", "순대 내장만", "튀김 골고루"]}
        /> */}
      </ul>
    </OrderMenuContainer>
  );
};

export default OrderMenu;
