import { useState } from "react";
import "./board.css";

const BoardWrite = ({selected, notice, selectList, titleRef, contentRef, setSelected}) => {
  const [isMobile] = useState(window.innerWidth <= 768);

  const handleSelect = (e) => {
    setSelected(e.target.value);
  }

  return (
    <div className="board_view">
      <form id="borad_new_data">
        <ul className="writing_list" id="board_table">
          <li className="board_text_wrapper">
            <div className="writing_tit required">
              <strong className="tit">카테고리<em className="point">필수</em></strong>
            </div>
            <div className="writing_cont">
              <div className="inputxt_normal">
                <select 
                  name="category_type" 
                  id="category_type" 
                  onChange={handleSelect} 
                  value={selected}
                  style={{width : isMobile ? "" : "20%"}}
                >
                  {selectList.map((item, index) =>
                    <option value={item} key={index}>{item}</option>
                  )}
                </select>
              </div>
            </div>
          </li>
          <li className="board_text_wrapper">
            <div className="writing_tit required">
              <strong className="tit">제목<em className="point">필수</em></strong>
            </div>
            <div className="writing_cont">
              <div className="inputxt_normal">
                <input 
                  type="text" 
                  placeholder="제목을 입력하세요." 
                  defaultValue={notice?.noticeSj || ""} 
                  ref={titleRef} 
                  onChange={(e) => titleRef.current.value = e.target.value}
                />
              </div>
            </div>
          </li>
          <li className="board_text_wrapper">
            <div className="writing_tit required">
              <strong className="tit">내용<em className="point">필수</em></strong>
            </div>
            <div className="writing_cont">
              <div className="inputxt_normal">
                <textarea 
                  defaultValue={notice?.noticeCn} 
                  ref={contentRef} 
                  onChange={(e) => contentRef.current.value = e.target.value} 
                  id="que_contents" 
                  name="que_contents" 
                  className="board_textarea"
                />
              </div>
            </div>
          </li>
          {/* <li>
            <div className="writing_tit">
              <strong className="tit">첨부파일</strong>
            </div>
            <div className="writing_cont">
              <div className="file_import">
                <input type="text" id="file_import1" readOnly="readonly" defaultValue />
                <label>
                  파일찾기
                  <input type="file" id="wk_file" name="wk_file"  /> 
                </label>
              </div>
            </div>
          </li> */}
        </ul>
      </form>
    </div>
  );
};

export default BoardWrite;