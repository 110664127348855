import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  setting: {
    shortcuts: [],
    widgets: Array.from({ length: 8 }, (_, i) => ({ id: i.toString(), isActive: true })),
    mode: "white",
  },
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    handleLogin(state, action) {
      if (state.data.length > 0) state.data = [];
      state.data.push(action.payload);
    },
    onClickLogout(state) {
      state.data.pop();
      sessionStorage.clear();
      localStorage.removeItem("user");
      state.setting = {};
    },
    handleChangeUser(state, action) {
      const item = state.data[0];
      state.data = [{ ...item, ...action.payload }];
    },
    handleUpdateSetting(state, action) {
      state.setting = action.payload;
    },
  },
});

export const { handleLogin, onClickLogout, handleChangeUser, handleUpdateSetting } = user.actions;
export default user;
