import NoticeForm from "./component/noticeForm";
import NoticeBanner from "./noticeBanner";

export default function Notice() {

  return(
    <div>
      <NoticeBanner />
      <NoticeForm />
    </div>
  )
}