import { Link } from "react-router-dom";

const MemberBanner = () => {

  return (
    <div className="sub_top" id="member">
      <span className="font_22 font300 pdb_20 font_color_white">{service.useCase}</span>
      <span className="font_72 font700 font_color_1 pdb_70">{service.useCaseE}</span>
      <ul className="sub_menu">
        {memberMenu.map((item, i) =>
            <li key={i}><Link to={item.link}>{item.label}</Link></li>
        )}
      </ul>
    </div>
  );
};

export default MemberBanner;