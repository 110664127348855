import React from "react";

const SettingForm = ({
  options,
  settings,
  toggleAllSelected,
  handleChange,
  handleSubmit,
  transformValue,
}) => {
  return (
    <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
      <ul>
        <li>
          <span className="tit">예약여부</span>
          <div className="radioContent">
            <label>
              <input
                type="radio"
                onChange={() => toggleAllSelected(true)}
                checked={options.some((option) => settings[option.field] === true)}
              />
              <span className="checkmark"></span>
              <span>가능</span>
            </label>
            <label>
              <input
                type="radio"
                onChange={() => toggleAllSelected(false)}
                checked={options.every((option) => settings[option.field] === false)}
              />
              <span className="checkmark"></span>
              <span>불가능</span>
            </label>
          </div>
        </li>
        {options.map((option) => (
          <li key={option.field}>
            <span className="tit">{option.label}</span>
            <div className="radioContent">
              <label>
                <input
                  type="radio"
                  name={option.field}
                  value="true"
                  checked={settings[option.field] === true}
                  onChange={handleChange}
                />
                <span className="checkmark"></span>
                <span>예약가능</span>
              </label>
              <label>
                <input
                  type="radio"
                  name={option.field}
                  value="false"
                  checked={settings[option.field] === false}
                  onChange={handleChange}
                />
                <span className="checkmark"></span>
                <span>예약중지</span>
              </label>
            </div>
          </li>
        ))}
        <li className="notice">
          <span className="tit">공지사항</span>
          <input
            type="text"
            maxLength={20}
            name="notice"
            value={settings.notice}
            placeholder="공지사항을 입력해주세요. (최대 20자)"
            onChange={handleChange}
          />
        </li>
      </ul>
      <button className="submit" onClick={handleSubmit}>
        저장
      </button>
    </div>
  );
};

export default SettingForm;
