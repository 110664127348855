import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setProductIndex } from "../../../../../store/compoIndex";

const ProductMenu = ({ index }) => {
  const dispatch = useDispatch();

  return (
    <ul className="hp_step3_list">
      {productMenu.map((item, i) => (
        <li
          key={i}
          onClick={() => dispatch(setProductIndex(i))}
          className={index === i ? "active marginR" : "marginR"}>
          <Link className="font_16 font400">{item.label}</Link>
        </li>
      ))}
    </ul>
  );
};

export default ProductMenu;
