import React from "react";
import { useSelector } from "react-redux";

const ModalContent = ({ handleConfirm, handleCancel }) => {
  return (
    <div>
      <h2 className="font_20 font500 font_color_white">회원가입</h2>
      <button onClick={handleCancel} className="hp_step_setting_wrap_close">
        <i className="fa fa-times" aria-hidden="true" />
      </button>
      <span className="font_20 font300 font_color_gray pdt_20 text_center">가입하시겠습니까?</span>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
        <button
          onClick={handleCancel}
          className="hp_step_setting_cate_save"
          style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
          취소
        </button>
        <button
          onClick={handleConfirm}
          className="hp_step_setting_cate_save"
          style={{ padding: "10px 20px", margin: "0px" }}>
          확인
        </button>
      </div>
    </div>
  );
};

export default ModalContent;
