import React from "react";
import { useSelector } from "react-redux";

const GroupDetailFeedAdvertisement = () => {
  const user = useSelector((state) => state?.user?.data[0]);
  const openAdvertisementPage = async () => {
    user && localStorage.setItem("user", JSON.stringify(user));
    window.open(`https://1472.ai/shop/lotteria-daejeon`);
  };

  return (
    <li className="advertisement">
      <div className="feedHeader">
        <div className="writerInfo">
          <img src={"/img/logo_with_text.png"} alt="logo" />
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <span className="nickNm">
              14기 조원선 <small>[AD]</small>
            </span>
          </div>
        </div>
      </div>
      <div className="feedBody">
        <div className="images" onClick={openAdvertisementPage}>
          <img src="/img/temp.png" alt="" />
        </div>
        <div className="content"></div>
      </div>
      <div className="feedFooter">
        <div className="commentList"></div>
      </div>
    </li>
  );
};

export default GroupDetailFeedAdvertisement;
