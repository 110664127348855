const MONEY_REGEX = /\B(?=(\d{3})+(?!\d))/g;
const BUSSINESS_NUMBER_REGEX = /(\d{3})(\d{2})(\d{5})/;
const MOBILEPHONE_NUMBER_REGEX = /(\d{3})(\d{4})(\d{4})/;
const TEL_NUMBER_REGEX = /(\d{3})(\d{3})(\d{4})/;
const TIME_ADD_ZERO_REGEX = /^(\d{1,2})$/;

export const useRegex = () => {
  const MoneyFormat = (value) => {
    return value?.toString().replace(MONEY_REGEX, ',')
  };

  const BussinessFormat = (value) => {
    return value?.toString().replace(BUSSINESS_NUMBER_REGEX, '$1-$2-$3')
  };

  const TelFormat = (value) => {
    const removeDash = value?.toString().replace(/-/g,'');
    switch (true) {
      case MOBILEPHONE_NUMBER_REGEX.test(removeDash):
        return removeDash?.toString().replace(MOBILEPHONE_NUMBER_REGEX, '$1-$2-$3');
      case TEL_NUMBER_REGEX.test(removeDash):
        return removeDash?.toString().replace(TEL_NUMBER_REGEX, '$1-$2-$3');
      default:
        return value;
    }
  };

  const TimeAddZero = (value) => {
    if(value < 10){
      return value?.replace(TIME_ADD_ZERO_REGEX,'0$1');
    }
    return value;
  };

  return { MoneyFormat, BussinessFormat, TelFormat, TimeAddZero };
};