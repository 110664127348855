import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { FiMoreVertical } from "react-icons/fi";
import { isSameUser } from "../../../../../scheduler/component/function/common";
import { ProjectCommentItem } from "../../../common/styled/project";
import ProjectDetailCommentFiles from "./projectDetailCommentFiles";

const ProjectDetailCommentItem = ({
  item,
  project,
  handleImageClick,
  handleContextMenu,
  handleDeleteFile,
  handleFindReply,
}) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const isMyCmpnyProject = (orgNo) => {
    if (project.gbn === "G" || parseInt(project.orgNo, 10) === parseInt(orgNo, 10)) {
      return true;
    }
    return false;
  };

  return (
    <ProjectCommentItem
      key={item.projectCommentNo}
      className={`${`comment-${item.projectCommentNo}`} ${
        isSameUser(user, item) ? "mine" : "theirs"
      }`}>
      <div className="commentContainer" onContextMenu={(e) => handleContextMenu(e, item)}>
        <div className="profileImage noDrag">
          <img
            src={item.imgPath ? imgHandler(item.imgPath) : "/img/icon_application_1.png"}
            alt="userProfileImage"
          />
        </div>
        <div className="commentInfo">
          <div className="topBar">
            <div className="writer">
              {!isMyCmpnyProject(item.orgNo) && (
                <span style={{ marginRight: 5 }}>{item.orgNm}</span>
              )}
              <span className="name">{item.mberNm}</span>
            </div>
          </div>
          <div className="date">
            <span>{moment(item.createDt).format("YYYY-MM-DD HH:mm")}</span>
          </div>
          {(item.replyNo || item.content) && (
            <div className="commentContent">
              {item.replyNo > 1 && item.delYn === "N" && (
                <div
                  className="origin"
                  onClick={() => handleFindReply(item)}
                  style={{ cursor: "pointer" }}>
                  <div>
                    <span>{item.replyNm}</span>에게 답장
                  </div>
                  <div className="reply">{item.replyContent ? item.replyContent : "사진/파일"}</div>
                </div>
              )}
              {item.content && (
                <div className="comment">
                  <div>{item.content}</div>
                </div>
              )}
            </div>
          )}
          <ProjectDetailCommentFiles
            item={item}
            images={item.imgList}
            files={item.fileList}
            handleDeleteFile={handleDeleteFile}
            type={"saved"}
            handleImageClick={handleImageClick}
          />
        </div>
      </div>
    </ProjectCommentItem>
  );
};

export default ProjectDetailCommentItem;
