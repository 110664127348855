import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FilePreview from "../../common/filePreview";

const ThumbnailUpload = ({
  product,
  handleAddImage,
  handleDeleteImage,
  onDragEnd,
  setIsDetail,
}) => {
  return (
    <li>
      <div className="writing_tit">
        <strong className="tit">썸네일 이미지 첨부</strong>
      </div>
      <div className="writing_cont thumbnail">
        <div className="file_import">
          <label>
            <i className="fa fa-plus" aria-hidden="true" />
            <input
              type="file"
              id="wk_file"
              name="wk_file"
              accept="image/*, video/*"
              multiple
              onClick={(e) => {
                if (product.media.length > 9) {
                  e.preventDefault();
                  toast.error("이미지는 최대 10장까지 첨부 가능합니다.");
                }
              }}
              onChange={handleAddImage}
            />
          </label>
          {product.media.length > 0 && (
            <div className="preview">
              <ul
                className="thumbnailImages"
                style={{
                  display: "flex",
                  backgroundColor: "#fff",
                }}>
                {product.media.map((item, index) => (
                  <li
                  // onClick={() => setIsDetail(true)}
                  >
                    <FilePreview item={item} />
                    <button onClick={() => handleDeleteImage(item)}>
                      <IoClose />
                    </button>
                  </li>
                ))}
              </ul>
              {/* <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="thumbnailImages" direction="horizontal">
                  {(provided, snapshot) => (
                    <ul
                      className="thumbnailImages"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        display: "flex",
                        backgroundColor: snapshot.isDraggingOver ? "#efefef" : "#fff",
                      }}>
                      {product.media.map((item, index) => (
                        <Draggable draggableId={item.id} index={index}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              onClick={() => setIsDetail(true)}>
                              <FilePreview item={item} />
                              <button onClick={() => handleDeleteImage(item)}>
                                <IoClose />
                              </button>
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext> */}
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default ThumbnailUpload;
