import React from "react";

import { GroupDetailSearchProjectContainer } from "../../../styled/group";
import { NotDataList } from "../../../styled/common";

import { useRegex } from "../../../../../../hooks/useRegex";
import { ProjectItem } from "../../../../../mypage/integratedSearch/component/searchItems";

const GroupDetailSearchProject = ({ data, isSearch }) => {
  const { MoneyFormat } = useRegex();

  return (
    <GroupDetailSearchProjectContainer>
      {isSearch && (
        <>
          {data.length > 0 ? (
            <>
              <div className="label">
                {data.length > 0 && (
                  <span>
                    검색 결과
                    <strong>{data.length.toLocaleString()}</strong>개
                  </span>
                )}
              </div>
              {data.length > 0 && data && (
                <ul>
                  {data.map((item) => (
                    <ProjectItem item={item} />
                  ))}
                </ul>
              )}
            </>
          ) : (
            <NotDataList>검색 결과가 존재하지 않습니다.</NotDataList>
          )}
        </>
      )}
    </GroupDetailSearchProjectContainer>
  );
};

export default GroupDetailSearchProject;
