import { useOutletContext, useParams } from "react-router";
import { useEffect, useState } from "react";

import ProductsViewForm from "./component/productsViewForm";
import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";

import { CuteProductsView } from "../shopping/component/styled/cuteTheme";
import { ModernProductsView } from "../shopping/component/styled/modernTheme";
import { SimpleProductsView } from "../shopping/component/styled/simpleTheme";
import { ChicProductsView } from "../shopping/component/styled/chicTheme";

const ProductsView = () => {
  const { cmpnyNo, theme } = useOutletContext();
  const { prdNo } = useParams();

  const [prduct, setPrduct] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getCmpnyPrductList();
    }
  }, [cmpnyNo]);

  const getCmpnyPrductList = () => {
    const url = "/api/prductStock";

    const req = {
      cmpnyNo: cmpnyNo,
      prdNo,
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 1,
      paged: true,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    };

    fetch(url, options)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        setPrduct(data.content);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderComponent = () => {
    switch (theme) {
      case "A":
        return <ProductsViewForm prduct={prduct} />;
      case "B":
        return (
          <ChicProductsView>
            <ProductsViewForm prduct={prduct} />
          </ChicProductsView>
        );
      case "C":
        return (
          <ModernProductsView>
            <ModernBanner />
            <ProductsViewForm prduct={prduct} />
          </ModernProductsView>
        );
      case "D":
        return (
          <CuteProductsView>
            <CuteBanner />
            <ProductsViewForm prduct={prduct} />
          </CuteProductsView>
        );
      case "E":
        return (
          <SimpleProductsView>
            <SimpleBanner />
            <ProductsViewForm prduct={prduct} />
          </SimpleProductsView>
        );
      default:
        return <ProductsViewForm prduct={prduct} />;
    }
  };

  return <>{renderComponent()}</>;
};

export default ProductsView;
