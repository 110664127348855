import React, { useRef, useState } from "react";

import ShopLocatorInfo from "./shopLocatorInfo";
import ShopLocatorSwiper from "./shopLocatorSwiper";
import { useMediaQuery } from "react-responsive";

const RestaurantLocator = () => {
  const swiperRef1 = useRef(null);
  const swiperRef2 = useRef(null);
  const [isAutoplay, setIsAutoplay] = useState(true);

  const images = [
    ...Array.from({ length: 6 }, (_, idx) => idx),
    ...Array.from({ length: 6 }, (_, idx) => idx),
    ...Array.from({ length: 6 }, (_, idx) => idx),
  ];
  const locator = { title: "찐맛집", info: "인기가 많은 식당을 알려드릴게요", type: "restaurant" };

  const toggleAutoplay = () => {
    [swiperRef1, swiperRef2].forEach((ref) => {
      if (ref.current && ref.current.swiper) {
        if (isAutoplay) {
          ref.current.swiper.setTransition(0);
          ref.current.swiper.autoplay.stop();
        } else {
          ref.current.swiper.setTransition(300);
          ref.current.swiper.autoplay.start();
        }
      }
    });
    setIsAutoplay(!isAutoplay);
  };

  return (
    <div className="restaurantInner">
      <ShopLocatorInfo locator={locator} slideEvent={toggleAutoplay} isAutoplay={isAutoplay} />
      <ShopLocatorSwiper images={images} type={locator.type} swiperRef={swiperRef1} speed={4000} />
      <ShopLocatorSwiper images={images} type={locator.type} swiperRef={swiperRef2} />
    </div>
  );
};

export default RestaurantLocator;
