export const customTooltip = (context) => {
  let tooltipEl = document.getElementById("chartjs-tooltip");

  // 생성
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = "absolute";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.zIndex = 5;
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "opacity 0.3s ease";
    tooltipEl.style.backgroundImage = "url('/img/speech_bubble.png')";
    tooltipEl.style.backgroundSize = "contain";
    tooltipEl.style.backgroundRepeat = "no-repeat";
    tooltipEl.style.color = "white";
    tooltipEl.style.fontSize = "14px";
    tooltipEl.style.fontFamily = "Lato";
    tooltipEl.style.width = "72px";
    tooltipEl.style.height = "44px";
    tooltipEl.style.lineHeight = "39px";
    tooltipEl.style.textAlign = "center";

    // 컨테이너 추가
    const tooltipContent = document.createElement("div");
    tooltipContent.id = "chartjs-tooltip-content";
    tooltipEl.appendChild(tooltipContent);

    document.body.appendChild(tooltipEl);
  }

  // 모델 가져오기
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // 내용 설정
  if (tooltipModel.body) {
    const bodyLines = tooltipModel.body.map((b) => b.lines);
    const tooltipContent = document.getElementById("chartjs-tooltip-content");
    if (tooltipContent) {
      tooltipContent.innerHTML = `
        <div>
          ${bodyLines.join("<br>")}
        </div>
      `;
    }
  }

  // 위치 설정
  const position = context.chart.canvas.getBoundingClientRect();
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
  tooltipEl.style.top =
    position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.clientHeight - 10 + "px";
};

export const drawHoverCircle = (chart, hoverCtx, activeElements, setHoverInfo) => {
  hoverCtx.clearRect(0, 0, hoverCtx.canvas.width, hoverCtx.canvas.height);

  if (activeElements.length > 0) {
    const { datasetIndex, index } = activeElements[0];
    const meta = chart.getDatasetMeta(datasetIndex);
    const dataPoint = meta.data[index];
    const { x, y } = dataPoint.tooltipPosition();
    const label = chart.data.labels[index];
    const value = chart.data.datasets[datasetIndex].data[index];

    hoverCtx.save();
    hoverCtx.beginPath();
    hoverCtx.arc(x, y, 15, 0, 2 * Math.PI);
    hoverCtx.fillStyle = "rgba(105,118,235,0.3)";
    hoverCtx.fill();
    hoverCtx.beginPath();
    hoverCtx.arc(x, y, 5, 0, 2 * Math.PI);
    hoverCtx.fillStyle = "#6976EB";
    hoverCtx.fill();
    hoverCtx.restore();

    // 호버 정보 업데이트
    setHoverInfo({ label, value });
  } else {
    setHoverInfo(null);
  }
};
