import React from "react";
import { CustomerInfoContainer } from "../styled/pickup";
import moment from "moment";

const CustomerInfo = ({ selected }) => {
  return (
    <CustomerInfoContainer className={selected?.status}>
      {selected?.status === "CANCELLED" ? (
        <span>취소사유: {selected?.cancelReason}</span>
      ) : (
        <span>주문시간: {moment(selected?.createdAt).format("a h시m분")}</span>
      )}
    </CustomerInfoContainer>
  );
};

export default CustomerInfo;
