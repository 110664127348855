import MypageBanner from "../mypageBanner";
import MenuSettingForm from "./component/menuSetting/menuSettingForm";
import HomePageSettingMenu from "./homepageSettingMenu";

export default function MenuSetting() {
  return (
    <div>
      <MypageBanner />
      <div className="inner_content">
        <HomePageSettingMenu />
        <MenuSettingForm />
      </div>
    </div>
  );
}
