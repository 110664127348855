import { useOutletContext } from "react-router";

import Portfolio from "./portfolio";
import CuteBanner from "../../shopping/component/themes/cute/banner";
import ModernBanner from "../../shopping/component/themes/modern/banner";
import SimpleBanner from "../../shopping/component/themes/simple/banner";

import { CuteNoticeViewContainer } from "../../shopping/component/styled/cuteTheme";
import { ModernNoticeViewContainer } from "../../shopping/component/styled/modernTheme";
import { ChicPortfolioViewContainer } from "../../shopping/component/styled/chicTheme";

const PortfolioViewPage = () => {
  const { theme } = useOutletContext();

  const renderComponent = () => {
    switch (theme) {
      case "A":
        return <Portfolio />;
      case "B":
        return (
          <ChicPortfolioViewContainer>
            <Portfolio />
          </ChicPortfolioViewContainer>
        );
      case "C":
        return (
          <ModernNoticeViewContainer>
            <ModernBanner />
            <Portfolio />
          </ModernNoticeViewContainer>
        );
      case "D":
        return (
          <CuteNoticeViewContainer>
            <CuteBanner />
            <Portfolio />
          </CuteNoticeViewContainer>
        );
      case "E":
        return (
          <CuteNoticeViewContainer>
            <SimpleBanner />
            <Portfolio />
          </CuteNoticeViewContainer>
        );
      default:
        return <Portfolio />;
    }
  };

  return <>{renderComponent()}</>;
};

export default PortfolioViewPage;
