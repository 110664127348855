import React from "react";
import { CommentButtonsContainer } from "../../../common/styled/project";
import { useSelector } from "react-redux";

const ProjectDetailCommentMenu = ({
  handleModalOpen,
  menuPosition,
  selectedComment,
  setIsReply,
  resetSelectComment,
  setIsMenuOpen,
}) => {
  const user = useSelector((state) => state?.user?.data[0]);

  const handleClickButton = (e, func) => {
    e.stopPropagation();
    func();
    setIsMenuOpen(false);
  };

  return (
    <CommentButtonsContainer onContextMenu={resetSelectComment} onClick={resetSelectComment}>
      <ul style={{ top: menuPosition.top, left: menuPosition.left }}>
        <li>
          <button onClick={(e) => handleClickButton(e, () => setIsReply(selectedComment))}>
            답장
          </button>
        </li>
        {selectedComment && selectedComment.mberNo === user.mberNo && (
          <li>
            <button
              onClick={(e) =>
                handleClickButton(e, () => handleModalOpen("comment", selectedComment))
              }>
              삭제
            </button>
          </li>
        )}
      </ul>
    </CommentButtonsContainer>
  );
};

export default ProjectDetailCommentMenu;
