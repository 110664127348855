import React, { useEffect, useRef } from "react";
import { ViewDetailsContainer } from "../styled/storage";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { formatStorageTimes, isStorageType } from "../function/kiosk";

const ViewDetails = ({ data, handleClickStorage, active }) => {
  const detailsRef = useRef();
  const isInner = useMediaQuery({ query: "(max-width:1400px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const COLUMNS = isMobile ? 3 : isInner ? 5 : 10;

  const chunkData = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const chunkedData = chunkData(data, COLUMNS);

  useEffect(() => {
    if (detailsRef.current) {
      detailsRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [active]);

  return (
    <ViewDetailsContainer className="content">
      <div className="details" ref={detailsRef}>
        <table className="hover">
          <tbody>
            {chunkedData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((item, itemIndex) =>
                  item.id ? (
                    <td
                      key={itemIndex}
                      onClick={() => handleClickStorage("userInfo", item)}
                      className={isStorageType(active, "key")}>
                      <div className="box">
                        <span>{item.index}</span>
                        <span>{item.mberNm}</span>
                        <span>보관날짜: {moment(item.reservationDay).format("M월 D일")}</span>
                        <span>보관종료시간: {formatStorageTimes(item).end}</span>
                        <span>예약번호: {item.reserveId}</span>
                      </div>
                    </td>
                  ) : (
                    <td key={itemIndex} className={`empty ${item.status}`}>
                      <div className="box">
                        <span>{item.index}</span>
                      </div>
                    </td>
                  ),
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ViewDetailsContainer>
  );
};

export default ViewDetails;
