import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";
import { HomePageSettingList } from "./component/styled/homePageSetting";
import { useDispatch } from "react-redux";
import { setRegisterIndex } from "../../../store/compoIndex";

const HomePageSettingMenu = () => {
  const { pathname } = useLocation();
  const navigater = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width:1050px)" });
  const dispatch = useDispatch();

  const onGo = (link) => {
    navigater(link);
    dispatch(setRegisterIndex(0));
  };

  return (
    <div className="inner_title">
      <ul className="inner_title_menu" style={{ borderCollapse: "collapse" }}>
        {settingMenu.map((item, i) => (
          <HomePageSettingList
            onClick={() => onGo(item.link)}
            key={i}
            className={!pathname.indexOf(item.link) ? "actived" : ""}>
            <span>{item.label}</span>
          </HomePageSettingList>
        ))}
        {isMobile && (
          <li id="blank_space" style={{ backgroundColor: "white", border: "white" }}>
            <a href="">{"\u00a0"}</a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default HomePageSettingMenu;
