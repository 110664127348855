import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLongPress } from "use-long-press";

import { AiOutlineDelete } from "react-icons/ai";

import ImageDetailViewer from "../../../../../../../common/imageDetailViewer";

const DropImageCollection = ({
  images,
  deleteImages,
  showMenu,
  setShowMenu,
  selectedImage,
  setSelectedImage,
  isRelatedPerson,
}) => {
  const [isDetail, setIsDetail] = useState(false);
  const [imageOrder, setImageOrder] = useState([]);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [showDeleteButton, setShowDeleteButton] = useState(Array(images.length).fill(false));

  useEffect(() => {
    setImageOrder(images);
    setShowDeleteButton(Array(images.length).fill(false));
  }, [images]);

  const handleImageClick = (index) => {
    setIsDetail(true);
    const reorderedImages = [...images.slice(index, images.length), ...images.slice(0, index)];
    setImageOrder(reorderedImages);
  };

  // 마우스 우클릭
  const handleContextMenu = (e, img) => {
    e.preventDefault();
    setShowMenu(true);
    setMenuPosition({ x: e.clientX, y: e.clientY });
    setSelectedImage(img);
  };

  // 이미지 다운
  const downloadImage = async (image) => {
    // URL
    if (!image.startsWith("data:image")) {
      try {
        const url = imgHandler(image);
        const res = await axios.post("/api/getFile", { url }, { responseType: "arraybuffer" });
        const blob = new Blob([res.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = image.split("/").pop();
        link.click();
        toast.success("이미지가 저장되었습니다.");
      } catch (error) {
        console.error("이미지 다운로드 실패:", error);
      }
    } else {
      // Base64
      const a = document.createElement("a");
      a.href = image;
      a.download = "image.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      toast.success("이미지가 저장되었습니다.");
    }
    setShowMenu(false);
    setSelectedImage(null);
  };

  const DropdownMenu = ({ x, y, deleteImages }) => (
    <div
      className="imageDelBox"
      onClick={(e) => {
        e.target === e.currentTarget && setShowMenu(false);
      }}>
      <div className="dropdownMenu" style={{ top: y, left: x }}>
        <button onClick={() => downloadImage(selectedImage.path)} style={{ color: "#000" }}>
          이미지 저장
        </button>
        {isRelatedPerson && (
          <button onClick={() => deleteImages(selectedImage)} style={{ color: "#000" }}>
            삭제
          </button>
        )}
      </div>
    </div>
  );

  const setShowDelBtn = (active) => {
    setShowDeleteButton(showDeleteButton.map((item, index) => index === active));
  };

  const ImageItem = ({ img, index }) => {
    const bind = useLongPress(
      () => {
        setShowDelBtn(index);
      },
      {
        threshold: 500,
        captureEvent: true,
        cancelOnMovement: true,
      },
    );

    return (
      <img
        {...bind()}
        src={imgHandler(img.path, false)}
        onClick={() => handleImageClick(index)}
        onContextMenu={(e) => handleContextMenu(e, img)}
      />
    );
  };

  return (
    <div className="dropImageCollection">
      <div className="collection">
        {images.map((img, index) => (
          <div className="imgBox">
            <ImageItem img={img} index={index} />
            {showDeleteButton[index] && (
              <div
                className="delBox"
                onClick={(e) =>
                  e.target === e.currentTarget &&
                  setShowDeleteButton(showDeleteButton.map(() => false))
                }>
                <button onClick={() => deleteImages(img)}>
                  <AiOutlineDelete />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      {isDetail && <ImageDetailViewer images={imageOrder} setIsDetail={setIsDetail} />}
      {showMenu && (
        <DropdownMenu x={menuPosition.x} y={menuPosition.y} deleteImages={deleteImages} />
      )}
    </div>
  );
};

export default DropImageCollection;
