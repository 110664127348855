import React, { useEffect, useRef, useState } from "react";
import { ProjectCalendarContainer, ProjectDetailSection } from "../../common/styled/project";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { hexToRgb, isEventAllTime } from "../../../../scheduler/component/function/common";
import moment from "moment";
import { CalendarContainer } from "../../../../scheduler/component/styled/scheduler";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { holidayList } from "../../../../scheduler/component/function/dateInfo";

const ProjectCalendar = ({ schedule }) => {
  const [data, setData] = useState([]);
  const calendarRef = useRef();
  const [onDate, setOnDate] = useState(moment().format("YYYY-MM-DD"));
  const [onPrevDate, setOnPrevDate] = useState(moment().format("YYYY-MM-DD"));
  const [currentHolidays, setCurrentHolidays] = useState([]);

  useEffect(() => {
    transformEvents(schedule);
  }, []);

  // 배경 변경
  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(onDate);
    }

    const prevDateElements = document.querySelectorAll(
      `[data-date="${moment(onPrevDate).format("YYYY-MM-DD")}"]`,
    );
    const currentDateElements = document.querySelectorAll(
      `[data-date="${moment(onDate).format("YYYY-MM-DD")}"]`,
    );

    prevDateElements.forEach((item) => item.classList.remove("selected"));
    currentDateElements.forEach((item) => item.classList.add("selected"));

    setOnPrevDate(onDate);
  }, [onDate]);

  // 날짜 클릭 시
  const handleDateClick = async (date) => {
    const target = moment(date).format("YYYY-MM-DD");
    setOnDate(target);
  };

  // 풀캘린더 형식으로 데이터 변환
  const transformEvents = async (origin) => {
    const transformData = origin.map((item) => {
      let end = moment(item.endDt);
      if (end.format("HH:mm:ss") === "00:00:00") {
        end = end.add(1, "seconds");
      }

      const opacity = item.completeYn === "Y" ? 0.7 : 0.8;
      const color = item.color ? `rgba(${hexToRgb(item.color)}, ${opacity})` : "";

      const event = {
        ...item,
        backgroundColor: color,
        borderColor: color,
        id: item.scheduleNo,
        start: item.startDt,
        allDay: false,
        end: end.format("YYYY-MM-DD HH:mm:ss"),
      };

      if (isEventAllTime(item)) {
        event.display = "block";
      }

      return event;
    });

    setData(transformData);
  };

  // 현재 보고 있는 달력
  const handleDatesSet = (info) => {
    setCurrentHolidays(isHolidays(info.startStr, info.endStr, holidayList));
  };

  const isHolidays = (startStr, endStr, holidays) => {
    const startDate = moment(startStr);
    const endDate = moment(endStr);

    const filtered = holidays.flatMap((holiday) => {
      let result = [];

      // 고정된 공휴일
      if (holiday.month && holiday.day) {
        const prevYear = moment([startDate.year() - 1, holiday.month - 1, holiday.day]);
        const currentYear = moment([startDate.year(), holiday.month - 1, holiday.day]);
        const nextYear = moment([startDate.year() + 1, holiday.month - 1, holiday.day]);

        if (prevYear.isSameOrAfter(startDate) && prevYear.isBefore(endDate)) {
          result.push({
            ...holiday,
            start: prevYear.format("YYYY-MM-DD 00:00:00"),
            end: prevYear.format("YYYY-MM-DD 23:59:59"),
          });
        }
        if (currentYear.isSameOrAfter(startDate) && currentYear.isBefore(endDate)) {
          result.push({
            ...holiday,
            start: currentYear.format("YYYY-MM-DD 00:00:00"),
            end: currentYear.format("YYYY-MM-DD 23:59:59"),
          });
        }
        if (nextYear.isSameOrAfter(startDate) && nextYear.isBefore(endDate)) {
          result.push({
            ...holiday,
            start: nextYear.format("YYYY-MM-DD 00:00:00"),
            end: nextYear.format("YYYY-MM-DD 23:59:59"),
          });
        }
      }

      // 매년 변하는 공휴일
      if (holiday.startDt && holiday.endDt) {
        const holidayStart = moment(holiday.startDt);
        const holidayEnd = moment(holiday.endDt);

        if (holidayStart.isSameOrAfter(startDate) || holidayEnd.isSameOrBefore(endDate)) {
          result.push({
            ...holiday,
            start: holidayStart.format("YYYY-MM-DD 00:00:00"),
            end: holidayEnd.format("YYYY-MM-DD 23:59:59"),
          });
        }
      }

      return result;
    });

    return filtered.map((item) => ({
      id: `holiday${item.start}`,
      title: item.name,
      start: item.start,
      end: item.end,
      display: "block",
      editable: false,
      backgroundColor: item.isPublicHoliday ? "#ca0000" : "#999",
      borderColor: item.isPublicHoliday ? "#ca0000" : "#999",
    }));
  };

  // 일정 순서
  const customEventOrder = (a, b) => {
    const aIsHoliday = a.id.includes("holiday");
    const bIsHoliday = b.id.includes("holiday");

    if (aIsHoliday && !bIsHoliday) {
      return -1;
    } else if (!aIsHoliday && bIsHoliday) {
      return 1;
    }

    const startDiff = a.start - b.start;
    if (startDiff !== 0) {
      return startDiff;
    }

    const durationDiff = b.end - b.start - (a.end - a.start);
    if (durationDiff !== 0) {
      return durationDiff;
    }

    return a.title.localeCompare(b.title);
  };

  // 공휴일 및 날짜 출력
  const displayDateInfo = (info) => {
    const current = info.date;
    const month = current.getMonth() + 1;
    const day = current.getDate();
    let target = holidayList
      .filter((item) => item.isPublicHoliday)
      .find((h) => h.month === month && h.day === day);

    if (!target) {
      target = holidayList.find((h) => {
        const startDt = moment(h.startDt);
        const endDt = moment(h.endDt);
        return current >= startDt && current <= endDt;
      });
    }
    const dateFormatted = moment(current).format("D");

    return target ? (
      <>
        <div className="holiday">
          {target.icon && <img src={target.icon} alt="" />}
          {target.name}
        </div>
        <div className="date">
          <span
            style={{
              color: target.isPublicHoliday && "#ca0000",
            }}>
            {dateFormatted}
          </span>
        </div>
      </>
    ) : (
      <>
        <div></div>
        <div className="date">
          <span>{dateFormatted}</span>
        </div>
      </>
    );
  };

  return (
    <ProjectDetailSection>
      <ProjectCalendarContainer>
        <CalendarContainer>
          <div className="calendarBtnArea">
            <button
              onClick={() => handleDateClick(moment().format("YYYY-MM-DD"))}
              className="todayBtn">
              오늘
            </button>
            <div className="moveMonth">
              <button
                onClick={() =>
                  handleDateClick(moment(onDate).subtract(1, "months").format("YYYY-MM-01"))
                }>
                <BsChevronLeft />
              </button>
              <button
                onClick={() =>
                  handleDateClick(moment(onDate).add(1, "months").format("YYYY-MM-01"))
                }>
                <BsChevronRight />
              </button>
            </div>
          </div>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin]}
            locale={"ko"}
            contentHeight={"auto"}
            events={data}
            dayMaxEvents={3}
            moreLinkContent={(info) => `+${info.num}`}
            datesSet={handleDatesSet}
            eventOrder={customEventOrder}
            dayCellContent={(info) => displayDateInfo(info)}
          />
        </CalendarContainer>
      </ProjectCalendarContainer>
    </ProjectDetailSection>
  );
};

export default ProjectCalendar;
