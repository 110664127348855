import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useRegex } from "../../../hooks/useRegex";

export default function HomepageEdit() {
  useEffect(() => {
    getCompanyInfo();
    checkedDomain();
  }, []);

  const cmpnyNo = useSelector((state) => state?.user?.data[0]?.cmpnyNo);
  const user = useSelector((state) => state?.user?.data[0]);
  const { BussinessFormat } = useRegex();

  const [companyInfo, setCompanyInfo] = useState({});
  const [res, setRes] = useState({});

  const getCompanyInfo = async () => {
    const url = "/api/cmpnyInfo";
    const body = {
      cmpnyNo,
    };
    const result = await axios.post(url, body);
    setCompanyInfo(result.data);
  };

  const checkedDomain = async () => {
    const url = "/api/domainSettingLoad";
    const body = {
      delYn: "N",
      cmpnyNo,
    };
    const response = await axios.post(url, body);
    setRes(response);
  };

  const openNewTab = () => {
    window.open(`http://1472.ai/shop/${res.data.domain}`);
    user && localStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <div className="inner_title" style={{ paddingBottom: "0" }}>
      <span className="font_30 font600 font_color_1 pdb_40">다음의 홈페이지를 수정합니다.</span>
      <ul className="hp_step_2_name pdb_40">
        <li>
          <span className="font_20 font500 font_color_black text_center">홈페이지 주소</span>
          {/* <span className="font_20 font400 font_color_black text_center">http://1472.ai/h/ogtoz</span> */}
          <span className="font_20 font400 font_color_black text_center">
            <Link onClick={openNewTab}>https://1472.ai/shop/{res?.data?.domain}</Link>
          </span>
        </li>
        <li>
          <span className="font_20 font500 font_color_black text_center">회사이름</span>
          <span className="font_20 font400 font_color_black text_center">
            {companyInfo.cmpnyNm}
          </span>
        </li>
        <li>
          <span className="font_20 font500 font_color_black text_center">사업자번호</span>
          <span className="font_20 font400 font_color_black text_center">
            {BussinessFormat(companyInfo.bizrno)}
          </span>
        </li>
        <li>
          <span className="font_20 font500 font_color_black text_center">생성타입</span>
          <span className="font_20 font400 font_color_black text_center">제품연동</span>
        </li>
      </ul>
      {/* <span className="font_20 font300 font_color_gray">
        <strong className="font_color_1">업종</strong> 또는{" "}
        <strong className="font_color_1">브랜드</strong>를 선택해 주세요.
        <br />
      </span> */}
    </div>
  );
}
