import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ReleaseNotesContainer } from "./component/styled/notice";

import ReleaseNoteItem from "./component/releaseNotesPage/releaseNoteItem";
import NoticeBanner from "./noticeBanner";

const ReleaseNotesPage = () => {
  const [devNotices, setDevNotices] = useState([]);
  const [totalNoticePage, setTotalNoticePage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [openNotice, setOpenNotice] = useState();

  useEffect(() => {
    getDevNotice(1);

    const observer = new IntersectionObserver(handleObserver, { threshold: 0.5 });
    const observerTarget = document.getElementById("observer");
    if (observerTarget) {
      observer.observe(observerTarget);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (pageNumber > totalNoticePage) return;
    getDevNotice(pageNumber);
  }, [pageNumber]);

  const getDevNotice = async (number = 1) => {
    const url = "/api/devNotice";
    const body = devNoticeCond(number);

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        console.log(res);
        setDevNotices((data) => [...data, ...res.data.content]);
        setTotalNoticePage(res.data.totalPages);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const devNoticeCond = (number) => {
    const pageSize = 10;
    const pageNumber = number - 1;

    return {
      cmpnyNo: 16421,
      gbn: "DEV",
      delYn: "N",
      offset: pageNumber * pageSize,
      pageNumber: pageNumber,
      pageSize,
      paged: true,
    };
  };

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPageNumber((page) => page + 1);
    }
  };

  const isOpenNotice = (noticeNo) => {
    if (noticeNo === openNotice) {
      return true;
    }
    return false;
  };

  return (
    <ReleaseNotesContainer>
      <NoticeBanner />
      <div className="section">
        <div className="inner">
          <ul style={{ minHeight: 800 }}>
            {devNotices.length > 0 &&
              devNotices?.map((item) => (
                <ReleaseNoteItem
                  item={item}
                  isOpenNotice={isOpenNotice}
                  setOpenNotice={setOpenNotice}
                />
              ))}
          </ul>
        </div>
        <div id="observer" style={{ height: "10px" }}></div>
      </div>
    </ReleaseNotesContainer>
  );
};

export default ReleaseNotesPage;
