import MypageBanner from "../mypageBanner";
import HomePageSettingMenu from "./homepageSettingMenu";
import HomePageSettingForm from "./component/homePageSettingForm";

const HomepageSetting = () => {

  return(
    <div>
      <MypageBanner />
      <div className="inner_content">
        <HomePageSettingMenu />
        <HomePageSettingForm />  
      </div>
    </div>
  )
};

export default HomepageSetting;