import React from "react";
import { CiSearch } from "react-icons/ci";
import { SearchFormContainer } from "../styled/company";

const SearchForm = () => {
  return (
    <SearchFormContainer>
      <input type="text" placeholder="찾으려고 하는 직원의 이름을 입력해주세요." />
      <button>
        <CiSearch />
      </button>
    </SearchFormContainer>
  );
};

export default SearchForm;
