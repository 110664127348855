import React, { useEffect, useState } from "react";

import { SearchedContainer } from "../styled/search";
import { useRegex } from "../../../../hooks/useRegex";
import { StockItem } from "./searchItems";

const SearchEChanggo = ({ data, isSearch }) => {
  const { MoneyFormat } = useRegex();
  const [imageHeight, setImageHeight] = useState();

  const updateImageSize = () => {
    const previewWidth = document.querySelector(".preview")?.offsetWidth;
    setImageHeight(previewWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateImageSize);
    return () => {
      window.removeEventListener("resize", updateImageSize);
    };
  }, []);

  return (
    <div className="searched">
      {isSearch && (
        <SearchedContainer className="stockList">
          {data.length > 0 ? (
            <>
              <div className="label">
                {data.length > 0 && (
                  <span>
                    검색 결과
                    <strong>{MoneyFormat(data.length)}</strong>개
                  </span>
                )}
              </div>
              {data.length > 0 && data && (
                <ul>
                  {data.map((item) => (
                    <StockItem
                      item={item}
                      updateImageSize={updateImageSize}
                      imageHeight={imageHeight}
                    />
                  ))}
                </ul>
              )}
            </>
          ) : (
            <div className="noData">검색 결과가 존재하지 않습니다.</div>
          )}
        </SearchedContainer>
      )}
    </div>
  );
};

export default SearchEChanggo;
