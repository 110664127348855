import { useState, useRef, useEffect } from "react";

import CategoryList from "./productInfoForm/categoryList";
import ThumbnailUpload from "./productInfoForm/thumbnailUpload";
import InputField from "./productInfoForm/inputField";
import useOutsideClick from "../../../../../../hooks/useOutsideClick";

import { ProductInfoFormContainer } from "../../styled/productAddForm";
import ImageDetailViewer from "../../../../../../common/imageDetailViewer";

const ProductInfoForm = ({
  changeInput,
  categories,
  setProduct,
  product,
  handleAddImage,
  handleDeleteImage,
  onDragEnd,
  categoriesObj,
}) => {
  const [viewCategory, setViewCategory] = useState(false);
  const categoriesRef = useRef();
  const [isDetail, setIsDetail] = useState(false);

  useOutsideClick(categoriesRef, () => setViewCategory(false));

  // 카테고리 체크
  const handleChangeRadio = (item) => {
    setProduct((data) => ({
      ...data,
      categoryId: item.categoryId,
    }));

    setViewCategory(false);
  };

  const resetCategory = () => {
    setProduct((data) => ({ ...data, categoryId: null }));
  };

  // 카테고리별 스타일
  const treeCategoryView = (item) => {
    if (!item) return;
    if (item.path.includes("/")) {
      const count = [...item.path].filter((item) => item === "/").length + 1;
      return `5px 20px 5px ${count * 20}px`;
    }
    return "5px 20px";
  };

  return (
    <ProductInfoFormContainer id="step1_html">
      <h5 className="sound_only">제품 정보 입력</h5>
      <div className="writing_ty2">
        <ul className="writing_list">
          <ThumbnailUpload
            product={product}
            handleAddImage={handleAddImage}
            handleDeleteImage={handleDeleteImage}
            onDragEnd={onDragEnd}
            setIsDetail={setIsDetail}
          />
          <InputField
            label="제품명"
            name="name"
            placeholder="제품명을 입력해주세요."
            required
            onChange={changeInput}
            value={product.name}
          />
          <li className={"half_li"}>
            <div className={`writing_tit`}>
              <strong className="tit">
                소비자가
                <em className="point">필수</em>
              </strong>
            </div>
            <div className="writing_cont">
              <div className="inputxt_normal spider">
                <input
                  type="number"
                  name="basePrice"
                  placeholder="소비자가를 입력해주세요."
                  onChange={changeInput}
                  value={product?.basePrice > 0 ? product.basePrice : null}
                  min={0}
                />
              </div>
            </div>
          </li>
          <InputField
            label="규격"
            name="specifications"
            placeholder="ex) 10x20cm"
            onChange={changeInput}
            half={true}
            value={product.specifications}
          />
          <li>
            <div className="writing_tit required">
              <strong className="tit">
                카테고리<em className="point">필수</em>
              </strong>
            </div>
            <div className="writing_cont">
              <CategoryList
                categories={categories}
                product={product}
                handleChangeRadio={handleChangeRadio}
                treeCategoryView={treeCategoryView}
                viewCategory={viewCategory}
                setViewCategory={setViewCategory}
                categoriesRef={categoriesRef}
                categoriesObj={categoriesObj}
                resetCategory={resetCategory}
              />
            </div>
          </li>
          <InputField
            label="브랜드"
            name="brand"
            placeholder="브랜드를 입력해주세요."
            onChange={changeInput}
            value={product.brand}
          />
          <InputField
            label="상품 설명"
            name="description"
            placeholder="상품 설명을 입력해주세요."
            onChange={changeInput}
            value={product.description}
          />
        </ul>
        {product?.media && product?.media.length > 0 && isDetail && (
          <ImageDetailViewer images={product.media} setIsDetail={setIsDetail} />
        )}
      </div>
    </ProductInfoFormContainer>
  );
};

export default ProductInfoForm;
