import QnaRequestContents from "./qnaRequestContents";
import QnaRequestPopup from "./qnaRequestPopup";
// import QnaRequestTitle from "./qnaRequestTitle";

export default function QnaRequestForm() {
  return (
    <div className="inner_content">
      <div className="inner_title">
        <span className="font_48 font600 font_color_black pdb_40">이용문의</span>
        <span className="font_30 font300 font_color_black pdb_40">
          서비스 사용 중 궁금한 점이나 문제를 해결해드립니다.
          <br />
          {service.bComment2}
        </span>
      </div>
      <QnaRequestContents />
      <QnaRequestPopup />
    </div>
  );
}
