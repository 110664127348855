import { Link } from "react-router-dom";

const NoticeBanner = () => {

  return (
    <div className="sub_top" id="notice">
      <span className="font_22 font300 pdb_20 font_color_white">{service.noticeInfo}</span>
      <span className="font_72 font700 font_color_1 pdb_70">{service.noticeE}</span>
      <ul className="sub_menu">
        {noticeBanner.map((item, i) =>
          <li key={i}> 
            <Link to={item.link}>{item.label}</Link>
          </li>
        )}
      </ul>
    </div>
  );
}

export default NoticeBanner;