import { useSelector } from "react-redux";
import ApplicationForm from "./component/applicationForm";
import ApplicationSecForm from "./component/applicationSecForm";
import ApplicationThdForm from "./component/applicationThdForm";
import ApplicationBanner from "./applicationBanner";


export default function ApplicationPage () {
  const index = useSelector((state) => state.compoIndex.application);
 
  return(
    <div>
      <ApplicationBanner 
        index={index}
      />
      <div className="inner_content" id="application_wrap" >
        <Components index={index} />
      </div>
    </div>
  )
}

function Components ({index}) {
  return(
    <>
     {[<ApplicationForm />, <ApplicationSecForm />, <ApplicationThdForm />][index]}
    </>
  )
}