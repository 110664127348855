import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { CompanyInviteContainer } from "../styled/company";
import { Modal } from "../../../styled/mypage";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";

const InviteModal = ({
  setMoblphon,
  moblphon,
  handleSearch,
  isSearched,
  searched,
  sendKakaoMessage,
  setIsInvite,
  handleInvite,
}) => {
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <Modal className={setting.mode}>
      <CompanyInviteContainer className={setting.mode}>
        <div className="modal">
          <div className="modalContent">
            <div className="closeButton">
              <button onClick={() => setIsInvite(false)}>
                <IoMdClose />
              </button>
            </div>
            <div className="label">회사초대</div>
            <div className="search">
              <span>휴대번호</span>
              <input
                type="text"
                placeholder="휴대폰 번호를 입력해주세요."
                id="keyword"
                name="keyword"
                onChange={(e) => setMoblphon(e.target.value)}
                value={moblphon}
                onKeyDown={(e) => handleEnterKey(e, handleSearch)}
              />
              <button>
                <CiSearch />
              </button>
            </div>
            {isSearched && (
              <div className="searched">
                {searched.length > 0 ? (
                  <ul>
                    <li>
                      <div className="mberNm">이름</div>
                      <div className="moblphonNo">전화번호</div>
                      <div className="invite">초대</div>
                    </li>
                    {searched.map((user) => (
                      <li key={user.mberNo}>
                        <div className="mberNm">
                          {`${user.mberNm[0]}${
                            user.mberNm.length > 1 ? "*" : ""
                          }${user.mberNm.substring(2)}`}
                        </div>
                        <div className="moblphonNo">{`${user.moblphonNo.substring(
                          0,
                          3,
                        )}****${user.moblphonNo.substring(7)}`}</div>
                        <div className="invite">
                          <button onClick={() => handleInvite(user.mberNo)}>초대</button>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="msg">
                    <span>
                      사용자가 존재하지 않습니다.
                      <br />
                      카카오톡 메시지를 보내시겠습니까?
                    </span>
                    <button onClick={sendKakaoMessage}>카카오톡 공유</button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </CompanyInviteContainer>
    </Modal>
  );
};

export default InviteModal;
