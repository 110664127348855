import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import GroupDetailSearchMap from "./groupDetailSearchEChanggo/groupDetailSearchMap";
import GroupDetailSearchProduct from "./groupDetailSearchEChanggo/groupDetailSearchProduct";
import GroupDetailSearchForm from "./groupDetailSearchForm";

const GroupDetailSearchEChanggo = ({ grp, cmpnys, searchWord, setSearchWord }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const [searched, setSearched] = useState(false);
  const [cmpnyNos, setCmpnyNos] = useState([...Object.keys(cmpnys)]);
  const [companys, setCompanys] = useState([]);
  const [selectedCmpny, setSelectedCmpny] = useState(null);
  const [selectedCmpnyPorts, setSelectedCmpnyPorts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [cmpnyProductList, setCmpnyProductList] = useState([]);
  const [totalProductPage, setTotalProductPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const swiperRef = useRef(null);

  useEffect(() => {
    getProductSearch(pageNumber);

    const observer = new IntersectionObserver(handleObserver, { threshold: 0.5 });
    const observerTarget = document.getElementById("observer");
    if (observerTarget) {
      observer.observe(observerTarget);
    }
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (pageNumber > totalProductPage) return;
    getProductSearch(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (cmpnyNos.length) {
      getCmpnyInfos(cmpnyNos);
    } else {
      setCompanys([]);
    }
  }, [cmpnyNos]);

  useEffect(() => {
    if (selectedCmpny) {
      getCmpnyPrducts();
      getCmpnyPort(selectedCmpny.cmpnyNo);
    }
  }, [selectedCmpny]);

  // 포트폴리오 조회
  const getCmpnyPort = async (cmpnyNo) => {
    const url = "/api/portMgmtList";
    const body = {
      cmpnyNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 5,
      paged: true,
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      setSelectedCmpnyPorts([...res.data.content]);
      if (swiperRef.current) {
        swiperRef.current.swiper.slideTo(0);
      }
    }
  };

  // 상품 조회
  const getProductSearch = async (page = 1) => {
    const url = "/api/combineSearch";
    const body = {
      groupNo: grp.groupNo,
      searchType: "E",
      searchWord,
      ...productCond(page),
    };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      const stocks = res.data.stockPageList;

      setProductList((product) => {
        const newProducts = stocks.content.filter(
          (item) => !product.some((prev) => prev.prdNo === item.prdNo),
        );
        return [...product, ...newProducts];
      });

      setTotalProductPage(stocks.totalPages);

      if (searchWord) {
        nonDuplicateCmpny(stocks.content);
      } else {
        setCmpnyNos([...Object.keys(cmpnys)]);
      }
    }
  };

  const nonDuplicateCmpny = (data) => {
    const cmpnys = data.map((item) => item.cmpnyNo);
    const uniqueCmpnyNos = new Set(cmpnys);
    setCmpnyNos([...uniqueCmpnyNos]);
  };

  const productCond = (number) => {
    const pageSize = 8;
    const pageNumber = number - 1;

    return {
      offset: pageNumber * pageSize,
      pageNumber: pageNumber,
      pageSize,
      paged: searchWord ? false : true,
    };
  };

  // 검색
  const handleSearch = async () => {
    setProductList([]);
    await getProductSearch(1);

    setPageNumber(1);
    setSearched(true);
    setSelectedCmpny(null);
  };

  // 회사 목록 데이터 조회
  const getCmpnyInfos = async (cmpnyNos) => {
    const url = "/api/cmpnyInfos";
    const body = { cmpnyNos };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      setCompanys(res.data);
    }
  };

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !selectedCmpny) {
      setPageNumber((page) => page + 1);
    }
  };

  // 특정 회사 상품 조회
  const getCmpnyPrducts = async () => {
    const url = "/api/prductStock";
    const body = {
      cmpnyNo: selectedCmpny.cmpnyNo,
      prductNm: searchWord,
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      setCmpnyProductList(res.data.content);
    }
  };

  return (
    <div className="eChanggo">
      <GroupDetailSearchForm
        searchWord={searchWord}
        setSearchWord={setSearchWord}
        handleSearch={handleSearch}
      />
      <GroupDetailSearchMap
        swiperRef={swiperRef}
        companys={companys}
        searched={searched}
        searchWord={searchWord}
        setSelectedCmpny={setSelectedCmpny}
        selectedCmpny={selectedCmpny}
        cmpnys={cmpnys}
        selectedCmpnyPorts={selectedCmpnyPorts}
      />
      <GroupDetailSearchProduct
        productList={selectedCmpny ? cmpnyProductList : productList}
        cmpnys={cmpnys}
      />
      <div id="observer" style={{ height: "10px" }}></div>
    </div>
  );
};

export default GroupDetailSearchEChanggo;
