import React, { useEffect, useState } from "react";
import { useLongPress } from "use-long-press";
import { AiOutlineDelete } from "react-icons/ai";

const MobileDropImageCollection = ({
  images,
  setIsDetail,
  setImageOrder,
  deleteImages,
  handleContextMenu,
}) => {
  const [showDeleteButton, setShowDeleteButton] = useState(Array(images.length).fill(false));

  useEffect(() => {
    setImageOrder(images);
    setShowDeleteButton(Array(images.length).fill(false));
  }, [images]);

  const handleImageClick = (index) => {
    setIsDetail(true);
    const reorderedImages = [...images.slice(index, images.length), ...images.slice(0, index)];
    setImageOrder(reorderedImages);
  };

  const setShowDelBtn = (active) => {
    setShowDeleteButton(showDeleteButton.map((item, index) => index === active));
  };

  const ImageItem = ({ img, index }) => {
    const bind = useLongPress(
      () => {
        setShowDelBtn(index);
      },
      {
        threshold: 500,
        captureEvent: true,
        cancelOnMovement: true,
      },
    );

    return (
      <img
        {...bind()}
        src={imgHandler(img.path)}
        onClick={() => handleImageClick(index)}
        onContextMenu={(e) => handleContextMenu(e, img)}
      />
    );
  };

  return (
    <div className="dropImageCollection">
      <div className="collection">
        {images.map((img, index) => (
          <div className="imgBox" key={index}>
            <ImageItem img={img} index={index} />
            {showDeleteButton[index] && (
              <div
                className="delBox"
                onClick={(e) =>
                  e.target === e.currentTarget &&
                  setShowDeleteButton(showDeleteButton.map(() => false))
                }>
                <button onClick={() => deleteImages(img)}>
                  <AiOutlineDelete />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileDropImageCollection;
