import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { ConfirmButton } from "./styled/common";
import { GroupJoinInfoContainer } from "./styled/group";

const GroupJoinInfo = ({ setIsUserRegister, id, grp, userInfo, getGrpMbers, isApplyUser }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();
  const [gbnValue, setGbnValue] = useState(userInfo.gbnValue ? userInfo.gbnValue : "");
  const [nickNm, setNickNm] = useState(
    userInfo.nickNm ? userInfo.nickNm : user.name ? user.name : "닉네임 미설정",
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChkDuplNickNm, setIsChkDuplNickNm] = useState(false);

  const changeGbnValue = (e) => {
    setGbnValue(e.target.value);
  };

  // 모임 가입
  const signGroup = async () => {
    if (grp?.gbn && !gbnValue) {
      toast.error("기수를 입력해주세요.");
      return;
    }
    if (!isChkDuplNickNm) {
      toast.error("닉네임 중복 확인을 해주세요.");
      return;
    }

    if (isSubmitting) {
      toast.error("가입이 진행 중입니다.");
      return;
    }
    setIsSubmitting(true);

    const url = "/api/groupMber";
    const body = {
      groupNo: id,
      mberNo: user.mberNo,
      auth: userInfo.auth,
      createMberId: user.userName,
      updateMberId: user.userName,
      delYn: "N",
      nickNm: nickNm,
    };

    // 명칭 사용
    if (grp?.gbn) {
      body.gbnValue = gbnValue;
    }
    // 가입 승인제 사용
    if (grp?.approvalUseYn === "Y") {
      body.approvalYn = userInfo.seq ? null : "N";
      body.isApplication = !userInfo.seq && !isApplyUser ? "Y" : null;
    }
    if (userInfo.seq) {
      body.seq = userInfo.seq;
    }

    const res = await axios.put(url, body);

    if (res.status === 200) {
      if (grp.approvalUseYn === "Y" && !isApplyUser) {
        toast.success("모임 가입이 신청되었습니다.");
        navigate("/group");
      } else {
        toast.success("모임 가입이 완료되었습니다.");
        setIsUserRegister(true);
        getGrpMbers(id);
      }
    } else {
      alert("잠시후 다시 시도해주세요");
    }
    setIsSubmitting(false);
  };

  // 닉네임 중복 체크
  const chkDuplNickNm = async () => {
    if (!nickNm || !nickNm.trim()) {
      toast.error("닉네임을 입력해주세요.");
      return;
    }
    if (nickNm.length < 2 || nickNm.length > 12) {
      toast.error("닉네임의 길이는 2~12자리여야 합니다.");
      return;
    }

    const url = "/api/duplNickName";
    const body = {
      groupNo: id,
      delYn: "N",
      nickNm: nickNm,
    };

    const res = await axios.post(url, body);
    if (res.data.success) {
      toast.success("사용 가능한 닉네임입니다.");
      setIsChkDuplNickNm(true);
    } else {
      toast.error("이미 사용 중인 닉네임입니다.");
      setIsChkDuplNickNm(false);
    }
  };

  // 닉네임 변경
  const changeNickName = (e) => {
    setNickNm(e.target.value);
    setIsChkDuplNickNm(false);
  };

  return (
    <GroupJoinInfoContainer>
      {isApplyUser ? (
        <div>가입 진행 중인 모임입니다.</div>
      ) : (
        <div className="myGrpContent">
          {grp?.gbn && (
            <div className="gbn">
              <p>명칭을 입력해 주세요</p>
              <label style={{ fontSize: "16px" }}>
                <input type="number" onChange={changeGbnValue} />
                <span>{grp?.gbn}</span>
              </label>
            </div>
          )}
          {grp?.nickUseYn === "Y" && (
            <div className="nick">
              <p>닉네임을 입력해 주세요</p>
              <label style={{ fontSize: "16px" }}>
                <input type="text" onChange={(e) => changeNickName(e)} value={nickNm} />
                <button onClick={chkDuplNickNm}>중복 확인</button>
              </label>
            </div>
          )}
          <ConfirmButton
            className={`pw_confirm pw_100${isSubmitting ? " isSubmit" : ""}`}
            onClick={signGroup}>
            <span>가입하기</span>
          </ConfirmButton>
        </div>
      )}
    </GroupJoinInfoContainer>
  );
};

export default GroupJoinInfo;
