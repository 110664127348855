import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

import { CustResvContainer } from "../../styled/storage";
import { Modal, ModalCloseButton } from "../../styled/common";

import { roundMinutes } from "../../function/kiosk";

import { IoClose } from "react-icons/io5";
import { TbArrowBackUp } from "react-icons/tb";

import CustomerList from "./custResvModal/customerList";
import SaveForm from "./custResvModal/saveForm";
import StorageNumber from "./userInfoForm/storageNumber";
import OnsitePayment from "./custResvModal/onsitePayment";
import ReserveCancel from "./userInfoForm/reserveCancel";

const CustResvModal = ({ handleExitModal, getPrintElement }) => {
  const [detail, setDetail] = useState([]);
  const [searched, setSearched] = useState("");
  const [viewType, setViewType] = useState(0);
  const [waitings, setWaitings] = useState([]);
  const [checkStorage, setCheckStorage] = useState([]);
  const [onsitePayments, setOnsitePayments] = useState([]);
  const [storage, setStorage] = useState([]);
  const [selectCancel, setSelectCancel] = useState({});
  const [origin, setOrigin] = useState([]);
  const [memo, setMemo] = useState("");
  const [payType, setPayType] = useState("1");
  const [selectAll, setSelectAll] = useState(false);

  const transformValue = viewType * -100;

  useEffect(() => {
    getReserveUser();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setSearched(value);
    }
  };

  const handleStorage = async (item) => {
    const reserved = await getReserveUserDetail(item.id);
    setDetail(reserved);
    setCheckStorage([...reserved].map((item) => item.id));
    setViewType(1);
  };

  // 예약 취소
  const handleCancel = (item) => {
    setSelectCancel(item);
    setViewType(4);
  };

  // 예약 고객 상세 조회
  const getReserveUserDetail = async (id) => {
    const url = "/api/reserve-user-detail";
    const body = { id };
    const res = await axios.post(url, body);

    if (res.status === 200) {
      const filtered = res.data.filter((item) => item.reservationStatus === "PENDING");
      return filtered;
    }
  };

  const handleBackButton = () => {
    switch (viewType) {
      case 1:
      case 3:
      case 4:
      case 5:
        handleReset();
        setViewType(0);
        break;
      case 0:
        handleExitModal(true);
        break;
      default:
        setViewType((type) => type - 1);
        break;
    }
  };

  // 예약 고객 조회
  const getReserveUser = async () => {
    const url = "/api/reserve-user";
    const body = {
      reserveStatus: "PENDING",
    };
    // if (parseInt(searched, 10)) {
    //   body.lastFourDigits = searched;
    // }

    const res = await axios.post(url, body);
    if (res.status === 200) {
      setOrigin(res.data);
      setWaitings(res.data);
    }
  };

  // 보관하기 버튼 눌렀을 시
  const handleCompleted = async (reserve) => {
    if (!reserve.historyIds.length || !reserve.historyIds) {
      toast.error("예약건을 선택해주세요.");
      return;
    }

    const data = detail.filter((item) => reserve.historyIds.includes(item.id) && item.payType > 0);
    setOnsitePayments(data);

    if (data.length > 0) {
      setViewType(2);
    } else {
      handleStorageUpdate(reserve);
    }
  };

  // 보관 api
  const handleStorageUpdate = async (reserve, flag = false) => {
    if (!reserve.historyIds.length || !reserve.historyIds) {
      toast.error("예약건을 선택해주세요.");
      return;
    }

    const url = "/api/reserve-completed";
    const body = { ...reserve };

    if (memo) {
      body.memo = memo;
    }
    if (flag) {
      body.payType = payType;
    }

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        setStorage(res.data);
        setViewType(3);
        await getReserveUser();
        getPrintElement(res.data);
      }
    } catch (error) {
      console.log(error);
      const message = error?.response?.data?.message || "알 수 없는 이유로 보관할 수 없습니다.";
      toast.error(message);
    }
  };

  const handleSearch = () => {
    setWaitings(
      origin.filter((item) => {
        return item.tel && item.tel.includes(searched);
      }),
    );
  };

  const handleReset = () => {
    setDetail([]);
    setCheckStorage([]);
    setOnsitePayments([]);
    setPayType("1");
    setStorage([]);
    setMemo("");
    setSelectAll(false);
  };

  // 취소하기
  const handleReserveCancel = async () => {
    const url = "/api/reserve-cancel";
    const body = { reserveId: selectCancel.id };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        toast.success("예약이 취소되었습니다.");
        await getReserveUser();
        setViewType(0);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || "알 수 없는 이유로 예약을 취소할 수 없습니다.";
      toast.error(message);
    }
  };

  return (
    <CustResvContainer>
      <Modal>
        <div className="modalContent">
          <ModalCloseButton className="close" onClick={handleBackButton}>
            {!!viewType ? <TbArrowBackUp /> : <IoClose />}
          </ModalCloseButton>
          <div className="slideWrapper">
            <CustomerList
              transformValue={transformValue}
              handleStorage={handleStorage}
              handleInputChange={handleInputChange}
              waitings={waitings}
              searched={searched}
              handleCancel={handleCancel}
              handleSearch={handleSearch}
            />
            <SaveForm
              transformValue={transformValue}
              detail={detail}
              handleCompleted={handleCompleted}
              checkStorage={checkStorage}
              setCheckStorage={setCheckStorage}
              memo={memo}
              setMemo={setMemo}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
            />
            <OnsitePayment
              transformValue={transformValue}
              onsitePayments={onsitePayments}
              checkStorage={checkStorage}
              detail={detail}
              handleBackButton={handleBackButton}
              handleStorageUpdate={handleStorageUpdate}
              payType={payType}
              setPayType={setPayType}
            />
            <StorageNumber
              storedItems={storage}
              handleBackButton={handleBackButton}
              transformValue={transformValue}
              getPrintElement={getPrintElement}
            />
            <ReserveCancel
              transformValue={transformValue}
              selectCancel={selectCancel}
              handleBackButton={handleBackButton}
              handleReserveCancel={handleReserveCancel}
            />
          </div>
        </div>
      </Modal>
    </CustResvContainer>
  );
};

export default CustResvModal;
