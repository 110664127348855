import React from "react";

const TabMenu = ({ tabs, active, setActive }) => {
  return (
    <div className="tabs">
      <ul>
        {tabs.map((tab) => (
          <li
            key={tab.key}
            onClick={() => setActive(tab.key)}
            className={active === tab.key ? "active" : "inactive"}>
            {tab.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabMenu;
