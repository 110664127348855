import styled from "styled-components";

export const Container = styled.div`
  justify-content: space-evenly;
  position: relative;
  width: 100%;
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  padding: 100px 20px;
  .joinTypeBox {
    width: 40%;
    text-align: center;
    border: 2px solid #0074ca;
    padding: 50px;
    border-radius: 15px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      background: #efefef;
    }
    .tit {
      font-size: 25px;
      margin-bottom: 10px;
      p {
        font-weight: 500;
      }
      img {
        width: 100px;
      }
    }
    span {
      font-size: 15px;
    }
    button {
      display: block;
      border: none;
      margin: 20px auto;
      padding: 10px 30px;
      border-radius: 10px;
      font-size: 15px;
      background: #0074ca;
      color: #fff;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: normal;
    gap: 30px;
    padding: 50px 20px;
    .joinTypeBox {
      width: 100%;
      padding: 30px;
    }
  }
`;

export const CustomCheckbox = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 10px 0;
  input {
    margin: 0;
  }
`;

export const LoginFormContainer = styled.div`
  span.label {
    display: block;
    font-size: 20px;
    margin: 10px 0 5px;
  }
`;
