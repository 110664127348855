import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { NotifyBenefitContainer } from "../styled/dashboard";
import { useSelector } from "react-redux";

const NotifyBenefit = () => {
  const [temp, setTemp] = useState([]);
  const user = useSelector((state) => state?.user?.data[0]);

  useEffect(() => {
    createAdData();
  }, []);

  function createAdData() {
    const adData = [];
    const categories = ["식품", "생활용품", "디지털 & 가전", "뷰티", "스포츠"];
    const tags = ["타임세일", "브랜드데이", "10% 할인 쿠폰", "한정수량", "오후 12시 라이브"];
    const descs = [
      "계엄을 선포한 때에는 대통령은 지체없이 국회에 통고하여야 한다. 원장은 국회의 동의를 얻어 대통령이 임명하고, 그 임기는 4년으로 하며, 1차에 한하여 중임할 수 있다. 모든 국민은 인간으로서의 존엄과 가치를 가지며, 행복을 추구할 권리를 가진다. 국가는 개인이 가지는 불가침의 기본적 인권을 확인하고 이를 보장할 의무를 진다. 대통령은 국무총리·국무위원·행정각부의 장 기타 법률이 정하는 공사의 직을 겸할 수 없다. 대통령은 필요하다고 인정할 때에는 외교·국방·통일 기타 국가안위에 관한 중요정책을 국민투표에 붙일 수 있다. 헌법재판소 재판관은 정당에 가입하거나 정치에 관여할 수 없다.",
      "고단백· 저당으로 건강과 맛을 동시에",
      "컬러감 좋은 젤컬러들만 모아모아~ 지금 스토어에서 10% 할인쿠폰까지",
      "사람을 짜증나게 하는 방법에는 두가지가 있다 하나는 말을 하다 마는 것이고",
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies",
      "추경 고객님 로젠택배 입니다. 고객님의 소중한 물품을 요청하신 장소로 오늘 배송완료 하였습니다. 항상 로젠택배를 이용해 주셔서 감사합니다.^^ ※ 택배박스는 운송장, 테이프 등 이물질을 제거 후 접어서 배출해주시길 바랍니다.",
    ];

    for (let i = 1; i <= 10; i++) {
      adData.push({
        adName: `[브랜드데이] 포니가 쏜다! ${i}`,
        adDescription: descs[Math.floor(Math.random() * descs.length)],
        tag: tags[Math.floor(Math.random() * tags.length)],
        storeNm: "포니네 당근마켓",
        createdAt: new Date().toISOString(),
        category: categories[Math.floor(Math.random() * categories.length)],
        virtualImage: `/img/prdct_0${i > 7 ? 8 : i + 1}.png`,
      });
    }

    console.log(adData);
    setTemp(adData);
  }

  const breakpointColumnsObj = {
    default: 3,
    1400: 3,
    1050: 2,
    767: 1,
  };

  const openAdvertisementPage = async () => {
    user && localStorage.setItem("user", JSON.stringify(user));
    window.open(`https://1472.ai/shop/lotteria-daejeon`);
  };

  return (
    <NotifyBenefitContainer>
      {temp && temp.length > 0 && (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          <div className="benefitItem" onClick={openAdvertisementPage}>
            <div className="storeContainer">
              <div className="storeThumb">
                <img src="/img/tempAdvertisementLogo.png" alt="" />
              </div>
              <div className="storeNm">롯데리아</div>
              <div className="category">브랜드</div>
            </div>
            <div className="productContainer">
              <div className="info">
                <span className="title">&#91;남대전고&#93; 14기 조원선</span>
                <span className="desc">쿠폰을 가져오시는 분들께 모든 상품 10%</span>
                <span className="tag">10% 할인 쿠폰</span>
              </div>
              <div className="thumb">
                <img src="/img/temp.png" alt="" />
              </div>
            </div>
          </div>
          {temp.map((item) => (
            <div className="benefitItem" onClick={() => alert("준비 중입니다.")}>
              <div className="storeContainer">
                <div className="storeThumb">
                  <img src="/img/tempAdvertisementLogo.png" alt="" />
                </div>
                <div className="storeNm">{item.storeNm}</div>
                <div className="category">{item.category}</div>
              </div>
              <div className="productContainer">
                <div className="info">
                  <span className="title">{item.adName}</span>
                  <span className="desc">{item.adDescription}</span>
                  <span className="tag">{item.tag}</span>
                </div>
                <div className="thumb">
                  <img src={item.virtualImage} alt="" />
                </div>
              </div>
            </div>
          ))}
        </Masonry>
      )}
    </NotifyBenefitContainer>
  );
};

export default NotifyBenefit;
