import { ApplicationSecFormContainer } from "./styled/application";

export default function ApplicationSecForm() {
  const priceList = [
    {
      month: "Free",
      priceOrigin: "1 ~ 50유저",
      now: "FREE",
      services: [
        "기본 레이아웃",
        "모노티앱 PUSH 기능",
        "주문 및 매출 연동",
        "상품 연동 결제/예약 기능",
      ],
    },
    {
      month: "Startup",
      priceOrigin: "51 ~ 100유저",
      now: "18,000",
      services: ["FREE의 모든 기능", "사용자 10+"],
    },
    {
      month: "Enterprise",
      priceOrigin: "101 ~ 200유저",
      now: "30,000",
      services: ["FREE의 모든 기능", "사용자 50+"],
    },
    { month: "클라우드 + 쇼핑몰 구축", priceOrigin: "월 구독료", now: "별도문의", services: [] },
  ];

  return (
    <ApplicationSecFormContainer>
      <div className="inner_title" id="application_2">
        <span className="font_48 font600 font_color_black pdb_40">{service.price}</span>
        <span className="font_30 font300 font_color_black">
          {service.pComment1}
          <br />
          {service.pComment2}
        </span>
      </div>
      <div className="inner_content_wrap" id="application_22">
        <div className="application_22_add">
          <span className="font_24 font400 font_color_1 pdb_10 text_center">
            {service.priceInfo}
            <br />
            <strong>{service.pComment3}</strong>
            <br />
            {service.pComment4}
          </span>
        </div>
        <ul className="application_2_price">
          {priceList.map((item, i) => (
            <li key={i}>
              <span className="font_22 font600 text_center font_color_white price_month">
                {item.month}
              </span>
              <span className="font_38 font700 text_center font_color_3 pdt_20 pdb_20 price_now">
                {item.now}
                {<i>{i !== priceList.length - 1 && i !== 0 && "/월"}</i>}
              </span>
              <span className="font_18 font300 text_center font_color_black">(VAT 포함)</span>
              <div className="font_18 font600 text_center font_color_black pdb_30 price_percent pdt_30">
                {item.services.map((item) => (
                  <span>{item}</span>
                ))}
              </div>
            </li>
          ))}
        </ul>
        <a href="#" className="application_btn font_24 font300">
          {service.create}
        </a>
      </div>
    </ApplicationSecFormContainer>
  );
}
