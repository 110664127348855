import React from "react";
import { StorageTypeContainer } from "../styled/selfService";

const StorageType = ({ transformValue, chosenStorage, setChosenStorage, total }) => {
  const storages = {
    0: "냉장고",
    1: "상온",
    2: "캐리어",
  };

  const getImageSrc = (key) => `/img/selfService/storages_${key}.png`;

  const adjustValue = (key, increment) => {
    setChosenStorage((prevState) => {
      const newValue = prevState[key] + increment;
      return {
        ...prevState,
        [key]: Math.max(newValue, 0),
      };
    });
  };

  return (
    <StorageTypeContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="total">
        <span> {total}</span>
      </div>
      <div className="storageList">
        {Object.entries(chosenStorage).map(([key, value]) => (
          <div key={key} className="storageItem">
            <div className="image">
              <img src={getImageSrc(key)} alt={`Storage ${key}`} />
            </div>
            <div className="info">
              <span>{storages[key]}</span>
            </div>
            <div className="stepper">
              <button onClick={() => adjustValue(key, -1)}>-</button>
              <span>{value}</span>
              <button onClick={() => adjustValue(key, 1)}>+</button>
            </div>
          </div>
        ))}
      </div>
    </StorageTypeContainer>
  );
};

export default StorageType;
