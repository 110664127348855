import styled from "styled-components";

export const ApplicationSecFormContainer = styled.div`
  ul.application_2_price {
    display: flex;
    flex-wrap: wrap;
    li {
      i {
        font-style: normal;
        color: #bbb;
        font-size: 14px;
        font-weight: 500;
      }
      div.price_percent {
        border-top: 1px solid #efefef;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    ul.application_2_price li div.price_percent {
      border-top: none;
      margin-top: 0;
    }
  }
`;
