import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { useSelector } from "react-redux";

import GroupDetailSearchAll from "./groupDetailSearch/groupDetailSearchAll";
import GroupDetailSearchEChanggo from "./groupDetailSearch/groupDetailSearchEChanggo";
import GroupDetailSearchBlog from "./groupDetailSearch/groupDetailSearchBlog";
import GroupDetailSearchSchedule from "./groupDetailSearch/groupDetailSearchSchedule";
import GroupDetailSearchFeed from "./groupDetailSearch/groupDetailSearchFeed";
import GroupDetailSearchProject from "./groupDetailSearch/groupDetailSearchProject";

import { GroupDetailSearchContainer } from "../../styled/group";
import GroupDetailSearchForm from "./groupDetailSearch/groupDetailSearchForm";

const GroupDetailSearch = ({ grp, userInfo, cmpnys }) => {
  const location = useLocation();

  const user = useSelector((state) => state?.user?.data[0]);

  const [active, setActive] = useState(0);
  const [searchWord, setSearchWord] = useState("");
  const [data, setData] = useState({
    scheduleList: [],
    blogList: [],
    feedList: [],
    projectList: [],
  });
  const [isSearch, setIsSearch] = useState(false);

  const tabs = [
    { name: "전체", type: null },
    { name: "e층창고" },
    { name: "친구소식", type: "B" },
    { name: "일정", type: "S" },
    { name: "소식", type: "F" },
    { name: "프로젝트", type: "P" },
  ];

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const keyword = params.get("keyword");

      if (keyword) {
        tabChange(3);
        setSearchWord(keyword);
        handleSearch(keyword);

        params.delete("keyword");

        const newSearch = params.toString();
        const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;
        window.history.replaceState(null, "", newUrl);
      }
    }
  }, []);

  const tabChange = (idx) => {
    setActive(idx);
  };

  const handleSearch = async (keyword = "") => {
    const url = "/api/combineSearch";
    const body = {
      groupNo: grp.groupNo,
      searchWord: keyword ? keyword : searchWord,
      mberNo: user.mberNo,
    };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      setIsSearch(true);

      const data = res.data;
      const projectItems = [
        ...(data.projectList?.cmpnyProjectList || []),
        ...(data.projectList?.fixedProjectList || []),
        ...(data.projectList?.groupProjectList || []),
        ...(data.projectList?.otherProjectList || []),
      ];

      const filtered = {
        stockList: data.stockList || [],
        scheduleList: data.scheduleList || [],
        blogList: data.blogList || [],
        feedList: data.feedList || [],
        projectList: projectItems,
      };

      setData(filtered);
    }
  };

  const componentMap = {
    0: (
      <GroupDetailSearchAll
        data={data}
        isSearch={isSearch}
        tabChange={tabChange}
        cmpnys={cmpnys}
        searchWord={searchWord}
      />
    ),
    1: (
      <GroupDetailSearchEChanggo
        grp={grp}
        cmpnys={cmpnys}
        searchWord={searchWord}
        setSearchWord={setSearchWord}
      />
    ),
    2: <GroupDetailSearchBlog data={data.blogList} cmpnys={cmpnys} isSearch={isSearch} />,
    3: (
      <GroupDetailSearchSchedule
        data={data.scheduleList}
        isSearch={isSearch}
        grp={grp}
        searchWord={searchWord}
      />
    ),
    4: (
      <GroupDetailSearchFeed
        data={data.feedList}
        isSearch={isSearch}
        grp={grp}
        userInfo={userInfo}
      />
    ),
    5: <GroupDetailSearchProject data={data.projectList} isSearch={isSearch} />,
  };

  return (
    <GroupDetailSearchContainer>
      <ul className="tabs">
        {tabs.map((item, idx) => (
          <li onClick={() => tabChange(idx)} className={active === idx ? "active" : ""}>
            {item.name}
          </li>
        ))}
      </ul>
      <>
        {active !== 1 && (
          <GroupDetailSearchForm
            searchWord={searchWord}
            setSearchWord={setSearchWord}
            handleSearch={handleSearch}
          />
        )}
        {componentMap[active]}
      </>
    </GroupDetailSearchContainer>
  );
};

export default GroupDetailSearch;
