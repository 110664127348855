import React, { useEffect } from "react";

import { DetailsContentContainer } from "../../styled/pickup";

import Progress from "../../common/progress";
import Requests from "../../common/requests";
import OrderMenu from "../../common/orderMenu";
import CustomerInfo from "../../common/customerInfo";

const DetailsContent = ({ selected }) => {
  useEffect(() => {
    console.log(selected);
  }, []);

  return (
    <DetailsContentContainer className={selected?.status}>
      {selected ? (
        <>
          <div className="pickupInfo">
            <span className="label">픽업정보</span>
            <Progress selected={selected} />
          </div>
          <Requests remark={selected?.reqTerm} />
          <OrderMenu products={selected?.products} selected={selected} />
          {selected.status !== "PICKUP" && <CustomerInfo selected={selected} />}
        </>
      ) : (
        <div className="notOrder">
          <div className="bg">선택된 주문이 없습니다.</div>
        </div>
      )}
    </DetailsContentContainer>
  );
};

export default DetailsContent;
