import React from "react";
import { HeaderContainer } from "../styled/common";
import { IoArrowBackSharp } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router";

const Header = ({ title, handleBackButton }) => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <button onClick={() => handleBackButton()}>
        <IoArrowBackSharp />
      </button>
      <h2>{title}</h2>
      <button onClick={() => navigate("/self-service")}>
        <IoMdClose />
      </button>
    </HeaderContainer>
  );
};

export default Header;
