export default function MyPageQnaTitle(){
  return(
    <div className="inner_title">
      <span className="font_48 font600 font_color_black pdb_40">{service.qna}</span>
      <span className="font_30 font300 font_color_black pdb_40">
        {service.lComment2}
      </span>
      {/* <ul class="inner_title_menu">
      <li class="actived"><a href="">꿀팁</a></li>
      <li><a href="">회원가입</a></li>
      <li><a href="">요금</a></li>
      <li><a href="">이용</a></li>
      <li><a href="">기타</a></li>
  </ul> */}
  </div>
  )
}