import React from "react";
import { isStorageType, storageType } from "../../../function/kiosk";
import { IoCaretDown, IoCaretUp } from "react-icons/io5";
import { HiMinus, HiPlus } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";

const ReserveForm = ({
  formData,
  total,
  handleChange,
  handleReserve,
  quantity,
  active,
  transformValue,
  setChosenStorage,
  chosenStorage,
  setTotal,
  calculateTotal,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width:820px)" });

  const updateTotal = (storage) => {
    setTotal(calculateTotal(storage));
  };

  const handleButtonClick = (index, delta) => {
    setChosenStorage((prev) => {
      const updatedStorage = {
        ...prev,
        [index]: Math.max(0, Math.min(500, prev[index] + delta)),
      };

      updateTotal(updatedStorage);
      return updatedStorage;
    });
  };

  const handleInputChange = (index, value) => {
    const num = parseInt(value, 10);
    setChosenStorage((prev) => {
      const updatedStorage = {
        ...prev,
        [index]: isNaN(num) ? prev[index] : Math.max(0, Math.min(500, num)),
      };

      updateTotal(updatedStorage);
      return updatedStorage;
    });
  };

  return (
    <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
      <ul className="userInfoForm">
        <li>
          <span className="tit">이름</span>
          <div className="con">
            <label htmlFor="mberNm">
              <input
                type="text"
                name="mberNm"
                onChange={handleChange}
                value={formData.mberNm}
                placeholder="이름을 입력해주세요."
              />
            </label>
          </div>
        </li>
        <li>
          <span className="tit">전화번호</span>
          <div className="con">
            <label htmlFor="tel">
              <input
                type="text"
                name="tel"
                onChange={handleChange}
                value={formData.tel}
                placeholder="전화번호를 입력해주세요."
              />
            </label>
          </div>
        </li>
        <li className="time">
          <span className="tit">보관시간</span>
          <div className="con">
            <span>3시간</span>
          </div>
        </li>
        <li className="storage">
          <span className="tit">보관방법</span>
          <div className="storageType">
            {Object.entries(storageType).map(([index, { label, key }]) => (
              <div className="box">
                <div className={`${key} bg`} key={index}>
                  <span>{label}</span>
                </div>
                <div className="storageNumber">
                  {!isMobile && (
                    <button onClick={() => handleButtonClick(index, -1)}>
                      <HiMinus />
                    </button>
                  )}
                  <input
                    type="number"
                    value={chosenStorage[index]}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                  {!isMobile && (
                    <button onClick={() => handleButtonClick(index, 1)}>
                      <HiPlus />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </li>
        <li className="quantity">
          <span className="tit">메모</span>
          <div className="con">
            <label htmlFor="memo">
              <input
                type="text"
                name="memo"
                onChange={handleChange}
                value={formData.memo}
                placeholder="메모를 입력해주세요. (최대 20자)"
                maxLength={20}
              />
            </label>
          </div>
        </li>
        <li className="paymentMethod">
          <span className="tit">결제방식</span>
          <div className="con">
            <label htmlFor="card">
              <input
                type="radio"
                name="payType"
                id="card"
                value="1"
                checked={formData.payType === "1"}
                onChange={handleChange}
              />
              <span className="checkmark"></span>
            </label>
            <span>카드</span>
            <label htmlFor="cash">
              <input
                type="radio"
                name="payType"
                id="cash"
                value="2"
                checked={formData.payType === "2"}
                onChange={handleChange}
              />
              <span className="checkmark"></span>
            </label>
            <span>현금</span>
          </div>
        </li>
        <li className="quantity">
          <span className="tit">총 수량</span>
          <div className="con">
            <span>{quantity}개</span>
          </div>
        </li>
        <li>
          <span className="tit">결제금액</span>
          <div className="con">
            <span>{total.toLocaleString()}원</span>
          </div>
        </li>
      </ul>
      <button className="submit" onClick={() => handleReserve()}>
        저장
      </button>
    </div>
  );
};

export default ReserveForm;
