import React from "react";
import { IoSearch } from "react-icons/io5";
import { CustomerListContainer } from "../../../styled/storage";
import { useRegex } from "../../../../../../../hooks/useRegex";

const CustomerList = ({
  transformValue,
  handleStorage,
  handleInputChange,
  waitings,
  searched,
  handleCancel,
  handleSearch,
}) => {
  const { TelFormat } = useRegex();

  return (
    <CustomerListContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="search">
        <input
          type="text"
          placeholder="전화번호 뒷자리를 입력해주세요."
          onChange={handleInputChange}
          value={searched}
          maxLength={4}
          onKeyDown={(e) => e.key === "Enter" && handleSearch()}
        />
        <button onClick={handleSearch}>
          <IoSearch />
        </button>
      </div>
      <div className="customers">
        {waitings && waitings.length > 0 ? (
          <ul>
            {waitings.map((item) => (
              <li>
                <div className="info">
                  <span className="idx">{item.id}</span>
                  <span className="name">{item.mberNm}</span>
                  <span className="tel">{TelFormat(item.tel)}</span>
                </div>
                <div className="buttons">
                  <button onClick={() => handleCancel(item)}>예약취소</button>
                  <button onClick={() => handleStorage(item)}>보관하기</button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div>예약한 고객이 존재하지 않습니다.</div>
        )}
      </div>
    </CustomerListContainer>
  );
};

export default CustomerList;
