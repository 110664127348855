import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Pagination from "react-js-pagination";

import { PaginationContainer } from "../../styled/shopping";

import Loading from "../../common/ShopLoading";
import useQueryString from "../../../../hooks/useQueryString";

const PortfolioList = () => {
  const navigater = useNavigate();
  const queryPage = Number(useQueryString("page"));
  const { theme } = useOutletContext();

  const [isLoading, setIsLoading] = useState(true);
  const [portfolio, setPortfolio] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { cmpnyNo, url } = useOutletContext();
  const [currentPage, setCurrentPage] = useState(queryPage || 0);

  const apiUrl = "/api/portMgmtList";

  useEffect(() => {
    if (cmpnyNo) {
      getPortfolio();
    } else {
      setIsLoading(false);
    }
  }, [cmpnyNo]);

  useEffect(() => {
    navigater(`/shop/${url}/portfolio?page=${currentPage}`);
  }, [currentPage]);

  const getPortfolio = async (page = queryPage || 0) => {
    setCurrentPage(page);
    const body = {
      cmpnyNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 12 * page,
      pageNumber: page,
      pageSize: 12,
      paged: true,
    };
    const response = await axios.post(apiUrl, body);
    const res = response.data.content;
    setIsLoading(false);
    setPortfolio(res);
    setTotalCount(response?.data?.totalElements);
  };

  const moveToPage = (portNo) => {
    navigater(`/shop/${url}/portfolioView/${portNo}`, { state: { page: currentPage } });
  };

  const nextPage = (num) => {
    getPortfolio(num - 1);
  };

  return (
    <main id="contProductsList" className="sub_page sub_page_mobile_mt">
      <div className="subTop_bnr subTop_bnr_01">{theme !== "A" && <span>PORTFOLIO</span>}</div>
      <article className="boundary pdt_70 pdb_70" style={{ maxWidth: 1400, width: "100%" }}>
        <h2 className="font_36 pdb_30 font600 text_center">포트폴리오</h2>
        <section id="secBrdcrmb" className="font_14 pdb_20 text_right">
          <Link to={`/shop/${url}`}>
            <i className="xi-home-o font_20"></i>
          </Link>
          <span>포트폴리오</span>
        </section>
        <section id="secListTop" className="font_14">
          <div className="list_info font_color_gray_light">
            <div className="count">
              <i className="fa fa-bars"></i> 포트폴리오
              <strong className="font_color_red"> {totalCount}</strong>개가 등록되어 있습니다.
            </div>
          </div>
        </section>
        <section
          id="secList"
          className="pdt_30"
          style={{ justifyContent: isLoading ? "center" : "normal" }}>
          {portfolio.length !== 0 &&
            portfolio?.map((item, index) => (
              <div className="col_3" key={index}>
                <a onClick={() => moveToPage(item.portNo)}>
                  <div className="img">
                    <img src={imgHandler(item.imgUrl)} alt="" onError={onErrorImg} />
                  </div>
                  <div>
                    <p className="font_18 font600 pdt_10 pdb_20" style={{ textAlign: "center" }}>
                      {item.title}
                    </p>
                  </div>
                </a>
              </div>
            ))}
          {portfolio.length !== 0 && (
            <PaginationContainer>
              <Pagination
                activePage={currentPage + 1}
                itemsCountPerPage={12}
                totalItemsCount={totalCount}
                pageRangeDisplayed={5}
                prevPageText={"‹"}
                nextPageText={"›"}
                onChange={nextPage}
              />
            </PaginationContainer>
          )}
          {isLoading && <Loading />}
        </section>
      </article>
    </main>
  );
};

export default PortfolioList;
