import styled from "styled-components";

export const PolicyContainer = styled.div`
  min-height: calc(100vh - 400px);
  width: 100vw;
  font-size: 20px;
  .main {
    margin: 100px auto;
    max-width: 1400px;
    width: 100%;
  }
  h2 {
    font-size: 60px;
    text-align: center;
    margin-bottom: 30px;
  }
  .tabs ul {
    li {
      display: inline-block;
      margin-right: 20px;
      padding: 10px 30px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.3s;
      &.active {
        background: #000;
        color: #fff;
      }
    }
  }
  .container {
    font-size: 30px;
    padding-bottom: 30px;
    .info {
      margin: 70px 0 30px;
      font-weight: 700;
    }
    .content {
      border: 1px solid #ddd;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      font-size: 16px;
      padding: 30px;
      border-radius: 15px;
    }
  }
`;
