import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../../../../../common/modal";

import { setProductIndex } from "../../../../../store/compoIndex";
import { ProductAddFormInfo, ProductAddFormSubmitButton } from "../styled/productAddForm";
import ExcelFileActions from "./productAddForm/excelFileActions";
import ProductInfoForm from "./productAddForm/productInfoForm";
import uuid from "react-uuid";
import OptionCreateModal from "./productAddForm/modal/optionCreateModal";
import ConfirmModal from "./productAddForm/modal/confirmModal";
import BrandSearchModal from "./productAddForm/modal/brandSearchModal";

export default function ProductAddForm({ categoriesObj, getProduct }) {
  const [openModal, setOpenModal] = useState(false);
  const [productXlsx, setProductXlsx] = useState();
  const [product, setProduct] = useState({
    name: "",
    basePrice: 0,
    brand: "",
    specifications: "",
    description: "",
    media: [],
    categoryId: null,
  });
  const [options, setOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [modalType, setModalType] = useState("");

  const user = useSelector((state) => state?.user?.data[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(product);
  }, [product]);

  // 모달
  const handleModalState = (type) => {
    console.log(type);
    if (type) {
      setModalType(type);
    }
    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCategory();
  }, []);

  // 카테고리 조회
  const getCategory = async () => {
    const url = "/api/categories";
    const res = await axios.get(url);

    if (res.status === 200) {
      setCategories(res.data);
    }
  };

  // 이미지 추가
  const handleAddImage = (e) => {
    const files = Array.from(e.target.files);
    if (product.media.length + files.length > 9) {
      toast.error("이미지는 최대 10장까지 첨부 가능합니다.");
      return;
    }

    if (files.length > 0) {
      const updatedFiles = files.map((file) => ({
        file,
        path: URL.createObjectURL(file),
        id: uuid(),
      }));

      setProduct((prevData) => ({
        ...prevData,
        media: [...prevData.media, ...updatedFiles],
      }));
    }
  };

  // 엑셀 추가
  const handleAddXlsx = (e) => {
    try {
      console.log("File input changed");
      const file = e.target.files[0];

      if (!file) {
        throw new Error("파일이 선택되지 않았습니다.");
      }

      const validExtensions = ["xlsx", "xls"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!validExtensions.includes(fileExtension)) {
        throw new Error("엑셀 파일만 업로드할 수 있습니다.");
      }

      console.log(file);
      setProductXlsx(file);
      handleModalState("excel");
    } catch (error) {
      console.error(error.message);
      toast.error(error.message);
    }
  };

  // 파일 업로드
  const uploadFiles = async (files) => {
    const form = new FormData();

    files.forEach((item) => {
      form.append("files", item.file, item.file.name);
    });

    form.append("ids", "N");
    form.append("mberNo", user.mberNo);

    const url = "/api/uploadFiles";
    const res = await axios.post(url, form);

    const response = res?.data?.responseData;
    return response && response.length > 0 ? response.map((item) => item.fileName) : null;
  };

  // 상품 등록
  const createProducts = async (files) => {
    console.log(product);
    console.log(files);

    const url = "/erp/cloudProducts/create";
    const body = { ...product, media: [], createCmpnyNo: user.cmpnyNo };

    if (files && files.length > 0) {
      body.media = files;
    }

    const res = await axios.post(url, body);
    if (res.status === 200) {
      const data = res.data;
      await syncProducts(data);
    }
  };

  // 상품 연동
  const syncProducts = async (item) => {
    if (!item) return;

    const url = "/erp/vendorProducts/create";
    const body = {
      cmpnyNo: user?.cmpnyNo,
      cloudProductId: [parseInt(item.id, 10)],
    };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        toast.success("상품이 등록되었습니다.");
        dispatch(setProductIndex(0));
        await getProduct();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 상품 저장
  const handleSubmit = async () => {
    if (!validationCheck()) {
      return;
    }

    let files;
    if (product.media && product.media.length) {
      files = await uploadFiles(product.media);
    }

    await createProducts(files);
  };

  // 유효성 체크
  const validationCheck = () => {
    if (!product.name) {
      toast.error("상품명을 입력해주세요.");
      return false;
    }

    if (!product.basePrice) {
      toast.error("소비자가를 입력해주세요.");
      return false;
    }

    return true;
  };

  // 엑셀 업로드
  const handleExcelUpload = async () => {
    const form = new FormData();

    form.append("file", productXlsx);
    form.append("userId", user.userName);

    const url = "/api/excel";
    try {
      const res = await axios.post(url, form);

      if (res.status === 200) {
        toast.success("엑셀이 등록되었습니다.");
        dispatch(setProductIndex(0));
      }
    } catch (error) {
      toast.error("해당 엑셀을 등록할 수 없습니다.");
      setOpenModal(false);
    }
  };

  // 이미지 순서 변경 로직
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderArray = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    setProduct((prevProduct) => ({
      ...prevProduct,
      media: reorderArray(prevProduct.media, result.source.index, result.destination.index),
    }));
  };

  // 입력 변경
  const changeInput = (e) => {
    const { name, value } = e.target;
    setProduct((data) => ({ ...data, [name]: value }));
  };

  // 이미지 삭제
  const handleDeleteImage = (item) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      media: prevProduct.media.filter((img) => img.id !== item.id),
    }));
  };

  // 모달
  const getModalContent = () => {
    switch (modalType) {
      case "excel":
        return (
          <ConfirmModal
            message="엑셀"
            setOpenModal={setOpenModal}
            handleUpload={handleExcelUpload}
          />
        );
      case "brand":
        return <BrandSearchModal />;

      default:
        return null;
    }
  };

  return (
    <div className="hp_step_content">
      <div className="hp_step_product_step">
        <ProductAddFormInfo>
          <span className="font_16 font400 text_left font_color_gray">
            등록할 제품의 정보를 입력해주세요.
            <strong className="font_color_1 font400">*항목은 필수값이니 꼭 입력해주세요.</strong>
          </span>
          <ExcelFileActions handleAddXlsx={handleAddXlsx} />
        </ProductAddFormInfo>
        <div>
          <div>
            <ProductInfoForm
              handleAddImage={handleAddImage}
              handleModalState={handleModalState}
              product={product}
              setProduct={setProduct}
              categories={categories}
              handleDeleteImage={handleDeleteImage}
              onDragEnd={onDragEnd}
              changeInput={changeInput}
              categoriesObj={categoriesObj}
            />
          </div>
          <ProductAddFormSubmitButton onClick={handleSubmit}>완료</ProductAddFormSubmitButton>
        </div>
        <Modal
          modalState={openModal}
          handleModalState={handleModalState}
          html={getModalContent()}
          h="auto"
        />
      </div>
    </div>
  );
}
