import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NotDataList } from "../../../../styled/common";
import { ProductItem } from "../groupDetailSearchItem";

const GroupDetailSearchProduct = ({ productList, cmpnys }) => {
  const [imageHeight, setImageHeight] = useState();
  const user = useSelector((state) => state?.user?.data[0]);

  const updateImageSize = () => {
    const previewWidth = document.querySelector(".preview")?.offsetWidth;
    setImageHeight(previewWidth);
  };

  useEffect(() => {
    updateImageSize();
    window.addEventListener("resize", updateImageSize);

    return () => {
      window.removeEventListener("resize", updateImageSize);
    };
  }, []);

  return (
    <div className="productList">
      {productList.length > 0 ? (
        <ul>
          {productList.map((item) => (
            <ProductItem
              item={item}
              cmpnys={cmpnys}
              imageHeight={imageHeight}
              updateImageSize={updateImageSize}
            />
          ))}
        </ul>
      ) : (
        <NotDataList>상품이 존재하지 않습니다.</NotDataList>
      )}
    </div>
  );
};

export default GroupDetailSearchProduct;
