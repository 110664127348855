import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { ProjectEditorContainer } from "../common/styled/project";
import ImageUploadAdapterPlugin from "../../../homepageSetting/component/utils/ImageUploadAdapter";
import { IoClose } from "react-icons/io5";

const ProjectCreateSecEditor = ({
  formData,
  setFormData,
  changeInput,
  handleFocus,
  handleBlur,
  handleImportFile,
  ckeditorInstanceRef,
  files,
  handleDeleteFile,
}) => {
  const handleEditorChange = (_, editor) => {
    const newContent = editor.getData();
    setFormData((data) => ({ ...data, content: newContent }));
  };

  return (
    <ProjectEditorContainer>
      <input
        type="text"
        placeholder={`공지명을 입력해주세요.${
          formData.gbn === "C" ? " 모든 업체에게 표시되는 내용입니니다." : ""
        }`}
        name="title"
        onChange={changeInput}
        value={formData.title}
        onFocus={() => handleFocus("title")}
        onBlur={() => handleBlur("title")}
      />
      <CKEditor
        editor={ClassicEditor}
        data={formData.content}
        onChange={handleEditorChange}
        onFocus={() => handleFocus("content")}
        onBlur={() => handleBlur("content")}
        config={{
          extraPlugins: [ImageUploadAdapterPlugin],
          alignment: {
            options: ["center"],
          },
          toolbar: ["bold", "italic", "|", "link", "imageUpload", "mediaEmbed"],
          uiSettings: {
            height: "500px",
          },
        }}
        ref={ckeditorInstanceRef}
      />
      <div className="fileImport">
        <label>
          파일찾기
          <input type="file" onChange={handleImportFile} multiple />
        </label>
        <ul>
          {files &&
            files.length > 0 &&
            files.map((item) => (
              <li>
                <span>{item.name}</span>
                <button onClick={() => handleDeleteFile(item)}>
                  <IoClose />
                </button>
              </li>
            ))}
        </ul>
      </div>
    </ProjectEditorContainer>
  );
};

export default ProjectCreateSecEditor;
