import { useEffect, useState } from "react";

import MemberBanner from "./memberBanner";
import MemberSearch from "./component/memberSearch";
import Members from "./component/members";

import axios from "axios";

const Member = () => {
  const [members, setMembers] = useState("");
  const [swiperMembers, setSwiperMembers] = useState("");
  const [noSearchResult, setNoSearchResult] = useState("");
  const [totalMber, setTotalMber] = useState(0);

  useEffect(() => {
    getShopsInfo(true);
  }, []);

  // 회원사 정보 조회
  const getShopsInfo = async (flag, page = 0, keyword) => {
    if (page) {
      page -= 1;
    }
    const url = "/api/cmpnyIntroduce";
    const body = {
      offset: page * 8,
      pageNumber: page,
      pageSize: 8,
      paged: true,
    };
    if (keyword) {
      body.keyword = keyword;
    }
    const res = await axios.post(url, body);
    if (res.data.content.length !== 0) {
      if (flag) {
        // swiper gbn
        setSwiperMembers(res.data);
        setTotalMber(res.data.totalElements);
      }
      setMembers(res.data);
      setNoSearchResult("");
    } else {
      noSearch();
      setMembers(res.data);
    }
  };

  const noSearch = () => {
    const html = "<div>검색된 결과가 없습니다.</div>";
    setNoSearchResult({ __html: html });
  };

  return (
    <div>
      <MemberBanner />
      <div className="inner_content" id="member_wrap">
        <Members members={swiperMembers} totalMber={totalMber} />
        <MemberSearch
          members={members}
          getShopsInfo={getShopsInfo}
          noSearchResult={noSearchResult}
        />
      </div>
      {/* } 콘텐츠 끝 */}
    </div>
  );
};

export default Member;
