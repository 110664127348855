import React from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { Link } from "react-router-dom";

const NotifyHeader = ({
  isSetMenuOpen,
  setIsSetMenuOpen,
  settingMenuClose,
  isDelete,
  setIsDelete,
  readAllNotis,
  active,
}) => {
  return (
    <div className="notifyHeader">
      <div className="notifyInner">
        <div className="title">
          <Link to={"/"}>
            <img src="/img/logo_monoti.png" />
          </Link>
        </div>
        {active === 0 && (
          <div className="msgSetting">
            {isDelete ? (
              <button className="cancel" onClick={settingMenuClose}>
                취소
              </button>
            ) : (
              <div className="settingBtnArea">
                <button onClick={() => setIsSetMenuOpen(!isSetMenuOpen)}>
                  <AiOutlineSetting />
                </button>
                {isSetMenuOpen && (
                  <div className="setMenus">
                    <ul>
                      <li>
                        <button onClick={readAllNotis}>모두읽음</button>
                      </li>
                      <li>
                        <button onClick={() => setIsDelete(true)}>알림삭제</button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotifyHeader;
