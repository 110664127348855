import React, { useState } from "react";

import Provision from "./component/provision";
import Privacy from "./component/privacy";
import Refund from "./component/refund";

import { PolicyContainer } from "./component/styled/policy";

const Policy = () => {
  const tabs = ["이용약관", "환불정책", "개인정보처리방침"];
  const [active, setActive] = useState(0);

  const getComponent = () => {
    return [<Provision />, <Refund />, <Privacy />][active];
  };

  return (
    <PolicyContainer>
      <div className="main">
        <h2>약관</h2>
        <div className="tabs">
          <ul>
            {tabs.map((item, idx) => (
              <li className={idx === active ? "active" : "inactive"} onClick={() => setActive(idx)}>
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="container">
          <div className="info">{tabs[active]}</div>
          <div className="content">{getComponent()}</div>
        </div>
      </div>
    </PolicyContainer>
  );
};

export default Policy;
