import FaqViewForm from "./component/faqPage/faqViewForm";
import NoticeBanner from "./noticeBanner";

export default function QnaView() {
  return (
    <>
      <NoticeBanner />
      <FaqViewForm />
    </>
  );
}
