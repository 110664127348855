import React, { useEffect, useState } from "react";
import { parseHTMLContent } from "../../function/shopping";
import { useNavigate } from "react-router";

const Introduce = ({ homepageInfo, url }) => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({ image: null, text: null });

  useEffect(() => {
    if (homepageInfo && homepageInfo.cmpnyIntroduce) {
      const cmpnyIntroduce = parseHTMLContent(homepageInfo.cmpnyIntroduce);
      setInfo({ ...cmpnyIntroduce });
    }
  }, [homepageInfo]);

  const goToCmpny = () => {
    navigate(`/shop/${url}/company`);
  };

  return (
    <div className="introduce">
      <div className="info">Introduce</div>
      <div className="introduceContent" onClick={goToCmpny}>
        <img src={info.image ? info.image : "/img/shop_main_bg3.jpg"} alt="" />
      </div>
      <div className="introduceContent" onClick={goToCmpny}>
        <div className="content">
          {info.text ? (
            <span className="con" dangerouslySetInnerHTML={{ __html: info.text }}></span>
          ) : (
            <span className="con">
              안녕하세요,
              <br />
              {homepageInfo.cmpnyNm}입니다.
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Introduce;
