import MypageBanner from "../mypageBanner";
import RegisterPortViewForm from "./component/register/registerPortViewForm";

export default function RegisterPortView() {
  return (
    <>
      <MypageBanner />
      <RegisterPortViewForm />
    </>
  );
}
