import React from "react";
import MypageBanner from "../mypageBanner";
import HomePageSettingMenu from "./homepageSettingMenu";
import RegisterTitle from "./component/register/registerTitle";
import RegisterMenu from "./component/register/registerMenu";
import RegisterForm from "./component/register/registerForm";
import RegisterIntroForm from "./component/register/registerIntroForm";
import RegisterPortForm from "./component/register/registerPortForm";
import RegisterNoticeForm from "./component/register/registerNoticeForm";

import { useSelector } from "react-redux";

export default function Register() {
  const index = useSelector((state) => state.compoIndex.register);

  return (
    <>
      <MypageBanner />
      <div className="inner_content">
        <HomePageSettingMenu />
        <RegisterTitle />
        <RegisterMenu index={index} />
        <Components index={index} />
      </div>
    </>
  );
}

function Components({ index }) {
  return (
    <>
      {
        [<RegisterForm />, <RegisterIntroForm />, <RegisterPortForm />, <RegisterNoticeForm />][
          index
        ]
      }
    </>
  );
}
