import React from "react";

const BrandSearchModal = ({ setModalState }) => {
  return (
    <div className="">
      <div className="">
        <h2 className="font_20 font500 font_color_white">브랜드 조회</h2>
        <button onClick={() => setModalState(false)} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          취급하는 제품의 브랜드를 선택해주세요.
        </span>
        <div className="hp_step_setting_wrap_input">
          <input type="text" defaultValue placeholder="카테고리를 입력하세요." />
          <button type="button">조회</button>
        </div>
        <table className="table_sm_list work_place hp_step">
          <colgroup>
            <col style={{ width: "25%" }} />
            <col style={{ width: "75%" }} />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">
                <span className="font_18 font300 font_color_gray text_center">선택</span>
              </th>
              <th scope="col">
                <span className="font_18 font300 font_color_gray text_center">브랜드</span>
              </th>
            </tr>
          </thead>
          <tbody id="place_tbody">
            <tr>
              <td>
                <input type="checkbox" />
              </td>
              <td>
                <span className="font_18 font300 font_color_gray text_center">입력칸</span>
              </td>
            </tr>
          </tbody>
        </table>
        <button type="button" className="hp_step_setting_cate_save">
          저장하기
        </button>
      </div>
    </div>
  );
};

export default BrandSearchModal;
