import React from "react";
import { CiAlarmOn, CiGrid42, CiHashtag, CiMap, CiTextAlignLeft } from "react-icons/ci";
import { PiProjectorScreenChartLight, PiUser, PiUsersFour } from "react-icons/pi";
import { isEventCategory, showEventTime } from "../function/scheduler";
import EventListItem from "../common/eventListItem";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const MobileEventDetailView = ({ item, isDday }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);

  return (
    <ul className="list font_16">
      <li>
        <div className="tit">
          <CiHashtag />
          <span>제목</span>
        </div>
        <div className="content" style={{ display: "flex", alignItems: "center", gap: 5 }}>
          {item.icon && (
            <img src={`/img/scheduler/scheduler_icon_${item.icon}`} alt="" style={{ width: 20 }} />
          )}
          <span style={{ textDecoration: item.completeYn === "Y" && "line-through" }}>
            {item.title}
          </span>
        </div>
      </li>
      <EventListItem icon={<CiAlarmOn />} title="날짜">
        {isDday
          ? moment(item.startDt).format("YYYY-MM-DD")
          : showEventTime(item.startDt, item.endDt)}
      </EventListItem>
      <EventListItem icon={<CiGrid42 />} title="구분">
        <span>{`${isEventCategory(item.gbn)}일정`}</span>
        {item.gbn !== "P" && (
          <span style={{ display: "block", fontWeight: 700 }}>{item.orgNm}</span>
        )}
      </EventListItem>
      <EventListItem icon={<CiMap />} title="위치" addClass="place">
        {item.place && item.place}
        {item.placeDetail && ` ${item.placeDetail}`}
        {!item.placeDetail && !item.place && "주소가 존재하지 않습니다"}
      </EventListItem>
      {item.gbn !== "P" && (
        <>
          {item.projectNo > 0 && item.projectNm && (
            <EventListItem icon={<PiProjectorScreenChartLight />} title="프로젝트">
              <span
                onClick={() => navigate(`/mypage/project/${item.projectNo}`)}
                style={{ cursor: "pointer" }}>
                {item.projectNm}
              </span>
            </EventListItem>
          )}
          <EventListItem icon={<PiUser />} title={item.gbn !== "G" ? "담당자" : "주최자"}>
            {item.managerNm}
          </EventListItem>
          <EventListItem
            icon={<PiUsersFour />}
            title={item.gbn !== "G" ? "실무자" : "참여자"}
            addClass="workers">
            {item.workerList &&
              Object.entries(item.workerList).map(([key, worker], i, arr) => (
                <div className="worker" key={i}>
                  <span
                    style={
                      user.mberNo === parseInt(key)
                        ? { fontWeight: 600, color: isDarkMode ? "#bb86fc" : "#0074ca" }
                        : null
                    }>
                    {worker}
                  </span>
                  {i !== arr.length - 1 && ", "}
                </div>
              ))}
          </EventListItem>
        </>
      )}
      <EventListItem icon={<CiTextAlignLeft />} title="메모" addClass="memo">
        {item.memo && item.memo.includes("\n")
          ? item.memo.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))
          : item.memo}
      </EventListItem>
    </ul>
  );
};

export default MobileEventDetailView;
