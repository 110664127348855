import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import compoIndex from "./compoIndex";
import product from "./product";
import productSync from "./productSync";
import productCode from "./productCode";
import productSyncCode from "./productSyncCode";
import user from "./user";
import scheduler from "./scheduler";
import loading from "./loading";

const rootReducer = combineReducers({
  compoIndex: compoIndex.reducer,
  user: user.reducer,
  product: product.reducer,
  productSync: productSync.reducer,
  productCode: productCode.reducer,
  productSyncCode: productSyncCode.reducer,
  scheduler: scheduler.reducer,
  loading: loading.reducer,
});

const persistConfig = {
  key: "root",
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
