import MypageBanner from "../mypageBanner"; 
import MyPageQnaTitle from "./component/myPageQnaTitle";
import BoardList from "../../../common/boardList";

const MyQna = () => {
  const BoardListProps = {
    button: true,
    search: true,
    title: "1:1문의"
  }

  return (
    <div>
      <MypageBanner />
      <div className="inner_content">
        <MyPageQnaTitle />
        <BoardList BoardListProps={BoardListProps}/>,
      </div>
    </div>
  );
};

export default MyQna;

