import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import axios from "axios";

import { IoMdSearch } from "react-icons/io";

import Modal from "../../../../../../common/modal";
import {
  GroupDetailSearchFormContainer,
  GroupDetailSettingMemberContainer,
  GroupDetailSettingMembershipContainer,
} from "../../../styled/group";

const GroupDetailSettingMembership = ({ grp, getGrpMbers }) => {
  const { id } = useParams();
  const [modalData, setModalData] = useState({ items: [], state: null });
  const [applyMbers, setApplyMbers] = useState([]);
  const [selected, setSelected] = useState(1);
  const [text, setText] = useState("");
  const [chkMbers, setChkMbers] = useState({});
  const [mberTotal, setMberTotal] = useState();
  const user = useSelector((state) => state?.user?.data[0]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  useEffect(() => {
    getApplyMber(getApplyMberCond(1));
  }, []);

  // 가입 대기 멤버 조회 조건
  const getApplyMberCond = (num = 1) => {
    const groupNo = Number(id);
    const pageSize = 10;
    const pageNumber = num - 1;

    const cond = {
      groupNo,
      delYn: "N",
      approvalYn: "N",
      offset: pageNumber * pageSize,
      pageNumber: pageNumber,
      pageSize,
      paged: true,
    };

    if (isNaN(text)) cond.mberNm = text;
    // if (!isNaN(text))
    return cond;
  };

  // 가입 대기 멤버 조회
  const getApplyMber = async (cond) => {
    const url = "/api/groupMberList";
    const body = { ...cond };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        setApplyMbers(res.data.content);
        const initiChkState = {};
        res.data.content.forEach((item) => {
          initiChkState[item.mberNo] = false;
        });
        setChkMbers(initiChkState);
        setMberTotal(res.data.totalElements);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 승인, 거절 클릭 시
  const onSubmit = async () => {
    const { items, state } = modalData;

    if (!items || items.length < 1) return;

    const requests = items.map((item) => {
      const url = "/api/groupMber";
      const body = {
        seq: item.seq,
        groupNo: id,
        mberNo: item.mberNo,
        auth: 4,
        createMberId: user.userName,
        updateMberId: user.userName,
        delYn: state ? "N" : "Y",
        approvalYn: state ? "Y" : "N",
        isApproval: state ? "Y" : null,
      };

      if (item.nickNm && grp.nickUseYn === "Y") body.nickNm = item.nickNm;
      if (item.gbnValue && grp.gbn) body.gbnValue = item.gbnValue;

      return axios.put(url, body);
    });

    try {
      await Promise.all(requests);
      toast.success(`${state ? "수락" : "거절"}되었습니다.`);
      setModalData({ items: [], state: null });
      getApplyMber(getApplyMberCond(1));
      handleModalOpen();
      getGrpMbers(grp);
    } catch (error) {
      console.error(error);
      toast.error("처리 중 오류가 발생했습니다.");
    }
  };

  // 전체선택
  const handleSelectAll = (e) => {
    const newChkState = {};
    applyMbers.forEach((member) => {
      newChkState[member.mberNo] = e.target.checked;
    });
    setChkMbers(newChkState);
    setIsAllChecked(e.target.checked);
  };

  // 개별선택
  const handleSelect = (mberNo) => {
    setChkMbers((chkMbers) => ({
      ...chkMbers,
      [mberNo]: !chkMbers[mberNo],
    }));
  };

  // 검색, 페이지
  const onPageChange = (num) => {
    setSelected(num);
    getApplyMber(getApplyMberCond(num));
    setChkMbers({});
  };

  // 검색
  const onSearch = () => {
    if (!text) {
      toast.error("검색어를 입력해주세요.");
      return;
    }
    if (isNaN(text) && text.length < 2) {
      toast.error("멤버 이름을 좀 더 구체적으로 입력해주세요.");
      return;
    }
    const telreg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    if (!isNaN(text) && !telreg.test(text)) {
      toast.error("유효하지 않은 휴대폰 번호입니다.");
      return;
    }
    setSelected(1);
    getApplyMber(getApplyMberCond(1));
  };

  // 다중 데이터 보내기
  const onClickSubmitAll = (state) => {
    const selectMbers = [];
    applyMbers.forEach((item) => {
      if (chkMbers[item.mberNo]) {
        selectMbers.push(item);
      }
    });
    if (selectMbers.length < 1) {
      toast.error("선택된 멤버가 존재하지 않습니다.");
    }
    handleModalOpen(selectMbers, state);
  };

  const handleModalOpen = (items = [], state = null) => {
    if (items.length > 0) {
      setModalData({ items, state });
    }
    setModalOpen((modalOpen) => !modalOpen);
  };

  const ModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">{modalData.state ? "수락" : "거절"}</h2>
        <button onClick={handleModalOpen} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_20 font300 font_color_gray pdt_20 text_center">
          {modalData.items.length}명의 모임 가입을
          <br />
          {modalData.state ? "수락" : "거절"}하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={onSubmit}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  return (
    <GroupDetailSettingMembershipContainer>
      <div className="applyContent">
        <div className="apply">
          {applyMbers.length > 0 ? (
            <div>
              <GroupDetailSearchFormContainer>
                <input
                  type="text"
                  id="keyword"
                  name="keyword"
                  placeholder="닉네임 혹은 전화번호를 입력하세요."
                  onChange={(e) => setText(e.target.value)}
                  value={text}
                  onKeyDown={(e) => handleEnterKey(e, onSearch)}
                />
                <button onClick={onSearch}>
                  <IoMdSearch />
                </button>
              </GroupDetailSearchFormContainer>
              <table className="list">
                <thead>
                  <tr>
                    <th>
                      <input type="checkbox" checked={isAllChecked} onChange={handleSelectAll} />
                    </th>
                    <th>닉네임</th>
                    <th>승인</th>
                  </tr>
                </thead>
                <tbody>
                  {applyMbers.map((mber) => (
                    <tr key={mber.mberNo}>
                      <td>
                        <input
                          type="checkbox"
                          checked={chkMbers[mber.mberNo] || false}
                          onChange={() => handleSelect(mber.mberNo)}
                        />
                      </td>
                      <td>{mber.nickNm ? mber.nickNm : "닉네임 미설정"}</td>
                      <td>
                        <button onClick={() => handleModalOpen([mber], true)}>수락</button>
                        <button onClick={() => handleModalOpen([mber], false)}>거절</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {mberTotal > 10 && (
                <Pagination
                  activePage={selected}
                  itemsCountPerPage={10}
                  totalItemsCount={mberTotal}
                  pageRangeDisplayed={5}
                  prevPageText={"‹"}
                  nextPageText={"›"}
                  onChange={onPageChange}
                />
              )}
              <div className="btnArea" style={{ marginTop: 30 }}>
                <button onClick={() => onClickSubmitAll(true)}>수락</button>
                <button onClick={() => onClickSubmitAll(false)}>거절</button>
              </div>
            </div>
          ) : (
            <div
              style={{
                fontSize: 20,
                width: "100%",
                height: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              가입 신청한 멤버가 존재하지 않습니다.
            </div>
          )}
        </div>
      </div>
      <Modal
        modalState={modalOpen}
        handleModalState={handleModalOpen}
        html={ModalForm()}
        w={isMobile ? "70%" : "17%"}
        h="auto"
      />
    </GroupDetailSettingMembershipContainer>
  );
};

export default GroupDetailSettingMembership;
