const RegisterNoticeModal = ({handleNoticeModal}) => {
  return(
    <div>
      <div>
        <h2 className="font_20 font500 font_color_white">권한 설정</h2>
        <button onClick={handleNoticeModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          ※ 공지사항 및 이벤트 등은 관리자 전용으로 활용하세요.
        </span>
        <div className="hp_step_setting_wrap_input">
          <span className="font_16 font600 font_color_1 left">글쓰기 권한</span>
          <div className="writing_cont">
            <div className="radio_area">
              <label htmlFor="hp_write_permission" className="btn_radio mr10">
                <input type="radio" id="hp_write_permission" name="hp_write_permission"
                  defaultValue defaultChecked="checked" />
                <span className="hero">관리자 전용</span>
              </label>
              <label htmlFor="hp_write_permission2" className="btn_radio mr10">
                <input type="radio" id="hp_write_permission2" name="hp_write_permission" defaultValue />
                <span className="hero">모든사용자(스팸주의)</span>
              </label>
            </div>
          </div>
        </div>
        <button className="hp_step_setting_cate_save">저장하기</button>
      </div>
    </div>
  )
}
export default RegisterNoticeModal