import React, { useState } from "react";
import Hangul from "hangul-js";
import { KeyboardContainer } from "../styled/common";

// 천지인 키보드 키 배열
const keys = [
  "ㅣ",
  "ㆍ",
  "ㅡ",
  "ㄱㅋ",
  "ㄴㄹ",
  "ㄷㅌ",
  "ㅂㅍ",
  "ㅅㅎ",
  "ㅈㅊ",
  "지우기",
  "ㅇㅁ",
  "띄어쓰기",
];

const keyMap = {
  ㄱㅋ: ["ㄱ", "ㅋ", "ㄲ"],
  ㄴㄹ: ["ㄴ", "ㄹ"],
  ㄷㅌ: ["ㄷ", "ㅌ", "ㄸ"],
  ㅂㅍ: ["ㅂ", "ㅍ", "ㅃ"],
  ㅅㅎ: ["ㅅ", "ㅎ", "ㅆ"],
  ㅈㅊ: ["ㅈ", "ㅊ", "ㅉ"],
  ㅇㅁ: ["ㅇ", "ㅁ"],
};

const combineHangul = (jamoArray) => {
  const vowels = {
    ㅣㆍ: "ㅏ",
    ㅏㆍ: "ㅑ",
    ㆍㅣ: "ㅓ",
    ㆍㅓ: "ㅕ",
    ㆍㅡ: "ㅗ",
    ㆍㅗ: "ㅛ",
    ㅡㆍ: "ㅜ",
    ㅜㆍ: "ㅠ",
    ㅡㅣ: "ㅢ",
    ㅓㅣ: "ㅔ",
    ㅕㅣ: "ㅖ",
    ㅏㅣ: "ㅐ",
    ㅑㅣ: "ㅒ",
    ㅜㅣ: "ㅟ",
    ㅠㅣ: "ㅝ",
  };

  let result = jamoArray.join("");

  // 모음 조합 처리
  Object.keys(vowels).forEach((key) => {
    while (result.includes(key)) {
      result = result.replace(key, vowels[key]);
    }
  });

  return result;
};

const ChunjiinKeyboard = ({ handleChangeInput }) => {
  const [jamoBuffer, setJamoBuffer] = useState([]);
  const [keyPressCount, setKeyPressCount] = useState({});
  const [space, setSpace] = useState(false);

  const handleKeyPress = (key) => {
    if (key === "지우기") {
      handleBackspace();
    } else if (key === "띄어쓰기") {
      setSpace(true);
      setKeyPressCount({});
      setJamoBuffer([...jamoBuffer, ""]);
    } else {
      let newKey = key;
      let newBuffer = [...jamoBuffer];

      if (space) {
        if (newBuffer.length > 0 && newBuffer[newBuffer.length - 1] === "") {
          newBuffer[newBuffer.length - 1] = newKey.split("")[0];
        } else {
          newBuffer.push(newKey); // 새로운 자모 추가
        }
        setSpace(false);
      } else {
        if (!keyMap[key] || !keyMap[key].includes(newBuffer[newBuffer.length - 1])) {
          setKeyPressCount({});
        }
        if (keyMap[key]) {
          const count = keyPressCount[key] ? keyPressCount[key] + 1 : 1;
          const index = (count - 1) % keyMap[key].length;

          newKey = keyMap[key][index];
          setKeyPressCount({ ...keyPressCount, [key]: count });

          if (newBuffer.length > 0 && keyMap[key].includes(newBuffer[newBuffer.length - 1])) {
            newBuffer[newBuffer.length - 1] = newKey;
          } else {
            newBuffer.push(newKey);
          }
        } else {
          newBuffer.push(newKey);
        }
      }

      let combined = combineHangul(newBuffer);

      let disassembled = Hangul.disassemble(combined);
      if (disassembled.length >= 2 && Hangul.isConsonant(disassembled[disassembled.length - 1])) {
        const trailingConsonant = disassembled.pop();
        const assembled = Hangul.assemble(disassembled);
        newBuffer = [...Hangul.disassemble(assembled), trailingConsonant];
        combined = combineHangul(newBuffer);
      }

      handleChangeInput("mberNm", Hangul.assemble(Hangul.disassemble(combined)));
      setJamoBuffer(Hangul.disassemble(combined));
    }
  };

  // 지우기
  const handleBackspace = () => {
    if (jamoBuffer.length > 0) {
      const newBuffer = jamoBuffer.slice(0, -1);
      const combined = combineHangul(newBuffer);
      handleChangeInput("mberNm", Hangul.assemble(Hangul.disassemble(combined)));
      setJamoBuffer(Hangul.disassemble(combined));
    }
    setKeyPressCount({});
  };

  const handleConfirm = () => {
    setJamoBuffer([]);
    setKeyPressCount({});
    determineStepAction(true);
  };

  return (
    <KeyboardContainer>
      <div className="keyboard">
        {keys.map((key, index) => (
          <button key={index} onClick={() => handleKeyPress(key)}>
            {key}
          </button>
        ))}
      </div>
    </KeyboardContainer>
  );
};

export default ChunjiinKeyboard;
