// export { app, messaging };
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCEYTkFAwmsXCMNIAzYEj1OXLTrlFCBsdc",
  authDomain: "toktok-358ef.firebaseapp.com",
  projectId: "toktok-358ef",
  storageBucket: "toktok-358ef.firebasestorage.app",
  messagingSenderId: "946728950878",
  appId: "1:946728950878:web:77f10fc3c0b98283a168cf",
  measurementId: "G-VWB1XWMRTC"
};

const app = initializeApp(firebaseConfig);
let messaging;

try {
  messaging = getMessaging(app);

} catch (error) {
  console.error("Firebase Messaging initialization failed:", error);
}

export { app, messaging };
