import styled from "styled-components";

export const HomepageSettingContainer = styled.div`
  #step1_html {
    .writingList {
      width: 100%;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      align-items: center;
      li {
        width: calc(25% - 20px);
        text-align: center;
        font-size: 1rem;
        position: relative;
        display: flex;
        &:not(:first-child) {
          justify-content: space-between;
          flex-direction: column;
        }
        &:nth-child(1) {
          border: 1px solid #efefef;
          box-sizing: border-box;
          align-items: center;
          .file_import {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            label {
              margin-top: 10px;
              background: #fff;
              color: #333;
              height: 25px;
              line-height: 25px;
              cursor: pointer;
              font-size: 1.5rem;
            }
          }
        }
        .preview {
          width: 100%;
          position: relative;
          margin-bottom: 10px;
          img {
            width: 100%;
            object-fit: contain;
          }
          p {
            width: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-weight: 700;
          }
        }
        .writing_cont {
          input {
            width: 100%;
            box-sizing: border-box;
            margin: 0;
          }
        }
        .deleteArea,
        .dragArea {
          position: absolute;
          width: 25px;
          height: 25px;
          right: 0px;
          top: 0px;
          background: rgba(0, 0, 0, 0.7);
          z-index: 10;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          button {
            width: 100%;
            height: 100%;
            border: none;
            background: transparent;
            color: #fff;
          }
          svg {
            display: block;
            margin: auto;
          }
        }
      }
    }
    .saveAndEdit {
      button {
        border: none;
        margin: 0 10px;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    #step1_html .writingList li {
      width: 32%;
    }
  }
  @media screen and (max-width: 1050px) {
    #step1_html .writingList {
      justify-content: space-between;
    }
    #step1_html .writingList li {
      width: 48%;
    }
  }
  @media screen and (max-width: 767px) {
    #step1_html .writingList {
      justify-content: center;
    }
    #step1_html .writingList li {
      width: 100%;
    }
    .saveAndEdit button {
      width: 40%;
    }
  }
`;

export const RegisterFormContainer = styled.div`
  #step1_html {
    .writingList {
      li {
        display: flex;
        align-items: center;
        .isLogoTypeChk {
          display: inline-block;
          margin-bottom: 20px;
        }
        .file_import {
          display: flex;
          align-items: center;
          .preview {
            width: 110px;
            height: 54px;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        .timeArea {
          display: flex;
          label.btn_check {
            display: flex;
            align-items: center;
          }
          .startArea,
          .endArea {
            margin-right: 20px;
            display: flex;
            align-items: center;
            select {
              width: 40%;
              margin-right: 10px;
              margin-left: 15px;
            }
          }
        }
      }
    }
    .saveAndNext {
      text-align: center;
      button {
        border: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
    #step1_html .writingList li .timeArea {
      flex-direction: column;
    }
    #step1_html .writingList li .timeArea .startArea,
    #step1_html .writingList li .timeArea .endArea {
      margin-bottom: 5px;
      margin-right: 0;
    }
    #step1_html .writingList li .timeArea .startArea select,
    #step1_html .writingList li .timeArea .endArea select {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
`;

export const HomePageSettingList = styled.li`
  &:hover span,
  &.actived span {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    color: #fff;
    transition: 0.3s;
  }
`;
