import React, { useEffect, useRef, useState, useCallback } from "react";

import ShopLocatorInfo from "./shopLocatorInfo";
import ShopLocatorSwiper from "./shopLocatorSwiper";

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const StoreLocator = () => {
  const swiperRef = useRef();
  const [imageHeight, setImageHeight] = useState(0);
  const images = [
    ...Array.from({ length: 6 }, (_, idx) => idx),
    ...Array.from({ length: 6 }, (_, idx) => idx),
    ...Array.from({ length: 6 }, (_, idx) => idx),
    ...Array.from({ length: 6 }, (_, idx) => idx),
    ...Array.from({ length: 6 }, (_, idx) => idx),
    ...Array.from({ length: 6 }, (_, idx) => idx),
    ...Array.from({ length: 6 }, (_, idx) => idx),
  ].reduce((result, item, index) => {
    if (index % 2 === 0) result.push([]);
    result[result.length - 1].push(item);
    return result;
  }, []);

  const locator = { title: "상점", info: "물품을 파는 근처 상점을 알려드릴게요", type: "store" };

  const updateImageSize = useCallback(() => {
    const previewWidth = document.querySelector(".mySwiper.store .imageArea")?.offsetWidth;
    if (previewWidth) {
      setImageHeight(previewWidth);
    }
  }, []);

  useEffect(() => {
    updateImageSize();

    const handleResize = debounce(() => {
      updateImageSize();
      // updateSwiper();
    }, 300);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateImageSize]);

  return (
    <div className="inner">
      <ShopLocatorInfo locator={locator} />
      <ShopLocatorSwiper
        images={images}
        type={locator.type}
        swiperRef={swiperRef}
        imageHeight={imageHeight}
        updateImageSize={updateImageSize}
      />
    </div>
  );
};

export default StoreLocator;
