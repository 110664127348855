import React from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

const OrderActions = ({
  item,
  active,
  index,
  editPickupBy,
  pickupBy,
  openModal,
  completedOrder,
}) => {
  switch (active) {
    case "COMPLETED":
    case "PICKUP":
      return null;
    case "READY":
      return (
        <div className="action">
          <button
            className="notify"
            onClick={() =>
              openModal({
                type: "confirmation",
                title: "상품 준비 완료",
                message: ["상품 준비 완료 알림을 고객님께 보내시겠습니까?"],
                handleConfirm: () => completedOrder(item.id),
              })
            }>
            상품 준비 완료 알림
          </button>
        </div>
      );
    case "PENDING":
      return (
        <div className="action stepper">
          <button onClick={() => editPickupBy(index, false)}>
            <AiFillCaretDown />
          </button>
          <span className="current">{pickupBy[index]}분</span>
          <button onClick={() => editPickupBy(index, true)}>
            <AiFillCaretUp />
          </button>
        </div>
      );
    default:
      return null;
  }
};

export default OrderActions;
