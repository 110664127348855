import React, { useCallback, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";

const GroupList = ({ items, setMberHeight }) => {
  const navigate = useNavigate();
  const memberRef = useRef(null);
  const isInner = useMediaQuery({ query: "(max-width:1400px)" });

  const updateHeight = useCallback(() => {
    if (isInner && memberRef.current) {
      setMberHeight(memberRef.current.offsetHeight);
    }
  }, [isInner, setMberHeight]);

  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [items, isInner]);

  return (
    <div className="member_search groupMember">
      {items?.map((grp, i) => (
        <div key={i} className="member_search_wrap" ref={i === 0 ? memberRef : null}>
          <div className="member_info_img" onClick={() => navigate(`/group/${grp.groupNo}`)}>
            <img src={grp.imgUrl ? imgHandler(grp.imgUrl) : "/img/function_11_bg.png"} />
          </div>
          <span
            className="font_24 font300 font_color_black pdb_10 text_center member_info_top"
            style={{
              height: "40px",
              marginTop: "10%",
            }}>
            {grp.groupName}
          </span>
        </div>
      ))}
    </div>
  );
};

export default GroupList;
