import React from "react";
import { Confirmation, ModalBackground } from "../../styled/common";
import ModalHeader from "./modalHeader";

// 확인/닫기 버튼
const ConfirmationModal = ({
  title = "",
  message = [],
  handleConfirm = null,
  handleCancel = null,
}) => {
  return (
    <ModalBackground>
      <Confirmation>
        <ModalHeader title={title} handleCancel={() => handleCancel()} />
        <div className="modalContent">
          <div className="message">
            <strong>{message[0]}</strong>
            {message.slice(1).map((item, index) => (
              <span key={index}>{item}</span>
            ))}
          </div>
          <div className="modalActions">
            <button onClick={() => handleCancel()}>닫기</button>
            <button onClick={handleConfirm}>확인</button>
          </div>
        </div>
      </Confirmation>
    </ModalBackground>
  );
};

export default ConfirmationModal;
