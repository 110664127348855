import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
S;
import * as S from "../styled";
import { useRegex } from "../../../../hooks/useRegex";
import Loading from "../../common/ShopLoading";
import BoardTable from "../../../../common/board/boardTable";
import useQueryString from "../../../../hooks/useQueryString";
import { useMediaQuery } from "react-responsive";

export default function SearchForm() {
  const { cmpnyNo, url } = useOutletContext();
  const { MoneyFormat } = useRegex();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [isLoading, setIsLoading] = useState();

  const [product, setProduct] = useState([]);
  const [portfolio, setPortfolio] = useState([]);

  const [count, setCount] = useState({
    prdCount: 0,
    portCount: 0,
    noticeCount: 0,
  });

  const queryString = useQueryString("keyword");

  const [notices, setNotices] = useState([]);
  const [keyword, setKeyword] = useState(queryString);
  const [displayKeyword, setDisplayKeyword] = useState(queryString);
  const navigater = useNavigate();

  useEffect(() => {
    if (queryString) {
      getApi();
    }
  }, []);

  const getPrdList = async () => {
    const apiUrl = "/api/prductStock";
    const body = {
      cmpnyNo: cmpnyNo,
      salesStatus: "S01",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: true,
    };

    if (keyword !== "") {
      body.prductNm = keyword;
    }

    const response = await axios.post(apiUrl, body);
    count.prdCount = response.data.totalElements;
    setCount({ ...count });
    setProduct(response.data.content);
  };

  const getPortfolio = async () => {
    const apiUrl = "/api/portMgmtList";
    const body = {
      cmpnyNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: true,
    };

    if (keyword !== "") {
      body.keyword = keyword;
    }
    const res = await axios.post(apiUrl, body);
    const portDatas = res.data.content;
    setPortfolio(portDatas);
    count.portCount = res.data.totalElements;
    setCount({ ...count });
    setIsLoading(false);
  };

  const getNotice = async (page = 0) => {
    const req = {
      gbn: "N",
      delYn: "N",
      cmpnyNo: cmpnyNo,
      offset: 10 * page,
      pageNumber: page,
      pageSize: 10,
      paged: true,
    };

    if (keyword !== "") {
      req.noticeSj = keyword;
    }

    const response = await axios.post("/api/noticeList", req);
    const res = response.data;
    count.noticeCount = res.totalElements;
    setCount({ ...count });
    setNotices(res.content);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (checkValidation(keyword)) {
        getApi();
        navigater(`/shop/${url ? `${url}/` : ""}search?keyword=${keyword}`);
      }
    }
  };

  const clickSearch = () => {
    if (checkValidation(keyword)) {
      getApi();
      navigater(`/shop/${url ? `${url}/` : ""}search?keyword=${keyword}`);
    }
  };

  const checkValidation = (value) => {
    if (value.length < 2) {
      toast.error("검색어 두 글자 이상 입력해 주세요.");
      return false;
    } else {
      return true;
    }
  };

  const getApi = () => {
    setIsLoading(true);
    if (cmpnyNo) {
      getPrdList();
      getPortfolio();
      getNotice();
      setDisplayKeyword(keyword);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <main id="contSearch" className="sub_page sub_page_mobile_mt">
      <div className="subTop_bnr subTop_bnr_01"></div>
      <article className="boundary pdt_70 pdb_40">
        <h2 className="font_36 pdb_30 font600 text_center">통합검색</h2>
        <section id="secSchbox" className="">
          <div className="sch_bar text_center font_16">
            <input
              type="search"
              placeholder="검색어를 입력해 주세요."
              defaultValue={queryString}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button onClick={clickSearch}>
              <img src="/img/icon_search.png" alt="검색" />
            </button>
          </div>
        </section>
      </article>
      {!displayKeyword && (
        <article className="boundary pdb_70">
          <section id="secResult" className="pdt_40 pdb_30 font_24 text_center">
            <span>검색어를 입력해 주세요.</span>
          </section>
        </article>
      )}
      {displayKeyword && (
        <article className="boundary" style={{ paddingBottom: isMobile ? 100 : 70 }}>
          {isLoading && <Loading />}
          {!isLoading && (
            <>
              <section id="secResult" className="pdt_40 pdb_30 font_24 text_center">
                <strong className="font_color_red">
                  {displayKeyword && `'${displayKeyword}'`}
                </strong>
                검색 결과 총
                <b>{String(count.prdCount + count.portCount + count.noticeCount || 0)}</b>건
              </section>
              {count.prdCount !== 0 && (
                <S.TitleWrap style={{ marginTop: "1rem" }}>
                  <S.TitleText>상품</S.TitleText>
                </S.TitleWrap>
              )}
              <section id="secList" className="pdt_30" style={{ justifyContent: "left" }}>
                {product?.map((item, index) => (
                  <div className="col_3" key={index}>
                    <a
                      onClick={() =>
                        navigater(`/shop/${url}/productsView/${item.prdNo}`, {
                          state: { keyword: queryString },
                        })
                      }>
                      <div className="img">
                        <img
                          src={
                            item.extrlImgUrl ||
                            `https://cloud.1472.ai:18443/images/cloud/uploads/${item.prdImgFlpth}`
                          }
                          onError={onErrorImg}
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="font_18 font600 pdt_10 pdb_20">{item.prductNm}</p>
                        <p className="font_16">{MoneyFormat(item.pdCmPrice)}원</p>
                      </div>
                    </a>
                  </div>
                ))}
              </section>

              {count.portCount !== 0 && (
                <section
                  style={
                    count.prdCount === 0
                      ? { display: "block", marginTop: "0" }
                      : { display: "block", marginTop: "7rem" }
                  }>
                  <S.TitleText>포트폴리오</S.TitleText>
                </section>
              )}
              <section id="secList" className="pdt_30" style={{ justifyContent: "left" }}>
                {portfolio.map((item, index) => (
                  <div className="col_3" key={index}>
                    <a
                      onClick={() =>
                        navigater(`/shop/${url}/portfolioView/${item.portNo}`, {
                          state: { keyword: queryString },
                        })
                      }>
                      <div className="img">
                        <img src={imgHandler(item.imgUrl)} alt="" onError={onErrorImg} />
                      </div>
                      <div>
                        <p
                          className="font_18 font600 pdt_10 pdb_20"
                          style={{ textAlign: "center" }}>
                          {item.title}
                        </p>
                      </div>
                    </a>
                  </div>
                ))}
              </section>
            </>
          )}
          {count.noticeCount !== 0 && (
            <>
              <section
                style={
                  count.prdCount === 0 && count.portCount === 0
                    ? { display: "block", marginTop: "0" }
                    : { display: "block", marginTop: "7rem" }
                }>
                <S.TitleText>공지사항</S.TitleText>
              </section>
              <section id="boardList">
                <BoardTable notices={notices} isShop={true} url={url} queryString={queryString} />
              </section>
            </>
          )}
        </article>
      )}
    </main>
  );
}
