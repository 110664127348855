import React from "react";
import moment from "moment";
import { sales } from "../function/kiosk";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const CalendarInfo = ({ onDate, handleDateClick, total, downloadExcel }) => {
  const formattedDate = moment(onDate);

  const handleTodayClick = () => {
    handleDateClick(moment().format("YYYY-MM-DD"));
  };

  const handleMonthChange = (months) => {
    handleDateClick(formattedDate.add(months, "months").format("YYYY-MM-01"));
  };

  return (
    <div className="calendarInfo">
      <SalesInfo totalSales={`${total.toLocaleString()}원`} sales={sales} onDate={onDate} />
      <div className="calendarAction">
        <button onClick={handleTodayClick} className="todayBtn">
          오늘
        </button>
        <button onClick={() => handleMonthChange(-1)}>
          <BsChevronLeft />
        </button>
        <span>{formattedDate.format("YYYY년 M월")}</span>
        <button onClick={() => handleMonthChange(1)}>
          <BsChevronRight />
        </button>
        <button className="excel" onClick={downloadExcel}>
          엑셀 다운로드
        </button>
      </div>
    </div>
  );
};

const order = [1, 2, 0, 3];

const SalesInfo = ({ totalSales, sales, onDate }) => (
  <div className="sales">
    <div className="salesInfo">
      <span className="tit">{moment(onDate).format("M")}월 매출액</span>
      <span className="con">{totalSales}</span>
    </div>
    <ul>
      {order.map((key) => (
        <li key={key} className={key}>
          <span className="dot" style={{ backgroundColor: sales[key].color }}></span>
          <span>{sales[key].label}</span>
        </li>
      ))}
    </ul>
  </div>
);

export default CalendarInfo;
