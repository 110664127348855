import React, { useState } from "react";
import { ProfileImages, ProjectDetailChatRoomContainer } from "../../../common/styled/project";
import moment from "moment";

const ProjectDetailChatRoom = ({ projectRoomList, setSelectedRoom }) => {
  const partCmpny = (orgInfos) => {
    const classNames = ["one", "two", "three", "four"];
    const className = classNames[Math.min(orgInfos.length, 4) - 1];

    const imageElements = orgInfos
      .slice(0, 4)
      .map((info, index) => (
        <img
          key={index}
          src={info.orgImg ? imgHandler(info.orgImg) : "/img/function_22_bg.png"}
          alt={`cmpny${index + 1}`}
        />
      ));

    return <div className={className}>{imageElements}</div>;
  };

  const lastMessage = (commentInfo) => {
    if (!commentInfo) {
      return <span className="msg">메세지가 존재하지 않습니다.</span>;
    }

    const { content, fileList, imgList } = commentInfo;
    if (content) {
      return <span className="msg">{content}</span>;
    }
    if (imgList && imgList.length > 0) {
      return <img src={imgHandler(imgList[0].filePath)} className="msg" />;
    }
    if (fileList && fileList.length > 0) {
      return <span>파일 {fileList.length}개</span>;
    }
    return <></>;
  };

  const getTime = (time) => {
    if (!time) return null;

    const now = moment();
    const target = moment(time);
    const today = moment().startOf("day");

    if (now.year() === target.year()) {
      if (target.isSame(today, "day")) {
        const minutes = now.diff(target, "minutes");
        return minutes < 1 ? "방금 전" : target.format("HH:mm");
      } else if (target.isSame(today.subtract(1, "days"), "day")) {
        return "어제";
      } else {
        return target.format("MM-DD");
      }
    } else {
      return target.format("YYYY-MM-DD");
    }
  };

  return (
    <ProjectDetailChatRoomContainer>
      {projectRoomList && projectRoomList.length > 0 && (
        <>
          <h3>채팅방 목록 &#40;{projectRoomList.length}&#41;</h3>
          <ul className="list">
            {projectRoomList.map((item) => (
              <li onClick={() => setSelectedRoom(item)}>
                <div className="chatContent">
                  {item.orgInfos && <ProfileImages>{partCmpny(item.orgInfos)}</ProfileImages>}
                  <div className="info">
                    <span className="tit">
                      {item.orgInfos &&
                        item.orgInfos.map((orgInfo, idx) => (
                          <React.Fragment key={idx}>
                            {orgInfo?.orgNm}
                            {idx !== item.orgInfos.length - 1 && ", "}
                          </React.Fragment>
                        ))}
                    </span>
                    {lastMessage(item.commentInfo)}
                  </div>
                  <div className="chatInfo">
                    {item.commentInfo && item.commentInfo.createDt && (
                      <span>{getTime(item.commentInfo.createDt)}</span>
                    )}
                    {item.unReadMsgCnt > 0 && (
                      <i>{item.unReadMsgCnt > 99 ? "99+" : item.unReadMsgCnt}</i>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </ProjectDetailChatRoomContainer>
  );
};

export default ProjectDetailChatRoom;
