import React from "react";
import { ActionConfirmationContainer } from "../styled/common";

const ActionConfirmation = ({ transformValue = 0, title, explanation, onConfirm }) => {
  return (
    <ActionConfirmationContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="box">
        <div className="tit">{title}</div>
        <div className="con">
          {explanation.map((item) => (
            <span>{item}</span>
          ))}
        </div>
        <div className="actions">
          <button onClick={() => onConfirm()}>확인</button>
        </div>
      </div>
    </ActionConfirmationContainer>
  );
};

export default ActionConfirmation;
