import { useNavigate } from "react-router";

const BoardView = () => {
  const navigater = useNavigate();

  return (
    <div>
      {/* <div className="inner_content_wrap">
        <div className="borad_info">
          <span className="font_30 font500 font_color_black text_left">
            게시판 제목이 들어갑니다. 최대글자수 제한은 없습니다. 한글 테스트 글 입니다. 한글 테스트 글 입니다. 한글 테스트 글 입니다. 한글 테스트 글 입니다. 한글 테스트 글 입니다.
            </span>
          <div className="author_info">
            <span className="author_name font_14 font_color_gray font300"><span className="sound_only">작성자</span><i className="fa fa-pencil-square-o" aria-hidden="true" /> 홍길동</span>
            <span className="author_date font_14 font_color_gray font300"><span className="sound_only">작성일</span><i className="fa fa-clock-o" aria-hidden="true" /> 2023-04-01</span>
            <span className="author_view font_14 font_color_gray font300"><span className="sound_only">조회수</span><i className="fa fa-eye" aria-hidden="true" /> 111 회</span>
          </div>
          <div className="board_content">
            <p className="board_content_inner">
            해당 영역에 게시글을 넣습니다.<br />
            해당 영역에 게시글을 넣습니다.<br />
            해당 영역에 게시글을 넣습니다.<br />
            해당 영역에 게시글을 넣습니다.
            </p>
          </div>
          <div className="content_control">
            <button type="button" className="btn_board_edit" >수정</button>
            <button type="button" className="btn_board_reply" >답변</button>
            <button type="button" className="btn_board_delete" >삭제</button>
            <button type="button" className="btn_board_new" >등록</button>
            <button type="button" className="btn_board_list white" onClick={() => navigater(-1)}>목록</button>
          </div>
          <ul className="content_paging">
            <li>
              <span className="font_16 font300 font_color_gray content_paging_info"><i className="fa fa-angle-up" aria-hidden="true" /> 이전글</span>
              <a href="" className="content_prev font_18 font_color_black">이전글 제목이 들어갑니다. 최대글자수가 넘어가면 자동으로 글자가 잘립니다. 이전글 제목이 들어갑니다. 최대글자수가 넘어가면 자동으로 글자가 잘립니다. 이전글 제목이 들어갑니다. 최대글자수가 넘어가면 자동으로 글자가 잘립니다. 이전글 제목이 들어갑니다. 최대글자수가 넘어가면 자동으로 글자가 잘립니다.</a>
              <span className="font_16 font300 font_color_gray content_paging_date"><span className="sound_only">등록일</span>2023-04-01</span>
            </li>
            <li>
              <span className="font_16 font300 font_color_gray content_paging_info"><i className="fa fa-angle-down" aria-hidden="true" /> 다음글</span>
              <a href="" className="content_next font_18 font_color_black">다음글 제목이 들어갑니다. 최대글자수가 넘어가면 자동으로 글자가 잘립니다.</a>
              <span className="font_16 font300 font_color_gray content_paging_date"><span className="sound_only">등록일</span>2023-04-01</span>
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
};

export default BoardView;
