import { useMediaQuery } from "react-responsive";
import { Link, useOutletContext } from "react-router-dom";

export default function CartForm() {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const { url } = useOutletContext();

  return (
    <main id="contCart" className="sub_page sub_page_mobile_mt">
      <div className="subTop_bnr subTop_bnr_01"></div>
      <article className="boundary pdt_70" style={{ paddingBottom: isMobile ? 100 : 70 }}>
        <h2 className="font_36 pdb_30 font600 text_center">장바구니</h2>
        <section id="secBrdcrmb" className="font_14 pdb_20 text_right">
          <Link to={`/shop/${url}`}>
            <i className="xi-home-o font_20"></i>
          </Link>
          <Link to={url ? `/shop/${url}/cart` : `/shop/cart`}>장바구니</Link>
        </section>
        <section id="secTable">
          <div className="tb_header font_18 font600 pdt_10 pdb_10 text_center">
            <div className="info">상품정보</div>
            <div className="amount">수량</div>
            <div className="price">주문금액</div>
            <div className="ship">배송정보</div>
          </div>
          <ul className="tb_items font_16">
            <li className="pdt_20 pdb_20">
              <div className="info pdt_10 pdb_10">
                <span className="chk_wrap">
                  <input type="checkbox" id="chk_item01" />
                  <label htmlFor="chk_item01">
                    <i className="xi-check"></i>
                  </label>
                </span>
                <div className="info_inner">
                  <p className="thumb">
                    <img src="/img/prdct_01.png" alt="" />
                  </p>
                  <div className="summ">
                    <p className="tit font600 pdb_10">
                      LED 60W 라잇 직사각 방등/거실등 660형 삼성모듈사용, 2년 무상 AS
                    </p>
                    <p className="unit">
                      <span>
                        <b className="font900">58,900</b>원
                      </span>
                      <small className="font_color_gray_light">
                        <s>78,000</s>
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="amount pdt_10 pdb_10">
                <div className="option pdb_10">
                  단품/세트 : 냄비세트 / 크기 : 3종세트(양수16+편수18+양수20) / 1개
                </div>
                <div className="gift pdt_10 pdb_10">사은품 : </div>
                <button className="btn_edit">주문수정</button>
              </div>
              <div className="price pdt_10 pdb_10 text_center">
                <span>
                  <b className="font900">58,900</b>원
                </span>
              </div>
              <div className="ship pdt_10 pdb_10 text_center">
                <dl>
                  <dt className="font600 pdb_10 font_color_gray_light">배송비</dt>
                  <dd>
                    <b className="font900">4,000</b>원
                    <button
                      className="btn_tooltip"
                      data-tooltip=" 배송관련정보 배송관련정보 배송관련정보 배송관련정보">
                      {" "}
                      {/* onClick="funcTooltip(this)" */}
                      <i className="xi-help-o font_18 font_color_gray_light"></i>
                    </button>
                  </dd>
                </dl>
              </div>
            </li>
            <li className="pdt_20 pdb_20">
              <div className="info pdt_10 pdb_10">
                <span className="chk_wrap">
                  <input type="checkbox" id="chk_item02" />
                  <label htmlFor="chk_item02">
                    <i className="xi-check"></i>
                  </label>
                </span>
                <div className="info_inner">
                  <p className="thumb">
                    <img src="/img/prdct_01.png" alt="" />
                  </p>
                  <div className="summ">
                    <p className="tit font600 pdb_10">
                      LED 60W 라잇 직사각 방등/거실등 660형 삼성모듈사용, 2년 무상 AS
                    </p>
                    <p className="unit">
                      <span>
                        <b className="font900">58,900</b>원
                      </span>
                      <small className="font_color_gray_light">
                        <s>78,000</s>
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="amount pdt_10 pdb_10">
                <div className="option pdb_10">
                  단품/세트 : 냄비세트 / 크기 : 3종세트(양수16+편수18+양수20) / 1개
                </div>
                <div className="gift pdt_10 pdb_10">사은품 : </div>
                <button className="btn_edit">주문수정</button>
              </div>
              <div className="price pdt_10 pdb_10 text_center">
                <span>
                  <b className="font900">58,900</b>원
                </span>
              </div>
              <div className="ship pdt_10 pdb_10 text_center">
                <dl>
                  <dt className="font600 pdb_10 font_color_gray_light">배송비</dt>
                  <dd>
                    <b className="font900">4,000</b>원
                    <button
                      className="btn_tooltip"
                      data-tooltip=" 배송관련정보 배송관련정보 배송관련정보 배송관련정보">
                      {" "}
                      {/* onClick="funcTooltip(this);" */}
                      <i className="xi-help-o font_18 font_color_gray_light"></i>
                    </button>
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
          <div className="tb_footer pdt_30 font_18 font600 text_center">
            <div className="">Total</div>
            <div className="price_detail">
              <dl>
                <dt className="font_color_gray_light">선택상품금액</dt>
                <dd>
                  <b className="font900">1,708,000</b>
                  <small>원</small>
                </dd>
              </dl>
              <i className="xi-plus font_30"></i>
              <dl>
                <dt className="font_color_gray_light">총 배송비</dt>
                <dd>
                  <b className="font900">40,000</b>
                  <small>원</small>
                </dd>
              </dl>
              <i className="xi-minus font_30"></i>
              <dl>
                <dt className="font_color_gray_light">할인예상금액</dt>
                <dd className="font_color_red">
                  <b className="font900">719,100</b>
                  <small>원</small>
                </dd>
              </dl>
            </div>
            <div className="price_sum">
              <dl>
                <dt>주문금액</dt>
                <dd>
                  <b className="font900">11,620,900</b>원
                </dd>
              </dl>
            </div>
            <div className="btns">
              <button className="btn_order font_16 font700">
                <b>1</b>건 주문하기
              </button>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
}
