import React, { useEffect, useState } from "react";
import { SearchEventContainer } from "../../../../../mypage/scheduler/component/styled/scheduler";
import moment from "moment";
import axios from "axios";
import GroupDetailSearchForm from "./groupDetailSearchForm";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

const GroupDetailSearchSchedule = ({ grp, data, isSearch, searchWord }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [scheduleList, setScheduleList] = useState({});

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const transformedData = transformData(data);
      setScheduleList(transformedData);
    }
  }, [data]);

  // 년/월/일 일정 분류
  const transformData = (schedules) => {
    return schedules.reduce((acc, item) => {
      const startDate = moment(item.startDt).startOf("day");
      const endDate = moment(item.endDt).startOf("day");

      for (let date = startDate; date.isSameOrBefore(endDate, "day"); date.add(1, "days")) {
        const year = date.year();
        const month = date.month() + 1;
        const day = date.date();
        if (!acc[year]) acc[year] = {};
        if (!acc[year][month]) acc[year][month] = {};
        if (!acc[year][month][day]) acc[year][month][day] = [];
        acc[year][month][day].push(item);
      }
      return acc;
    }, {});
  };

  const handleClickEvent = (scheduleNo) => {
    let basePath = `/mypage/detailEvent/${scheduleNo}`;
    let queryParams = [`path=${pathname}`];

    if (searchWord) {
      queryParams.push(`keyword=${searchWord}`);
    }

    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";
    return navigate(`${basePath}${queryString}`);
  };

  return (
    <SearchEventContainer>
      {isSearch && (
        <div className="searchContaier" style={{ height: "auto" }}>
          <div className="label">
            {data.length > 0 && (
              <span>
                검색 결과
                <strong>{data.length.toLocaleString()}</strong>개
              </span>
            )}
          </div>
          <>
            {Object.keys(scheduleList).length > 0 ? (
              <table className="searchResultsTable">
                <tbody>
                  {Object.entries(scheduleList).flatMap(([year, months]) => [
                    <tr key={year}>
                      <td className="year">{year}년</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>,
                    ...Object.entries(months).flatMap(([month, days]) => [
                      <tr key={`${year}-${month}`}>
                        <td></td>
                        <td className="month">{month}월</td>
                        <td></td>
                        <td></td>
                      </tr>,
                      ...Object.entries(days).flatMap(([day, events]) => [
                        <tr key={`${year}-${month}-${day}`}>
                          <td></td>
                          <td></td>
                          <td className="day">{day}일</td>
                          <td></td>
                        </tr>,
                        ...events.map((event, index) => (
                          <tr key={`${year}-${month}-${day}-${index}`}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td
                              style={{
                                background: "#0074ca",
                                color: "#fff",
                                textDecoration: event.completeYn === "Y" ? "line-through" : "none",
                              }}
                              className="event"
                              onClick={() => handleClickEvent(event.scheduleNo)}>
                              {event.title}
                            </td>
                          </tr>
                        )),
                      ]),
                    ]),
                  ])}
                </tbody>
              </table>
            ) : (
              <div className="noData">일정이 존재하지 않습니다.</div>
            )}
          </>
        </div>
      )}
    </SearchEventContainer>
  );
};

export default GroupDetailSearchSchedule;
