import React from "react";

import { InfoContainer } from "./styled/info";

import Visual from "./common/visual";
import Hero from "./common/hero";
import Mockup from "./common/mockup";
import Highlight from "./common/highlight";
import SecondaryMockup from "./common/secondaryMockup";

const InfoShop = () => {
  const components = [
    {
      component: Mockup,
      content: {
        heading: "간편한 웹사이트 구축 도구",
        description:
          "사용자가 코딩 지식이 없어도 직관적인 UI 를 통해\n온라인 쇼핑몰을 쉽게 제작할 수 있도록 지원합니다.\n다양한 디자인 템플릿과 맞춤형 기능을 제공하여\n각 기업의 브랜딩에 맞춘\n웹사이트를 손쉽게 구성할 수 있습니다.",
        feature: "손쉬운 파일 저장과 공유",
      },
    },
    {
      component: SecondaryMockup,
      content: {
        heading: "다양한 테마 선택",
        description:
          "다양한 테마를 제공하여 기업이 원하는 분위기와\n스타일에 맞는 디자인을 선택할 수 있습니다.\n테마 변경 후에는 회사 설명, 로고 등을 자유롭게 변경할 수 있습니다.",
        feature: "모바일과 PC 미리보기 기능 제공",
      },
    },
    {
      component: Hero,
      content: {
        heading: "맞춤형 기능 제공",
        description:
          "인테리어, 제조, 유통 등 특정 산업군에 맞춘\n현장 맞춤형 기능도 지원합니다.\n제조업체의 경우, 자재 발주와 생산 현황을\n쇼핑몰 시스템과 연동하여 관리할 수 있습니다.",
        feature: "특화된 솔루션 제공",
      },
    },
  ];
  return (
    <InfoContainer className="shop">
      <Visual />
      {components.map(({ component: Component, content }, index) => (
        <Component key={index} content={content} type="shop" />
      ))}
    </InfoContainer>
  );
};

export default InfoShop;
