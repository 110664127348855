import React from "react";
import { SelectedProductContainer } from "../../../styled/mutualBenefit";
import { Doughnut } from "react-chartjs-2";

const SelectedProduct = ({ hoverInfo }) => {
  const max = 120;

  const doughnutData = {
    datasets: [
      {
        data: [hoverInfo ? hoverInfo.value : 0, hoverInfo ? max - hoverInfo.value : max],
        backgroundColor: ["#4E5AC3", "#E0E0E0"],
        borderWidth: 0,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "80%",
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
  };

  return (
    <SelectedProductContainer className={`hoverProductDetail ${hoverInfo ? "active" : "inactive"}`}>
      <div className="doughnut">
        {hoverInfo && <Doughnut data={doughnutData} options={doughnutOptions} />}
      </div>
      <div className="info">
        <strong>{hoverInfo ? hoverInfo.value : ""}</strong>
        <span>{hoverInfo ? hoverInfo.label : ""}</span>
      </div>
    </SelectedProductContainer>
  );
};

export default SelectedProduct;
