import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import LoginForm from "./component/loginForm";
import Modal from "../../common/modal";
import { handleLogin } from "../../store/user";
import axios from "axios";
import { getSetting } from "../../store/scheduler";
import { isSScenterMbers, onFullscreen } from "../mypage/kiosk/component/function/kiosk";

const Login = () => {
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width:640px)" });
  const user = useSelector((state) => state?.user?.data[0]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userId, setId] = useState("");
  const [password, setPassword] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [rememberId, setRememberId] = useState(false);

  const handleLoginModal = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  useEffect(() => {
    if (user) {
      dispatch(handleLogin(user));
      navigate("/mypage");
    }

    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const params = {};
      searchParams.forEach((value, key) => {
        params[key] = value;
      });
      sessionStorage.setItem("params", JSON.stringify(params));
    }

    const storedData = localStorage.getItem("monoti");
    if (storedData) {
      const id = JSON.parse(storedData);

      const decodedId = atob(id);
      setId(decodedId);
      setRememberId(true);
    }
  }, []);

  const handleSaveId = (id) => {
    if (rememberId && !isMobile) {
      const encodedId = btoa(id);
      localStorage.setItem("monoti", JSON.stringify(encodedId));
    } else {
      localStorage.removeItem("monoti");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = "/api/signIn";
    const req = {
      id: userId,
      pwd: password,
    };

    if (!userId || !password) {
      setError("아이디 또는 비밀번호를 입력해주세요.");
      setModalOpen(true);
      return;
    }

    if (localStorage.getItem("msgToken")) {
      req.fcmToken = localStorage.getItem("msgToken");
      console.log(localStorage.getItem("msgToken"));
    }

    const res = await axios.post(url, req);

    if (res.data.success === false) {
      setError(res.data.message);
      setModalOpen(true);
    } else {
      dispatch(handleLogin(res.data));
      const mberNo = res.data.mberNo;

      if (sessionStorage.getItem("params")) {
        await handleInvite(mberNo);
      }
      handleSaveId(userId);
      await getSchedulerSetting(mberNo);
    }
  };

  const handleInvite = async (mberNo) => {
    const params = JSON.parse(sessionStorage.getItem("params"));

    const url = "/api/notification";
    const body = {
      gbn: "I",
      invite: {
        gbn: params.gbn,
        mberNoList: [mberNo],
        orgNo: params.orgNo,
      },
    };

    for (const key in body.invite) {
      if (body.invite.hasOwnProperty(key) && !body.invite[key]) {
        console.log("object contains empty values");
        return;
      }
    }

    const res = await axios.post(url, body);
    if (res.data.body.success) {
      sessionStorage.removeItem("params");
    }
  };

  const getSchedulerSetting = async (mberNo) => {
    const url = "/api/toktokSettingList";
    const req = {
      mberNo,
    };
    const res = await axios.post(url, req);
    if (res.request.status === 200) {
      dispatch(getSetting(res.data));

      if (isSScenterMbers(mberNo)) {
        onFullscreen();
        navigate("/mypage/kiosk/salesCheck");
      } else {
        navigate("/mypage");
      }
    }
  };

  const LoginModalForm = (error) => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">로그인</h2>
        <button onClick={handleLoginModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">{error}</span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        modalState={modalOpen}
        handleModalState={handleLoginModal}
        html={LoginModalForm(error)}
        w={isMobile ? "70%" : "17%"}
        h="auto"
      />
      <LoginForm
        id={userId}
        password={password}
        setId={setId}
        setPassword={setPassword}
        handleSubmit={handleSubmit}
        getSchedulerSetting={getSchedulerSetting}
        handleInvite={handleInvite}
        rememberId={rememberId}
        setRememberId={setRememberId}
      />
    </>
  );
};

export default Login;
