import React from "react";
import { icons } from "../function/dateInfo";
import { IconContainer } from "../styled/calendarEntry";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { transformDate } from "../function/scheduler";

const IconPicker = ({ setFormData, setIsIcon, saveToLocalStorage }) => {
  const selectIcon = (src = "") => {
    setFormData((data) => {
      let updated = {
        ...data,
        icon: src,
      };

      saveToLocalStorage(updated);
      return updated;
    });
    setIsIcon(false);
  };

  return (
    <IconContainer onClick={(e) => e.target === e.currentTarget && setIsIcon(false)}>
      <div className="iconArea">
        <button className="close" onClick={() => setIsIcon(false)}>
          <IoMdClose />
        </button>
        {icons.map((icon) => (
          <div className="icon" data-name={icon.name}>
            <img
              src={`/img/scheduler/scheduler_icon_${icon.src}`}
              onClick={() => selectIcon(icon.src)}
            />
          </div>
        ))}
        <button className="removeIconBtn" onClick={() => selectIcon()}>
          <RiDeleteBinLine />
        </button>
      </div>
    </IconContainer>
  );
};

export default IconPicker;
