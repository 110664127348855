import { useSelector } from "react-redux";
import FaqSubMenu from "./component/faqSubMenu";
import MypageBanner from "../mypageBanner";
import BoardList from "../../../common/boardList";

const Faq = () => {
  const index = useSelector((state) => state.compoIndex.faq);
  
  const BoardListProps = {
    button: false,
    search: false,
    title: "FAQ"
  };

  return (
    <div>
      <MypageBanner/>
      <div className="inner_content">
        <FaqSubMenu index={index} />
        <Components index={index} BoardListProps={BoardListProps}/>
      </div>
    </div>
  );
};

export default Faq;

function Components ({index, BoardListProps}) {
  return (
    <>
      {[<BoardList BoardListProps={BoardListProps}/>, null, null, null, null][index]}
    </>
  )
}