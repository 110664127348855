import React, { useEffect, useState } from "react";
import { SlArrowRight } from "react-icons/sl";
import { ScheduleSideFormContainer } from "../styled/calendarEntry";
import SideFormItem from "./sideFormItem";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const SideForm = ({
  formData,
  setFormData,
  affilEmp,
  actSelType,
  saveToLocalStorage,
  toggleSideForm,
  projectMberInfo,
  setActSelType,
}) => {
  const [text, setText] = useState("");
  const [users, setUsers] = useState([]);
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);
  const [allChecked, setAllChecked] = useState(false);
  const [projectMbers, setProjectMbers] = useState([]);

  useEffect(() => {
    if (affilEmp && affilEmp.length > 0) {
      const sorted = [...affilEmp].sort((a, b) => a.mberNo - b.mberNo);
      setUsers(sorted);
    } else {
      setUsers([]);
    }
  }, [affilEmp, formData.managerNo]);

  useEffect(() => {
    if (!actSelType) {
      const allSelected = users.every((item) => formData.workerList?.[item.mberNo]);
      setAllChecked(allSelected);
    }
    return;
  }, [formData.workerList, formData.managerNo, users, actSelType]);

  useEffect(() => {
    setProjectMbers([...projectMberInfo]);
  }, [projectMberInfo]);

  // 실무자 지우기
  const removeWorker = (data, item) => {
    const newWorkerList = { ...data.workerList };
    delete newWorkerList[item.mberNo];
    return newWorkerList;
  };

  // 담당자
  const setManager = (item) => {
    setFormData((data) => {
      let updated = {
        ...data,
        managerNo: item.mberNo,
        managerNm: item.mberNm,
        workerList: removeWorker(data, item),
      };

      saveToLocalStorage(updated);
      return updated;
    });
    toggleSideForm(true);
    setActSelType(false);
  };

  // 실무자 선택 토글
  const toggleWorker = (item) => {
    setFormData((data) => {
      const newWorkerList = { ...data.workerList };
      if (newWorkerList[item.mberNo]) {
        delete newWorkerList[item.mberNo];
      } else {
        newWorkerList[item.mberNo] = item.mberNm;
      }
      const updated = { ...data, workerList: newWorkerList };

      saveToLocalStorage(updated);

      return updated;
    });
  };

  // 검색
  const handleSearch = (e) => {
    e.preventDefault();
    setUsers(
      affilEmp.filter(
        (user) =>
          (user.mberNm && user.mberNm.includes(text)) || (user.tel && user.tel.includes(text)),
      ),
    );
    setProjectMbers(
      projectMberInfo.filter(
        (org) =>
          org.orgNo !== formData.orgNo &&
          org.mbers.some(
            (user) =>
              (user.mberNm && user.mberNm.includes(text)) ||
              (user.tel && user.moblphonNo.includes(text)),
          ),
      ),
    );
    setText("");
  };

  const handleAllCheck = (checked) => {
    setAllChecked(checked);
    if (!actSelType) {
      const newWorkerList = checked
        ? users.reduce((acc, curr) => ({ ...acc, [curr.mberNo]: curr.mberNm }), {})
        : {};
      setFormData((data) => {
        let updated = { ...data, workerList: newWorkerList };

        saveToLocalStorage(updated);
        return updated;
      });
    }
  };

  return (
    <ScheduleSideFormContainer className={`font_16 scheduleSide ${isDarkMode}`}>
      {users.length > 0 ? (
        <>
          <div className="sideFormHeader" onClick={() => toggleSideForm(false)}>
            <SlArrowRight />
            <span className="font_24">{actSelType ? "담당자" : "실무자"} 선택</span>
          </div>
          <form onSubmit={(e) => handleSearch(e)}>
            <input
              type="text"
              placeholder="이름 또는 전화번호 입력"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <button type="submit">검색</button>
          </form>
          <div className={`section ${formData.gbn === "C" ? "cmpny" : "grp"}`}>
            <ul className="searchContent">
              <li className="tit">
                <div>
                  {!actSelType && (
                    <input
                      type="checkbox"
                      checked={allChecked}
                      onChange={(e) => handleAllCheck(e.target.checked)}
                    />
                  )}
                </div>
                <div>회원명</div>
                {formData.gbn === "C" && <div>전화번호</div>}
              </li>
              {users
                .filter((item) => actSelType || item.mberNo !== formData.managerNo)
                .map((item, i) => (
                  <SideFormItem
                    key={item.mberNo}
                    item={item}
                    formData={formData}
                    onToggle={actSelType ? setManager : toggleWorker}
                    actSelType={actSelType}
                  />
                ))}
            </ul>

            {projectMbers.length > 0 && formData.gbn === "C" && !isNaN(formData.projectNo) && (
              <div className="participants">
                {projectMbers
                  .filter((org) => parseInt(org.orgNo) !== parseInt(formData.orgNo))
                  .map((org) => (
                    <>
                      <div>{org.orgNm ? org.orgNm : "회사명 미설정"}</div>
                      <ul>
                        {org.mbers.map((item, i) => (
                          <SideFormItem
                            key={item.mberNo}
                            item={item}
                            formData={formData}
                            onToggle={
                              actSelType
                                ? // () => toast.error("외부업체는 담당자 설정이 불가능합니다.")
                                  setManager
                                : toggleWorker
                            }
                            actSelType={actSelType}
                          />
                        ))}
                      </ul>
                    </>
                  ))}
              </div>
            )}
          </div>
          <div className="btnArea">
            <button onClick={() => toggleSideForm(false)}>확인</button>
          </div>
        </>
      ) : (
        <div>선택할 멤버가 존재하지 않습니다.</div>
      )}
    </ScheduleSideFormContainer>
  );
};

export default SideForm;
