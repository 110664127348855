import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { ScheduleSwiperContainer } from "../../../styled/dashboard";
import { isOutsideCmpny } from "../../../../../scheduler/component/function/common";

const ProjectSwiper = ({ data = [], perView = 1 }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);

  const displayTime = (item) => {
    const type = {
      P: "개인",
      C: "업무",
      G: "모임",
    };

    if (isOutsideCmpny(item, user)) {
      return (
        <>
          <span>{moment(item.createDt).format("M/D")}</span>
          <span className="projectType">외부업체</span>
        </>
      );
    }
    if (item.completeYn === "N")
      return (
        <>
          <span>{moment(item.createDt).format("M/D")}</span>
          <span className="projectType">{type[item?.gbn]}</span>
        </>
      );
    return null;
  };

  const isColor = (item) => {
    if (item.pinnedYn === "Y") return "#ffc455";
    if (isOutsideCmpny(item, user)) return "#ed5583";
    if (item.gbn === "C") return "#0074ca";
    if (item.gbn === "G") return "#f06f02";

    return `#${item.color}`;
  };

  return (
    <ScheduleSwiperContainer>
      <Swiper
        slidesPerView={perView}
        spaceBetween={10}
        //  loop={true}
        className="mySwiper">
        {data.map((item, i) => (
          <SwiperSlide key={i}>
            <div
              className="scheduleContent"
              style={{ borderLeft: `5px solid ${isColor(item)}` }}
              onClick={() => navigate(`/mypage/project/${item.projectNo}`)}>
              <div className="timeArea">{displayTime(item)}</div>
              <div className="contentArea">
                <span>{item.projectNm}</span>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </ScheduleSwiperContainer>
  );
};

export default ProjectSwiper;
