import React from "react";
import { EventInfoDetailList } from "../../styled/scheduler";
import axios from "axios";
import { keysToRemove } from "../../function/scheduler";

const EventTodoListView = ({ item, getSchedule, setEventInfo }) => {
  const updateTodoState = (todos, name, state) => {
    return todos.map((todoItem) =>
      todoItem.todoNo.toString() === name ? { ...todoItem, completeYn: state } : todoItem,
    );
  };

  const handleTodoComplete = async (e) => {
    const { name, checked } = e.target;

    const state = checked ? "Y" : "N";

    const url = "/api/schedule";
    const body = keysToRemove(item);

    body.todo = updateTodoState(body.todo, name, state);

    try {
      const res = await axios.put(url, body);
      if (res.status === 200) {
        getSchedule();
        setEventInfo((event) => ({
          ...event,
          todo: updateTodoState(event.todo, name, state),
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <EventInfoDetailList>
      {item.todo.map((todoItem) => (
        <li key={todoItem.todoNo}>
          <label style={{ display: "flex", gap: 25, cursor: "pointer" }}>
            <input
              type="checkbox"
              name={todoItem.todoNo}
              checked={todoItem.completeYn === "Y"}
              onChange={(e) => handleTodoComplete(e)}
            />
            <span style={{ textDecoration: todoItem.completeYn === "Y" ? "line-through" : "none" }}>
              {todoItem.title}
            </span>
          </label>
        </li>
      ))}
    </EventInfoDetailList>
  );
};

export default EventTodoListView;
