import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import { BsPin, BsPinAngleFill } from "react-icons/bs";
import { RxDotsVertical } from "react-icons/rx";

import { ProjectDetailHeader } from "../../common/styled/project";

import { useNavigate } from "react-router";
import Loading from "../../../../../../common/loading";
import { hideLoading, showLoading } from "../../../../../../store/loading";
import { isColor } from "../../common/function/project";
import { isSameUser } from "../../../../scheduler/component/function/common";
import { HiOutlineMenu } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";
import { PiUsersThree } from "react-icons/pi";

const ProjectDetailInfo = ({
  project,
  setProject,
  openMenu,
  setOpenMenu,
  handleModalOpen,
  projectInfo,
  setShowSideMenu,
  showSideMenu,
  setShowParticipants,
  showParticipants,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const user = useSelector((state) => state?.user?.data[0]);
  const isLoading = useSelector((state) => state?.loading?.isLoading);
  const menuButtonRef = useRef(null);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });

  // 즐겨찾기
  const projectPinned = async () => {
    // dispatch(showLoading());
    const state = project.pinnedYn === "Y";

    if (project.completeYn === "Y") {
      toast.error("해당 프로젝트는 변경할 수 없습니다.");
      return;
    }

    const url = "/api/participants";
    const body = {
      projectParticipantNo: project.projectParticipantNo,
      projectNo: project.projectNo,
      gbn: project.gbn,
      mberNo: user.mberNo,
      orgNo: project.gbn === "C" ? user.cmpnyNo : project.orgNo,
      partiType: project.partiType,
      pinnedYn: state ? "N" : "Y",
      delYn: "N",
      updateMberId: user.userName,
    };

    const res = await axios.put(url, [body]);

    if (res.status === 200) {
      dispatch(hideLoading());
      toast.success(`즐겨찾기가 ${state ? "취소" : "등록"}되었습니다.`);
      setProject((data) => ({ ...data, pinnedYn: state ? "N" : "Y" }));
    }
  };

  useEffect(() => {
    if (openMenu && menuButtonRef.current) {
      const rect = menuButtonRef.current.getBoundingClientRect();
      setButtonPosition({ top: rect.top, left: rect.left });
    }
  }, [openMenu]);

  const projectTitleWidth = () => {
    if (!isMobile) {
      return "calc(100% - 80px)";
    }
    if (!project?.projectParticipantNo && !isSameUser(user, project)) {
      return "calc(100% - 120px)";
    }
    if (!isSameUser(user, project)) {
      return "calc(100% - 152px)";
    }
    return "calc(100% - 177px)";
  };

  const isProjectColor = (project) => {
    if (!project) return;

    if (project.pinnedYn === "Y") return "#FFC455";
    if (project.gbn === "G") return "#F46F02";
    if (project.gbn === "C" && project.orgNo.toString() === user.cmpnyNo.toString()) {
      return "#0074ca";
    } else {
      return "#ED5583";
    }
  };

  return (
    <ProjectDetailHeader>
      <div className="projectHeaderGroup">
        {isTablet && (
          <button onClick={() => setShowSideMenu(!showSideMenu)} className="sideMenuButton">
            <HiOutlineMenu />
          </button>
        )}
        <div className="gbn" style={{ background: isProjectColor(project) }}></div>
        {project?.projectParticipantNo && (
          <button onClick={projectPinned}>
            {project?.pinnedYn === "Y" ? (
              <BsPinAngleFill style={{ color: "#e72e2e" }} />
            ) : (
              <BsPin />
            )}
          </button>
        )}
      </div>
      {isSameUser(user, project) && (
        <button
          className="moreButton"
          onClick={() => setOpenMenu((openMenu) => !openMenu)}
          ref={menuButtonRef}>
          <RxDotsVertical />
        </button>
      )}
      {openMenu && isSameUser(user, project) && (
        <div className="feedMenu" onClick={() => setOpenMenu(false)}>
          <ul style={{ top: `${buttonPosition.top}px`, left: `${buttonPosition.left}px` }}>
            <li>
              <button
                onClick={() =>
                  navigate("/mypage/projectCreateSec", {
                    state: { projectInfo },
                  })
                }>
                수정
              </button>
            </li>
            <li>
              <button onClick={() => handleModalOpen("delete", { ...projectInfo })}>삭제</button>
            </li>
          </ul>
        </div>
      )}
      <div className="projectTitle" style={{ width: projectTitleWidth() }}>
        <h2>{project?.projectNm ? project.projectNm : ""}</h2>
        {project?.projectDesc && <p title={project.projectDesc}>{project.projectDesc}</p>}
      </div>
      {isMobile && (
        <button
          onClick={() => setShowParticipants(!showParticipants)}
          className="participantButton">
          <PiUsersThree />
        </button>
      )}
      {isLoading && <Loading />}
    </ProjectDetailHeader>
  );
};

export default ProjectDetailInfo;
