export default function ServiceBenefit({ref3}) {
  return(
    <>
      <div className="inner_title" id="function_3" ref={ref3}>
        <span className="font_48 font600 pdb_20">{service.service}</span>
        <span className="font_30 font300 pdb_20 pdb_40">{service.sComment1}</span>
        <span className="font_24 font500 font_color_1 pdb_10 text_center">{service.benefit}</span>
        <span className="font_20 font300 font_color_gray">{service.sCommnet2}</span>
      </div>
      <div className="inner_content_wrap" id="function_33">
        <ul className="function_3_ul">
          {functionSys.map((item, i) =>
            <li key={i}>
            <img src={item.img} />
              <div className="function_3_li_text">
                <span className="font_48 font600 font_color_white text_center pdb_70">{item.number}</span>
                <span className="font_30 font600 font_color_black text_center pdb_20">{item.label}</span>
                <span className="font_20 font300 font_color_gray text_center">{item.comment}</span>
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  )
}