import React from "react";
import { isStorageType } from "../../../function/kiosk";
import { StorageNumberContainer } from "../../../styled/storage";
import { useRegex } from "../../../../../../../hooks/useRegex";

const StorageNumber = ({ transformValue, storedItems, handleBackButton, getPrintElement }) => {
  const { TelFormat } = useRegex();

  const itemsByType = storedItems.reduce((acc, item) => {
    if (!acc[item.type]) {
      acc[item.type] = [];
    }
    acc[item.type].push(item.storageId);
    return acc;
  }, {});

  return (
    <StorageNumberContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="info">보관되었습니다.</div>
      <div className="storageInfo" id="storageLabel">
        {storedItems.length > 0 && (
          <>
            <div>
              <span className="tit">예약번호: </span>
              <span className="con">{storedItems[0]?.reserveId}</span>
            </div>
            <div>
              <span className="tit">이름: </span>
              <span className="con">{storedItems[0]?.mberNm}</span>
            </div>
            <div>
              <span className="tit">연락처: </span>
              <span className="con">{TelFormat(storedItems[0]?.tel)}</span>
            </div>
          </>
        )}
        <div>
          <div className="label">보관방식</div>
          {Object.keys(itemsByType).map((type, index) => (
            <div key={index}>
              <span className="tit">{isStorageType(type, "label")}: </span>
              <span className="con">{itemsByType[type].join(", ")}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="buttons">
        <button className="checkButton" onClick={() => getPrintElement(storedItems)}>
          재출력
        </button>
        <button onClick={handleBackButton} className="checkButton">
          확인
        </button>
      </div>
    </StorageNumberContainer>
  );
};

export default StorageNumber;
