import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const Navigation = ({ menuItems, setClickSideMenu }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.data[0]);

  const handlEChanggo = (e) => {
    e.preventDefault();

    if (!user) {
      navigate("/login");
    } else {
      openMyPage();
    }
  };

  const openMyPage = async () => {
    const url = "/api/domainSettingLoad";
    const req = {
      delYn: "N",
      cmpnyNo: user.cmpnyNo,
    };

    const res = await axios.post(url, req);
    if (res.data.domain) {
      window.open(`https://1472.ai/shop/${res.data.domain}`);
      user && localStorage.setItem("user", JSON.stringify(user));
    } else {
      navigate("/mypage/homepage");
    }
  };

  return (
    <nav>
      <ul>
        {menuItems.map((menu, index) => (
          <li key={index} className="dropdown">
            <Link
              to={menu.link || "/"}
              onClick={() => {
                if (menu.action && typeof menu.action === "function") {
                  dispatch(menu.action(0));
                }
              }}>
              {menu.title}
            </Link>
            {menu.items && (
              <ul className="dropdown-menu">
                {menu.items.map(
                  (item, i) => (
                    // item.label === "그룹" ? (
                    //   <li key={i}>
                    //     <Link
                    //       to={item.link}
                    //       target="_blank"
                    //       onClick={() => user && localStorage.setItem("user", JSON.stringify(user))}>
                    //       {item.label}
                    //     </Link>
                    //   </li>
                    // ) : item.label === "e층창고" ? (
                    //   <li key={i}>
                    //     <Link onClick={(e) => handlEChanggo(e)}>{item.label}</Link>
                    //   </li>
                    // ) : (
                    <li key={i}>
                      <Link
                        to={item.link}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (menu.action && typeof menu.action === "function") {
                            dispatch(menu.action(i));
                          }
                          setClickSideMenu(false);
                        }}>
                        {item.label}
                      </Link>
                    </li>
                  ),
                  // ),
                )}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
