import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "./sideMenu";
import { setFunctionIndex, setServiceIndex, setApplicationIndex } from "../store/compoIndex";
import { onClickLogout } from "../store/user";
import { resetSetting } from "../store/scheduler";
import { HeaderContainer } from "./styled/common";
import { MdMenu } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import AuthButtons from "./header/authButtons";
import Navigation from "./header/navigation";

const Header = () => {
  const [clickSideMenu, setClickSideMenu] = useState(false);
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const { pathname } = useLocation();
  const extracted = pathname.includes("/info/") ? pathname.split("/info/")[1] || "" : "";

  const services = [
    { link: "/info/group", label: "모임" },
    { link: "/info/scheduler", label: "캘린더" },
    { link: "/info/project", label: "프로젝트" },
    { link: "/info/shop", label: "e층창고" },
  ];

  const menuItems = [
    { title: "서비스", link: "#", items: services },
    { title: "공지/안내", link: "/notice", items: noticeBanner },
  ];

  useEffect(() => {
    if (clickSideMenu) {
      document.documentElement.style.height = "100vh";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.height = "auto";
      document.documentElement.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [clickSideMenu]);

  return (
    <HeaderContainer className={extracted}>
      <div className="headerInner">
        <h1>{service.welcome}</h1>
        <Link to="/" className="logo">
          <div>MONOTI</div>
        </Link>
        {!isTablet ? (
          <>
            <Navigation menuItems={menuItems} setClickSideMenu={setClickSideMenu} />
            <div className="headerBtnArea">
              <AuthButtons />
            </div>
          </>
        ) : (
          <button onClick={() => setClickSideMenu(true)} className="sideMenu">
            <MdMenu />
          </button>
        )}
      </div>
      <SideMenu
        setClickSideMenu={setClickSideMenu}
        clickSideMenu={clickSideMenu}
        menuItems={menuItems}
      />
    </HeaderContainer>
  );
};
export default Header;
