import React from "react";

const ProjectDetailParticipantSearched = ({ searchedList, handleSearchedMber }) => {
  return (
    <ul className="searched">
      {searchedList.slice(0, 8).map((item) => (
        <li onClick={() => handleSearchedMber(item)}>
          <span>{item.searchWord}</span>
        </li>
      ))}
    </ul>
  );
};

export default ProjectDetailParticipantSearched;
