import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";

import { FilterHandler } from "../styled/common";

import ProductFilter from "./common/productFilter";
import ProductDesktop from "./productForm/productDesktop";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Modal from "../../../../../common/modal";
import EditProductModal from "./productForm/modal/editProductModal";
import DetailProduct from "./productForm/modal/detailProduct";
import ImageDetailViewer from "../../../../../common/imageDetailViewer";

export default function ProductForm({
  products,
  categories,
  categoriesObj,
  getProduct,
  filtered,
  setFiltered,
}) {
  const user = useSelector((state) => state?.user?.data[0]);
  const isMobile = useMediaQuery({ query: "(max-width:640px)" });
  const [pages, setPages] = useState({ limit: 10, page: 1 });
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [modalType, setModalType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isDetail, setIsDetail] = useState(false);
  const [viewCategory, setViewCategory] = useState(false);

  const changePages = ({ target, value }) => {
    setPages((data) => {
      const updatedPages = { ...data, [target]: value };
      return updatedPages;
    });
  };

  const handleCheck = (prd) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.some((item) => item.id === prd.id)
        ? prevSelected.filter((item) => item.id !== prd.id)
        : [...prevSelected, prd],
    );
  };

  const handleAllCheck = (isChecked) => {
    const startIndex = (pages.page - 1) * pages.limit;
    const currentProducts = products.slice(startIndex, startIndex + pages.limit);

    if (isChecked) {
      setSelectedProducts([...currentProducts]);
    } else {
      setSelectedProducts([]);
    }
  };

  useEffect(() => {
    console.log(selectedProducts);
  }, [selectedProducts]);

  const deleteProduct = async () => {
    if (!selectedProducts || !selectedProducts.length) {
      toast.error("선택된 상품이 없습니다.");
      return;
    }

    const url = "/erp/vendorProducts/delete";
    const body = {
      ids: selectedProducts.map((item) => item.id),
      cmpnyNo: user.cmpnyNo,
    };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      toast.success("선택하신 상품이 삭제되었습니다.");
      await getProduct();
      setSelectedProducts([]);
      setPages({ limit: 10, page: 1 });
    }
  };

  const handleModalState = (type, item) => {
    if (type) setModalType(type);
    if (item) setSelectedProduct(item);

    setOpenModal(!openModal);
  };

  const closeModal = async (flag = false) => {
    if (flag) await getProduct();
    setOpenModal(false);
    setSelectedProduct(null);
  };

  // 모달
  const getModalContent = () => {
    switch (modalType) {
      case "edit":
        return (
          <EditProductModal
            selectedProduct={selectedProduct}
            setIsDetail={setIsDetail}
            categories={categories}
            categoriesObj={categoriesObj}
            viewCategory={viewCategory}
            closeModal={closeModal}
          />
        );
      case "detail":
        return (
          <DetailProduct
            selectedProduct={selectedProduct}
            closeModal={closeModal}
            categories={categories}
            categoriesObj={categoriesObj}
            setIsDetail={setIsDetail}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    console.log(isDetail);
    console.log(selectedProduct);
  }, [isDetail]);

  return (
    <div className="hp_step_content">
      <Modal
        modalState={openModal}
        handleModalState={handleModalState}
        html={getModalContent()}
        w="600"
        h="auto"
      />
      {selectedProduct?.media && selectedProduct?.media.length > 0 && isDetail && (
        <ImageDetailViewer images={selectedProduct?.media} setIsDetail={setIsDetail} />
      )}
      <div className="hp_step_product_step">
        <span
          className="font_16 font400 text_left font_color_gray"
          style={isMobile ? { fontSize: "18px" } : {}}>
          <i className="fa fa-cubes" aria-hidden="true" />
          내가 연동한 제품 총<strong className="font_color_1 font400"> {products.length}</strong>개
        </span>
        <ProductFilter
          filtered={filtered}
          categories={categories}
          categoriesObj={categoriesObj}
          setFiltered={setFiltered}
        />
        <FilterHandler>
          <div className="filter">
            <select
              value={pages.limit}
              onChange={(e) =>
                changePages({ target: "limit", value: parseInt(e.target.value, 10) })
              }>
              <option value={10}>10개 보기</option>
              <option value={30}>30개 보기</option>
              <option value={50}>50개 보기</option>
            </select>
            <button className="filterBtn" onClick={() => getProduct()}>
              조회
            </button>
          </div>
          <button className="deleteBtn" onClick={deleteProduct}>
            삭제
          </button>
        </FilterHandler>
        <div className="hp_step_product_wrap">
          <ProductDesktop
            products={products}
            total={products.length}
            pages={pages}
            changePages={changePages}
            selectedProducts={selectedProducts}
            handleCheck={handleCheck}
            handleAllCheck={handleAllCheck}
            categoriesObj={categoriesObj}
            categories={categories}
            handleModalState={handleModalState}
          />
        </div>
      </div>
    </div>
  );
}
