export default function ProductionCase ({ref2}) {
  return (
    <>
      <div className="inner_title" id="function_2" ref={ref2}>
        <span className="font_48 font600 pdb_20">{service.case}</span>
        <span className="font_30 font300 pdb_20 pdb_40">{service.fComment2}</span>
      </div>
      <div className="inner_content_wrap" id="function_22">
        <ul className="function_1_ul">
          {functionPort.map((item, i) =>
            <li key={i}><img src={item.img} /></li>
          )}
        </ul>
      </div>
    </>
  )
}