import React from "react";
import { HiMiniEllipsisVertical } from "react-icons/hi2";
import { useSelector } from "react-redux";

const Mentions = ({ label, className, handleButtonClick }) => {
  return (
    <div>
      <div className="info">
        <span className="label">{label}</span>
        <button data-name={className} onClick={handleButtonClick}>
          <HiMiniEllipsisVertical />
        </button>
      </div>
      <ul>
        <li>멘션이 존재하지 않습니다.</li>
      </ul>
    </div>
  );
};

export default Mentions;
