import React, { useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { ProductFilterContainer } from "../../styled/common";
import useOutsideClick from "../../../../../../hooks/useOutsideClick";

const ProductFilter = ({ filtered, setFiltered, categories, categoriesObj }) => {
  const categoriesRef = useRef();
  const [viewCategory, setViewCategory] = useState(false);

  useOutsideClick(categoriesRef, () => setViewCategory(false));

  const treeCategoryView = (item) => {
    if (item.path.includes("/")) {
      const count = [...item.path].filter((item) => item === "/").length + 1;
      return `5px 10px 5px ${count * 15}px`;
    }
    return "5px 10px";
  };

  const changeInput = (e) => {
    const { name, value } = e.target;

    if (name === "minBasePrice" || name === "maxBasePrice") {
      const numericValue = value.replace(/,/g, "");
      if (/^\d*$/.test(numericValue)) {
        setFiltered((data) => ({ ...data, [name]: numericValue }));
      }
    } else {
      setFiltered((data) => ({ ...data, [name]: value }));
    }
  };

  const handleFocus = (e) => {
    const { name } = e.target;

    if (name === "minBasePrice" || name === "maxBasePrice") {
      setFiltered((data) => ({
        ...data,
        [name]: filtered[name] ? filtered[name].replace(/,/g, "") : "",
      }));
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;

    if (name === "minBasePrice" || name === "maxBasePrice") {
      setFiltered((data) => ({
        ...data,
        [name]: filtered[name] ? formatNumber(filtered[name]) : "",
      }));
    }
  };

  const formatNumber = (value) => {
    if (!value) return "";

    const numericValue = value.replace(/,/g, "");
    return Number(numericValue).toLocaleString();
  };

  const changeCategory = (e, item) => {
    e.stopPropagation();
    changeInput({ target: { name: "categoryId", value: item.categoryId } });
    setViewCategory(false);
  };

  return (
    <ProductFilterContainer>
      <table>
        <tbody>
          <tr>
            <th>카테고리</th>
            <td ref={categoriesRef}>
              <div className="selected" onClick={() => setViewCategory(!viewCategory)}>
                {filtered?.categoryId ? (
                  <span className="category">
                    {categoriesObj?.[filtered.categoryId]}
                    <button
                      className="deleteBtn"
                      onClick={() => setFiltered((data) => ({ ...data, categoryId: null }))}>
                      <IoMdClose />
                    </button>
                  </span>
                ) : (
                  <span className="not">카테고리를 선택해주세요</span>
                )}
              </div>
              {viewCategory && (
                <ul className="categories">
                  {categories.map((item) => (
                    <li
                      key={item.categoryId}
                      style={{ padding: treeCategoryView(item) }}
                      onClick={(e) => changeCategory(e, item)}>
                      <span>{item.categoryName}</span>
                    </li>
                  ))}
                </ul>
              )}
            </td>
            <th>가격</th>
            <td>
              <label className="price">
                <input
                  type="text"
                  name="minBasePrice"
                  value={filtered.minBasePrice ? formatNumber(filtered.minBasePrice) : ""}
                  onChange={changeInput}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  placeholder="최소금액"
                />
                <span>~</span>
                <input
                  type="text"
                  name="maxBasePrice"
                  value={filtered.maxBasePrice ? formatNumber(filtered.maxBasePrice) : ""}
                  onChange={changeInput}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  placeholder="최대금액"
                />
              </label>
            </td>
          </tr>
          <tr>
            <th>브랜드</th>
            <td>
              <input
                type="text"
                placeholder="브랜드를 입력해주세요"
                onChange={changeInput}
                name="brand"
              />
            </td>
            <th>상품명</th>
            <td>
              <input
                type="text"
                placeholder="상품명을 입력해주세요"
                onChange={changeInput}
                name="name"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </ProductFilterContainer>
  );
};

export default ProductFilter;
