import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HiMiniEllipsisVertical } from "react-icons/hi2";

const UnreadNotifications = ({ label, notification, className, handleButtonClick }) => {
  return (
    <div>
      <div className="info">
        <span className="label">{label}</span>
        <button data-name={className} onClick={handleButtonClick}>
          <HiMiniEllipsisVertical />
        </button>
      </div>
      <ul>
        {notification && notification.length > 0 ? (
          notification.map((item) => (
            <li>
              <span>{item.title}</span>
            </li>
          ))
        ) : (
          <li>미확인 알림이 존재하지 않습니다.</li>
        )}
      </ul>
    </div>
  );
};

export default UnreadNotifications;
