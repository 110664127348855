import React from "react";
import { CustomInput } from "../../../styled/common";
import { IoCloseOutline } from "react-icons/io5";

const CategoryList = ({
  categories,
  product,
  handleChangeRadio,
  treeCategoryView,
  viewCategory,
  setViewCategory,
  categoriesRef,
  resetCategory,
  categoriesObj,
}) => {
  return (
    <div
      className="inputxt_normal spider category"
      onClick={() => setViewCategory((data) => !data)}
      ref={categoriesRef}>
      <div className="selected">
        {product?.categoryId ? (
          <>
            <span>{categoriesObj[product.categoryId]}</span>
            <button onClick={resetCategory}>
              <IoCloseOutline />
            </button>
          </>
        ) : (
          <span className="not_category">카테고리를 선택해주세요.</span>
        )}
      </div>
      {viewCategory && (
        <ul>
          {categories &&
            categories.map((item) => (
              <li
                key={item.categoryId}
                className={item.categoryId === product.categoryId ? "active" : ""}
                style={{ padding: treeCategoryView(item) }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleChangeRadio(item);
                }}>
                <CustomInput>
                  <input
                    type="radio"
                    name="categoryId"
                    value={item.categoryId}
                    checked={item.categoryId === product.categoryId}
                    onChange={() => handleChangeRadio(item)}
                  />
                  <span className="checkmark"></span>
                </CustomInput>
                <span>{item.categoryName}</span>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default CategoryList;
