import React from "react";
import { useSelector } from "react-redux";

const ModalContent = ({ modalMsg: msg, handleConfirm, handleCancel, isDday = false }) => {
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);
  const eventType = isDday ? "디데이" : "일정";

  const getContent = (type, action) => `${chooseParticle(type, "을를")} ${action}하시겠습니까?`;

  const modalDetails = {
    exit: { title: "나가기", content: "작성을 취소하시겠습니까?" },
    eventDrop: { title: "일정 변경", content: "일정을 변경하시겠습니까?" },
    eventDel: { title: `${eventType} 삭제`, content: getContent(eventType, "삭제") },
    eventEdit: { title: `${eventType} 수정`, content: getContent(eventType, "수정") },
    eventSave: { title: `${eventType} 등록`, content: getContent(eventType, "등록") },
    eventCopy: { title: `${eventType} 복사`, content: getContent(eventType, "복사") },
    eventTodoCopy: {
      title: `일정 할일 복사`,
      content: `일정의 할일을 복사하시겠습니까?`,
    },
    grpJoin: { title: "모임 참여", content: "모임 일정에 참여하시겠습니까?" },
    grpJoinCancel: { title: "참여 취소", content: "일정 참여를 취소하시겠습니까?" },
    default: { title: "오류", content: "알 수 없는 오류가 발생했습니다." },
    overwrite: {
      title: "일정 복구",
      content: "작성하던 내용이 있습니다.\n이어서 작성하시겠습니까?",
    },
    imgUpload: {
      title: "이미지 업로드",
      content: "업로드되지 않은 이미지가 있습니다.\n업로드하시겠습니까?",
    },
  };

  const modalInfo = modalDetails[msg] || modalDetails.default;

  return (
    <div>
      <h2
        className="font_20 font500 font_color_white"
        style={{ background: isDarkMode && "#533670" }}>
        {modalInfo.title}
      </h2>
      <button onClick={handleCancel} className="hp_step_setting_wrap_close">
        <i className="fa fa-times" aria-hidden="true" />
      </button>
      <span
        className="font_20 font300 font_color_gray pdt_20 text_center"
        style={{ color: isDarkMode && "#efefef" }}>
        {modalInfo.content && modalInfo.content.includes("\n")
          ? modalInfo.content.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))
          : modalInfo.content}
      </span>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
        <button
          onClick={handleCancel}
          className="hp_step_setting_cate_save"
          style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
          취소
        </button>
        <button
          onClick={handleConfirm}
          className="hp_step_setting_cate_save"
          style={{ padding: "10px 20px", margin: "0px", background: isDarkMode && "#533b70" }}>
          확인
        </button>
      </div>
    </div>
  );
};

export default ModalContent;
