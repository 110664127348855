import { useOutletContext } from "react-router";

import BoardView from "../../../../common/board/boardView";
import ModernBanner from "../../shopping/component/themes/modern/banner";
import CuteBanner from "../../shopping/component/themes/cute/banner";

import { ModernNoticeViewContainer } from "../../shopping/component/styled/modernTheme";
import { CuteNoticeViewContainer } from "../../shopping/component/styled/cuteTheme";
import { ChicNoticeViewContainer } from "../../shopping/component/styled/chicTheme";
import SimpleBanner from "../../shopping/component/themes/simple/banner";

const ShoppingNoticeView = () => {
  const { theme } = useOutletContext();

  const NoticeView = () => {
    return (
      <main id="noticeView" className="sub_page sub_page_mobile_mt">
        <div className="subTop_bnr subTop_bnr_01">{theme !== "A" && <span>NEWS</span>}</div>
        <article className="boundary pdt_70 pdb_70">
          <BoardView isShop={true} />
        </article>
      </main>
    );
  };

  const renderComponent = () => {
    switch (theme) {
      case "A":
        return <NoticeView />;
      case "B":
        return (
          <ChicNoticeViewContainer>
            <NoticeView />
          </ChicNoticeViewContainer>
        );
      case "C":
        return (
          <ModernNoticeViewContainer>
            <ModernBanner />
            <NoticeView />
          </ModernNoticeViewContainer>
        );
      case "D":
        return (
          <CuteNoticeViewContainer>
            <CuteBanner />
            <NoticeView />
          </CuteNoticeViewContainer>
        );
      case "E":
        return (
          <CuteNoticeViewContainer>
            <SimpleBanner />
            <NoticeView />
          </CuteNoticeViewContainer>
        );
      default:
        return <NoticeView />;
    }
  };

  return <>{renderComponent()}</>;
};

export default ShoppingNoticeView;
