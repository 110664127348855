import styled from "styled-components";

const darkSubColor = "#2b2b2b";
const ef = "#efefef";

export const GoToBackHeader = styled.div`
  &.dark {
    border-bottom: 1px solid ${darkSubColor};
  }
  font-size: 18px;
  font-weight: 400;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${ef};
  position: relative;
  .icon {
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px 0 0;
    cursor: pointer;
    svg {
      display: block;
    }
  }
  .selectTypeArea {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: flex;
    gap: 15px;
    button {
      border: none;
      background: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 25px;
      span {
        font-size: 10px;
      }
    }
  }
`;

export const HolidayLayerContainer = styled.div`
  &.dark {
    .content {
      background: ${darkSubColor};
    }
  }
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
  .content {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    background: #fff;
    .title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 25px;
      margin-bottom: 20px;
      img {
        width: 40px;
      }
    }
    .desc {
      font-size: 16px;
      line-height: 23px;
    }
  }

  @media screen and (max-width: 767px) {
    .content {
      width: 300px;
      padding: 15px;
    }
  }
`;
