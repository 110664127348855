import React, { useEffect, useState, useRef } from "react";
import "swiper/swiper-bundle.min.css";
import "swiper/css/pagination";
import { parseHTMLContent } from "../../function/shopping";
import { useNavigate } from "react-router";

const Introduce = ({ homepageInfo, url }) => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({ image: null, text: null });

  useEffect(() => {
    if (homepageInfo && homepageInfo.cmpnyIntroduce) {
      const cmpnyIntroduce = parseHTMLContent(homepageInfo.cmpnyIntroduce);
      setInfo({ ...cmpnyIntroduce });
    }
  }, [homepageInfo]);

  const goToCmpnyInfoPage = () => {
    navigate(`/shop/${url}/company`);
  };

  return (
    <div className="introduce">
      <div className="label">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        INTRODUCE
      </div>
      <div className="introduceContent">
        <div className="bg"></div>
        <div className="box">
          <div className="viewarea">
            <img src={info.image ? info.image : "/img/shop_main_bg3.jpg"} alt="" />
          </div>
          <div className="content">
            {info.text ? (
              <span dangerouslySetInnerHTML={{ __html: info.text }}></span>
            ) : (
              <span>
                안녕하세요,
                <br />
                {homepageInfo.cmpnyNm}입니다.
              </span>
            )}
            <button onClick={goToCmpnyInfoPage}>자세히보기</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduce;
