import React, { useEffect, useState } from "react";

import { Container } from "./component/styled/common";
import { SalesCheckContainer } from "./component/styled/salesCheck";

import MutualBenefitHeader from "./component/common/mutualBenefitHeader";
import SideMenu from "./component/salesCheck/sideMenu";
import SalesCheckDetails from "./component/salesCheck/salesCheckDetails";

import { useDisableScroll } from "../kiosk/component/function/kiosk";
import { useSelector } from "react-redux";
import axios from "axios";

const SalesCheck = () => {
  useDisableScroll();

  const user = useSelector((state) => state?.user?.data[0]);

  const [active, setActive] = useState("orderHistory");
  const tabs = {
    orderHistory: "주문내역",
    salesCheck: "매출확인",
    statistics: "주문통계",
    settlementForm: "정산신청",
  };
  const [cmpny, setCmpny] = useState([]);

  useEffect(() => {
    getCmpnyNm();
  }, []);

  const getCmpnyNm = async () => {
    const url = "/api/cmpnyNmLoad";
    const body = { cmpnyNo: user.cmpnyNo };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      setCmpny([res.data]);
    }
  };

  return (
    <Container>
      <SalesCheckContainer>
        <MutualBenefitHeader />
        <div className="container">
          <SideMenu active={active} setActive={setActive} tabs={tabs} cmpny={cmpny} />
          <SalesCheckDetails active={active} tabs={tabs} />
        </div>
      </SalesCheckContainer>
    </Container>
  );
};

export default SalesCheck;
