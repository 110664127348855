import NoticeWriteForm from "../../../../common/noticeWriteForm";
import FaqTitle from "./faqTitle";

export default function FaqWriteForm() {
  return (
    <div className="inner_content">
      <FaqTitle />
      <NoticeWriteForm />
    </div>
  );
}
