import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { GoFile, GoProjectRoadmap } from "react-icons/go";
import { ProjectSideMenuContainer } from "../styled/project";

const ProjectSideMenu = ({ showSideMenu, setShowSideMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  const projectMainPageMenus = [
    { name: "내 프로젝트", icon: GoProjectRoadmap, path: "/mypage/projectList" },
    { name: "파일함", icon: GoFile, path: "/mypage/projectFileBox" },
  ];

  const handleMenuClick = (path) => {
    if (location.pathname === path) {
      window.location.reload();
    } else {
      navigate(path);
    }
  };

  return (
    <ProjectSideMenuContainer
      style={{ transform: `translateX(${!isTablet || showSideMenu ? "0%" : "-100%"})` }}>
      <div className="sideMenuContent">
        <div className="projectSection">
          <button className="createProjectButton" onClick={() => navigate("/mypage/projectCreate")}>
            새 프로젝트 생성
          </button>
        </div>
        <ul className="menus">
          {projectMainPageMenus.map((item) => (
            <li onClick={() => handleMenuClick(item.path)}>
              <item.icon />
              <span>{item.name}</span>
            </li>
          ))}
        </ul>
      </div>
      {isTablet && <div className="bg" onClick={() => setShowSideMenu(false)}></div>}
    </ProjectSideMenuContainer>
  );
};

export default ProjectSideMenu;
