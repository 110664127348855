import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router";

const GroupFeedSwiper = ({ img, handleModal }) => {
  const location = useLocation();

  return (
    <Swiper
      pagination={true}
      modules={[Pagination]}
      className="mySwiper"
      style={{
        width: "100%",
        height: "100%",
      }}>
      {img.map((item, i) => (
        <SwiperSlide key={i}>
          <div className="swiper-wrapper">
            <div className="slide swiper-slide" id={i}>
              <div
                className="slide_text_warp text_center"
                style={{
                  height: "100%",
                }}>
                {location.pathname.includes("write") && (
                  <div
                    className="deleteBox"
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      fontSize: 24,
                      cursor: "pointer",
                    }}
                    onClick={() => handleModal(item)}>
                    <AiOutlineClose />
                  </div>
                )}
                <img
                  src={imgHandler(typeof item === "string" ? item : item.path) || ""}
                  alt=""
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "calc(100% - 30px)",
                  }}
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default GroupFeedSwiper;
