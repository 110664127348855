import React from "react";
import { ContactUsContainer } from "../styled/community";
import SwiperList from "../common/swiperList";
import { useMediaQuery } from "react-responsive";

const ContactUs = () => {
  const temp = Array.from({ length: 9 }).fill("1");
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  return (
    <ContactUsContainer className="viewerContiner">
      <div className="label">
        <span>문의 글</span>
        <button>문의하기</button>
      </div>
      <SwiperList data={temp} range={isTablet ? 2 : 3} type="contactUs" />
    </ContactUsContainer>
  );
};

export default ContactUs;
