import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import { useNavigate } from "react-router";

const Portfolio = ({ cmpnyNo, url }) => {
  const [portfolio, setPortfolio] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (cmpnyNo) {
      getPortfolio(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getPortfolio = async (cmpnyNo) => {
    const url = "/api/portMgmtList";
    const body = {
      cmpnyNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 1,
      paged: true,
    };

    try {
      const res = await axios.post(url, body);
      const data = res?.data?.content?.[0] || null;
      setPortfolio(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="portfolio">
      <div className="info">
        Portfolio
        <span>더 많은 상품은 화살표를 눌러주세요.</span>
      </div>
      {portfolio && (
        <div
          className="inner"
          onClick={() => navigate(`/shop/${url}/portfolioView/${portfolio.portNo}`)}>
          <div className="left">
            <img src={portfolio ? imgHandler(portfolio.imgUrl) : "/img/not_prdct.png"} />
          </div>
          <div className="right">
            <button onClick={() => navigate(`/shop/${url}/portfolio?page=0`)}>
              <GoArrowUpRight />
            </button>
            {portfolio && (
              <div className="portfolioInfo">
                <span className="tit">{portfolio.title}</span>
                <span className="content">
                  {portfolio.cmpnyNm}
                  <br />
                  {portfolio.adres}
                  <br />
                  {moment(portfolio.createDt).format("YYYY-MM-DD")}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Portfolio;
