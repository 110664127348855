import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { ScheduleSwiperContainer } from "../../../styled/dashboard";
import { isOutsideCmpny } from "../../../../../scheduler/component/function/common";

const ScheduleSwiper = ({ data = [], perView = 1 }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const displayTime = (item) => {
    const today = moment();
    const start = moment(item.startDt);
    const end = moment(item.endDt);

    if (item.dday === "Y") {
      const diffDays = today.diff(start, "days");
      return (
        <>
          <span className="text_overflow">
            {diffDays === 0 ? "D-day" : diffDays > 0 ? `D+${diffDays}` : `D${diffDays}`}
          </span>
          <span className="ddayDate">
            {isMobile ? start.format("MM.DD") : start.format("YYYY.MM.DD")}
          </span>
        </>
      );
    }

    if (
      (start.format("HH:mm:ss") === "00:00:00" && end.format("HH:mm:ss") === "23:59:59") ||
      (today.isAfter(start, "day") && today.isBefore(end, "day"))
    ) {
      return (
        <span className="text_overflow" style={{ fontSize: isMobile ? 12 : 16 }}>
          하루종일
        </span>
      );
    }

    if (today.isSame(start, "day")) {
      return (
        <>
          <span>{start.format("HH:mm")}</span>
          <span className="endOfStart">시작</span>
        </>
      );
    }

    if (today.isSame(end, "day")) {
      return (
        <>
          <span>{end.format("HH:mm")}</span>
          <span className="endOfStart">종료</span>
        </>
      );
    }

    return null;
  };

  const isColor = (item) => {
    if (!item.color) return "#bbb";
    if (item.gbn === "C") return "#0074ca";
    if (item.gbn === "G") return "#f06f02";

    return `#${item.color}`;
  };

  return (
    <ScheduleSwiperContainer>
      <Swiper
        slidesPerView={perView}
        spaceBetween={10}
        // loop={true}
        className="mySwiper">
        {data.map((item, i) => (
          <SwiperSlide key={i}>
            <div
              className={`scheduleContent${isOutsideCmpny(item, user) ? " outside" : ""}`}
              style={{ borderLeft: `5px solid ${isColor(item)}` }}
              onClick={() => navigate(`/mypage/detailEvent/${item.scheduleNo}`)}>
              <div className="timeArea">{displayTime(item)}</div>
              <div className="contentArea">
                <span>{item.title}</span>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </ScheduleSwiperContainer>
  );
};

export default ScheduleSwiper;
