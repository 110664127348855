import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import useQueryString from "../../hooks/useQueryString";

const BoardTable = ({ notices, isShop, url, queryString}) => {
  const navigater = useNavigate();
  const isMobile = useMediaQuery({query: "(max-width:767px)"});
  const queryPage = Number(useQueryString('page'));

  const moveToPage = (noticeNo) => {
    if(!isShop){
      navigater(`/mypage/registerNoticeView/${noticeNo}`);
    } else {
      if(queryString){
        navigater(`/shop/${url}/shoppingNoticeView/${noticeNo}`, {state : { keyword : queryString}});
      } else {
        navigater(`/shop/${url}/shoppingNoticeView/${noticeNo}`, {state : {page : queryPage}});
      }
    }
  };

  return (
    <table className="board_table">
      <caption>공지사항</caption>
      <thead>
        <tr>
          {boardFaq.map((item, i) =>
            <th scope="col" key={i}>{item.label}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {
          (!isMobile && !isShop && notices.length !== 0) && 
          notices.map((item, index) => 
          <tr key={index} style={{width : "100%", cursor : "pointer"}} onClick={() => moveToPage(item.noticeNo)}>
            <td className="td_width2">{item.noticeNo}</td>
            <td className="td_width">{item.noticeCl}</td>
            <td className="td_title" style={{textAlign: isMobile ? "left" :"center"}} >{item.noticeSj}</td>
            <td className="td_width" style={isMobile ? {textAlign : "left"} : {}}>{item.createMberId}</td>
            <td className="td_width">{item.updateDt || item.createDt}</td>
          </tr>)
        }
        {
          (!isMobile && isShop && notices.length !== 0) && 
          notices.map((item, index) => 
          <tr key={index} style={{width : "100%", cursor : "pointer"}} onClick={() => moveToPage(item.noticeNo)}>
            <td style={{width : "5%"}}>{item.noticeNo}</td>
            <td style={{width : "10%"}}>{item.noticeCl}</td>
            <td className="td_title" style={{textAlign: "center", width: "60%"}} >{item.noticeSj}</td>
            <td style={{width : "5%"}}>{item.createMberId}</td>
            <td style={{width : "20%"}}>{item.updateDt || item.createDt}</td>
          </tr>)
        }
        {
          (isMobile && notices.length !== 0) && 
          notices.map((item, index) => 
          <tr key={index} className="board_mobile_tr" onClick={() => moveToPage(item.noticeNo)}>
            <td className="td_title">{item.noticeSj}</td>
            <td className="board_mobile_td_blue">{item.noticeCl}</td>
            <td className="board_mobile_td_gray">|</td>
            <td className="td_width">{item.updateDt || item.createDt}</td>
          </tr>)
        }
        {
          notices.length === 0 && 
          <tr style={{width : "100%", background : "white"}}>
            <td style={{width : "100%"}} colSpan={5} className="table_data_empty">등록된 공지사항이 없습니다</td>
          </tr>
        }
      </tbody>
    </table>
  );
};

export default BoardTable;