import React, { useEffect } from "react";

const PreviewModal = ({ preview, setPreview }) => {
  // useEffect(() => {
  //   const scrollY = window.scrollY;

  //   document.body.style.overflow = "hidden";

  //   return () => {
  //     document.body.style.overflow = "";
  //     window.scrollTo(0, scrollY);
  //   };
  // }, []);

  return (
    <div className="previewModal" onClick={() => setPreview(null)}>
      <div className="content">
        <img src={`/img/shopTheme/shop_${preview.label}.png`} alt={preview.label} />
      </div>
    </div>
  );
};

export default PreviewModal;
