import React, { useRef, useState } from "react";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

import { FaChevronDown } from "react-icons/fa";

import { StorageContainer } from "./component/styled/selfService";
import { roundMinutes, useDisableScroll } from "../mypage/kiosk/component/function/kiosk";
import { useRegex } from "../../hooks/useRegex";

import Header from "./component/common/header";
import NameFields from "./component/common/nameFields";
import TelFields from "./component/common/telFields";
import StorageType from "./component/storage/storageType";
import ActionConfirmation from "./component/common/actionConfirmation";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";

const Storage = () => {
  useDisableScroll();

  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [active, setActive] = useState(0);
  const transformValue = active * -100;
  const [formData, setFormData] = useState({ mberNm: "", tel: "" });
  const [chosenStorage, setChosenStorage] = useState({ 0: 0, 1: 0, 2: 0 });
  const total = Object.values(chosenStorage).reduce((acc, value) => acc + value, 0);
  const isDuplicateCheck = useRef(false);

  const inputFields = [
    { id: 0, label: "이름" },
    { id: 1, label: "전화번호" },
    { id: 2, label: "보관방법" },
  ];

  const determineStepAction = (isNext = false) => {
    if ((isNext && active > 2) || (!isNext && active < 1) || (!isNext && active === 3)) {
      return;
    }

    if (isNext) {
      if (!stepCheck()) return;
      if (active === 2) {
        handleReserve();
      } else {
        setActive((data) => data + 1);
      }
    } else {
      setActive((data) => data - 1);
    }
  };

  // 이동 예외처리
  const stepCheck = () => {
    if (active === 0) {
      const vowelOnlyRegex = /^[ㅏ-ㅣ]*$/;
      const nameRegex = /^[a-zA-Z가-힣\s]+$/;
      const name = formData.mberNm?.trim();

      if (!name) {
        toast.error("이름을 입력해주세요.");
        return false;
      }
      if (name.length < 2 || name.length > 6) {
        toast.error("이름은 2자 이상 6자 이하로 입력해주세요.");
        return false;
      }
      if (!nameRegex.test(name) || vowelOnlyRegex.test(name)) {
        toast.error("이름에는 한글 자음 및 모음만 포함할 수 없습니다.");
        return false;
      }
    }

    if (active === 1) {
      const telRegex = /^(010|011)-?[0-9]{3,4}-?[0-9]{4}$/;
      const tel = formData.tel;

      if (!tel || tel.length < 10 || tel.length > 11 || !telRegex.test(tel)) {
        toast.error("유효한 전화번호를 입력해주세요.");
        return false;
      }
    }

    if (active === 2 && !total) {
      toast.error("보관방법을 선택해주세요.");
      return false;
    }

    return true;
  };

  const handleChangeInput = (type, value) => {
    setFormData((data) => ({ ...data, [type]: value }));
  };

  // 예약하기
  const handleReserve = async () => {
    if (isDuplicateCheck.current) {
      toast.error("예약 처리 중입니다. 잠시만 기다려 주세요.");
      return;
    }

    isDuplicateCheck.current = true;

    try {
      const start = roundMinutes();
      const url = "/api/reserve";
      const body = [];

      Object.entries(chosenStorage).forEach(([type, quantity]) => {
        if (quantity !== 0) {
          body.push({
            mberNm: formData.mberNm,
            os: "web",
            payType: 1,
            quantity: quantity,
            reservationDay: moment().format("YYYYMMDD"),
            reservationStartTime: start,
            reservationTime: 180,
            tel: formData.tel,
            type: type,
          });
        }
      });

      const res = await axios.post(url, body);
      if (res.status === 200) {
        setActive(3);
      }
    } catch (error) {
      toast.error("예약이 실패하였습니다. 잠시 후 다시 시도해주세요.");
      console.error("예약 실패:", error);
    } finally {
      isDuplicateCheck.current = false;
    }
  };

  return (
    <StorageContainer>
      <Header title="고객 정보 입력" handleBackButton={determineStepAction} />
      <div className="content">
        {active < 3 && (
          <div className="sidemenu">
            <ul>
              {inputFields.map((item, index) => (
                <>
                  <li className={active === index ? "active" : ""}>{item.label} 입력</li>
                  {index !== inputFields.length - 1 && (
                    <li>
                      <FaChevronDown />
                    </li>
                  )}
                </>
              ))}
            </ul>
            <button onClick={() => determineStepAction(true)}>다음</button>
          </div>
        )}
        <div
          className="slideWrapper"
          style={{ width: active < 3 && !isMobile ? "calc(100% - 375px)" : "100%" }}>
          <NameFields
            transformValue={transformValue}
            handleChangeInput={handleChangeInput}
            formData={formData}
          />
          <TelFields
            transformValue={transformValue}
            handleChangeInput={handleChangeInput}
            formData={formData}
          />
          <StorageType
            transformValue={transformValue}
            setChosenStorage={setChosenStorage}
            chosenStorage={chosenStorage}
            total={total}
          />
          <ActionConfirmation
            transformValue={transformValue}
            title="보관신청완료"
            explanation={["보관 신청이 완료되었습니다.", "카운터로 가셔서 직원에게 말씀해 주세요."]}
            onConfirm={() => navigate("/self-service")}
          />
        </div>
      </div>
    </StorageContainer>
  );
};

export default Storage;
