import HomepageEndTitle from "./homepageEndTitle";
import HomepageTip from "./homepageTip";

export default function HomepageEndForm(){
  return(
    <div className="inner_content">
      <div className="inner_content_wrap">
        <HomepageEndTitle />
        <HomepageTip />
      </div>
    </div>
  )
}