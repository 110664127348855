import { useEffect, useState } from "react";
import { useRegex } from "../../../hooks/useRegex";
import { useMediaQuery } from "react-responsive";
import { MemberSearchContainer } from "./styled/member";

import Pagination from "react-js-pagination";

export default function MemberSearch({ members, getShopsInfo, noSearchResult }) {
  const isFontSize = useMediaQuery({ query: "(max-width:1250px) and (min-width: 1050px)" });
  const { TelFormat } = useRegex();

  const [selected, setSelected] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [search, setSearch] = useState(false);

  const onKeyDown = (e) => {
    if (e.nativeEvent.isComposing) return;
    if (e.key !== "Enter") {
      return;
    }
    searchFlag(1, "k");
  };

  const shopsLink = (domain) => {
    window.open(`https://1472.ai/shop/${domain}`);
  };

  const searchFlag = (num, key) => {
    if (search) {
      getShopsInfo(false, num, keyword);
    } else {
      if (key) {
        num = 1;
        setSearch(true);
        getShopsInfo(false, num, keyword);
      } else {
        getShopsInfo(false, num);
      }
    }
    setSelected(num);
    const marker = document.querySelector(".marker");
    if (marker) {
      marker.scrollIntoView();
    }
  };

  const isBannerImages = (bannerImgList) => {
    const defaultImage = "/img/function_11_bg.png";
    const imageList = JSON.parse(bannerImgList);

    if (!bannerImgList || !imageList || !imageList.length) return defaultImage;
    return imgHandler(imageList);
  };

  return (
    <MemberSearchContainer>
      <div className="marker" />
      <div className="inner_title" id="member_2" style={{}}>
        <span className="font_48 font600 font_color_black pdb_40">회원사 검색</span>
      </div>
      <div className="inner_content_wrap" id="member_22">
        <div className="search_box">
          <fieldset>
            <legend>검색영역</legend>
            <div className="basic">
              {/* <select name="search_type" id="search_add1">
            <option value="A">지역</option>
            </select>
            <select name="search_type" id="search_add2">
            <option value="A">시/군/구 선택</option>
            </select> */}
              <input
                type="text"
                name="keyword"
                placeholder="매장명을 입력하세요."
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyDown={(e) => onKeyDown(e)}
              />
              <button onClick={() => searchFlag(1, "k")}>
                <span className="font_20 font300 font_color_white">검색</span>
              </button>
            </div>
          </fieldset>
        </div>
        {/* <ul
        className="member_result"
        style={{
        width: "35%",
        height: "10%",
        padding: "unset",
        marginTop: "1%",
        border: "none"
        }}
        >
        <li style={{marginLeft: "3%"}}>포토폴리오 많은순</li>
        <li>최근 가입순</li>
        <li><a>가나다순</a></li>
        </ul> */}
        <div
          className="member_search"
          style={{
            marginTop: "2%",
            marginBottom: "2%",
          }}>
          {noSearchResult && (
            <div
              className="font_24 font500"
              dangerouslySetInnerHTML={noSearchResult}
              style={{ textAlign: "center" }}
            />
          )}
          {members?.content?.map((item, i) => (
            <div key={i} className="member_search_wrap" onClick={() => shopsLink(item.domain)}>
              <span
                className="member_location font_16 font500 font_color_white"
                style={{ top: "3%" }}>
                대전
              </span>
              <div className="mem_logo2">
                {item.logoImage ? (
                  <img className="member_logo" src={imgHandler(item.logoImage)} />
                ) : (
                  <img className="member_logo" src="/img/member_nologo_copy.png" />
                )}
              </div>
              <span className="font_24 font600 font_color_black pdb_10 text_center text_overflow">
                {item.cmpnyNm ?? ""}
              </span>
              <span className={`font_16 font300 font_color_gray text_center text_overflow adres`}>
                {item.adres}
              </span>
              <div className="member_info font_18 font500 font_color_gray text_center text_overflow">
                <span className="font_18">{item.rprNm ? `대표자: ${item.rprNm}` : ""}</span>
                <br />
                <span className={isFontSize ? "font_14" : "font_18"}>
                  {item.telNo ? `전화: ${TelFormat(item.telNo)}` : "전화번호가 존재하지 않습니다."}
                </span>
              </div>
              <div className="member_info_img">
                <img
                  src={isBannerImages(item.bannerImgList)}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
              <p className="member_search_btn font_20 font500 font_color_white" id="mobile_none">
                <span className="homepage_link">홈페이지 바로가기</span>
              </p>
            </div>
          ))}
        </div>
      </div>
      <Pagination
        activePage={selected}
        itemsCountPerPage={8}
        totalItemsCount={members?.totalElements}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={searchFlag}
      />
    </MemberSearchContainer>
  );
}
