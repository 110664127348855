import moment from "moment";
import "moment/locale/ko";
import { isSameUser } from "./common";
import axios from "axios";

// 선택된 날짜, 시작/종료 날짜 비교
export const timeByDate = (selected, startDt, endDt) => {
  const select = moment(selected);
  const start = moment(startDt);
  const end = moment(endDt);

  const formatTime = (date) => date.format("A HH:mm");
  const isFullDay =
    start.format("HH:mm:ss") === "00:00:00" && end.format("HH:mm:ss") === "23:59:59";
  const isSelectedStart = select.isSame(start, "day");
  const isSelectedEnd = select.isSame(end, "day");

  if (isFullDay) {
    return "하루종일";
  }
  if (isSelectedStart && isSelectedEnd) {
    return `${formatTime(start)} ~ ${formatTime(end)}`;
  }
  if (isSelectedStart) {
    return `${formatTime(start)} 시작`;
  }
  if (isSelectedEnd) {
    return `${formatTime(end)} 종료`;
  }
  if (select.isAfter(start) && select.isBefore(end)) {
    return "하루종일";
  }
  return "";
};

// 시작 날짜, 종료 날짜 출력
export const showEventTime = (startDt, endDt) => {
  const start = moment(startDt);
  const end = moment(endDt);

  const startDate = start.format("M월 D일 dddd");
  const endDate = end.format("M월 D일 dddd");
  const startTime = start.format("a h:mm");
  const endTime = end.format("a h:mm");

  const isStart = start.format("HH:mm:ss") === "00:00:00";
  const isEnd = end.format("HH:mm:ss") === "23:59:59";
  const isAllDayEvent = isStart && isEnd;

  if (isAllDayEvent) {
    if (startDate === endDate) {
      return `${startDate} 하루종일`;
    } else {
      return `${startDate} ~ ${endDate} 하루종일`;
    }
  } else {
    if (startDate === endDate) {
      return `${startDate} ${startTime} ~ ${endTime}`;
    } else {
      return `${startDate} ${startTime} ~ ${endDate} ${endTime}`;
    }
  }
};

// 일정 분류
export const isEventCategory = (gbn) => {
  const categories = {
    P: "개인",
    C: "업무",
    G: "모임",
    O: "외부",
  };
  return categories[gbn];
};

// 특정 날짜에 해당하는 데이터 찾기
export const isSameDay = (target, start, end) => {
  if (end) {
    return (
      target.isSame(start, "day") ||
      target.isSame(end, "day") ||
      (target.isAfter(start) && target.isBefore(end))
    );
  } else {
    return target.isSame(start, "day");
  }
};

// 이벤트 모달창 위치
export const modalPosition = (rect, event, user) => {
  const modalWidth = 430;
  const modalHeight = calculateModalHeight(user, event);

  const center = {
    left: (window.innerWidth - modalWidth) / 2,
    top: (window.innerHeight - modalHeight) / 2,
  };

  const position = (start, end, size, maxSize, screenCenter) =>
    start - size > 0 ? start - size : end + size < maxSize ? end : screenCenter;

  return {
    left: position(rect.left, rect.right, modalWidth, window.innerWidth, center.left),
    top: position(rect.top, rect.bottom, modalHeight, window.innerHeight, center.top),
  };
};

// selected data sort
// 정렬 기준 - 디데이, 시작시간, 지속기간순
export const eventsSorted = (events) => {
  return events.sort((a, b) => {
    if (a.dday !== b.dday) {
      return a.dday === "Y" ? -1 : 1;
    }

    const startA = moment(a.startDt);
    const startB = moment(b.startDt);

    if (startA.isSame(startB)) {
      const durationA = a.endDt ? moment(a.endDt).diff(startA) : 0;
      const durationB = b.endDt ? moment(b.endDt).diff(startB) : 0;
      return durationB - durationA;
    }

    return startA.diff(startB);
  });
};

// 이벤트 모달창 높이 계산
export const calculateModalHeight = (user, item) => {
  const { gbn, dday } = item;

  if (dday === "Y") return 240;
  if (gbn === "P") {
    return 335;
  } else {
    if (isSameUser(user, item)) {
      return 496;
    } else {
      return 446;
    }
  }
};

// 컬러값에 따른 클래스명 지정
export const assignColorClass = (hex) => {
  if (!hex || typeof hex !== "string") {
    return "EM";
  }

  const color = hex.toUpperCase();

  switch (color) {
    case "0074CA":
      return "C";
    case "F46F02":
      return "G";
    case "ED5583":
      return "O";
    case "FFC455":
      return "GS";
    case "8F2D56":
      return "BB";
    case "A7A3D9":
      return "LL";
    case "38C6BD":
      return "TT";
    case "B350C3":
      return "VL";
    default:
      return "EM";
  }
};

// 디데이 계산
export const calculateDday = (eventDate) => {
  const today = moment().startOf("day");
  const startEvent = moment(eventDate).startOf("day");
  const diffDays = startEvent.diff(today, "days");

  if (diffDays > 0) {
    return `D-${diffDays}`;
  } else if (diffDays < 0) {
    return `D+${Math.abs(diffDays)}`;
  } else {
    return "D-day";
  }
};

// selected d-day sort
// 정렬 기준 - 당일, 미래날짜, 과거날짜
export const ddaysSorted = (data) => {
  const sorted = [...data].sort((a, b) => {
    const today = moment();
    const dateA = moment(a.startDt, "YYYY-MM-DD");
    const dateB = moment(b.startDt, "YYYY-MM-DD");

    if (dateA.isSame(today, "day") && dateB.isSame(today, "day")) return 0;
    if (dateA.isSame(today, "day")) return -1;
    if (dateB.isSame(today, "day")) return 1;

    if (dateA.isAfter(today) && dateB.isAfter(today)) {
      return dateA.diff(today) - dateB.diff(today);
    }

    if (dateA.isBefore(today) && dateB.isBefore(today)) {
      return today.diff(dateA) - today.diff(dateB);
    }

    return dateA.isAfter(today) ? -1 : 1;
  });

  return sorted;
};

// 날짜 형식 변환
export const transformDate = (date, isAll) => {
  if (!date) return;

  const { dateStart, dateEnd, timeStartHour, timeStartMinute, timeEndHour, timeEndMinute } = date;

  let startDt = `${dateStart} ${timeStartHour}:${timeStartMinute}:00`;
  let endDt = `${dateEnd} ${timeEndHour}:${timeEndMinute}:00`;

  if (isAll) {
    startDt = `${dateStart} 00:00:00`;
    endDt = `${dateEnd} 23:59:59`;
  }

  return { startDt, endDt };
};

// 불필요한 데이터 지우기
export const keysToRemove = (item, deletionKeys = []) => {
  let keys = ["backgroundColor", "borderColor", "start", "end", "id", "display"];

  if (deletionKeys.length > 0) {
    keys = keys.concat(deletionKeys);
  }

  const newItem = { ...item };
  keys.forEach((key) => {
    if (newItem.hasOwnProperty(key)) {
      delete newItem[key];
    }
  });
  return newItem;
};

// 참석자인지 여부 확인
export const isEventAttendees = (user, data) => {
  if (!user.mberNo) return false;
  if (user.mberNo === data.managerNo || user.mberNo === data.mberNo) {
    return true;
  }
  if (data.workerList && Object.keys(data.workerList).includes(user.mberNo.toString())) {
    return true;
  }
  return false;
};

// 날짜 형식 맞추기
export const setDateObject = (dateStart, dateEnd, timeStart, timeEnd) => {
  const [startHour, startMinute] = timeStart.split(":");
  const [endHour, endMinute] = timeEnd.split(":");

  return {
    dateStart,
    dateEnd,
    timeStartHour: startHour,
    timeStartMinute: startMinute,
    timeEndHour: endHour,
    timeEndMinute: endMinute,
  };
};

// 5분 단위 확인, 아닐 경우 반올림
export const roundTime = (time) => {
  if (parseInt(time.split(":")[1]) % 5) {
    const [hours, minutes, seconds] = time.split(":");
    const roundedMinutes = Math.round(parseInt(minutes) / 5) * 5;
    return `${hours}:${roundedMinutes < 10 ? "0" : ""}${roundedMinutes}:${seconds}`;
  } else {
    return time;
  }
};

// 프로젝트 참여자 조회
export const getProjectMbers = async (projectNo, user) => {
  const url = "/api/participantList";
  const body = {
    projectNo,
    delYn: "N",
    offset: 0,
    pageNumber: 0,
    pageSize: 10,
    paged: false,
  };

  const res = await axios.post(url, body);
  if (res.status === 200) {
    const list = res.data.content;

    if (!list.find((item) => item.mberNo === user.mberNo)) {
      return false;
    } else {
      return true;
    }
  }
};
