import React, { useState, useEffect, useRef } from "react";
import { Container as MapDiv, NaverMap, Marker } from "react-naver-maps";
import { makeMarkerClustering } from "../../../../function/marker-cluster";
import { GroupDetailSearchMapContainer } from "../../../../styled/group";
import { htmlMarkers } from "../../../../function/group";
import GroupDetailSearchMapSelected from "./groupDetailSearchMapSelected";

const GroupDetailSearchMap = ({
  swiperRef,
  companys,
  searched,
  searchWord,
  selectedCmpny,
  setSelectedCmpny,
  cmpnys,
  selectedCmpnyPorts,
}) => {
  const [userLocation, setUserLocation] = useState(null);
  const [center, setCenter] = useState({ lat: 36.348768969298746, lng: 127.39696329741778 });
  const [markers, setMarkers] = useState([]);
  const navermaps = window.naver.maps;
  const mapRef = useRef(null);
  const MarkerClustering = makeMarkerClustering(window.naver);
  const { htmlMarker1, htmlMarker2, htmlMarker3, htmlMarker4, htmlMarker5 } = htmlMarkers;

  // 사용자의 현재 위치
  useEffect(() => {
    let isActive = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (isActive) {
            const location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            setCenter(location);
            setUserLocation(location);

            if (mapRef.current) {
              mapRef.current.panTo(new navermaps.LatLng(location.lat, location.lng));
            }
          }
        },
        (error) => {
          console.error("Geolocation error:", error);
        },
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
      );
    }
    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    markers.forEach((marker) => marker.setMap(null));

    // 사용자 위치와 가장 가까운 회사 찾기
    if (searched && userLocation && companys.length > 0) {
      let closest = null;
      let minDistance = Infinity;

      companys.forEach((cmpny) => {
        const distance = calculateDistance(
          userLocation.lat,
          userLocation.lng,
          parseFloat(cmpny.latitude),
          parseFloat(cmpny.longitude),
        );
        if (!closest || distance < minDistance) {
          closest = cmpny;
          minDistance = distance;
        }
      });

      if (closest) {
        setCenter({ lat: parseFloat(closest.latitude), lng: parseFloat(closest.longitude) });
        if (mapRef.current) {
          mapRef.current.setZoom(17);
        }
      }
    }
  }, [companys]);

  // 위도와 경도 사이의 거리 계산
  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    return Math.sqrt((lat1 - lat2) ** 2 + (lng1 - lng2) ** 2);
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.panTo(new window.naver.maps.LatLng(center.lat, center.lng));
    }
  }, [center]);

  const onClickCmpny = (cmpny) => {
    const newCenter = { lat: parseFloat(cmpny.latitude), lng: parseFloat(cmpny.longitude) };
    setSelectedCmpny(cmpny);
    setCenter(newCenter);
    if (mapRef.current) {
      mapRef.current.setZoom(17);
    }
  };

  // 회사 마커 생성하고 클러스터링
  useEffect(() => {
    if (mapRef.current && companys.length > 0) {
      const newMarkers = companys.map((cmpny) => {
        const marker = new navermaps.Marker({
          position: new navermaps.LatLng(cmpny.latitude, cmpny.longitude),
          title: cmpny.cmpnyNm,
          icon: {
            content: `<div style="padding: 5px; cursor: pointer; display: flex; flex-direction: column; align-items: center;">
                        <div style="width: 40px; height: 40px; padding: 3px; background:#fff; border-radius:5px;">
                          <img src=${
                            cmpny.logoImage ? imgHandler(cmpny.logoImage) : "/img/marker.png"
                          } style="width: 100%; height: 100%; background:#efefef; border-radius:5px; object-fit: contain;" />
                        </div>
                        <span style="font-weight: 600; font-size: 11px; white-space: nowrap;">${
                          cmpny.cmpnyNm
                        }</span>
                      </div>`,
          },
        });
        marker.addListener("click", () => onClickCmpny(cmpny));
        return marker;
      });

      setMarkers(newMarkers);

      const cluster = new MarkerClustering({
        minClusterSize: 2,
        maxZoom: 15,
        map: mapRef.current,
        markers: newMarkers,
        disableClickZoom: false,
        gridSize: 120,
        icons: [htmlMarker1, htmlMarker2, htmlMarker3, htmlMarker4, htmlMarker5],
        indexGenerator: [10, 20, 30, 40, 50],
        stylingFunction: (clusterMarker, count) => {
          clusterMarker.getElement().querySelector("div:first-child").innerText = count;
        },
      });

      return () => {
        cluster.setMap(null);
      };
    }
  }, [companys]);

  return (
    <GroupDetailSearchMapContainer>
      <GroupDetailSearchMapSelected
        swiperRef={swiperRef}
        selectedCmpny={selectedCmpny}
        setSelectedCmpny={setSelectedCmpny}
        cmpnys={cmpnys}
        selectedCmpnyPorts={selectedCmpnyPorts}
      />
      <MapDiv
        style={{
          width: "100%",
          height: "400px",
        }}>
        <NaverMap
          ref={mapRef}
          defaultCenter={new navermaps.LatLng(36.348768969298746, 127.39696329741778)}
          defaultZoom={13}
          center={center}>
          {userLocation && (
            <Marker
              position={new navermaps.LatLng(userLocation.lat, userLocation.lng)}
              title="현재 위치"
              icon={{
                content:
                  '<img src="/img/newMan.png" style="width: 30px; height: 30px; object-fit: contain;" />',
              }}
            />
          )}
        </NaverMap>
      </MapDiv>
    </GroupDetailSearchMapContainer>
  );
};

export default GroupDetailSearchMap;
