import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router";

import { CloseButton, Container } from "./component/styled/common";
import { KioskMemuList } from "./component/styled/kiosk";

import { MdClose } from "react-icons/md";
import { exitFullscreen, useDisableScroll } from "./component/function/kiosk";

const KioskPage = () => {
  useDisableScroll();
  const navigate = useNavigate();

  const sungsimdang = [
    { key: "storage", tit: "보관", imagePath: "sungsimdang" },
    { key: "pickup", tit: "찾기", imagePath: "sungsimdang_cake" },
    { key: "salesCheck", tit: "매출관리", imagePath: "" },
    { key: "reservationStatus", tit: "이용내역조회", imagePath: "sungsimdang_bread" },
  ];

  const exitKioskPage = () => {
    exitFullscreen();
    navigate("/mypage");
  };

  return (
    <Container className="bg">
      <CloseButton>
        <button onClick={exitKioskPage}>
          <MdClose />
        </button>
      </CloseButton>
      <KioskMemuList>
        {sungsimdang.map((item) => (
          <li
            key={item.key}
            className={item.key}
            onClick={() => navigate(`/mypage/kiosk/${item.key}`)}>
            <div className="content">
              <div className="image">
                <img src={`/img/sungsimdang/${item.imagePath}.png`} alt="" />
              </div>
              <span>으능&성심 {item.tit}</span>
            </div>
          </li>
        ))}
      </KioskMemuList>
    </Container>
  );
};

export default KioskPage;
