import React, { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { HiCalendarDays } from "react-icons/hi2";
import { HamburgerMenuContainer } from "../styled/scheduler";

const HamburgerMenu = ({ onDate, setIsBurgerOpen, isBurgerOpen }) => {
  const navigate = useNavigate();
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);

  const dateTime = (day) => {
    const [date] = day.split(" ");
    const now = moment().format("HH:30:00");
    return `${date} ${now}`;
  };

  return (
    <HamburgerMenuContainer className={isDarkMode}>
      {isBurgerOpen && (
        <div
          className="bg"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setIsBurgerOpen(false);
            }
          }}></div>
      )}
      <div className="burgerNav">
        <div className="sidebar">
          <ul className={`list ${isBurgerOpen ? "open" : ""}`}>
            <li>
              <div
                onClick={() =>
                  navigate("/mypage/calendarEntry", {
                    state: {
                      eventInfo: {
                        startDt: moment(dateTime(onDate))
                          .startOf("hour")
                          .format("YYYY-MM-DD HH:30:00"),
                        endDt: moment(dateTime(onDate))
                          .startOf("hour")
                          .add(1, "hour")
                          .format("YYYY-MM-DD HH:30:00"),
                        pushMinute: 60,
                      },
                    },
                  })
                }>
                <HiOutlinePencilAlt />
                <p>일정</p>
              </div>
            </li>
            <li>
              <div
                onClick={() =>
                  navigate("/mypage/ddayEntry", {
                    state: {
                      eventInfo: {
                        startDt: onDate,
                        pushMinute: 900,
                      },
                    },
                  })
                }>
                <HiCalendarDays />
                <p>D-DAY</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="burgerBox">
          <label class="burger" htmlFor="burger">
            <input
              type="checkbox"
              id="burger"
              checked={isBurgerOpen}
              onChange={() => setIsBurgerOpen(!isBurgerOpen)}
            />
            <div>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </label>
        </div>
      </div>
    </HamburgerMenuContainer>
  );
};

export default HamburgerMenu;
