import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import { useOutletContext } from "react-router";

import { ChicCmpnyContainer } from "../shopping/component/styled/chicTheme";
import { ModernCmpnyContainer } from "../shopping/component/styled/modernTheme";
import { CuteCmpnyContainer } from "../shopping/component/styled/cuteTheme";
import { SimpleCmpnyContainer } from "../shopping/component/styled/simpleTheme";

import CompanyForm from "./component/companyForm";
import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";

const CustomToast = styled(ToastContainer)`
  z-index: 10000;
  width: "400px";
  font-weight: 600;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const Company = () => {
  const { theme } = useOutletContext();

  const renderComponent = () => {
    switch (theme) {
      case "A":
        return <CompanyForm />;
      case "B":
        return (
          <ChicCmpnyContainer>
            <CompanyForm />
          </ChicCmpnyContainer>
        );
      case "C":
        return (
          <ModernCmpnyContainer>
            <ModernBanner />
            <CompanyForm />
          </ModernCmpnyContainer>
        );
      case "D":
        return (
          <CuteCmpnyContainer>
            <CuteBanner />
            <CompanyForm />
          </CuteCmpnyContainer>
        );
      case "E":
        return (
          <SimpleCmpnyContainer>
            <SimpleBanner />
            <CompanyForm />
          </SimpleCmpnyContainer>
        );
      default:
        return <CompanyForm />;
    }
  };

  return (
    <>
      <CustomToast
        position="top-center"
        theme="light"
        pauseOnHover
        hideProgressBar={true}
        autoClose={1000}
        closeButton={false}
      />
      {renderComponent()}
    </>
  );
};

export default Company;
