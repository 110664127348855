import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import DaumPostcode from "react-daum-postcode";

import { CompanyInfoFormContainer } from "./styled/companyInfo";
import { handleChangeUser } from "../../../../store/user";

import { AddrSearchModal } from "../../scheduler/component/styled/calendarEntry";

export default function CompanyInfoForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.data[0]);
  const [formData, setFormData] = useState({
    cmpnyNm: "",
    rprNm: "",
    bizrno: "",
    adres: "",
    dtlAdres: "",
  });
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [showPostCode, setShowPostCode] = useState(false);

  const openPostCode = () => setShowPostCode(true);
  const closePostCode = () => setShowPostCode(false);

  // 작성
  const handleCreateCmpny = async () => {
    if (!validateFormData()) {
      return;
    }

    const url = "/api/cmpny";
    const body = {
      ...formData,
      createMberId: user.userName,
      updateMberId: user.userName,
      useYn: "Y",
    };

    const res = await axios.put(url, body);
    if (res.data.success) {
      await handleUpdateMber(res.data.message);
    }
  };

  const handleUpdateMber = async (cmpnyNo) => {
    const url = "/api/mber";
    const body = {
      mberNo: user.mberNo,
      mberNm: user.name,
      cmpnyNo,
      username: user.userName,
      moblphonNo: user.moblphonNo,
      email: user.email,
      rgsCl: "MBRS04",
      delYn: "N",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    const res = await axios.put(url, body);
    if (res.status === 200) {
      dispatch(handleChangeUser({ cmpnyNo }));
      toast.success("회사가 추가되었습니다.");
      navigate("/mypage/company");
    }
  };

  // 중복 확인 여부 체크, 빈값 체크
  const validateFormData = () => {
    const fieldLabels = {
      bizrno: "사업자 번호",
      cmpnyNm: "회사 이름",
      rprNm: "대표자명",
      adres: "주소",
    };

    for (let key in fieldLabels) {
      if (textNotExist(formData[key])) {
        const label = fieldLabels[key] || "";
        toast.error(`${chooseParticle(label, "을를")} 입력해주세요.`);
        return false;
      }
    }

    if (!isDuplicate) {
      toast.error("사업자번호 중복 확인을 해주세요.");
      return false;
    }

    return true;
  };

  // 사업자번호 중복 확인
  const alreadyExists = async () => {
    const bizrnoRegex = /^[0-9]{10}$/;
    if (!formData.bizrno || !bizrnoRegex.test(formData.bizrno)) {
      toast.error("유효하지 않은 사업자 번호입니다.");
      return;
    }

    const url = "/api/duplBizrNo";
    const body = { bizrno: formData.bizrno };
    const res = await axios.post(url, body);

    const message = res.data.message;

    if (message === "isNotExist") {
      toast.success("사용할 수 있는 사업자 번호입니다.");
      setIsDuplicate(true);
    } else {
      if (message === "isExist") {
        toast.error(`이미 사용 중인 사업자 번호입니다.`);
        setIsDuplicate(false);
      } else {
        toast.error(message);
        setIsDuplicate(false);
      }
    }
  };

  const changeInput = (e) => {
    const { name, value } = e.target;
    if (name === "bizrno" && isDuplicate) {
      setIsDuplicate(false);
    }
    setFormData((data) => ({ ...data, [name]: value }));
  };

  // 카카오 주소
  const userAddress = (data) => {
    const extraAddress = [data.bname, data.buildingName].filter(Boolean).join(", ");
    const fullAddress = data.address + (extraAddress ? ` (${extraAddress})` : "");

    setFormData((data) => {
      const updated = { ...data, adres: fullAddress };
      return updated;
    });

    closePostCode();
  };

  return (
    <CompanyInfoFormContainer className="inner_content_wrap">
      {showPostCode && (
        <AddrSearchModal
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              closePostCode();
            }
          }}>
          <div className="modalContent">
            <DaumPostcode onComplete={userAddress} />
            <button onClick={closePostCode}>닫기</button>
          </div>
        </AddrSearchModal>
      )}
      <div id="step1_html">
        <div className="writing_ty2">
          <ul className="writing_list">
            <li className="no_border">
              <div className="writing_tit ">
                <strong className="tit">사업자번호</strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider">
                  <input
                    type="text"
                    name="bizrno"
                    placeholder="사업자등록번호(숫자만 입력)"
                    value={formData.bizrno}
                    style={{ width: "100%" }}
                    onChange={changeInput}
                  />
                  <button className="btn_line" onClick={alreadyExists}>
                    중복확인
                  </button>
                </div>
              </div>
            </li>
            <li className="half_li">
              <div className="writing_tit ">
                <strong className="tit">회사명</strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider">
                  <input
                    type="text"
                    name="cmpnyNm"
                    placeholder="회사명을 입력해주세요."
                    value={formData.cmpnyNm}
                    style={{ width: "100%" }}
                    onChange={changeInput}
                  />
                </div>
              </div>
            </li>
            {/* <li className="half_li">
              <div className="writing_tit">
                <strong className="tit">최초관리자</strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider">
                  <input type="number" id name placeholder="최초관리자 아이디" readOnly />
                </div>
              </div>
            </li> */}
            <li className="half_li">
              <div className="writing_tit ">
                <strong className="tit">대표자명</strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider">
                  <input
                    type="text"
                    name="rprNm"
                    placeholder="대표자명을 입력해주세요."
                    value={formData.rprNm}
                    style={{ width: "100%" }}
                    onChange={changeInput}
                  />
                </div>
              </div>
            </li>
            {/* <li className="half_li">
              <div className="writing_tit">
                <strong className="tit">대표이메일</strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider">
                  <input type="email" id name placeholder="id@domain.com" />
                </div>
              </div>
            </li>
            <li className="half_li">
              <div className="writing_tit ">
                <strong className="tit">대표전화</strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider">
                  <input type="number" id name placeholder="대표전화를 입력해주세요." defaultValue />
                </div>
              </div>
            </li>
            <li className="half_li">
              <div className="writing_tit">
                <strong className="tit">팩스</strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider">
                  <input type="number" id name placeholder="팩스번호를 입력해주세요." />
                </div>
              </div>
            </li>
            <li>
              <div className="writing_tit ">
                <strong className="tit">도메인 설정</strong>
              </div>
              <div className="writing_cont" id="company_domain">
                <div className="inputxt_normal">
                  <p className="font_18 font_color_black font300 text_center">https://1472.ai/h/</p>
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder="abcd"
                    value={inputDomain}
                    onChange={(e) => handleInputDomain(e.target.value)}
                  />
                  <button
                    type="button"
                    name="button"
                    className="btn_line gray btn_duplicate"
                    onClick={() => checkedDomain(true)}>
                    중복체크
                  </button>
                </div>
              </div>
            </li>
            <li>
              <div className="writing_tit ">
                <strong className="tit">휴대전화</strong>
              </div>
              <div className="writing_cont">
                <div className="inputxt_normal spider">
                  <p className="font_16 font_color_gray font300 text_left pdb_20">
                    * 입력한 휴대전화로 알림톡이 발송되니 정확하게 입력해주세요.
                  </p>
                  <input
                    type="text"
                    id
                    className="onlyNum"
                    name
                    placeholder="숫자만 입력해주세요 예)01012345678"
                    defaultValue
                  />
                </div>
              </div>
            </li> */}
            <li>
              <div className="writing_tit ">
                <strong className="tit">주소</strong>
              </div>
              <div className="writing_cont">
                <div className="mt5">
                  <div className="inputxt_normal spider" style={{ display: "flex" }}>
                    <input
                      type="text"
                      id="wk_addr1"
                      name="wk_addr1"
                      placeholder="주소를 입력해주세요."
                      value={formData.adres}
                      readOnly="readonly"
                      style={{ width: "100%" }}
                    />
                    <button
                      type="button"
                      name="button"
                      className="btn_line gray wk_post"
                      onClick={openPostCode}>
                      <span>주소찾기</span>
                    </button>
                  </div>
                </div>
                <div className="mt5">
                  <div className="inputxt_normal spider">
                    <input
                      type="text"
                      id="wk_addr3"
                      name="dtlAdres"
                      placeholder="상세주소를 입력해주세요."
                      style={{ width: "100%" }}
                      value={formData.dtlAdres}
                      onChange={changeInput}
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="btnarea_center mt20" style={{ marginTop: 40 }}>
            <button className="btn_normal" style={{ border: "none" }} onClick={handleCreateCmpny}>
              <span>완료</span>
            </button>
          </div>
        </div>
      </div>
    </CompanyInfoFormContainer>
  );
}
