import MemberBanner from "./memberBanner";


const MemberBenefit = () => {
  
  return (
    <div>
      <MemberBanner />

      <div className="inner_content" id="member_wrap">
        <div className="inner_title" id="member_1">
          <span className="font_48 font600 font_color_black pdb_40">{service.customerPoint}</span>
          <span className="font_30 font300 font_color_black pdb_40">{service.prepare}</span>
        </div>
        <div className="inner_content_wrap" id="member_11">
        </div>
      </div>
    </div>
  );
};

export default MemberBenefit;