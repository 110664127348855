import React, { useEffect, useRef, useState } from "react";
import { SearchMapContainer } from "../../styled/shop";
import { Container as MapDiv, NaverMap, Marker } from "react-naver-maps";

const ShopSearchMap = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [center, setCenter] = useState({ lat: 36.348768969298746, lng: 127.39696329741778 });
  const navermaps = window.naver.maps;
  const mapRef = useRef(null);

  useEffect(() => {
    let isActive = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (isActive) {
            const location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            setCenter(location);
            setUserLocation(location);
          }
        },
        (error) => {
          console.error("Geolocation error:", error);
        },
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
      );
    }
    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.panTo(new window.naver.maps.LatLng(center.lat, center.lng));
    }
  }, [center]);

  return (
    <SearchMapContainer>
      <div className="mapField">
        <MapDiv
          style={{
            width: "100%",
            height: "100%",
          }}>
          <NaverMap
            ref={mapRef}
            center={new navermaps.LatLng(center.lat, center.lng)}
            defaultZoom={13}>
            {userLocation && (
              <Marker
                position={new navermaps.LatLng(userLocation.lat, userLocation.lng)}
                title="현재 위치"
                icon={{
                  content:
                    '<img src="/img/newMan.png" style="width: 30px; height: 30px; object-fit: contain;" />',
                }}
              />
            )}
          </NaverMap>
        </MapDiv>
      </div>
    </SearchMapContainer>
  );
};

export default ShopSearchMap;
