import BoardView from "../../../../common/boardViewPage";
import MyPageQnaTitle from "./myPageQnaTitle";

export default function MyQnaViewForm () {
  return(
    <div className="inner_content">
      <MyPageQnaTitle />
      <BoardView />
    </div>
  )
}