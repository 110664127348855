import React from "react";
import { SearchFormContainer } from "../../styled/shop";
import { GoChevronRight } from "react-icons/go";

const ShopSearchForm = () => {
  return (
    <SearchFormContainer>
      <div className="inputField">
        <input type="text" placeholder="구매 상품 검색하기" />
        <button>
          <GoChevronRight />
        </button>
      </div>
    </SearchFormContainer>
  );
};

export default ShopSearchForm;
