import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

const Greeting = () => {
  const user = useSelector((state) => state?.user?.data[0]);
  const [currentTime, setCurrentTime] = useState(moment().format("a h시m분"));

  const getGreeting = () => {
    const hour = moment().hour();

    if (hour >= 5 && hour < 12) return "좋은 아침이에요";
    if (hour >= 12 && hour < 18) return "즐거운 오후 보내세요";
    if (hour >= 18 && hour < 22) return "좋은 저녁이에요";
    return "좋은 밤 보내세요";
  };

  useEffect(() => {
    let animationFrameId;

    const updateCurrentTime = () => {
      setCurrentTime(moment().format("a h시m분"));
      animationFrameId = requestAnimationFrame(updateCurrentTime);
    };

    updateCurrentTime();
    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  return (
    <div class="message">
      <span>{currentTime}</span>
      <span>
        안녕하세요! {user?.name ? `${user.name}님` : ""}
        <br />
        {getGreeting()}
      </span>
    </div>
  );
};

export default Greeting;
