import axios from "axios";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { SimpleBannerContainer } from "../../styled/simpleTheme";
import { useOutletContext } from "react-router";

const SimpleBanner = () => {
  const { cmpnyNo } = useOutletContext();
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getMyBanner(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getMyBanner = async (cmpnyNo) => {
    const url = "/api/firstSettingLoad";
    const body = {
      cmpnyNo,
      delYn: "N",
    };
    try {
      const res = await axios.post(url, body);
      const images = JSON.parse(res.data.bannerImgList);

      if (images && images.length > 0) {
        setBanner(images);
      } else {
        const basePath = "/img/shop_main_bg";
        setBanner(Array.from({ length: 3 }, (_, index) => `${basePath}${index + 1}.jpg`));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SimpleBannerContainer className="banner">
      <Swiper
        className="banners"
        modules={[Navigation]}
        slidesPerView={1}
        loop={true}
        speed={2000}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}>
        {banner.map((item, i) => (
          <SwiperSlide key={i}>
            <div className="swiper-wrapper">
              <div className="bannerSlide" id={i}>
                <img src={`${imgHandler(item)}`} />
                <div
                  className="slide_text_warp text_center"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}></div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </SimpleBannerContainer>
  );
};

export default SimpleBanner;
