import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import MypageBanner from "../mypageBanner";
import RegisterTitle from "./component/register/registerTitle";
import RegisterMenu from "./component/register/registerMenu";
import HomePageSettingMenu from "./homepageSettingMenu";
import BoardView from "../../../common/board/boardView";

import { setRegisterIndex } from "../../../store/compoIndex";

export default function RegisterNoticeView() {
  const index = useSelector((state) => state.compoIndex.register);
  const user = useSelector((state) => state?.user?.data[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("register")) {
      dispatch(setRegisterIndex(3));
    }
  });

  return (
    <>
      <MypageBanner />
      <div className="inner_content">
        <HomePageSettingMenu />
        <RegisterTitle />
        <RegisterMenu index={index} />
        <BoardView userName={user?.userName} cmpnyNo={user?.cmpnyNo} />
      </div>
    </>
  );
}
