import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import { GrLinkNext, GrLinkPrevious, GrPauseFill, GrPlayFill } from "react-icons/gr";
import "swiper/swiper-bundle.min.css";
import { useMediaQuery } from "react-responsive";

const Products = ({ cmpnyNo, url }) => {
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [isAutoplay, setIsAutoplay] = useState(true);
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  useEffect(() => {
    if (cmpnyNo) {
      getProducts(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getProducts = async (cmpnyNo) => {
    const url = "/api/prductStock";
    const body = {
      cmpnyNo,
      offset: 0,
      pageNumber: 0,
      pageSize: 20,
      paged: true,
    };

    try {
      const res = await axios.post(url, body);
      console.log(res.data.content);
      setProducts(res.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleAutoplay = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (isAutoplay) {
        swiperRef.current.swiper.autoplay.stop();
      } else {
        swiperRef.current.swiper.autoplay.start();
      }
    }
    setIsAutoplay(!isAutoplay);
  };

  const handleSwiperButton = (isPrev) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (isPrev) {
        swiperRef.current.swiper.slidePrev();
      } else {
        swiperRef.current.swiper.slideNext();
      }
    }
  };

  return (
    <div className="products">
      <div className="info">
        Product
        <span>더 많은 상품은 더보기를 눌러주세요.</span>
      </div>
      <Swiper
        ref={swiperRef}
        className="productSwiper"
        modules={[Navigation, Autoplay]}
        slidesPerView={isMobile ? 1.7 : isTablet ? 3 : 5}
        spaceBetween={isMobile ? 10 : isTablet ? 30 : 60}
        navigation={true}
        loop={true}
        speed={3000}
        autoplay={isAutoplay ? { delay: 4500, disableOnInteraction: false } : false}>
        {products.map((item, i) => (
          <SwiperSlide key={i}>
            <div className="swiper-wrapper">
              <div className="productSlide" id={i}>
                <div
                  className="box"
                  onClick={() => navigate(`/shop/${url}/productsView/${item.prdNo}`)}>
                  <img
                    src={isPrdImg(item)}
                    onError={(e) => (e.target.src = "/img/prdct_08.png")}
                    alt="Product"
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="actions">
        <div className="swiperAction">
          <button className="swiper-button-prev" onClick={() => handleSwiperButton(true)}>
            <GrLinkPrevious />
          </button>
          <button onClick={toggleAutoplay}>{isAutoplay ? <GrPauseFill /> : <GrPlayFill />}</button>
          <button className="swiper-button-next" onClick={() => handleSwiperButton(false)}>
            <GrLinkNext />
          </button>
        </div>
        <button className="more" onClick={() => navigate(`/shop/${url}/productsList`)}>
          더보기
        </button>
      </div>
    </div>
  );
};

export default Products;
