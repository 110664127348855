import moment from "moment";
import React, { useEffect, useState } from "react";
import { Footer } from "../styled/common";
import { useLocation } from "react-router";

const KioskFooter = () => {
  const [currentTime, setCurrentTime] = useState(moment().format("YYYY년 MM월 DD일 A h시 m분"));

  useEffect(() => {
    let animationFrameId;

    const updateCurrentTime = () => {
      setCurrentTime(moment().format("YYYY년 MM월 DD일 A h시 m분"));
      animationFrameId = requestAnimationFrame(updateCurrentTime);
    };

    updateCurrentTime();

    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  return (
    <Footer>
      <div className="date">{currentTime}</div>
      <div className="kioskLogo">
        <img src="/img/sungsimdang/sungsimdang.png" alt="" />
      </div>
    </Footer>
  );
};

export default KioskFooter;
