import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import moment from "moment";
import { getToken } from "firebase/messaging";
import { toast } from "react-toastify";

import { categorizeItemsByStatus } from "./component/function/mutualBenefit";
import { useDisableScroll } from "../kiosk/component/function/kiosk";
import { messaging } from "../../../firebase/firebase.js";

import { PickUpContainer } from "./component/styled/pickup";
import { Container } from "./component/styled/common";

import MutualBenefitHeader from "./component/common/mutualBenefitHeader";
import DesktopOrderView from "./component/pickup/desktopOrderView";
import MobileOrderView from "./component/pickup/mobileOrderView";
import { AlertModal, ChoiceModal, ConfirmationModal, SelectModal } from "./component/common/modal";

const defaultModalConfig = {
  isOpen: false,
  type: "",
  title: "",
  message: [],
  handleConfirm: null,
  handleCancel: null,
};

const Pickup = () => {
  useDisableScroll();

  const user = useSelector((state) => state?.user?.data[0]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [data, setData] = useState([]);
  const [modalConfig, setModalConfig] = useState({ ...defaultModalConfig });
  const [modal, setModal] = useState(null);
  const [selected, setSelected] = useState(null);
  const [categorized, setCategorized] = useState({
    PENDING: [],
    CONFIRMED: [],
    READY: [],
    CANCELLED: [],
    COMPLETED: [],
    PICKUP: [],
  });
  const [mobileActive, setMobileActive] = useState("PENDING");
  const [desktopActive, setDesktopActive] = useState("PENDING");
  const [alertModalConfigs, setAlertModalConfigs] = useState([]);
  const [checkedOrders, setCheckedOrders] = useState([]);

  useEffect(() => {
    setModal(getModalContent());
  }, [modalConfig]);

  useEffect(() => {
    const splitData = categorizeItemsByStatus(data);
    console.log(splitData);
    setCategorized(splitData);
  }, [data]);

  // 웹소켓 연결
  const initializeOrderEvent = () => {
    // const eventSource = new EventSource(`http://43.200.230.247:8888/events?cmpnyNo=${user.cmpnyNo}`);
    const eventSource = new EventSource(`http://cloud.1472.ai:8888/events?cmpnyNo=${user.cmpnyNo}`);

    eventSource.onmessage = async (event) => {
      let token;
      let message = JSON.parse(event.data);
      console.log(message);

      const messages = {
        0: { title: "신규 주문", body: "새로운 주문이 들어왔습니다.", tab: "PENDING" },
        1: {
          title: "주문 취소",
          body: `${message.orderId}번 주문이 취소되었습니다.`,
          tab: "PICKUP",
        },
      };

      try {
        token = await getToken(messaging, {
          vapidKey:
            "BDTF9CMkogFUR-U1aUldUuwl1y3c6V9UuEYtcWoCBMXFJUj9bXOOJpsb249HJcUU4SM8j6RG5zwVe0hrUIzhNQU",
        });
        console.log("FCM Token:", token);
      } catch (error) {
        console.error("FCM Token err:", error);
      }

      getOrders();

      if (Notification.permission === "granted") {
        const notification = new Notification(`${messages[message?.gbn]?.title} `, {
          body: `${messages[message?.gbn]?.body} 주문을 확인해 주세요.`,
          icon: "/img/logo.png",
        });

        notification.onclick = function () {
          window.focus();
          selectOrder({ id: message?.orderId });
        };

        const sound = new Audio("/source/notification.mp3");
        sound.play().catch((error) => {
          console.error("사운드 재생 오류:", error);
        });

        if (isMobile) {
          setMobileActive(messages[message?.gbn]?.tab);
        } else {
          setDesktopActive(messages[message?.gbn]?.tab);
        }
      } else {
        console.warn("알림 권한이 거부되었습니다.");
      }

      openAlertModal({
        title: `${messages[message?.gbn]?.title}`,
        message: [`${messages[message?.gbn]?.body}`, "주문을 확인해 주세요."],
      });
    };

    eventSource.onerror = (error) => {
      console.error("알림 에러:", error);
      eventSource.close();
    };

    return eventSource;
  };

  useEffect(() => {
    getOrders();
    getLocalStorage();

    const eventSource = initializeOrderEvent();
    return () => {
      eventSource.close();
    };
  }, []);

  const getLocalStorage = () => {
    const local = JSON.parse(localStorage.getItem("checkedOrders"));
    console.log(local);
    setCheckedOrders(local || []);
  };

  const updateLocalStorage = (id) => {
    setCheckedOrders((prev) => {
      if (!prev.includes(id)) {
        const newCheckedOrders = [...prev, id];
        localStorage.setItem("checkedOrders", JSON.stringify(newCheckedOrders));

        return newCheckedOrders;
      }
      return prev;
    });
  };

  // 주문 리스트 조회
  const getOrders = async () => {
    const query = `
    query SsOrders($cmpnyNo: Int, $createdAt: String) {
      ssOrders(filter: { cmpnyNo: $cmpnyNo, createdAt: $createdAt }) {
       orders {
            id
            orderFlag            
            status
            createdAt
            pickupBy
            price
        }
      }
    }
  `;

    const variables = {
      cmpnyNo: user?.cmpnyNo,
      createdAt: moment().format("YYYYMMDD"),
    };

    try {
      const res = await axios.post(
        "/erp/ss/orders",
        { query, variables },
        {
          headers: { "Content-Type": "application/json" },
        },
      );
      setData(res?.data?.orders || []);
    } catch (error) {
      console.error(error);
    }
  };

  const changeRejectReason = (item) => {
    setModalConfig((config) => ({ ...config, rejectionReason: item }));
  };

  const getModalContent = () => {
    if (!modalConfig.isOpen) return null;

    const modalProps = {
      title: modalConfig.title,
      message: modalConfig.message,
      handleConfirm: modalConfig.handleConfirm || closeModal,
      handleCancel: modalConfig.handleCancel || closeModal,
      ...modalConfig,
    };

    switch (modalConfig.type) {
      case "alert":
        return <AlertModal {...modalProps} />;
      case "confirmation":
        return <ConfirmationModal {...modalProps} />;
      case "select":
        return (
          <SelectModal
            {...modalProps}
            handleChangeOption={changeRejectReason}
            active={modalConfig.rejectionReason}
          />
        );
      case "choice":
        return (
          <ChoiceModal
            {...modalProps}
            handleChangeOption={changeRejectReason}
            active={modalConfig.rejectionReason}
          />
        );
      default:
        return null;
    }
  };

  const openModal = (options) => {
    setModalConfig({
      isOpen: true,
      ...options,
    });
  };

  const closeModal = (flag = false) => {
    setModalConfig(() => ({ ...defaultModalConfig }));

    if (flag) {
      setSelected(null);
    }
  };

  // 주문 취소
  const deleteOrder = async (reason, id) => {
    if (!reason) {
      toast.error("주문거부 사유를 선택해주세요.");
      return;
    }

    const url = "/erp/ss/delete";
    const body = { id, actionId: user.cmpnyNo, cancelReason: reason };
    console.log(body);

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        openModal({
          type: "alert",
          title: "주문 취소",
          message: ["주문이 취소되었습니다."],
          handleConfirm: () => {
            getOrders();
            closeModal(true);
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 주문 수락
  const acceptOrder = async (id, pickupBy) => {
    const url = "/erp/ss/update";
    const body = { id, pickupBy, status: "READY" };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        console.log(res);
        openModal({
          type: "alert",
          title: "주문 수락",
          message: ["주문이 수락되었습니다."],
          handleConfirm: () => {
            getOrders();
            closeModal(true);
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 픽업 완료
  const updateOrder = async (id) => {
    const url = "/erp/ss/update";
    const body = { id, status: "PICKUP" };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        console.log(res);
        openModal({
          type: "alert",
          title: "주문 완료",
          message: ["주문이 완료되었습니다."],
          handleConfirm: () => {
            getOrders();
            closeModal(true);
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 상품 준비 완료
  const completedOrder = async (id) => {
    const url = "/erp/ss/update";
    const body = { id, status: "COMPLETED" };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        console.log(res);
        openModal({
          type: "alert",
          title: "음식 완료",
          message: ["음식 준비가 완료되었습니다."],
          handleConfirm: () => {
            getOrders();
            closeModal(true);
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 주문 취소
  const rejectOrder = (item) => {
    openModal({
      type: "select",
      title: "주문 취소",
      message: ["주문거부 사유를 선택해주세요."],
      options: ["재료소진", "조리지연", "가게사정 취소", "요청사항 불가", "직접 입력"],
      rejectionReason: "",
      handleConfirm: (reason) => deleteOrder(reason, item.id),
      handleCancel: () => closeModal(),
    });
  };

  const selectOrder = async (item) => {
    const detail = await getOrder(item.id);

    if (detail) {
      updateLocalStorage(detail.id);
      setSelected(detail);
    }
  };

  // 주문 상세보기
  const getOrder = async (id) => {
    const url = "/erp/ss/order";
    const body = { id };

    try {
      const res = await axios.post(url, body);
      console.log(res);
      return res.status === 200 ? res?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const openAlertModal = (options) => {
    setAlertModalConfigs((prevConfigs) => {
      const newIndex = prevConfigs.length;

      return [
        ...prevConfigs,
        {
          isOpen: true,
          ...options,
          handleConfirm: () => closeAlertModal(newIndex),
        },
      ];
    });
  };

  const closeAlertModal = (index = null) => {
    setAlertModalConfigs((prevConfigs) => {
      if (index !== null) {
        return prevConfigs.filter((_, i) => i !== index);
      }
      return [];
    });
  };

  // 주문 모달 포지션 계산
  const calcAlertPosition = (index, totalModals) => {
    const basePosition = 50;
    const baseOffset = 10;
    // const baseOffset = 15;

    if (totalModals === 1) {
      return {
        top: `${basePosition}%`,
        left: `${basePosition}%`,
        transform: "translate(-50%, -50%)",
      };
    }

    // const centerIndex = Math.floor((totalModals - 1) / 2);
    // const offset = (index - centerIndex) * baseOffset;
    const offset = baseOffset * (index + 1);

    return {
      top: `calc(${basePosition}% + ${offset}px)`,
      left: `calc(${basePosition}% + ${offset}px)`,
    };
  };

  return (
    <Container>
      <PickUpContainer>
        {alertModalConfigs.map(
          (config, index) =>
            config.isOpen && (
              <AlertModal
                key={index}
                title={config?.title}
                message={config?.message}
                handleConfirm={() => closeAlertModal(index)}
                index={index}
                style={calcAlertPosition(index, alertModalConfigs.length)}
              />
            ),
        )}
        {modalConfig?.isOpen && modal}
        <MutualBenefitHeader />
        <div className="container">
          {isMobile ? (
            <MobileOrderView
              categorized={categorized}
              selectOrder={selectOrder}
              rejectOrder={rejectOrder}
              acceptOrder={acceptOrder}
              completedOrder={completedOrder}
              openModal={openModal}
              selected={selected}
              setSelected={setSelected}
              updateOrder={updateOrder}
              active={mobileActive}
              setActive={setMobileActive}
              checkedOrders={checkedOrders}
            />
          ) : (
            <DesktopOrderView
              categorized={categorized}
              selectOrder={selectOrder}
              rejectOrder={rejectOrder}
              acceptOrder={acceptOrder}
              completedOrder={completedOrder}
              openModal={openModal}
              selected={selected}
              setSelected={setSelected}
              updateOrder={updateOrder}
              active={desktopActive}
              setActive={setDesktopActive}
              checkedOrders={checkedOrders}
            />
          )}
        </div>
      </PickUpContainer>
    </Container>
  );
};

export default Pickup;
