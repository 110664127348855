export default function ApplicationThdForm(){
  return(
    <>
      <div className="inner_title" id="application_3">
        <span className="font_48 font600 font_color_black pdb_40">{service.join}</span>
        <span className="font_30 font300 font_color_black">{service.joinInfo}<br />{service.jComment1}</span>
      </div>
      <div className="inner_content_wrap" id="application_33">
        <ul className="application_3_list">
          {appJoin.map((item, i) =>
            <li key={i}>
            <img src={item.img} />
            <span className="font_24 font600 text_center font_color_white application_3_title">{item.label}</span>
              {item.label === "유형선택" ? 
                <p className="font_20 font300 font_color_black text_center">
                    {item.comment}<br />{item.comment2} <br />{item.comment3}
                </p> 
                : 
                <p className="font_20 font300 font_color_black text_center">{item.comment}</p>
              }
          </li>
          )}
        </ul>
      </div>
    </>
  )
}