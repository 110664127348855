import axios from "axios";
import { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import Pagination from "react-js-pagination";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import Modal from "../../../../common/modal";

export default function ProductBrandBottom ({
  brandList, 
  page, 
  handlePageChange,
  getBrandList,
  bussinessCategory,
  largeCategory,
  middleCategory,
  smallCategory,
  detailCategory,
  checkedSet,
  setCheckedSet,
  selectedBrand,
  bussinessCode
}) {
  const isMobile = useMediaQuery({query: "(max-width:767px)"});
  const rows = brandList?.content?.map((item) => ({item}));
  const numChecked = checkedSet.size;
  const [message, setMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const deleteBrand = () => {
    if(numChecked < 1){
      toast.error("삭제하실 리스트를 선택해 주세요.");
      return;
    }

    setMessage("해당 리스트를 삭제 하시겠습니까?");
    setOpenModal(true);
  };
  
  const postBrand = async () => {
    const checkedItems = Array.from(checkedSet);
    const url = '/api/brandSave';
    const bodyArr = [];

    checkedItems.forEach((item) => {
      const obj = {
        delYn : "Y",
        cmpnyNo: item.cmpnyNo,
        seq : item.seq,
      }
      bodyArr.push(obj);
    });

    Promise.all(
      bodyArr.map(async body => {
        try {
          await axios.post(url, body);
        } catch(error) {
          // setMessage("제품 삭제를 실패했습니다.");
        }
      })
    ).then(() => {
      // setMessage("제품이 삭제 되었습니다.");

      getBrandList({page});
      setCheckedSet(new Set());
      setOpenModal(false);
      toast.success("선택하신 리스트가 삭제 되었습니다.");
    });
  }

  const handleCheckChange = (item) => {
    setCheckedSet((prevSet) => updateSet(prevSet, item));
  };

  const updateSet = (set, item) => {
    const updatedSet = new Set(set);

    if (updatedSet.has(item)) {
      updatedSet.delete(item);
    } else {
      updatedSet.add(item);
    };

    return updatedSet;
  };

  const toggleAllChecked = ({ target: { checked } }) => {
    if (checked) {
      const allChecked = new Set(rows.map(({ item }) => item));
      setCheckedSet(allChecked);
    } else {
      setCheckedSet(new Set());
    };
  };

  const deleteModalForm = ( message ) => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">연동 리스트 삭제</h2>
        <button
          onClick={() => setOpenModal(!openModal)}
          className="hp_step_setting_wrap_close"
        >
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          {message}
        </span>
        
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px"
          }}
        >
          <button
            onClick={() => setOpenModal(!openModal)}
            className="hp_step_setting_cate_save"
            style={{
              padding : "10px 15px",
              margin: "0px",
              background : "#6C757D",
              fontSize : "16px"
            }}
          >
            취소
          </button>
          <button
            onClick={postBrand}
            className="hp_step_setting_cate_save"
            style={{
              padding : "10px 15px",
              margin: "0px",
              fontSize : "16px"
            }}
          >
            확인
          </button>
        </div>
      </div>
    )
  };
  return (
    <div className="hp_step_product_list">
      <div className="hp_step_product_list_wrap">
        <table className="table_sm_list work_place hp_step">
          <colgroup>
            <col style={{width: '5%'}} />
            <col style={{width: '10%'}} />
            <col style={{width: '25%'}} />
            <col style={{width: '11%'}} />
            <col style={{width: '11%'}} />
            <col style={{width: '11%'}} />
            <col style={{width: '11%'}} />
            <col style={{width: '11%'}} />
            <col style={{width: '5%'}} />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">
                <input 
                  type="checkbox" 
                  onChange={toggleAllChecked}
                  checked={numChecked === rows?.length && brandList?.length !== 0}
                />
              </th>
              <th scope="col"><span className="text_center">번호</span></th>
              <th scope="col"><span className="text_center">브랜드</span></th>
              <th scope="col"><span className="text_center">업종</span></th>
              <th scope="col"><span className="text_center">대분류</span></th>
              <th scope="col"><span className="text_center">중분류</span></th>
              <th scope="col"><span className="text_center">소분류</span></th>
              <th scope="col"><span className="text_center">세분류</span></th>
              <th scope="col"><span className="text_center">현 시간 이후</span></th>
            </tr>
          </thead>
          <tbody id="place_tbody">
           {brandList?.content?.map((item) =>
            item?.delYn === "N" &&
            <tr>
              <td className="input_50">
                <input
                  type="checkbox"
                  onClick={() => handleCheckChange(item)}
                  checked={checkedSet.has(item)}
                />
              </td>
              <td className="input_50"><span>{item.seq}</span></td>
              <td className="input_50"><span>{item.brand}</span></td>
              <td className="input_50">
                <span>
                {bussinessCategory?.filter((category) => category?.code === item?.bsnsTy)[0]?.codeNm}
                </span>
              </td>
              <td className="input_50">
                <span>
                  {/* {item.prductCl1} */}
                  {/* {largeCategory?.filter((category) => category?.code === item?.prductCl1)[0]?.codeNm} */}
                </span>
              </td>
              <td className="input_50">
                <span>
                  {/* {item.prductCl2} */}
                  {/* {middleCategory?.filter((category) => category?.code === item?.prductCl2)[0]?.codeNm} */}
                </span>
              </td>
              <td className="input_50">
                <span>
                  {/* {item.prductCl3} */}
                  {/* {smallCategory?.filter((category) => category?.code === item?.prductCl3)[0]?.codeNm} */}
                </span>
              </td>
              <td className="input_50">
                <span>
                  {/* {item.prductCl4} */}
                  {/* {detailCategory?.filter((category) => category?.code === item?.prductCl4)[0]?.codeNm} */}
                </span>
                </td>
              <td className="input_50">{item.allTimeFlag ?<AiOutlineCheck size="20" color="#0074ca"/> : null}</td>
            </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="hp_step_product_btn_wrap">
        <button
          onClick={deleteBrand}
          type="button"
          className="product_btn product_btn_delete"
        >
          삭제
        </button>
      </div>
      
      <Pagination
        activePage={page}
        itemsCountPerPage={10}
        totalItemsCount={brandList?.totalElements}
        pageRangeDisplayed={isMobile ? 5 : 10}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={handlePageChange}
      />

      <Modal
        modalState={openModal}
        // handleModalState={handleDeleteModal}
        html={deleteModalForm(message)}
        w={isMobile ? "60%" :"15%"}
        h="auto"
      />
    </div>
  )
}