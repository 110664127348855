import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IoNotificationsOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { MdMenu } from "react-icons/md";
import { GrGroup } from "react-icons/gr";
import { RiServiceLine } from "react-icons/ri";
import axios from "axios";
import { LuUser } from "react-icons/lu";
import { toast } from "react-toastify";

import Modal from "../../../../common/modal";

import { onClickLogout } from "../../../../store/user";
import { resetSetting } from "../../../../store/scheduler";

import { HeaderContainer } from "../styled/common";
import TooltipSideMenu from "../../../../common/tooltipSideMenu";

const GroupHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.data[0]);
  const [clickSideMenu, setClickSideMenu] = useState(null);
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    if (user) {
      getNotification();
    }
  }, []);

  const getNotification = async () => {
    const url = "/api/notificationHistory";
    const body = {
      mberNo: user.mberNo,
    };
    const res = await axios.post(url, body);
    const data = res?.data;
    if (data && data.length > 0) {
      const filterNotification = data.filter((not) => not.readYn === "N");
      setNotification(filterNotification);
    }
  };

  const menuItems = [
    user && {
      title: "내 정보",
      icon: LuUser,
      items: [{ link: "/mypage/modifyProfile", label: "정보 수정" }],
    },
    {
      icon: GrGroup,
      title: "모임",
      items: [
        { link: "/group", label: "모임" },
        { link: "/group/myGroup", label: "마이 모임" },
        { link: "/group/feed", label: "소식" },
        { link: "/group/createGroup", label: "모임 생성" },
      ],
    },
    {
      title: "서비스",
      icon: RiServiceLine,
      items: serviceMenu,
    },
  ].filter(Boolean);

  // 로그아웃
  const [modalOpen, setModalOpen] = useState(false);
  const handleLogoutModal = () => {
    setModalOpen(!modalOpen);
  };
  const onClickModalCheck = async () => {
    await dispatch(onClickLogout());
    await dispatch(resetSetting());
    setModalOpen(false);
    navigate("/group");
  };

  const LogoutModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">로그아웃</h2>
        <button onClick={handleLogoutModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_20 font300 font_color_gray pdt_20 text_center">
          로그아웃 하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={onClickModalCheck}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const openMyPage = async () => {
    const url = "/api/domainSettingLoad";
    const req = {
      delYn: "N",
      cmpnyNo: user.cmpnyNo,
    };

    const res = await axios.post(url, req);
    if (res.data.domain) {
      window.open(`https://1472.ai/shop/${res.data.domain}`);
      user && localStorage.setItem("user", JSON.stringify(user));
    } else {
      toast.error("홈페이지가 존재하지 않습니다.");
    }
  };

  return (
    <HeaderContainer id="groupHeader">
      <Modal
        modalState={modalOpen}
        handleModalState={handleLogoutModal}
        html={LogoutModalForm()}
        w="300px"
        h="auto"
      />
      <div className="headerInner">
        <h1>{service.welcome}</h1>
        <Link to={"/"}>
          <div>
            <img className="logo" src="/img/logo_monoti.png" />
          </div>
        </Link>
        <div className="headerBtnArea">
          {!user ? (
            <button className="login" onClick={() => navigate("/group/login")}>
              <i className="icon_login" />
              <span className="sound_only">로그인</span>
            </button>
          ) : (
            <>
              <button onClick={handleLogoutModal} className="logout">
                <i className="icon_logout"></i>
              </button>
              <button onClick={() => navigate("/mypage")} className="mypage">
                <AiOutlineUser />
              </button>
              <Link
                to="/mypage/notify"
                target="_blank"
                className="notify"
                onClick={() => user && localStorage.setItem("user", JSON.stringify(user))}>
                <IoNotificationsOutline />
                {notification.length > 0 && (
                  <span>{notification.length < 99 ? notification.length : "99+"}</span>
                )}
              </Link>
            </>
          )}
          <button onClick={() => setClickSideMenu(true)} className="sideMenu">
            <MdMenu />
          </button>
        </div>
      </div>
      {clickSideMenu && (
        <TooltipSideMenu setClickSideMenu={setClickSideMenu} clickSideMenu={clickSideMenu} />
      )}
    </HeaderContainer>
  );
};

export default GroupHeader;
