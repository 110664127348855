import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import Modal from "../../../../../../common/modal";
import { DeleteGrpContainer } from "../../../styled/group";

import { GoAlertFill } from "react-icons/go";

const GroupDetailSettingWithdrawal = ({ userInfo }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const user = useSelector((state) => state?.user?.data[0]);

  // 모임 탈퇴 모달
  const exitModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">탈퇴</h2>
        <button onClick={handleExitModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_20 font300 font_color_gray pdt_20 text_center">
          탈퇴하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={onClickModalCheck}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const handleExitModal = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  // 모임 탈퇴
  const onClickModalCheck = async () => {
    const url = "/api/groupMber";
    const req = {
      groupNo: parseInt(id),
      mberNo: user.mberNo,
      auth: userInfo.auth,
      createMberId: user.userName,
      updateMberId: user.userName,
      delYn: "Y",
      seq: userInfo.seq,
    };

    const res = await axios.put(url, req);
    if (res.status === 200) {
      navigate("/group", { state: { exitMessage: "모임 탈퇴가 완료되었습니다." } });
    } else {
      toast.error("잠시후 다시 시도해주세요");
    }
  };

  return (
    <DeleteGrpContainer>
      <div className="msg">
        <div className="title">
          <GoAlertFill />
          <span>모임 삭제 안내</span>
        </div>
        <div className="info">
          <p>
            모임을 탈퇴하더라도 이전에 작성하신 게시글은 <strong>삭제되지 않고 유지</strong>
            됩니다.
          </p>
          <p>
            해당 모임이 <strong>비공개</strong> 설정일 경우
          </p>
          <p>
            추후 다시 가입하고자 할 경우 초대를 받아야만
            <strong>재가입이 가능</strong>합니다.
          </p>
          <p>
            또한, 이 모임이 <strong>가입 승인제</strong>로 운영되고 있는 경우
          </p>
          <p>
            재가입 시에는 <strong>모임장의 승인</strong>이 필요함을 유념해 주시기 바랍니다.
          </p>
          <p>탈퇴 결정이 확정적이라면, 아래의 버튼을 클릭하여 진행해 주십시오.</p>
        </div>
        <div className="btnArea">
          <button onClick={handleExitModal}>탈퇴하기</button>
        </div>
      </div>
      <Modal
        modalState={modalOpen}
        handleModalState={handleExitModal}
        html={exitModalForm()}
        w="300px"
        h="auto"
      />
    </DeleteGrpContainer>
  );
};

export default GroupDetailSettingWithdrawal;
