import { useEffect, useRef, useState } from "react";
import { AiOutlineClose, AiOutlineDownload, AiOutlineMenu } from "react-icons/ai";
import { HomepageSettingContainer } from "./styled/homePageSetting";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import uuid from "react-uuid";

export default function HomePageSettingForm() {
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    bannerImgList: [],
    bannerImgTextList: [],
  });
  const [tempImages, setTempImages] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width:1400px)" });

  const fileImportRef = useRef(null);
  const [height, setHeight] = useState();

  function windowResize() {
    if (fileImportRef.current) {
      const width = fileImportRef.current.offsetWidth;
      setHeight(Math.floor(width / 3.0206));
    }
  }

  // 데이터 조회
  useEffect(() => {
    getMySettingData();
    windowResize();
    window.addEventListener("resize", windowResize);
  }, []);

  const getMySettingData = async () => {
    try {
      const data = await getMySetting();
      if (data) {
        parseAndSetData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMySetting = async () => {
    const url = "/api/firstSettingLoad";
    const request = getDataCondition();
    const response = await axios.post(url, request);
    return response.data;
  };

  const getDataCondition = () => {
    return {
      cmpnyNo: user.cmpnyNo,
      delYn: "N",
    };
  };

  // 배열 확인 및 세팅
  const parseAndSetData = (data) => {
    let bannerImgList = JSON.parse(data.bannerImgList) || [];
    let bannerImgTextList = JSON.parse(data.bannerImgTextList) || [];

    bannerImgList = bannerImgList.map((item, idx) => ({
      image: item,
      id: uuid(),
    }));

    bannerImgTextList = bannerImgTextList.map((item, idx) => ({
      text: item,
      id: bannerImgList[idx].id,
    }));

    const tempImages = new Array(bannerImgList.length).fill({ text: "" }).map((item, idx) => ({
      ...item,
      id: bannerImgList[idx].id,
    }));

    setFormData({
      bannerImgList,
      bannerImgTextList,
    });
    setTempImages(tempImages);
  };

  // 이미지 추가
  const addImage = (e) => {
    if (formData.bannerImgList.length >= 3) {
      toast.error("이미지는 최대 3장까지만 선택할 수 있습니다.");
      e.target.value = "";
      return;
    }

    const newId = uuid();
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      const imageUrl = reader.result;
      setFormData((prevData) => ({
        ...prevData,
        bannerImgList: [...(prevData.bannerImgList || []), { image: imageUrl, id: newId }],
        bannerImgTextList: [...(prevData.bannerImgTextList || []), { text: "", id: newId }],
      }));

      file.id = newId;
      setTempImages((prevImages) => [...prevImages, file]);
      e.target.value = "";
    };
  };

  // 이미지 추가 시 파일 업로드
  const uploadImages = async () => {
    const imagesResult = new Array(tempImages.length).fill({});

    for (let i = 0; i < tempImages.length; i++) {
      const item = tempImages[i];

      if (typeof item === "object" && item instanceof File) {
        const form = new FormData();
        form.append("file", item);
        form.append("ids", "N");
        form.append("mberNo", user.mberNo);

        const url = "/api/uploadFile";
        const res = await axios.post(url, form);

        imagesResult[i] = {
          image: res?.data?.responseData?.fileName,
          id: formData.bannerImgList[i].id,
        };
      }
    }

    return imagesResult;
  };

  // 겹치는 데이터 삭제
  const removeDupl = (uploadedImages) => {
    return uploadedImages.map((item, i) => {
      if (Object.keys(item).length === 0 || !item.image || !item.id) {
        return formData.bannerImgList[i];
      }
      return item;
    });
  };

  // 저장
  const onSave = async (e) => {
    e.preventDefault();
    const url = "/api/firstSettingSave";
    const uploadedImages = await uploadImages();

    if (!uploadedImages) {
      return;
    }

    const request = {
      cmpnyNo: user.cmpnyNo,
      delYn: "N",
      bannerImgList: JSON.stringify(removeDupl(uploadedImages).map((item) => item.image)),
      bannerImgTextList: JSON.stringify(formData.bannerImgTextList.map((item) => item.text)),
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    await axios.put(url, request);
    navigate("/mypage/menuSetting", { state: { saveMessage: true } });
  };

  // banner text 변경
  const changeInput = (e, id) => {
    const newText = formData.bannerImgTextList.map((item) => {
      if (item.id === id) {
        return { ...item, text: e.target.value };
      }
      return item;
    });

    setFormData((prevData) => ({
      ...prevData,
      bannerImgTextList: newText,
    }));
  };

  // 삭제
  const removeImage = (e, id) => {
    e.preventDefault();
    setFormData((prevData) => ({
      ...prevData,
      bannerImgList: prevData.bannerImgList.filter((item) => item.id !== id),
      bannerImgTextList: prevData.bannerImgTextList.filter((item) => item.id !== id),
    }));
    setTempImages(tempImages.filter((item) => item.id !== id));
  };

  // dnd
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;

    const moveItem = (array, startIndex, endIndex) => {
      const [removed] = array.splice(startIndex, 1);
      array.splice(endIndex, 0, removed);
    };

    const bannerImgList = [...formData.bannerImgList];
    moveItem(bannerImgList, source.index, destination.index);

    const bannerImgTextList = [...formData.bannerImgTextList];
    moveItem(bannerImgTextList, source.index, destination.index);

    const tempImagesCopy = [...tempImages];
    moveItem(tempImagesCopy, source.index, destination.index);

    setFormData((prevData) => ({
      ...prevData,
      bannerImgList,
      bannerImgTextList,
    }));
    setTempImages(tempImagesCopy);
  };

  const onEdit = (e) => {
    e.preventDefault();
    setIsEdit(!isEdit);
  };

  const example = () => {
    return (
      <li>
        <div className="preview">
          <img
            src="https://cloud.1472.ai:18443/images/cloud/portfolio/P_f34a4b97-6e2b-40ed-95fd-917bc1b5ece5.jpg"
            style={{ height }}
          />
          <p>예시입니다</p>
        </div>
        <div className="writing_cont inputxt_normal" id="hp_step_1_slogan">
          <input type="text" value="이미지 추가 시 삭제됩니다" readOnly />
        </div>
      </li>
    );
  };

  return (
    <HomepageSettingContainer>
      <div className="inner_content_wrap">
        <form method="post" onSubmit={onSave}>
          <div>
            <div id="step1_html">
              <div className="writing_ty2">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="writingList"
                    direction={isMobile ? "vertical" : "horizontal"}>
                    {(provided, snapshot) => (
                      <ul
                        className="writingList"
                        bordered
                        hover
                        responsive
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ backgroundColor: snapshot.isDraggingOver ? "#efefef" : "#fff" }}>
                        <li ref={fileImportRef} style={{ height: height + 63.64 }}>
                          <div className="file_import">
                            <p>이미지를 추가해주세요.</p>
                            <label>
                              <AiOutlineDownload />
                              <input
                                type="file"
                                id="wk_file"
                                name="mainImg"
                                accept=".jpg, .png"
                                onChange={addImage}
                              />
                            </label>
                          </div>
                        </li>
                        {formData.bannerImgList.length < 1
                          ? example()
                          : formData.bannerImgList.map((item, idx) => (
                              <Draggable draggableId={item.id} index={idx} key={item.id}>
                                {(provided) => (
                                  <li
                                    key={item.id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}>
                                    {isEdit ? (
                                      <div className="dragArea" {...provided.dragHandleProps}>
                                        <div>
                                          <AiOutlineMenu />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="deleteArea">
                                        <button onClick={(e) => removeImage(e, item.id)}>
                                          <AiOutlineClose />
                                        </button>
                                      </div>
                                    )}
                                    <div className="preview">
                                      <img
                                        src={imgHandler(item.image) || ""}
                                        alt=""
                                        style={{ height }}
                                      />
                                      <p>{formData.bannerImgTextList[idx].text || ""}</p>
                                    </div>
                                    <div
                                      className="writing_cont inputxt_normal"
                                      id="hp_step_1_slogan">
                                      <input
                                        type="text"
                                        placeholder="메인 배너 텍스트를 입력하세요."
                                        value={formData.bannerImgTextList[idx].text || ""}
                                        onChange={(e) => changeInput(e, item.id)}
                                        maxLength="16"
                                      />
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className="btnarea_center pdt_40 hp_step_btn saveAndEdit">
                  {formData.bannerImgList.length > 1 && (
                    <button className="btn_normal" onClick={(e) => onEdit(e)}>
                      {isEdit ? "완료" : "순서 변경하기"}
                    </button>
                  )}
                  <button type="submit" className="btn_normal_save">
                    저장하고 다음 단계
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </HomepageSettingContainer>
  );
}
