import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { setFaqIndex } from "../../../../store/compoIndex";

const FaqSubMenu = ({index}) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({query: "(max-width:1050px)"});

  return(
    <div className="inner_title">
      <span className="font_48 font600 font_color_black pdb_40">F A Q</span>
      <span className="font_30 font300 font_color_black pdb_40">{service.qComment1}</span>
      <ul className="inner_title_menu">
        {faqMenu.map((item, i) =>
          <li onClick={() => dispatch(setFaqIndex(i))} key={i} className={index === i ? "actived" : ""}><Link to={"/mypage/faq"}>{item.label}</Link></li>
        )}
        {isMobile && <li id="blank_space" style={{backgroundColor: "white", border : "white",}}><Link >{"\u00a0"}</Link></li>}
      </ul>
    </div>

  )
};

export default FaqSubMenu;