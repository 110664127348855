import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import uuid from "react-uuid";

import { IoMdClose } from "react-icons/io";

import {
  ProjectCloseButton,
  ProjectCreateSecContainer,
  ProjectSection,
} from "./component/common/styled/project";

import ProjectCreateSecInputField from "./component/projectCreateSecPage/projectCreateSecInputField";
import ProjectCreateSecPreview from "./component/projectCreateSecPage/projectCreateSecPreview";
import ProjectCreateSecEditor from "./component/projectCreateSecPage/projectCreateSecEditor";
import Modal from "../../../common/modal";

export default function ProjectCreateSec() {
  const navigate = useNavigate();
  const [isAddDesc, setIsAddDesc] = useState(false);
  const user = useSelector((state) => state?.user?.data[0]);
  const [formData, setFormData] = useState({
    orgNo: null,
    gbn: "C",
    mberNo: user.mberNo,
    projectNm: "",
    projectDesc: "",
    title: "",
    content: "",
    publicYn: "Y",
    completeYn: "N",
    delYn: "N",
    createMberId: user.userName,
    updateMberId: user.userName,
  });
  const [viewType, setViewType] = useState(0);
  const transformValue = viewType * -100;
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const projectInfo = location?.state?.projectInfo;
  const [focusState, setFocusState] = useState({
    projectNm: true,
    projectDesc: false,
    title: false,
    content: false,
  });
  const [duplProjectNm, setDuplProjectNm] = useState(false);
  const eventInfo = location?.state?.eventInfo;
  const [files, setFiles] = useState([]);
  const [notUploadeFiles, setNotUploadeFiles] = useState([]);
  const ckeditorInstanceRef = useRef(null);

  const handleFocus = (name) => {
    const updated = {};

    Object.keys(focusState).forEach((key) => {
      updated[key] = false;
    });

    updated[name] = true;

    setFocusState(updated);
  };

  const handleBlur = (name) => {
    setFocusState((state) => ({ ...state, [name]: false }));
  };

  useEffect(() => {
    if (projectInfo) {
      setFormData({ ...projectInfo });
      if (projectInfo.fileList) {
        setFiles([...projectInfo.fileList].map((item) => ({ ...item, name: item.fileNm })));
      }
      if (projectInfo?.projectDesc) {
        setIsAddDesc(true);
      }
    }
  }, []);

  // 애니메이션
  const handleViewTypeChange = () => {
    setViewType(viewType === 0 ? 1 : 0);
  };

  // 입력값 변경
  const changeInput = (e) => {
    const { name, type } = e.target;

    if (!name) return;

    if (name === "projectNm" && duplProjectNm) {
      setDuplProjectNm(false);
    }
    if (name === "orgNo" && projectInfo) {
      toast.error("프로젝트 생성 후에는 수정이 불가합니다.");
      e.target.value = formData.orgNo;
      return;
    }

    if (type === "checkbox") {
      const isChecked = e.target.checked ? "N" : "Y";
      setFormData((data) => ({ ...data, [name]: isChecked }));
    } else {
      const { value } = e.target;
      setFormData((data) => ({ ...data, [name]: value }));
    }
  };

  // 이미지 데이터 추출
  const extractImages = (content) => {
    const imgRegex = /<img.*?src=['"](data:image\/\w+;base64,.*?)['"].*?>/g;
    const matches = content.match(imgRegex);
    const images = [];

    if (matches) {
      matches.forEach((match) => {
        const dataUriMatch = match.match(/src=['"](data:image\/\w+;base64,.*?)['"]/);
        if (dataUriMatch && dataUriMatch[1]) {
          images.push(dataUriMatch[1]);
        }
      });
    }

    return images;
  };

  // 다중 파일 저장
  const uploadFileServer = async (isImages, fileList) => {
    try {
      let form = new FormData();

      fileList.forEach((file) => {
        let blob = dataURItoBlob(isImages ? file : file.filePath);
        form.append("files", blob, isImages ? uuid() : file.name);
      });
      form.append("ids", "N");
      form.append("mberNo", user.mberNo);
      if (!isImages) {
        form.append("fileType", "F");
      }

      const url = "/api/uploadFiles";
      const res = await axios.post(url, form);

      if (res.status === 200) {
        const responseData = res.data.responseData;

        let data = [];

        if (isImages) {
          return responseData;
        } else {
          data = responseData.map((item, idx) => ({
            ...item,
            originalName: notUploadeFiles[idx].name,
          }));
        }

        return data;
      }
    } catch (error) {
      console.error("업로드 중 오류 발생:", error);
    }
  };

  // Blob 형태 변경
  const replaceImagesWithUrls = (content, imageUrls) => {
    let updatedContent = content;
    if (!updatedContent) {
      return "";
    }
    const dataUriRegex = /src=['"]data:image\/[^;'"]+;base64,([^'"]+)['"]/g;
    let matches = updatedContent.match(dataUriRegex);

    imageUrls.forEach((imageUrl, index) => {
      updatedContent = updatedContent.replace(matches[index], `src="${imgHandler(imageUrl)}"`);
    });

    return updatedContent;
  };

  // 입력값 저장
  const handleSubmit = async (contents) => {
    const url = "/api/project";
    const body = {
      ...formData,
    };
    if (contents) {
      body.content = contents;
    }
    if (textNotExist(formData.title)) {
      body.title = body.projectNm;
    }
    if (!isAddDesc) {
      body.projectDesc = "";
    }

    const res = await axios.put(url, body);

    if (res.data.success) {
      const data = res.data.rtnModel;

      if (notUploadeFiles.length > 0) {
        const uploaded = await uploadFileServer(false, notUploadeFiles);
        if (uploaded && uploaded.length > 0) {
          handleUploadFiles(data.projectNo, uploaded);
        } else {
          return;
        }
      }

      if (
        projectInfo &&
        projectInfo.fileList &&
        projectInfo.fileList.some(
          (projectFile) => !files.some((file) => file.projectFileNo === projectFile.projectFileNo),
        )
      ) {
        const missingFiles = projectInfo.fileList.filter(
          (projectFile) => !files.some((file) => file.projectFileNo === projectFile.projectFileNo),
        );
        await handleServerDeleteFile(missingFiles);
      }
      toast.success(`프로젝트가 ${projectInfo ? "수정" : "등록"}되었습니다.`);

      if (eventInfo) {
        navigate("/mypage/calendarEntry", {
          state: { eventInfo: { ...eventInfo, projectNo: data.projectNo }, infoState: "write" },
        });
      } else {
        navigate("/mypage/projectList");
      }
    }
  };

  // 서버에 반영된 파일 삭제
  const handleServerDeleteFile = async (data) => {
    const splitFiles = { F: [], V: [] };

    data.forEach((file) => {
      if (file.fileType.startsWith("video")) {
        splitFiles.V.push(file);
      } else {
        splitFiles.F.push(file);
      }
    });

    for (const item of data) {
      const url = "/api/file";
      const body = {
        delYn: "Y",
        projectFileNo: item.projectFileNo,
        projectNo: item.projectNo,
        gbn: item.gbn,
        refeNo: item.refeNo,
        mberNo: user.mberNo,
        fileType: item.fileType,
      };

      try {
        const res = await axios.put(url, body);
        console.log("Response for item:", item, res.data);
      } catch (error) {
        console.error("Error updating item:", item, error);
      }
    }
  };

  // 서버에 파일 업로드
  const handleUploadFiles = async (projectNo, data) => {
    const splitFiles = { F: [], V: [] };

    data.forEach((file) => {
      if (file.fileType.startsWith("video")) {
        splitFiles.V.push(file);
      } else {
        splitFiles.F.push(file);
      }
    });

    try {
      const body = Object.keys(splitFiles).map(async (key) => {
        if (splitFiles[key].length > 0) {
          const url = "/api/file";
          const body = {
            projectNo,
            refeNo: projectNo,
            gbn: "N",
            mberNo: user.mberNo,
            fileType: key,
            delYn: "N",
            fileList: splitFiles[key].map((file) => ({
              fileNm: file.originalName,
              filePath: file.fileName,
            })),
            createMberId: user.userName,
            updateMberId: user.userName,
          };

          const res = await axios.put(url, body);
          return res;
        }
      });

      await Promise.all(body.filter(Boolean));
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = () => {
    if (!checkValidation()) {
      return;
    }
    setModalOpen(true);
  };

  const checkValidation = () => {
    const required = {
      projectNm: "프로젝트 이름",
    };

    for (const [key, displayName] of Object.entries(required)) {
      if (!formData[key]) {
        toast.error(`${displayName}를 입력해 주세요.`);
        return false;
      }
    }

    return true;
  };

  // 확인 버튼 눌렀을 때
  const handleConfirm = async () => {
    const images = extractImages(formData.content);

    if (images.length === 0) {
      await handleSubmit(formData.content);
    } else {
      const imageDatas = await uploadFileServer(true, images);
      if (!imageDatas) {
        return;
      }
      let imageUrls = imageDatas.map((item) => item.fileName);
      let reqContent = replaceImagesWithUrls(formData.content, imageUrls);
      await handleSubmit(reqContent);
    }
  };

  const ModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">
          프로젝트 {projectInfo ? "수정" : "생성"}
        </h2>
        <button onClick={() => setModalOpen(false)} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          프로젝트를 {projectInfo ? "수정" : "생성"}하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={handleConfirm}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  // 중복체크
  const checkDuplProjectNm = async () => {
    if (textNotExist(formData.projectNm)) {
      toast.error("프로젝트 명을 입력해주세요.");
      return;
    }

    const url = "/api/duplProjectNm";
    const body = {
      gbn: formData.gbn,
      orgNo: formData.orgNo,
      projectNm: formData.projectNm,
    };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      if (res.data.success) {
        setDuplProjectNm(true);
        toast.success("사용 가능한 프로젝트명입니다.");
      } else {
        setDuplProjectNm(false);
        toast.error(`이미 사용 중인 프로젝트명입니다. (비공개 포함)`);
      }
    }
  };

  const isDuplProjectNm = () => {
    if (!duplProjectNm && (!projectInfo || projectInfo.projectNm !== formData.projectNm)) {
      toast.error("프로젝트명 중복 확인을 해주세요.");
      return;
    }
    handleViewTypeChange(1);
  };

  // 파일 업로드
  const handleImportFile = async (e) => {
    const files = e.target.files;

    if (files) {
      const imgArr = [];
      let loadedCount = 0;

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (event) => {
          imgArr[i] = { path: event.target.result, id: uuid() };
          loadedCount++;
          files[i].imgName = imgArr[i].path;
          files[i].id = imgArr[i].id;

          if (loadedCount === files.length) {
            setRImages([...rImages, ...files]);
            setImages([...images, ...imgArr.filter(Boolean)]);
          }
        };

        reader.readAsDataURL(files[i]);
      }
    }
  };

  // 파일 삭제
  const handleDeleteFile = (item) => {
    const data = [...files].filter((file) => file !== item);
    setFiles(data);
    setNotUploadeFiles((data) => data.filter((file) => item !== file));
  };

  return (
    <ProjectSection>
      <ProjectCloseButton
        onClick={() =>
          eventInfo
            ? navigate("/mypage/calendarEntry", { state: { eventInfo } })
            : navigate("/mypage", { state: { slideIndex: 1 } })
        }>
        <IoMdClose />
      </ProjectCloseButton>
      <ProjectCreateSecContainer>
        <div className="inner">
          <h2>
            프로젝트 만들기 <span>STEP{viewType + 1}</span>
          </h2>
          <div className="createProjectContent">
            <div className="slideWrapper">
              <div
                style={{ transform: `translateX(${transformValue}%)`, opacity: !viewType && 1 }}
                className="slideContent">
                <ProjectCreateSecInputField
                  isAddDesc={isAddDesc}
                  setIsAddDesc={setIsAddDesc}
                  formData={formData}
                  changeInput={changeInput}
                  setFormData={setFormData}
                  handleFocus={handleFocus}
                  handleBlur={handleBlur}
                  projectInfo={projectInfo}
                  checkDuplProjectNm={checkDuplProjectNm}
                />
              </div>
              <div
                style={{ transform: `translateX(${transformValue}%)`, opacity: viewType && 1 }}
                className="slideContent">
                <ProjectCreateSecEditor
                  formData={formData}
                  setFormData={setFormData}
                  changeInput={changeInput}
                  handleFocus={handleFocus}
                  handleBlur={handleBlur}
                  files={files}
                  handleImportFile={handleImportFile}
                  ckeditorInstanceRef={ckeditorInstanceRef}
                  handleDeleteFile={handleDeleteFile}
                />
              </div>
            </div>
            <ProjectCreateSecPreview formData={formData} focusState={focusState} />
          </div>
          <div className="moveAndSaveButtons">
            {!viewType ? (
              <button onClick={isDuplProjectNm}>다음</button>
            ) : (
              <button onClick={() => handleViewTypeChange(0)}>이전</button>
            )}
            {viewType === 1 && <button onClick={openModal}>저장</button>}
          </div>
        </div>
        <Modal
          modalState={modalOpen}
          handleModalState={() => setModalOpen(false)}
          html={ModalForm()}
          w="300px"
          h="auto"
        />
      </ProjectCreateSecContainer>
    </ProjectSection>
  );
}
