import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  page : 1,
  selected : 10,
  prductNm : "",
};

const product = createSlice({
  name : "product",
  initialState,
  reducers : {
    setProductPage(state, action) {
      state.page = action.payload;
    },
    setProductSelected(state, action) {
      state.selected = action.payload;
    },
    setPrductNm(state, action) {
      state.prductNm = action.payload;
    },
  }
});

export const { setProductPage, setProductSelected, setPrductNm} = product.actions;
export default product;
