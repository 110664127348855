import React, { useRef, useEffect, useState } from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  ArcElement,
} from "chart.js";
import { StatisticsContainer } from "../../styled/salesCheck";
import { customTooltip, drawHoverCircle } from "./statistics/chartUtils";
import SelectedProduct from "./statistics/selectedProduct";
import SalesCheckInfo from "./common/salesCheckInfo";
import SortOptions from "./common/sortOptions";
import { useMediaQuery } from "react-responsive";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  ArcElement,
);

const data = [
  { name: "상품 A", sold: 30 },
  { name: "상품 B", sold: 45 },
  { name: "상품 C", sold: 75 },
  { name: "상품 D", sold: 110 },
  { name: "상품 E", sold: 120 },
  { name: "상품 F", sold: 100 },
  { name: "상품 G", sold: 85 },
];

export const chartData = {
  labels: data.map((item) => item.name),
  datasets: [
    {
      label: "",
      data: data.map((item) => item.sold),
      backgroundColor: "#6976EB",
      borderColor: "#fff",
      borderWidth: 1,
      borderRadius: 10,
      hoverBackgroundColor: "#2B3695",
    },
  ],
};

const options = {
  indexAxis: "y",
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },
    tooltip: { enabled: false, external: customTooltip },
  },
  scales: {
    x: {
      beginAtZero: true,
      min: 0,
      max: 140,
      grid: {
        drawBorder: true,
        drawOnChartArea: false,
      },
    },
    y: {
      beginAtZero: true,
      grace: "5%",
      grid: {
        drawBorder: true,
        drawOnChartArea: false,
      },
    },
  },

  events: ["mousemove"],
};

const Statistics = ({ active, tabs }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const chartCanvasRef = useRef(null);
  const hoverCanvasRef = useRef(null);
  const chartInstance = useRef(null);
  const [hoverInfo, setHoverInfo] = useState(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    const chartCanvas = chartCanvasRef.current;
    const hoverCanvas = hoverCanvasRef.current;
    const hoverCtx = hoverCanvas.getContext("2d");

    if (chartInstance.current) {
      chartInstance.current.destroy();
      chartInstance.current = null;
    }

    if (active === "statistics") {
      chartInstance.current = new Chart(chartCanvas, {
        type: "bar",
        data: chartData,
        options: {
          ...options,
          onHover: (event, activeElements) =>
            drawHoverCircle(chartInstance.current, hoverCtx, activeElements, setHoverInfo),
        },
      });

      hoverCanvas.width = chartCanvas.width;
      hoverCanvas.height = chartCanvas.height;
    } else {
      const tooltipEl = document.getElementById("chartjs-tooltip");
      if (tooltipEl) {
        tooltipEl.style.opacity = 0;
      }
      setHoverInfo(null);
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
      const tooltipEl = document.getElementById("chartjs-tooltip");
      if (tooltipEl) {
        tooltipEl.style.opacity = 0;
      }
    };
  }, [active]);

  return (
    <>
      <SalesCheckInfo active={active} tabs={tabs} />
      {/* <SortOptions /> */}
      <StatisticsContainer>
        <div className="selectChart">
          <span>상품별 주문통계</span>
          <select name="" id="">
            <option value="">이번달</option>
          </select>
        </div>
        {active === "statistics" && (
          <div className="chart" style={{ position: "relative" }}>
            <canvas ref={chartCanvasRef} style={{ zIndex: 1 }}></canvas>
            <canvas
              ref={hoverCanvasRef}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 2,
                pointerEvents: "none",
              }}></canvas>
            {!isMobile && <SelectedProduct hoverInfo={hoverInfo} />}
          </div>
        )}
      </StatisticsContainer>
    </>
  );
};

export default Statistics;
