import React, { useEffect, useState } from "react";
import {
  FeedPreviewItem,
  ProjectItem,
  SchedulerItem,
} from "../../../../../mypage/integratedSearch/component/searchItems";
import { StockItem, BlogItem } from "./groupDetailSearchItem";
import { useMediaQuery } from "react-responsive";
import { SearchResults } from "../../../../../mypage/integratedSearch/styled/search";

const GroupDetailSearchAll = ({ data, isSearch, cmpnys, tabChange, searchWord }) => {
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const [imageHeight, setImageHeight] = useState();

  const updateImageSize = () => {
    const previewWidth = document.querySelector(".preview")?.offsetWidth;
    setImageHeight(previewWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateImageSize);
    return () => {
      window.removeEventListener("resize", updateImageSize);
    };
  }, []);

  const itemTypes = {
    stockList: { label: "e층창고", component: StockItem, tabIndex: 1 },
    blogList: { label: "친구소식", component: BlogItem, tabIndex: 2 },
    scheduleList: { label: "일정", component: SchedulerItem, tabIndex: 3 },
    feedList: { label: "소식", component: FeedPreviewItem, tabIndex: 4 },
    projectList: { label: "프로젝트", component: ProjectItem, tabIndex: 5 },
  };

  return (
    <SearchResults>
      {isSearch &&
        Object.entries(data).map(([listName, itemList]) => (
          <div key={listName} className={`result ${listName}`}>
            <div className="label">
              <span>
                {itemTypes[listName].label}
                {itemList.length > 0 && <strong>{itemList.length.toLocaleString()}</strong>}
              </span>
              {itemList.length > 0 && (
                <button onClick={() => tabChange(itemTypes[listName].tabIndex)}>더보기</button>
              )}
            </div>
            {itemList.length > 0 ? (
              <ul>
                {itemList.slice(0, listName === "stockList" && !isTablet ? 5 : 3).map((item) => {
                  const ItemComponent = itemTypes[listName].component;

                  return (
                    <ItemComponent
                      item={item}
                      {...(listName === "stockList" && { updateImageSize, imageHeight, cmpnys })}
                      {...(listName === "feedList" && { tabChange })}
                      {...(listName === "scheduleList" && { searchWord })}
                    />
                  );
                })}
              </ul>
            ) : (
              <div>검색 결과가 존재하지 않습니다.</div>
            )}
          </div>
        ))}
    </SearchResults>
  );
};

export default GroupDetailSearchAll;
