import React from "react";
import SearchForm from "./searchForm";
import Pagination from "react-js-pagination";
import { GoPlus } from "react-icons/go";
import { MemberListContainer } from "../styled/company";
import { useSelector } from "react-redux";

const MemberList = ({ members, editWorkStatus, page, total, changePages, setIsInvite }) => {
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <MemberListContainer className={setting.mode}>
      <div className="viewer">
        <div className="info">
          <SearchForm />
          <button className="add" onClick={() => setIsInvite(true)}>
            <GoPlus />
          </button>
        </div>
        <div className="members">
          <ul>
            <li className="label">
              <div className="dot">
                <div></div>
              </div>
              <div className="mberNm">이름</div>
              <div className="username">아이디</div>
              <div className="moblphonNo">휴대번호</div>
              <div className="work">
                <span>근무여부</span>
                <i>?</i>
              </div>
            </li>
            {members.map((item) => (
              <li>
                <div className="dot">
                  <div></div>
                </div>
                <div className="mberNm">{item.mberNm}</div>
                <div className="username">{item.userId}</div>
                <div className="moblphonNo">{item.mberTel}</div>
                <div className="work">
                  <div
                    className="tabs"
                    onClick={() => editWorkStatus(item)}
                    style={{ background: item.isWorking ? "rgba(37, 99, 235, 0.7)" : "#bbb" }}>
                    <span
                      className="glider"
                      style={{
                        transform: `translate(${item.isWorking ? "100%, -50%" : "0, -50%"})`,
                      }}></span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Pagination
        activePage={page + 1}
        itemsCountPerPage={10}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={(e) => changePages(e)}
      />
    </MemberListContainer>
  );
};

export default MemberList;
