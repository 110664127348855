import MypageBanner from "../mypageBanner";
import RegisterNoticeWriteForm from "./component/register/registerNoticeWriteForm";

export default function RegisterNoticeWrite() {
  return (
    <>
      <MypageBanner />
      <RegisterNoticeWriteForm />
    </>
  );
}
