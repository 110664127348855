import React from "react";

import { InfoContainer } from "./styled/info";

import Visual from "./common/visual";
import Hero from "./common/hero";
import Mockup from "./common/mockup";
import Highlight from "./common/highlight";
import SecondaryMockup from "./common/secondaryMockup";

const InfoProject = () => {
  const components = [
    {
      component: Highlight,
      content: {
        description:
          "프로젝트 진행을 한 곳에서 관리할 수 있도록\n모든 관련 정보와 활동을 통합합니다.\n카테고리별로 구분시켜 사용하기에 용이합니다.",
        feature: "통합된 프로젝트 관리",
      },
    },
    {
      component: Mockup,
      content: {
        heading: "파일 공유 및 관리",
        description:
          "프로젝트와 관련된 모든 파일을 쉽게 공유하고\n중앙에서 관리할 수 있습니다.\n각 프로젝트에 필요한 문서, 자료, 이미지 등을 프로젝트 내에서 저장할 수 있어\n팀원들이 필요한 자료를 언제든지 바로 찾고 사용할 수 있습니다.\n파일 버전 관리를 통해 최신 파일을 유지할 수 있어, 문서 관리가 용이합니다.",
        feature: "손쉬운 파일 저장과 공유",
      },
    },
    {
      component: Hero,
      content: {
        heading: "작업 분담 및 추적 기능",
        description:
          "프로젝트 기능을 통해 프로젝트를 세분화하여 여러 개의 작업으로 나누며,\n각 작업을 팀원들에게 할당할 수 있습니다.\n팀원은 자신의 작업 상태를 실시간으로 업데이트하고 추적할 수 있어,\n누가 어떤 업무를 언제까지 완료해야 하는지\n명확하게 파악할 수 있습니다.",
        feature: "전반적인 진척 상황 쉽게 모니터링",
      },
    },
    {
      component: SecondaryMockup,
      content: {
        heading: "팀 내 실시간 협업 및 소통",
        description:
          "프로젝트 기능은 팀원 간 실시간 협업을 촉진합니다.\n프로젝트 관련 커뮤니케이션을 프로젝트 페이지 내에서 바로 진행할 수 있으며\n모든 토론과 피드백이 프로젝트에 연계되어 한눈에 볼 수 있습니다.\n의견을 교환하거나 피드백을 주고받을 수 있어,\n불필요한 지연을 줄일 수 있습니다.",
        feature: "신속한 의사결정 가능",
      },
    },
  ];
  return (
    <InfoContainer className="project">
      <Visual />
      {components.map(({ component: Component, content }, index) => (
        <Component key={index} content={content} type="project" />
      ))}
    </InfoContainer>
  );
};

export default InfoProject;
