import React, { useRef } from "react";

import ShopLocatorInfo from "./shopLocator/shopLocatorInfo";
import ShopLocatorSwiper from "./shopLocator/shopLocatorSwiper";
import { useMediaQuery } from "react-responsive";

const GroupLocator = () => {
  const images = Array.from({ length: 7 }, (_, idx) => idx);
  const locator = { title: "모임 추천", info: "가입하고 싶은 모임에 가입하세요", type: "group" };
  const swiperRef = useRef();

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  return (
    <div className="inner">
      <ShopLocatorInfo locator={locator} />
      <ShopLocatorSwiper images={images} type={locator.type} swiperRef={swiperRef} />
    </div>
  );
};

export default GroupLocator;
