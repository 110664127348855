import React from "react";
import { CompanyInfoContainer } from "../styled/company";
import moment from "moment";
import { weatherStates } from "../../../scheduler/component/function/dateInfo";
import { useSelector } from "react-redux";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router";

const CompanyInfo = ({ cmpnyNm, todayWeather }) => {
  const navigate = useNavigate();
  const setting = useSelector((state) => state?.user?.setting);

  const isWeather = (weather) => {
    if (!weather) return null;

    const state = weatherStates[weather?.weather];
    if (!state) return null;

    return (
      <div className="weather">
        <span className="icon">
          <state.icon />
        </span>
        <span>
          {weather?.temMin}℃/{weather?.temMax}℃
        </span>
      </div>
    );
  };

  return (
    <CompanyInfoContainer className={setting.mode}>
      <button onClick={() => navigate("/mypage")}>
        <FaAngleLeft />
      </button>
      <div className="info">
        <h3>{cmpnyNm}</h3>
        <div className="overview">
          <span>{moment().format("YYYY년 M월 D일")}</span>
          {todayWeather && isWeather(todayWeather)}
        </div>
      </div>
    </CompanyInfoContainer>
  );
};

export default CompanyInfo;
