import React from "react";
import { StorageDetailContainer } from "../../styled/pickUp";
import { Buttons } from "../../styled/common";

const AddPayment = ({ transformValue, extraCharges, handleAddPay, payType, setPayType }) => {
  return (
    <StorageDetailContainer
      className="slideContent center"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <>
        <div className="modalTit">추가결제금액 {extraCharges.toLocaleString()}원이 있습니다.</div>
        <div className="paymentMethod">
          <div className="con">
            <label htmlFor="card">
              <input
                type="radio"
                name="payType"
                id="card"
                value="1"
                checked={payType === "1"}
                onChange={(e) => setPayType(e.target.value)}
              />
              <span className="checkmark"></span>
            </label>
            <span>카드</span>
            <label htmlFor="cash">
              <input
                type="radio"
                name="payType"
                id="cash"
                value="2"
                checked={payType === "2"}
                onChange={(e) => setPayType(e.target.value)}
              />
              <span className="checkmark"></span>
            </label>
            <span>현금</span>
          </div>
        </div>
        <div className="modalTit">
          결제 유형을 선택하고 결제를 완료한 후, 확인 버튼을 눌러 주세요.
        </div>
      </>
      <Buttons>
        <button onClick={() => handleAddPay()}>확인</button>
      </Buttons>
    </StorageDetailContainer>
  );
};

export default AddPayment;
