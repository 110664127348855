import React, { useRef, useState } from "react";
import uuid from "react-uuid";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import InputField from "../../productAddForm/productInfoForm/inputField";
import ThumbnailUpload from "../../productAddForm/productInfoForm/thumbnailUpload";
import CategoryList from "../../productAddForm/productInfoForm/categoryList";

import useOutsideClick from "../../../../../../../hooks/useOutsideClick";

import { EditModalContainer } from "../../../styled/productForm";

const EditProductModal = ({
  selectedProduct,
  setIsDetail,
  categories,
  categoriesObj,
  closeModal,
}) => {
  const categoriesRef = useRef();
  useOutsideClick(categoriesRef, () => setViewCategory(false));

  const user = useSelector((state) => state?.user?.data[0]);
  const [product, setProduct] = useState({
    ...selectedProduct,
  });
  const [viewCategory, setViewCategory] = useState(false);

  const changeInput = (e) => {
    const { name, value } = e.target;
    setProduct((data) => ({ ...data, [name]: value }));
  };

  // 이미지 삭제
  const handleDeleteImage = (item) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      media: prevProduct.media.filter((img) => img.id !== item.id),
    }));
  };

  // 이미지 추가
  const handleAddImage = (e) => {
    const files = Array.from(e.target.files);

    if (files.length > 0) {
      const updatedFiles = files.map((file) => ({
        file,
        path: URL.createObjectURL(file),
        id: uuid(),
      }));

      setProduct((prevData) => ({
        ...prevData,
        media: [...prevData.media, ...updatedFiles],
      }));
    }
  };

  // 이미지 순서 변경 로직
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderArray = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    setProduct((prevProduct) => ({
      ...prevProduct,
      media: reorderArray(prevProduct.media, result.source.index, result.destination.index),
    }));
  };

  const handleUpload = async () => {
    try {
      let uploaded = [];

      const fileOnly = product.media.filter((file) => typeof file === "object");

      if (fileOnly.length) {
        const uploadedFiles = await uploadFiles(fileOnly);

        if (uploadedFiles) {
          uploaded = product.media.map((file) => {
            if (typeof file === "object") {
              const matchedFile = uploadedFiles.find((item) => item.origin === file.file.name);
              return matchedFile ? matchedFile.fileName : file.file.name;
            }
            return file;
          });
        } else {
          console.error("파일 업로드 실패");
          return;
        }
      } else {
        uploaded = product.media;
      }

      return uploaded;
    } catch (error) {
      console.error(error);
    }
  };

  const uploadFiles = async (files) => {
    const form = new FormData();

    files.forEach((item) => {
      form.append("files", item.file, item.file.name);
    });

    form.append("ids", "N");
    form.append("mberNo", user.mberNo);

    const url = "/api/uploadFiles";
    const res = await axios.post(url, form);

    const response = res?.data?.responseData;

    if (response && response.length > 0) {
      const originalFileNames = files.map((item) =>
        item.file.name.split(".").slice(0, -1).join("."),
      );

      return response.map((item, idx) => {
        const uploadedFileName = item.fileName.split("/").pop().split(".").slice(0, -1).join(".");
        const isSameName = originalFileNames.includes(uploadedFileName);

        return {
          ...item,
          origin: isSameName ? files[idx].file.name : undefined,
        };
      });
    }

    return null;
  };

  const handleEdit = async () => {
    let uploadedFiles = null;
    if (product.media && product.media.length > 0) {
      uploadedFiles = await handleUpload();
    }
    updateProduct(uploadedFiles);
  };

  const updateProduct = async (files) => {
    try {
      const url = "/erp/vendorProducts/update";
      const body = {
        id: selectedProduct?.id,
        input: {
          cmpnyNo: product?.cmpnyNo,
          cloudProductId: product?.cloudProductId || "",
          name: product?.name,
          description: product?.description,
          brand: product?.brand,
          specifications: product?.specifications,
          categoryId: product?.categoryId,
          media: Array.isArray(files) && files.length > 0 ? files : product?.media,
          quantity: product?.quantity ? parseInt(product.quantity) : null,
          price: product?.price ? parseFloat(product.price) : null,
        },
      };

      const res = await axios.post(url, body, {
        headers: { "Content-Type": "application/json" },
      });

      if (res.status === 200) {
        toast.success("상품이 변경되었습니다.");
        await closeModal(true);
      }
    } catch (error) {
      console.error("업데이트 실패:", error.response?.data || error.message, error.stack);
    }
  };

  // 카테고리 체크
  const handleChangeRadio = (item) => {
    setProduct((data) => ({
      ...data,
      categoryId: item.categoryId,
    }));

    setViewCategory(false);
  };

  const resetCategory = () => {
    setProduct((data) => ({ ...data, categoryId: null }));
  };

  // 카테고리별 스타일
  const treeCategoryView = (item) => {
    if (!item) return;
    if (item.path.includes("/")) {
      const count = [...item.path].filter((item) => item === "/").length + 1;
      return `5px 20px 5px ${count * 20}px`;
    }
    return "5px 20px";
  };

  return (
    <EditModalContainer>
      <h2 className="font_20 font500 font_color_white">상품 수정</h2>
      <button onClick={() => closeModal()} className="hp_step_setting_wrap_close">
        <i className="fa fa-times" aria-hidden="true" />
      </button>
      <div className="input_field">
        <ul>
          <ThumbnailUpload
            product={product}
            handleAddImage={handleAddImage}
            handleDeleteImage={handleDeleteImage}
            onDragEnd={onDragEnd}
            setIsDetail={setIsDetail}
          />
          <InputField
            label="제품명"
            name="name"
            placeholder="제품명을 입력해주세요."
            required
            onChange={changeInput}
            value={product.name}
          />
          <li className={"half_li"}>
            <div className={`writing_tit`}>
              <strong className="tit">
                소비자가
                <em className="point">필수</em>
              </strong>
            </div>
            <div className="writing_cont">
              <div className="inputxt_normal spider">
                <input
                  type="number"
                  name="price"
                  placeholder="소비자가를 입력해주세요."
                  onChange={changeInput}
                  value={product?.price > 0 ? product.price : null}
                  min={0}
                />
              </div>
            </div>
          </li>
          <InputField
            label="규격"
            name="specifications"
            placeholder="ex) 10x20cm"
            onChange={changeInput}
            half={true}
            value={product.specifications}
          />
          <li>
            <div className="writing_tit required">
              <strong className="tit">
                카테고리<em className="point">필수</em>
              </strong>
            </div>
            <div className="writing_cont">
              <CategoryList
                categories={categories}
                product={product}
                handleChangeRadio={handleChangeRadio}
                treeCategoryView={treeCategoryView}
                viewCategory={viewCategory}
                setViewCategory={setViewCategory}
                categoriesRef={categoriesRef}
                categoriesObj={categoriesObj}
                resetCategory={resetCategory}
              />
            </div>
          </li>
          <InputField
            label="브랜드"
            name="brand"
            half
            placeholder="브랜드를 입력해주세요."
            onChange={changeInput}
            value={product.brand}
          />
          <li className={"half_li"}>
            <div className={`writing_tit`}>
              <strong className="tit">수량</strong>
            </div>
            <div className="writing_cont">
              <div className="inputxt_normal spider">
                <input
                  type="number"
                  name="quantity"
                  placeholder="상품 수량을 입력해주세요."
                  onChange={changeInput}
                  value={product?.quantity > 0 ? product.quantity : null}
                  min={0}
                />
              </div>
            </div>
          </li>
          <InputField
            label="상품 설명"
            name="description"
            placeholder="상품 설명을 입력해주세요."
            onChange={changeInput}
            value={product.description}
          />
        </ul>
      </div>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
        <button
          onClick={() => closeModal()}
          className="hp_step_setting_cate_save"
          style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
          취소
        </button>
        <button
          onClick={handleEdit}
          className="hp_step_setting_cate_save"
          style={{ padding: "10px 20px", margin: "0px" }}>
          확인
        </button>
      </div>
    </EditModalContainer>
  );
};

export default EditProductModal;
