import React from "react";

const FilePreview = ({ item }) => {
  if (!item) return null;

  if (typeof item === "string") {
    const extension = item.split(".").pop().toLowerCase();
    const imageTypes = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "webp",
      "svg",
      "ico",
      "tiff",
      "tif",
      "heic",
      "heif",
      "raw",
      "jfif",
      "pjpeg",
      "pjp",
    ];

    if (imageTypes.includes(extension)) {
      return <img src={imgHandler(item)} alt="Image Preview" />;
    } else {
      return <video src={imgHandler(item)} autoPlay muted loop />;
    }
  }

  if (item?.file?.type?.startsWith("image/")) {
    return <img src={item.path} alt={item.name} />;
  } else if (item?.file?.type?.startsWith("video/")) {
    return <video src={item.path} autoPlay muted loop />;
  } else {
    console.log("지원되지 않는 파일 형식입니다");
    return null;
  }
};

export default FilePreview;
