import React from "react";
import { storageType } from "../function/kiosk";

const TabMenu = ({ active, setActive }) => {
  return (
    <ul className="storageTab">
      {Object.keys(storageType).map((key) => {
        const item = storageType[key];
        return (
          <li
            key={key}
            onClick={() => setActive(parseInt(key))}
            className={`hover${active === parseInt(key) ? ` ${item.key}` : ""}`}>
            {item.label} 보관
          </li>
        );
      })}
    </ul>
  );
};

export default TabMenu;
