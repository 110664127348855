import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import axios from "axios";

import Modal from "../../../../../common/modal";

const ChangePwForm = ({}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);
  const isMobile = useMediaQuery({ query: "(max-width:640px)" });
  const [modalOpen, setModalOpen] = useState(false);
  const [userPw, setUserPw] = useState({
    prevPassWord: "",
    password: "",
    confirmPassword: "",
  });

  const changePassword = (e) => {
    const { name } = e.target;

    if (!name) return;

    const { value } = e.target;
    setUserPw((data) => ({ ...data, [name]: value }));
  };

  // 비밀번호 체크
  const validatePassword = async () => {
    const fieldLabels = {
      prevPassword: "현재 비밀번호",
      password: "비밀번호",
      confirmPassword: "비밀번호 확인",
    };

    for (let key in fieldLabels) {
      if (textNotExist(fieldLabels[key])) {
        const label = fieldLabels[key] || "";
        toast.error(`${chooseParticle(label, "을를")} 입력해주세요.`);
        return;
      }
    }

    const regPassword = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[#?!@$ %^&*-]).{8,}$/;
    if (!regPassword.test(userPw.password)) {
      toast.error("새 비밀번호 양식이 올바르지 않습니다.");
      return;
    }

    if (userPw.password !== userPw.confirmPassword) {
      toast.error("새 비밀번호가 일치하지 않습니다.");
      return;
    }

    const isUser = await isUserVerify();
    if (isUser) {
      handleModalOpen();
    }
  };

  const isUserVerify = async () => {
    const url = "/api/signIn";
    const body = {
      id: user.userName,
      pwd: userPw.prevPassWord,
    };

    const res = await axios.post(url, body);

    if (res.data.success === false) {
      toast.error("비밀번호가 일치하지 않습니다.");
      return false;
    } else {
      return true;
    }
  };

  // 모달
  const handleModalOpen = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  const ModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">비밀번호 수정</h2>
        <button onClick={handleModalOpen} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_20 font300 font_color_gray pdt_20 text_center">
          비밀번호를 변경하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={handleSubmitPassword}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  // 비밀번호 변경
  const handleSubmitPassword = async () => {
    const url = "/api/newPasswd";
    const body = {
      mberNo: user.mberNo,
      password: userPw.password,
      username: user.userName,
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: true,
    };

    const res = await axios.post(url, body);
    console.log(res);

    if (res.data.success && res.data.message === "새로운비밀번호 변경완료") {
      toast.success("비밀번호가 변경되었습니다.");
      navigate("/mypage");
    } else {
      toast.error("잠시 후 다시 시도해주세요.");
    }
  };

  return (
    <div className="writing_ty2 m_join_wrap">
      <div className="step_tit_bar m_join">
        <span className="font_32 font600 font_color_1 pdb_20">비밀번호 변경</span>
        <span className="font_16 font_color_gray font300 text_left pdb_20">
          ※ 모두 필수 입력 정보입니다.
        </span>
      </div>
      <ul className="writing_list m_join">
        <li className="no_border">
          <div className="writing_tit required">
            <strong className="tit">현재 비밀번호</strong>
          </div>
          <div className="writing_cont">
            <div className="inputxt_normal spider between_f">
              <input
                type="password"
                name="prevPassWord"
                placeholder="현재 비밀번호를 입력해주세요."
                value={userPw.prevPassWord}
                onChange={(e) => changePassword(e)}
                tabIndex={1}
              />
              <button
                type="button"
                name="button"
                className="btn_line gray btn_duplicate no_cusor"
                style={{
                  width: 44,
                  background: "#0074ca",
                }}>
                <i className="icon_secure"></i>
              </button>
            </div>
          </div>
        </li>
        <li className="no_border">
          <div className="writing_tit required">
            <strong className="tit">새 비밀번호</strong>
          </div>
          <div className="writing_cont">
            <div className="inputxt_normal spider between_f">
              <input
                type="password"
                name="password"
                placeholder="새 비밀번호 (영문 + 숫자 + 특수문자, 8자리이상)"
                value={userPw.password}
                onChange={(e) => changePassword(e)}
                tabIndex={2}
              />
              <button type="button" name="button" className="btn_line gray btn_duplicate no_cusor">
                <i className="icon_secure"></i>
              </button>
            </div>
          </div>
        </li>
        <li className="no_border">
          <div className="writing_tit required">
            <strong className="tit">새 비밀번호 확인</strong>
          </div>
          <div className="writing_cont">
            <div className="inputxt_normal spider between_f">
              <input
                type="password"
                name="confirmPassword"
                placeholder="새 비밀번호 확인"
                value={userPw.confirmPassword}
                onChange={(e) => changePassword(e)}
                tabIndex={3}
              />
              <button type="button" name="button" className="btn_line gray btn_duplicate no_cusor">
                <i className="icon_secure"></i>
              </button>
            </div>
          </div>
        </li>
      </ul>
      <div className="btnarea_center mt20">
        <button
          className="btn_normal"
          style={{ border: "none" }}
          onClick={validatePassword}
          tabIndex={4}>
          <span>비밀번호 변경</span>
        </button>
      </div>
      <Modal
        modalState={modalOpen}
        handleModalState={handleModalOpen}
        html={ModalForm()}
        w={isMobile ? "70%" : "17%"}
        h="auto"
      />
    </div>
  );
};

export default ChangePwForm;
