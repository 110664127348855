import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import "./styled/company.css";
import ShopLoading from "../../common/ShopLoading";

export default function CompanyForm() {
  const { cmpnyNo, url, theme, homepageInfo } = useOutletContext();
  const [cmpnyInfo, setCmpnyInfo] = useState("");
  const apiKey = "AIzaSyBahfp5JfpHc5fR1kEZVYN56pjIzlozRPY";
  const defaultAddress = "경기도 부천시 신흥로 223";
  const domainUrl = window.location.href;
  const [totalLamp] = useState(domainUrl.includes("marygold"));
  const [htmlData, setHtmlData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const scrollRef = useRef();
  const shopCompany = useSelector((state) => state.compoIndex.shopCompany);

  useEffect(() => {
    if (cmpnyNo) {
      getCmpnyInfo();
      getMySetting();
    } else {
      setIsLoading(false);
    }
  }, [cmpnyNo]);

  useEffect(() => {
    if (shopCompany === 0) {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        scrollRef?.current?.scrollIntoView({ behavior: "auto" });
      }, 200);
    }
  }, [shopCompany]);

  const getMySetting = async () => {
    const url = "/api/infoSettingLoad";
    const request = {
      cmpnyNo: cmpnyNo,
      delYn: "N",
    };
    const response = await axios.post(url, request);

    const htmlString = response?.data?.cmpnyIntroduce;
    if (htmlString) {
      const contents = document.getElementById("contents");
      contents.innerHTML = htmlString;
    }
    setHtmlData(htmlString);
    setIsLoading(false);
  };

  const getCmpnyInfo = async () => {
    const url = "/api/cmpnyInfo";
    const body = {
      cmpnyNo,
    };
    const res = await axios.post(url, body);
    if (res) {
      setCmpnyInfo(res.data);
    }
  };

  const copyAddress = () => {
    const address = cmpnyInfo.adres ?? defaultAddress;
    navigator.clipboard.writeText(address);
    toast.success("주소가 복사 되었습니다.");
  };

  const getThemeText = (textType) => {
    const textMap = {
      company: {
        A: "회사소개",
        B: "COMPANY",
        C: "COMPANY",
        D: "회사소개",
        E: homepageInfo?.cmpnyNm ? homepageInfo.cmpnyNm : "회사소개",
      },
      location: {
        A: "찾아오시는 길",
        B: "COMPANY",
        C: "WAY TO COME",
        D: "오시는 길",
        E: "오시는 길",
      },
    };

    return textMap[textType]?.[theme] || "";
  };

  return (
    <main id="contCompany" className="sub_page sub_page_mobile_mt">
      {!totalLamp && (
        <div className="subTop_bnr subTop_bnr_01">{theme === "B" && <span>COMPANY</span>}</div>
      )}
      {totalLamp && (
        <div>
          <img src={`/img/totallamp79/totallamp_banner.png`} alt="" />
        </div>
      )}
      <article
        className="boundary pdt_70 pdb_70"
        style={{ maxWidth: theme !== "E" && 1400, width: "100%" }}>
        <h2 className="font_36 pdb_30 font600 text_center">회사소개</h2>
        <section id="secBrdcrmb" className="font_14 pdb_20 text_right">
          <Link to={`/shop/${url}`}>
            <i className="xi-home-o font_20"></i>
          </Link>
          <span>회사소개</span>
        </section>
        <h3 className="font_24 company_address_h1">
          <span>{getThemeText("company")}</span>
        </h3>
        <section id="secMsg" className={!htmlData ? "text_center" : "company_wrapper"}>
          {isLoading && <ShopLoading />}
          <strong className="company_content" id="contents"></strong>
          {!htmlData && cmpnyInfo && (
            <h1 className="company_h1_font_size">안녕하세요. {cmpnyInfo.cmpnyNm} 입니다.</h1>
          )}
        </section>
        {
          // cmpnyInfo.adres &&
          <section id="secMap" className="pdt_70" ref={scrollRef}>
            <h3 className="font_24 company_address_h1">
              <span>{getThemeText("location")}</span>
            </h3>
            <div className="addr pdb_30 font_18 font_color_gray_dark address_btn_wrapper">
              {cmpnyInfo.adres ?? defaultAddress}
              {/* <input type="hidden" className="" value="대전 서구 청사로 65 109동 107호 (월평동, 황실타운)" /> */}
              <button className="addr_copy font_16 address_btn_wrapper_btn" onClick={copyAddress}>
                <i className="fa fa-copy"></i> 복사
              </button>
            </div>
            {/* <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3212.9928977890536!2d127.36687358877975!3d36.36095092112117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35654bc4cbc46db9%3A0xbaecc29d2aa2aad4!2z64yA7KCE6rSR7Jet7IucIOyEnOq1rCDssq3sgqzroZwgNjUgMTA37Zi4!5e0!3m2!1sko!2skr!4v1684425695475!5m2!1sko!2skr"
                width="1280" height="400" style={{maxWidth: "100%", border: "0"}} allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div> */}
            <div className="google_map_wrapper">
              <div className="map google_map_sub_wrapper">
                <iframe
                  style={{
                    width: "100%",
                    height: "450px",
                    border: 0,
                  }}
                  src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${
                    cmpnyInfo.adres || defaultAddress
                  }&zoom=18`}></iframe>
              </div>
            </div>
          </section>
        }
      </article>
    </main>
  );
}
