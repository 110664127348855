import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { CalendarContainer } from "../../../styled/salesCheck";
import moment from "moment";

const SalesCheckCalendar = ({ date, setOpenCalendar, changeSearched }) => {
  console.log(date);

  const dayCellContent = (info) => {
    const isSelected =
      date && moment(info.date).format("YYYYMMDD") === moment(date).format("YYYYMMDD");

    return (
      <div className={`fc-daygrid-day-number ${isSelected ? "selected-date" : ""}`}>
        {info.dayNumberText}
      </div>
    );
  };

  return (
    <CalendarContainer onClick={(e) => e.currentTarget === e.target && setOpenCalendar(false)}>
      <div className="calendar">
        <FullCalendar
          // ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          locale={"ko"}
          initialDate={date ? date : null}
          dateClick={(info) => changeSearched({ name: "date", value: info.dateStr })}
          dayCellContent={dayCellContent}
        />
      </div>
    </CalendarContainer>
  );
};

export default SalesCheckCalendar;
