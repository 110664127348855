import { GoArrowUp } from "react-icons/go";
import { useEffect, useState } from "react";
import { ScrollTopBtn } from "../styled/shopping";

const FooterScorllTopBtn = ({ theme }) => {
  const [isShowBtn, setIsShowBtn] = useState(false);

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 500) {
        setIsShowBtn(true);
      } else {
        setIsShowBtn(false);
      }
    };
    window.addEventListener("scroll", handleShowButton);
    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, [isShowBtn]);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <ScrollTopBtn
      style={{ display: isShowBtn ? "flex" : "none" }}
      onClick={scrollToTop}
      className={`theme-${theme}`}>
      <div>
        <GoArrowUp />
      </div>
    </ScrollTopBtn>
  );
};

export default FooterScorllTopBtn;
