import React from "react";
import ChunjiinKeyboard from "../common/keyboard";
import NumberKeyboard from "../common/numberKeyboard";

const ContactInfoInput = ({
  transformValue,
  formData,
  handleChangeInput,
  tabs,
  tab,
  determineStepAction,
}) => {
  const translateX = tab * -100;
  return (
    <>
      <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
        <div className="contactInputFields">
          {tabs.map((item) => (
            <div className="contactInputSlide" style={{ transform: `translateX(${translateX}%)` }}>
              <div className="inputBox">
                <div className="inputFields">
                  <input
                    type="text"
                    value={item.id === 0 ? formData.mberNm : formData.tel}
                    readOnly
                  />
                </div>
                <div className="keyboardFields">
                  {item.id == 0 ? (
                    <ChunjiinKeyboard handleChangeInput={handleChangeInput} />
                  ) : (
                    <NumberKeyboard
                      type="tel"
                      handleChangeInput={handleChangeInput}
                      inputValue={formData.tel}
                    />
                  )}
                </div>
                <div className="inputActions">
                  <button onClick={() => determineStepAction(true)}>확인</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ContactInfoInput;
