import NoticeBanner from "./noticeBanner";
import NoticeViewForm from "./component/noticeViewForm";

export default function NoticeView () {
  return(
    <>
      <NoticeBanner />
      <NoticeViewForm />
    </>
  )
}