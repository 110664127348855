import React from "react";
import { FaChevronRight } from "react-icons/fa6";
import { HiMiniPause } from "react-icons/hi2";
import { LocatorInfoContainer } from "../../styled/shop";
import { useMediaQuery } from "react-responsive";

const ShopLocatorInfo = ({ locator, slideEvent, isAutoplay = true }) => {
  const { title, info, type } = locator;
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  const handleClickButton = () => {
    if (slideEvent) {
      slideEvent();
    }
  };

  return (
    <LocatorInfoContainer style={{ padding: type === "restaurant" && isTablet ? "0 20px" : "" }}>
      <div className="info">
        <span>내 주변 {title}</span>
        <span>{info}</span>
      </div>
      {type === "restaurant" && (
        <div className="moreButton">
          <button onClick={handleClickButton}>
            {isAutoplay ? <HiMiniPause /> : <FaChevronRight />}
          </button>
        </div>
      )}
    </LocatorInfoContainer>
  );
};

export default ShopLocatorInfo;
