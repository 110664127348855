import React, { useEffect, useState, useRef } from "react";
import { useLocation, useOutletContext } from "react-router";
import { OrderContainer } from "../styled/shopping";
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";
import { useSelector } from "react-redux";
import uuid from "react-uuid";
import { useRegex } from "../../../hooks/useRegex";

const OrderPage = () => {
  const { url, cmpnyNo } = useOutletContext();
  const location = useLocation();
  const clientKey = "live_gck_5OWRapdA8dGRyYlOWMdbro1zEqZK";
  const user = useSelector((state) => state?.user?.data[0]);
  const customerKey = user?.id || "sample-customer-key"; // 고객 키
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState({ prdTotal: 0, deliveryFee: 0 });
  const paymentWidgetRef = useRef(null);
  const { TelFormat } = useRegex();

  useEffect(() => {
    const locationPrd = location?.state?.products;

    if (locationPrd) {
      setProducts(locationPrd);

      const productsTotal = locationPrd.reduce((acc, curr) => acc + curr.notitlePdCmPrice, 0); // 총 제품 가격
      const deliveryFee = getDeliveryFee(locationPrd); // 배송비
      setTotal({
        prdTotal: productsTotal,
        deliveryFee,
      });
    }
  }, [location]);

  useEffect(() => {
    if (total.prdTotal + total.deliveryFee > 0) {
      initPayment();
    } else {
      console.log("결제할 상품 없음");
    }
  }, [total]);

  // 주문 정보
  const getOrderDetails = () => {
    const orderName = `${products[0]?.prductNm}${
      products.length > 1 ? ` 외 ${products.length - 1}개` : ""
    }`;
    const customerName = user?.name || "홍길동";

    return {
      orderId: uuid(),
      orderName,
      customerName,
      customerEmail: "customer123@gmail.com",
    };
  };

  // 결제 위젯
  const initPayment = async () => {
    const paymentWidget = await loadPaymentWidget(clientKey, customerKey);
    const amount = total.deliveryFee + total.prdTotal;

    paymentWidget.renderPaymentMethods("#payment-widget", {
      value: amount,
      ...getOrderDetails(),
    });

    paymentWidgetRef.current = paymentWidget;
  };

  // 배송비 계산
  const getDeliveryFee = (data) => {
    const { totalDeliveryFee } = data.reduce(
      (acc, curr) => {
        if (!acc.cmpnyNos.has(curr.cmpnyNo)) {
          acc.cmpnyNos.add(curr.cmpnyNo);
          acc.totalDeliveryFee += curr.deliveryFee;
        }
        return acc;
      },
      { cmpnyNos: new Set(), totalDeliveryFee: 0 },
    );

    return totalDeliveryFee;
  };

  // 결제 버튼 클릭 시
  const handlePayment = async () => {
    if (!products || !products.length || !paymentWidgetRef.current) return;
    const orderUrl = url ? `/${url}/` : "";

    try {
      await paymentWidgetRef.current.requestPayment({
        ...getOrderDetails(),
        successUrl: `http://localhost:3000/shop${orderUrl}order/success`,
        failUrl: `http://localhost:3000/shop${orderUrl}order/fail`,
      });
    } catch (error) {
      console.error("Payment Error:", error.code, error.message);
      alert(error.message);
    }
  };

  const formatCommas = (num) => {
    if (!num) return 0;
    return num.toLocaleString();
  };

  return (
    <OrderContainer>
      <div className="test">테스트 결제입니다.</div>
      <h2>주문/결제</h2>
      {user && (
        <div className="userInfo">
          <span>{user.name}</span>
          <span>{TelFormat(user.moblphonNo)}</span>
        </div>
      )}
      {products && products.length > 0 && (
        <div className="products">
          <p className="info">주문상품 {products.length}개</p>
          <ul>
            {products.map((item) => (
              <li key={item.id}>
                <div className="prdImage">
                  <img src={isPrdImg(item)} alt={item.prductNm} />
                </div>
                <div className="prdInfo">
                  <span>{item.prductNm}</span>
                  <span>{formatCommas(item.notitlePdCmPrice)}원</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="total">
        <span>배송비 {formatCommas(total.deliveryFee)}원</span>
        <span>총 결제 금액 {formatCommas(total.prdTotal + total.deliveryFee)}원</span>
      </div>
      <div id="payment-widget" />
      <button onClick={handlePayment}>결제</button>
    </OrderContainer>
  );
};

export default OrderPage;
