import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { QnaTitleContainer } from "../styled/notice";

export default function FaqTitle() {
  const qna = ["꿀팁", "회원가입", "요금", "이용", "기타"];

  return (
    <QnaTitleContainer className="inner_title">
      <span className="font_48 font600 font_color_black pdb_40">{service.qa}</span>
      <span className="font_30 font300 font_color_black pdb_40">{service.qComment1}</span>
      <ul className="inner_title_menu">
        {qna.map((item) => (
          <li onClick={() => alert("준비 중입니다.")}>{item}</li>
        ))}
      </ul>
    </QnaTitleContainer>
  );
}
