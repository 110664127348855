import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { ModifyProfileContainer } from "./component/styled/dashboard";

import Modal from "../../../common/modal";
import ChangeUserInfoForm from "./component/modifyProfilePage/changeUserInfoForm";
import ChangePwForm from "./component/modifyProfilePage/changePwForm";
import MembershipWithdrawal from "./component/modifyProfilePage/membershipWithdrawal";

const ModifyProfilePage = () => {
  const location = useLocation();
  const tab = location?.state?.tab;
  const [tabIndex, setTabIndex] = useState(tab ? 2 : 0);

  // 탭 메뉴
  const tabs = ["계정정보변경", "비밀번호변경", "회원탈퇴"];

  function Components({ index }) {
    return <>{[<ChangeUserInfoForm />, <ChangePwForm />, <MembershipWithdrawal />][index]}</>;
  }

  return (
    <ModifyProfileContainer>
      <div className="sub_top" id="mypage">
        <span className="font_22 font300 pdb_20 font_color_white">회원정보수정</span>
        <span className="font_72 font700 font_color_1 pdb_70">EDIT</span>
      </div>
      <div className="inner_content">
        <ul className="tabMenu">
          {tabs.map((item, idx) => (
            <li onClick={() => setTabIndex(idx)} className={idx === tabIndex ? "active" : ""}>
              {item}
            </li>
          ))}
        </ul>
        {<Components index={tabIndex} />}
      </div>
    </ModifyProfileContainer>
  );
};

export default ModifyProfilePage;
