import React from "react";

const InputField = ({
  transformValue,
  changeShortcut,
  shortcut,
  changePage,
  selectMonotiService,
  services,
  isMonotiService,
}) => {
  const showUrlValue = () => {
    const fixed = "https://";

    if (!shortcut.url) return fixed;

    const target = isMonotiService();
    if (target) {
      return target.name;
    }

    if (shortcut.url.startsWith(fixed)) {
      return shortcut.url;
    }

    return `${fixed}${shortcut.url}`;
  };

  return (
    <div
      className="slideContent inputField"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <span className="label">바로가기 추가</span>
      <div className="content">
        <div className="name input">
          <span className="info">이름</span>
          <input
            type="text"
            placeholder="모노티"
            name="label"
            onChange={changeShortcut}
            value={shortcut.label}
          />
        </div>
        <div className="url input">
          <div className="info">
            <span>URL 또는</span>
            <div className="monotiService">
              {services.map((item) => (
                <button onClick={() => selectMonotiService(item)}>
                  <img src={`/img/quickLinks/${item.key}.png`} alt="" />
                </button>
              ))}
            </div>
          </div>
          <input
            type="text"
            placeholder="www.1472.ai"
            name="url"
            onChange={changeShortcut}
            value={showUrlValue()}
          />
        </div>
      </div>
      <div className="buttons">
        <button onClick={() => changePage()}>취소</button>
        <button onClick={() => changePage(true)}>다음</button>
      </div>
    </div>
  );
};

export default InputField;
