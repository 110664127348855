import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import BoardTable from "../../../../common/board/boardTable";
import ShopLoading from "../../common/ShopLoading";
import useQueryString from "../../../../hooks/useQueryString";
import Pagination from "react-js-pagination";
import { PaginationContainer } from "../../styled/shopping";
import NoticeList from "./noticeList";

export default function NoticeForm() {
  const navigater = useNavigate();
  const queryPage = Number(useQueryString("page"));

  const { url, cmpnyNo, theme } = useOutletContext();
  const [notices, setNotices] = useState([]);
  const [currentPage, setCurrentPage] = useState(queryPage || 0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (cmpnyNo) {
      getNotice();
    } else {
      setIsLoading(false);
    }
  }, [cmpnyNo]);

  useEffect(() => {
    navigater(`/shop/${url}/shoppingNotice?page=${currentPage}`);
  }, [currentPage]);

  const getNotice = async (page = queryPage || 0) => {
    setCurrentPage(page);
    const req = {
      gbn: "N",
      delYn: "N",
      cmpnyNo: cmpnyNo,
      offset: 10 * page,
      pageNumber: page,
      pageSize: 10,
      paged: true,
    };

    const response = await axios.post("/api/noticeList", req);
    const res = response.data;

    setIsLoading(false);
    setNotices(res.content);
    setTotalCount(res.totalElements);
  };

  const nextPage = (num) => {
    getNotice(num - 1);
  };

  const isTheme = () => {
    const themes = ["A", "D"];
    return themes.includes(theme);
  };

  return (
    <main id="contNoticeList" className="sub_page board sub_page_mobile_mt">
      <div className="subTop_bnr subTop_bnr_01">{theme !== "A" && <span>NEWS</span>}</div>
      <article className="boundary pdt_70 pdb_70" style={{ maxWidth: 1400, width: "100%" }}>
        <h2 className="font_36 pdb_30 font600 text_center">공지사항</h2>
        <section id="secBrdcrmb" className="font_14 pdb_20 text_right">
          <Link to={`/shop/${url}`}>
            <i className="xi-home-o font_20"></i>
          </Link>
          <span>공지사항</span>
        </section>
        <section id="boardList">
          {isLoading && <ShopLoading />}
          {isTheme() ? (
            <BoardTable notices={notices} isShop={true} url={url} />
          ) : (
            <NoticeList notices={notices} url={url} />
          )}
          {notices.length !== 0 && (
            <PaginationContainer>
              <Pagination
                activePage={currentPage + 1}
                itemsCountPerPage={10}
                totalItemsCount={totalCount}
                pageRangeDisplayed={5}
                prevPageText={"‹"}
                nextPageText={"›"}
                onChange={nextPage}
              />
            </PaginationContainer>
          )}
        </section>
      </article>
    </main>
  );
}
