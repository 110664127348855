import styled from "styled-components";

export const SimpleContainer = styled.main`
  min-height: 100vh;
  font-family: "Pretendard";
  img {
    user-select: none;
  }
  .info {
    font-size: 75px;
    font-weight: 700;
    margin-bottom: 70px;
    text-transform: uppercase;
  }
  .history {
    max-width: 1400px;
    margin: 160px auto;
    .content {
      .contentInfo {
        text-align: center;
        font-size: 30px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        margin-bottom: 100px;
        font-weight: 700;
      }
      .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        img {
          width: 184px;
          height: 184px;
          object-fit: contain;
          background-image: url("/img/shop_union_bg.png");
          background-repeat: no-repeat;
          touch-action: none;
          user-select: none;
          cursor: pointer;
        }
        span {
          display: block;
          font-size: 30px;
          text-align: center;
          margin-top: 30px;
        }
      }
    }
  }
  .products {
    width: 100%;
    max-width: 1400px;
    margin: 160px auto;
    .wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .box {
        position: relative;
        width: 25%;
        height: 516px;
        cursor: pointer;
        .image {
          width: 100%;
          height: 100%;
          transition: 0.3s;
          background: #fff;
          img {
            transition: 0.3s;
            width: 100%;
            height: 100%;
            filter: grayscale(50%);
            opacity: 0.5;
            object-fit: cover;
          }
        }
        .prdInfo {
          position: absolute;
          left: 0;
          top: 50%;
          font-size: 28px;
          padding: 0 20px;
          span {
            display: block;
            &:first-child {
              width: 100%;
              margin-bottom: 24px;
              height: 66px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            &.price {
              font-weight: lighter;
              background: #fff;
              width: 148px;
              text-align: center;
              padding: 8px 0;
            }
          }
        }
        &:hover {
          .image {
            background: #000;
            img {
              opacity: 0.5;
              filter: grayscale(0%);
            }
          }
          .prdInfo {
            color: #fff;
            .price {
              color: #000;
            }
          }
        }
      }
    }
  }
  .portfolio {
    width: 100%;
    max-width: 1400px;
    margin: 160px auto;
    .info {
      margin-bottom: 0;
      .more {
        margin-top: 70px;
        font-size: 18px;
        font-weight: normal;
        > span {
          display: block;
        }
        button {
          display: flex;
          align-items: center;
          background: transparent;
          gap: 8px;
          margin-top: 28px;
          .line {
            display: inline-block;
            height: 1px;
            width: 82px;
            background: #102c57;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 10px;
              height: 10px;
              background: #102c57;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .content {
      display: flex;
      gap: 24px;
      height: 850px;
      .box {
        width: calc((100% - 48px) / 3);
        height: 720px;
        cursor: pointer;
        .view {
          width: 100%;
          height: 100%;
          border: 1px solid #ccc;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .contentInfo {
          font-size: 28px;
          margin-top: 30px;
        }
        &:nth-child(odd) {
          padding-top: 130px;
        }
        &:nth-child(even) {
          padding-bottom: 130px;
        }
      }
    }
  }
  .news {
    background: #002153;
    padding: 60px 0;
    margin: 160px 0;
    .info {
      max-width: 1400px;
      margin: 0 auto 47px;
      color: #fff;
    }
    .content {
      max-width: 1400px;
      margin: 0 auto;
      display: flex;
      align-items: flex-end;
      gap: 40px;
      .more {
        button {
          display: flex;
          width: 300px;
          font-size: 40px;
          color: #fff;
          background: transparent;
          align-items: flex-end;
          text-align: left;
          gap: 40px;
          svg {
            display: block;
            path {
              stroke: #fff;
            }
          }
        }
      }
      .newsContent {
        display: flex;
        align-items: center;
        width: calc(100% - 340px);
        height: min-content;
        .line {
          width: 20px;
          height: 1px;
          background: #fff;
        }
        .box {
          width: calc((100% - 40px) / 3);
          max-width: 300px;
          aspect-ratio: 1 / 1;
          border-radius: 50%;
          background: #fff;
          cursor: pointer;
          position: relative;
          .newsInfo {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            span {
              display: block;
              margin: 0 auto;
              &.noticeSj {
                max-width: 80%;
                max-height: 58px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-size: 24px;
                font-weight: 700;
              }
              &.noticeCl {
                margin: 20px 0;
                font-size: 16px;
              }
              &.date {
                font-size: 16px;
                color: #6a6a6a;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .history,
    .products,
    .portfolio {
      margin: 70px 0;
      padding: 0 20px;
    }
    .news {
      margin: 70px 0;
      padding: 20px;
    }
  }
  @media screen and (max-width: 1050px) {
    .info {
      font-size: 24px;
      margin-bottom: 40px;
    }
    .history,
    .products,
    .portfolio,
    .news {
      margin: 70px 0;
    }
    .history .content {
      .contentInfo {
        font-size: 14px;
        margin-bottom: 36px;
      }
      .wrapper {
        gap: 10px;
        .box {
          width: calc((100% - 30px) / 4);
          img {
            width: 100%;
            height: 200px;
            object-fit: contain;
            background-size: contain;
            background-position: 50% 50%;
          }
          span {
            font-size: 14px;
          }
        }
      }
    }
    .products .wrapper {
      .box {
        width: 50%;
        &:nth-child(n + 3) {
          display: none;
        }
        .prdInfo {
          font-size: 14px;
          span:first-child {
            font-size: 16px;
            height: min-content;
          }
        }
      }
    }
    .portfolio {
      .info {
        margin-bottom: 40px;
        .more {
          margin-top: 40px;
        }
      }
      .content {
        height: 300px;
        .box {
          height: 100%;
          &:nth-child(odd),
          &:nth-child(even) {
            padding: 0;
          }
          .view {
            height: calc(100% - 30px);
          }
          .contentInfo {
            font-size: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-top: 15px;
            text-align: center;
          }
        }
      }
    }
    .news {
      position: relative;
      .content {
        .more {
          position: absolute;
          right: 20px;
          top: 20px;
          width: min-content;
          button {
            width: auto;
            font-size: 24px;
            span {
              display: none;
            }
          }
        }
        .newsContent {
          width: 100%;
          .line {
            width: 10px;
          }
          .box {
            width: calc((100% - 20px) / 3);
            .newsInfo span {
              &.noticeSj {
                font-size: 14px;
                max-height: none;
              }
              &.noticeCl {
                font-size: 12px;
                margin: 5px 0;
              }
              &.date {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 150px;
    .history .content .wrapper .box img {
      height: 100px;
    }
    .products .wrapper {
      gap: 30px;
      .box {
        width: 100%;
        height: 350px;
        &:nth-child(n + 3) {
          display: none;
        }
      }
    }
    .portfolio {
      .info .more {
        font-size: 14px;
        button {
          .line {
            width: 50px;
          }
        }
      }
      .content {
        gap: 10px;
        .box {
          width: calc(50% - 5px);
          &:nth-child(n + 3) {
            display: none;
          }
        }
      }
    }

    .news .content .newsContent .box .newsInfo span {
      &.date,
      &.noticeCl {
        font-size: 10px;
      }
      &.noticeSj {
        width: 100%;
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
      }
    }
  }
`;

export const SimpleBannerContainer = styled.div`
  width: 100%;
  height: 470px;
  margin-top: 120px;
  .banners {
    width: 100%;
    height: 100%;
    .bannerSlide {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }
  }
`;

export const SimpleProductsContainer = styled.div`
  img {
    user-select: none;
  }
  main#contProductsList {
    margin-top: 0;
    font-family: "Pretendard";
    .subTop_bnr_01 {
      display: none;
    }
    article.boundary {
      width: 1400px;
    }
    #secBrdcrmb {
      display: none;
    }
    #secListTop .list_info {
      padding-bottom: 10px;
      font-size: 18px;
      .fa.fa-bars {
        margin-right: 5px;
      }
      strong {
        color: #000;
      }
    }
    .categorys {
      background: #faf8f7;
      .categoryInfo {
        padding: 20px;
        margin-bottom: 0;
        .toggleButton {
          background: #fff;
          padding: 5px;
        }
      }
      ul {
        border-top: 1px solid rgb(226 226 226);
        box-shadow: none;
        color: #9f9f9f;
      }
    }
    #secList {
      .col_3 {
        position: relative;
        height: 400px;
        border: 1px solid #ddd;
        .img {
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            filter: grayscale(50%);
            opacity: 0.5;
            object-fit: cover;
          }
        }
        .img + div {
          position: absolute;
          left: 0;
          top: 50%;
          padding: 0 20px;
          width: 100%;
          p {
            display: block;
            font-size: 28px;
            &:first-child {
              width: 100%;
              margin-bottom: 24px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              white-space: pre-wrap;
              -webkit-box-orient: vertical;
              line-height: normal;
              padding: 0;
            }
            &:last-child {
              font-weight: lighter;
              background: #fff;
              width: 148px;
              text-align: center;
              padding: 8px 0;
            }
          }
        }
        a:hover {
          color: #000;
        }
        &:hover {
          .img {
            background: #000;
            img {
              opacity: 0.7;
              filter: grayscale(0%);
            }
          }
          p {
            transition: 0.3s;
            &:first-child {
              color: #fff;
            }
          }
        }
      }
    }
    ul.pagination {
      li {
        border: none;
        a {
          color: #bfbfbf;
        }
      }
      li.active,
      li:hover {
        background: transparent;
        a {
          color: #333;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    article.boundary {
      padding: 50px 20px;
      margin: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 1050px) {
    article.boundary {
      margin: 0;
      width: 100%;
    }
    main#contProductsList #secListTop .count,
    main#contProductsList .categorys ul li {
      font-size: 16px;
    }
    main#contProductsList #secList {
      gap: 20px;
      .col_3 {
        width: calc(50% - 10px);
        .img + div p:first-child {
          font-size: 20px;
        }
        .img + div p:last-child {
          font-size: 18px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    main#contProductsList {
      margin-bottom: 100px;
      #secList {
        gap: 50px;
        .col_3 {
          width: 100%;
          .img + div p:first-child {
            font-size: 20px;
          }
          .img + div p:last-child {
            font-size: 18px;
          }
        }
      }
    }
  }
`;

export const SimpleProductsView = styled.div`
  img {
    user-select: none;
  }
  main#contProductsView {
    margin: 0 auto;
    font-family: "Pretendard";
    max-width: 1400px;
    .boundary {
      width: 100%;
    }
    .subTop_bnr_01 {
      display: none;
    }
    .prd_img_wrapper > img {
      width: 100%;
    }
  }
  @media screen and (max-width: 1400px) {
    main#contProductsView {
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 767px) {
    main#contProductsView {
      margin-bottom: 100px;
    }
  }
`;

export const SimplePortfolioContainer = styled.div`
  img {
    user-select: none;
  }
  main#contProductsList {
    margin-top: 0;
    font-family: "Pretendard";
    .subTop_bnr_01 {
      display: none;
    }
    article.boundary {
      width: 1400px;
    }
    #secBrdcrmb {
      display: none;
    }
    #secListTop .list_info {
      padding-bottom: 10px;
      font-size: 18px;
      .fa.fa-bars {
        margin-right: 5px;
      }
      strong {
        color: #000;
      }
    }
    ul.pagination {
      li {
        border: none;
        a {
          color: #bfbfbf;
        }
      }
      li.active,
      li:hover {
        background: transparent;
        a {
          color: #333;
        }
      }
    }
  }
`;

export const SimpleCmpnyContainer = styled.div`
  img {
    user-select: none;
  }
  main#contCompany {
    margin: 0;
    .subTop_bnr_01,
    h2,
    #secBrdcrmb {
      display: none;
    }
    h3,
    .company_content,
    .address_btn_wrapper,
    .google_map_sub_wrapper {
      width: 100%;
    }
    h3 {
      text-align: center;
      font-size: 36px;
    }
    #secMap {
      max-width: 1400px;
      margin: auto;
      h3 {
        &::before {
          display: none;
        }
        span {
          padding: 0;
          border: none;
        }
      }
      .addr {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 10px;
        button {
          margin: 0;
          background: #ffc96f;
          color: #000;
          font-weight: 700;
          padding: 5px;
        }
      }
      .company_address_h1 {
        color: #000;
        background: transparent;
        margin-bottom: 0;
        padding: 80px 0;
      }
    }
    .company_address_h1 {
      color: #3878f6;
      background: #e9edf8;
      margin-bottom: 0;
      padding: 80px 0;
      font-size: 48px;
    }
    #secMsg.company_wrapper {
      background: #e9edf8;
      padding-bottom: 80px;
      .company_content {
        max-width: 1400px;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    main#contCompany {
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 1050px) {
    main#contCompany h3 {
      font-size: 32px;
    }
  }
  @media screen and (max-width: 767px) {
    main#contCompany {
      margin-bottom: 100px;
      h3 {
        font-size: 26px;
      }
    }
  }
`;

export const SimpleNoticeContainer = styled.div`
  margin-bottom: 100px;
  main#contNoticeList {
    margin-top: 0;
    font-family: "Pretendard";
    a:hover {
      color: #333;
    }
    .subTop_bnr {
      display: none;
    }
    #secListTop {
      margin: 20px 0;
      .count {
        font-size: 18px;
        i.fa-bars {
          margin-right: 5px;
        }
        strong {
          color: rgba(192, 165, 165, 1);
        }
      }
    }
    h2 {
      display: none;
    }
    .notices {
      border-top: 1px solid #000;
      li {
        width: 100%;
        font-size: 24px;
        border-bottom: 1px solid #989898;
        padding: 30px 10px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        .noticeCl {
          font-weight: 700;
          width: 120px;
          color: #c4c4c4;
        }
        .noticeSj {
          width: calc(100% - 300px);
          overflow: hidden;
          text-overflow: ellipsis;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .date {
          color: #c4c4c4;
          width: 130px;
        }
      }
    }
    ul.pagination {
      li {
        border: none;
        a {
          color: #bfbfbf;
        }
      }
      li.active,
      li:hover {
        background: transparent;
        a {
          color: #333;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    article.boundary {
      padding: 50px 20px;
      margin: 0;
    }
  }
  @media screen and (max-width: 1050px) {
    article.boundary {
      margin: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    main#contNoticeList .notices li {
      font-size: 14px;
      .noticeCl {
        width: 60px;
      }
      .noticeSj {
        width: calc(100% - 160px);
      }
      .date {
        width: 80px;
      }
    }
  }
`;
