import React from "react";
import { EChanggoCardContainer } from "../styled/shop";

const EChanggoCard = () => {
  return (
    <EChanggoCardContainer className="inner">
      <div className="bg">
        <span>E층창고와 함께 해요!</span>
        <span>여기는 설명입력입니다.</span>
      </div>
    </EChanggoCardContainer>
  );
};

export default EChanggoCard;
