import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { Container } from "../styled/mypage";

import CompanyInfo from "./component/companyPage/companyInfo";
import MemberList from "./component/companyPage/memberList";
import moment from "moment";
import InviteModal from "./component/companyPage/inviteModal";
import { toast } from "react-toastify";
import { FaAngleLeft } from "react-icons/fa";

const CompanyPage = () => {
  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const [members, setMembers] = useState([]);
  const [cmpnyNm, setCmpnyNm] = useState("");
  const [todayWeather, setTodayWeather] = useState(null);
  const [moblphon, setMoblphon] = useState("");
  const [searched, setSearched] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [isInvite, setIsInvite] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getCmpnyNm();
    getCmpnyMber();
    getWeather();

    const initKakao = async () => {
      if (!Kakao.isInitialized()) {
        // Kakao.init('b9886faecde9faa67a51166600a9d107'); // 개발 환경에 맞는 API 키 사용
        Kakao.init("4970bf08e349ccf4b295296035944317"); // 운영 환경에 맞는 API 키 사용
      }
    };

    initKakao();
  }, []);

  const getCmpnyNm = async () => {
    const url = "/api/cmpnyNmLoad";
    const body = { cmpnyNo: user.cmpnyNo };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      setCmpnyNm(res.data.cmpnyNm);
    }
  };

  const getCmpnyMber = async (page = 0) => {
    const url = "/api/cmpnyMbers";
    const body = { cmpnyNo: user.cmpnyNo, size: 10, page };
    try {
      const res = await axios.post(url, body);
      if (res.data) {
        console.log(res);
        setMembers(res.data.content);
        setTotal(res.data.totalElements);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendKakaoMessage = () => {
    window.Kakao.Share.sendCustom({
      templateId: 89755,
      templateArgs: {
        company: cmpnyNm,
        unique: user.cmpnyNo,
        name: user.name,
      },
    });
  };

  const getWeather = async () => {
    const url = "/api/weather";
    const today = moment().format("YYYY-MM-DD");
    const req = {
      startDt: today,
      endDt: today,
    };
    const res = await axios.post(url, req);
    if (res.status === 200) {
      setTodayWeather(res.data[0]);
    }
  };

  const handleSearch = async () => {
    const phoneRegex = /^(010\d{8}|011\d{7})$/;

    if (!phoneRegex.test(moblphon)) {
      toast.error("전화번호 양식이 일치하지 않습니다.");
      return;
    }

    const url = "/api/mberList";
    const body = {
      moblphonNo: moblphon,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      console.log(res);
      setSearched(res.data.content);
      setIsSearched(true);
    }
  };

  const handleInvite = async (mberNo) => {
    if (members.some((user) => parseInt(user.mberNo) === parseInt(mberNo))) {
      toast.error("이미 가입된 멤버입니다.");
      return;
    }

    const url = "/api/notification";
    const body = {
      gbn: "I",
      invite: {
        gbn: "C",
        mberNoList: [mberNo],
        orgNo: user.cmpnyNo,
      },
    };
    const res = await axios.post(url, body);

    if (res.data.body.success) {
      toast.success("초대 알림이 발송되었습니다.");
      setIsInvite(false);
    }
  };

  const editWorkStatus = async (item) => {
    const updatedStatus = !item.isWorking;

    try {
      const res = await axios.put("/api/cmpnyMber", {
        id: item.id,
        cmpnyNo: item.cmpnyNo,
        mberNo: item.mberNo,
        mberNm: item.mberNm,
        mberTel: item.mberTel,
        auth: item.auth,
        isWorking: updatedStatus,
        isDeleted: item.isDeleted,
        userId: item.userId,
      });

      if (res.status === 200) {
        toast.success("근태 상태가 업데이트 되었습니다.");
        await getCmpnyMber();
      } else {
        console.error(res);
      }
    } catch (error) {
      console.error("서버 요청 중 오류 발생:", error);
    }
  };

  const changePages = (pages) => {
    const newPage = pages - 1;

    setPage(newPage);
    getCmpnyMber(newPage);
  };

  return (
    <Container className={setting.mode}>
      {isInvite && (
        <InviteModal
          setMoblphon={setMoblphon}
          moblphon={moblphon}
          handleSearch={handleSearch}
          isSearched={isSearched}
          searched={searched}
          sendKakaoMessage={sendKakaoMessage}
          setIsInvite={setIsInvite}
          handleInvite={handleInvite}
        />
      )}
      <div className="content">
        <CompanyInfo cmpnyNm={cmpnyNm} todayWeather={todayWeather} />
        <MemberList
          members={members}
          editWorkStatus={editWorkStatus}
          page={page}
          total={total}
          changePages={changePages}
          setIsInvite={setIsInvite}
        />
      </div>
    </Container>
  );
};

export default CompanyPage;
