import styled from "styled-components";

export const CompanyInfoContainer = styled.div`
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  margin-bottom: 100px;
  > button {
    font-size: 30px;
    border: none;
    background: transparent;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 40px;
      font-weight: 700;
    }
    .overview {
      font-size: 20px;
      text-align: right;
      span {
      }
      .weather {
        display: flex;
        gap: 10px;
        justify-content: end;
        margin-top: 30px;
        align-items: center;
        .icon svg {
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    h3 {
      font-size: 20px;
    }
    .overview {
      font-size: 12px;
      .weather {
        margin-top: 10px;
      }
    }
  }
`;

export const MemberListContainer = styled.div`
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  &.dark {
    .viewer {
      background: rgb(44, 45, 48);
      ul li {
        color: rgba(255, 255, 255, 0.7);
        &.label {
          color: #fff;
        }
      }
    }
    .members ul li.label .work i {
      background: rgb(12, 12, 12);
    }
    .pagination li {
      background: rgb(44, 45, 48);
    }
  }
  .viewer {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    padding: 30px;
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    .add {
      font-size: 26px;
      height: 26px;
      border: none;
      background: transparent;
    }
  }
  .members {
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5);
    ul {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        text-align: center;
        &.label {
          font-weight: 700;
          .work {
            display: flex;
            justify-content: center;
            gap: 5px;
            i {
              width: 33px;
              height: 33px;
              font-style: normal;
              display: block;
              background: #ccc;
              border-radius: 50%;
              position: relative;
              cursor: pointer;
              &::after {
                content: "근무 중인 직원에게만 알림이 전송됩니다.";
                position: absolute;
                bottom: 110%;
                font-size: 12px;
                right: 50%;
                transform: translateX(30%);
                opacity: 0;
                transition: 0.3s;
                width: 200px;
                padding: 3px;
                border-radius: 5px;
                font-weight: 300;
              }
              &:hover::after {
                background: rgba(0, 0, 0, 0.5);
                color: #fff;
                opacity: 1;
              }
            }
          }
        }
        &:not(.label) {
          .dot {
            div {
              margin: 0 auto;
              width: 10px;
              height: 10px;
              background: #6648ff;
              border-radius: 50%;
            }
            &::after {
              content: "";
              position: absolute;
              width: 1px;
              height: 70px;
              top: 50%;
              left: 50%;
              transform: translateX(-50%);
              background: #5c5c5c;
            }
          }
          &:last-child .dot::after {
            display: none;
          }
        }
        .dot {
          width: 10%;
          position: relative;
        }
        .mberNm {
          width: 20%;
        }
        .username {
          width: 20%;
        }
        .moblphonNo {
          width: 30%;
        }
        .work {
          width: 20%;
          .tabs {
            width: 36px;
            height: 20px;
            border-radius: 20px;
            position: relative;
            margin: auto;
            cursor: pointer;
            .glider {
              display: block;
              width: 16px;
              height: 16px;
              position: absolute;
              top: 50%;
              left: 2px;
              background: #fff;
              cursor: pointer;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .pagination {
    gap: 20px;
    margin-top: 100px;
    li {
      background: rgba(255, 255, 255, 0.5);
      border: none;
      width: 40px;
      height: 40px;
      &:hover,
      &.active {
        background: #fff;
        a {
          color: #000;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .viewer {
      padding: 20px;
      .info {
        margin-bottom: 20px;
      }
      .members {
        font-size: 12px;
        ul li {
          height: 50px;
          .work {
            width: 25%;
            i {
              width: 16px;
              height: 16px;
              font-size: 13px;
            }
          }
          &.label .work i::after {
            right: 0;
            transform: translateX(13%);
            font-size: 12px;
          }
          &:first-child {
            font-size: 14px;
          }
          .dot {
            display: none;
          }
        }
      }
    }
  }
  .pagination {
    gap: 10px;
    margin-top: 50px;
    li {
      width: 30px;
      height: 30px;
    }
  }
`;

export const SearchFormContainer = styled.div`
  width: 450px;
  position: relative;
  height: 50px;
  input {
    font-size: 20px;
    width: 100%;
    height: 100%;
    padding: 0 60px 0 20px;
    border: none;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    &:focus {
      border: none !important;
      box-shadow: none;
    }
  }
  button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 24px;
    width: 50px;
    height: 50px;
    display: flex;
    border: none;
    background: transparent;
    color: rgba(0, 0, 0, 0.7);
    svg {
      display: block;
      margin: auto;
    }
  }
  @media screen and (max-width: 767px) {
    width: calc(100% - 40px);
    height: 30px;
    input {
      font-size: 12px;
      padding: 0px 40px 0px 10px;
    }
    button {
      width: 30px;
      height: 30px;
    }
  }
`;

export const CompanyInviteContainer = styled.div`
  &.dark {
    div.modal .search input[type="text"] + button {
      background: #3d3d3d;
    }
    div.modal .searched ul li {
      color: #fff;
    }
  }
  div.modal {
    width: 482px;
    height: 478px;
  }
  .closeButton {
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 24px;
    button {
      border: none;
      background: transparent;
    }
    svg {
      display: block;
    }
  }
  .search {
    font-size: 20px;
    span {
      margin: 25px 0;
      display: block;
      letter-spacing: -1px;
    }
    label {
      display: flex;
    }
    input[type="text"] {
      width: calc(100% - 130px);
      height: 55px;
      margin-right: 10px;
      padding: 0 20px;
      border-radius: 10px;
      border: 1px solid #a9a9a9;
      &:focus {
        border: 1px solid #a9a9a9 !important;
        box-shadow: none;
      }
    }
    input[type="text"] + button {
      width: 120px;
      height: 55px;
      border-radius: 10px;
      background: #e5e5e5;
      border: 1px solid transparent;
      font-size: 24px;
      svg {
        display: block;
        margin: auto;
      }
    }
  }
  .searched {
    height: calc(100% - 191px);
    margin-top: 25px;
    ul {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
      }
      &::-webkit-scrollbar-track {
        background-color: #d9d9d9;
        border-radius: 10px;
      }
      li {
        text-align: center;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 300;
        color: #7d7d7d;
        height: 50px;
        line-height: 50px;
        display: block;
        > div {
          display: inline-block;
        }
        &:first-child {
          font-weight: 500;
          color: #000;
        }
        .mberNm {
          width: 60px;
        }
        .moblphonNo {
          width: calc(100% - 150px);
        }
        .invite {
          width: 70px;
          button {
            background: #0b57d0;
            color: #fff;
            border-radius: 20px;
            font-size: 14px;
            width: 50px;
            height: 30px;
            border: none;
          }
        }
      }
    }
    .msg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 20px;
      span {
        font-size: 16px;
        color: #7d7d7d;
        text-align: center;
      }
      button {
        font-size: 18px;
        background: #0b57d0;
        border: none;
        padding: 10px 30px;
        color: #fff;
        border-radius: 30px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    div.modal {
      width: 255px;
      height: 300px;
      .modalContent {
        padding: 20px;
        .label {
          font-size: 20px;
          height: 25px;
        }
      }
      .closeButton {
        top: 20px;
        right: 20px;
      }
      .search {
        font-size: 14px;
        span {
          margin: 15px 0;
        }
        input[type="text"] {
          padding: 0 10px;
          width: calc(100% - 60px);
          height: 32px;
        }
        input[type="text"] + button {
          width: 50px;
          height: 32px;
        }
      }
      .searched {
        height: calc(100% - 120px);
        margin-top: 15px;
        .msg {
          span {
            font-size: 14px;
          }
          button {
            font-size: 14px;
            padding: 7px 20px;
          }
        }
        ul li {
          font-size: 12px;
          height: 30px;
          line-height: 30px;
          .mberNm {
            width: 40px;
          }
          .moblphonNo {
            width: calc(100% - 100px);
          }
          .invite {
            width: 50px;
            button {
              width: 40px;
              height: 20px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
`;
