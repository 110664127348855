import React, { useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import { useMediaQuery } from "react-responsive";

import { IoIosSearch } from "react-icons/io";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { CiMap, CiTextAlignLeft } from "react-icons/ci";
import { GoCheckbox } from "react-icons/go";

import { IsDetails } from "../../styled/calendarEntry";

import EventListItem from "../../common/eventListItem";

const ScheduleFormDetails = ({
  formData,
  setFormData,
  changeInput,
  openPostCode,
  saveToLocalStorage,
  // isRecurring
}) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [isDetails, setIsDetails] = useState(false);
  const todoInputRefs = useRef([]);
  const [todoAdded, setTodoAdded] = useState(false);

  useEffect(() => {
    if (todoAdded) {
      const lastTodoIndex = formData.todo.length - 1;
      const lastTodoRef = todoInputRefs.current[lastTodoIndex];
      if (lastTodoRef && typeof lastTodoRef.focus === "function") {
        lastTodoRef.focus();
      }
      setTodoAdded(false);
    }
  }, [formData.todo, todoAdded]);

  const repeat = {
    선택안함: "notRepeat",
    매일: "DAILY",
    매주: "WEEKLY",
    매월: "MONTHLY",
    매년: "YEARLY",
  };

  const addTodos = () => {
    const newId = uuid();
    const newTodo = {
      todoNo: null,
      tempId: newId,
      title: "",
      completeYn: "N",
      delYn: "N",
    };

    setFormData((data) => {
      const updated = { ...data, todo: [...data.todo, newTodo] };
      saveToLocalStorage(updated);
      return updated;
    });

    setTodoAdded(true);
    todoInputRefs.current = todoInputRefs.current.concat(newId);
  };

  const changeTodo = (e) => {
    const { name, value } = e.target;

    setFormData((data) => {
      const updated = {
        ...data,
        todo: data.todo.map((item) => (item.tempId === name ? { ...item, title: value } : item)),
      };
      saveToLocalStorage(updated);
      return updated;
    });
  };

  const deleteTodo = (id) => {
    setFormData((data) => {
      const updatedTodos = data.todo.filter((item) => item.tempId !== id);
      saveToLocalStorage({ ...data, todo: updatedTodos });

      const deletedIndex = data.todo.findIndex((item) => item.tempId === id);
      todoInputRefs.current.splice(deletedIndex, 1);

      return { ...data, todo: updatedTodos };
    });
  };

  // const changeRecur = (e) => {
  //   const { name, value } = e.target;

  //   if (isRecurring && name === "freq") {
  //     toast.error("반복 일정은 반복 수정이 불가합니다.");
  //     return;
  //   }

  //   setFormData((data) => ({
  //     ...data,
  //     recur: {
  //       ...data.recur,
  //       [name]: value,
  //     },
  //   }));
  // };

  return (
    <>
      <IsDetails>
        {isDetails ? (
          <span
            onClick={() => {
              setIsDetails(false);
            }}>
            접기
            <SlArrowUp />
          </span>
        ) : (
          <span
            onClick={() => {
              setIsDetails(true);
            }}>
            상세설정
            <SlArrowDown />
          </span>
        )}
      </IsDetails>
      {isDetails && (
        <ul className={`font_16 ${isDetails ? "open" : ""}`}>
          <EventListItem icon={<CiMap />} title="위치" addClass="addr">
            <input
              type="text"
              value={formData.place}
              readOnly
              placeholder="주소를 추가해주세요"
              onClick={openPostCode}
            />
            <div>
              <input
                type="text"
                name="placeDetail"
                placeholder="상세주소"
                value={formData.placeDetail}
                onChange={(e) => changeInput(e)}
              />
              <button onClick={openPostCode}>
                <IoIosSearch />
              </button>
            </div>
          </EventListItem>
          {/*   {formData.gbn !== "P" && (
            <EventListItem icon={<FaRegEye />} title="공개여부" addClass="public">
              <div className="radio-input" style={{ width: isMobile ? "100%" : "50%" }}>
                <label>
                  <input
                    value="Y"
                    name="publicYn"
                    type="radio"
                    checked={formData.publicYn === "Y"}
                    onChange={(e) => setFormData({ ...formData, publicYn: e.target.value })}
                  />
                  <span>전체공개</span>
                </label>
                <label>
                  <input
                    value="N"
                    name="publicYn"
                    type="radio"
                    checked={formData.publicYn === "N"}
                    onChange={(e) => setFormData({ ...formData, publicYn: e.target.value })}
                  />
                  <span>참여자공개</span>
                </label>
                <span class="selection"></span>
              </div>
            </EventListItem>
          )} */}
          {/* 일정반복 */}
          {/* <EventListItem icon={<PiRepeatOnceFill />} title="반복" addClass="repeat">
            <select
              name="freq"
              value={formData.recur.freq}
              onChange={(e) => changeRecur(e)}
              style={{ width: isMobile ? "100%" : "50%" }}>
              {Object.entries(repeat).map(([label, value]) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </EventListItem>
          {formData.recur.freq !== "notRepeat" && formData.recur.freq && (
            <EventListItem icon={<BsCalendarX />} title="종료날짜" addClass="until">
              <input
                type="date"
                name="until"
                style={{ width: isMobile ? "100%" : "50%" }}
                onChange={(e) => changeRecur(e)}
              />
            </EventListItem>
          )} */}
          <EventListItem icon={<CiTextAlignLeft />} title="메모" addClass="">
            <textarea
              placeholder="설명을 추가해주세요"
              value={formData.memo}
              name="memo"
              onChange={(e) => changeInput(e)}></textarea>
          </EventListItem>
          <EventListItem icon={<GoCheckbox />} title="할일" addClass={"todoList"}>
            <div className="todos">
              <button className="todoAddBtn" onClick={addTodos}>
                할일 추가
              </button>
              {formData.todo
                .filter((item) => item.delYn === "N")
                .map((item, idx) => (
                  <div className="todoItem">
                    <input
                      type="text"
                      value={item.title}
                      name={item.tempId}
                      onChange={(e) => changeTodo(e)}
                      onKeyDown={(e) => handleEnterKey(e, addTodos)}
                      ref={(el) => (todoInputRefs.current[idx] = el)}
                    />
                    <button onClick={() => deleteTodo(item.tempId)}>삭제</button>
                  </div>
                ))}
            </div>
          </EventListItem>
        </ul>
      )}
    </>
  );
};

export default ScheduleFormDetails;
