import React, { useEffect, useState } from "react";
import MypageBanner from "../mypageBanner";
import HomepageSubMenu from "./homepageSubMenu";
import HomepageForm from "./component/homepageForm";
import CompanyAddForm from "./component/companyAddForm";
import { useLocation } from "react-router";
import { toast } from "react-toastify";

export default function Hompage () {
  const [activeMenu, setActiveMenu] = useState("/homepage");

  const handleChangeComponent = (link) => {
    setActiveMenu(link);
  };

  const location = useLocation();
  const saveMessage = location.state?.saveMessage;

  useEffect(() => {
    if(saveMessage){
      toast.success('홈페이지가 생성되었습니다.');
    }
  },[saveMessage]);

  const components = [
    { link: "/homepage", component: <HomepageForm /> },
    { link: "/companyAdd", component: <CompanyAddForm /> },
  ];

  return (
    <div>
      <MypageBanner />
      <div className="inner_content">
        <>
          <HomepageSubMenu
            activeMenu={activeMenu}
            handleChangeComponent={handleChangeComponent}
          />
          {components.map((item) =>
            item.link === activeMenu ? (
              <React.Fragment key={item.link}>{item.component}</React.Fragment>
            ) : null
          )}
        </>
      </div>
    </div>
  );
}