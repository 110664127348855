import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import HamburgerMenu from "./hamburgerMenu";
import SearchEvent from "./searchEvent";

import { CalendarContainer } from "../styled/scheduler";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FiHome, FiSearch } from "react-icons/fi";

import { hexToRgb } from "../function/common";
import { holidayList } from "../function/dateInfo";

export default function Calendar({
  data,
  handleDateClick,
  calendarRef,
  handleEventClick,
  onDate,
  toggleAgendaContent,
  handleEventDrag,
  setIsBurgerOpen,
  isBurgerOpen,
  displayDateInfo,
  clickEvent,
  isSearch,
  setIsSearch,
  searchTxt,
  setSearchTxt,
}) {
  const { mySchedSet, isDarkMode } = useSelector((state) => state?.scheduler);
  const isByType = mySchedSet.viewMode === "C";
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const [events, setEvents] = useState(data);
  const [currentHolidays, setCurrentHolidays] = useState([]);
  const user = useSelector((state) => state?.user?.data[0]);

  const getColorByGbn = (gbn) => {
    switch (gbn) {
      case "P":
        return "139859";
      case "C":
        return "0074ca";
      case "G":
        return "f06f02";
      case "O":
        return "ED5583";
      default:
        return "cccccc";
    }
  };

  const tallyEvents = (data) => {
    const result = [];

    data.forEach((item) => {
      const { startDt, endDt, gbn } = item;
      if (item.dday !== "Y") {
        const startDate = startDt.split(" ")[0];
        const endDate = endDt.split(" ")[0];

        const duration = moment(endDate).diff(moment(startDate), "days");

        for (let i = 0; i <= duration; i++) {
          const current = moment(startDate).add(i, "days").format("YYYY-MM-DD");
          const existingEvent = result.some(
            (event) => event.start === current && event.gbn === gbn,
          );

          if (existingEvent) {
            const eventIndex = result.findIndex(
              (event) => event.start === current && event.gbn === gbn,
            );
            result[eventIndex].title++;
          } else {
            const color = `rgba(${hexToRgb(getColorByGbn(gbn))}, 0.8)`;
            result.push({
              start: current,
              gbn: gbn,
              title: 1,
              backgroundColor: color,
              borderColor: color,
            });
          }
        }
      }
    });

    return result;
  };

  useEffect(() => {
    let schedules = data.map((item) =>
      item.dday === "Y"
        ? {
            ...item,
            display: "block",
            backgroundColor: "#efefef",
            textColor: "#000",
            borderColor: "#969696",
          }
        : item,
    );

    if (isByType) {
      schedules = tallyEvents(data);
    }

    const combinedSchedules =
      isMobile && !isByType ? [...schedules, ...currentHolidays] : schedules;

    setEvents(combinedSchedules);

    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.removeAllEvents();
      calendarApi.addEventSource(combinedSchedules);
    }
  }, [isMobile, data, currentHolidays, mySchedSet]);

  // 현재 보고 있는 달력
  const handleDatesSet = (info) => {
    setCurrentHolidays(isHolidays(info.startStr, info.endStr, holidayList));
  };

  const isHolidays = (startStr, endStr, holidays) => {
    const startDate = moment(startStr);
    const endDate = moment(endStr);

    const filtered = holidays.flatMap((holiday) => {
      let result = [];

      // 고정된 공휴일
      if (holiday.month && holiday.day) {
        const prevYear = moment([startDate.year() - 1, holiday.month - 1, holiday.day]);
        const currentYear = moment([startDate.year(), holiday.month - 1, holiday.day]);
        const nextYear = moment([startDate.year() + 1, holiday.month - 1, holiday.day]);

        if (prevYear.isSameOrAfter(startDate) && prevYear.isBefore(endDate)) {
          result.push({
            ...holiday,
            start: prevYear.format("YYYY-MM-DD 00:00:00"),
            end: prevYear.format("YYYY-MM-DD 23:59:59"),
          });
        }
        if (currentYear.isSameOrAfter(startDate) && currentYear.isBefore(endDate)) {
          result.push({
            ...holiday,
            start: currentYear.format("YYYY-MM-DD 00:00:00"),
            end: currentYear.format("YYYY-MM-DD 23:59:59"),
          });
        }
        if (nextYear.isSameOrAfter(startDate) && nextYear.isBefore(endDate)) {
          result.push({
            ...holiday,
            start: nextYear.format("YYYY-MM-DD 00:00:00"),
            end: nextYear.format("YYYY-MM-DD 23:59:59"),
          });
        }
      }

      // 매년 변하는 공휴일
      if (holiday.startDt && holiday.endDt) {
        const holidayStart = moment(holiday.startDt);
        const holidayEnd = moment(holiday.endDt);

        if (holidayStart.isSameOrAfter(startDate) || holidayEnd.isSameOrBefore(endDate)) {
          result.push({
            ...holiday,
            start: holidayStart.format("YYYY-MM-DD 00:00:00"),
            end: holidayEnd.format("YYYY-MM-DD 23:59:59"),
          });
        }
      }

      return result;
    });

    return filtered.map((item) => ({
      id: `holiday${item.start}`,
      title: item.name,
      start: item.start,
      end: item.end,
      display: "block",
      editable: false,
      backgroundColor: item.isPublicHoliday ? "#ca0000" : "#999",
      borderColor: item.isPublicHoliday ? "#ca0000" : "#999",
    }));
  };

  const clickDate = (info) => {
    handleDateClick(info.date);
    toggleAgendaContent(true);
  };

  const customEventContent = (eventInfo) => {
    const { event } = eventInfo;

    const color = {
      borderColor: event.borderColor,
    };
    const imgStyle = {
      width: 15,
      height: 15,
      marginRight: 3,
    };

    const start = moment(event.start).format("YYYY-MM-DD");
    const end = moment(event.end).format("YYYY-MM-DD");
    const isMultiDayEvent = start !== end;

    if (isMultiDayEvent || event.display === "block") {
      return (
        <>
          <div class="fc-event-main-frame">
            <div class="fc-event-time"></div>
            <div class="fc-event-title-container">
              <div class="fc-event-title fc-sticky">
                {event.extendedProps.icon && (
                  <img
                    src={`/img/scheduler/scheduler_icon_${event.extendedProps.icon}`}
                    style={imgStyle}
                  />
                )}
                {eventInfo.event.title}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div class="fc-daygrid-event-dot" style={color}></div>
          <div class="fc-event-time"></div>
          <div class="fc-event-title">
            {event.extendedProps.icon && (
              <img
                src={`/img/scheduler/scheduler_icon_${event.extendedProps.icon}`}
                style={imgStyle}
              />
            )}
            {eventInfo.event.title}
          </div>
        </>
      );
    }
  };

  const customEventOrder = (a, b) => {
    const aIsHoliday = a.id.includes("holiday");
    const bIsHoliday = b.id.includes("holiday");

    if (aIsHoliday && !bIsHoliday) {
      return -1;
    } else if (!aIsHoliday && bIsHoliday) {
      return 1;
    }

    const startDiff = a.start - b.start;
    if (startDiff !== 0) {
      return startDiff;
    }

    const durationDiff = b.end - b.start - (a.end - a.start);
    if (durationDiff !== 0) {
      return durationDiff;
    }

    return a.title.localeCompare(b.title);
  };

  const handleSearchClick = () => {
    if (isSearch) {
      setSearchTxt("");
    }
    setIsSearch(!isSearch);
  };

  return (
    <CalendarContainer className={isDarkMode}>
      <div className="mainCalendar" style={{ padding: isSearch ? "4em 0 0" : 0 }}>
        <div className={`searchBtn ${isSearch && isMobile ? "active" : ""}`}>
          <button onClick={handleSearchClick}>{isSearch ? <FiHome /> : <FiSearch />}</button>
        </div>
        {!isSearch && (
          <div className="calendarBtnArea">
            <button
              onClick={() => clickDate({ date: moment().format("YYYY-MM-DD") })}
              className="todayBtn">
              오늘
            </button>
            <div className="moveMonth">
              <button
                onClick={() =>
                  handleDateClick(moment(onDate).subtract(1, "months").format("YYYY-MM-01"))
                }>
                <BsChevronLeft />
              </button>
              <button
                onClick={() =>
                  handleDateClick(moment(onDate).add(1, "months").format("YYYY-MM-01"))
                }>
                <BsChevronRight />
              </button>
            </div>
          </div>
        )}
        {isSearch ? (
          <SearchEvent
            data={data}
            clickEvent={clickEvent}
            searchTxt={searchTxt}
            setSearchTxt={setSearchTxt}
            handleDateClick={handleDateClick}
            onDate={onDate}
          />
        ) : (
          <div className={`calendarView-${mySchedSet.viewMode}`}>
            <FullCalendar
              ref={calendarRef}
              initialDate={onDate}
              initialView="dayGridMonth"
              plugins={[dayGridPlugin, interactionPlugin]}
              locale={"ko"}
              contentHeight={"auto"}
              droppable={true}
              datesSet={handleDatesSet}
              events={events}
              eventDurationEditable={false}
              eventStartEditable={true}
              eventClick={(info) => handleEventClick(info.el)}
              dateClick={(info) => clickDate(info)}
              eventDrop={handleEventDrag}
              dayMaxEvents={3}
              moreLinkContent={(info) => `+${info.num}`}
              dragScroll={true}
              eventClassNames={(info) =>
                `schedule-${info.event.id}${
                  info.event.extendedProps.completeYn === "Y" ? " eventComplete" : ""
                }`
              }
              dayCellContent={(info) => displayDateInfo(info)}
              eventOrder={customEventOrder}
              eventContent={(info) => customEventContent(info)}
              eventOverlap={false}
              eventOrderStrict={true}
              slotEventOverlap={false}
            />
          </div>
        )}
      </div>
      {isTablet && !isSearch && (
        <HamburgerMenu
          onDate={moment().format("YYYY-MM-DD hh:mm:00")}
          isBurgerOpen={isBurgerOpen}
          setIsBurgerOpen={setIsBurgerOpen}
        />
      )}
    </CalendarContainer>
  );
}
