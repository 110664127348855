import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

// styled import
import { GroupTitle, NotData } from "./component/styled/common";
import {
  GroupInner,
  GroupListContainer,
  GroupSearchBox,
  GroupSection,
} from "./component/styled/common";

// page import
import GroupList from "./component/common/groupList";
import GroupVisualSwiper from "./component/common/groupVisualSwiper";

// use globally redux
import { handleLogin } from "../../store/user";
import { toast } from "react-toastify";
import axios from "axios";
import Pagination from "react-js-pagination";
import { getSetting } from "../../store/scheduler";

const Group = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const exitMessage = location.state?.exitMessage;
  const [grp, setGrp] = useState([]);
  const [totalGrp, setTotalGrp] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [mberHeight, setMberHeight] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [text, setText] = useState("");
  const isInner = useMediaQuery({ query: "(max-width:1400px)" });
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      dispatch(handleLogin(user));
      sessionStorage.setItem("user", JSON.stringify(user));
      localStorage.removeItem("user");
      getSchedulerSetting(user.mberNo);
    }

    if (exitMessage) {
      toast.success(exitMessage);
    }

    getGrp(defaultDataCond());
  }, []);

  const getSchedulerSetting = async (mberNo) => {
    const url = "/api/toktokSettingList";
    const req = {
      mberNo,
    };
    const res = await axios.post(url, req);
    if (res.request.status === 200) {
      dispatch(getSetting(res.data));
    }
  };

  // 모임 조회
  const getGrp = async (cond) => {
    const url = "/api/groupList";
    const req = cond;
    const res = await axios.post(url, req);
    if (res.status === 200) {
      setGrp(res.data.content);
      setTotalGrp(res.data.totalElements);
    }
  };

  // 기본 조건
  const defaultDataCond = () => {
    const offset = Math.floor(Math.random() * 14);

    return {
      offset,
      displayYn: "Y",
      delYn: "N",
      pageNumber: 0,
      pageSize: 8,
      paged: true,
    };
  };

  // 검색, 페이지
  const getSearchCond = (number = 1) => {
    const pageSize = 8;
    const pageNumber = number - 1;

    return {
      groupName: text,
      displayYn: "Y",
      delYn: "N",
      offset: pageNumber * pageSize,
      pageNumber: pageNumber,
      pageSize,
      paged: true,
    };
  };

  // 페이지네이션 변경
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getGrp(getSearchCond(pageNumber));
  };

  const onSubmit = () => {
    setIsSearch(true);
    if (!text) {
      setIsSearch(false);
    }
    setCurrentPage(1);
    getGrp(getSearchCond());
  };

  // 더보기
  const moreData = () => {
    setIsSearch(false);
    getGrp({
      delYn: "N",
      displayYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 1,
      paged: false,
    });
  };

  // 모임 리스트 최소 높이
  const calculateMinHeight = () => {
    if (isTablet) {
      return mberHeight * 4;
    }
    if (isInner) {
      return mberHeight * 2;
    }
    return 540;
  };

  return (
    <>
      {!grp ? (
        <></>
      ) : (
        <GroupSection>
          <GroupTitle className="font_48 font600 font_color_black text_center">
            전체 모임
          </GroupTitle>
          <GroupVisualSwiper />
          <GroupInner>
            <GroupSearchBox>
              <div className="search_box" style={{ marginTop: 20 }}>
                <div className="basic">
                  <input
                    type="text"
                    id="keyword"
                    name="keyword"
                    placeholder="모임명을 입력하세요."
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    onKeyDown={(e) => handleEnterKey(e, onSubmit)}
                  />
                  <button onClick={onSubmit}>
                    <span className="font_20 font300 font_color_white">검색</span>
                  </button>
                </div>
              </div>
            </GroupSearchBox>
            <GroupListContainer style={{ minHeight: calculateMinHeight() }}>
              {isSearch && grp?.length < 1 ? (
                <NotData>
                  <span className="font_22 text_center pdb_70 pdt_70">
                    검색 결과가 존재하지 않습니다.
                  </span>
                </NotData>
              ) : (
                <GroupList items={grp} setMberHeight={setMberHeight} />
              )}
            </GroupListContainer>
            {isSearch && grp && (
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={8}
                totalItemsCount={totalGrp}
                pageRangeDisplayed={5}
                prevPageText={"‹"}
                nextPageText={"›"}
                onChange={onPageChange}
                style={{ marginTop: "30px" }}
              />
            )}
            {!isSearch && grp?.length < 9 && (
              <div
                className="search_box"
                style={{
                  textAlign: "center",
                }}>
                <button onClick={moreData}>
                  <span className="font_20 font300 font_color_white">더보기</span>
                </button>
              </div>
            )}
          </GroupInner>
        </GroupSection>
      )}
    </>
  );
};

export default Group;
