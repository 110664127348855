import React from "react";
import { FiMoreVertical, FiSend } from "react-icons/fi";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";

import GroupFeedSwiper from "../../../common/groupFeedSwiper";
import GroupDetailFeedComment from "./groupDetailFeedComment";

const GroupDetailFeedItem = ({
  feed,
  setFeedList,
  setIsBigImages,
  text,
  setText,
  active,
  setActive,
  handleDeleteModal,
  editFeed,
  onSubmit,
}) => {
  const { id } = useParams();
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();

  // gbnValue 확인
  const isGbnValue = (feed) => {
    const { groupName, gbnValue, gbnValueName } = feed;

    if (!isNaN(id)) {
      if (gbnValue && gbnValueName && !isNaN(gbnValue)) {
        return <span>{gbnValue + gbnValueName}</span>;
      } else {
        return null;
      }
    } else {
      return (
        <>
          <span
            onClick={() => navigate(`/group/${feed.gbn}`)}
            style={{ maxWidth: "40%", cursor: "pointer" }}>
            &#91;{groupName}&#93;
          </span>
          <span style={{ maxWidth: "10%" }}>
            {gbnValue && gbnValueName && !isNaN(gbnValue) ? ` ${gbnValue + gbnValueName}` : ""}
          </span>
        </>
      );
    }
  };

  const isAuth = (feed) => {
    if (feed.auth < 2 || user.mberNo === feed.mberNo) {
      return true;
    }
    return false;
  };

  //  피드 댓글 더보기
  const commentShowMore = (feedNo, state) => {
    setFeedList((feed) =>
      feed.map((item) =>
        item.feedNo === feedNo
          ? { ...item, showComments: state === "fold" ? 3 : item.showComments + 3 }
          : item,
      ),
    );
  };

  //  피드 본문내용 더보기
  const feedShowMore = (feedNo) => {
    setFeedList((feed) =>
      feed.map((item) =>
        item.feedNo === feedNo ? { ...item, showContent: !item.showContent } : item,
      ),
    );
  };

  const handleClickImage = (feed) => {
    const { imgUrl, nickNm } = feed;
    const image = JSON.parse(imgUrl);

    if (!image) return;

    const images = image.map((item) => ({
      ...item,
      path: item,
      createNm: nickNm ? nickNm : "닉네임 미설정",
      createDt: "",
    }));

    setIsBigImages([...images]);
  };

  return (
    <li>
      <div className="feedHeader">
        <div className="writerInfo">
          <img src={feed.mberImgPath ? imgHandler(feed.mberImgPath) : "/img/user.png"} alt="logo" />
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            {feed.groupName && isGbnValue(feed)}
            <span className="nickNm">{feed.nickNm ? feed.nickNm : "닉네임 미설정"}</span>
          </div>
        </div>
        {isAuth(feed) && (
          <div
            className={`dropdown ${active === feed.feedNo ? "active" : ""}`}
            onClick={() => (active === feed.feedNo ? setActive(null) : setActive(feed.feedNo))}
            style={{ padding: 10 }}>
            <FiMoreVertical />
            {active === feed.feedNo && (
              <ul>
                {user.mberNo === feed.mberNo && <li onClick={() => editFeed(feed)}>수정</li>}
                <li
                  onClick={() => handleDeleteModal(feed, "feed")}
                  style={{
                    borderTop:
                      user.mberNo === feed.mberNo ? "1px solid rgb(221, 221, 221)" : "none",
                  }}>
                  삭제
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
      <div className="feedBody">
        {feed.imgUrl && JSON.parse(feed.imgUrl).length > 0 ? (
          <div className="images" onClick={() => handleClickImage(feed)}>
            <GroupFeedSwiper img={JSON.parse(feed.imgUrl)} />
          </div>
        ) : (
          <></>
        )}
        <div className="content">{feed.showContent ? feed.realContent : feed.content}</div>
        {feed.realContent && (
          <button onClick={() => feedShowMore(feed.feedNo)} className="more">
            {feed.showContent ? "접기" : "자세히 보기"}
          </button>
        )}
      </div>
      <div className="feedFooter">
        <div className="commentList">
          {feed.comments && feed.comments.length > 3 && (
            <button
              className="more"
              onClick={() =>
                commentShowMore(
                  feed.feedNo,
                  feed.comments.length > feed.showComments ? "more" : "fold",
                )
              }>
              {feed.comments.length > feed.showComments ? "더보기" : "접기"}
            </button>
          )}
          {feed.comments && feed.comments.length > 0 && (
            <ul className="comments">
              {feed.comments.slice(-feed.showComments).map((item) => (
                <GroupDetailFeedComment
                  item={item}
                  handleDeleteModal={handleDeleteModal}
                  feed={feed}
                />
              ))}
            </ul>
          )}
        </div>
        <form onSubmit={(e) => onSubmit(e, feed.feedNo)}>
          <input
            type="text"
            placeholder="댓글 달기"
            value={text[feed.feedNo] || ""}
            name={feed.feedNo}
            onChange={(e) => setText({ ...text, [e.target.name]: e.target.value })}
          />
          <button
            className="font_20"
            type="submit"
            style={{
              backgroundColor:
                text[feed.feedNo] && text[feed.feedNo].trim() ? "rgb(0, 116, 202)" : "#F0F0F0",
            }}>
            <FiSend />
          </button>
        </form>
      </div>
    </li>
  );
};

export default GroupDetailFeedItem;
