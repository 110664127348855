import React from "react";
import { InstallContainer } from "../styled/dashboard";
import { IoCloseOutline } from "react-icons/io5";

const Install = ({ installApp, mode = "white", setDeferredPrompt = null }) => {
  return (
    <InstallContainer className={mode}>
      <div className="content">
        모노티 웹을 바탕화면에 추가하시겠습니까?
        <button onClick={installApp}>추가하기</button>
      </div>
      <button className="close" onClick={() => setDeferredPrompt(false)}>
        <IoCloseOutline />
      </button>
    </InstallContainer>
  );
};

export default Install;
