import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";

const SideMenuChoiceOption = ({
  label,
  name,
  items,
  isOpen,
  changeIsOpen,
  changeInput,
  selectAllCheck,
}) => {
  const [isAllChecked, setIsAllChecked] = useState();
  const user = useSelector((state) => state?.user?.data[0]);

  useEffect(() => {
    if (user) {
      setIsAllChecked(items.every((item) => item.check));
    }
  }, [items]);

  return (
    <li className={name}>
      <label htmlFor={name}>
        <input
          type="checkbox"
          checked={isAllChecked}
          onChange={() => selectAllCheck(name)}
          id={name}
        />
        {label}
      </label>
      <div className="more font_14">
        <button name={name} onClick={() => changeIsOpen(name)}>
          {label} 상세보기
          <span>{isOpen[name] ? <SlArrowUp /> : <SlArrowDown />}</span>
        </button>
        <ul className={isOpen[name] ? "active" : ""}>
          {Array.isArray(items) &&
            items.length > 0 &&
            items.map((item) => (
              <li key={item.orgNo}>
                <label htmlFor={String(item.orgNo)}>
                  <input
                    type="checkbox"
                    id={String(item.orgNo)}
                    name={name}
                    checked={item.check}
                    onChange={changeInput}
                  />
                  {item.orgNm}
                </label>
              </li>
            ))}
        </ul>
      </div>
    </li>
  );
};

export default SideMenuChoiceOption;
