export default function GroupRequestPopup () {
    return (
        <div className="popupWrapper" id="popupWrapper">
          <div className="popup">
            <div className="popupHeader font_28 font_color_black">
              <h2>개인정보처리방침</h2>
              <button className="closeBtn" id="closeBtn">×</button>
            </div>
            <div className="popupContent">
              <p className="font_20 font_color_gray">
                개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다.
                <br /><br /><br />
                개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다.
                <br />
                개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다.
                <br /><br />
                개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다.
                <br />
                개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다.
                <br /><br /><br /><br />
                개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다.
                <br /><br />
                개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다.
                <br />
                개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다.
                <br />
                개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다.
                <br />
                개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다.
                <br />
                개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다.
                <br />
                개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다. 개인정보 처리방침 내용이 들어갑니다.
                <br />
              </p>
            </div>
          </div>
        </div>     
    )
}