import React, { useEffect, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";

const ProjectFileBoxItem = ({
  files,
  folderState,
  handleAllCheckboxClick,
  toggleFileItem,
  selectedFiles,
  updateImageSize,
  fileHeight,
  setPageNumber,
  handleImageClick,
  setLoadingImages,
  loadingImages,
}) => {
  const observerTargetRef = useRef();
  const isLoading = useSelector((state) => state?.loading?.isLoading);
  const [isImageLoading, setIsImageLoading] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, { threshold: 0.5 });
    if (observerTargetRef.current) {
      observer.observe(observerTargetRef.current);
    }

    return () => {
      if (observerTargetRef.current) {
        observer.unobserve(observerTargetRef.current);
      }
    };
  }, []);

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !isLoading) {
      setPageNumber((page) => page + 1);
    }
  };

  const handleImageLoad = (filePath) => {
    setLoadingImages((prev) => ({ ...prev, [filePath]: true }));
    if (!isImageLoading) {
      updateImageSize();
      setIsImageLoading(true);
    }
  };

  // 사진, 파일, 비디오 분류
  const isFileType = (item) => {
    if (folderState.fileType === "I") {
      return (
        <>
          {!loadingImages[item.filePath] && <Skeleton height={fileHeight} width={fileHeight} />}
          <img
            src={imgHandler(item.filePath, false)}
            onLoad={() => handleImageLoad(item.filePath)}
            decoding="async"
          />
        </>
      );
    }
    if (folderState.fileType === "F") {
      return (
        <>
          <img
            src="/img/file.png"
            onLoad={updateImageSize}
            style={{ height: `calc(100% - 18px)` }}
          />
          <div className="tit">{item.fileNm}</div>
        </>
      );
    }
    if (folderState.fileType === "V") {
      return <img src="/img/video.png" onLoad={updateImageSize} />;
    }
  };

  const memoizedFiles = useMemo(() => files, [files]);

  return (
    <div className="filesContainer">
      <div className="content">
        {memoizedFiles[folderState.fileType] &&
          Object.entries(files[folderState.fileType]).map(([date, data]) => (
            <div key={date} className="dateGroup">
              <div className="dateHeader">
                <input
                  type="checkbox"
                  checked={data.every((item) => selectedFiles.includes(item))}
                  onChange={(e) => handleAllCheckboxClick(data, e.target.checked)}
                />
                <span>{date}</span>
              </div>
              {data.length > 0 && (
                <>
                  <ul className="fileGroup">
                    {data.map((item, idx) => (
                      <li
                        key={idx}
                        className="fileItem"
                        data-path={item.filePath}
                        style={{ height: fileHeight }}
                        onClick={() => handleImageClick(item)}>
                        <div className="preview">{isFileType(item, idx)}</div>
                        <span
                          className="selected"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFileItem(item);
                          }}>
                          {selectedFiles.includes(item) && <i>{selectedFiles.indexOf(item) + 1}</i>}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          ))}
      </div>
      <div id="observer" ref={observerTargetRef} style={{ height: "10px" }}></div>
    </div>
  );
};

export default ProjectFileBoxItem;
