import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setRegisterIndex } from "../../../../../store/compoIndex";

export default function RegisterMenu({ index }) {
  const dispatch = useDispatch();

  return (
    <ul className="hp_step3_list">
      {registerMenu.map((item, i) => (
        <li
          style={{ padding: "0" }}
          className={index === i ? "active marginR" : "marginR"}
          key={i}
          onClick={() => dispatch(setRegisterIndex(i))}>
          <Link
            style={{ padding: "10px 20px" }}
            to={"/mypage/register"}
            className="font_16 font400">
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
}
