import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  syncBussinessCode : "",
  syncLargeCode : "",
  syncMiddleCode : "",
  syncSmallCode : "",
  syncDetailCode : "",
  syncLargeCategory : [],
  syncMiddleCategory : [],
  syncSmallCategory : [],
  syncDetailCategory : [],
  syncIsClick : [false, false, false, false]
};

const productSyncCode = createSlice({
  name : "productSyncCode",
  initialState,
  reducers : {
    setSyncBussinessCode(state, action) {
      state.syncBussinessCode = action.payload;
    },
    setSyncLargeCode(state, action) {
      state.syncLargeCode = action.payload;
    },
    setSyncMiddleCode(state, action) {
      state.syncMiddleCode = action.payload;
    },
    setSyncSmallCode(state, action) {
      state.syncSmallCode = action.payload;
    },
    setSyncDetailCode(state, action) {
      state.syncDetailCode = action.payload;
    },
    setSyncLargeCategory(state, action) {
      state.syncLargeCategory = [...action.payload];
    },
    setSyncMiddleCategory(state, action) {
      state.syncMiddleCategory = [...action.payload];
    },
    setSyncSmallCategory(state, action) {
      state.syncSmallCategory = [...action.payload];
    },
    setSyncDetailCategory(state, action) {
      state.syncDetailCategory = [...action.payload];
    },
    setSyncIsClick(state, action) {
      state.syncIsClick = [...action.payload];
    },
    resetSyncBussiness(state) {
      state.syncBussinessCode = "";
      state.syncLargeCode = "";
      state.syncMiddleCode = "";
      state.syncSmallCode = "";
      state.syncDetailCode = "";
      state.syncLargeCategory = [];
      state.syncMiddleCategory = [];
      state.syncSmallCategory = [];
      state.syncDetailCategory = [];
      state.syncIsClick = [false, false, false, false];
    },
    resetSyncLarge(state) {
      state.syncLargeCode = "";
      state.syncMiddleCode = "";
      state.syncSmallCode = "";
      state.syncDetailCode = "";
      state.syncMiddleCategory = [];
      state.syncSmallCategory = [];
      state.syncDetailCategory = [];
      state.syncIsClick = [true, false, false, false];
    },
    resetSyncMiddle(state) {
      state.syncMiddleCode = "";
      state.syncSmallCode = "";
      state.syncDetailCode = "";
      state.syncSmallCategory = [];
      state.syncDetailCategory = [];
      state.syncIsClick = [true, true, false, false];
    }
  }
});

export const 
{ setSyncBussinessCode, 
  setSyncLargeCode,
  setSyncMiddleCode, 
  setSyncSmallCode,
  setSyncDetailCode, 
  setSyncLargeCategory,
  setSyncMiddleCategory,
  setSyncSmallCategory,
  setSyncDetailCategory,
  setSyncIsClick,
  resetSyncBussiness,
  resetSyncLarge,
  resetSyncMiddle
} = productSyncCode.actions;
export default productSyncCode;
