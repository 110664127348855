import { useEffect } from "react";
import moment from "moment";
import "moment/locale/ko";

export const exitFullscreen = () => {
  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  ) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
};

export const onFullscreen = () => {
  const onFullScreenChange = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener("webkitfullscreenchange", onFullScreenChange);
      document.removeEventListener("mozfullscreenchange", onFullScreenChange);
      document.removeEventListener("MSFullscreenChange", onFullScreenChange);
    }
  };

  document.addEventListener("fullscreenchange", onFullScreenChange);
  document.addEventListener("webkitfullscreenchange", onFullScreenChange);
  document.addEventListener("mozfullscreenchange", onFullScreenChange);
  document.addEventListener("MSFullscreenChange", onFullScreenChange);

  const element = document.documentElement;
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
};

export const useDisableScroll = () => {
  useEffect(() => {
    const originalOverflowHtml = document.documentElement.style.overflow;
    const originalOverflowBody = document.body.style.overflow;

    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";

    return () => {
      document.documentElement.style.overflow = originalOverflowHtml;
      document.body.style.overflow = originalOverflowBody;
    };
  }, []);
};

export const formatTime = (item, isDateVisible) => {
  const { reservationDay: day, reservationStartTime: start, reservationTime: duration } = item;
  if (!start || duration === undefined) return "";

  const startTime = moment(start, "HH:mm");
  const endTime = startTime.clone().add(duration, "minutes");

  if (!isDateVisible) {
    const formattedStartTime = startTime.format("HH:mm");
    const formattedEndTime = endTime.format("HH:mm");

    return `${formattedStartTime}~${formattedEndTime}`;
  } else {
    const startDateTime = moment(`${day} ${start}`, "YYYY-MM-DD HH:mm");
    const endDateTime = startDateTime.clone().add(duration, "minutes");

    const formattedStartDateTime = startDateTime.format("MM월 DD일 A hh:mm");
    const formattedEndDateTime = endDateTime.format("A hh:mm");

    return `${formattedStartDateTime} ~ ${formattedEndDateTime}`;
  }
};

export const formatStorageTimes = (item) => {
  if (!item || typeof item !== "object") return { start: "", end: "" };
  const { reservationStartTime: start, reservationTime: duration } = item;

  if (!start || !duration || isNaN(duration)) {
    return { start: "", end: "" };
  }

  try {
    const startTime = moment(start, "HH:mm");
    if (!startTime.isValid()) {
      throw new Error("Invalid start time format");
    }
    const endTime = startTime.clone().add(parseInt(duration, 10), "minutes");

    if (!endTime.isValid()) {
      throw new Error("Invalid end time calculation");
    }

    const formattedStart = startTime.format("A hh:mm");
    const formattedEnd = endTime.format("A hh:mm");

    return { start: formattedStart, end: formattedEnd };
  } catch (error) {
    return { start: "", end: "" };
  }
};

export const storageType = {
  0: { label: "냉장고", key: "refrigerated" },
  1: { label: "상온", key: "roomTemperature" },
  2: { label: "캐리어", key: "carrier" },
};

export const isStorageType = (type, flag) => {
  const storage = storageType[type];
  return storage ? storage[flag] : "";
};

export const resStatus = {
  PENDING: { label: "대기", color: "#acadac" },
  PICKUP: { label: "픽업완료", color: "#378b15" },
  CANCELED: { label: "취소", color: "#d52727" },
  CANCEL: { label: "취소", color: "#d52727" },
  READY: { label: "픽업요청", color: "#FE8A01" },
  COMPLETED: { label: "보관중", color: "#2e5aac" },
};

export const formatText = (text) => {
  return text ? text.replace(/-/g, "") : "";
};

export const isPayType = (type) => {
  switch (type) {
    case 0:
      return "앱";
    case 1:
      return "카드";
    case 2:
      return "현금";

    default:
      return "";
  }
};

export const formatDate = (dateStr) => {
  if (!dateStr) return "";
  return dateStr.includes("-")
    ? dateStr
    : `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}`;
};

// 라벨 프린트
export const printLabel = async (item, isClient = false) => {
  setLabelId(1);
  checkLabelStatus();
  clearBuffer();

  if (!isClient) {
    const textItems = [
      { text: "예약번호", y: 20, fontSize: 48 },
      { text: item.reserveId, y: 90, fontSize: 48 },
      { text: "보관함: " + item.storageId, y: 200, fontSize: 56 },
      { text: "보관방식: " + item.type, y: 300, fontSize: 30 },
      { text: "고객명: " + item.mberNm, y: 360, fontSize: 30 },
      { text: "연락처: " + item.tel, y: 420, fontSize: 30 },
      { text: "보관시작시간: " + item.reservationTime, y: 480, fontSize: 30 },
    ];

    textItems.forEach(({ text, y, fontSize }) => {
      drawTrueTypeFont(text, 20, y, "Arial", fontSize, 0, false, false, false, false);
    });
  } else {
    const textItems = [
      { text: "고객용", y: 20, fontSize: 48 },
      { text: "고객명: " + item.mberNm, y: 100, fontSize: 30 },
      { text: "예약번호: " + item.reserveId, y: 150, fontSize: 30 },
      { text: "보관시작시간: " + item.reservationTime, y: 200, fontSize: 30 },
    ];

    textItems.forEach(({ text, y, fontSize }) => {
      drawTrueTypeFont(text, 20, y, "Arial", fontSize, 0, false, false, false, false);
    });

    let yPosition = 270;

    // 고객 데이터 출력
    Object.entries(item.type).forEach(([key, values]) => {
      drawTrueTypeFont(
        storageType[key].label,
        20,
        yPosition,
        "Arial",
        30,
        0,
        false,
        false,
        false,
        false,
      );

      const valuesString = values.join(", ");
      yPosition += 40;

      drawTrueTypeFont(valuesString, 20, yPosition, "Arial", 30, 0, false, false, false, false);
      yPosition += 30;
    });
  }

  printBuffer();

  const labelData = getLabelData();
  requestPrint("enxss", labelData, function (result) {
    console.log(result);
  });
};

// 시간 올림
export const roundMinutes = () => {
  const time = moment();
  const minutes = time.minutes();
  const roundedMinutes = Math.ceil(minutes / 5) * 5;

  if (roundedMinutes === 60) {
    time.add(1, "hour");
    time.minutes(0);
  } else {
    time.minutes(roundedMinutes);
  }

  return time.seconds(0).format("HH:mm");
};

// 추가요금 여부 확인
export const checkAdditionalFee = (item) => {
  const now = moment();
  const reservationStart = moment(
    `${item.reservationDay} ${item.reservationStartTime}`,
    "YYYY-MM-DD HH:mm",
  );
  const reservationEnd = reservationStart.clone().add(item.reservationTime, "minutes");

  return reservationEnd.isBefore(now);
};

// 보관/픽업예약 체크
export const isValidStatus = (status) => ["COMPLETED", "READY"].includes(status);

export const isPlatform = (os) => {
  if (!os) return "";
  if (os === "web") return "키오스크";
  return "모노티";
};

export const sales = {
  1: { label: "카드", color: "#4f78b8", type: "card" },
  2: { label: "현금", color: "#ff8a00", type: "cash" },
  0: { label: "앱", color: "#a976ff", type: "app" },
  3: { label: "총합", color: "#333", type: "total" },
};

export const isSScenterMbers = (mberNo) => {
  const mberNos = [3343, 3342, 3341, 3340, 3339, 3338, 3337, 3336, 3335];
  if (mberNos.some((no) => mberNo === no)) {
    return true;
  }
  return false;
};

export const extractTime = (time) => {
  if (!Array.isArray(time) || !time.length) {
    return "";
  }

  const year = time[0];
  const month = time[1] - 1;
  const day = time[2];
  const hours = time[3];
  const minutes = time[4];
  const seconds = time[5];

  const date = moment({ year, month, day, hours, minutes, seconds });
  return date;
};
