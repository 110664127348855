import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { status } from "../../../function/mutualBenefit";
import { useMediaQuery } from "react-responsive";

const OrderItem = ({ item, index, contentRefs, toggleItem, selected }) => {
  console.log(item);
  const isSelected = (item) => selected?.id === item.id;
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRefs.current[index]) {
      setContentHeight(contentRefs.current[index].scrollHeight);
    }
  }, [selected]);

  return (
    <li className={isSelected(item) ? "active" : "inactive"}>
      <div className="toggle">
        <button onClick={() => toggleItem(item)}>
          <FaChevronRight />
        </button>
      </div>
      <div className="index">{item?.id}</div>
      <div className={`status ${item?.status}`}>
        <span>{status[item?.status]}</span>
      </div>
      <div className="createdAt">{moment(item?.createdAt).format("YYYY.MM.DD HH:MM")}</div>
      <div className="price">{item?.price ? Number(item?.price).toLocaleString() : 0}원</div>
      {item?.products && (
        <div
          className="products"
          ref={(el) => (contentRefs.current[index] = el)}
          style={{
            maxHeight: isSelected(item) ? `${contentHeight}px` : "0",
          }}>
          <span className="tit">주문상품</span>
          <ul>
            <li className="header">
              <div className="product media">상품이미지</div>
              <div className="product name">상품명</div>
              <div className="product price">상품가격</div>
              <div className="product quantity">개수</div>
            </li>
            {item.products.map((product) => (
              <li>
                <div className="product media">
                  <img src={imgHandler(JSON.parse(product.media))} alt={product?.name} />
                </div>
                <div className="product name">{product.name}</div>
                <div className="product price">
                  {product?.price ? Number(product?.price).toLocaleString() : 0}원
                </div>
                <div className="product quantity">{product.quantity}</div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

export default OrderItem;
