import React from "react";
import { LoadingContainer } from "./styled/common";

const Loading = () => {
  return (
    <LoadingContainer>
      <i className="xi-spinner-4 xi-spin"></i>
    </LoadingContainer>
  );
};

export default Loading;
