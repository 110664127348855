import React from "react";
import { Alert, ModalBackground } from "../../styled/common";
import { IoClose } from "react-icons/io5";
import ModalHeader from "./modalHeader";
import { useMediaQuery } from "react-responsive";

// 단순 알림
const AlertModal = ({
  title = "",
  message = "",
  handleConfirm = null,
  index = null,
  style = {},
}) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const renderMessage = (msg) => {
    if (typeof msg === "string") {
      return msg.split("\n").map((part, index) => (
        <React.Fragment key={index}>
          <strong>{part}</strong>
          {index < msg.split("\n").length - 1 && <br />}
        </React.Fragment>
      ));
    } else if (Array.isArray(msg)) {
      return (
        <>
          <strong>{msg[0]}</strong>
          {msg.slice(1).map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <ModalBackground
      style={{
        background: index ? "transparent" : "rgba(0, 0, 0, 0.7)",
        zIndex: index ? 9999 + index : 9999,
      }}>
      <Alert style={!isMobile && index ? style : null}>
        <ModalHeader title={title} handleCancel={handleConfirm} />
        <div className="modalContent">
          <div className="message">{renderMessage(message)}</div>
          <div className="modalActions">
            <button onClick={handleConfirm}>확인</button>
          </div>
        </div>
      </Alert>
    </ModalBackground>
  );
};

export default AlertModal;
