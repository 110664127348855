export default function CompanyAddForm() {
  return(
    <div className="inner_content_wrap">
      <form id="form1" name="form1" method="post" action="work_write_proc.php" encType="multipart/form-data" target="ifrm">
        <div>
          <div id="step1_html">
            <h5 className="sound_only">프로젝트 정보 입력</h5>
            <div className="writing_ty2">
              <ul className="writing_list">
                <li >
                  <div className="writing_tit ">
                      <strong className="tit">회사명</strong>
                  </div>
                  <div className="writing_cont">
                      <div className="inputxt_normal spider">
                      <input type="text" placeholder="회사명을 입력하세요" defaultValue />
                      </div>
                  </div>
                </li>
                <li>
                  <div className="writing_tit ">
                      <strong className="tit">사업자번호</strong>
                  </div>
                  <div className="writing_cont" id="company_regnum">
                      <div className="inputxt_normal">
                      <input type="text" placeholder="사업자번호를 입력하세요. (- 없이)" defaultValue />
                      <button type="button" name="button" className="btn_line gray btn_duplicate"><span>중복체크</span></button>
                      </div>
                  </div>
                </li>
                <li>
                  <div className="writing_tit">
                      <strong className="tit">사업자등록증 첨부</strong>
                  </div>
                  <div className="writing_cont">
                    <div className="file_import">
                    <input type="text" id="file_import1" readOnly="readonly" defaultValue />
                    <label>
                      파일찾기
                      <input type="file" id="wk_file" name="wk_file"  />
                      {/* onChange="javascript:document.getElementById('file_import1').value=this.value" */}
                    </label>
                    </div>
                  </div>
                  </li>
              </ul>
            <div className="impor_txt">
              ※ 실제 소유사업자 및 존재사업자 여부를 관리자가 검토후 승인처리합니다.<br />
              ※ 승인 되지않은 사업자는 홈페이지 생성이 불가 하오니, 승인이 지연될 경우 고객센터로 연락바랍니다.
            </div>
            <div className="btnarea_center mt20">
              <a href="#" className="btn_normal">
                <span>수정하기</span>
              </a>
            </div>
            </div>
          </div>
        </div>
      </form>
  </div>
  )
}