import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import InteractionStats from "./interactionStats";
import { SwiperListContainer } from "../styled/common";
import { useMediaQuery } from "react-responsive";

const createSlides = (data, range) => {
  const slides = [];
  for (let i = 0; i < data.length; i += range) {
    slides.push(data.slice(i, i + range));
  }
  return slides;
};

const SwiperList = ({ data, range, type }) => {
  const [slides, setSlides] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  useEffect(() => {
    setSlides(createSlides(data, isMobile ? 1 : range));
  }, [data]);

  const swiperRef = useRef();

  return (
    <SwiperListContainer className={type}>
      <Swiper
        className="wrapper"
        ref={swiperRef}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}>
        {slides.map((slideData, index) => (
          <SwiperSlide key={index}>
            {slideData.map((item) => (
              <div className="box">
                <div className="slideContent">
                  <div className="writer">
                    <img src={"/img/user2.png"} alt="group image" />
                    <span>김모노티</span>
                  </div>
                  <div className="questions">
                    <span>안녕하세요 ! 문의가 있어 글 남깁니다!</span>
                    {type === "contactUs" && (
                      <div className="respond">답글 : 이거는이러이러해서 이렇습니다~</div>
                    )}
                  </div>
                </div>
                <InteractionStats />
              </div>
            ))}
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperListContainer>
  );
};

export default SwiperList;
