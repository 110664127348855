import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { TfiSearch } from "react-icons/tfi";
import { SalesCheckInfoContainer } from "../../../styled/salesCheck";
import { status } from "../../../function/mutualBenefit";
import SalesCheckCalendar from "./salesCheckCalendar";
import { IoMdClose } from "react-icons/io";

const SalesCheckInfo = ({ active, tabs, searched, setSearched }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [openCalendar, setOpenCalendar] = useState(false);

  useEffect(() => {
    console.log(searched);
  }, [searched]);

  const changeSearched = ({ name, value }) => {
    setSearched({ ...searched, [name]: value });
    if (openCalendar) setOpenCalendar(false);
  };

  return (
    <SalesCheckInfoContainer>
      <div className="info">
        <span>{tabs[active]}</span>
        {active === "orderHistory" && (
          <div className="search">
            <div className="date">
              <button onClick={() => setOpenCalendar(true)}>
                {searched?.date ? searched.date : "날짜선택"}
              </button>
              {searched?.date && (
                <button onClick={(e) => changeSearched({ name: "date", value: null })}>
                  <IoMdClose />
                </button>
              )}
            </div>
            <div className="status">
              <span className="label">상태별</span>
              <select
                value={searched.status}
                onChange={(e) => changeSearched({ name: "status", value: e.target.value })}>
                <option value="all">전체보기</option>
                {Object.entries(status).map(([key, label]) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <button>
              <TfiSearch />
            </button>
          </div>
        )}
      </div>
      {openCalendar && (
        <SalesCheckCalendar
          setOpenCalendar={setOpenCalendar}
          date={searched.date}
          changeSearched={changeSearched}
        />
      )}
    </SalesCheckInfoContainer>
  );
};

export default SalesCheckInfo;
