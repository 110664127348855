import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";

export default function BoardList({ BoardListProps }) {
  const navigater = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width:1050px)" });

  const moveToPage = () => {
    if (BoardListProps.title === "공지사항") navigater("/noticeView");
    if (BoardListProps.title === "자주하는 질문") navigater("/qnaView");
    if (BoardListProps.title === "FAQ") navigater("/mypage/faqView");
    if (BoardListProps.title === "1:1문의") navigater("/mypage/myQnaView");
    if (BoardListProps.title === "[추가]공지사항") navigater("/mypage/registerNoticeView");
  };

  const moveToWritePage = () => {
    if (BoardListProps.title === "자주하는 질문") navigater("/qnaWrite");
    if (BoardListProps.title === "1:1문의") navigater("/mypage/myQnaWrite");
    if (BoardListProps.title === "[추가]공지사항") navigater("/mypage/registerNoticeWrite");
  };

  return (
    <>
      {BoardListProps.search && (
        <div className="search_box">
          <fieldset>
            <legend>검색영역</legend>
            <div className="basic">
              <select name="search_type" id="search_type">
                <option value="A">전체</option>
                <option value="T">제목</option>
                <option value="C">내용</option>
              </select>
              <input type="text" id="keyword" name="keyword" placeholder="검색어를 입력하세요." />
              <button type="submit">
                <i className="fa fa-search" aria-hidden="true" />
                <span className="sound_only">검색</span>
              </button>
            </div>
          </fieldset>
        </div>
      )}

      <table className="board_table">
        <caption>FAQ 게시판</caption>
        <thead>
          <tr>
            {boardFaq.map((item, i) => (
              <th scope="col" key={i}>
                {item.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* <tr style={{ width: "100%", cursor: "pointer" }}>
            <td className="td_width2">222</td>
            <td className="td_width">카테고리 이름</td>
            <td
              className="td_title"
              style={{ textAlign: isMobile ? "left" : "center" }}
              onClick={moveToPage}>
              {BoardListProps.title}
            </td>
            <td className="td_width">홍길동</td>
            <td className="td_width">2023.04.01</td>
            <td className="td_width">111</td>
          </tr> */}
          <tr style={{ width: "100%", background: "white" }}>
            <td style={{ width: "100%" }} colSpan={6} className="table_data_empty">
              {BoardListProps.title && (
                <>{chooseParticle(BoardListProps.title, "이가")} 없습니다.</>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {/* <div className="board_paging">
        <a href="" className="ctrl fir">
          <i className="fa fa-angle-double-left" aria-hidden="true"></i>
          <span className="sound_only">처음</span>
        </a>
        <a href="" className="ctrl bac">
          <i className="fa fa-angle-left" aria-hidden="true"></i>
          <span className="sound_only">이전</span>
        </a>
        <a href="" className="num actived">
          <strong>
            1<span className="sound_only">현재 페이지</span>
          </strong>
        </a>
        <a href="" className="num">
          2<span className="sound_only">2 페이지</span>
        </a>
        <a href="" className="ctrl nex">
          <i className="fa fa-angle-right" aria-hidden="true"></i>
          <span className="sound_only">다음</span>
        </a>
        <a href="" className="ctrl las">
          <i className="fa fa-angle-double-right" aria-hidden="true"></i>
          <span className="sound_only">마지막</span>
        </a>
        {BoardListProps.button && (
          <button type="button" className="btn_write" onClick={moveToWritePage}>
            {BoardListProps.title === "[추가]공지사항" ? "등록하기" : "글쓰기"}
          </button>
        )}
      </div> */}
    </>
  );
}
