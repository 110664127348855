export default function HomepageEndTitle () {
  return(
    <div className="inner_title">
      <p className="font_30 font600 pdb_40">
          <strong className="font_color_1 font600">ogtoz@naver.com</strong>님<br/>

          홈페이지 생성이 완료되었습니다.<br/>
      </p>
      <span className="font_18 font400 font_color_black pdb_40 text_center">지금 접속하여 회사정보, 제품정보 등 자세한 사항을 수정해보세요.</span>
      <ul className="hp_step_2_name pdb_40">
          <li>
              <span className="font_20 font500 font_color_black text_center">유형</span>
              <span className="font_20 font400 font_color_black text_center">제품연동 홈페이지</span>
          </li>
          <li>
              <span className="font_20 font500 font_color_black text_center">사업자번호</span>
              <span className="font_20 font400 font_color_black text_center">742-86-00224</span>
          </li>
          <li>
              <span className="font_20 font500 font_color_black text_center">회사이름</span>
              <span className="font_20 font400 font_color_black text_center">여기어때</span>
          </li>
          <li>
              <span className="font_20 font500 font_color_black text_center">도메인설정</span>
              <span className="font_20 font400 font_color_black text_center"><a href="http://1472.ai/h/ogto43" className="font_color_black">http://1472.ai/h/ogto43</a></span>
          </li>
      </ul>
    
      <div className="btnarea_center m_btn_full">
          <a href="" className="btn_normal_save">
              <span>생성된 홈페이지 바로가기</span>
          </a>
          <a href="" className="btn_normal">
              <span>홈페이지 설정 바로가기</span>
          </a>
      </div>
    </div>
  )
}