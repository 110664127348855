import React from "react";
import Pagination from "react-js-pagination";
import { NotMessage } from "../../../common/styled/project";
import { useMediaQuery } from "react-responsive";

const ProjectDetailParticipantList = ({
  mberTotal,
  currentPage,
  onPageChange,
  mberList,
  isGrp,
  isSearch,
  handleCheck,
  handleCheckAll,
  isChecked,
  isAllChecked,
  handleModalOpen,
  chkMberList,
  participantList,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isMberName = (mberNm) => {
    if (isGrp && !mberNm) return "닉네임 미설정";
    if (isGrp) return mberNm;
    return `${mberNm[0]}${mberNm.length > 1 ? "*" : ""}${mberNm.substring(2)}`;
  };

  const buttonOption = (state) => {
    if (state) {
      return {
        pointerEvents: "auto",
        background: "#0074ca",
        cursor: "pointer",
      };
    } else {
      return {
        pointerEvents: "none",
        background: "#555",
        cursor: "default",
      };
    }
  };

  return (
    <div className="section">
      {mberList.length > 0 ? (
        <table className={isGrp ? "grp" : "cmpny"}>
          <thead>
            <tr>
              <th>
                <input type="checkbox" onChange={handleCheckAll} checked={isAllChecked} />
              </th>
              {!isGrp && <th>회사명</th>}
              <th>이름</th>
              {!isGrp && <th>휴대번호</th>}
              <th>초대</th>
            </tr>
          </thead>
          <tbody>
            {mberList.map((mber) => (
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={isChecked(mber.mberNo)}
                    onChange={() => handleCheck(mber)}
                  />
                </td>
                {!isGrp && <td>{mber.cmpnyNm}</td>}
                <td>{`${isMberName(mber.mberNm)}`}</td>
                {!isGrp && (
                  <td>
                    {mber.moblphonNo
                      ? `${mber.moblphonNo.substring(0, 3)}****${mber.moblphonNo.substring(7)}`
                      : ""}
                  </td>
                )}
                <td>
                  <button
                    style={buttonOption(
                      mber.mberNm &&
                        !participantList.some((participant) => participant.mberNo === mber.mberNo),
                    )}
                    onClick={() => handleModalOpen("invite", [mber])}>
                    초대
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NotMessage>
          {isSearch ? "검색된 멤버가 존재하지 않습니다." : "찾으려는 멤버를 검색해주세요."}
        </NotMessage>
      )}
      {mberTotal > 0 && (
        <div className="inviteArea">
          <div>
            <button
              onClick={() => handleModalOpen("invite", chkMberList)}
              style={buttonOption(chkMberList.length > 0)}>
              초대
            </button>
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={isMobile ? 7 : 10}
              totalItemsCount={mberTotal}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={onPageChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDetailParticipantList;
