import React, { useEffect } from "react";
import PendingCount from "./salesCheckDetails/pendingCount";
import SalesOverview from "./salesCheckDetails/salesOverview";
import OrderHistory from "./salesCheckDetails/orderHistory";
import Statistics from "./salesCheckDetails/statistics";
import SettlementForm from "./salesCheckDetails/settlementForm";

const SalesCheckDetails = ({ active, tabs }) => {
  const renderComponent = () => {
    switch (active) {
      case "salesCheck":
        return <SalesOverview active={active} tabs={tabs} />;
      case "orderHistory":
        return <OrderHistory active={active} tabs={tabs} />;
      case "statistics":
        return <Statistics active={active} tabs={tabs} />;
      case "settlementForm":
        return <SettlementForm active={active} tabs={tabs} />;
      default:
        return null;
    }
  };

  return (
    <div className="content salesCheck">
      <PendingCount />
      <div className="viewer">{renderComponent()}</div>
    </div>
  );
};

export default SalesCheckDetails;
