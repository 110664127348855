import React, { useCallback } from "react";
import { MobileEventGalleryContainer } from "../styled/scheduler";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import MobileDropImageCollection from "./mobileDropImageCollection";
import DragToUpload from "../scheduler/eventLayer/eventGallery/dragToUpload";

const MobileEventGallery = ({
  eventInfo,
  setIsDetail,
  setImageOrder,
  handleContextMenu,
  isRelatedPerson,
  originalImages,
  setOriginalImages,
  setImages,
  images,
  deleteImages,
  getImages,
}) => {
  const user = useSelector((state) => state?.user?.data[0]);

  // 파일 추가
  const handleFilesAdded = useCallback((files) => {
    if (!isRelatedPerson) {
      toast.error("사진 업로드 권한이 없습니다.");
      return;
    }

    if (files.length) {
      const imgArr = [];
      let loadedCount = 0;

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (event) => {
          imgArr[i] = { path: event.target.result };
          loadedCount++;
          files[i].path = event.target.result;

          if (loadedCount === files.length) {
            setOriginalImages((prev) => [...prev, ...files]);
            setImages((prev) => [...prev, ...imgArr.filter(Boolean)]);
          }
        };
        reader.readAsDataURL(files[i]);
      }
    }
  }, []);

  // 이미지 업로드
  const uploadImages = async () => {
    if (originalImages.length < 1) {
      toast.error("업로드 할 이미지가 존재하지 않습니다.");
      return;
    }

    try {
      const formData = new FormData();
      originalImages.forEach((image) => formData.append("images", image));

      if (eventInfo.projectNo) {
        formData.append("gbn", "P");
      } else {
        formData.append("gbn", "S");
      }
      formData.append("mberNo", user.mberNo);
      formData.append("refeNo", eventInfo.scheduleNo);

      const response = await axios.post("/api/photos", formData, {});

      if (response.status === 200) {
        getImages();
        toast.success("이미지가 성공적으로 업로드되었습니다.");
        setOriginalImages([]);
      } else {
        console.error("업로드 실패", response.data);
      }
    } catch (error) {
      console.error("업로드 중 에러 발생", error);
    }
  };

  return (
    <MobileEventGalleryContainer>
      <MobileDropImageCollection
        images={images}
        setIsDetail={setIsDetail}
        deleteImages={deleteImages}
        setImageOrder={setImageOrder}
        handleContextMenu={handleContextMenu}
      />
      <DragToUpload onFilesAdded={handleFilesAdded} />
      <div className="gallery">
        <button onClick={uploadImages}>저장</button>
      </div>
    </MobileEventGalleryContainer>
  );
};

export default MobileEventGallery;
