import React from "react";
import QnaRequestForm from "./component/qnaPage/qnaRequestForm";
import NoticeBanner from "./noticeBanner";

const QnaPage = () => {
  return (
    <>
      <NoticeBanner />
      <QnaRequestForm />
    </>
  );
};

export default QnaPage;
