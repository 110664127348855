import MemberPageSwiper from "./memberPageSwiper";

export default function Members({ members, totalMber }) {
  return (
    <>
      <div className="inner_title" id="member_1" style={{ paddingBottom: "0%" }}>
        <span className="font_48 font600 font_color_black pdb_40">{service.customer}</span>
        <span className="font_30 font300 font_color_black pdb_40">
          {service.mComment7} <strong className="font_color_1">{totalMber}</strong>
          {service.mComment8}
        </span>
      </div>
      <div className="inner_content_wrap" id="member_11">
        <MemberPageSwiper members={members} />
      </div>
    </>
  );
}
