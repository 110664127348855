import React from "react";

const ConfirmModal = ({ setOpenModal, handleUpload, message }) => {
  return (
    <div>
      <h2 className="font_20 font500 font_color_white">{message} 등록</h2>
      <button onClick={() => setOpenModal(false)} className="hp_step_setting_wrap_close">
        <i className="fa fa-times" aria-hidden="true" />
      </button>
      <span className="font_20 font300 font_color_gray pdt_20 text_center">
        {message}을 등록하시겠습니까?
      </span>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
        <button
          onClick={() => setOpenModal(false)}
          className="hp_step_setting_cate_save"
          style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
          취소
        </button>
        <button
          onClick={handleUpload}
          className="hp_step_setting_cate_save"
          style={{ padding: "10px 20px", margin: "0px" }}>
          확인
        </button>
      </div>
    </div>
  );
};

export default ConfirmModal;
