import React from "react";
import { IoMdSearch } from "react-icons/io";
import { SearchFormContainer } from "../styled/search";

const SearchForm = ({ searchWord, setSearchWord, handleSearch }) => {
  return (
    <SearchFormContainer>
      <input
        type="text"
        value={searchWord}
        onChange={(e) => setSearchWord(e.target.value)}
        onKeyDown={(e) => handleEnterKey(e, handleSearch)}
      />
      <button onClick={handleSearch}>
        <IoMdSearch />
      </button>
    </SearchFormContainer>
  );
};

export default SearchForm;
