import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setServiceIndex } from "../../../store/compoIndex";


export default function ServiceBanner(){
  const dispatch = useDispatch();
  
  return (
    <div className="sub_top" id="function">
      <span className="font_22 font300 pdb_20 font_color_white">{service.serviceInfo}</span>
      <span className="font_72 font700 font_color_1 pdb_70">{service.serviceE}</span>
      <ul className="sub_menu">
        <li>
          <Link onClick={() => dispatch(setServiceIndex(0))} to="/service">{service.point}</Link>
        </li>
        <li>
          <Link onClick={() => dispatch(setServiceIndex(1))} to="/service">{service.case}</Link>
        </li>
        <li>
          <Link onClick={() => dispatch(setServiceIndex(2))} to="/service">{service.service}</Link>
        </li>
      </ul>
    </div>
  );
}