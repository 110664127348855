import React, { useState } from "react";
import { GroupDetailSettingProfileContainer } from "../../../styled/group";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import axios from "axios";
import Modal from "../../../../../../common/modal";
import { useSelector } from "react-redux";

const GroupDetailSettingProfile = ({ grp, userInfo, setViewType, setGrpMber, setUserInfo }) => {
  const [formData, setFormData] = useState({
    nickNm: userInfo.nickNm,
    gbnValue: userInfo.gbnValue,
  });
  const [isChkDuplNickNm, setIsChkDuplNickNm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { id } = useParams();
  const user = useSelector((state) => state?.user?.data[0]);

  // 닉네임 중복 체크
  const chkDuplNickNm = async () => {
    if (!formData.nickNm || !formData.nickNm.trim()) {
      toast.error("닉네임을 입력해주세요.");
      return;
    }
    if (formData.nickNm.length < 2 || formData.nickNm.length > 12) {
      toast.error("닉네임의 길이는 2~12자리여야 합니다.");
      return;
    }

    const url = "/api/duplNickName";
    const body = {
      groupNo: id,
      delYn: "N",
      nickNm: formData.nickNm,
    };

    const res = await axios.post(url, body);
    if (res.data.success) {
      toast.success("사용 가능한 닉네임입니다.");
      setIsChkDuplNickNm(true);
    } else {
      toast.error("이미 사용 중인 닉네임입니다.");
      setIsChkDuplNickNm(false);
    }
  };

  // 닉네임 변경
  const changeNickName = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));

    if (name === "nickNm") {
      setIsChkDuplNickNm(false);
    }
  };

  const checkValidation = () => {
    if (grp?.gbn && !formData.gbnValue) {
      toast.error("기수를 입력해주세요.");
      return false;
    }
    if (userInfo.nickNm !== formData.nickNm && !isChkDuplNickNm) {
      toast.error("닉네임 중복 확인을 해주세요.");
      return false;
    }
    if (isSubmitting) {
      toast.error("수정이 진행 중입니다.");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!checkValidation()) {
      return;
    }
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    const url = "/api/groupMber";
    const body = {
      ...userInfo,
      nickNm: formData.nickNm,
      gbnValue: formData.gbnValue,
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    const res = await axios.put(url, body);

    if (res.status === 200) {
      toast.success("프로필이 변경되었습니다.");
      setModalOpen(false);
      setViewType(0);
      await getGrpMbers();
    }
  };

  // 모임 멤버 조회
  const getGrpMbers = async () => {
    const url = "/api/groupMberList";
    const req = {
      groupNo: id,
      offset: 0,
      delYn: "N",
      pageNumber: 0,
      pageSize: 1,
      paged: false,
    };
    const res = await axios.post(url, req);
    if (res.status === 200) {
      const grpMbers = res?.data?.content;
      setGrpMber(grpMbers);

      const isMber = grpMbers?.filter((item) => item?.mberNo === user?.mberNo)?.[0];
      if (isMber) {
        setUserInfo((prev) => ({
          ...prev,
          auth: isMber.auth,
          seq: isMber.seq,
          nickNm: isMber.nickNm,
          gbnValue: isMber.gbnValue,
        }));
      }
    }
  };

  const ModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">정보 수정</h2>
        <button onClick={() => setModalOpen(false)} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          프로필을 수정하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={handleConfirm}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  return (
    <GroupDetailSettingProfileContainer>
      <div className="userProfile">
        {grp.gbn && (
          <div className="gbn">
            <input
              type="number"
              name="gbnValue"
              value={formData.gbnValue}
              onChange={changeNickName}
            />
            <span>{grp.gbn}</span>
          </div>
        )}
        <div className="nickNm">
          <input type="text" name="nickNm" value={formData.nickNm} onChange={changeNickName} />
          <button onClick={chkDuplNickNm}>중복확인</button>
        </div>
      </div>
      <button onClick={handleSubmit} className="submitButton">
        확인
      </button>
      <Modal
        modalState={modalOpen}
        handleModalState={() => setModalOpen(false)}
        html={ModalForm()}
        w="300px"
        h="auto"
      />
    </GroupDetailSettingProfileContainer>
  );
};

export default GroupDetailSettingProfile;
