import React from "react";
import { MenuContainer } from "../../styled/dashboard";

const ContextMenu = ({ menuPosition, currentMenuItems, menuRef, setting }) => {
  return (
    <MenuContainer
      ref={menuRef}
      style={{ top: menuPosition.y, left: menuPosition.x }}
      className={setting.mode}>
      <ul className="contextMenu">
        {currentMenuItems.map((item, index) => (
          <li key={index} onClick={item.action}>
            {item.name}
          </li>
        ))}
      </ul>
    </MenuContainer>
  );
};

export default ContextMenu;
