import React from "react";

const ModalHeader = ({ title, handleCancel }) => {
  return (
    <div className="modalHeader">
      <h2>{title}</h2>
      <button onClick={handleCancel}>
        <i className="fa fa-times" aria-hidden="true" />
      </button>
    </div>
  );
};

export default ModalHeader;
