import React from "react";
import axios from "axios";
import { toast } from "react-toastify";

const ExcelFileActions = ({ handleAddXlsx }) => {
  const handleDownloadExcel = async () => {
    const url = "/api/excel-template";
    try {
      const res = await axios.post(url, {}, { responseType: "blob" });
      if (res.status === 200) {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "excel_template.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("엑셀 양식이 저장되었습니다.");
      } else {
        toast.error("엑셀 양식 다운로드에 실패했습니다.");
      }
    } catch (error) {
      console.error(error);
      toast.error("엑셀 양식 다운로드 중 오류가 발생했습니다.");
    }
  };

  return (
    <div className="xlsxButtons">
      <button onClick={handleDownloadExcel}>양식 내려받기</button>
      <label>
        <input type="file" onChange={handleAddXlsx} accept=".xlsx, .xls" />
        엑셀 업로드
      </label>
    </div>
  );
};

export default ExcelFileActions;
