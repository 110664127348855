import styled from "styled-components";

export const QnaTitleContainer = styled.div`
  .inner_title_menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    li {
      &:hover {
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .inner_title_menu {
      justify-content: flex-start;
      li {
        box-sizing: border-box;
        margin-bottom: -1px;
        &:nth-child(even) {
          border-left: none;
        }
      }
    }
  }
`;

export const GroupReqContents = styled.div`
  button.btn_normal {
    border: none;
  }
`;

export const ReleaseNotesContainer = styled.div`
  .section {
    margin: 100px 0;
  }
  .inner {
    margin: auto;
    max-width: 1400px;
    > ul {
      > li {
        border-radius: 20px;
        padding: 15px;
        box-sizing: border-box;
        margin: 10px 0;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        .devNotifyWrapper {
          max-height: 35px;
          overflow-y: hidden;
          transition: 0.3s;
          cursor: pointer;
          &.active {
            max-height: 200vh;
          }
          .title {
            display: flex;
            align-items: center;
            gap: 15px;
            position: relative;
            img {
              width: 35px;
              height: 35px;
              object-fit: cover;
              border-radius: 50%;
            }
            span {
              &.noticeSj {
                font-weight: 800;
              }
            }
            .date {
              color: #999;
            }
            .toggleCollapseButton {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              border: none;
              background: transparent;
              font-size: 20px;
              display: flex;
            }
            .editButton {
              position: absolute;
              right: 30px;
              top: 50%;
              transform: translateY(-50%);
              border: none;
              background: #efefef;
              padding: 5px 10px;
              display: flex;
              border-radius: 10px;
              font-size: 13px;
            }
          }
          .devNoticeContent {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #efefef;
          }
        }
        .decisionBtnArea {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          align-items: center;
          overflow: hidden;
          transition: 0.3s;
          height: 0;
          padding: 0;
          button {
            width: 50%;
            border-radius: 20px;
            border: none;
            padding: 5px;
            font-size: 14px;
            background: #efefef;
            &:first-child {
              background: #0074ca;
              color: #fff;
            }
          }
          &.inv {
            padding: 20px 0 0;
            height: 47px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .notifyInner {
      padding: 15px;
    }
    .notify .notifyBody .notifyInner .section ul li .notifyWrapper .content .infoBox .title {
      max-width: calc(100% - 60px);
    }
    .notify .notifyHeader .notifyInner .msgSetting .settingBtnArea .setMenus {
      transform: translate(0, 105%);
    }
  }
  @media screen and (max-width: 768px) {
    .notify {
      .notifyBody {
        .notifyInner {
          .section {
            > ul {
              > li {
                .notifyWrapper {
                  gap: 10px;
                  img {
                    width: 50px;
                    height: 50px;
                  }
                  .content {
                    width: calc(100% - 100px);
                    .infoBox {
                      flex-direction: column;
                      gap: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .notify .notifyHeader .notifyInner .title a img {
      width: 100px;
      height: 35px;
    }
    .notify .notifyBody .notifyInner .notifyTabMenu li {
      padding: 10px;
    }
  }
`;
