import MypageBanner from "../mypageBanner";
import MyQnaViewForm from "./component/myQnaViewForm";

export default function MyQnaView () {
  return(
    <>
      <MypageBanner />
      <MyQnaViewForm />
    </>
  )
}