import React, { useState } from "react";
import { GroupInner } from "../../styled/common";
import { useMediaQuery } from "react-responsive";
import GroupDetailFeed from "./groupDetailHome/groupDetailFeed";
import GroupDetailBlog from "./groupDetailHome/groupDetailBlog";
import { GroupDetailHomeContainer } from "../../styled/group";

const GroupDetailHome = ({ grp, cmpnys }) => {
  const [active, setActive] = useState(0);

  const tabs = ["피드", "친구소식"];

  return (
    <GroupDetailHomeContainer>
      <ul className="tabs">
        {tabs.map((item, idx) => (
          <li onClick={() => setActive(idx)} className={active === idx ? "active" : ""}>
            {item}
          </li>
        ))}
      </ul>
      {active === 0 ? <GroupDetailFeed /> : <GroupDetailBlog grp={grp} cmpnys={cmpnys} />}
    </GroupDetailHomeContainer>
  );
};

export default GroupDetailHome;
