import styled from "styled-components";

export const KioskMemuList = styled.ul`
  @font-face {
    font-family: "JSArirangHON-Regular";
    src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/JSArirangHON-RegularA1.woff")
      format("woff");
    font-weight: normal;
    font-style: normal;
  }
  font-family: "JSArirangHON-Regular", sans-serif;
  display: flex;
  flex-wrap: wrap;
  gap: 1.3889vw;
  font-size: 5rem;
  width: 100%;
  height: 100vh;
  position: relative;
  justify-content: center;
  overflow: hidden;
  li {
    border-radius: 20px;
    transition: 0.3s;
    .content {
      position: relative;
      width: 100%;
      height: 100%;
    }
    &:hover {
      transform: scale(1.1);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      z-index: 3;
    }
    span {
      color: #333;
      position: absolute;
      width: 100%;
      transform: translateX(-50%);
      left: 50%;
      bottom: 4.8828vh;
      text-align: center;
      word-wrap: break-word;
    }
    img {
      max-width: 70%;
      height: 24.4141vh;
      object-fit: contain;
      position: absolute;
      left: 50%;
      top: 4.8828vh;
      transform: translateX(-50%);
      user-select: none;
    }
    &:nth-child(1),
    &:nth-child(2) {
      height: 49.5117vh;
    }
    &:nth-child(3),
    &:nth-child(4) {
      height: calc(100vh - 49.5117vh - 1.3889vw);
    }
    &:nth-child(1) {
      width: calc(60.5556vw - 5vw);
      background: #ede3fe;
      height: 49.5117vh;
    }
    &:nth-child(2) {
      width: calc(37.9861vw - 5vw);
      background: #ade3ff;
      height: 49.5117vh;
    }

    &:nth-child(3) {
      width: calc(38.75vw - 5vw);
      background-color: #aed3ff;
      background-image: linear-gradient(rgba(174, 211, 255, 0.5), rgba(174, 211, 255, 0.5)),
        url("/img/sungsimdang/sungsimdang_character.png");
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      /* .content {
        span {
          top: 2.0833vw;
          left: 2.0833vw;
          text-align: left;
          transform: none;
          font-size: 6.25vw;
        }
        .customers {
          position: absolute;
          bottom: 2.0833vw;
          right: 2.0833vw;
          color: #333;
          font-size: 8.33vw;
        }
      } */
    }
    &:nth-child(4) {
      width: calc(59.8611vw - 5vw);
      background: #d7faf4;
    }
  }
  @media screen and (min-width: 1401px) {
    li.calculate .content {
      span {
        font-size: 90px;
      }
      .customers {
        font-size: 110px;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    li span {
      font-size: 4.3rem;
    }
  }
  @media screen and (max-width: 820px) {
    li {
      span {
        bottom: 3.3704vh;
      }
      &:nth-child(1),
      &:nth-child(4) {
        width: calc(100vw - 10vw);
        height: 30.2778vh;
        img {
          height: 13.8889vh;
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        width: calc((100% - 1.3889vw) / 2 - 5vw);
        height: 37.5vh;
        img {
          height: 13.8889vh;
        }
      }
    }
  }
  @media screen and (max-width: 530px) {
    li span {
      font-size: 40px;
    }
  }
`;
