import React from "react";
import { Link } from "react-router-dom";
const HomepageSubMenu = ({ activeMenu, handleChangeComponent }) => {
  return (
    <div className="inner_title">
      <ul className="inner_title_menu">
        {homepageMenu.map((item, i) => (
          <li
            className={activeMenu === item.link ? "actived" : ""}
            key={i}
            onClick={() => handleChangeComponent(item.link)}
          >
            <Link>{item.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HomepageSubMenu;