import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import axios from "axios";
import uuid from "react-uuid";
import moment from "moment";

import { IoMdMenu } from "react-icons/io";
import { FiMoon, FiSun } from "react-icons/fi";

import { getSetting } from "../../../store/scheduler";
import { hideLoading, showLoading } from "../../../store/loading";

import { SchedulerPageContainer, MobileMenu } from "./component/styled/scheduler";

import {
  isSameDay,
  modalPosition,
  eventsSorted,
  keysToRemove,
  getProjectMbers,
} from "./component/function/scheduler";
import { isSameUser, isEventAllTime, hexToRgb } from "./component/function/common";
import { holidayList, weatherStates } from "./component/function/dateInfo";

import Agenda from "./component/scheduler/agenda";
import Calendar from "./component/scheduler/calendar";
import EventLayer from "./component/scheduler/eventLayer";
import Header from "./component/scheduler/header";
import SideMenu from "./component/scheduler/sideMenu";
import Modal from "../../../common/modal";
import Loading from "../../../common/loading";
import ModalContent from "./component/common/modalContent";
import HamburgerMenu from "./component/scheduler/hamburgerMenu";
import HolidayLayer from "./component/scheduler/holidayLayer";

const Scheduler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const user = useSelector((state) => state?.user?.data[0]);
  const isLoading = useSelector((state) => state?.loading?.isLoading);
  const { mySchedSet, isDarkMode } = useSelector((state) => state.scheduler);
  const calendarRef = useRef(null);
  const sideCalendarRef = useRef(null);

  const [modalMsg, setModalMsg] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [onDate, setOnDate] = useState();
  const [onPrevDate, setOnPrevDate] = useState(null);
  const [selected, setSelected] = useState([]);
  const [eventPosition, setEventPosition] = useState({ top: 0, right: 0 });
  const [showEvent, setShowEvent] = useState(false);
  const [eventInfo, setEventInfo] = useState({});
  const [dndEventInfo, setDndEventInfo] = useState({});
  const [data, setData] = useState([]);
  const [userDate, setUserDate] = useState({
    year: Number(moment().format("YYYY")),
    month: Number(moment().format("MM")),
  });
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [showHoliday, setShowHoliday] = useState(false);
  const [holiday, setHoliday] = useState({});
  const [weather, setWeather] = useState([]);
  const [todayWeather, setTodayWeater] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [images, setImages] = useState([]);
  const [originalImages, setOriginalImages] = useState([]);

  useEffect(() => {
    if (user) {
      getWeather();
      initSettings();
    }

    const target = moment();
    const today = target.format("YYYY-MM-DD");
    setOnDate(today);

    if (location.search) {
      setIsSearch(true);
    }
  }, []);

  useEffect(() => {
    if (user && mySchedSet) {
      getSchedule();
    }
  }, [
    mySchedSet.companyYnList,
    mySchedSet.groupYnList,
    mySchedSet.completeYn,
    mySchedSet.personalYn,
  ]);

  const initSettings = async () => {
    const url = "/api/toktokSettingList";
    const req = {
      mberNo: user.mberNo,
    };
    const res = await axios.post(url, req);
    if (res.request.status === 200) {
      dispatch(getSetting(res.data));
    }
  };

  // 캘린더 일정 조회
  const getSchedule = async () => {
    dispatch(showLoading());

    try {
      const filterOrg = (org) => {
        if (!org) return [];
        return org.filter((item) => item.check).map((item) => item.orgNo);
      };

      const url = "/api/scheduleList";
      const body = {
        mberNo: user.mberNo,
        showPersonalYn: mySchedSet.personalYn ? "Y" : "N",
        cmpnyNos: filterOrg(mySchedSet.companyYnList),
        groupNos: filterOrg(mySchedSet.groupYnList),
        completeYn: mySchedSet.completeYn || "D",
      };
      const res = await axios.post(url, body);
      const schedule = res.data;
      await transformEvents(schedule);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  // 날씨 정보 가져오기
  const getWeather = async () => {
    const url = "/api/weather";
    const req = {};
    const res = await axios.post(url, req);
    if (res.request.status === 200) {
      setWeather(res.data);
      updateWeather(moment(), res.data);
    }
  };

  // FullCalendar 형식 맞추기
  const transformEvents = async (origin) => {
    const transformData = origin.map((item) => {
      let end = moment(item.endDt);
      if (end.format("HH:mm:ss") === "00:00:00") {
        end = end.add(1, "seconds");
      }

      const opacity = item.completeYn === "Y" ? 0.7 : 0.8;
      const gbnColor = {
        P: item.color,
        C: "0074CA",
        G: "F46F02",
      };
      const selectedColor = gbnColor[item.gbn] || "000000";
      const color = `rgba(${hexToRgb(selectedColor)}, ${opacity})`;

      const event = {
        ...item,
        backgroundColor: color,
        borderColor: color,
        id: item.scheduleNo,
        start: item.startDt,
        allDay: false,
        end: end.format("YYYY-MM-DD HH:mm:ss"),
      };

      if (isEventAllTime(item)) {
        event.display = "block";
      }

      return event;
    });

    setData(transformData);
    isToday(transformData);
  };

  // 오늘 날짜 데이터 가져오기
  const isToday = (events) => {
    const target = moment(onDate);

    const data = events.filter((event) => {
      const start = moment(event.startDt);
      const end = event.endDt ? moment(event.endDt) : null;
      return isSameDay(target, start, end);
    });
    const sorted = eventsSorted(data);

    setSelected(sorted);
  };

  // 클릭 시 해당 날짜 배경색 변경
  useEffect(() => {
    if (onDate) {
      updateDate(onDate);
    }
  }, [onDate]);

  const updateDate = (onDate) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(onDate);
    }
    if (sideCalendarRef.current) {
      const sideCalendarApi = sideCalendarRef.current.getApi();
      sideCalendarApi.gotoDate(onDate);
    }

    const prevDateElements = document.querySelectorAll(
      `[data-date="${moment(onPrevDate).format("YYYY-MM-DD")}"]`,
    );
    const currentDateElements = document.querySelectorAll(
      `[data-date="${moment(onDate).format("YYYY-MM-DD")}"]`,
    );

    prevDateElements.forEach((item) => item.classList.remove("selected"));
    currentDateElements.forEach((item) => item.classList.add("selected"));

    setOnPrevDate(onDate);
  };

  // 클릭한 날짜 날씨
  const updateWeather = (date, data) => {
    const today = moment(date).format("YYYY-MM-DD");
    const isWeather = data.filter((item) => item.date === today);

    if (isWeather.length) {
      setTodayWeater(isWeather[0]);
    } else {
      setTodayWeater(null);
    }
  };

  // 날짜 클릭
  const handleDateClick = async (arg, isSearchReset = false) => {
    const target = moment(arg);

    let sameDate = data.filter((event) =>
      isSameDay(target, moment(event.startDt), event.end ? moment(event.endDt) : null),
    );

    if (isSearch && !isSearchReset && searchTxt) {
      sameDate = sameDate.filter((event) => event.title.includes(searchTxt));
    }
    const sorted = eventsSorted(sameDate);

    setSelected(sorted);
    setOnDate(arg);
    updateWeather(arg, weather);

    const [year, month] = target.format("YYYY-MM").split("-");
    setUserDate({
      year: Number(year),
      month: Number(month),
    });
  };

  // 컴포넌트 애니메이션
  const toggleAgendaContent = (flag) => {
    const agenda = document.querySelector(".agenda").style;
    agenda.transform = `translateY(${!isTablet ? "0" : flag ? "0" : "150%"})`;
  };
  const toggleSideMenu = (flag) => {
    const sidemenu = document.querySelector(".sidemenu").style;
    sidemenu.transform = `translateX(${!isMobile ? "0" : flag ? "0" : "-150%"})`;
  };

  // 이벤트 클릭
  const handleEventClick = (item) => {
    const classNames = Array.from(item.classList);
    const containsHoliday = classNames.some((name) => name.includes("holiday"));

    if (containsHoliday) {
      return;
    }

    const event = getIdFromClass(item);
    if (!event) return;

    if (isTablet) {
      navigate(`/mypage/detailEvent/${event.scheduleNo}`);
      return;
    }

    setEventInfo(event);

    const rect = item.getBoundingClientRect();
    const position = modalPosition(rect, event, user);

    onEventModal(position);
  };

  // 클래스명과 일치하는 데이터 찾기
  const getIdFromClass = (item) => {
    const classNameMatch = item.className.match(/schedule-(\d+)/);

    let id;
    if (classNameMatch) {
      id = parseInt(classNameMatch[1]);

      const event = data.find((item) => item.scheduleNo === id);
      return event;
    }
    return null;
  };

  // 이벤트 드래그앤드롭
  const handleEventDrag = (info) => {
    setDndEventInfo(info);

    const event = getIdFromClass(info.el);
    if (!event) {
      info.revert();
      return;
    }

    if (!isSameUser(user, event)) {
      info.revert();
      toast.error("작성자가 아닙니다.");
      return;
    } else {
      handleModalOpen("eventDrop");
    }
  };

  const handleModalOpen = (msg) => {
    setModalMsg(msg);

    if (msg) {
      setModalOpen(true);
    }
  };

  // 이벤트 드래그앤드롭 취소/확인 버튼
  const handleEventDrop = (flag) => {
    if (flag) {
      dndEventInfo.revert();
    } else {
      const event = getIdFromClass(dndEventInfo.el);
      if (!event) return;

      const { delta } = dndEventInfo;

      const calculateNewDate = (date) => {
        return moment(date)
          .add(delta.days, "days")
          .add(delta.months, "months")
          .add(delta.years, "years")
          .format("YYYY-MM-DD HH:mm:ss");
      };

      const newStart = calculateNewDate(event.startDt);
      const newEnd = calculateNewDate(event.endDt);

      event.startDt = newStart;
      event.endDt = newEnd;

      submitEventDrop(event);
    }
    setDndEventInfo({});
  };

  // 이벤트 esc, 영역 밖 클릭 시
  const handleModalClose = () => {
    if (dndEventInfo && dndEventInfo.revert) {
      dndEventInfo.revert();
    }
    setModalOpen(false);
  };

  // agenda 이벤트 커스텀 모달
  const onEventModal = (position) => {
    setShowEvent(true);
    setEventPosition({ y: position.top, x: position.left });
  };

  // 모달 확인 버튼
  const handleModalConfirm = () => {
    if (modalMsg === "eventDrop") {
      handleEventDrop(false);
    }
    if (modalMsg === "eventDel") {
      submitDelete();
    }
    if (modalMsg === "eventTodoCopy") {
      handleScheduleCopy(true);
    }
    if (modalMsg === "imgUpload") {
      uploadImages(true);
    }
    setModalOpen(false);
  };

  // 모달 취소 버튼
  const handleModalCancel = () => {
    if (modalMsg === "eventDrop") {
      handleEventDrop(true);
    }
    if (modalMsg === "eventTodoCopy") {
    }
    if (modalMsg === "eventTodoCopy") {
      handleScheduleCopy();
    }
    if (modalMsg === "imgUpload") {
      setImages([]);
      setOriginalImages([]);
      setShowEvent(false);
    }
    setModalOpen(false);
  };

  // 모달 확인 - 일정 완료
  const handleEventComplete = async (state) => {
    dispatch(showLoading());
    const url = "/api/schedule";
    const body = keysToRemove(eventInfo);
    body.completeYn = state;

    const res = await axios.put(url, body);
    if (res.status === 200) {
      getSchedule();
      setEventInfo({ ...eventInfo, completeYn: state });
    }
  };

  // 모달 확인 - 이벤트 드래그앤드롭
  const submitEventDrop = async (eventInfo) => {
    const url = "/api/schedule";
    const body = keysToRemove(eventInfo);

    if (body.workerList && Object.keys(body.workerList).length !== 0) {
      const workers = Object.keys(body.workerList);
      body.workerNos = workers;
    }

    const res = await axios.put(url, body);
    if (res.status === 200) {
      toast.success("일정이 변경되었습니다.");
      setShowEvent(false);
      getSchedule();
    }
  };

  // 모달 확인 - 이벤트 삭제
  const submitDelete = async () => {
    const url = "/api/schedule";
    const body = keysToRemove(eventInfo);
    body.delYn = "Y";

    const res = await axios.put(url, body);
    if (res.status === 200) {
      toast.success("일정이 삭제되었습니다.");
      setShowEvent(false);
      getSchedule();
    }
  };

  // 시스템 화면 모드 변경
  const setScreenMode = async (screenMode) => {
    const url = "/api/toktokSetting";
    const body = {
      ...mySchedSet,
      screenMode,
    };
    const res = await axios.put(url, body);
    if (res.status === 200) {
      dispatch(getSetting(body));
    }
  };

  // 날씨 가져오기
  const getWeatherForDate = (info) => {
    const date = moment(info.date).format("YYYY-MM-DD");

    const matchedDate = weather.find((item) => item.date === date);

    if (matchedDate) {
      return isWeather(matchedDate.weather);
    } else {
      return <></>;
    }
  };

  const isWeather = (weather) => {
    const state = weatherStates[weather];
    if (!state) return <></>;

    const Icon = state.icon;
    return (
      <>
        <Icon style={{ color: state.color }} />
      </>
    );
  };

  // 공휴일 및 날짜 출력
  const displayDateInfo = (info) => {
    const current = info.date;
    const month = current.getMonth() + 1;
    const day = current.getDate();
    let target = holidayList.find((h) => h.month === month && h.day === day);

    if (!target) {
      target = holidayList.find((h) => {
        const startDt = moment(h.startDt);
        const endDt = moment(h.endDt);
        return current >= startDt && current <= endDt;
      });
    }
    const dateFormatted = moment(current).format("D");

    return target ? (
      <>
        <div
          onClick={() => isHolidayOpen(target)}
          className="holiday"
          style={{ color: isDarkMode ? "#efefef" : "#1b1b1b" }}>
          {target.icon && <img src={target.icon} alt="" />}
          {target.name}
        </div>
        <div className="date">
          <div>{getWeatherForDate(info)}</div>
          <span
            style={{
              color: target.isPublicHoliday && "#ca0000",
            }}>
            {dateFormatted}
          </span>
        </div>
      </>
    ) : (
      <>
        <div></div>
        <div className="date">
          <div>{getWeatherForDate(info)}</div>
          <span>{dateFormatted}</span>
        </div>
      </>
    );
  };

  // 공휴일 팝업
  const isHolidayOpen = (target = null) => {
    if (target) {
      setHoliday(target);
    }
    if (isTablet) {
      toggleAgendaContent(false);
    }
    setShowHoliday((holiday) => !holiday);
  };

  // Agenda/SearchEvent 일정 클릭
  const clickEvent = async (item, searchTxt = "") => {
    setEventInfo(item);

    if (isTablet) {
      if (searchTxt) {
        const encoded = encodeURIComponent(searchTxt);
        navigate(`/mypage/detailEvent/${item.scheduleNo}?keyword=${encoded}`);
      } else {
        navigate(`/mypage/detailEvent/${item.scheduleNo}`);
      }
    } else {
      const position = {
        left: (window.innerWidth - 400) / 2,
        top: (window.innerHeight - 350) / 2,
      };
      onEventModal(position);
    }
  };

  // 일정 복사
  const handleScheduleCopy = async (flag = false) => {
    const item = keysToRemove(eventInfo, ["completeYn", "mberNo", "scheduleNo"]);

    let body = { ...item, mberNo: user.mberNo };

    if (item.projectNo) {
      const isExist = await getProjectMbers(item.projectNo, user);
      console.log(isExist);

      if (!isExist) {
        body = {
          ...body,
          workerList: null,
          managerNo: null,
          managerNm: "",
          projectNo: null,
          projectNm: null,
        };
      }
    }

    if (flag) {
      body = {
        ...body,
        todo: (body.todo || []).map((item) => ({
          ...item,
          todoNo: null,
          tempId: uuid(),
          completeYn: "N",
          delYn: "N",
        })),
      };
    } else {
      body.todo = [];
    }

    navigate("/mypage/calendarEntry", {
      state: { eventInfo: body, infoState: "copy" },
    });
  };

  // 이미지 업로드
  const uploadImages = async (isCloseModal = false) => {
    if (originalImages.length < 1) {
      toast.error("업로드 할 이미지가 존재하지 않습니다.");
      return;
    }

    try {
      const formData = new FormData();
      originalImages.forEach((image) => formData.append("images", image));

      if (eventInfo.projectNo) {
        formData.append("gbn", "P");
      } else {
        formData.append("gbn", "S");
      }
      formData.append("refeNo", eventInfo.scheduleNo);
      formData.append("mberNo", user.mberNo);

      const response = await axios.post("/api/photos", formData, {});

      if (response.status === 200) {
        toast.success("이미지가 성공적으로 업로드되었습니다.");
        console.log(isCloseModal);
        if (isCloseModal) {
          setImages([]);
          setShowEvent(false);
        } else {
          getImages();
        }
        setOriginalImages([]);
      } else {
        console.error("업로드 실패", response.data);
      }
    } catch (error) {
      console.error("업로드 중 에러 발생", error);
    }
  };

  // 이미지 조회
  const getImages = async () => {
    const url = "/api/photoList";
    const body = {
      refeNo: eventInfo.scheduleNo,
    };

    const res = await axios.post(url, body);
    setImages(res.data);
  };

  return (
    <SchedulerPageContainer className={isDarkMode} onContextMenu={(e) => e.preventDefault()}>
      <Header setScreenMode={setScreenMode} />
      <div className="inner_content" id="schduler_content">
        {isMobile && !isSearch && (
          <MobileMenu className={isDarkMode}>
            <button onClick={() => toggleSideMenu(true)}>
              <IoMdMenu />
            </button>
            <button onClick={() => setScreenMode(isDarkMode ? 0 : 1)}>
              {isDarkMode ? <FiSun /> : <FiMoon />}
            </button>
          </MobileMenu>
        )}
        <SideMenu
          sideCalendarRef={sideCalendarRef}
          data={data}
          handleDateClick={handleDateClick}
          userDate={userDate}
          setUserDate={setUserDate}
          toggleSideMenu={toggleSideMenu}
          toggleAgendaContent={toggleAgendaContent}
          onDate={onDate}
        />
        <Calendar
          calendarRef={calendarRef}
          data={data}
          setOnPrevDate={setOnPrevDate}
          handleDateClick={handleDateClick}
          handleEventClick={handleEventClick}
          handleEventDrag={handleEventDrag}
          showEvent={showEvent}
          toggleAgendaContent={toggleAgendaContent}
          onDate={onDate}
          isBurgerOpen={isBurgerOpen}
          setIsBurgerOpen={setIsBurgerOpen}
          displayDateInfo={displayDateInfo}
          clickEvent={clickEvent}
          isSearch={isSearch}
          setIsSearch={setIsSearch}
          searchTxt={searchTxt}
          setSearchTxt={setSearchTxt}
        />
        <Agenda
          selected={selected}
          toggleAgendaContent={toggleAgendaContent}
          isTablet={isTablet}
          onEventModal={onEventModal}
          setEventInfo={setEventInfo}
          onDate={onDate}
          isBurgerOpen={isBurgerOpen}
          setIsBurgerOpen={setIsBurgerOpen}
          isHolidayOpen={isHolidayOpen}
          todayWeather={todayWeather}
          clickEvent={clickEvent}
        />
        {showEvent && (
          <EventLayer
            eventPosition={eventPosition}
            setShowEvent={setShowEvent}
            eventInfo={eventInfo}
            handleModalOpen={handleModalOpen}
            handleEventComplete={handleEventComplete}
            getSchedule={getSchedule}
            setEventInfo={setEventInfo}
            handleScheduleCopy={handleScheduleCopy}
            searchTxt={searchTxt}
            images={images}
            setImages={setImages}
            originalImages={originalImages}
            setOriginalImages={setOriginalImages}
            uploadImages={uploadImages}
            getImages={getImages}
          />
        )}
        {showHoliday && <HolidayLayer holiday={holiday} isHolidayOpen={isHolidayOpen} />}
        {!isTablet && (
          <HamburgerMenu
            onDate={moment(onDate).format("YYYY-MM-DD hh:00:00")}
            isBurgerOpen={isBurgerOpen}
            setIsBurgerOpen={setIsBurgerOpen}
          />
        )}
      </div>
      <Modal
        modalState={modalOpen}
        handleModalState={handleModalClose}
        html={
          <ModalContent
            modalMsg={modalMsg}
            handleConfirm={handleModalConfirm}
            handleCancel={handleModalCancel}
          />
        }
        isDarkMode={isDarkMode}
        w="300px"
        h="auto"
      />
      {isLoading && <Loading />}
    </SchedulerPageContainer>
  );
};

export default Scheduler;
