import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { SwiperContainer } from "../../component/styled/group";
import { useMediaQuery } from "react-responsive";

const GroupVisualSwiper = () => {
  const arr = Array.from({ length: 2 });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  return (
    <SwiperContainer style={{ height: isMobile ? "auto" : "400px" }}>
      <Swiper
        modules={[Navigation]}
        slidesPerView={1}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        loop={true}
        speed={2000}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}>
        {arr.map((_, i) => (
          <SwiperSlide key={i}>
            <div className="swiper-wrapper">
              <div className="slide swiper-slide" id={i}>
                <div className="slide_text_warp text_center">
                  <img src={`/img/main_bg${i + 1}.jpg`} alt="" />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-button-prev swiper-button-white" style={{ left: "50px" }}></div>
      <div className="swiper-button-next swiper-button-white" style={{ right: "50px" }}></div>
    </SwiperContainer>
  );
};

export default GroupVisualSwiper;
