import React, { useState } from "react";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { GoAlertFill } from "react-icons/go";
import Modal from "../../../../../../common/modal";
import { DeleteGrpContainer } from "../../../styled/group";

const GroupDetailSettingDelete = ({ grp, setGrp }) => {
  const [isDelete, setIsDelete] = useState(grp.delDt ? true : false);
  const [isDelConfirm, setIsDelConfirm] = useState(false);
  const user = useSelector((state) => state?.user?.data[0]);
  const [modalOpen, setModalOpen] = useState(false);

  // 삭제 및 되돌리기
  const onSubmit = async (state) => {
    if (!isDelConfirm && !isDelete) {
      toast.error("삭제 절차에 동의하셔야 삭제가 진행됩니다.");
      return;
    }

    const url = "/api/group";
    const body = {
      ...grp,
      mberNo: user.mberNo,
      mberNm: user.userName,
    };

    if (state === "delete") {
      body.delDt = moment().add(7, "days").format("YYYY-MM-DD HH:mm:ss");
    } else {
      body.delDt = null;
    }

    const res = await axios.put(url, body);
    const data = res.data;
    if (data.success && Object.keys(data.rtnModel).length > 0) {
      toast.success(`삭제 신청이${isDelete ? " 취소" : " "}되었습니다.`);
      setGrp({ ...data.rtnModel });
      window.scroll(0, 0);
      // tab(0);
    }
  };

  // 모임 삭제 모달
  const exitModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">모임 삭제{isDelete ? " 취소" : ""}</h2>
        <button onClick={handleExitModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_20 font300 font_color_gray pdt_20 text_center">
          모임 삭제를 {isDelete ? "취소" : "신청"}하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={() => onSubmit(isDelete ? "cancel" : "delete")}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const handleExitModal = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  return (
    <DeleteGrpContainer>
      <div className="msg">
        <div className="title">
          <GoAlertFill />
          <span>모임 삭제 안내</span>
        </div>
        {isDelete ? (
          <div className="info">
            <p>
              귀하의 모임 삭제 요청이 <strong>성공적으로 접수</strong>되었습니다.
            </p>
            <p>
              현재 모임은 <strong>유예 기간</strong>에 있습니다.
            </p>
            <p>
              이 기간 동안에는 언제든지 삭제 요청을 <strong>철회</strong>하실 수 있습니다.
            </p>
            <p>
              유예 기간이 끝나면, 모임은 <strong>영구적으로 삭제</strong>되며
            </p>
            <p>
              복구는 <strong>불가능</strong>해집니다.
            </p>
          </div>
        ) : (
          <div className="info">
            <p>선택한 모임에 대한 삭제 요청 시</p>
            <p>
              <strong>일주일간의 유예 기간</strong>이 주어집니다.
            </p>
            <p>
              이 기간 동안 삭제 요청을 <strong>취소</strong>할 수 있고
            </p>
            <p>모임 활동을 계속 이어갈 수 있습니다.</p>
            <p>
              유예 기간 종료 후, 모임은 <strong>영구적으로 삭제</strong>되며
            </p>
            <p>
              복구는 <strong>불가능</strong>합니다.
            </p>
          </div>
        )}
      </div>
      <div className="confirm">
        {isDelete ? (
          <span>삭제 예정일 {moment(grp.delDt).format("YYYY년 MM월 D일 HH시 mm분")}</span>
        ) : (
          <label>
            <input
              type="checkbox"
              checked={isDelConfirm}
              onChange={(e) => setIsDelConfirm(e.target.checked)}
            />
            <span>위 내용을 모두 확인하였으며, 모임 삭제 절차에 동의합니다.</span>
          </label>
        )}
      </div>

      <div className="btnArea">
        <button onClick={handleExitModal}>삭제 {isDelete ? "취소" : "신청"}</button>
      </div>
      <Modal
        modalState={modalOpen}
        handleModalState={handleExitModal}
        html={exitModalForm()}
        w="300px"
        h="auto"
      />
    </DeleteGrpContainer>
  );
};

export default GroupDetailSettingDelete;
