import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

const Calendar = ({ calendarRef, handleEventClick, events }) => {
  const eventOrder = (a, b) => {
    const order = ["card", "cash", "app", "total"];
    return order.indexOf(a.extendedProps.payType) - order.indexOf(b.extendedProps.payType);
  };

  return (
    <div className="calendar">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        locale={"ko"}
        events={events}
        eventClick={(info) => handleEventClick(info.event.startStr)}
        dateClick={(info) => handleEventClick(info.dateStr)}
        eventClassNames={(info) => info.event.id}
        eventOrder={(a, b) => eventOrder(a, b)}
        // format={{ day: "numeric" }}
        dayCellContent={(dateInfo) => dateInfo.date.getDate()}
      />
    </div>
  );
};

export default Calendar;
