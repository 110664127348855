import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";

import { ProjectDetailViewContainer } from "../common/styled/project";

import ProjectDetailInfo from "./projectDetailView/projectDetailInfo";
import ProjectDetailDashboard from "./projectDetailView/projectDetailDashboard";
import ProjectDetailParticipants from "./projectDetailView/projectDetailParticipants";
import ProjectDetailFile from "./projectDetailView/projectDetailFile";

import ProjectDetailTabMenu from "./projectDetailView/projectDetailTabMenu";
import ProjectScheduler from "./projectDetailView/projectScheduler";
import ProjectCalendar from "./projectDetailView/projectCalendar";
import ProjectDetailParticipantModal from "./projectDetailView/projectDetailParticipants/projectDetailParticipantModal";

const ProjectDetailView = ({
  project,
  setProject,
  projectNotice,
  participantList,
  projectInfo,
  active,
  setActive,
  openMenu,
  setOpenMenu,
  handleModalOpen,
  getParticipantList,
  participant,
  projectRoomList,
  getProjectRoom,
  setShowSideMenu,
  showSideMenu,
  schedule,
}) => {
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);

  // 탭 메뉴 변경
  const tabChange = (idx) => setActive(idx);

  // 컴포넌트
  function Components({ index }) {
    return (
      <>
        {
          [
            <ProjectDetailDashboard
              project={project}
              projectNotice={projectNotice}
              projectInfo={projectInfo}
              projectRoomList={projectRoomList}
            />,
            <ProjectCalendar schedule={schedule} />,
            <ProjectScheduler
              schedule={schedule}
              project={project}
              participantList={participantList}
            />,
            <ProjectDetailFile project={project} />,
            null,
          ][index]
        }
      </>
    );
  }

  return (
    <ProjectDetailViewContainer>
      <ProjectDetailInfo
        project={project}
        setProject={setProject}
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        handleModalOpen={handleModalOpen}
        projectInfo={projectInfo}
        setShowSideMenu={setShowSideMenu}
        showSideMenu={showSideMenu}
        setShowParticipants={setShowParticipants}
        showParticipants={showParticipants}
      />
      <div className="projectInfoBody">
        <div className="content">
          <ProjectDetailTabMenu tabChange={tabChange} />
          {<Components index={active} />}
        </div>
        <ProjectDetailParticipants
          participantList={participantList}
          project={project}
          participant={participant}
          setIsInviteOpen={setIsInviteOpen}
          isInviteOpen={isInviteOpen}
          showParticipants={showParticipants}
          setShowParticipants={setShowParticipants}
        />
        {isInviteOpen && (
          <ProjectDetailParticipantModal
            setIsInviteOpen={setIsInviteOpen}
            project={project}
            participantList={participantList}
            getParticipantList={getParticipantList}
            getProjectRoom={getProjectRoom}
          />
        )}
      </div>
    </ProjectDetailViewContainer>
  );
};

export default ProjectDetailView;
