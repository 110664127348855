import styled from "styled-components";

export const CompanyInfoFormContainer = styled.div`
  .inputxt_normal {
    display: flex;
  }
  .writing_list li.half_li input {
    max-width: none;
  }
  .inputxt_normal .btn_line {
    float: none;
  }
  .inputxt_normal #wk_addr1,
  .inputxt_normal #wk_addr2,
  .inputxt_normal #wk_addr3 {
    width: 100%;
  }
  @media screen and (min-width: 1051px) {
    .writing_list li .writing_tit {
      text-indent: 10px;
    }
    .writing_list li {
      padding-right: 10px;
    }
  }
`;
