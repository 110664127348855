import styled from "styled-components";

export const ModernHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  font-family: "Pretendard";
  &.custom.theme {
    position: relative;
    padding: 20px 0 0;
    align-items: center;
  }
  h1.logo_wrapper {
    width: 200px;
    height: 100px;
    line-height: 100px;
  }
  ul {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: normal;
  }
  .gnb nav ul,
  .tnb ul {
  }
  .header_content {
    border-radius: 0 0 0 100px;
    display: flex;
    align-items: center;
    width: calc(100% - 250px);
    justify-content: space-between;
    .actions ul {
      gap: 15px;
      align-items: center;
      li {
        display: flex;
      }
    }
    .gnb nav ul {
      gap: 70px;
      li {
        font-size: 20px;
      }
    }
    .tnb ul {
      font-size: 14px;
      gap: 30px;
    }
  }
  @media screen and (max-width: 1050px) {
    .header_content {
      justify-content: end;
      gap: 40px;
      ul {
        gap: 20px;
      }
      .gnb {
        display: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    &.custom.theme {
      padding: 10px 20px;
      .logo_wrapper {
        width: 100px;
        height: 50px;
        line-height: 0;
      }
      .tnb {
        display: none;
      }
    }
  }
`;

export const ModernContainer = styled.main`
  .info {
    font-family: "Prata", "Pretendard";
    font-size: 100px;
    margin-bottom: 60px;
    span {
      display: block;
      font-size: 30px;
      text-indent: 50px;
    }
  }
  .introduce {
    max-width: 1400px;
    width: 100%;
    margin: 180px auto;
    .introduceContent {
      cursor: pointer;
      width: 100%;
      max-width: 1200px;
      height: 335px;
      overflow: hidden;
      border-radius: 0 335px 335px 0;
      border: 1px solid rgba(192, 165, 165, 0.5);
      margin: 0 0 60px auto;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
      &:nth-child(2) .content {
        top: 0;
        left: 0;
      }
      &:nth-child(3) {
        margin: 0 auto 0 0;
        border-radius: 335px 0 0 335px;
        background: rgba(192, 165, 165, 0.5);
        .content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: end;
          justify-content: center;
          text-align: right;
          padding-right: 80px;
          font-size: 60px;
          font-weight: 700;
          span {
            width: 100%;
            height: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            font-weight: 700;
          }
        }
      }
    }
  }
  .products {
    max-width: 1400px;
    width: 100%;
    margin: 180px auto;
    .productSwiper {
      height: 400px;
      width: 100%;
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
      .swiper-slide {
        padding: 10px;
        &:nth-child(odd) {
          .productSlide {
            padding-bottom: 60px;
          }
        }
        &:nth-child(even) {
          .productSlide {
            padding-top: 60px;
          }
        }
        .box {
          width: 100%;
          height: 100%;
          border-radius: 300px 300px 0 0;
          overflow: hidden;
          border: 2px solid #a79277;
          user-select: none;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .actions {
      position: relative;
      height: 70px;
      font-size: 30px;
      margin-top: 90px;
      .swiperAction {
        width: 200px;
        height: 70px;
        background: #a79277;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        button {
          border: none;
          background: transparent;
          svg {
            display: block;
            path {
              stroke: #fff;
            }
          }
        }
      }
      .more {
        position: absolute;
        top: 0;
        right: 0;
        height: 70px;
        width: 300px;
        background: #fff2e1;
        border-radius: 70px;
      }
    }
  }
  .portfolio {
    padding: 70px 0 200px;
    background: #fff2e1;
    .inner {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    .info {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto 60px;
    }
    .left {
      width: 800px;
      height: 600px;
      border-radius: 0 300px 0 300px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }
    .right {
      text-align: right;
      button {
        font-size: 170px;
        border: none;
        background: transparent;
        margin-bottom: 200px;
        svg {
          display: block;
        }
      }
      .portfolioInfo {
        .tit {
          display: block;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 30px;
        }
        .content {
          font-size: 20px;
        }
      }
    }
  }
  .news {
    max-width: 1400px;
    width: 100%;
    margin: 180px auto;
    .newsSwiper {
      width: 100%;
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
      .newsSlide {
        width: 100%;
      }
      .newsInfo {
        width: 100%;
        cursor: pointer;
        span {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          &:first-child {
            font-weight: 700;
            font-size: 24px;
            margin: 30px 0;
          }
          &:last-child {
            font-size: 16px;
            color: #6a6a6a;
          }
        }
      }
    }
    .actions {
      margin-top: 70px;
      text-align: center;
      button {
        background: #a79277;
        font-size: 40px;
        padding: 15px 25px;
        svg {
          display: block;
          path {
            stroke: #fff;
          }
        }
        &:first-child {
          border-radius: 40px 0 0 40px;
        }
        &:last-child {
          border-radius: 0 40px 40px 0;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .introduce,
    .products,
    .portfolio .info,
    .portfolio .inner,
    .news {
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 1050px) {
    margin-bottom: 100px;
    .info {
      font-size: 32px;
      margin-bottom: 40px;
      span {
        text-indent: 0;
        font-size: 12px;
      }
    }
    .introduce {
      max-width: 100%;
      margin: 50px auto;
      .introduceContent {
        width: 100%;
        height: 110px;
        &:nth-child(3) .content {
          padding-right: 20px;
          span {
            font-size: 14px;
          }
        }
      }
    }
    .products {
      position: relative;
      margin: 50px auto;
      .productSwiper {
        height: 300px;
        .swiper-slide .productSlide {
          &:nth-child(odd),
          &:nth-child(even) {
            padding: 0;
          }
        }
      }
      .actions {
        position: static;
        margin-top: 30px;
        .swiperAction {
          position: relative;
          transform: none;
          left: 0;
          margin-left: auto;
          width: 100px;
          height: 30px;
          font-size: 14px;
          gap: 5px;
        }
        .more {
          width: 70px;
          height: 20px;
          font-size: 12px;
        }
      }
    }
    .portfolio {
      padding: 20px 0 40px;
      position: relative;
      .left {
        width: 50%;
        height: 135px;
        border-radius: 0 67px 0 67px;
      }
      .right {
        width: 40%;
        button {
          margin-bottom: 0;
          font-size: 60px;
          top: 20px;
          right: 20px;
          position: absolute;
        }
        .portfolioInfo {
          .tit {
            font-size: 14px;
          }
          .content {
            font-size: 12px;
          }
        }
      }
    }
    .news {
      margin: 50px auto;
      .newsSwiper .newsInfo {
        span {
          &:first-child {
            font-size: 16px;
            margin: 20px 0;
          }
        }
      }
      .actions {
        margin-top: 30px;
        button {
          font-size: 16px;
          padding: 5px 10px;
        }
      }
    }
  }
`;

export const ModernBannerContainer = styled.div`
  width: 70vw;
  height: 555px;
  overflow: hidden;
  .banners {
    width: 100%;
    height: 100%;
    border-radius: 0 300px 0 0;
    .bannerSlide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
    }
  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    height: 346px;
    padding-right: 20px;
    .banners {
      border-radius: 0 160px 0 0;
    }
  }
`;

export const ModernCmpnyContainer = styled.div`
  margin-bottom: 100px;
  main#contCompany {
    margin-top: 0;
    font-family: "Pretendard";
    h2 {
      display: none;
    }
    h3 {
      font-family: "Prata";
      font-weight: normal;
      font-size: 44px;
      margin-bottom: 44px;
      line-height: normal;
      width: 100%;
      max-width: 1400px;
    }
    .company_content {
      width: 100%;
      max-width: 1400px;
    }
    #secMap {
      .addr,
      .google_map_wrapper,
      .google_map_sub_wrapper {
        width: 100%;
        max-width: 1400px;
      }
      .address_btn_wrapper {
        font-size: 12px;
      }
      .addr_copy {
        background: rgba(192, 165, 165, 0.5);
        color: #333;
        font-size: 14px;
        padding: 5px 10px;
        font-weight: 700;
        border-radius: 5px;
        margin-left: 5px;
        i {
          margin-right: 5px;
        }
      }
      h3 {
        margin-bottom: 44px;
        span {
          padding: 0;
          border: none;
        }
        &::before {
          display: none;
        }
      }
    }

    .subTop_bnr_01 {
      display: none;
    }
  }
  @media screen and (max-width: 1400px) {
    article.boundary {
      padding: 70px 20px;
    }
  }
  @media screen and (max-width: 767px) {
    main#contCompany #secMap h3,
    main#contCompany h3 {
      font-size: 30px;
    }
  }
`;

export const ModernProductsContainer = styled.div`
  margin-bottom: 100px;
  main#contProductsList {
    margin-top: 0;
    font-family: "Pretendard";
    a:hover {
      color: #333;
    }
    .subTop_bnr {
      display: none;
    }
    #secListTop {
      margin: 20px 0;
      .count {
        font-size: 18px;
        i.fa-bars {
          margin-right: 5px;
        }
        strong {
          color: rgba(192, 165, 165, 1);
        }
      }
    }
    h2 {
      display: none;
    }
    h3 {
      font-family: "Prata";
      font-weight: normal;
      font-size: 44px;
      margin-bottom: 44px;
      line-height: normal;
    }
    .categorys {
      .categoryInfo {
        span {
          font-family: "Prata";
          font-size: 32px;
          line-height: normal;
        }
      }
      ul {
        box-shadow: none;
        justify-content: center;
        li {
          text-align: center;
          font-size: 20px;
          width: auto;
        }
      }
    }
    .pagination {
      li {
        border: none;
        &.active,
        &:hover {
          background: rgba(192, 165, 165, 0.5);
          color: #000;
        }
        a[aria-label*="page number"] {
          font-family: "Prata";
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    article.boundary {
      padding: 50px 20px;
      margin: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 1050px) {
    article.boundary {
      margin: 0;
      width: 100%;
    }
    main#contProductsList #secListTop .count,
    main#contProductsList .categorys ul li {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    #contProductsList #secList .col_3 {
      width: 100%;
      a p {
        font-size: 14px;
      }
    }
  }
`;

export const ModernNoticeContainer = styled.div`
  margin-bottom: 100px;
  main#contNoticeList {
    margin-top: 0;
    font-family: "Pretendard";
    a:hover {
      color: #333;
    }
    .subTop_bnr {
      display: none;
    }
    #secListTop {
      margin: 20px 0;
      .count {
        font-size: 18px;
        i.fa-bars {
          margin-right: 5px;
        }
        strong {
          color: rgba(192, 165, 165, 1);
        }
      }
    }
    h2 {
      display: none;
    }
    .notices {
      display: flex;
      flex-wrap: wrap;
      gap: 50px 30px;
      li {
        width: calc(50% - 15px);
        font-size: 24px;
        background: rgba(192, 165, 165, 0.25);
        padding: 30px;
        border-radius: 30px;
        cursor: pointer;
        .noticeCl {
          font-weight: 700;
        }
        .noticeSj {
          margin: 30px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          height: 58px;
        }
        .date {
          color: #6a6a6a;
        }
      }
    }
    .pagination {
      li {
        border: none;
        &.active,
        &:hover {
          background: rgba(192, 165, 165, 0.5);
          color: #000;
        }
        a[aria-label*="page number"] {
          font-family: "Prata";
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    article.boundary {
      padding: 50px 20px;
      margin: 0;
    }
  }
  @media screen and (max-width: 1050px) {
    article.boundary {
      margin: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    main#contNoticeList .notices li {
      width: 100%;
      font-size: 16px;
      .noticeSj {
        height: 19px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: inline-block;
        margin: 20px 0;
      }
    }
  }
`;

export const ModernNoticeViewContainer = styled.div`
  margin-bottom: 50px;
  main#noticeView,
  main#contProductsList {
    margin-top: 0;
    font-family: "Pretendard";
    article.boundary {
      width: 1400px;
    }
    a:hover {
      color: #333;
    }
    .subTop_bnr {
      display: none;
    }
    h2 {
      display: none;
    }
    .shop_port_title,
    #secBrdcrmb,
    .shop_port_detail_content {
      width: 100%;
    }
  }
  @media screen and (max-width: 1400px) {
    main#noticeView,
    main#contProductsList {
      padding: 0 20px;
      article.boundary {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    article.boundary {
      margin: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    main#noticeView,
    main#contProductsList {
      margin-bottom: 100px;
    }
  }
`;

export const ModernProductsView = styled.div`
  main#contProductsView {
    margin: 0 auto;
    font-family: "Pretendard";
    max-width: 1400px;
    .boundary {
      width: 100%;
    }
    .subTop_bnr_01 {
      display: none;
    }
    #secInfo .btns button {
      &.btn_wht {
        border: 1px solid rgba(192, 165, 165, 0.5);
      }
      &.btn_blk {
        color: #000;
        background: rgba(192, 165, 165, 0.5);
        border: 1px solid rgba(192, 165, 165, 0.5);
      }
    }
    .prd_img_wrapper > img {
      width: 100%;
    }
  }
  @media screen and (max-width: 1400px) {
    main#contProductsView {
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 767px) {
    main#contProductsView {
      margin-bottom: 100px;
    }
  }
`;
