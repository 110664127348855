import styled from "styled-components";

export const ProductAddFormInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .xlsxButtons {
    display: flex;
    gap: 10px;
    align-items: center;
    button,
    label {
      width: 120px;
      height: 40px;
      font-size: 16px;
      font-weight: 300;
      color: #fff;
      background: #333333;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input {
      display: none;
    }
  }
`;

export const ProductAddFormSubmitButton = styled.button`
  margin: auto;
  display: block;
  width: 200px;
  height: 54px;
  line-height: 54px;
  font-size: 18px;
  color: #fff;
  background-color: #0074ca;
  border: 1px solid #0074ca;
  border-radius: 0;
`;

export const ProductInfoFormContainer = styled.div`
  .inputxt_normal.category {
    padding: 15px 20px;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid #ccc;
    border-radius: 0;
    color: #333333;
    position: relative;
    .selected {
      display: flex;
      align-items: center;
      gap: 10px;
      span:not(.not_category) {
        color: #0074ca;
      }
      button {
        border: none;
        background: #efefef;
        border-radius: 50%;
        padding: 3px;
        font-size: 14px;
        svg {
          display: block;
        }
      }
    }
    ul {
      position: absolute;
      top: 100%;
      left: 0;
      background: #fff;
      border: 1px solid #ddd;
      z-index: 333;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      width: 100%;
      li {
        cursor: pointer;
        margin-bottom: 5px;
        display: flex;
        gap: 5px;
        align-items: center;
        transition: 0.3s;
        &:hover {
          background: #efefef;
        }
      }
    }
  }
  input[type="number"] {
    outline: none;
    &:focus {
      box-shadow: 0 0 5px #9ed4ff;
      border: 1px solid #558ab7 !important;
    }
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .options {
    .inputxt_normal {
      display: flex;
      margin-bottom: 10px;
      gap: 10px;
      input {
        width: calc(30% - 54px);
      }
      .optionField {
        width: calc(70% - 20px);
        padding: 15px 20px;
        font-size: 18px;
        font-weight: 300;
        border: 1px solid #ccc;
        border-radius: 0;
        color: #333333;
        position: relative;
        cursor: pointer;
        .option {
          border: 1px solid #ddd;
          bottom: 0;
          left: 0;
          transform: translateY(100%);
          width: 100%;
          position: absolute;
          background: #fff;
          z-index: 100;
          padding: 5px 0;
          .registration {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
            padding: 0 15px;
            cursor: default;
            button {
              border: none;
              background: transparent;
              font-weight: 700;
              color: tomato;
            }
          }
          ul {
            li {
              margin-bottom: 5px;
              display: flex;
              align-items: center;
              gap: 5px;
              transition: 0.3s;
              padding: 0 15px;
              &:hover {
                background: #efefef;
              }
            }
          }
        }
      }
    }
  }
  .thumbnail {
    .writing_cont {
      font-size: 18px;
      width: calc(100% - 220px);
      label {
        span {
          position: relative;
          display: inline-block;
          text-align: center;
          width: 110px;
          height: 54px;
          line-height: 54px;
          font-size: 18px;
          font-weight: 300;
          color: #fff;
          background: #333333;
          border: 0;
          border-radius: 0;
        }
        input {
          display: none;
        }
      }
    }
    .preview {
      .thumbnailImages {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;
      }
      li {
        width: calc((100% - 90px) / 10);
        height: 100%;
        aspect-ratio: 1;
        margin-bottom: 0;
        img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        button {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 20px;
          border: none;
          overflow: hidden;
          background: rgba(0, 0, 0, 0.3);
          color: #fff;
          svg {
            display: block;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .options .inputxt_normal {
      flex-wrap: wrap;
      input {
        width: 100%;
      }
      .optionField {
        width: calc(100% - 64px);
      }
    }
  }
`;
