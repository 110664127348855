import { useLocation } from "react-router";

const useQueryString = (query) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryString = queryParams.get(query);
  if(queryString === 'true'){
    return true;
  } else if(queryString === 'false'){
    return false;
  } else {
    return queryString
  }
}

export default useQueryString