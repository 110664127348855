import React from "react";

const InputField = ({
  label,
  name,
  placeholder,
  required = false,
  onChange,
  type = "text",
  children,
  half = false,
  value,
}) => (
  <li className={half ? "half_li" : ""}>
    <div className={`writing_tit ${required ? "required" : ""}`}>
      <strong className="tit">
        {label}
        {required && <em className="point">필수</em>}
      </strong>
    </div>
    <div className="writing_cont">
      <div className="inputxt_normal spider">
        {type === "select" ? (
          <select name={name} value={value} onChange={onChange}>
            {children}
          </select>
        ) : (
          <input
            type={type === "number" ? "number" : "text"}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        )}
      </div>
    </div>
  </li>
);

export default InputField;
