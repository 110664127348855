import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useRegex } from "../../../../hooks/useRegex";

export default function HomepageForm() {
  useEffect(() => {
    getCompaynyInfo();
    checkedDomain();
  }, []);

  const user = useSelector((state) => state?.user?.data[0]);
  const { BussinessFormat } = useRegex();
  const [companyInfo, setCompanyInfo] = useState({});
  const [res, setRes] = useState({});

  const getCompaynyInfo = async () => {
    const url = "/api/cmpnyInfo";
    const body = {
      cmpnyNo: user.cmpnyNo,
    };
    const result = await axios.post(url, body);
    setCompanyInfo(result.data);
  };

  const checkedDomain = async () => {
    const url = "/api/domainSettingLoad";
    const body = {
      delYn: "N",
      cmpnyNo: user.cmpnyNo,
    };
    const response = await axios.post(url, body);
    setRes(response);
  };

  const openNewTab = () => {
    window.open(`https://1472.ai/shop/${res.data.domain}`);
    user && localStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <div className="inner_content_wrap">
      <div className="hp_list">
        <table className="table_sm_list work_place">
          <colgroup>
            <col style={{ width: "35%" }} />
            <col style={{ width: "65%" }} />
          </colgroup>
          <thead>
            <tr>
              <th className="center">사업자번호</th>
              <th className="center">홈페이지 관리</th>
            </tr>
          </thead>
          <tbody id="place_tbody">
            {res?.data?.domain !== undefined ? (
              <tr>
                <td>
                  <span className="font_18 font_color_black">{companyInfo?.cmpnyNm}</span>
                  <span className="font_16 font_color_gray">{`(${BussinessFormat(
                    companyInfo.bizrno,
                  )})`}</span>
                </td>
                <td className="hp_control">
                  <Link onClick={openNewTab} className="hp_control_1">
                    <i className="fa fa-laptop" aria-hidden="true" /> 홈페이지 바로가기
                  </Link>
                  <Link to="/mypage/companyInfo" className="hp_control_1">
                    <i className="fa fa-building-o" aria-hidden="true" /> 기업정보
                  </Link>
                  <Link to="/mypage/homepageSetting" className="hp_control_1">
                    <i className="fa fa-gear" aria-hidden="true" /> 관리
                  </Link>
                </td>
              </tr>
            ) : (
              <tr>
                <td>
                  <span className="font_18 font_color_black">{companyInfo?.cmpnyNm}</span>
                  <span className="font_16 font_color_gray">{`(${BussinessFormat(
                    companyInfo.bizrno,
                  )})`}</span>
                </td>
                <td className="hp_control">
                  <Link to="/mypage/makeCompanyDomain" className="hp_control_1">
                    <i className="fa fa-plus" aria-hidden="true" /> 생성
                  </Link>
                  {/* <Link to="/mypage/companyInfo" className="hp_control_2"><i className="fa fa-plus" aria-hidden="true" /> 생성</Link> */}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
