import React from "react";
import { PendingCountContainer } from "../../styled/salesCheck";
import { BiSolidBellRing } from "react-icons/bi";

const PendingCount = ({ count = 2 }) => {
  return (
    <PendingCountContainer className={count > 0 ? "hasPending" : "noPending"}>
      <div className="info">
        <div className="icon">
          <BiSolidBellRing />
        </div>
        {/* <span>아직 정산하지 않은 건이 {count}건 있습니다.</span> */}
      </div>
    </PendingCountContainer>
  );
};

export default PendingCount;
