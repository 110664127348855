import MypageBanner from "../mypageBanner";
import CompanyInfoForm from "./component/companyInfoForm";
import CompanyInfoTitle from "./component/companyInfoTitle";
import ApplicationSecForm from "../../application/component/applicationSecForm";
import { useSelector } from "react-redux";

const CompanyInfo = () => {
  const index = useSelector((state) => state.compoIndex.company);
  
  return(
    <div>
      <MypageBanner />
      <div className="inner_content">
        <CompanyInfoTitle index={index} />
        <Components index={index}/>
      </div>
    </div>
  )
};

export default CompanyInfo;

function Components ({index}) {
  return (
    <>
      {[<CompanyInfoForm />, <ApplicationSecForm />][index]}
    </>
  )
}