import React, { useEffect, useState } from "react";
import { Header } from "../styled/common";
import { useNavigate } from "react-router";
import { IoClose } from "react-icons/io5";
import moment from "moment";
import { ConfirmationModal } from "./modal";
import { useMediaQuery } from "react-responsive";

const MutualBenefitHeader = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [currentTime, setCurrentTime] = useState(
    moment().format(isMobile ? "a h시m분" : "YYYY년 MM월 DD일 A h시 m분"),
  );
  const [isClosed, setIsOpen] = useState(false);
  const [openMadal, setOpenMadal] = useState(false);

  useEffect(() => {
    let animationFrameId;

    const updateCurrentTime = () => {
      setCurrentTime(moment().format(isMobile ? "a h시m분" : "YYYY년 MM월 DD일 A h시 m분"));
      animationFrameId = requestAnimationFrame(updateCurrentTime);
    };

    updateCurrentTime();
    return () => cancelAnimationFrame(animationFrameId);
  }, [isMobile]);

  const nav = [
    { label: "홈", path: "home", function: () => console.log("준비중입니다") },
    { label: "영업재개", path: "reopen", function: () => console.log("준비중입니다") },
    { label: "영업종료", path: "close", function: () => console.log("준비중입니다") },
  ];

  const changeStatus = () => {
    setOpenMadal(true);
  };

  const handleConfirm = () => {
    setIsOpen(!isClosed);
    setOpenMadal(false);
  };

  return (
    <>
      <Header>
        <div className="toggle">
          <label className={`switch ${isClosed ? "close" : "open"}`} onChange={changeStatus}>
            <input type="checkbox" checked={!isClosed} />
            <span className="slider"></span>
          </label>
          <span>{isClosed ? "영업종료" : "영업중"}</span>
        </div>
        <div className="actions">
          <span>{currentTime}</span>
          <button onClick={() => navigate("/mypage/mutualBenefit")}>
            <IoClose />
          </button>
        </div>
      </Header>
      {openMadal && (
        <ConfirmationModal
          title={isClosed ? "영업재개" : "영업종료"}
          message={[`영업을 ${isClosed ? "재개" : "종료"}하시겠습니까?`]}
          handleConfirm={handleConfirm}
          handleCancel={() => setOpenMadal(false)}
        />
      )}
    </>
  );
};

export default MutualBenefitHeader;
