import styled from "styled-components";

const bdr = "20px";
const shadow = `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;`;

export const Container = styled.div`
  padding: 150px 0px 50px;
  margin: auto;
  position: relative;
  width: 95%;
  .inner {
    max-width: 1400px;
    width: 100%;
    margin: 50px auto;
  }
  .restaurantInner {
    max-width: 1440px;
    width: 100%;
    margin: 50px auto;
  }
  .mySwiper {
    max-width: 1400px;
    margin-top: 20px;
    .slideContent {
      border-radius: ${bdr};
      overflow: hidden;
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .inner {
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 1050px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 100px 0 50px;
  }
`;

export const SearchContainer = styled.div`
  height: 75vh;
  width: 100%;
  position: relative;
  display: flex;
  gap: 20px;
  @media screen and (max-width: 1050px) {
    padding: 0 20px;
    flex-wrap: wrap;
    height: auto;
  }
  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }
`;

export const SearchFormContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 320px;
  background: #fff;
  border-radius: 0 0 20px 0;
  z-index: 3;
  &::before,
  &::after {
    content: "";
    background-image: url("/img/border-radius.png");
    width: 20px;
    height: 20px;
    position: absolute;
  }
  &::before {
    right: 0;
    top: 0;
    transform: translateX(100%);
  }
  &::after {
    left: 0;
    bottom: 0;
    transform: translateY(100%);
  }
  .inputField {
    width: 100%;
    position: relative;
    display: flex;
    input {
      width: 100%;
      border-radius: ${bdr};
      border: none;
      box-shadow: ${shadow};
      padding: 15px;
      color: #232323;
      font-size: 20px;
      margin: 0 15px 15px 0;
      &:focus {
        outline: none;
      }
    }
    button {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(calc(-50% - 4.5px));
      border-radius: 50%;
      background: #000;
      color: #fff;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (max-width: 1050px) {
    &::after {
      left: 20px;
    }
    .inputField {
      padding: 0px 15px 15px 20px;
      input {
        font-size: 17px;
        margin: 0;
      }
      button {
        width: 30px;
        height: 30px;
        font-size: 30px;
        transform: translateY(calc(-50% - 6.5px));
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 50%;
    &::after {
      left: 15px;
    }
    .inputField {
      padding: 0px 15px 15px 15px;
      input {
        font-size: 14px;
      }
      button {
        width: 20px;
        height: 20px;
        font-size: 20px;
        transform: translateY(calc(-50% - 6.5px));
      }
    }
  }
`;

export const SearchMapContainer = styled.div`
  width: calc(100% - 320px);
  background: #efefef;
  border-radius: ${bdr};
  .mapField {
    width: 100%;
    height: 100%;
    border-radius: ${bdr};
    overflow: hidden;
  }
  @media screen and (max-width: 1050px) {
    width: 100%;
    .mapField {
      height: 70vh;
    }
  }
`;

export const SearchBannerContainer = styled.div`
  width: 300px;
  height: 100%;
  overflow: hidden;
  ul {
    display: flex;
    gap: 20px;
    flex-direction: column;
    height: 100%;
    li {
      border-radius: ${bdr};
      overflow: hidden;
      height: calc((100% - 40px) / 3);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    width: 100%;
    height: 250px;
    ul {
      flex-direction: row;
      li {
        width: calc((100% - 40px) / 3);
        height: 200px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    height: auto;
    ul {
      flex-wrap: wrap;
      li {
        height: 150px;
        &:not(&:last-child) {
          width: calc(50% - 10px);
        }
        &:last-child {
          width: 100%;
        }
      }
    }
  }
`;

export const EChanggoCardContainer = styled.div`
  &.inner {
    margin: 50px auto 70px;
  }
  .bg {
    height: 150px;
    background: #eefef4;
    position: relative;
    border-radius: ${bdr};
    display: flex;
    gap: 50px;
    align-items: center;
    padding: 50px;
    font-size: 30px;
    span {
      &:first-child {
        font-weight: 800;
      }
      &:last-child {
        font-size: 25px;
        color: #afb6b1;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 400px;
      height: 150px;
      background-image: url("/img/e_changgo_character.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      z-index: 1;
      bottom: 0;
      right: 0;
    }
  }
  @media screen and (max-width: 1050px) {
    .bg {
      flex-direction: column;
      gap: 10px;
      align-items: baseline;
      padding: 0;
      justify-content: center;
      text-indent: 30px;
      span {
        &:first-child {
          font-size: 26px;
        }
        &:last-child {
          font-size: 22px;
        }
      }
      &::after {
        width: 300px;
        height: 80%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .bg {
      height: 80px;
      span {
        &:first-child {
          font-size: 18px;
        }
        &:last-child {
          font-size: 16px;
        }
      }
      &::after {
        width: 200px;
        height: 80%;
      }
    }
  }
`;

export const LocatorInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  max-width: 1400px;
  .info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    span:first-child {
      font-size: 35px;
      font-weight: 700;
    }
    span:last-child {
      font-size: 30px;
      color: #c5c5c5;
    }
  }
  .moreButton {
    button {
      display: flex;
      font-size: 35px;
      color: #b2b2b2;
      background: transparent;
    }
  }
  @media screen and (max-width: 767px) {
    .info {
      span:first-child {
        font-size: 24px;
      }
      span:last-child {
        font-size: 14px;
      }
    }
  }
`;

export const LocatorSwiperContainer = styled.div`
  position: relative;
  .swiperButons {
    position: absolute;
    top: 50%;
    display: flex;
    font-size: 35px;
    color: rgb(178, 178, 178);
    background: transparent;
    transform: translateY(-50%);
    &.next {
      right: -50px;
    }
    &.prev {
      left: -50px;
    }
  }
  .mySwiper.restaurant {
    padding: 10px;
    .swiper-wrapper {
      transition-timing-function: linear;
    }
    .slideContent {
      box-shadow: ${shadow};
      border-radius: 5px;
      .imageArea {
        width: 100%;
        height: 150px;
        overflow: hidden;
      }
      .infoArea {
        padding: 15px;
        font-size: 13px;
        display: flex;
        gap: 7px;
        flex-direction: column;
        color: #b4b4b4;
        font-weight: 500;
        .tit {
          font-weight: 700;
          font-size: 18px;
          color: #000;
          margin-bottom: 5px;
        }
      }
    }
  }
  .mySwiper.store {
    .slideContent {
      .imageArea {
        width: 100%;
        overflow: hidden;
        img {
          border-radius: ${bdr};
        }
      }
      .infoArea {
        padding: 15px 15px 30px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        color: #b4b4b4;
        .tit {
          font-weight: 700;
          font-size: 25px;
          color: #000;
          margin-bottom: 5px;
        }
      }
    }
  }
  .mySwiper.group {
    .slideContent {
      position: relative;
      border-radius: 0 ${bdr} ${bdr} ${bdr};
      overflow: hidden;
      padding: 10px;
      margin: 0 -8px;
      .imageArea {
        filter: brightness(70%);
        border-radius: 20px;
        overflow: hidden;
      }
      .tit,
      .grpDesc {
        position: absolute;
      }
      .infoArea {
        width: 100%;
      }
      .tit {
        width: 60%;
        height: 65px;
        border-radius: 0px 0px 20px;
        top: 10px;
        left: 10px;
        background: rgb(255, 255, 255);
        padding: 0px 15px 0px 0px;
        &::before,
        &::after {
          content: "";
          background-image: url("/img/border-radius.png");
          width: 20px;
          height: 20px;
          position: absolute;
        }
        &::before {
          right: 1px;
          top: 0;
          transform: translateX(100%);
        }
        &::after {
          left: 0;
          bottom: 0;
          transform: translateY(100%);
        }
        .grpName {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: 700;
          justify-content: space-between;
          border-radius: ${bdr};
          padding: 10px 15px;
          box-shadow: ${shadow};
          button {
            display: flex;
            border-radius: 50%;
            background: #000;
            font-size: 30px;
            color: #fff;
            width: 30px;
            height: 30px;
          }
        }
      }
      .grpDesc {
        left: 30px;
        bottom: 50px;
        font-size: 22px;
        color: #fff;
      }
    }
    .swiper-pagination {
      position: relative;
      margin-top: 30px;
      .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        margin: 0 5px;
        background: #fff;
        border: 2px solid #d3d3d3;
        &.swiper-pagination-bullet-active {
          border: 1px solid #1a1a1a;
          background: #1a1a1a;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .mySwiper.restaurant {
      .slideContent .infoArea {
        .tit {
          font-size: 18px;
        }
      }
    }
    .mySwiper.store {
      .slideContent {
        padding: 10px 0px;
        .infoArea {
          padding: 10px;
          font-size: 16px;
          .tit {
            font-size: 18px;
            margin-bottom: 0;
          }
        }
      }
    }
    .mySwiper.group {
      .slideContent,
      .imageArea {
        height: 100%;
      }
      .slideContent {
        .tit {
          width: 50%;
          padding: 0 10px 0 0;
          height: 50px;
          .grpName {
            font-size: 15px;
            padding: 10px;
            button {
              width: 20px;
              height: 20px;
              font-size: 20px;
            }
          }
        }
        .grpDesc {
          font-size: 14px;
          left: 10%;
          bottom: 10%;
        }
      }
      .swiper-pagination {
        .swiper-pagination-bullet {
          width: 15px;
          height: 15px;
          margin: 0 5px;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .swiperButons {
      display: none;
    }
  }
`;
