class ImageUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then((file) => {
      return new Promise((resolve, reject) => {
        // 파일을 업로드하지 않고, 선택한 파일을 CKEditor 5 에디터에 삽입합니다.
        const reader = new FileReader();

        reader.onload = (event) => {
          resolve({
            default: event.target.result,
          });
        };

        reader.readAsDataURL(file);
      });
    });
  }
}

function ImageUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    loader.file.then((file) => {});
    return new ImageUploadAdapter(loader);
  };
}

export default ImageUploadAdapterPlugin;
