import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ChangePwForm from "./changePwForm";

const MemberSearchPassword = () => {
  const [formData, setFormData] = useState({
    username: "",
    mberNm: "",
    certCode: "",
    email: "",
  });
  const [countdown, setCountdown] = useState(0);
  const [isCertified, setIsCertified] = useState(false);
  const [result, setResult] = useState({});

  useEffect(() => {
    let intervalId;

    if (countdown > 0 && isCertified) {
      intervalId = setInterval(() => {
        setCountdown((currentCountdown) => currentCountdown - 1);
      }, 1000);
    } else if (countdown === 0 && isCertified) {
      toast.error("인증번호의 유효 시간이 만료되었습니다.");
      setIsCertified(false);
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [countdown, isCertified]);

  const changeInput = (e) => {
    const { name, value } = e.target;
    if (!name) return;

    if (name === "email" && isCertified) {
      setIsCertified(false);
    }
    setFormData((data) => ({ ...data, [name]: value }));
  };

  // 입력 검증
  const validateInput = (flag = false) => {
    if (!formData.username) {
      toast.error("아이디를 입력해주세요.");
      return false;
    }
    if (!formData.mberNm) {
      toast.error("이름을 입력해주세요.");
      return false;
    }
    if (!formData.email) {
      toast.error("이메일을 입력해주세요.");
      return false;
    }
    const regEmail =
      /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    if (!regEmail.test(formData.email)) {
      toast.error("이메일 양식이 올바르지 않습니다.");
      return false;
    }
    if (flag && !formData.certCode) {
      toast.error("인증번호가 존재하지 않습니다.");
      return false;
    }
    if (flag && !isCertified) {
      toast.error("인증번호 받기를 눌러주세요.");
      return false;
    }
    return true;
  };

  // 이메일로 인증번호 전송
  const getCertCode = async () => {
    if (!validateInput()) return;

    try {
      const url = "/api/mailSendRandomNumber";
      const body = { username: formData.username, mberNm: formData.mberNm, email: formData.email };

      const res = await axios.post(url, body);
      setCountdown(600);

      if (res.data.success) {
        toast.success(res.data.message);
        setIsCertified(true);
      }
    } catch (error) {
      console.error(error);
      toast.error("입력한 정보를 다시 확인해주세요.");
      setIsCertified(false);
    }
  };

  const onSubmit = async () => {
    if (!validateInput(true)) return;

    try {
      const url = "/api/chkIdByEmail";
      const body = {
        username: formData.username,
        mberNm: formData.mberNm,
        email: formData.email,
        certCode: formData.certCode,
      };

      const res = await axios.post(url, body);

      if (res.data.length > 0) {
        const user = res.data[0];
        setResult(user);
        toast.success("성공적으로 확인되었습니다. 비밀번호를 재설정하세요.");
      } else {
        toast.error("입력한 정보를 다시 확인해주세요.");
        setResult({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="tab_inner active">
      <div className="inner_title m_join_in m_search">
        <span className="font_22 font300 font_color_black pdb_40">
          비밀번호가 기억나지 않으세요?
          <br />
          비밀번호 찾기 방법 중 가능한 방법을 선택해 주세요.
        </span>
      </div>
      <div className="writing_ty2 m_join_wrap">
        {/* <div className="step_tit_bar m_join">
      <span className="font_32 font600 font_color_1 pdb_20">
        회원정보에 등록한 휴대전화로 인증
      </span>
      <span className="font_16 font_color_gray font300 text_left pdb_20">
        회원정보에 등록한 휴대전화 번호와 입력한 휴대전화 번호가 같아야, <br />
        인증번호를 받을 수 있습니다.
      </span>
    </div> */}
        {Object.keys(result).length === 0 ? (
          <>
            <ul className="writing_list m_join">
              {/* <li className="no_border">
        <div className="writing_tit required">
          <strong className="tit">
            아이디<em className="point">필수</em>
          </strong>
        </div>

        <div className="writing_cont">
          <div className="inputxt_normal spider">
            <input
              type="text"
              id="client_name"
              name="client_name"
              placeholder="아이디를 입력하세요"
              value=""
            />
          </div>
        </div>
      </li>
      <li className="no_border">
        <div className="writing_tit required">
          <strong className="tit">
            이름<em className="point">필수</em>
          </strong>
        </div>

        <div className="writing_cont">
          <div className="inputxt_normal spider">
            <input
              type="text"
              id="client_name"
              name="client_name"
              placeholder="이름을 입력하세요"
              value=""
            />
          </div>
        </div>
      </li>
      <li>
        <div className="impor_txt">입력한 휴대전화로 알림톡이 발송되니 정확하게 입력해주세요.</div>
        <div className="writing_tit required">
          <strong className="tit">
            휴대전화 번호<em className="point">필수</em>
          </strong>
        </div>
        <div className="writing_cont">
          <div className="inputxt_normal spider between_f">
            <input
              type="text"
              id="member_id"
              name="member_id"
              placeholder="휴대전화번호(숫자만 입력)"
              value=""
            />
            <button
              type="button"
              name="button"
              className="btn_line gray btn_duplicate m_search">
              <span>인증번호 받기</span>
            </button>
          </div>
        </div>
      </li>
      <li>
        <div className="writing_tit required">
          <strong className="tit">
            인증번호 확인<em className="point">필수</em>
          </strong>
        </div>
        <div className="writing_cont">
          <div className="inputxt_normal spider">
            <input
              type="text"
              id="client_phone"
              className=""
              name="client_phone"
              placeholder="인증번호 6자리"
              value=""
            />
            <input type="hidden" name="client_phone_org" value="" />
          </div>
        </div>
      </li> */}
              <li className="company_info">
                <div className="step_tit_bar m_join">
                  <span className="font_32 font600 font_color_1 pdb_20">
                    본인확인 이메일로 인증
                  </span>
                  <span className="font_16 font_color_gray font300 text_left pdb_20">
                    회사코드가 전송된 이메일 주소와 입력한 이메일 주소가 같아야,
                    <br /> 인증번호를받을 수 있습니다.
                  </span>
                </div>
              </li>
              <li className="no_border">
                <div className="writing_tit required">
                  <strong className="tit">
                    아이디<em className="point">필수</em>
                  </strong>
                </div>
                <div className="writing_cont">
                  <div className="inputxt_normal spider">
                    <input
                      type="text"
                      placeholder="아이디를 입력하세요."
                      name="username"
                      value={formData.username}
                      onChange={(e) => changeInput(e)}
                      tabIndex={1}
                    />
                  </div>
                </div>
              </li>
              <li className="no_border">
                <div className="writing_tit required">
                  <strong className="tit">
                    이름<em className="point">필수</em>
                  </strong>
                </div>

                <div className="writing_cont">
                  <div className="inputxt_normal spider">
                    <input
                      type="text"
                      placeholder="이름을 입력하세요"
                      value={formData.mberNm}
                      name="mberNm"
                      onChange={(e) => changeInput(e)}
                      tabIndex={2}
                    />
                  </div>
                </div>
              </li>
              <li>
                <div className="writing_tit required">
                  <strong className="tit">
                    이메일 주소<em className="point">필수</em>
                  </strong>
                </div>
                <div className="writing_cont">
                  <div className="inputxt_normal spider between_f">
                    <input
                      type="text"
                      placeholder="이메일 주소"
                      name="email"
                      value={formData.email}
                      onChange={(e) => changeInput(e)}
                      tabIndex={3}
                    />
                    <button
                      type="button"
                      className="btn_line gray btn_duplicate m_search"
                      tabIndex={4}
                      onClick={getCertCode}>
                      <span>인증번호 받기</span>
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <div className="writing_tit required">
                  <strong className="tit">
                    인증번호 확인<em className="point">필수</em>
                  </strong>
                </div>
                <div className="writing_cont">
                  <div className="inputxt_normal spider">
                    <input
                      type="text"
                      placeholder="인증번호 6자리"
                      name="certCode"
                      value={formData.certCode}
                      onChange={(e) => changeInput(e)}
                      tabIndex={5}
                    />
                    {countdown > 0 && isCertified && (
                      <div className="countdown">
                        {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, "0")}
                      </div>
                    )}
                  </div>
                </div>
              </li>
            </ul>
            <div className="btnarea_center mt20">
              <button className="btn_normal" style={{ border: "none" }} onClick={onSubmit}>
                <span>
                  다음 <i className="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </>
        ) : (
          <ChangePwForm user={result} code={formData.certCode} />
        )}
      </div>
    </div>
  );
};

export default MemberSearchPassword;
