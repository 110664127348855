import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router";

import Header from "./component/common/header";
import ReserveInput from "./component/retrieve/reserveInput";
import ContactInfoInput from "./component/retrieve/contactInfoInput";
import ResultNotify from "./component/retrieve/resultNotify";

import { useDisableScroll } from "../mypage/kiosk/component/function/kiosk";

import { RetrieveContainer } from "./component/styled/selfService";
import ActionConfirmation from "./component/common/actionConfirmation";
import { useMediaQuery } from "react-responsive";

const Retrieve = () => {
  useDisableScroll();

  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [tab, setTab] = useState(0);
  const [formData, setFormData] = useState({ id: "", mberNm: "", tel: "" });
  const [details, setDetails] = useState([]);

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const transformValue = active * -100;

  const tabs = [
    { id: 0, label: "이름" },
    { id: 1, label: "전화번호 뒷자리" },
    // { id: 2, label: "예약번호" },
  ];

  const handleChangeInput = (key, value) => {
    console.log(key, value);
    setFormData((data) => ({ ...data, [key]: value }));
  };

  const changeTab = (index) => {
    setFormData((data) => ({ ...data, mberNm: "", tel: "" }));
    setTab(index);
  };

  const determineStepAction = (isNext = false) => {
    console.log(isNext, active);
    if ((isNext && active > 1) || (!isNext && active > 1) || (!isNext && !active)) {
      return;
    }

    if (isNext) {
      if (!stepCheck()) return;
      if (active === 1) {
        getStorageDetail();
      } else {
        setActive((data) => data + 1);
      }
    } else {
      if (active === 1) {
        setFormData((data) => ({ ...data, mberNm: "", tel: "" }));
        setTab(0);
      }
      setActive((data) => data - 1);
    }
  };

  // 이동 예외처리
  const stepCheck = () => {
    if (active === 0) {
      const id = parseInt(formData.id, 10);

      if (isNaN(id) || id < 1) {
        toast.error("유효한 예약번호를 입력해주세요.");
        return false;
      }
    }

    if (active === 1 && tab === 0) {
      const vowelOnlyRegex = /^[ㅏ-ㅣ]*$/;
      const nameRegex = /^[a-zA-Z가-힣\s]+$/;
      const name = formData.mberNm?.trim();

      if (!name) {
        toast.error("이름을 입력해주세요.");
        return false;
      }
      if (name.length < 2 || name.length > 6) {
        toast.error("이름은 2자 이상 6자 이하로 입력해주세요.");
        return false;
      }
      if (!nameRegex.test(name) || vowelOnlyRegex.test(name)) {
        toast.error("이름에는 한글 자음 및 모음만 포함할 수 없습니다.");
        return false;
      }
    }

    if (active === 1 && tab === 1) {
      const tel = formData.tel;

      if (!tel || tel.length < 4) {
        toast.error("유효한 전화번호를 입력해주세요.");
        return false;
      }
    }

    return true;
  };

  const getStorageDetail = async () => {
    const id = parseInt(formData.id, 10);
    if (!id) {
      toast.error("예약번호가 존재하지 않습니다.");
      return;
    }

    const url = "/api/reserve-user-detail";
    const body = { id };

    try {
      const res = await axios.post(url, body);
      console.log(res);
      const data = res.data.filter(
        (item) =>
          item.reservationStatus === "COMPLETED" &&
          (item.mberNm === formData.mberNm || item.tel.slice(-4) === formData.tel),
      );

      if (!data.length) {
        toast.error("예약 정보를 찾을 수 없습니다.");
        return;
      }

      setDetails(data);
      setActive(2);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RetrieveContainer>
      <Header
        title={active < 2 ? "고객 정보 입력" : "픽업 상태"}
        handleBackButton={determineStepAction}
      />
      <div
        className="content"
        style={{ height: active < 2 ? `calc(100% - ${isMobile ? 50 : 86}px)` : "100%" }}>
        {active < 2 && (
          <div className="tabs">
            <ul>
              {active > 0 ? (
                tabs.map((item, index) => (
                  <li onClick={() => changeTab(index)} className={index === tab ? "active" : ""}>
                    {item.label}
                  </li>
                ))
              ) : (
                <li>예약번호</li>
              )}
            </ul>
          </div>
        )}
        <div
          className="slideWrapper"
          style={{ height: active > 2 && isMobile && "calc(100vh - 50px)" }}>
          <ReserveInput
            transformValue={transformValue}
            formData={formData}
            handleChangeInput={handleChangeInput}
            determineStepAction={determineStepAction}
          />
          <ContactInfoInput
            transformValue={transformValue}
            formData={formData}
            handleChangeInput={handleChangeInput}
            tabs={tabs}
            tab={tab}
            determineStepAction={determineStepAction}
          />
          <ResultNotify transformValue={transformValue} details={details} setActive={setActive} />
          <ActionConfirmation
            transformValue={transformValue}
            title="픽업요청완료"
            explanation={["픽업 신청이 완료되었습니다.", "카운터로 가셔서 직원에게 말씀해 주세요."]}
            onConfirm={() => navigate("/self-service")}
          />
        </div>
      </div>
    </RetrieveContainer>
  );
};

export default Retrieve;
