import React from "react";
import { ViewTypeContainer } from "../styled/storage";

const ViewType = ({ type, setType, handleClickStorage }) => {
  const tab = ["전체", "상세"];

  return (
    <ViewTypeContainer>
      <ul>
        {tab.map((item, idx) => (
          <li className={`hover${type === idx ? " active" : ""}`} onClick={() => setType(idx)}>
            {item}
          </li>
        ))}
      </ul>
      <div className="buttons">
        <button onClick={() => handleClickStorage("custResv")}>예약고객</button>
        <button onClick={() => handleClickStorage("userInfoForm")}>보관하기</button>
      </div>
    </ViewTypeContainer>
  );
};

export default ViewType;
