import { useEffect, useState } from "react";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { SideMenuContainer } from "../styled/scheduler";

import { SlArrowUp, SlArrowDown } from "react-icons/sl";

import SideMenuCalendar from "./sideMenu/sideMenuCalendar";
import SideMenuChoiceList from "./sideMenu/sideMenuChoiceList";
import SideMenuSelectMonth from "./sideMenu/sideMenuSelectMonth";

import { assignColorClass } from "../function/scheduler";

export default function SideMenu({
  data,
  sideCalendarRef,
  userDate,
  setUserDate,
  toggleSideMenu,
  toggleAgendaContent,
  handleDateClick,
  onDate,
}) {
  const [dataByDate, setDataByDate] = useState([]);
  const [version, setVersion] = useState(0);
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);
  const navigate = useNavigate();

  useEffect(() => {
    const newEvents = [];
    const eventsCount = {};

    data.forEach((item) => {
      if (item.dday === "Y") {
        return;
      }

      const { startDt, endDt, color, id, title } = item;
      const [startDate] = startDt.split(" ");
      const [endDate] = endDt.split(" ");

      const duration = moment(endDate).diff(moment(startDate), "days");
      for (let i = 0; i <= duration; i++) {
        const current = moment(startDate).add(i, "days").format("YYYY-MM-DD");
        if (!eventsCount[current] || eventsCount[current] < 3) {
          const className = `schedule${assignColorClass(color)}`;
          newEvents.push({
            title,
            id,
            start: current,
            className,
          });
          eventsCount[current] = (eventsCount[current] || 0) + 1;
        }
      }
    });

    setDataByDate(newEvents);
    setVersion((v) => v + 1);
  }, [data]);

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [isAnnualCalendar, setIsAnnualCalendar] = useState(false);

  const clickDate = (info) => {
    handleDateClick(info.date);
    toggleSideMenu(false);
    toggleAgendaContent(true);
  };

  const dateTime = (day) => {
    const formatted = moment(day).format("YYYY-MM-DD hh:mm:ss");
    const date = formatted.split(" ")[0];

    const now = moment().format("HH:00:00");
    return `${date} ${now}`;
  };

  return (
    <SideMenuContainer
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          toggleSideMenu(false);
        }
      }}
      className={`sidemenu ${isDarkMode}`}
      style={{ transform: `translateX(${isMobile ? "-150%" : "0"})` }}>
      <div className="writing_constract_list_wrap">
        <div className="tableEditAddBtnArea">
          <button
            className="font_14 font400 text_center exit"
            onClick={() =>
              navigate("/mypage/calendarEntry", {
                state: {
                  eventInfo: {
                    startDt: moment(dateTime(onDate)).startOf("hour").format("YYYY-MM-DD HH:30:00"),
                    endDt: moment(dateTime(onDate))
                      .startOf("hour")
                      .add(1, "hour")
                      .format("YYYY-MM-DD HH:30:00"),
                  },
                },
              })
            }>
            일정 추가
          </button>
          {isMobile && (
            <button
              className="font_14 font400 text_center exit"
              onClick={() => navigate("/mypage")}>
              목록으로 돌아가기
            </button>
          )}
        </div>
        <div className="calendarBox">
          <button
            className="selectCalendarBtn"
            onClick={() => setIsAnnualCalendar(!isAnnualCalendar)}
            style={{ left: isAnnualCalendar ? 70 : 95 }}>
            {isAnnualCalendar ? <SlArrowUp /> : <SlArrowDown />}
          </button>
          <SideMenuCalendar
            isAnnualCalendar={isAnnualCalendar}
            setIsAnnualCalendar={setIsAnnualCalendar}
            handleDateClick={handleDateClick}
            onDate={onDate}
            userDate={userDate}
            setUserDate={setUserDate}
            data={dataByDate}
            clickDate={clickDate}
            sideCalendarRef={sideCalendarRef}
            version={version}
          />
          <SideMenuSelectMonth
            userDate={userDate}
            setUserDate={setUserDate}
            handleDateClick={handleDateClick}
            setIsAnnualCalendar={setIsAnnualCalendar}
            isAnnualCalendar={isAnnualCalendar}
          />
        </div>
        <SideMenuChoiceList />
      </div>
    </SideMenuContainer>
  );
}
