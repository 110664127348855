import React from "react";
import FaqViewForm from "./component/faqPage/faqViewForm";
import NoticeBanner from "./noticeBanner";

const FaqPage = () => {
  return (
    <>
      <NoticeBanner />
      <FaqViewForm />
    </>
  );
};

export default FaqPage;
