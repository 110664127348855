import React from "react";

const ProjectFileBoxFolders = ({ folders, current, folderState, changeFolder, counts }) => {
  const isShowTypeButton = (item) => {
    if (current !== "fileType") return true;
    if (folderState.gbn === "P" && item.key !== "I") return false;
    if (folderState.sub === "S" && item.key !== "I") return false;
    return true;
  };

  const isClassName = (item) => {
    if (current === "gbn") {
      return item.key;
    }
    if (current === "fileType") {
      if (item.key === folderState.fileType) {
        return "active";
      }
    }
    return "";
  };

  return (
    <ul className="folders">
      {folders[current].map((item) => {
        if (isShowTypeButton(item)) {
          return (
            <li
              key={item.key}
              onClick={() => changeFolder(current, item.key)}
              className={isClassName(item)}>
              <span>{item.name}</span>
              {current === "fileType" && counts[item.key] > 0 && (
                <i>&#40;{counts[item.key]}&#41;</i>
              )}
            </li>
          );
        }
        return null;
      })}
    </ul>
  );
};

export default ProjectFileBoxFolders;
